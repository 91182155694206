import React, { Component } from 'react';
import { DotsHorizontalIcon } from '@heroicons/react/solid'

import { AnnotationIcon, HeartIcon, PaperAirplaneIcon, BookmarkIcon, ChevronRightIcon, ThumbUpIcon, RefreshIcon } from '@heroicons/react/outline';

class PreviewLinkedInImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cta: {},
            page: {},
            image: null,
            video: null,
            body: "",
            title: "",
            link: "",
            caption: "",
            read_more: false,
            adData: {},
            is_post: false,
            pdf: null,
        }
    };


    async componentDidMount() {
        await this.promisedSetState({
            cta: this.props.cta,
            page: this.props.page,
            image: this.props.image,
            video: this.props.video,
            body: this.props.body,
            title: this.props.title,
            link: this.props.link,
            caption: this.props.caption,
            adData: this.props.adData,
            is_post: this.props.is_post,
            pdf: this.props.pdf,
        })
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            cta: nextProps.cta,
            page: nextProps.page,
            image: nextProps.image,
            video: nextProps.video,
            body: nextProps.body,
            title: nextProps.title,
            link: nextProps.link,
            caption: nextProps.caption,
            adData: nextProps.adData,
            is_post: nextProps.is_post,
            pdf: nextProps.pdf,
        })

        setTimeout(() => {
            try {
                if (this.refs.videoOne) {
                    this.refs.videoOne.setAttribute("muted", true);
                }
                if (this.refs.videoTwo) {
                    this.refs.videoTwo.setAttribute("muted", true);
                }
            } catch (error) {
                //console.log(error);
            }
        }, 200);
    }
    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        }
    };

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.title !== "" ? this.state.title : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.body !== "" ? this.state.body : "Body ...";
                body = body.replace("{location}", this.state.location);
                const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
                body = body.replace(regex, '<br>');
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }
                return body;
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                let description = this.state.description !== "" ? this.state.description : "";
                description = description.replace("{location}", this.state.location);
                return description
            } catch (e) {
                return ""
            }
        },
        image: () => {
            try {
                return this.state.image
            } catch (e) {
                return ""
            }
        },
        cover: () => {
            try {
                return this.state.facebook_event.cover.source
            } catch (e) {
                return ""
            }
        },
        media: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.props.linkedin) {
                    if (this.state.creatives[0].call_to_action_linkedin.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.state.creatives[0].call_to_action_linkedin.name;
                    }
                } else {
                    if (this.state.cta.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.cta.name;
                    }
                }
            } catch (e) {
                return null
            }
        },
        ratioCheck: () => {
            try {
                if (this.state.adData.ad_type.value !== "merge_ad") {
                    if (this.state.adData.file.ratio === "9:16") {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } catch (e) {
                return false
            }
        },
    };

    render() {
        return (
            <div
                className='w-full h-auto max-w-80 flex flex-col justify-center items-center'
            >
                <div className='w-80 rounded-md border shadow-xl bg-white'>
                    <div className='flex flex-1 justify-between px-3 py-2'>
                        <div className='flex flex-1 items-start'>
                            <div className='mr-1'>
                                {
                                    this.state.page.image &&
                                    <>
                                        <div className='flex flex-col justify-center h-10 w-10 rounded-full'>
                                            <img src={this.state.page.image} alt="" />
                                        </div>
                                    </>
                                }
                                {
                                    !this.state.page.image &&
                                    <div className='h-10 w-10 border rounded-full'></div>
                                }
                            </div>
                            <div className='flex flex-col text-sm font-semibold'>
                                {this.state.page.name ? this.state.page.name : "Page name"}
                                <div className='flex flex-col font-normal text-xs text-gray-700'>
                                    <span>453 followers</span>
                                    <span>Promoted</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex flex-0'><DotsHorizontalIcon className='w-5' /></div>
                            <div className="flex flex-1"></div>
                        </div>
                    </div>
                    <div className='px-3'>
                        <div className='text-sm pb-1'>
                            {
                                this.state.body !== "" ?
                                    <span dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="text-sm whitespace-pre-wrap">

                                    </span>
                                    : "Body..."
                            }
                            {
                                !this.state.read_more &&
                                this.renders.body().length > 125 &&
                                <span
                                    onClick={async () => {
                                        await this.promisedSetState({
                                            read_more: true
                                        });
                                    }} className="cursor-pointer font-semibold text-sm text-center text-gray-600">
                                    {" "}more
                                </span>
                            }
                        </div>
                    </div>
                    <div className='w-full'>
                        {this.renders.ratioCheck() &&
                            <div style={this.renders.ratioCheck() ? { height: 318 } : { height: "100%" }}>
                                {
                                    this.state.image &&
                                    <img src={this.state.image} alt={this.state.name} className="w-full h-full" />
                                }
                                {
                                    this.state.video &&
                                    <video src={this.state.video} autoplay={false} controls={true} muted loop alt={this.state.name} className="h-full w-full" ref="videoOne" />
                                }
                            </div>
                        }

                        {
                            !this.renders.ratioCheck() && (
                                <div style={{ height: 318 }}>
                                    {this.state.image && (
                                        <img src={this.state.image} alt={this.state.name} className="w-full h-full" />
                                    )}
                                    {this.state.video && (
                                        <video src={this.state.video} autoplay={false} controls={true} muted loop alt={this.state.name} className="h-full w-full" ref="videoTwo" />
                                    )}
                                    {this.state.pdf && (
                                        <iframe src={this.state.pdf} className="w-full h-full object-cover"></iframe>
                                    )}
                                </div>
                            )
                        }
                    </div>
                    <div className='flex justify-between w-full px-3 py-2 text-sm font-semibold border-b bg-blue-100'>
                        {/* <div className='flex flex-col flex-0 justify-center'> */}
                        <div className='w-3/5 flex flex-col flex-0 justify-center'>
                            {
                                !this.state.is_post &&
                                <span>{this.state.title ? this.functions.truncText(this.state.title, 70) : "Headline..."}</span>
                            }
                            <span className='font-normal text-xs text-gray-700'>{this.state.link ? this.functions.truncText(this.state.link.replace("https://", "").replace("http://", ""), 30) : "website.com"}</span>
                        </div>
                        {/* <div className='flex flex-col flex-1 items-end ml-1'> */}
                        {
                            this.state.cta &&
                            < div className='w-2/5 flex flex-col flex-1 items-end ml-1'>
                                <div
                                    style={{ width: "fit-content" }}
                                    className='border-2 border-linkedin-500 rounded-full px-2 text-linkedin-500 whitespace-no-wrap'>
                                    {this.state.cta ? this.state.cta.name : ''}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='flex justify-between space-x-4 items-center text-xms font-semibold px-3 py-2 text-gray-700'>
                        <div className='flex items-center'><ThumbUpIcon className='w-5 mr-1' />Like</div>
                        <div className='flex items-center'><AnnotationIcon className='w-5 mr-1' />Comment</div>
                        <div className='flex items-center'><RefreshIcon className='w-5 mr-1' />Share</div>
                    </div>
                </div>
            </div >
        )
    }
}

export default PreviewLinkedInImage;