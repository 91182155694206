class campaignTiktok {
    static validate(campaign) {
        let error = false;
        if ((campaign.budget_optimization && campaign.budget_optimization.value) || (campaign.budget_limit && campaign.budget_limit.value)) {
            if (campaign.budget_mode && !campaign.ios14_campaign) {
                if (campaign.budget_mode.value === "BUDGET_MODE_DAY" && (campaign.daily_budget === "" || parseInt(campaign.daily_budget) < 1 || isNaN(parseInt(campaign.daily_budget)))) {
                    error = true;
                } else if (campaign.budget_mode.value === "BUDGET_MODE_TOTAL" && (campaign.total_budget === "" || parseInt(campaign.total_budget) < 1 || isNaN(parseInt(campaign.total_budget)))) {
                    error = true;
                }
            }
        }
        if (campaign.objective && typeof campaign.objective === "object" && campaign.objective.value === "APP_PROMOTION" && campaign.ios14_campaign && (!campaign.app || campaign.app.id === 0)) {
            error = true;
        }
        return error;
    }
}

export { campaignTiktok }