import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, TrashIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon, ArrowDownIcon, FilterIcon, UserAddIcon, LightningBoltIcon, ExternalLinkIcon, PauseIcon, ArrowUpIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import SuccessModal from '../moduleFiles/successModal';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import { userRegister } from '../services/userRegister';
import internalChannels from '../assets/json/channels.json';
import { createChangelog } from '../services/createChangelog';
import TopNavigationAgentTiny from '../moduleFiles/topNavigationAgentTiny';
import ReactTooltip from 'react-tooltip';
import TableFilters from '../moduleFiles/tableFilters';
var moment = require('moment');

class ClientList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            manager: false,
            clients: [],
            loading: true,
            loading_partial: false,
            meta: {},
            user: {},
            previous: {},
            limit: 10,
            page: 1,
            sort: "spend",
            direction: "descending",
            create_client_step: "client",
            client_created: {},
            order_created: {},
            search: "",
            columns: [
                { name: 'name', value: 'name', min_width: '450px' },
                { name: 'channels', value: 'channels', noSort: true },
                { name: 'status', value: 'status', min_width: '150px' },
                { name: 'assigned agents', value: 'agents', min_width: '150px' },
                //{ name: 'Budget', value: 'budget', min_width: '200px' },
                { name: 'spend', value: 'spend', min_width: '200px' },
                { name: 'impressions', value: 'impressions', min_width: '200px' },
                { name: 'click', value: 'click', min_width: '200px' },
                { name: 'ctr', value: 'ctr', min_width: '200px' },
                { name: 'cpc', value: 'cpc', min_width: '200px' },
                { name: 'cpm', value: 'cpm', min_width: '200px' },
                { name: 'created', value: 'created', min_width: '100px' },
                { name: 'email', value: 'email', min_width: '350px' },
                { name: 'reference id', value: 'internalId', min_width: '150px' }
            ],
            client: {},
            showCompare: false,
            advancedFilters: [
                {
                    maxWidth: true,
                    type: "timeSpan",
                    placeholder: "This month",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "This month", value: "this_month" },
                        { id: 2, name: "Last month", value: "last_month" },
                        { id: 3, name: "Two months ago", value: "2_months" },
                        { id: 4, name: "Three months ago", value: "3_months" }
                    ],
                    selected: { id: 1, name: "This month", value: "this_month" },
                    loading: false
                },
                {
                    maxWidth: true,
                    type: "agents",
                    placeholder: "All",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "Assigned/Unassigned", value: "all" },
                        { id: 2, name: "Unassigned", value: "unassigned" },
                        { id: 3, name: "Assigned", value: "assigned" }
                    ],
                    selected: { id: 1, name: "Assigned/Unassigned", value: "all" },
                    loading: false
                }
            ],
            updated: false,
            metric: "spend",
            doughnuts: {},
            doughnut: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    enabled: false,
                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            tooltipEl.style.backgroundColor = "#FFFFFF";
                            tooltipEl.style.borderRadius = "5px";
                            tooltipEl.style.boxShadow = "0 3px 30px -5px rgba(0,0,0,.1)";
                            tooltipEl.style.fontWeight = "bold";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);

                            var innerHtml = '<thead>';

                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                var span = '<span style="' + style + '"></span>';
                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                cutoutPercentage: 55,
            },
            disable_chart: false,
            pause: null,
            claimData: null,
            selected_tags: [],
            pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true },
            openStepTitle: "Create client"
        };
    }

    componentWillMount() {
    }

    async componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page") || 1;
        let limit = params.get("limit") || this.state.limit;
        let sort = params.get("sortBy") || this.state.sort;
        let direction = params.get("orderBy") || this.state.direction;
        let search = params.get("search") || this.state.search;
        let agents = params.get("agents");
        let tags = params.getAll("tags[]");
        let active = params.get("active");
        let all = params.get("active");

        if (agents) {
            this.state.advancedFilters[1].selected = this.state.advancedFilters[1].options.filter(item => item.value === agents)[0];
        }

        if (active) {
            this.state.advancedFilters[0].selected = this.state.advancedFilters[0].options.filter(item => item.value === active)[0];
        }

        let buff = [];
        if (tags.length > 0) {
            tags.map(tag => {
                buff.push({ name: tag });
            })
        }

        await this.promisedSetState({
            page: +page,
            limit: +limit,
            sort: sort,
            direction: direction,
            ...(search && { search: search }),
            advancedFilters: this.state.advancedFilters,
            selected_tags: buff
        });

        this.functions.getUser();
        this.functions.client();
        this.functions.getClients(true);
        this.functions.listClientsCharts(true);
        //this.functions.listClientsChartsDaily(true);
    }

    // ON BACK BUTTON
    async componentWillReceiveProps(nextProps, nextContext) {
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page")
        let limit = params.get("limit")
        let sort = params.get("sortBy")
        let direction = params.get("orderBy")
        let search = params.get("search");
        let agents = params.get("agents");
        let tags = params.getAll("tags[]");
        let active = params.get("active");

        if (agents) {
            this.state.advancedFilters[1].selected = this.state.advancedFilters[1].options.filter(item => item.value === agents)[0];
        }

        if (active) {
            this.state.advancedFilters[0].selected = this.state.advancedFilters[0].options.filter(item => item.value === active)[0];
        }

        if (tags && tags.length > 0) {
            let selected_tags = [];
            tags.map(tag => {
                selected_tags.push({ name: tag });
            })
            this.state.selected_tags = selected_tags;
        }

        if (page && page !== this.state.page) {
            await this.promisedSetState({
                page: +page,
                limit: +limit,
                sort: sort,
                direction: direction,
                ...(search && { search: search }),
                advancedFilters: this.state.advancedFilters,
                selected_tags: this.state.selected_tags
            })
            this.functions.getClients();
        } else if (!window.location.pathname.includes("?limit=")) {
            await this.promisedSetState({
                page: 1,
                limit: 10,
                sort: this.state.sort,
                direction: this.state.direction,
                ...(search && { search: search }),
                advancedFilters: this.state.advancedFilters,
                selected_tags: this.state.selected_tags
            })
            this.functions.getClients();
        }
    }

    functions = {
        getRandomColorWithBlackText: () => {
            function getRandomInt(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }
            const r = getRandomInt(0, 255);
            const g = getRandomInt(0, 255);
            const b = getRandomInt(0, 255);
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            if (brightness < 128) {
                return this.functions.getRandomColorWithBlackText();
            }
            return `rgb(${r}, ${g}, ${b})`;
        },
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
            /*
            console.log(this.state.user, "USER")
            if (this.state.user && ((this.state.user.userRole && this.state.user.userRole === "admin" ) || this.state.user.manager)) {
                await this.promisedSetState({
                    manager: true
                });
            } else {
                await this.promisedSetState({
                    client: { id: 1, name: "My clients" }
                });
            }*/
            this.functions.getAgent();
        },
        getAgent: async () => {
            if (!(this.state.user.channels && this.state.user.channels.length > 0)) {
                try {
                    const response = await this.calls.getAgent();
                    const channels = [];
                    Object.keys(internalChannels).forEach((item, index) => {
                        if (response.data[item]) {
                            channels.push({
                                id: (index + 1),
                                channel: internalChannels[item].value,
                                name: internalChannels[item].name,
                                value: item,
                                authenticated: response.data[item].authenticated
                            })
                        }
                    })
                    this.state.user.channels = channels;
                    await this.promisedSetState({ user: this.state.user });
                } catch (error) { }
            }
        },
        getClients: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name + "___" + (tag.exclude ? "exclude" : "include");
                });
                let type = window.location.href.split('clients/')[1].split('?')[0];
                if (type !== "all") {
                    params = params + "&status=" + type;
                }
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.getClients(params);
                let clients = response.data.map(client => {
                    if (Array.isArray(client.agents)) {
                        client.agents.map(agent => {
                            agent.color = "rgb(69, 63, 241)"; //this.functions.getRandomColorWithBlackText();
                            return agent
                        });
                    }
                    return client;
                })
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ clients: clients, meta: response.meta, initial_data: response.data });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        listClientsCharts: async (init) => {
            await this.promisedSetState({ loading_chart: true });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name + "___" + (tag.exclude ? "exclude" : "include");
                });
                let type = window.location.href.split('clients/')[1].split('?')[0];
                if (type !== "all") {
                    params = params + "&status=" + type;
                }
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.listClientsCharts(params);
                await this.promisedSetState({
                    doughnuts: response.meta,
                    total: response.meta.total,
                    previous: response.meta.previous
                });
            } catch (error) { }
            await this.promisedSetState({ loading_chart: false });
        },
        listClientsChartsDaily: async (init) => {
            await this.promisedSetState({ loading_chart_daily: true });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name + "___" + (tag.exclude ? "exclude" : "include");
                });
                let type = window.location.href.split('clients/')[1].split('?')[0];
                if (type !== "all") {
                    params = params + "&status=" + type;
                }
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.listClientsChartsDaily(params);
                await this.promisedSetState({
                    chart: response.data
                });
            } catch (error) { }
            await this.promisedSetState({ loading_chart_daily: false });
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.clients.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ clients: this.state.clients });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ clients: this.state.clients.filter((item) => { return item.id !== temp.id }) });
                this.functions.handleCreateChangelog(temp.id, "removed")
            } catch (error) {
                this.state.clients.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ clients: this.state.clients });
            }
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
        },
        updateClient: async (id, data) => {
            this.state.clients.map((item) => { if (item.id === id) { item.loading = true } return item });
            const response = await this.calls.updateClient(id, data);
            if (response.status === 200) {
                let clients = this.state.clients.map(item => {
                    if (item.id === id) {
                        item = { ...item, ...data };
                    }
                    return item;
                });
                await this.promisedSetState({ clients: clients.filter((item) => { return item.id !== id }), updated: true, pause: null, claimData: null });
                this.functions.handleCreateChangelog(id, data.status);
                if (this.props.updateStatistics) {
                    this.props.updateStatistics();
                }
            }
            this.state.clients.map((item) => { if (item.id === id) { item.loading = false } return item });
            await this.promisedSetState({ clients: this.state.clients });
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
        },
        pauseOrArchive: async (id, data) => {
            await this.promisedSetState({ pause: null });
            this.state.clients.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ clients: this.state.clients });
            if (data.status && data.status == "disabled") {
                data.pause_campaigns = this.state.pause_all_campaigns.value;
            }
            try {
                await this.calls.updateClient(id, data);
                let clients = this.state.clients.map(item => {
                    if (item.id === id) {
                        item = { ...item, ...data };
                    }
                    return item;
                });
                await this.promisedSetState({ clients: clients.filter((item) => { return item.id !== id }), updated: true, pause: null });
                this.functions.handleCreateChangelog(id, data.status);
            } catch (error) { }
            this.state.clients.map((item) => { if (item.id === id) { item.loading = false } return item });
            await this.promisedSetState({ clients: this.state.clients });
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
            await this.promisedSetState({ clients: this.state.clients, pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true } });
        },
        claimClient: async () => {
            this.setState({
                claim_client_loader: true
            });
            let claim_channels = {};
            if (this.state.claimData.selectedChannels) {
                for (let key in this.state.claimData.selectedChannels) {
                    claim_channels[key] = { email: this.state.user.email, id: this.state.user.id };
                }
            }
            try {
                let response = await this.calls.updateClient(this.state.claimData.id, {
                    claim_agent: { email: this.state.user.email, id: this.state.user.id },
                    claim_orders: this.state.claimData.orders,
                    remove_agents: this.state.claimData.remove_agents,
                    claim_channels: claim_channels
                });
                this.state.clients = this.state.clients.map((item) => {
                    if (item.id == this.state.claimData.id) {
                        item.agents = response.data.agents;
                    }
                    return item;
                });
                this.setState({
                    clients: this.state.clients,
                    claim_client_loader: false,
                    claimData: null
                });
            } catch (error) {
                this.setState({
                    claim_client_loader: false
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        handleSetTags: async (value) => {
            await this.promisedSetState({
                selected_tags: value
            });
            this.functions.getClients();
            this.functions.listClientsCharts();
            //this.functions.listClientsChartsDaily();
        },
        history: async () => {
            try {
                window.history.pushState({
                    page: this.state.page,
                    limit: this.state.limit,
                    sort: this.state.sort,
                    direction: this.state.direction,
                    ...(this.state.search !== "" && { search: this.state.search }),
                    client: JSON.stringify(this.state.client),
                    agents: this.state.advancedFilters[1].selected ? this.state.advancedFilters[1].selected : { id: 1, name: "Assigned/Unassigned", value: "all" },
                    active: this.state.advancedFilters[0].selected ? this.state.advancedFilters[0].selected : { id: 1, name: "Active", value: "active" },
                    tags: this.state.selected_tags ? this.state.selected_tags : []
                }, "",
                    ("?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + (this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.selected_tags.length > 0 ? this.state.selected_tags.map(item => "&tags[]=" + item.name).join('') : "") + "&agents=" + this.state.advancedFilters[1].selected.value + "&active=" + this.state.advancedFilters[0].selected.value)
                )
            } catch (error) {
                console.log(error)
            }
        },
        handleCreateChangelog: async (id, action) => {
            try {
                let client = null;
                client = this.state.initial_data.find(item => item.id === id)
                this.state.updated_data = client
                this.state.updated_data = { ...this.state.updated_data, status: action };
                if (action !== "removed") {
                    await this.promisedSetState({
                        updated_data: this.state.updated_data
                    })
                }
                createChangelog({ initial_data: client, updated_data: this.state.updated_data, client: id, order: null, item: id, level: "client" });
                if (action !== "removed") {
                    client = null;
                    await this.promisedSetState({
                        updated_data: {}
                    })
                }
            } catch (error) { }

        },
        clientUpdated: async (client) => {
            await this.promisedSetState({
                page: 1,
                client: client
            });
            this.functions.getClients();
            this.functions.listClientsCharts();
        }
    };

    calls = {
        getClients: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientsWithPerformance?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&performance=true" + params + "&include_agents=true";
            return apiRegister.call(options, url);
        },
        listClientsChartsDaily: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientsChartsDaily?chart=1" + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + params;
            return apiRegister.call(options, url);
        },
        listClientsCharts: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientsCharts?chart=1" + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + params;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeClient?client=" + id;
            return apiRegister.call(options, url);
        },
        updateClient: (id, data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateClient?client=" + id;
            return apiRegister.call(options, url);
        },
        getAgent: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgent";
            return apiRegister.call(options, url);
        }
    }

    renders = {
        comparedValue(org_number, preceding_number) {
            try {
                if (+preceding_number > 0) {
                    let increase = +org_number - +preceding_number;
                    let value = (+increase / +preceding_number) * 100;
                    return value.toFixed(2);;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        metricGoal(key) {
            if (key == "impressions" || key == "clicks" || key == "spend" || key == "ctr" || key == "budget") {
                return "highest";
            } else if (key == "cpc" || key == "cpm") {
                return "lowest";
            } else {
                return null;
            }
        },
        convertToDecimal: (value) => {
            try {
                value = +value;
                return isNaN(value.toFixed(2)) ? null : value.toFixed(2);
            } catch (error) {
                if (value) {
                    return value;
                } else {
                    return null
                }
            }
        },
        addCommas(nStr) {
            try {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ' ' + '$2');
                }
                return x1 + x2;
            } catch (error) {
                return nStr;
            }
        },
        pauseAllCampaigns: (value) => {
            try {
                if (value) {
                    return value;
                } else if (this.state.pause_all_campaigns) {
                    return this.state.pause_all_campaigns;
                } else {
                    return { id: 1, name: "Yes, pause everything", value: true }
                }
            } catch (error) {
                return { id: 0, name: "Select ..." }
            }
        },
        channelWidth: (data, index, pretty) => {
            let total = 0;
            let channel_value = 0;
            try {
                data.datasets[0].data.map((value, inner_index) => {
                    if (index == inner_index) {
                        channel_value = value;
                    }
                    total += +value;
                });
            } catch (error) { }
            return pretty ? +(channel_value / total * 100).toFixed(2) : +(channel_value / total * 100);
        },
        channelValue: (data, index) => {
            let channel_value = 0;
            try {
                data.datasets[0].data.map((value, inner_index) => {
                    if (index == inner_index) {
                        channel_value = value;
                    }
                });
            } catch (error) { }
            return this.renders.addCommas(channel_value);
        },
        createClientButtonText: () => {
            let text = "";
            if (this.state.create_client_step == "client") {
                text = "Create client";
            } else if (this.state.create_client_step == "connections") {
                text = "Next";
            } else if (this.state.create_client_step == "report") {
                text = "Next";
            } else if (this.state.create_client_step == "order") {
                text = "Create";
            } else if (this.state.create_client_step == "agents") {

            }
            return text;
        },
        clientsStatus: () => {
            let string = null;
            try {
                if (window.location.pathname.indexOf("clients/active") !== -1) {
                    string = "Active";
                }
                if (window.location.pathname.indexOf("clients/upcoming") !== -1) {
                    string = "Upcoming";
                }
                if (window.location.pathname.indexOf("clients/paused") !== -1) {
                    string = "Paused";
                }
                if (window.location.pathname.indexOf("clients/ended") !== -1) {
                    string = "Ended";
                }
                if (window.location.pathname.indexOf("clients/archived") !== -1) {
                    string = "Archived";
                }
                if (window.location.pathname.indexOf("clients/all") !== -1) {
                    string = "All";
                }
            } catch (error) { }
            return string;
        }
    }


    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/*HEADER*/}
                {
                    !this.state.loading &&
                    <div className="w-full flex items-center p-4 pb-0 justify-center">
                        <div className="flex flex-1 flex-col relative">
                            <div className="text-2xl font-bold">
                                {this.renders.clientsStatus() && <span className="mr-2">{this.renders.clientsStatus()}</span>}Clients
                            </div>
                        </div>
                        <div className="flex">
                            {
                                this.state.user && this.state.user.userRole && this.state.user.userRole === "sales" ? [] : [
                                    { text: "Create new", icon: IdentificationIcon, value: "new", loading: false, show: !this.state.loading }
                                ].map((item) => {
                                    return (
                                        <button
                                            onClick={() => {
                                                if (item.value === "new") {
                                                    this.setState({
                                                        create_client: true
                                                    })
                                                }
                                            }}
                                            className={(!item.disabled ? (item.red ? "bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white" : "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white") : " cursor-not-allowed bg-gray-100 text-gray-400") + " inline-flex items-center justify-center shadow relative rounded-md h-10 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                        >
                                            <span>{item.text}</span>
                                            {
                                                item.icon &&
                                                <item.icon className="ml-2 h-5 w-5" />
                                            }
                                            {
                                                item.loading &&
                                                <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading clients ...</div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                    onSetTags={async (value) => {
                        this.functions.handleSetTags(value)
                    }}
                    showTagsDropdown={true}
                />

                {/*Pause MODAL*/}
                <WarningModalTailwind
                    open={!!this.state.pause}
                    title={this.state.pause ? this.state.pause.statusName : ""}
                    description={'Are you sure you want to ' + (this.state.pause ? this.state.pause.statusName : "") + '? All connected orders and campaigns will be paused.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={this.state.pause ? this.state.pause.statusName : ""}
                    showInput={false}
                    onClose={async (value) => await this.promisedSetState({ pause: null, pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true } })}
                    onSubmit={(value) => {
                        if (this.state.pause && this.state.pause.status) {
                            this.functions.pauseOrArchive(this.state.pause.itemId, { status: this.state.pause.status })
                        }
                    }}
                    loading={this.state.loading}
                    dropDown={true}
                    dropDownOptions={[{ id: 1, name: "Yes, pause everything", value: true }, { id: 2, name: "No, don't pause everything", value: false }]}
                    dropDownSelectedRender={this.renders.pauseAllCampaigns()}
                    onDropDownSelect={async (value) => {
                        this.state.pause_all_campaigns = value
                        await this.promisedSetState({ pause_all_campaigns: this.state.pause_all_campaigns });
                    }}
                />

                {/*CLAIM CLIENT*/}
                <SlideoutTailwind
                    title={"Claim Client"}
                    submitButtonText={"Update"}
                    secondaryButton={false}
                    open={!!this.state.claimData}
                    loader={this.state.claim_client_loader}
                    onCancel={() => {
                        this.promisedSetState({ claimData: null });
                    }}
                    onSubmit={async () => {
                        this.functions.claimClient();
                    }}
                >
                    {
                        this.state.claimData &&
                        <div>
                            <div className={`flex flex-1 text-sm items-center pt-0 py-4 justify-center border-b`}>
                                <div className='text-sm font-medium'>Claim all beloning orders</div>
                                <div className="flex flex-1"></div>
                                <div className="relative overflow-hidden">
                                    <SwitchTailwind
                                        value={this.state.claimData.orders}
                                        onSwitch={async () => {
                                            this.state.claimData.orders = !this.state.claimData.orders;
                                            this.setState({
                                                claimData: this.state.claimData
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={`flex flex-1 text-sm items-center py-4 justify-center border-b`}>
                                <div className='text-sm font-medium'>Remove other agents</div>
                                <div className="flex flex-1"></div>
                                <div className="relative overflow-hidden">
                                    <SwitchTailwind
                                        value={this.state.claimData.remove_agents}
                                        onSwitch={async () => {
                                            this.state.claimData.remove_agents = !this.state.claimData.remove_agents;
                                            this.setState({
                                                claimData: this.state.claimData
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                this.state.user.channels &&
                                this.state.user.channels.filter(item => { return item.authenticated && this.state.claimData[item.channel] }).length > 0 &&
                                <div className="rounded-lg mt-4 bg-gray-50 border">
                                    {
                                        this.state.user.channels.filter(item => { return item.authenticated && this.state.claimData[item.channel] }).length > 0 &&
                                        <div className="border-b p-4">
                                            <div className="text-sm font-medium">
                                                Update default agent on channel connections with new agent <span className="underline">optional</span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.user.channels.filter(item => { return item.authenticated && this.state.claimData[item.channel] }).map((item, index) => {
                                            return (
                                                <div className={(index > 0 ? "border-t" : "") + " flex flex-1 text-sm items-center justify-center p-4"}>
                                                    <div className='text-sm font-medium'>{item.name}</div>
                                                    <div className="flex flex-1"></div>
                                                    <div className="relative overflow-hidden">
                                                        <SwitchTailwind
                                                            value={this.state.claimData.selectedChannels && this.state.claimData.selectedChannels[item.channel]}
                                                            onSwitch={async () => {
                                                                if (!this.state.claimData.selectedChannels) {
                                                                    this.state.claimData.selectedChannels = {};
                                                                }
                                                                if (this.state.claimData.selectedChannels[item.channel]) {
                                                                    delete this.state.claimData.selectedChannels[item.channel];
                                                                } else {
                                                                    this.state.claimData.selectedChannels[item.channel] = true;
                                                                }
                                                                await this.promisedSetState({ claimData: this.state.claimData });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }

                </SlideoutTailwind>

                {/*CREATE CLIENT*/}
                <SlideoutTailwind
                    openStep={this.state.openStep}
                    steps={this.state.steps}
                    white={true}
                    noPadding={true}
                    noPaddingTop={true}
                    title={this.state.openStepTitle}
                    whiteSubmit={true}
                    disabled={this.state.create_client_step == "client" && !this.state.client_created.id}
                    submitButtonText={"Done"}
                    medium={true}
                    open={this.state.create_client ? true : false}
                    loader={this.state.create_client_loading}
                    onCancel={() => {
                        this.setState({
                            openStepTitle: "Create client",
                            create_client: false,
                            client_created: {},
                            order_created: {}
                        });
                    }}
                    onSubmit={async () => {
                        if (this.state.client_created.id) {
                            await this.promisedSetState({ sort: "created", page: 1 });
                            this.functions.getClients();
                        }
                        await this.promisedSetState({ create_client: false, openStepTitle: "Create client", client_created: {}, order_created: {} });
                    }}
                >
                    {
                        this.state.create_client &&
                        <CreateClient
                            ref="CreateClient"
                            setLoading={async (value) => {
                                await this.promisedSetState({ create_client_loading: value });
                            }}
                            onStep={async (step) => {
                                await this.promisedSetState({ create_client_step: step })
                            }}
                            onClient={async (client) => {
                                await this.promisedSetState({ client_created: client });
                            }}
                            onOrder={async (order) => {
                                await this.setState({ order_created: order });
                            }}
                            onDone={async () => {
                                await this.promisedSetState({ create_client: false, client_created: {}, order_created: {} });
                                await this.promisedSetState({ sort: "created", page: 1 });
                                this.functions.getClients();
                            }}
                            updateSteps={async (steps) => {
                                await this.promisedSetState({ steps: steps })
                            }}
                            updateStep={async (openStep) => {
                                await this.promisedSetState({ openStep: openStep });
                            }}
                            updateTitle={async (openStepTitle) => {
                                await this.promisedSetState({ openStepTitle: openStepTitle });
                            }}
                        />
                    }
                </SlideoutTailwind>

                <SuccessModal
                    open={!!this.state.updated}
                    title="Updated"
                    button="OK"
                    onClose={() => {
                        this.setState({
                            updated: false
                        })
                    }}
                />

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {
                            <div className="grid grid-cols-5 mb-4 bg-white shadow rounded-lg divide-x">
                                {
                                    ["impressions", "clicks", "spend", "CTR", "CPC"].map((metric) => {
                                        return (
                                            <div
                                                onMouseEnter={() => {
                                                    if (metric !== "budget" && metric !== "spend_vs_budget") {
                                                        this.setState({
                                                            show_metric: metric
                                                        })
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        show_metric: null
                                                    })
                                                }}
                                                className="col-span-1 relative">
                                                {
                                                    this.state.show_metric == metric &&
                                                    <div className="py-2 border shadow px-4 absolute mt-20 w-full overflow-hidden z-70 bg-white rounded-lg">
                                                        <ul>
                                                            {
                                                                this.state.doughnuts[metric.toLowerCase()] &&
                                                                Array.isArray(this.state.doughnuts[metric.toLowerCase()].labels) &&
                                                                this.state.doughnuts[metric.toLowerCase()].labels.map((label, index) => {
                                                                    let show_channel = this.renders.channelWidth(this.state.doughnuts[metric.toLowerCase()], index) > 0;
                                                                    if (label == "budget" || label == "spend vs budget") {
                                                                        show_channel = false;
                                                                    }
                                                                    return (
                                                                        <div className={"text-xs py-2"}>
                                                                            <div><span className="font-semibold capitalize">{label}</span> {this.renders.channelWidth(this.state.doughnuts[metric.toLowerCase()], index, true)}%</div>
                                                                            <div>
                                                                                {this.renders.channelValue(this.state.doughnuts[metric.toLowerCase()], index)}
                                                                            </div>
                                                                            <div className={"h-1 rounded-md w-full bg-gray-100 mt-1 overflow-hidden"}>
                                                                                <div style={{ width: this.renders.channelWidth(this.state.doughnuts[metric.toLowerCase()], index) + "%" }} className={"bg-" + label + "-500 overflow-hidden h-full"}></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                <div
                                                    onClick={async () => {
                                                        await this.promisedSetState({
                                                            disable_chart: true,
                                                            metric: metric
                                                        })
                                                        await this.functions.sleep(1000);
                                                        await this.promisedSetState({
                                                            disable_chart: false,
                                                        })
                                                    }}
                                                    className={"p-3 relative overflow-hidden flex w-full h-full cursor-pointer"}>
                                                    {
                                                        this.state.loading_chart &&
                                                        <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    <div className="data--item__donut relative text-center items-center w-full">
                                                        {
                                                            !this.state.loading_chart &&
                                                            !(Array.isArray(this.state.advancedFilters) && this.state.advancedFilters.length > 0 && this.state.advancedFilters[0].selected && this.state.advancedFilters[0].selected.value == "this_month") &&
                                                            this.state.previous && this.state.total &&
                                                            <div style={{ top: "4.5px", right: "-2px" }} className="text-xxs absolute font-medium flex flex-col items-end justify-end">
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <span className="text-green-500 relative">
                                                                        {this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()])}%
                                                                    </span>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <span className="text-red-500">{this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()])}%</span>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <div className="bg-green-100 text-green-500 border-green-500 h-3 w-3 mr-1 rounded-full flex justify-center items-center">
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0 &&
                                                                            <ArrowUpIcon className={"w-2"} />
                                                                        }
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0 &&
                                                                            <ArrowDownIcon className={"w-2"} />
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <div className="bg-red-100 text-red-500 border-red-500 h-3 w-3 mr-1 rounded-full flex justify-center items-center">
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0 &&
                                                                            <ArrowUpIcon className={"w-2"} />
                                                                        }
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0 &&
                                                                            <ArrowDownIcon className={"w-2"} />
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        <div className="">
                                                            <div className={"font-bold"}>{this.state.total && !isNaN(this.state.total[metric.toLowerCase()]) ? this.renders.addCommas(this.state.total[metric.toLowerCase()]) : "0"}{metric == "spend_vs_budget" ? "%" : ""}</div>
                                                            <div className="text-xs text-gray-700">{metric !== "spend_vs_budget" ? metric : "Spend/Budget"} {(metric == "CTR" || metric == "CPC") ? "Avg." : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        <div className={"shadow bg-white w-full flex flex-col overflow-hidden h-full rounded-lg"}>

                            <TableFilters
                                showEnableStats={true}
                                onAdvancedFilter={async (value, type) => {
                                    this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                        if (item.type === type) {
                                            item.selected = value;
                                        }
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        page: 1,
                                        limit: 10,
                                        advancedFilters: this.state.advancedFilters,
                                    });
                                    this.functions.getClients();
                                    this.functions.listClientsCharts();
                                }}
                                onLimit={async (value) => {
                                    await this.promisedSetState({
                                        page: 1,
                                        limit: value
                                    })
                                    this.functions.getClients();
                                }}
                                onNext={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.history()
                                    this.functions.getClients();
                                }}
                                onPrevious={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.history()
                                    this.functions.getClients();
                                }}
                                onSearch={async (value) => {
                                    await this.promisedSetState({
                                        loading_partial: true,
                                        search: value
                                    });
                                    setTimeout(async () => {
                                        if (value === this.state.search) {
                                            await this.promisedSetState({
                                                page: 1
                                            });
                                            this.functions.getClients(false, value);
                                        }
                                    }, 400);
                                }}
                                limit={this.state.limit}
                                page={this.state.page}
                                total={this.state.meta ? this.state.meta.total : 0}
                                showPaginaton={!this.state.loading}
                                showPaginationSearch={!this.state.loading}
                                showAdvanced={!this.state.loading}
                                advancedFilter={true}
                                advancedFilters={this.state.advancedFilters}
                                filters={[]}
                                hideUserDropdown={true}
                                breadcrumb={"Orders"}
                                buttons={[]}
                                buttonTypes={{}}
                                buttonLoaders={{}}
                                show_table_settings={false}
                                onOpenTableSettings={async () => {
                                    await this.promisedSetState({ show_table_settings: true });
                                }}
                                onSetTags={async (value) => {
                                    this.functions.handleSetTags(value)
                                }}
                                url_tags={this.state.selected_tags}
                                showTagsDropdown={true}
                                tagType={"client"}
                                searchInputValue={this.state.search}
                                search={this.state.search}
                                client={{}}
                            />

                            {/*NO ORDERS*/}
                            {
                                this.state.clients.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No clients available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.clients.length > 0 &&
                                <div className="relative h-full flex flex-1 border-t">
                                    <div className="overflow-auto absolute table-overflow w-full h-full">

                                        {/*LOADER PARTIAL*/}
                                        {
                                            this.state.loading_partial &&
                                            <div className="text-center absolute top-0 bottom-0 z-55 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        <table className="w-full relative divide-y divide-gray-300 border-gray-300">
                                            <thead className="sticky z-60 bg-white top-0">
                                                <tr>
                                                    <th style={{ maxWidth: "50px", width: "50px" }} scope="col" className='bg-gray-100 border-r bg-opacity-100'></th>
                                                    {this.state.columns.filter(col => { return !col.disabled }).map((item, index) => {
                                                        return (
                                                            <th
                                                                onClick={async () => {
                                                                    if (!item.noSort) {
                                                                        await this.promisedSetState({
                                                                            page: 1,
                                                                            sort: item.value,
                                                                            direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                        });
                                                                        this.functions.getClients();
                                                                    }
                                                                }}
                                                                style={index === 0 ? { left: 0, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                scope="col"
                                                                className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-100 cursor-pointer" : (!item.noSort ? "bg-white hover:bg-gray-100 hover:bg-opacity-100 cursor-pointer" : "")) + " border-r px-3 py-3 border-gray-300 bg-gray-100 text-left text-xxs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider " + (index === 0 && " sticky ")}
                                                            >
                                                                <div className="flex flex-row items-center justify-between">
                                                                    <div className="mr-4">{item.name}</div>
                                                                    {
                                                                        !item.noSort &&
                                                                        <div className="flex justify-start flex-col">
                                                                            <ArrowDownIcon className={`h-3 w-3 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-gray-300">
                                                {
                                                    this.state.clients.map((item, index) => {
                                                        return (
                                                            <Fragment>
                                                                <tr
                                                                    onMouseEnter={() => {
                                                                        item.hover = true;
                                                                        this.setState({
                                                                            clients: this.state.clients
                                                                        })
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        item.hover = false;
                                                                        this.setState({
                                                                            clients: this.state.clients
                                                                        })
                                                                    }}
                                                                    className="border-b" key={item.id}>
                                                                    <td className={(item.hover ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " px-4 border-r border-gray-300 overflow-visible"}>
                                                                        <Menu as="div" className="">
                                                                            <div>
                                                                                <Menu.Button className={"cursor-pointer bg-gray-50 flex relative w-6 h-6 justify-center items-center rounded border border-gray-300 text-xs font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                    <ChevronDownIcon className="w-4" aria-hidden="true" />
                                                                                    {
                                                                                        item.loading &&
                                                                                        <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                        </div>
                                                                                    }
                                                                                </Menu.Button>
                                                                            </div>
                                                                            <Transition
                                                                                as={Fragment}
                                                                                enter="transition ease-out duration-100"
                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                enterTo="transform opacity-100 scale-100"
                                                                                leave="transition ease-in duration-75"
                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                leaveTo="transform opacity-0 scale-95"
                                                                            >
                                                                                <Menu.Items className={`border absolute z-60 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                    <div className="py-1">
                                                                                        {
                                                                                            ["Claim client", "Open", "Open new tab", "Archive", "Activate", "Pause", "Delete"].filter((option) => {
                                                                                                if (item.status === "active") {
                                                                                                    return option !== "Delete" && option !== "Activate" && option !== "Archive"
                                                                                                } else if (item.status === "archived") {
                                                                                                    return option !== "Archive" && option !== "Pause"
                                                                                                } else if (item.status === "unassigned") {
                                                                                                    return option !== "Delete" && option !== "Archive"
                                                                                                } else if (item.status === "disabled") {
                                                                                                    return option !== "Pause" && option !== "Delete"
                                                                                                } else {
                                                                                                    return true;
                                                                                                }
                                                                                            }).map((option) => {
                                                                                                return (
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    if (option === "Open") {
                                                                                                                        this.functions.history()
                                                                                                                        this.props.history.push('/v2/client/' + item.id);
                                                                                                                    } else if (option === "Delete" && item.status == "archived") {
                                                                                                                        this.setState({
                                                                                                                            remove: item
                                                                                                                        });
                                                                                                                    } else if (option === 'Pause' && item.status == "active") {
                                                                                                                        this.setState({
                                                                                                                            pause: { itemId: item.id, status: 'disabled', statusName: "Pause" }
                                                                                                                        });
                                                                                                                    } else if (option === 'Archive' && item.status == "disabled") {
                                                                                                                        this.functions.pauseOrArchive(item.id, { status: "archived" });
                                                                                                                    } else if (option === 'Activate' && item.status !== "active") {
                                                                                                                        this.functions.updateClient(item.id, { status: 'active' });
                                                                                                                    } else if (option == "Open new tab") {
                                                                                                                        if (window.location.hostname === 'localhost') {
                                                                                                                            window.open("http://localhost:5001/v2/client/" + item.id);
                                                                                                                        } else if (window.location.hostname === 'app.adcredo.io') {
                                                                                                                            window.open("https://app.adcredo.io/v2/client/" + item.id);
                                                                                                                        } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                                                                            window.open("https://dev.adcredo.io/v2/client/" + item.id);
                                                                                                                        }
                                                                                                                    } else if (option === 'Claim client') {
                                                                                                                        this.setState({
                                                                                                                            claimData: item
                                                                                                                        })
                                                                                                                    }
                                                                                                                }}
                                                                                                                className={cn(
                                                                                                                    active ? (option !== "Delete" ? 'bg-gray-50 text-gray-900' : '') : (option !== "Delete" ? 'text-gray-700' : ''),
                                                                                                                    option === "Delete" && item.disabled ? 'bg-red-500 text-white' : '',
                                                                                                                    'px-4 py-2 text-sm relative items-center flex flex-row cursor-pointer',
                                                                                                                    option == "Claim client" ? 'bg-purple-100 text-purple-500' : ''
                                                                                                                )}
                                                                                                            >
                                                                                                                <div className="flex flex-1">
                                                                                                                    {option}
                                                                                                                </div>
                                                                                                                {
                                                                                                                    option === "Delete" && item.disabled &&
                                                                                                                    <TrashIcon class="w-4 h-4" />
                                                                                                                }
                                                                                                                {
                                                                                                                    option == "Claim client" &&
                                                                                                                    <UserAddIcon className="w-5" />
                                                                                                                }
                                                                                                                {
                                                                                                                    option == "Open new tab" &&
                                                                                                                    <ExternalLinkIcon className="w-5" />
                                                                                                                }
                                                                                                                {
                                                                                                                    option == "Pause" &&
                                                                                                                    <PauseIcon className="w-5" />
                                                                                                                }
                                                                                                            </div>

                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </Menu.Items>
                                                                            </Transition>
                                                                        </Menu>
                                                                    </td>
                                                                    <td onClick={() => {
                                                                        this.functions.history()
                                                                        this.props.history.push('/v2/client/' + item.id);
                                                                    }} style={{ zIndex: 0 }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " sticky left-0 border-r text-left px-3 py-4 whitespace-no-wrap font-semibold cursor-pointer hover:text-purple-500 items-center text-xms border-gray-300"}>
                                                                        <div className="flex flex-row items-center">
                                                                            <div>
                                                                                <div className="h-6 w-6 overflow-hidden rounded-md flex justify-center bg-gray-100 items-center">
                                                                                    <img src={item.logo} className="bg-cover" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="ml-2"> {item.name ? item.name : "-"}</div>
                                                                            {
                                                                                Array.isArray(item.tags) && item.tags.length > 0 &&
                                                                                <div class="flex flex-row whitespace-no-wrap ml-2">
                                                                                    {
                                                                                        item.tags.map((item) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <div className="text-xxs text-gray-900 font-medium mr-1 py-0.5 rounded-full bg-gray-100 border px-2">{item.name}</div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        <td className={((this.state.sort === 'channels' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 items-center text-xms  border-gray-300"}>
                                                                            <div className="flex flex-row pl-2">
                                                                                {
                                                                                    Array.isArray(item.channels) &&
                                                                                    item.channels.map((channel) => {
                                                                                        if (channel.value === "facebook") {
                                                                                            return (
                                                                                                <div
                                                                                                    className="w-7 h-7 bg-facebook-500 rounded-full p-1.5 border-2 border-white -ml-2 relative">
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>

                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "linkedin") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-linkedin-500 rounded-full p-1.5 border-2 border-white -ml-2 relative'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>

                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "google") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-google-500 rounded-full p-1.5 border-2 border-white -ml-2 relative'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>

                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "tiktok") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-black rounded-full p-1.5 border-2 border-white -ml-2 relative'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>

                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "twitter") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-twitter-500 rounded-full p-1.5 border-2 border-white -ml-2 relative'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>

                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "adform") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-adform-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "google_analytics_4") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-googleanalytics-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "google_analytics") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-googleanalytics-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div></div>
                                                                                            )
                                                                                        } else if (channel.value === "snapchat") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-snapchat-500 rounded-full p-1.5 border-2 border-white -ml-2 relative'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(https://s3.amazonaws.com/bitmoji-sdk-images/logo-snapchat.svg)" }}></div>

                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "bing") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-bing-500 rounded-full p-1.5 border-2 border-white -ml-2 relative'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "dv360") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-dv360-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "cm360") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-cm360-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/cm360-logo.png') + ")" }}></div>
                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "bidtheatre") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-bidtheatre-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                                                </div>
                                                                                            )
                                                                                        } else if (channel.value === "google_search_console") {
                                                                                            return (
                                                                                                <div
                                                                                                    className={'w-7 h-7 bg-google-search-console-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/google_search_console.png') + ")" }}></div>
                                                                                                </div>
                                                                                            )
                                                                                        } else {
                                                                                            return (<></>)
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    <td className={((this.state.sort === 'status' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 font-medium py-2 items-center border-gray-300"}>
                                                                        <span
                                                                            className={cn("px-2 py-1 inline-flex text-xxms font-semibold rounded-md",
                                                                                {
                                                                                    ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                    ["bg-red-100 text-orange-500 bg-opacity-50"]: item.status === "disabled",
                                                                                    ["bg-gray-100 text-gray-500 bg-opacity-50"]: item.status === "archived"
                                                                                })
                                                                            }>
                                                                            {item.status === "disabled" ? "paused" : item.status}
                                                                        </span>
                                                                    </td>
                                                                    <td className={((this.state.sort === 'agents' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                        {
                                                                            Array.isArray(item.agents) && item.agents.length > 0 &&
                                                                            <div className="flex flex-row pl-2"
                                                                                data-tip='' data-for={item._id + "_agents"} key={item._id + "_agents"}>
                                                                                {
                                                                                    item.agents.map(agent => {
                                                                                        return (
                                                                                            <div
                                                                                                className="w-7 h-7 rounded-full p-1.5 border-2 border-white -ml-2"
                                                                                                style={{ backgroundColor: agent.color ? agent.color : "rgb(69, 63, 241)" }}>
                                                                                                <div className="text-white flex w-full h-full uppercase items-center justify-center">
                                                                                                    {agent.email ? agent.email[0] : ""}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <ReactTooltip effect='solid' id={item._id + "_agents"}>
                                                                                    {
                                                                                        item.agents.map(agent => {
                                                                                            return (
                                                                                                <div>{agent.email}</div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ReactTooltip>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            ((!Array.isArray(item.agents)) || (Array.isArray(item.agents) && item.agents.length < 1)) &&
                                                                            <span
                                                                                className="px-2 py-1 inline-flex text-xxs font-semibold rounded-md bg-orange-100 text-orange-500 bg-opacity-50 border-orange-500 ">
                                                                                Unassigned
                                                                            </span>
                                                                        }
                                                                    </td>
                                                                    {
                                                                        false &&
                                                                        <td className={((this.state.sort === 'budget' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 whitespace-no-wrap font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.budget) ? this.renders.convertToDecimal(item.budget) : "0"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'spend' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 whitespace-no-wrap font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.total_spend) ? this.renders.convertToDecimal(item.total_spend) : "0"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'impressions' || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {item.total_impressions ? item.total_impressions : "0"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'click' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 whitespace-no-wrap font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {item.total_clicks ? item.total_clicks : "0"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'ctr' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 whitespace-no-wrap font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.total_ctr)}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'cpc' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 whitespace-no-wrap font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.total_cpc)}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'cpm' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 whitespace-no-wrap font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.total_cpm)}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'created' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 whitespace-no-wrap font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {item.created ? moment(item.created).format("YYYY-MM-DD") : "-"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'email' || item.hover) ? "bg-gray-50" : "") + " border-r px-3  whitespace-no-wrap font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {item.email ? item.email : "-"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        <td className={((this.state.sort === 'internalId' || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                            {item.internalId ? item.internalId : "-"}
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                }
            </div >
        )
    }
}

export default ClientList;