import React from 'react';
import { createPortal } from 'react-dom';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html'
import { Editor, EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import FeatherIcon from 'feather-icons-react';
import { DotsVerticalIcon, MinusIcon, PlusIcon, XIcon } from '@heroicons/react/outline';

export default class AdvancedReportCellText extends React.Component {

    constructor(props) {
        super(props);
        this.fonts = {}
        this.customStyleMap = {};
        this.stateToHTMLOptions = {
            inlineStyles: {}
        };

        this.state = {
            editorState: this.initialState(this.props.data),
            htmlState: this.props.data ? this.props.data : '',
            textAlign: this.props.source ? this.props.source.textAlign : 'LEFT',
            verticalAlign: this.props.source ? this.props.source.verticalAlign : false,
            transparent_cells: this.props.transparent_cells ? this.props.transparent_cells : false,
            report_background_color: this.props.report_background_color,
            cell: this.props.source ? this.props.source : {},
            page: this.props.page ? this.props.page : {},
            show: true
        };
        this.editorPopupRef = React.createRef();
        this.onChange = (editorState) => {
            const htmlState = stateToHTML(this.state.editorState.getCurrentContent(), this.stateToHTMLOptions);
            this.setState({
                editorState,
                htmlState: htmlState
            });
            this.props.onInput(htmlState, this.state.textAlign, this.state.verticalAlign);
        };
        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleHeadingType = this._toggleHeadingType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    async componentDidMount() {
        await this.promisedSetState({
            editorState: this.initialState(this.props.data),
            htmlState: this.props.data ? this.props.data : '',
            transparent_cells: this.props.transparent_cells ? this.props.transparent_cells : false,
            report_background_color: this.props.report_background_color,
            cell: this.props.source ? this.props.source : {},
            page: this.props.page ? this.props.page : {},
            fonts: this.props.fonts ? this.props.fonts : [],
            show_component: this.props.show_component,
            base_font: this.props.base_font
        });
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.promisedSetState({
            transparent_cells: nextProps.transparent_cells ? nextProps.transparent_cells : false,
            report_background_color: nextProps.report_background_color,
            cell: nextProps.source ? nextProps.source : {},
            page: nextProps.page ? nextProps.page : {},
            base_font: nextProps.base_font
        });

    }

    functions = {
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }

    loadFontSizes = () => {
        for (let i = 4; i <= 100; i = i + 2) {
            this.customStyleMap[`FONTSIZE_${i}`] = { fontSize: `${i}px` };
            this.stateToHTMLOptions.inlineStyles[`FONTSIZE_${i}`] = { style: { fontSize: `${i}px` } }
        }
    }

    loadFonts = () => {
        [
            { label: 'Poppins', value: 'poppins' },
            { label: "Arial", value: "arial" },
            { label: "Arial Black", value: "arialblack" },
            { label: "Comic Sans MS", value: "comicsansms" },
            { label: "Courier New", value: "couriernew" },
            { label: "Georgia", value: "georgia" },
            { label: "Impact", value: "impact" },
            { label: "Lucida Console", value: "lucidaconsole" },
            { label: "Lucida Sans Unicode", value: "lucidasansunicode" },
            { label: "Microsoft Sans Serif", value: "microsoftsansserif" },
            { label: "Palatino Linotype", value: "palatinolinotype" },
            { label: "Tahoma", value: "tahoma" },
            { label: "Times New Roman", value: "timesnewroman" },
            { label: "Trebuchet MS", value: "trebuchetms" },
            { label: "Verdana", value: "verdana" },
            { label: "Courier", value: "courier" },
            { label: "Roboto", value: "roboto" },
            { label: "Open Sans", value: "opensans" },
            { label: "Lato", value: "lato" },
            { label: "Montserrat", value: "montserrat" },
            { label: "Poppins", value: "poppins" },
            { label: "Raleway", value: "raleway" },
            { label: "Source Sans Pro", value: "sourcesanspro" },
            { label: "Oswald", value: "oswald" },
            { label: "Merriweather", value: "merriweather" },
            { label: "Fira Sans", value: "firasans" },
            { label: "Playfair Display", value: "playfairdisplay" },
            { label: "Quicksand", value: "quicksand" },
            { label: "Bree Serif", value: "bree-serif" },
            { label: "Cabin", value: "cabin" },
            { label: "Nunito", value: "nunito" },
            { label: "PT Sans", value: "ptsans" },
            { label: "Roboto Slab", value: "robotoslab" },
            { label: "Work Sans", value: "worksans" },
            { label: "Droid Sans", value: "droidsans" },
            { label: "Droid Serif", value: "droidserif" },
            { label: "Indie Flower", value: "indieflower" },
            { label: "Josefin Sans", value: "josefinsans" },
            { label: "Lora", value: "lora" },
            { label: "Ubuntu", value: "ubuntu" },
            { label: "Zilla Slab", value: "zillaslab" },
            { label: "Anton", value: "anton" },
            { label: "Lobster", value: "lobster" },
            { label: "Pacifico", value: "pacifico" },
            { label: "Dancing Script", value: "dancingscript" },
            { label: "Courgette", value: "courgette" },
            { label: "Arvo", value: "arvo" },
            { label: "Slabo 27px", value: "slabo27px" },
            { label: "Tisa", value: "tisa" },
            { label: "Abril Fatface", value: "abrilfatface" }
        ].forEach(font => {
            this.customStyleMap[`FONT_${font.value.toUpperCase()}`] = { fontFamily: font.value }
            this.stateToHTMLOptions.inlineStyles[`FONT_${font.value.toUpperCase()}`] = { style: { fontFamily: font.value } }
        })
        if (Array.isArray(this.props.fonts)) {
            this.props.fonts.forEach(font => {
                this.customStyleMap[`FONT_${font.name.replace(/\x00/g, '').toUpperCase()}`] = { fontFamily: font.name.replace(/\x00/g, '') }
                this.stateToHTMLOptions.inlineStyles[`FONT_${font.name.replace(/\x00/g, '').toUpperCase()}`] = { style: { fontFamily: font.name.replace(/\x00/g, '') } }
            })
        }
    }

    initialState = (html) => {
        this.loadFontSizes();
        this.loadFonts();
        if (html && typeof html === 'string') {
            return EditorState.createWithContent(stateFromHTML(html, {
                customInlineFn: (element, inlineCreators) => {
                    if (element.tagName === 'SPAN') {
                        if (element.style.fontFamily) {
                            const fontFamily = element.style.fontFamily;
                            return inlineCreators.Style('FONT_' + fontFamily.toUpperCase().replace(/"/g, ''))
                        }
                        if (element.style.fontSize) {
                            const fontSize = element.style.fontSize;
                            const fontSizeMatch = fontSize.match(/^(\d+)(PX|PT)$/i);
                            if (fontSizeMatch) {
                                const sizeValue = fontSizeMatch[1];
                                const styleKey = `FONTSIZE_${sizeValue}`;
                                return inlineCreators.Style(styleKey)
                            }
                        }
                    }
                    return undefined;
                },
                elementStyles: this.stateToHTMLOptions.inlineStyles
            }));
        } else {
            return EditorState.createEmpty();
        }
    };

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }

    _toggleHeadingType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
    }

    alignText = () => {
        let style = {}
        if (this.state.textAlign == "LEFT") {
            style = { textAlign: 'left' };
        } else if (this.state.textAlign == "CENTER") {
            style = { textAlign: 'center' };
        } else if (this.state.textAlign == "RIGHT") {
            style = { textAlign: 'right' };
        } else {
            style = { justifyContent: "left" };
        }
        try {
            if (this.state.base_font && this.state.base_font.name) {
                let font_name = this.state.base_font.name.replace(/\x00/g, '');
                font_name = font_name.toLowerCase();
                style.fontFamily = `"${font_name}"`;
            }
        } catch (error) { }
        return style
    }

    backgroundColor = () => {
        let color = "#FFFFFF";
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.transparent) {
            if (this.state.page && this.state.page.enable_background) {
                color = "#" + this.state.page.background_color;
            } else {
                color = "#" + this.state.report_background_color;
            }
        } else if (this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color) {
            color = "#" + this.state.cell.settings.background_color;
        }
        return color;
    };

    fontColor = () => {
        let color = "#000000";
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color) {
            color = "#" + this.state.cell.settings.font_color;
        }
        return color;
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        const { editorState } = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = ''; //'py-3 h-full';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            this.state.show ? <div
                id={`RichEditor${this.state.cell.i}`}
                style={this.state.cell && this.state.cell.settings && !this.state.cell.settings.transparent ? { backgroundColor: this.backgroundColor(), color: this.fontColor() } : { color: this.fontColor() }}
                className={(!(this.state.cell.settings && (this.state.cell.settings.disable_margin_top || this.state.cell.settings.disable_margin_left)) ? " rounded-tl-md " : "") + (!(this.state.cell.settings && (this.state.cell.settings.disable_margin_top || this.state.cell.settings.disable_margin_right)) ? " rounded-tr-md " : "") + (!(this.state.cell.settings && (this.state.cell.settings.disable_margin_bottom || this.state.cell.settings.disable_margin_right)) ? " rounded-br-md " : "") + (!(this.state.cell.settings && (this.state.cell.settings.disable_margin_bottom || this.state.cell.settings.disable_margin_left)) ? " rounded-bl-md " : "") + " RichEditor-root"}
            >
                {!this.props.static && this.state.headings &&
                    createPortal(
                        <div
                            className="w-full h-full flex items-center justify-center"
                            style={{
                                position: "fixed",
                                zIndex: "500",
                                top: "1px",
                                background: "#00000061",
                            }}>
                            <div className="absolute w-full h-full"
                                onClick={() => {
                                    console.log(2)
                                    this.setState({
                                        headings: !this.state.headings
                                    })
                                }}>
                            </div>
                            <div
                                className="flex-col items-center absolute"
                                style={{
                                    left: `${document.getElementById(`RichEditor${this.state.cell.i}`).getBoundingClientRect().left + window.scrollX}px`,
                                    top: `${document.getElementById(`RichEditor${this.state.cell.i}`).getBoundingClientRect().top + window.scrollY - 1}px`
                                }}>
                                <div className="absolute p-2 bg-white rounded-lg"
                                    style={{
                                        minWidth: "600px",
                                        top: "-120px",
                                        left: `${document.getElementById(`RichEditor${this.state.cell.i}`).getBoundingClientRect().width / 2 - 300}px`
                                    }}>
                                    <div className="flex flex-row flex-1 mb-2">
                                        <div className="flex flex-1 rounded-md overflow-hidden border">
                                            <PositionStyleControls
                                                textAlign={this.state.textAlign}
                                                verticalAlign={this.state.verticalAlign}
                                                cell={this.state.cell}
                                                editorState={editorState}
                                                onToggle={(style) => {
                                                    if (style == "VERTICAL_ALIGN") {
                                                        this.setState({
                                                            verticalAlign: !this.state.verticalAlign
                                                        });
                                                    } else {
                                                        this.setState({
                                                            textAlign: style
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="">
                                            <FontControls customFonts={this.state.fonts} cell={this.state.cell} editorState={editorState} onToggleInline={this.toggleInlineStyle} onToggleHeading={this.toggleHeadingType} />
                                        </div>
                                        <div className="flex flex-row justify-end">
                                            <div onClick={() => {
                                                this.setState({
                                                    headings: false
                                                })
                                            }}
                                                className={"w-12 border border-opacity-0 rounded-md ml-2 flex justify-center items-center text-sm font-medium text-red-500 bg-red-100 cursor-pointer duration-200"}>
                                                <XIcon className={"w-5"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-end flex-1">
                                        <StyleControls cell={this.state.cell} editorState={editorState} onToggleInline={this.toggleInlineStyle} onToggleHeading={this.toggleHeadingType} />
                                    </div>
                                </div>
                                {
                                    false &&
                                    !this.props.static &&
                                    <div style={{ right: '15px', top: '15px' }}
                                        className="absolute z-60 overflow-hidden rounded">
                                        <div className="flex flex-col justify-end">
                                            <div className="flex flex-row justify-end">
                                                <div onClick={() => {
                                                    this.setState({
                                                        headings: !this.state.headings
                                                    })
                                                }}
                                                    className={((this.state.cell.w < 2 || this.state.cell.h < 2) ? "w-6 h-6 " : "w-10 h-10 ") + " rounded flex justify-center items-center text-sm font-medium text-purple-500 bg-purple-100 hover:bg-gray-100 cursor-pointer duration-200"}>
                                                    {
                                                        !this.state.headings &&
                                                        <DotsVerticalIcon className={(this.state.cell.w < 2 || this.state.cell.h < 2) ? "h-3" : "h-5"} />
                                                    }
                                                    {
                                                        this.state.headings &&
                                                        <XIcon className={(this.state.cell.w < 2 || this.state.cell.h < 2) ? "w-3" : "w-5"} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div
                                    style={{
                                        height: `${document.getElementById(`RichEditor${this.state.cell.i}`).getBoundingClientRect().height}px`,
                                        width: `${document.getElementById(`RichEditor${this.state.cell.i}`).getBoundingClientRect().width}px`,
                                        backgroundColor: this.backgroundColor(),
                                    }}
                                    onClick={() => {
                                        if (!this.props.static) {
                                            this.editorPopupRef.current.focus();
                                        }
                                    }}
                                    className={"rounded-tl-md  rounded-tr-md  rounded-br-md  rounded-bl-md  RichEditor-root place" + (this.state.verticalAlign ? " table-cell align-middle" : "")}>
                                    <div style={this.alignText()} className={className}>
                                        <Editor
                                            readOnly={this.props.static}
                                            blockStyleFn={getBlockStyle}
                                            editorState={editorState}
                                            handleKeyCommand={this.handleKeyCommand}
                                            keyBindingFn={this.mapKeyToEditorCommand}
                                            onChange={this.onChange}
                                            customStyleMap={this.customStyleMap}
                                            placeholder=""
                                            ref={this.editorPopupRef}
                                            spellCheck={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>,
                        document.body
                    )
                }
                {
                    !this.state.headings &&
                    <div
                        className={"h-full w-full" + (this.state.verticalAlign ? " grid place-items-center" : "")}
                        onClick={() => {
                            if (!this.props.static) {
                                this.setState({
                                    headings: !this.state.headings
                                }, () => {
                                    this.editorPopupRef.current.focus();
                                })
                            }
                        }}>
                        <div style={this.alignText()} className={className + " w-full"}>
                            <Editor
                                readOnly={this.props.static}
                                blockStyleFn={getBlockStyle}
                                editorState={editorState}
                                handleKeyCommand={this.handleKeyCommand}
                                keyBindingFn={this.mapKeyToEditorCommand}
                                customStyleMap={this.customStyleMap}
                                onChange={this.onChange}
                                placeholder=""
                                ref="editor"
                                spellCheck={false}
                            />
                        </div>
                    </div>
                }
            </div> : <div
                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                className="w-full h-full relative flex flex-col justify-center items-center">
                <div className="bg-transparent">
                    <div style={{ borderTopColor: "transparent" }}
                        class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote';
        default:
            return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }
    render() {
        let className = '  hover:bg-gray-100 cursor-pointer duration-200 bg-white flex justify-center items-center font-medium';
        if (this.props.active) {
            className += ' text-purple-500 bg-gray-100';
        } else {
            className += ' text-gray-700';
        }
        if (this.props.textSizes) {
            className += ' flex flex-1';
        } else {
            className += ' h-10 w-12 min-w-12';
        }
        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.icon &&
                    <span>
                        <FeatherIcon className="stroke-current" size={16} icon={this.props.icon} />
                    </span>
                }
                {!this.props.icon &&
                    <span>{this.props.label}</span>
                }
            </span>
        );
    }
}

class FontPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showFonts: false,
            selectedFont: null,
            fonts: [
                { label: 'Poppins', value: 'poppins' },
                { label: "Arial", value: "arial" },
                { label: "Arial Black", value: "arialblack" },
                { label: "Comic Sans MS", value: "comicsansms" },
                { label: "Courier New", value: "couriernew" },
                { label: "Georgia", value: "georgia" },
                { label: "Impact", value: "impact" },
                { label: "Lucida Console", value: "lucidaconsole" },
                { label: "Lucida Sans Unicode", value: "lucidasansunicode" },
                { label: "Microsoft Sans Serif", value: "microsoftsansserif" },
                { label: "Palatino Linotype", value: "palatinolinotype" },
                { label: "Tahoma", value: "tahoma" },
                { label: "Times New Roman", value: "timesnewroman" },
                { label: "Trebuchet MS", value: "trebuchetms" },
                { label: "Verdana", value: "verdana" },
                { label: "Courier", value: "courier" },
                { label: "Roboto", value: "roboto" },
                { label: "Open Sans", value: "opensans" },
                { label: "Lato", value: "lato" },
                { label: "Montserrat", value: "montserrat" },
                { label: "Poppins", value: "poppins" },
                { label: "Raleway", value: "raleway" },
                { label: "Source Sans Pro", value: "sourcesanspro" },
                { label: "Oswald", value: "oswald" },
                { label: "Merriweather", value: "merriweather" },
                { label: "Fira Sans", value: "firasans" },
                { label: "Playfair Display", value: "playfairdisplay" },
                { label: "Quicksand", value: "quicksand" },
                { label: "Bree Serif", value: "bree-serif" },
                { label: "Cabin", value: "cabin" },
                { label: "Nunito", value: "nunito" },
                { label: "PT Sans", value: "ptsans" },
                { label: "Roboto Slab", value: "robotoslab" },
                { label: "Work Sans", value: "worksans" },
                { label: "Droid Sans", value: "droidsans" },
                { label: "Droid Serif", value: "droidserif" },
                { label: "Indie Flower", value: "indieflower" },
                { label: "Josefin Sans", value: "josefinsans" },
                { label: "Lora", value: "lora" },
                { label: "Ubuntu", value: "ubuntu" },
                { label: "Zilla Slab", value: "zillaslab" },
                { label: "Anton", value: "anton" },
                { label: "Lobster", value: "lobster" },
                { label: "Pacifico", value: "pacifico" },
                { label: "Dancing Script", value: "dancingscript" },
                { label: "Courgette", value: "courgette" },
                { label: "Arvo", value: "arvo" },
                { label: "Slabo 27px", value: "slabo27px" },
                { label: "Tisa", value: "tisa" },
                { label: "Abril Fatface", value: "abrilfatface" }
            ]
        };
    }

    functions = {
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }

    componentDidMount() {
        this.props.customFonts.forEach(font => {
            this.state.fonts.push({
                value: font.name.replace(/\x00/g, '').toLowerCase(),
                name: font.name.replace(/\x00/g, '').toLowerCase(),
                label: font.name.replace(/\x00/g, ''),
                uploaded: true
            });
        });
        this.setState({
            fonts: this.state.fonts,
        });
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentStyle && nextProps.currentStyle.entries() && nextProps.currentStyle.values()) {
            const styles = Array.from(nextProps.currentStyle.values());
            const fontStyle = styles.find(style => style.indexOf('FONT_') > -1);
            if (fontStyle) {
                let fontValue = fontStyle.slice(fontStyle.indexOf('_') + 1).toLowerCase();
                this.state.selectedFont = this.state.fonts.find(font => font.value.toLowerCase() === fontValue);
                this.setState({ selectedFont: this.state.selectedFont });
            } else {
                if (this.state.selectedFont) {
                    this.setState({ selectedFont: null });
                }
            }
        }
    }

    render() {
        return (
            <div className="flex bg-white mx-2 rounded-md border hover:bg-gray-100">
                <button
                    onMouseDown={(e) => {
                        e.preventDefault()
                        this.setState({ showFonts: !this.state.showFonts });
                    }}
                    style={{ "minWidth": "135px", "width": "135px" }}
                    className="px-4 py-2 truncate"
                >
                    {this.state.selectedFont ? this.state.selectedFont.label : "Select font"}
                </button>
                {
                    this.state.showFonts &&
                    <ul
                        style={{ top: "53px", "minWidth": "270px", "width": "270px" }}
                        className="absolute z-70 mt-1 bg-white border shadow-lg max-h-40 overflow-scroll rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {this.state.fonts.map((font) => (
                            <li
                                key={font.value}
                                style={{ fontFamily: font.uploaded ? `"${font.value}"` : font.value }}
                                className={`${this.state.selectedFont && this.state.selectedFont.label === font.label ? "bg-gray-100 text-purple-500" : "text-gray-900"} cursor-pointer text-sm font-medium select-none hover:bg-gray-100 relative py-3 pl-4 pr-4`}
                                onMouseDown={async (e) => {
                                    e.preventDefault();
                                    const styles = Array.from(this.props.currentStyle.values());
                                    const changed = !(this.state.selectedFont && this.state.selectedFont.label === font.label)
                                    if (styles && styles.find(style => style.indexOf('FONT_') > -1)) {
                                        for (let index in styles) {
                                            if (styles[index].indexOf('FONT_') > -1) {
                                                this.props.onToggle(styles[index]);
                                                await this.functions.sleep(100);
                                            }
                                        }
                                    }
                                    this.setState({ showFonts: !this.state.showFonts });
                                    if (changed) {
                                        this.props.onToggle(`FONT_${font.value.toUpperCase()}`);
                                    }
                                }}
                            >
                                {font.label}
                            </li>
                        ))}
                    </ul>
                }
            </div>
        )
    }
}

class FontSizePicker extends React.Component {

    constructor() {
        super();
        this.state = {
            fontSize: 14
        };
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentStyle && nextProps.currentStyle.entries() && nextProps.currentStyle.values()) {
            const styles = Array.from(nextProps.currentStyle.values());
            const fontSizeStyle = styles.findLast(style => style.indexOf('FONTSIZE_') > -1);
            if (fontSizeStyle) {
                this.setState({ fontSize: +fontSizeStyle.slice(fontSizeStyle.indexOf('_') + 1) });
            } else {
                if (this.state.fontSize > 14) {
                    this.setState({ fontSize: 14 });
                }
            }
        }
    }

    functions = {
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }

    increaseFontSize = async (e) => {
        if (this.state.fontSize < 98) {
            e.preventDefault();
            const size = this.state.fontSize
            const styles = Array.from(this.props.currentStyle.values());
            if (styles && styles.find(style => style.indexOf('FONTSIZE_') > -1)) {
                await styles.map(async (style) => {
                    if (style.indexOf('FONTSIZE_') > -1) {
                        this.props.onToggle(style);
                        await this.functions.sleep(50);
                    }

                    return Promise.resolve();
                })
            }

            this.props.onToggle(`FONTSIZE_${size + 2}`);
        }
    };

    decreaseFontSize = async (e) => {
        if (this.state.fontSize > 4) {
            e.preventDefault();
            const size = this.state.fontSize
            const styles = Array.from(this.props.currentStyle.values());
            if (styles && styles.find(style => style.indexOf('FONTSIZE_') > -1)) {
                await styles.map(async (style) => {
                    if (style.indexOf('FONTSIZE_') > -1) {
                        this.props.onToggle(style);
                        await this.functions.sleep(50);
                    }

                    return Promise.resolve();
                })
            }

            this.props.onToggle(`FONTSIZE_${size - 2}`);
        }
    };

    render() {
        const { fontSize } = this.state;
        let className = 'h-10 w-10 min-w-10 hover:bg-gray-100 cursor-pointer duration-200 bg-white flex justify-center items-center font-medium';
        return (
            <span className="flex rounded-md border overflow-hidden">
                <span className={className} onMouseDown={this.increaseFontSize}><PlusIcon className="w-4"></PlusIcon></span>
                <span onMouseDown={(e) => e.preventDefault()} className="flex h-10 border-r border-l w-11 min-w-10 justify-center bg-gray-50 items-center text-sm font-medium">
                    {fontSize}
                </span>
                <span className={className} onMouseDown={this.decreaseFontSize}><MinusIcon className="w-4"></MinusIcon></span>
            </span>
        );
    }
}

class StyleButtonText extends React.Component {
    constructor() {
        super();
        this.state = {};
    }
    render() {
        let className = 'h-10 flex hover:bg-gray-100 cursor-pointer duration-200 bg-white justify-center items-center font-medium';
        if (this.props.active) {
            className += ' text-purple-500 bg-gray-100';
        } else {
            className += ' text-gray-700';
        }
        if (this.props.label == "Vertical") {
            className += ' px-3';
        } else {
            className += ' flex-1';
        }
        return (
            <div className={className} onMouseDown={() => {
                if (this.props.onToggle) {
                    this.props.onToggle(this.props.style);
                }
            }}>
                <span>{this.props.label}</span>
            </div>
        );
    }
}

const FontControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="flex flex-row text-xs bg-white">
            <FontPicker customFonts={props.customFonts} editorPopupRef={props.editorPopupRef} onToggle={props.onToggleInline} currentStyle={currentStyle} />
            <FontSizePicker onToggle={props.onToggleInline} currentStyle={currentStyle} />
        </div>
    )
}

const StyleControls = (props) => {
    const INLINE_STYLES = [
        { label: 'Bold', style: 'BOLD', icon: 'bold' },
        { label: 'Italic', style: 'ITALIC', icon: 'italic' },
        { label: 'Underline', style: 'UNDERLINE', icon: 'underline' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH', icon: 'minus' }
    ];
    const HEADING_TYPES_LIST = [
        { label: 'UL', style: 'unordered-list-item', icon: 'list' },
        { label: 'OL', style: 'ordered-list-item', icon: '' },
    ];
    const HEADING_TYPES_SECOND = [
        { label: 'P', style: 'unstyled' },
        { label: 'H1', style: 'header-one' },
        { label: 'H2', style: 'header-two' },
        { label: 'H3', style: 'header-three' },
        { label: 'H4', style: 'header-four' },
    ];
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
    const currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="flex flex-row bg-white text-xs flex-1">
            <div className="flex flex-row border rounded-md overflow-hidden mr-1">
                {INLINE_STYLES.map((type) => (
                    <StyleButton cell={props.cell} key={type.label} active={currentStyle.has(type.style)}
                        label={type.label} icon={type.icon} onToggle={props.onToggleInline}
                        style={type.style} />
                ))}
            </div>
            <div className="flex flex-row border rounded-md overflow-hidden mx-1">
                {HEADING_TYPES_LIST.map((type) => (
                    <StyleButton cell={props.cell} key={type.label} active={currentStyle.has(type.style)}
                        label={type.label} icon={type.icon} onToggle={props.onToggleInline}
                        style={type.style} />
                ))}
            </div>
            <div className="flex flex-row border rounded-md overflow-hidden flex-1 ml-1">
                {HEADING_TYPES_SECOND.map((type) => (
                    <StyleButton textSizes={true} cell={props.cell} key={type.label} active={type.style === blockType} label={type.label}
                        onToggle={props.onToggleHeading} style={type.style} />
                ))}
            </div>
        </div>
    );
};

const PositionStyleControls = (props) => {
    var POSITION_STYlE = [
        { label: 'Left', style: 'LEFT' },
        { label: 'Center', style: 'CENTER' },
        { label: 'Right', style: 'RIGHT' },
        { label: 'Vertical', style: 'VERTICAL_ALIGN' }
    ];
    return (
        <div className="flex flex-row text-xs items-stretch flex-1">
            {POSITION_STYlE.map((type) => (
                <StyleButtonText cell={props.cell} key={type.label} active={props.textAlign === type.style || (props.verticalAlign && type.style == "VERTICAL_ALIGN") || (type.style == "LEFT" && !props.textAlign)} label={type.label} onToggle={props.onToggle} style={type.style} />
            ))}
        </div>
    );
};
