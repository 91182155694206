import React, { Component } from 'react';
import { BeatLoader } from "react-spinners";
import FeatherIcon from 'feather-icons-react';
import { ArrowDownIcon, TrendingUpIcon } from '@heroicons/react/outline';
import { ArrowUpIcon } from '@heroicons/react/solid';
import moment from 'moment';

export default class AdvancedReportCellNumber extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cell: {},
            items: [],
            show: false,
            show_component: false,
            load_status: 'Requesting data from platforms'
        };
    };

    async componentDidMount() {

        await this.promisedSetState({
            cell: this.props.source ? this.props.source : {},
            items: this.props.items ? this.props.items : [],
            transparent_cells: this.props.transparent_cells,
            report_background_color: this.props.report_background_color,
            show_component: this.props.show_component,
            base_font: this.props.base_font
        });

        //ONLY SHOW CONTENT IN VIEW
        this.functions.showInView();

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            cell: nextProps.source ? nextProps.source : {},
            items: nextProps.items ? nextProps.items : [],
            transparent_cells: nextProps.transparent_cells,
            report_background_color: nextProps.report_background_color,
            show_component: nextProps.show_component,
            base_font: nextProps.base_font
        });
        this.getCellLoadStatus();
    }

    functions = {
        showInView: async () => {
            let self = this;
            if (this.state.show_component) {
                self.setState({ show: true });
            } else {
                await self.functions.sleep(2000);
                try {
                    let box = null;
                    if (window.innerWidth <= 767) {
                        box = document.getElementById(self.state.cell.i + "_preview_mobile");
                    } else {
                        box = document.getElementById(self.state.cell.i + "_preview");
                    }
                    const rect = box.getBoundingClientRect();
                    const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                        rect.bottom > 0 &&
                        rect.right > 0;
                    if (isInView) {
                        self.setState({ show: isInView });
                    }
                } catch (error) {
                    self.setState({ show: true });
                }
                document.addEventListener('scroll', async function () {
                    try {
                        let box = null;
                        if (window.innerWidth <= 767) {
                            box = document.getElementById(self.state.cell.i + "_preview_mobile");
                        } else {
                            box = document.getElementById(self.state.cell.i + "_preview");
                        }
                        const rect = box.getBoundingClientRect();
                        const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                            rect.bottom > 0 &&
                            rect.right > 0;
                        self.setState({ show: isInView });
                    } catch (error) {
                        self.setState({ show: true });
                    }
                }, {
                    capture: true,
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    };

    getCellLoadStatus(timeout = false) {
        if (this.state.cell.loading) {
            if (this.state.cell.load_status) {
                if (!timeout && this.state.load_status === 'Requesting data from platforms') {
                    this.state.load_status = 'Requesting data from platforms';
                    setTimeout(() => {
                        this.getCellLoadStatus(true);
                    }, 10000);
                } else if (timeout) {
                    this.state.load_status = 'Waiting on data from platforms';
                }
            } else {
                this.state.load_status = 'Requesting data from platforms';
            }
        }

        this.promisedSetState({
            load_status: this.state.load_status
        });
    }

    setLoader() { }

    template() {
        try {
            return (this.state.cell.data_sources.channels && Object.keys(this.state.cell.data_sources.channels).length < 1) && Object.keys(this.state.cell.data_sources.accounts).length < 1 && Object.keys(this.state.cell.data_sources.campaigns).length < 1 && Object.keys(this.state.cell.data_sources.adgroups).length < 1 && Object.keys(this.state.cell.data_sources.ads).length < 1
        } catch (error) {
            return false;
        }
    }

    metricGoal() {
        let metric = null;
        for (let key in this.state.cell.metrics) {
            metric = this.state.cell.metrics[key];
        }
        if (metric && metric.goal && metric.goal.value === "highest") {
            return "highest"
        } else if (metric && metric.goal && metric.goal.value === "lowest") {
            return "lowest"
        } else {
            return null;
        }
    }

    metricComparison() {
        let metric = null;
        for (let key in this.state.cell.metrics) {
            metric = this.state.cell.metrics[key];
        }
        if (metric && metric.comparison && metric.comparison.value) {
            return metric.comparison.value
        } else {
            return null;
        }
    }

    showOverlayText() {
        let show = false;
        try {
            if (!this.state.cell.loading) {
                let total = this.state.cell.data ? +this.state.cell.data.total : 0;
                if (total == 0) {
                    if (this.state.cell.settings && this.state.cell.settings.zeroValueOverlay) {
                        if (typeof this.state.cell.settings.zeroValueOverlayText == "string" && this.state.cell.settings.zeroValueOverlayText !== "") {
                            show = true;
                        }
                    }
                }
            }
        } catch (error) { }
        return show;
    }

    compareNumber() {
        if (this.state.cell.daterange && this.state.cell.daterange.compare_dates && this.state.cell.daterange.compare_dates.value === "enabled") {
            let preceding_number = 0;
            let total = this.state.cell.data ? +this.state.cell.data.total : 0;
            this.state.items.map((item) => {
                if (item.abstract && item.parent_cell == this.state.cell.i) {
                    preceding_number = item.data ? item.data.total : 0;
                }
            });
            if (preceding_number > 0) {
                let metric = null;
                for (let key in this.state.cell.metrics) {
                    metric = this.state.cell.metrics[key];
                }
                if (metric && metric.comparison && metric.comparison.value == "number") {
                    if (preceding_number % 1 != 0) {
                        return preceding_number.toFixed(2);
                    } else {
                        return preceding_number;
                    }
                } else {
                    let increase = total - preceding_number;
                    let value = (increase / preceding_number) * 100;
                    if (value % 1 != 0) {
                        return value.toFixed(2);
                    } else {
                        return value;
                    }
                }
            } else {
                return null;
            }
        }
    }

    addCommas(nStr) {
        nStr += '';
        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ' ' + '$2');
        }
        return x1 + x2;
    }

    fontSizeHeadline() {
        let font_size = "text-2xl";
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.font_size && this.state.cell.settings.font_size.value == "small") {
            font_size = "text-lg";
        }
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.font_size && this.state.cell.settings.font_size.value == "medium") {
            font_size = "text-xl";
        }
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.font_size && this.state.cell.settings.font_size.value == "large") {
            font_size = "text-2xl";
        }
        return font_size;
    }

    fontSizeSubtitle() {
        let font_size = "text-sm";
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.font_size && this.state.cell.settings.font_size.value == "small") {
            font_size = "text-xxs";
        }
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.font_size && this.state.cell.settings.font_size.value == "medium") {
            font_size = "text-xs";
        }
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.font_size && this.state.cell.settings.font_size.value == "large") {
            font_size = "text-sm";
        }
        return font_size;
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    getIconSize = (icon) => {
        switch (icon.size.value) {
            case 'small': return '50px';
            case 'medium': return '75px';
            case 'large': return '100px';
            default: return '50px';
        }
    }

    totalDays = () => {
        try {
            var a = moment(this.state.cell.daterange.end_date);
            var b = moment(this.state.cell.daterange.start_date);
            return a.diff(b, 'days')
        } catch (error) {
            return "-";
        }
    }

    daysLeft = () => {
        try {
            if (moment(this.state.cell.daterange.end_date).isBefore(moment())) {
                return this.totalDays();
            } else {
                var b = moment(this.state.cell.daterange.start_date);
                var a = moment();
                return a.diff(b, 'days')
            }
        } catch (error) {
            return "-";
        }
    }

    percentageDays = () => {
        try {
            return (this.daysLeft() / this.totalDays()) * 100;
        } catch (error) {
            return 0;
        }
    }

    percentageMetric = (metric) => {
        try {
            let target = +this.state.cell.settings.performance_target[metric];
            return (this.state.cell.data.total / target) * 100;
        } catch (error) {
            return 0;
        }
    }

    font = () => {
        let font_string = "Poppins";
        try {
            if (this.state.base_font && this.state.base_font.value) {
                font_string = this.state.base_font.name.replace(/\x00/g, '');
                font_string = font_string.toLowerCase();
            }
        } catch (error) { }
        return font_string;
    }

    render() {
        return (
            this.state.show ? <div
                className={"flex absolute w-full h-full items-center text-center " + (this.state.cell.settings && this.state.cell.settings.align ? `justify-${this.state.cell.settings.align.value} pr-2 pl-2` : "justify-center ju")}
                //style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                style={{
                    ...(this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color && !this.state.cell.settings.transparent && { backgroundColor: "#" + this.state.cell.settings.background_color }),
                    ...(this.state.cell && this.state.cell.settings && !this.state.cell.settings.background_color && !this.state.cell.settings.transparent && { backgroundColor: "#FFFFFF" }),
                    ...(this.state.base_font && this.state.base_font.value && { fontFamily: `"${this.font()}"` })
                }}
            >
                {
                    !this.state.cell.loading &&
                    !this.showOverlayText() &&
                    !this.template() &&
                    this.metricGoal() &&
                    this.state.cell.daterange && this.state.cell.daterange.compare_dates && this.state.cell.daterange.compare_dates.value === "enabled" &&
                    !(this.state.cell.celltype && this.state.cell.celltype.id == "performance") &&
                    <div style={{ top: "15px", right: "15px" }} className="absolute text-sm font-medium flex flex-row">
                        {
                            ((this.metricGoal() === "highest" && this.compareNumber() > 0) || (this.metricGoal() === "lowest" && this.compareNumber() < 0)) &&
                            <div className="bg-green-100 text-green-500 border-green-500 h-5 w-5 mr-1 rounded-full flex justify-center items-center">
                                {
                                    this.compareNumber() > 0 &&
                                    <ArrowUpIcon className={"w-3"} />
                                }
                                {
                                    this.compareNumber() < 0 &&
                                    <ArrowDownIcon className={"w-3"} />
                                }
                            </div>
                        }
                        {
                            ((this.metricGoal() === "lowest" && this.compareNumber() > 0) || (this.metricGoal() === "highest" && this.compareNumber() < 0)) &&
                            <div className="bg-red-100 text-red-500 border-red-500 h-5 w-5 mr-1 rounded-full flex justify-center items-center">
                                {
                                    this.compareNumber() > 0 &&
                                    <ArrowUpIcon className={"w-3"} />
                                }
                                {
                                    this.compareNumber() < 0 &&
                                    <ArrowDownIcon className={"w-3"} />
                                }
                            </div>
                        }
                        {
                            ((this.metricGoal() === "highest" && this.compareNumber() > 0) || (this.metricGoal() === "lowest" && this.compareNumber() < 0)) &&
                            <span className="text-green-500">{this.compareNumber()}{this.metricComparison() !== "number" && <span>%</span>}</span>
                        }
                        {
                            ((this.metricGoal() === "lowest" && this.compareNumber() > 0) || (this.metricGoal() === "highest" && this.compareNumber() < 0)) &&
                            <span className="text-red-500">{this.compareNumber()}{this.metricComparison() !== "number" && <span>%</span>}</span>
                        }
                    </div>
                }
                {
                    !this.showOverlayText() &&
                    !(this.state.cell.celltype && this.state.cell.celltype.id == "performance") &&
                    !this.state.cell.loading && !this.template() && this.state.cell.settings.icon && this.state.cell.settings.icon.path &&
                    <div
                        className="mr-4 flex items-center"
                        style={{ height: this.getIconSize(this.state.cell.settings.icon), width: this.getIconSize(this.state.cell.settings.icon) }} >
                        <img src={this.state.cell.settings.icon.path} />
                    </div>
                }
                {
                    !this.showOverlayText() &&
                    !(this.state.cell.celltype && this.state.cell.celltype.id == "performance") &&
                    !this.state.cell.loading && !this.template() &&
                    <div className={"flex flex-col justify-center " + (this.state.cell.settings.icon ? "items-left text-left" : "items-center text-center")}>
                        {
                            this.state.cell && this.state.cell.data &&
                            <div className={this.fontSizeHeadline() + " font-bold"} style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}>{this.addCommas(this.state.cell.data.total)}{this.state.cell.data.suffix ? (" " + this.state.cell.data.suffix) : ""}</div>
                        }
                        {
                            this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                            <div className={this.fontSizeSubtitle() + " font-medium"} style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}>{this.state.cell.settings.title}</div>
                        }
                    </div>
                }
                {
                    !this.showOverlayText() &&
                    this.state.cell.celltype && this.state.cell.celltype.id == "performance" &&
                    !this.state.cell.loading && !this.template() &&
                    <div className="flex flex-col h-full w-full justify-center items-center px-4">
                        {
                            this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                            <div className="flex flex-1 justify-center items-center">
                                {
                                    this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                                    <div className={this.fontSizeSubtitle() + " font-medium"} style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}>{this.state.cell.settings.title}</div>
                                }
                            </div>
                        }
                        <div className="w-full mb-4">
                            <div style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }} className="flex flex-1">
                                <div className="font-medium text-xs">Time passed</div>
                                <div className="flex flex-1"></div>
                                <div className="font-medium text-xs">{this.daysLeft()}/{this.totalDays()}</div>
                            </div>
                            <div className="w-full relative overflow-hidden">
                                <div style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color ? { backgroundColor: "#" + this.state.cell.settings.font_color, opacity: "0.2" } : { backgroundColor: "#333", opacity: "0.2" }} className="h-4 overflow-hidden relative w-full bg-gray-50 rounded-sm"></div>
                                <div style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color ? { backgroundColor: "#" + this.state.cell.settings.font_color, width: this.percentageDays() + "%" } : { backgroundColor: "#333", width: this.percentageDays() + "%" }} className="h-full z-10 absolute left-0 top-0 bottom-0 bg-gray-100"></div>
                            </div>
                        </div>
                        {
                            this.state.cell.settings && this.state.cell.settings.performance_target &&
                            Object.keys(this.state.cell.settings.performance_target).map((key) => {
                                return (
                                    <div className="w-full">
                                        <div style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }} className="flex flex-1">
                                            <div className="font-medium text-xs">{key}</div>
                                            <div className="flex flex-1"></div>
                                            <div className="font-medium text-xs">{this.state.cell.data ? this.state.cell.data.total : "-"}/{this.state.cell.settings.performance_target[key]}</div>
                                        </div>
                                        <div className="w-full relative overflow-hidden">
                                            <div style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color ? { backgroundColor: "#" + this.state.cell.settings.font_color, opacity: "0.2" } : { backgroundColor: "#333", opacity: "0.2" }} className="h-4 overflow-hidden relative w-full bg-gray-50 rounded-sm"></div>
                                            <div style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color ? { backgroundColor: "#" + this.state.cell.settings.font_color, width: this.percentageMetric(key) + "%" } : { backgroundColor: "#333", width: this.percentageMetric(key) + "%" }} className="h-full z-10 absolute left-0 top-0 bottom-0 bg-gray-100"></div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                            <div className="flex flex-1 -mt-4"></div>
                        }
                    </div>
                }
                {
                    this.state.cell.loading &&
                    <div className="w-full h-full relative flex flex-col justify-center items-center">
                        <div className='bg-transparent'>
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                        <div style={{ fontFamily: "Poppins" }} className="text-xs mt-3">{this.state.load_status}</div>
                    </div>
                }
                {
                    this.template() && !this.state.cell.loading &&
                    <div className="w-full h-full flex flex-col justify-center items-center relative">
                        <button onClick={() => {
                            this.props.onEditItem();
                        }} style={{ top: "22px", left: "22px" }}
                            className="transition-all duration-200 absolute px-2 font-bold text-xs leading-none flex items-center justify-center mr-1 h-8 bg-purple-100 text-purple-500 rounded">
                            <FeatherIcon className={"stroke-current"} size={15} icon={"tag"} />
                            {
                                this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                                <span className="ml-2">{this.state.cell.settings.title}</span>
                                ||
                                <span className="ml-2">...</span>
                            }
                        </button>
                    </div>
                }
                {
                    this.showOverlayText() &&
                    !this.template() &&
                    !this.state.cell.loading &&
                    <div
                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                        className="w-full h-full relative flex flex-col justify-center items-center">
                        {
                            this.state.cell && this.state.cell.settings &&
                            <div
                                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}
                                className="text-sm font-medium">{this.state.cell.settings.zeroValueOverlayText}</div>
                        }
                    </div>
                }
            </div> : <div
                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                className="w-full h-full relative flex flex-col justify-center items-center">
                <div className="bg-transparent">
                    <div style={{ borderTopColor: "transparent" }}
                        class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                </div>
                <div style={{ fontFamily: "Poppins" }} className="text-xs mt-3">{this.state.load_status}</div>
            </div>
        )
    }

};
