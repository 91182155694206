import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, CursorClickIcon, MinusIcon, PlusIcon, LocationMarkerIcon, CheckCircleIcon, BookOpenIcon, ChatIcon, CameraIcon, NewspaperIcon, ExclamationIcon, ReplyIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import cn from "classnames";
import DropdownTailwind from './dropdownTailwind';
import DropdownComboTailwind from './dropdownComboTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import moment from 'moment';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import { Bar } from 'react-chartjs-2';
import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';
import { adgroupFacebook } from '../validators/adgroupFacebook';
import SwitchTailwind from './switchTailwind';

class EditFacebookAdset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: [],
            currency: "",
            reach: {},
            open: false,
            adset: {},
            updated: {},
            client: {},
            ages: [],
            pixels: [],
            campaign_objective: {},
            campaign_budget_optimization: {},
            pages: [],
            app_options: [],
            campaign_smart_promotion_type: null,
            audience_new: { id: 0, name: "Select ..." },
            store_options: [
                { id: 1, name: "Amazon Appstore", value: "amazon_app_store" },
                { id: 2, name: "Facebook Canvas", value: "fb_canvas" },
                { id: 3, name: "Oculus App store", value: "fb_gameroom" },//????
                { id: 4, name: "Google Play", value: "google_play" },
                { id: 5, name: "Games", value: "instant_game" },//??? 
                { id: 6, name: "App store", value: "itunes" },
                { id: 7, name: "App store for iPad", value: "itunes_ipad" },
                { id: 8, name: "Windows store", value: "windows_10_store" },
            ],
            location_options: [],
            destination_types: [
                { id: 1, name: "Website", value: "WEBSITE" },
                //{ id: 2, name: "App", value: "APP" },
                //{ id: 3, name: "Messenger", value: "MESSENGER" },
                //{ id: 4, name: "Instagram", value: "INSTAGRAM_DIRECT" },
                //{ id: 5, name: "Instant forms", value: "ON_AD" },
                { id: 6, name: "On your ad (Post engagement)", value: "ON_POST" },
                { id: 7, name: "On your ad (Video views)", value: "ON_VIDEO" },
                { id: 8, name: "Facebook page", value: "ON_PAGE" },
                //{ id: 9, name: "Event", value: "ON_EVENT" }
            ],

            optimization_goals_new: {
                "1": [
                    { id: 13, name: "Reach", value: "REACH" },
                    { id: 5, name: "Impressions", value: "IMPRESSIONS" },
                    { id: 2, name: "Ad recall lift", value: "AD_RECALL_LIFT" },
                    { id: 17, name: "Thruplay", value: "THRUPLAY" },
                    { id: 21, name: "2-second continuous video views", value: "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS" }

                ],
                "2": [
                    { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                    { id: 14, name: "Landing page views", value: "LANDING_PAGE_VIEWS" },
                    { id: 5, name: "Impressions", value: "IMPRESSIONS" },
                    { id: 6, name: "Daily unique reach", value: "REACH" },
                ],
                "3": {
                    "1": [
                        { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" },
                        { id: 14, name: "Landing page views", value: "LANDING_PAGE_VIEWS" },
                        { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                        { id: 5, name: "Impressions", value: "IMPRESSIONS" },
                        { id: 6, name: "Daily unique reach", value: "REACH" },
                    ],
                    "2": [
                        { id: 9, name: "App events", value: "OFFSITE_CONVERSIONS" },
                        { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                        { id: 6, name: "Daily unique reach", value: "REACH" },
                    ],
                    "6": [
                        { id: 11, name: "Post Engagement", value: "POST_ENGAGEMENT" },
                        { id: 5, name: "Impressions", value: "IMPRESSIONS" },
                        { id: 6, name: "Daily unique reach", value: "REACH" },
                    ],
                    "7": [
                        { id: 17, name: "Thruplay", value: "THRUPLAY" },
                        { id: 21, name: "2-second continuous video views", value: "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS" }
                    ],
                    "8": [{ id: 10, name: "Page Likes", value: "PAGE_LIKES" }]
                },
                "4": {
                    "1": [
                        { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" },
                        { id: 14, name: "Landing page views", value: "LANDING_PAGE_VIEWS" },
                        { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                        { id: 5, name: "Impressions", value: "IMPRESSIONS" },
                        { id: 6, name: "Daily unique reach", value: "REACH" }
                    ],
                    "2": [
                        { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" },
                        { id: 14, name: "Landing page views", value: "LANDING_PAGE_VIEWS" },
                        { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                        { id: 5, name: "Impressions", value: "IMPRESSIONS" },
                        { id: 6, name: "Daily unique reach", value: "REACH" }
                    ],
                    "5": [
                        { id: 21, name: "Maximize number of leads", value: "LEAD_GENERATION" },
                        { id: 22, name: "Maximize number of conversion leads", value: "QUALITY_LEAD" },
                    ]
                },

                "5": [

                    { id: 1, name: "App Installs", value: "APP_INSTALLS" },
                    { id: 8, name: "Link Clicks", value: "LINK_CLICKS" }
                ],
                "6": [
                    { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" },
                    { id: 14, name: "Landing page views", value: "LANDING_PAGE_VIEWS" },
                    { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                    { id: 5, name: "Impressions", value: "IMPRESSIONS" },
                    { id: 6, name: "Daily unique reach", value: "REACH" }
                ]

            },
            optimization_goals: [
                { id: 1, name: "App Installs", value: "APP_INSTALLS" },
                { id: 2, name: "Ad recall lift", value: "AD_RECALL_LIFT" },
                { id: 3, name: "Engaged Users", value: "ENGAGED_USERS" },
                { id: 4, name: "Event responses", value: "EVENT_RESPONSES" },
                { id: 5, name: "Impressions", value: "IMPRESSIONS" },
                { id: 6, name: "Lead Generation", value: "LEAD_GENERATION" },
                { id: 7, name: "Quality lead", value: "QUALITY_LEAD" },
                { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" },
                { id: 10, name: "Page Likes", value: "PAGE_LIKES" },
                { id: 11, name: "Post Engagement", value: "POST_ENGAGEMENT" },
                { id: 12, name: "Quality call", value: "QUALITY_CALL" },
                { id: 13, name: "Reach", value: "REACH" },
                { id: 14, name: "Landing page views", value: "LANDING_PAGE_VIEWS" },
                { id: 15, name: "Visit instagram profile", value: "VISIT_INSTAGRAM_PROFILE" },
                { id: 16, name: "Value", value: "VALUE" },
                { id: 17, name: "Thruplay", value: "THRUPLAY" },
                { id: 18, name: "Derived events", value: "DERIVED_EVENTS" },
                { id: 19, name: "App install and offsite conversions", value: "APP_INSTALLS_AND_OFFSITE_CONVERSIONS" },
                { id: 20, name: "Conversations", value: "CONVERSATIONS" }
            ],
            billing_events: [
                { id: 1, name: "App Installs", value: "APP_INSTALLS" },
                { id: 2, name: "Impressions", value: "IMPRESSIONS" },
                { id: 3, name: "Link Clicks", value: "LINK_CLICKS" },
                { id: 4, name: "Offer Claims", value: "OFFER_CLAIMS" },
                { id: 5, name: "Page Likes", value: "PAGE_LIKES" },
                { id: 6, name: "Post Engagement", value: "POST_ENGAGEMENT" },
                { id: 7, name: "Video Views", value: "VIDEO_VIEWS" },
                { id: 8, name: "Thruplay", value: "THRUPLAY" },
            ],
            genders: [
                { id: 1, name: "All genders", value: [], selected: true },
                { id: 2, name: "Men only", value: [1], selected: false },
                { id: 3, name: "Female only", value: [2], selected: false }
            ],
            places: [
                { id: 1, name: "People living in or recently in this location", value: ["home", "recent"] },
                //{ id: 2, name: "People living in this location", value: ["home"] },
                //{ id: 3, name: "People recently in this location", value: ["recent"] },
                //{ id: 4, name: "People traveling in this location", value: ["travel_in"] }
            ],
            platforms: [
                { id: 1, name: "Facebook", value: "facebook" },
                { id: 2, name: "Audience network", value: "audience_network" },
                { id: 3, name: "Instagram", value: "instagram" },
                { id: 4, name: "Messenger", value: "messenger" }
            ],
            facebook_positions: [
                { id: 1, name: "Feed", value: "feed" },
                { id: 2, name: "Marketplace", value: "marketplace" },
                { id: 3, name: "Video feeds", value: "video_feeds" },
                { id: 4, name: "Right column", value: "right_hand_column" },
                { id: 5, name: "Business explore", value: "biz_disco_feed" },
                { id: 6, name: "Story", value: "story" },
                { id: 7, name: "Reels", value: "facebook_reels" },
                { id: 8, name: "In-stream video", value: "instream_video" },
                { id: 9, name: "Ads on Facebook Reels", value: "facebook_reels_overlay" },
                { id: 7, name: "Search results", value: "search" }
            ],
            instagram_positions: [
                { id: 1, name: "Feed", value: "stream" },
                { id: 2, name: "Explore", value: "explore" },
                { id: 3, name: "Explore home", value: "explore_home" },
                //{ id: 4, name: "Shop", value: "shop" },
                { id: 5, name: "Story", value: "story" },
                { id: 6, name: "Reels", value: "reels" }
            ],
            audience_network_positions: [
                { id: 1, name: "Native, banner and interstitial", value: "classic" },
                { id: 2, name: "Rewarded videos", value: "rewarded_video" },
            ],
            messenger_positions: [
                { id: 1, name: "Inbox", value: "messenger_home" },
                { id: 2, name: "Stories", value: "story" }
            ],
            devices: [
                { id: 1, name: "All devices (recommended)", value: ["mobile", "desktop"] },
                { id: 2, name: "Mobile only", value: ["mobile"] },
                { id: 3, name: "Desktop only", value: ["desktop"] }
            ],
            loader: false,
            tabs: [
                { id: 1, name: "Basic", value: "basic" },
                { id: 2, name: "Budget & Schedule", value: "budget" },
                { id: 3, name: "Audience", value: "audience" },
                { id: 8, name: "Beneficiary and payer", value: "beneficiary" },
                { id: 5, name: "Locations", value: "locations" },
                { id: 4, name: "Placements", value: "placements" },
                { id: 6, name: "Conversion", value: "conversion" },
                { id: 7, name: "App", value: "app" }
            ],
            event_types:
            {
                "3": [
                    { id: 12, name: "Add to wishlist", value: "ADD_TO_WISHLIST" },
                    { id: 3, name: "Contact", value: "CONTACT" },
                    { id: 4, name: "Customize product", value: "CUSTOMIZE_PRODUCT" },
                    { id: 5, name: "Donate", value: "DONATE" },
                    { id: 6, name: "Find location", value: "FIND_LOCATION" },
                    { id: 7, name: "Schedule", value: "SCHEDULE" },
                    { id: 19, name: "Search", value: "SEARCH" },
                    { id: 8, name: "Start trial", value: "START_TRIAL" },
                    { id: 9, name: "Submit application", value: "SUBMIT_APPLICATION" },
                    { id: 10, name: "Subscribe", value: "SUBSCRIBE" },
                    { id: 18, name: "View content", value: "CONTENT_VIEW" }
                ],
                "4": [
                    { id: 17, name: "Complete registration", value: "COMPLETE_REGISTRATION" },
                    { id: 3, name: "Contact", value: "CONTACT" },
                    { id: 6, name: "Find location", value: "FIND_LOCATION" },
                    { id: 16, name: "Lead", value: "LEAD" },
                    { id: 7, name: "Schedule", value: "SCHEDULE" },
                    { id: 19, name: "Search", value: "SEARCH" },
                    { id: 8, name: "Start trial", value: "START_TRIAL" },
                    { id: 9, name: "Submit application", value: "SUBMIT_APPLICATION" },
                    { id: 10, name: "Subscribe", value: "SUBSCRIBE" },
                    { id: 18, name: "View content", value: "CONTENT_VIEW" }
                ],
                "6": [
                    { id: 17, name: "Complete registration", value: "COMPLETE_REGISTRATION" },
                    { id: 14, name: "Add payment info", value: "ADD_PAYMENT_INFO" },
                    { id: 11, name: "Add to cart", value: "ADD_TO_CART" },
                    { id: 12, name: "Add to wishlist", value: "ADD_TO_WISHLIST" },
                    { id: 5, name: "Donate", value: "DONATE" },
                    { id: 13, name: "Initiated checkout", value: "INITIATED_CHECKOUT" },
                    { id: 15, name: "Purchase", value: "PURCHASE" },
                    { id: 19, name: "Search", value: "SEARCH" },
                    { id: 8, name: "Start trial", value: "START_TRIAL" },
                    { id: 10, name: "Subscribe", value: "SUBSCRIBE" },
                    { id: 18, name: "View content", value: "CONTENT_VIEW" }
                ],
            },
            event_types_old: [
                { id: 1, name: "Rate", value: "RATE" },
                { id: 2, name: "Tutorial Completion", value: "TUTORIAL_COMPLETION" },
                { id: 3, name: "Contact", value: "CONTACT" },
                { id: 4, name: "Customize product", value: "CUSTOMIZE_PRODUCT" },
                { id: 5, name: "Donate", value: "DONATE" },
                { id: 6, name: "Find location", value: "FIND_LOCATION" },
                { id: 7, name: "Schedule", value: "SCHEDULE" },
                { id: 8, name: "Start trial", value: "START_TRIAL" },
                { id: 9, name: "Submit application", value: "SUBMIT_APPLICATION" },
                { id: 10, name: "Subscribe", value: "SUBSCRIBE" },
                { id: 11, name: "Add to cart", value: "ADD_TO_CART" },
                { id: 12, name: "Add to wishlist", value: "ADD_TO_WISHLIST" },
                { id: 13, name: "Initiated checkout", value: "INITIATED_CHECKOUT" },
                { id: 14, name: "Add payment info", value: "ADD_PAYMENT_INFO" },
                { id: 15, name: "Purchase", value: "PURCHASE" },
                { id: 16, name: "Lead", value: "LEAD" },
                { id: 17, name: "Complete registration", value: "COMPLETE_REGISTRATION" },
                { id: 18, name: "Content view", value: "CONTENT_VIEW" },
                { id: 19, name: "Search", value: "SEARCH" },
                { id: 20, name: "Service booking request", value: "SERVICE_BOOKING_REQUEST" },
                { id: 21, name: "Messaging conversation started last 7 days", value: "MESSAGING_CONVERSATION_STARTED_7D" },
                { id: 22, name: "Level Achieved", value: "LEVEL_ACHIEVED" },
                { id: 23, name: "Achievement Unlocked", value: "ACHIEVEMENT_UNLOCKED" },
                { id: 24, name: "Spent Credits", value: "SPENT_CREDITS" },
                { id: 25, name: "Listing interaction", value: "LISTING_INTERACTION" },
                { id: 26, name: "Retention last 2 days", value: "D2_RETENTION" },
                { id: 27, name: "Retention last 7 days", value: "D7_RETENTION" },
                { id: 28, name: "Other", value: "OTHER" }
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            map: { id: 1, name: "List View", value: false },
            audience_types: { id: 1, name: "Included", value: true },
            location_type: { id: 1, name: "Included", value: true },
            audience_type: { id: 1, name: "Included", value: true },
            interest_type: { id: 1, name: "Included", value: true },
            education_schools_type: { id: 1, name: "Included", value: true },
            education_majors_type: { id: 1, name: "Included", value: true },
            work_employers_type: { id: 1, name: "Included", value: true },
            job_title_type: { id: 1, name: "Included", value: true },
            behavior_type: { id: 1, name: "Included", value: true },
            center: { lat: 40.64, lng: -73.96 },
            zoom: 6,
            place: null,
            place_default: {
                "latitude": 59.32932349999999,
                "longitude": 18.0685808,
                "radius": 30000,
                "name": "(SE) Stockholm, Sweden, (59.329 lat, 18.069 lng, 30km)",
                "custom": true,
                "negative": false,
                "included": true,
                "include": true,
                "id": 4919442428,
                "code": "se",
                "lat": 59.32932349999999,
                "lng": 18.0685808
            },
            audience: [],
            default_conversion_options: [
                {
                    objective_id: 3,
                    id: 7,
                    optimization_goal: { id: 17, name: "Thruplay", value: "THRUPLAY" }
                },
                {
                    objective_id: 3,
                    id: 6,
                    optimization_goal: { id: 11, name: "Post Engagement", value: "POST_ENGAGEMENT" }
                },
                {
                    objective_id: 3,
                    id: 8,
                    optimization_goal: { id: 10, name: "Page Likes", value: "PAGE_LIKES" }
                },
                {
                    objective_id: 3,
                    id: 1,
                    optimization_goal: { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" }
                },
                {
                    objective_id: 3,
                    id: 2,
                    optimization_goal: { id: 9, name: "App events", value: "OFFSITE_CONVERSIONS" }
                },
                {
                    objective_id: 4,
                    id: 5,
                    optimization_goal: { id: 21, name: "Maximize number of leads", value: "LEAD_GENERATION" }
                }
            ]
        }
    };

    async componentDidMount() {

        let ages = [];
        for (let i = 13; i < 66; i++) {
            ages.push({ id: i, name: i.toString(), value: i.toString() });
        }

        await this.promisedSetState({
            campaign: this.props.campaign,
            ages: ages,
            adset: this.props.adset,
            client: this.props.client,
            wizard: this.props.wizard
        })
        if (this.state.client.channels.filter((item) => { return item.value === "facebook" }).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => { return item.value === "facebook" })[0].currency
            })
        }

        if (!this.state.wizard) {
            this.functions.locations();
        }

        this.functions.map().then((response) => {
            try {
                this.autocomplete = new response.maps.places.AutocompleteService();
                this.geocoder = new response.maps.Geocoder;
            } catch (e) { }
        });

        if (this.props.updated) {
            await this.promisedSetState({
                updated: this.props.updated
            });

            if (!this.state.updated.advantage_audience) {
                this.state.updated.advantage_audience = { id: 1, name: "Select ...", value: false };
                await this.promisedSetState({
                    updated: this.props.updated
                });
            }

            if (this.state.campaign.length > 0 && this.state.campaign[0].scheduler && !this.state.campaign[0].scheduler.schedule && this.state.campaign[0].budget_optimization.id == 1 && this.state.updated.scheduler) {
                console.log("scheduler");
                this.state.tabs.push({ id: 11, name: "Scheduler", value: "schedule" });
                if (this.state.updated.scheduler.planer) {
                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                    } else {
                        this.state.updated.daily_budget = 0;
                    }
                }
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    updated: this.state.updated
                });
            }
        }

        this.functions.platforms();
        this.functions.audience();
        this.functions.behaviors();
        this.functions.pixels();
        this.functions.listApps();
        this.functions.listPages();
        this.functions.reach();
        this.functions.gender();

        try {
            if (Object.keys(this.state.updated).length > 0 && Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0) {
                let only_custom = true;
                for (let location of this.state.updated.locations) {
                    if (!location.hasOwnProperty('custom')) {
                        only_custom = false;
                        break;
                    }
                }

                if (only_custom) {
                    this.state.map = { id: 2, name: "Map View", value: true }
                    this.state.place = this.state.updated.locations[0];
                    await this.promisedSetState({ map: this.state.map, place: this.state.place });
                }
            }
        } catch (error) { }

    }

    componentWillReceiveProps(nextProps) {
        /*
        this.setState({
            //adset: nextProps.adset,
            //client: nextProps.client,
            wizard: nextProps.wizard
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }
        */
    }

    functions = {
        gender: async () => {
            try {
                if (this.state.updated.gender) {
                    this.state.genders = this.state.genders.map((item) => {
                        if (item.id !== this.state.updated.gender.id) {
                            item.selected = false;
                        } else {
                            item.selected = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ genders: this.state.genders });
                }
            } catch (error) {

            }
        },
        reach: async () => {
            try {
                if (this.state.updated.audience && this.state.updated.audience.filter((item) => { return item.included }).length > 0) {
                    if (this.props.onReach) {
                        this.props.onReach({
                            estimate_ready: true,
                            estimate_not_count: true
                        });
                    }
                } else {
                    let response = await this.calls.reach(this.state.updated);
                    let reach = { ...response.data };
                    if (this.props.onReach) {
                        this.props.onReach(reach ? reach : {});
                    }
                }

            } catch (error) {
                if (this.props.onReach) {
                    this.props.onReach({});
                }
            }
        },
        update: async () => {
            if (!this.state.wizard) {

                this.props.onError(false);
                this.props.onSuccess(false);
                this.props.onLoader(true);

                let body = {};
                body.name = this.renders.name();

                if (this.state.adset.targeting) {
                    body.targeting = JSON.parse(JSON.stringify(this.state.adset.targeting));
                } else {
                    body.targeting = {};
                }

                body.targeting.genders = this.renders.gender().value;
                body.targeting.age_min = this.renders.ageMin().value;
                body.targeting.age_max = this.renders.ageMax().value;

                if (this.renders.endDate()) {
                    body.end_time = this.renders.endDate();
                } else {
                    body.end_time = 0;
                }

                if (this.renders.startDate() && "start_date" in this.state.updated) {
                    body.start_time = this.renders.startDate();
                }

                if (this.renders.status().value) {
                    body.status = this.renders.status().value;
                }

                body.targeting.exclusions = {};
                body.targeting.exclusions.interests = this.renders.interests().filter((item) => { return !item.included }).map((item) => { return item.id });
                body.targeting.exclusions.behaviors = this.renders.behaviors().filter((item) => { return !item.included }).map((item) => { return item.id });

                body.targeting.flexible_spec = [{
                    interests: this.renders.interests().filter((item) => { return item.included }).map((item) => { return item.id }),
                    behaviors: this.renders.behaviors().filter((item) => { return item.included }).map((item) => { return item.id })
                }];

                /*
                body.targeting.flexible_spec = body.targeting.flexible_spec.map((item) => {
                    item.interests = this.renders.interests().filter((item) => { return item.included }).map((item) => { return item.id });
                    if (item.interests.length < 1) {
                        delete item.interests;
                    }
                    item.behaviors = this.renders.behaviors().filter((item) => { return item.included }).map((item) => { return item.id });
                    if (item.behaviors.length < 1) {
                        delete item.behaviors;
                    }
                    return item
                });
                */

                body.targeting.geo_locations = {};
                body.targeting.excluded_geo_locations = {};

                if (this.renders.places().value) {
                    body.targeting.geo_locations.location_types = this.renders.places().value;
                    body.targeting.excluded_geo_locations.location_types = this.renders.places().value;
                }

                body.targeting.custom_audiences = this.renders.audience().filter((item) => { return item.included }).map((item) => { return { id: item.id } })
                body.targeting.excluded_custom_audiences = this.renders.audience().filter((item) => { return !item.included }).map((item) => { return { id: item.id } })

                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return item.type == 'country' && item.included }).length > 0) {
                    body.targeting.geo_locations.countries = this.state.updated.locations.filter((item) => { return item.type == 'country' && item.included }).map((item) => { return item.key });
                }
                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return (item.type == 'city' || item.type == 'subcity') && item.included }).length > 0) {
                    body.targeting.geo_locations.cities = this.state.updated.locations.filter((item) => { return (item.type == 'city' || item.type == 'subcity') && item.included }).map((item) => { return { key: item.key } });
                }
                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return (item.type == 'region') && item.included }).length > 0) {
                    body.targeting.geo_locations.regions = this.state.updated.locations.filter((item) => { return (item.type == 'region') && item.included }).map((item) => { return { key: item.key } });
                }
                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return item.custom && item.included }).length > 0) {
                    body.targeting.geo_locations.custom_locations = this.state.updated.locations.filter((item) => { return item.custom && item.included }).map((item) => { return { "latitude": item.lat, "longitude": item.lng, "radius": item.radius / 1000, "distance_unit": "kilometer" } });
                }

                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return item.type == 'country' && !item.included }).length > 0) {
                    body.targeting.excluded_geo_locations.countries = this.state.updated.locations.filter((item) => { return item.type == 'country' && !item.included }).map((item) => { return item.key });
                }
                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return (item.type == 'city' || item.type == 'subcity') && !item.included }).length > 0) {
                    body.targeting.excluded_geo_locations.cities = this.state.updated.locations.filter((item) => { return (item.type == 'city' || item.type == 'subcity') && !item.included }).map((item) => { return { key: item.key } });
                }
                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return (item.type == 'region') && !item.included }).length > 0) {
                    body.targeting.excluded_geo_locations.regions = this.state.updated.locations.filter((item) => { return (item.type == 'region') && !item.included }).map((item) => { return { key: item.key } });
                }
                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return item.custom && !item.included }).length > 0) {
                    body.targeting.excluded_geo_locations.custom_locations = this.state.updated.locations.filter((item) => { return item.custom && !item.included }).map((item) => { return { "latitude": item.lat, "longitude": item.lng, "radius": item.radius / 1000, "distance_unit": "kilometer" } });
                }

                if (this.renders.spendLimit().value) {
                    body.daily_min_spend_target = +this.renders.dailyMinimum() * 100;
                    if (+this.renders.dailyMaximum() > 0) {
                        body.daily_spend_cap = +this.renders.dailyMaximum() * 100;
                    } else {
                        body.daily_spend_cap = 922337203685478;
                    }
                } else {
                    //body.daily_min_spend_target = 0;
                    //body.daily_spend_cap = 922337203685478;
                }

                if (this.renders.adsetBudget().value) {
                    body.daily_budget = +this.renders.dailyBudget();
                }

                //PLACEMENTS
                if (this.renders.autoPlacements().id == 1) {
                    delete body.targeting.audience_network_positions;
                    delete body.targeting.device_platforms;
                    delete body.targeting.facebook_positions;
                    delete body.targeting.instagram_positions;
                    delete body.targeting.messenger_positions;
                    delete body.targeting.publisher_platforms;
                } else {
                    if (this.renders.devices().value) {
                        body.targeting.device_platforms = this.renders.devices().value;
                    }
                    if (Array.isArray(this.state.platforms)) {
                        body.targeting.publisher_platforms = this.state.platforms.filter((item) => { return item.selected }).map((item) => { return item.value });
                    }
                    if (Array.isArray(body.targeting.publisher_platforms) && body.targeting.publisher_platforms.filter((item) => { return item == 'facebook' }).length > 0) {
                        body.targeting.facebook_positions = this.state.facebook_positions.filter((item) => { return item.selected }).map((item) => { return item.value });
                    } else {
                        delete body.targeting.facebook_positions;
                    }
                    if (Array.isArray(body.targeting.publisher_platforms) && body.targeting.publisher_platforms.filter((item) => { return item == 'instagram' }).length > 0) {
                        body.targeting.instagram_positions = this.state.instagram_positions.filter((item) => { return item.selected }).map((item) => { return item.value });
                    } else {
                        delete body.targeting.instagram_positions;
                    }
                    if (Array.isArray(body.targeting.publisher_platforms) && body.targeting.publisher_platforms.filter((item) => { return item == 'audience_network' }).length > 0) {
                        body.targeting.audience_network_positions = this.state.audience_network_positions.filter((item) => { return item.selected }).map((item) => { return item.value });
                    } else {
                        delete body.targeting.audience_network_positions;
                    }
                    if (Array.isArray(body.targeting.publisher_platforms) && body.targeting.publisher_platforms.filter((item) => { return item == 'messenger' }).length > 0) {
                        body.targeting.messenger_positions = this.state.messenger_positions.filter((item) => { return item.selected }).map((item) => { return item.value });
                    } else {
                        delete body.targeting.messenger_positions;
                    }
                }

                try {
                    let response = await this.calls.update(body);
                    let updated_adset = { ...this.state.adset, ...response.data };
                    await this.promisedSetState({
                        adset: updated_adset
                    })
                    this.functions.platforms();
                    this.functions.audience();
                    this.functions.behaviors();
                    this.props.onSuccess("Adset updated");
                    this.props.onUpdate(updated_adset);
                    this.props.onLoader(false);
                } catch (error) {
                    this.props.onError(error.body ? error.body.message : "Something went wrong");
                    this.props.onLoader(false);
                }

            } else {

                //ADD PLACEMENT TO UPDATED
                this.state.updated.platforms = this.state.platforms;
                this.state.updated.facebook_positions = this.state.facebook_positions;
                this.state.updated.instagram_positions = this.state.instagram_positions;
                this.state.updated.messenger_positions = this.state.messenger_positions;
                this.state.updated.audience_network_positions = this.state.audience_network_positions;

                await this.promisedSetState({
                    updated: this.state.updated
                });

                console.log(this.state.updated);

                this.props.onUpdate(this.state.updated);
            }

        },
        locations: () => {
            let locations = [];
            let place = null;
            if (this.state.adset.targeting && this.state.adset.targeting.geo_locations) {
                if (Array.isArray(this.state.adset.targeting.geo_locations.countries)) {
                    this.state.adset.targeting.geo_locations.countries.map((item) => {
                        locations.push({ key: item, name: item, value: item, type: 'country', supports_city: true, included: true });
                    })
                }
                if (Array.isArray(this.state.adset.targeting.geo_locations.cities)) {
                    this.state.adset.targeting.geo_locations.cities.map((item) => {
                        item.type = 'city';
                        item.supports_city = true;
                        item.included = true;
                        locations.push(item);
                    })
                }
                if (Array.isArray(this.state.adset.targeting.geo_locations.regions)) {
                    this.state.adset.targeting.geo_locations.regions.map((item) => {
                        item.type = 'region';
                        item.supports_city = true;
                        item.included = true;
                        locations.push(item);
                    })
                }
                if (Array.isArray(this.state.adset.targeting.geo_locations.places)) {
                    this.state.adset.targeting.geo_locations.places.map((item) => {
                        item.type = 'places';
                        item.supports_city = true;
                        item.included = true;
                        locations.push(item);
                    })
                }
                if (Array.isArray(this.state.adset.targeting.geo_locations.custom_locations)) {
                    this.state.adset.targeting.geo_locations.custom_locations.map((item) => {
                        item.id = Math.floor((Math.random() * 9999999999) + 1);
                        item.type = 'custom_locations';
                        item.custom = true;
                        item.included = true;
                        item.include = true;
                        item.lat = item.latitude;
                        item.lng = item.longitude;
                        item.campaign_edit = true;
                        item.radius = Math.round(item.radius * 1000);
                        item.name = parseFloat(item.lat).toFixed(3) + " lat, " + parseFloat(item.lng).toFixed(3) + " lng";
                        locations.push(item);

                        place = item;

                    })
                }
            }
            if (this.state.adset.targeting && this.state.adset.targeting.excluded_geo_locations) {
                if (Array.isArray(this.state.adset.targeting.excluded_geo_locations.countries)) {
                    this.state.adset.targeting.excluded_geo_locations.countries.map((item) => {
                        locations.push({ name: item, value: item, type: 'country', included: false });
                    })
                }
                if (Array.isArray(this.state.adset.targeting.excluded_geo_locations.cities)) {
                    this.state.adset.targeting.excluded_geo_locations.cities.map((item) => {
                        item.type = 'city';
                        item.included = false;
                        locations.push(item);
                    })
                }
                if (Array.isArray(this.state.adset.targeting.excluded_geo_locations.regions)) {
                    this.state.adset.targeting.excluded_geo_locations.regions.map((item) => {
                        item.type = 'region';
                        item.included = false;
                        locations.push(item);
                    })
                }
                if (Array.isArray(this.state.adset.targeting.excluded_geo_locations.places)) {
                    this.state.adset.targeting.excluded_geo_locations.places.map((item) => {
                        item.type = 'places';
                        item.included = false;
                        locations.push(item);
                    })
                }
                if (Array.isArray(this.state.adset.targeting.excluded_geo_locations.custom_locations)) {
                    this.state.adset.targeting.excluded_geo_locations.custom_locations.map((item) => {
                        item.id = Math.floor((Math.random() * 9999999999) + 1);
                        item.type = 'places';
                        item.custom = true;
                        item.included = false;
                        item.include = false;
                        item.lat = item.latitude;
                        item.lng = item.longitude;
                        item.radius = Math.round(item.radius * 1000);
                        item.name = parseFloat(item.lat).toFixed(3) + " lat, " + parseFloat(item.lng).toFixed(3) + " lng";
                        locations.push(item);
                    })
                }
            }
            this.state.updated.locations = locations;
            this.setState({
                place: place,
                updated: this.state.updated
            })
        },
        platforms: () => {
            if (this.state.wizard) {
                console.log(this.state.updated);
                console.log(this.state.updated.platforms);
                this.setState({
                    platforms: this.state.updated.platforms ? this.state.updated.platforms : this.state.platforms,
                    facebook_positions: this.state.updated.facebook_positions ? this.state.updated.facebook_positions : this.state.facebook_positions,
                    instagram_positions: this.state.updated.instagram_positions ? this.state.updated.instagram_positions : this.state.instagram_positions,
                    messenger_positions: this.state.updated.messenger_positions ? this.state.updated.messenger_positions : this.state.messenger_positions,
                    audience_network_positions: this.state.updated.audience_network_positions ? this.state.updated.audience_network_positions : this.state.audience_network_positions
                }, () => {
                    console.log(this.state.platforms);
                })
            } else {
                if (this.state.adset.targeting && this.state.adset.targeting.publisher_platforms) {
                    this.state.adset.targeting.publisher_platforms.map((item) => {
                        this.state.platforms = this.state.platforms.map((platform) => {
                            if (platform.value == item) {
                                platform.selected = true;
                                if (item === "facebook") {
                                    if (Array.isArray(this.state.adset.targeting.facebook_positions)) {
                                        this.state.adset.targeting.facebook_positions.map((facebook_position) => {
                                            this.state.facebook_positions = this.state.facebook_positions.map((position) => {
                                                if (position.value == facebook_position) {
                                                    position.selected = true;
                                                }
                                                return position;
                                            })
                                        })
                                    } else {
                                        this.state.facebook_positions = this.state.facebook_positions.map((item) => { return item.selected = true });
                                    }
                                } else if (item === "instagram") {
                                    if (Array.isArray(this.state.adset.targeting.instagram_positions)) {
                                        this.state.adset.targeting.instagram_positions.map((instagram_position) => {
                                            this.state.instagram_positions = this.state.instagram_positions.map((position) => {
                                                if (position.value == instagram_position) {
                                                    position.selected = true;
                                                }
                                                return position;
                                            })
                                        })
                                    } else {
                                        this.state.instagram_positions = this.state.instagram_positions.map((item) => { return item.selected = true });
                                    }
                                } else if (item === "messenger") {
                                    if (Array.isArray(this.state.adset.targeting.messenger_positions)) {
                                        this.state.adset.targeting.messenger_positions.map((messenger_position) => {
                                            this.state.messenger_positions = this.state.messenger_positions.map((position) => {
                                                if (position.value == messenger_position) {
                                                    position.selected = true;
                                                }
                                                return position;
                                            })
                                        })
                                    } else {
                                        this.state.messenger_positions = this.state.messenger_positions.map((item) => { return item.selected = true });
                                    }
                                } else if (item === "audience_network") {
                                    if (Array.isArray(this.state.adset.targeting.audience_network_positions)) {
                                        this.state.adset.targeting.audience_network_positions.map((audience_network_position) => {
                                            this.state.audience_network_positions = this.state.audience_network_positions.map((position) => {
                                                if (position.value == audience_network_position) {
                                                    position.selected = true;
                                                }
                                                return position;
                                            })
                                        })
                                    } else {
                                        this.state.audience_network_positions = this.state.audience_network_positions.map((item) => { return item.selected = true });
                                    }
                                }

                            }
                            return platform;
                        })
                    })
                }
                this.setState({
                    facebook_positions: this.state.facebook_positions,
                    instagram_positions: this.state.instagram_positions,
                    messenger_positions: this.state.messenger_positions,
                    audience_network_positions: this.state.audience_network_positions,
                    platforms: this.state.platforms
                })
            }
        },
        searchLocations: async () => {
            try {
                this.promisedSetState({ loading_locations: true });
                let response = await this.calls.locations();
                this.promisedSetState({
                    loading_locations: false, location_options: response.data.map((item) => {
                        item.name = item.name + (item.region ? (", " + item.region) : "") + (item.country_name ? ", " + item.country_name : "") + (item.type ? (" (" + item.type + ")") : "");
                        return item;
                    })
                });
            } catch (error) {
                this.promisedSetState({
                    location_options: []
                })
            }
        },
        listApps: async () => {
            try {
                this.promisedSetState({ loading_apps: true });
                let response = await this.calls.apps();
                let data = [];
                if (this.renders.campaign_objective().id !== 5) {
                    data = [{ id: 0, name: "No App", value: null }];
                } else {
                    data = [];
                }
                data = data.concat(response.data);

                this.promisedSetState({ loading_apps: false, app_options: data });
            } catch (error) {
                this.promisedSetState({
                    app_options: []
                })
            }
        },
        listPages: async () => {
            try {
                this.promisedSetState({ loading_pages: true });
                let response = await this.calls.pages();
                let data = [];
                data = data.concat(response.data);
                this.promisedSetState({ loading_pages: false, pages: data.map((item) => { return { id: item.id, name: item.name, image: item.picture.data.url } }) });
            } catch (error) {
                this.promisedSetState({
                    pages: []
                })
            }
        },
        searchGoogleLocations: async () => {
            let self = this;
            if (self.state.search_locations !== "") {
                await self.promisedSetState({ loading_locations: true });
                self.autocomplete.getPlacePredictions({
                    input: this.state.search_locations
                }, function (predictions, status) {
                    if (status === 'OK' && predictions && predictions.length > 0) {
                        self.promisedSetState({
                            loading_locations: false,
                            location_options: predictions.map((item) => {
                                item.name = item.description;
                                item.custom = true;
                                return item;
                            })
                        });
                    } else {
                        self.promisedSetState({ loading_locations: true, location_options: [] });
                    }
                });
            } else {
                self.promisedSetState({ loading_locations: true, location_options: [] });
            }
        },
        searchInterests: async () => {
            try {
                this.promisedSetState({ loading_interests: true });
                let response = await this.calls.interests();
                this.promisedSetState({ loading_interests: false, interests_options: response.data.map((item) => { item.type = "interests"; return item }) });
            } catch (error) {
                this.promisedSetState({
                    interests_options: []
                })
            }
        },
        searchJob: async () => {
            try {
                this.promisedSetState({ loading_jobs: true });
                let response = await this.calls.jobs();
                this.promisedSetState({ loading_jobs: false, jobs_options: response.data.map((item) => { item.type = "work_positions"; return item }) });
            } catch (error) {
                this.promisedSetState({
                    jobs_options: []
                })
            }
        },
        searchLanguages: async (data) => {
            try {
                this.promisedSetState({ loading_languages: true });
                let response = await this.calls.languages(data);
                this.promisedSetState({ loading_languages: false, languages_options: response.data.map((item) => { item.id = item.key; return item }) });
            } catch (error) {
                this.promisedSetState({
                    languages_options: []
                })
            }
        },
        searchSchool: async () => {
            try {
                this.promisedSetState({ loading_schools: true });
                let response = await this.calls.schools();
                this.promisedSetState({ loading_schools: false, schools_options: response.data.map((item) => { item.type = "education_schools"; return item }) });
            } catch (error) {
                this.promisedSetState({
                    schools_options: []
                })
            }
        },
        searchEducation: async () => {
            try {
                this.promisedSetState({ loading_educations: true });
                let response = await this.calls.educations();
                this.promisedSetState({ loading_educations: false, educations_options: response.data.map((item) => { item.type = "education_majors"; return item }) });
            } catch (error) {
                this.promisedSetState({
                    educations_options: []
                })
            }
        },
        searchEmployer: async () => {
            try {
                this.promisedSetState({ loading_employers: true });
                let response = await this.calls.employers();
                this.promisedSetState({ loading_employers: false, employers_options: response.data.map((item) => { item.type = "work_employers"; return item }) });
            } catch (error) {
                this.promisedSetState({
                    employers_options: []
                })
            }
        },
        map: () => {
            if (!this.googleMapsPromise && !window.google) {
                this.googleMapsPromise = new Promise((resolve) => {
                    window.resolveGoogleMapsPromise = () => {
                        resolve(window.google);
                        delete window.resolveGoogleMapsPromise;
                    };
                    const script = document.createElement("script");
                    const API = "AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow";
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
                    script.async = true;
                    document.body.appendChild(script);
                });
            } else {
                this.googleMapsPromise = new Promise((resolve) => {
                    resolve(window.google);
                });
            }
            return this.googleMapsPromise;
        },
        audience: async () => {
            try {
                this.promisedSetState({ loading_audience: true });
                let response = await this.calls.audience();
                this.promisedSetState({ loading_audience: false, audience: response.data });
            } catch (error) {
                this.promisedSetState({
                    audience: []
                })
            }
        },
        behaviors: async () => {
            try {
                this.promisedSetState({ loading_behaviors: true });
                let response = await this.calls.behaviors();
                this.promisedSetState({ loading_behaviors: false, behaviors: response.data });
            } catch (error) {
                this.promisedSetState({
                    behaviors: []
                })
            }
        },
        pixels: async () => {
            try {
                this.promisedSetState({ loading_pixels: true });
                let response = await this.calls.pixels();
                response.data.unshift({ id: 0, name: "No pixel", value: null });
                this.promisedSetState({ loading_pixels: false, pixels: response.data });
            } catch (error) {
                this.promisedSetState({
                    pixels: []
                })
            }
        }
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/updateAdset?client=" + this.state.client.id + "&id=" + this.state.adset.id;
            return apiRegister.call(options, url);
        },
        languages: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listLanguages?client=" + this.state.client.id + "&search=" + data;
            return apiRegister.call(options, url);
        },
        locations: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listLocations?client=" + this.state.client.id + "&search=" + this.state.search_locations + (this.state.updated.location_type ? "&location_type=" + this.state.updated.location_type.value : "");
            return apiRegister.call(options, url);
        },
        apps: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listApps?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        pages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPages?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        interests: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listInterests?client=" + this.state.client.id + "&search=" + this.state.search_interests;
            return apiRegister.call(options, url);
        },
        jobs: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listDemographics?client=" + this.state.client.id + "&search=" + this.state.search_job + "&type=adworkposition";
            return apiRegister.call(options, url);
        },
        employers: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listDemographics?client=" + this.state.client.id + "&search=" + this.state.search_employer + "&type=adworkemployer";
            return apiRegister.call(options, url);
        },
        educations: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listDemographics?client=" + this.state.client.id + "&search=" + this.state.search_education + "&type=adeducationmajor";
            return apiRegister.call(options, url);
        },
        schools: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listDemographics?client=" + this.state.client.id + "&search=" + this.state.search_school + "&type=adeducationschool";
            return apiRegister.call(options, url);
        },
        audience: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listCustomAudience?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        behaviors: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listBehaviors?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        pixels: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPixels?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        reach: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/reachEstimate?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        audience_options: (type) => {
            try {
                if (type === "interests") {
                    return Array.isArray(this.state.interests_options) ? this.state.interests_options : [];
                } else if (type === "behaviors") {
                    return Array.isArray(this.state.behaviors) ? this.state.behaviors : [];
                } else if (type === "education_schools") {
                    return Array.isArray(this.state.schools_options) ? this.state.schools_options : [];
                } else if (type === "education_majors") {
                    return Array.isArray(this.state.educations_options) ? this.state.educations_options : [];
                } else if (type === "work_employers") {
                    return Array.isArray(this.state.employers_options) ? this.state.employers_options : [];
                } else if (type === "work_positions") {
                    return Array.isArray(this.state.jobs_options) ? this.state.jobs_options : [];
                }

            } catch (error) {
                console.log("error HANDLE", error);
                return [];
            }
        },
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.adset.name) {
                    return this.state.adset.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        beneficiary: () => {
            try {
                if ('beneficiary' in this.state.updated) {
                    return this.state.updated.beneficiary;
                } else if (this.state.adset.beneficiary) {
                    return this.state.adset.beneficiary;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        payer: () => {
            try {
                if ('payor' in this.state.updated) {
                    return this.state.updated.payor;
                } else if (this.state.adset.payor) {
                    return this.state.adset.payor;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        gender: () => {
            try {
                if (this.state.updated.gender) {
                    return this.state.updated.gender;
                } else {
                    if (this.state.adset.targeting && this.state.adset.targeting.genders) {
                        return this.state.adset.targeting.genders[0] == 2 ? { id: 3, name: "Female only", value: [2] } : { id: 2, name: "Men only", value: [1] };
                    } else {
                        return { id: 1, name: "All genders", value: [] };
                    }
                }
            } catch (error) {
                return { id: 1, name: "All genders", value: [] };
            }
        },
        ageMin: () => {
            try {
                if (this.state.updated.age_min) {
                    return this.state.updated.age_min;
                } else {
                    if (this.state.adset.targeting && this.state.adset.targeting.age_min) {
                        return this.state.ages.filter((item) => { return item.value == this.state.adset.targeting.age_min.toString() })[0];
                    } else {
                        return { id: 18, name: "18", value: "18" };
                    }
                }
            } catch (error) {
                return { id: 18, name: "18", value: "18" };
            }
        },
        ageMax: () => {
            try {
                if (this.state.updated.age_max) {
                    return this.state.updated.age_max;
                } else {
                    if (this.state.adset.targeting && this.state.adset.targeting.age_max) {
                        return this.state.ages.filter((item) => { return item.value == this.state.adset.targeting.age_max.toString() })[0];
                    } else {
                        return { id: 65, name: "65", value: "65" };
                    }
                }
            } catch (error) {
                return { id: 65, name: "65", value: "65" };
            }
        },
        places: () => {
            try {
                if (this.state.updated.places) {
                    return this.state.updated.places;
                } else {
                    if (this.state.adset.targeting && this.state.adset.targeting.geo_locations && Array.isArray(this.state.adset.targeting.location_types)) {
                        if (this.state.adset.targeting.location_types.length == 1 && this.state.adset.targeting.location_types[0] == "home") {
                            return { id: 2, name: "People living in this location", value: ["home"] };
                        } else if (this.state.adset.targeting.location_types.length == 1 && this.state.adset.targeting.location_types[0] == "travel_in") {
                            return { id: 4, name: "People traveling in this location", value: ["travel_in"] };
                        } else if (this.state.adset.targeting.location_types.length == 1 && this.state.adset.targeting.location_types[0] == "recent") {
                            return { id: 3, name: "People recently in this location", value: ["recent"] };
                        } else {
                            return { id: 1, name: "People living in or recently in this location", value: ["home", "recent"] };
                        }
                    } else {
                        return { id: 1, name: "People living in or recently in this location", value: ["home", "recent"] };
                    }
                }
            } catch (error) {
                return { id: 1, name: "People living in or recently in this location", value: ["home", "recent"] };
            }
        },
        spendLimit: () => {
            try {
                if (this.state.updated.spend_limit) {
                    return this.state.updated.spend_limit;
                } else if (this.state.adset.daily_min_spend_target || this.state.adset.daily_spend_cap) {
                    return { id: 1, name: "Yes", value: true };
                } else {
                    return { id: 2, name: "No", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        status: () => {
            try {
                if (this.state.updated.status) {
                    return this.state.updated.status;
                } else if (this.state.adset.status == 'ACTIVE') {
                    return { id: 1, name: "Active", value: "ACTIVE" };
                } else if (this.state.adset.status == 'PAUSED') {
                    return { id: 2, name: "Paused", value: "PAUSED" };
                } else if (this.state.adset.status == 'ARCHIVED') {
                    return { id: 3, name: "Archived", value: "ARCHIVED" };
                } else if (this.state.adset.status == 'DELETED') {
                    return { id: 4, name: "Deleted", value: "DELETED" };
                } else {
                    return { id: 1, name: "Active", value: "ACTIVE" };
                }
            } catch (error) {
                return { id: 1, name: "Active", value: "ACTIVE" };
            }
        },
        store: () => {
            try {
                if ("store" in this.state.updated) {
                    return this.state.updated.store;
                } else {
                    return { id: 0, name: "Select store", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select store", value: null };
            }
        },
        campaign_objective: () => {
            try {
                if ("campaign_objective" in this.state.updated) {
                    return this.state.updated.campaign_objective;
                } else {
                    return { id: 0, value: null };
                }
            } catch (error) {
                return { id: 0, value: null };
            }
        },
        campaign_budget_optimization: () => {
            try {
                if ("campaign_budget_optimization" in this.state.updated) {
                    return this.state.updated.campaign_budget_optimization;
                } else {
                    return { id: 1, name: "No", value: false };
                }
            } catch (error) {
                return { id: 1, name: "No", value: false };
            }
        },
        campaign_smart_promotion_type: () => {
            try {
                if ("campaign_smart_promotion_type" in this.state.updated) {
                    return this.state.updated.campaign_smart_promotion_type;
                } else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        },
        optimizationGoal: () => {
            try {
                if ("optimization_goal" in this.state.updated) {
                    return this.state.updated.optimization_goal;
                } else {
                    return { id: 0, name: "Select goal", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select goal", value: null };
            }
        },
        destination: () => {
            try {
                if ("destination_type" in this.state.updated) {
                    return this.state.updated.destination_type;
                } else {
                    return { id: 0, name: "Select destination", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select destination", value: null };
            }
        },
        application: () => {
            try {
                if ("application" in this.state.updated) {
                    return this.state.updated.application;
                } else {
                    return { id: 0, name: "No App", value: null };
                }
            } catch (error) {
                return { id: 0, name: "No App", value: null };
            }
        },
        page: () => {
            try {
                if ("page" in this.state.updated) {
                    return this.state.updated.page;
                } else {
                    return { id: 0, name: "Select page", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select page", value: null };
            }
        },
        eventType: () => {
            try {
                if ("event_type" in this.state.updated) {
                    return this.state.updated.event_type;
                } else {
                    return { id: 0, name: "Select event", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select event", value: null };
            }
        },
        pixel: () => {
            try {
                if ("pixel" in this.state.updated) {
                    return this.state.updated.pixel;
                } else {
                    return { id: 0, name: "Select pixel", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select pixel", value: null };
            }
        },
        billingEvent: () => {
            try {
                if ("billing_event" in this.state.updated) {
                    return this.state.updated.billing_event;
                } else {
                    return { id: 0, name: "Select billing event", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select billing event", value: null };
            }
        },
        adsetBudget: () => {
            try {
                if (this.state.updated.adset_budget) {
                    return this.state.updated.adset_budget;
                } else if (this.state.adset.daily_budget && this.state.adset.daily_budget > 0) {
                    return { id: 1, name: "Yes", value: true };
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Yes", value: true };
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else if (this.state.adset.daily_budget && this.state.adset.daily_budget != "0") {
                    return +this.state.adset.daily_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        dailyMinimum: () => {
            try {
                if ('daily_minimum' in this.state.updated) {
                    return this.state.updated.daily_minimum;
                } else if (this.state.adset.daily_min_spend_target && this.state.adset.daily_min_spend_target != "0") {
                    return +this.state.adset.daily_min_spend_target / 100;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        dailyMaximum: () => {
            try {
                if ('daily_maximum' in this.state.updated) {
                    return this.state.updated.daily_maximum;
                } else if (this.state.adset.daily_spend_cap && this.state.adset.daily_spend_cap !== "0") {
                    return +this.state.adset.daily_spend_cap / 100;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        autoPlacements: () => {
            try {
                if ("auto_placements" in this.state.updated) {
                    return this.state.updated.auto_placements;
                } else if (this.state.adset.targeting && this.state.adset.targeting.device_platforms) {
                    return { id: 2, name: "No", value: false };
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Yes", value: true };
            }
        },
        devices: () => {
            try {
                if ("devices" in this.state.updated) {
                    return this.state.updated.devices;
                } else {
                    if (this.state.adset.targeting && this.state.adset.targeting.device_platforms && Array.isArray(this.state.adset.targeting.device_platforms)) {
                        if (this.state.adset.targeting.device_platforms.length == 1 && this.state.adset.targeting.device_platforms[0] == "mobile") {
                            return { id: 2, name: "Mobile only", value: ["mobile"] };
                        } else if (this.state.adset.targeting.device_platforms.length == 1 && this.state.adset.targeting.device_platforms[0] == "desktop") {
                            return { id: 3, name: "Desktop only", value: ["desktop"] };
                        } else {
                            return { id: 1, name: "All devices (recommended)", value: ["mobile", "desktop"] };
                        }
                    } else {
                        return { id: 1, name: "All devices (recommended)", value: ["mobile", "desktop"] };
                    }
                }
            } catch (error) {
                return { id: 1, name: "All devices (recommended)", value: ["mobile", "desktop"] };
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else {
                    return moment(this.state.adset.start_time).format("YYYY-MM-DD");
                }
            } catch (error) {
                return 0;
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date ? this.state.updated.end_date : null;
                } else {
                    return this.state.adset.end_time ? moment(this.state.adset.end_time).format("YYYY-MM-DD") : null;
                }
            } catch (error) {
                return 0;
            }
        },
        audience: () => {
            try {
                if ('audience' in this.state.updated) {
                    return this.state.updated.audience;
                } else {
                    let adset = JSON.parse(JSON.stringify(this.state.adset));
                    let included = Array.isArray(adset.targeting.custom_audiences) ? adset.targeting.custom_audiences.map((item) => { item.included = true; return item }) : [];
                    let excluded = Array.isArray(adset.targeting.excluded_custom_audiences) ? adset.targeting.excluded_custom_audiences.map((item) => { item.included = false; return item }) : [];
                    return included.concat(excluded);
                }
            } catch (error) {
                return [];
            }
        },
        interests: () => {
            try {
                if ('interests' in this.state.updated) {
                    return this.state.updated.interests;
                } else {
                    let adset = JSON.parse(JSON.stringify(this.state.adset));
                    let included = [];
                    let excluded = [];
                    if (adset.targeting && adset.targeting.exclusions && Array.isArray(adset.targeting.exclusions.interests)) {
                        excluded = adset.targeting.exclusions.interests.map((item) => { item.included = false; return item });
                    }
                    if (adset.targeting && Array.isArray(adset.targeting.flexible_spec) && adset.targeting.flexible_spec.filter((item) => { return item.interests }).length > 0) {
                        let interests = adset.targeting.flexible_spec.filter((item) => { return item.interests }).map((item) => { return item.interests })[0];
                        included = interests.map((item) => { item.included = true; return item });
                    }
                    return included.concat(excluded);
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        work_positions: () => {
            try {
                if ('work_positions' in this.state.updated) {
                    return this.state.updated.work_positions;
                } else {
                    let adset = JSON.parse(JSON.stringify(this.state.adset));
                    let included = [];
                    let excluded = [];
                    if (adset.targeting && adset.targeting.exclusions && Array.isArray(adset.targeting.exclusions.work_positions)) {
                        excluded = adset.targeting.exclusions.work_positions.map((item) => { item.included = false; return item });
                    }
                    if (adset.targeting && Array.isArray(adset.targeting.flexible_spec) && adset.targeting.flexible_spec.filter((item) => { return item.work_positions }).length > 0) {
                        let work_positions = adset.targeting.flexible_spec.filter((item) => { return item.work_positions }).map((item) => { return item.work_positions })[0];
                        included = work_positions.map((item) => { item.included = true; return item });
                    }
                    return included.concat(excluded);
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        work_employers: () => {
            try {
                if ('work_employers' in this.state.updated) {
                    return this.state.updated.work_employers;
                } else {
                    let adset = JSON.parse(JSON.stringify(this.state.adset));
                    let included = [];
                    let excluded = [];
                    if (adset.targeting && adset.targeting.exclusions && Array.isArray(adset.targeting.exclusions.work_employers)) {
                        excluded = adset.targeting.exclusions.work_employers.map((item) => { item.included = false; return item });
                    }
                    if (adset.targeting && Array.isArray(adset.targeting.flexible_spec) && adset.targeting.flexible_spec.filter((item) => { return item.work_employers }).length > 0) {
                        let work_employers = adset.targeting.flexible_spec.filter((item) => { return item.work_employers }).map((item) => { return item.work_employers })[0];
                        included = work_employers.map((item) => { item.included = true; return item });
                    }
                    return included.concat(excluded);
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        education_majors: () => {
            try {
                if ('education_majors' in this.state.updated) {
                    return this.state.updated.education_majors;
                } else {
                    let adset = JSON.parse(JSON.stringify(this.state.adset));
                    let included = [];
                    let excluded = [];
                    if (adset.targeting && adset.targeting.exclusions && Array.isArray(adset.targeting.exclusions.education_majors)) {
                        excluded = adset.targeting.exclusions.education_majors.map((item) => { item.included = false; return item });
                    }
                    if (adset.targeting && Array.isArray(adset.targeting.flexible_spec) && adset.targeting.flexible_spec.filter((item) => { return item.education_majors }).length > 0) {
                        let education_majors = adset.targeting.flexible_spec.filter((item) => { return item.education_majors }).map((item) => { return item.education_majors })[0];
                        included = education_majors.map((item) => { item.included = true; return item });
                    }
                    return included.concat(excluded);
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        education_schools: () => {
            try {
                if ('education_schools' in this.state.updated) {
                    return this.state.updated.education_schools;
                } else {
                    let adset = JSON.parse(JSON.stringify(this.state.adset));
                    let included = [];
                    let excluded = [];
                    if (adset.targeting && adset.targeting.exclusions && Array.isArray(adset.targeting.exclusions.education_schools)) {
                        excluded = adset.targeting.exclusions.education_schools.map((item) => { item.included = false; return item });
                    }
                    if (adset.targeting && Array.isArray(adset.targeting.flexible_spec) && adset.targeting.flexible_spec.filter((item) => { return item.education_schools }).length > 0) {
                        let education_schools = adset.targeting.flexible_spec.filter((item) => { return item.education_schools }).map((item) => { return item.education_schools })[0];
                        included = education_schools.map((item) => { item.included = true; return item });
                    }
                    return included.concat(excluded);
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        languages: () => {
            try {
                if ('languages' in this.state.updated) {
                    return this.state.updated.languages;
                } else {
                    return []
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        behaviors: () => {
            try {
                if ('behaviors' in this.state.updated) {
                    return this.state.updated.behaviors;
                } else {
                    let adset = JSON.parse(JSON.stringify(this.state.adset));
                    let included = [];
                    let excluded = [];
                    if (adset.targeting && adset.targeting.exclusions && Array.isArray(adset.targeting.exclusions.behaviors)) {
                        excluded = adset.targeting.exclusions.behaviors.map((item) => { item.included = false; return item });
                    }
                    if (adset.targeting && Array.isArray(adset.targeting.flexible_spec) && adset.targeting.flexible_spec.filter((item) => { return item.behaviors }).length > 0) {
                        let behaviors = adset.targeting.flexible_spec.filter((item) => { return item.behaviors }).map((item) => { return item.behaviors })[0];
                        included = behaviors.map((item) => { item.included = true; return item });
                    }
                    return included.concat(excluded);
                }
            } catch (error) {
                return [];
            }
        },
        frequency: () => {
            try {
                if ('frequency' in this.state.updated) {
                    return this.state.updated.frequency;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        frequency_schedule: () => {
            try {
                if ('frequency_schedule' in this.state.updated) {
                    return this.state.updated.frequency_schedule;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        let selected_targeting = (type, add = false) => {
            let buffer = [];
            ["interests", "behaviors", "education_schools", "education_majors", "work_employers", "work_positions"].map((item) => {
                if (this.renders[item]().length > 0) {
                    buffer = buffer.concat(this.renders[item]());
                }
            })
            return (
                buffer.sort(function (x, y) {
                    return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                }).map((item, index) => {
                    return (
                        <Fragment>
                            <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex m-1 rounded-full items-center py-1.5 px-3 text-sm font-medium "}>
                                <div className="flex flex-1">{item.name}</div>
                                <button
                                    onClick={() => {
                                        console.log(item, "===HEREEEE====");
                                        this.state.updated[item.type] = this.renders[item.type]().filter((inner_inner_item) => {
                                            return inner_inner_item.id !== item.id;
                                        })
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                        this.functions.reach();
                                    }}
                                    type="button"
                                    className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                >
                                    <span className="sr-only">Remove</span>
                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                    </svg>
                                </button>
                            </div>
                        </Fragment>
                    )
                })

            )
        }
        return (
            <>
                <div className="grid grid-cols-3 gap-4">

                    <div className="col-span-3">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.filter((item) => {
                                    return (item.id !== 6 && item.id !== 7 && item.id !== 3 && item.id !== 4) || (item.id === 6 && ((this.renders.campaign_objective().id === 3 && this.renders.optimizationGoal().id === 9) || (this.renders.campaign_objective().id === 4) || (this.renders.campaign_objective().id === 6))) || (item.id === 7 && (this.renders.campaign_objective().id === 5 || (this.renders.campaign_objective().id === 6 && this.renders.destination().id === 2) || (this.renders.campaign_objective().id === 2 && this.renders.destination().id === 2))) || (item.id === 3 && (this.renders.campaign_objective().id !== 5 || (this.renders.campaign_objective().id === 5 && this.renders.campaign_smart_promotion_type().id !== 1))) || (item.id === 4 && (this.renders.campaign_objective().id !== 5 || (this.renders.campaign_objective().id === 5 && this.renders.campaign_smart_promotion_type().id !== 1)))
                                }).map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {

                                                (tab.id === 1 && (adgroupFacebook.validate(this.state.updated).name || adgroupFacebook.validate(this.state.updated).destination_type || adgroupFacebook.validate(this.state.updated).page)) ||
                                                    (tab.id === 2 && (adgroupFacebook.validate(this.state.updated).start_date || adgroupFacebook.validate(this.state.updated).end_date || adgroupFacebook.validate(this.state.updated).budget)) ||
                                                    (tab.id === 5 && adgroupFacebook.validate(this.state.updated).location) ||
                                                    (tab.id === 7 && adgroupFacebook.validate(this.state.updated).app) ||
                                                    (tab.id === 4 && adgroupFacebook.validate(this.state.updated).placements) ||
                                                    (tab.id === 8 && (adgroupFacebook.validate(this.state.updated).beneficiary || adgroupFacebook.validate(this.state.updated).payor)) ||
                                                    (tab.id === 11 && ((this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity) || Object.keys(this.state.updated.scheduler.weekdays).length < 1) && this.state.updated.scheduler.schedule) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*Basic*/}
                    {
                        this.state.tab.id == 1 &&
                        <>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Adset name"}
                                    error={adgroupFacebook.validate(this.state.updated).name}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.campaign_objective().id !== 1 && this.renders.campaign_objective().id !== 5 &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Conversion location"}
                                        error={adgroupFacebook.validate(this.state.updated).destination_type}
                                        selected={this.renders.destination()}
                                        options={(this.renders.campaign_objective().id === 3 ? this.state.destination_types : (this.renders.campaign_objective().id === 4 ? [{ id: 1, name: "Website", value: "WEBSITE" }, { id: 2, name: "App", value: "APP" }, { id: 5, name: "Instant forms", value: "ON_AD" }] : [{ id: 1, name: "Website", value: "WEBSITE" }, { id: 2, name: "App", value: "APP" }]))}
                                        onChange={(value) => {
                                            this.state.updated.destination_type = value;
                                            if (value.id !== 2) {
                                                this.state.updated.application = { id: 0, name: "No App", value: null };
                                                this.state.updated.store = { id: 0, name: "Select store", value: null };
                                            }
                                            this.state.default_conversion_options.filter((item) => { return item.objective_id === this.renders.campaign_objective().id }).map((item) => {
                                                if (value.id === item.id) {
                                                    this.state.updated.optimization_goal = item.optimization_goal;
                                                }
                                            })
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                (this.renders.destination().id === 8) &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Facebook page"}
                                        error={adgroupFacebook.validate(this.state.updated).page}
                                        selected={this.renders.page()}
                                        options={this.state.pages}
                                        onChange={(value) => {
                                            this.state.updated.page = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }

                            {
                                !this.state.wizard &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Status"}
                                        selected={this.renders.status()}
                                        locked={this.renders.status().id == 4 || this.renders.status().id == 3}
                                        options={[
                                            { id: 1, name: "Active", value: "ACTIVE" },
                                            { id: 2, name: "Paused", value: "PAUSED" },
                                            //{ id: 3, name: "Archived", value: "ARCHIVED" },
                                            //{ id: 4, name: "Deleted", value: "DELETED" }
                                        ]}
                                        onChange={(value) => {
                                            this.state.updated.status = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                        </>
                    }

                    {/*Budget & Schedule*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className="col-span-3">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3 grid grid-cols-6 gap-4">
                                        <div className="col-span-3">
                                            <InputDatepickerTailwind
                                                label={"Start date"}
                                                error={adgroupFacebook.validate(this.state.updated).start_date}
                                                value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                                onChange={(value) => {
                                                    let time = moment(this.renders.startDate()).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.updated.start_date = moment(new_date);
                                                    if (this.state.updated.scheduler) {
                                                        this.state.updated.scheduler.start_date = moment(new_date);
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                    }
                                                    if (this.renders.endDate() && new Date(this.state.updated.start_date).getTime() > new Date(this.renders.endDate()).getTime()) {
                                                        this.state.updated.end_date = moment(new_date).add(1, 'days');
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTimepickerTailwind
                                                label={"Start time"}
                                                error={adgroupFacebook.validate(this.state.updated).start_date}
                                                value={moment(this.renders.startDate()).format("HH:mm")}
                                                onChange={(value) => {
                                                    let date = moment(this.renders.startDate()).format("YYYY-MM-DD");
                                                    let new_date = date + " " + value;
                                                    this.state.updated.start_date = moment(new_date);
                                                    if (this.state.updated.scheduler) {
                                                        this.state.updated.scheduler.start_date = moment(new_date);;

                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className={"col-span-3 grid grid-cols-6 col-gap-4"}>
                                        <div className={(this.renders.endDate() ? "col-span-3" : "col-span-6")}>
                                            <InputDatepickerTailwind
                                                label={"End date"}
                                                disabled={!this.renders.endDate()}
                                                minDate={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                                error={adgroupFacebook.validate(this.state.updated).end_date}
                                                labelRight={this.renders.endDate() ? "Disable" : "Enable"}
                                                placeholder={!this.renders.endDate() ? "No end date" : ""}
                                                value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                                onChange={async (value) => {
                                                    let time = moment(this.renders.endDate()).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.updated.end_date = moment(new_date);
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                                onLabelRightClick={async (value) => {
                                                    if (this.renders.endDate()) {
                                                        this.state.updated.end_date = null;
                                                    } else {
                                                        let new_date = moment(this.renders.startDate()).add(1, 'days');
                                                        this.state.updated.end_date = moment(new_date);
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.renders.endDate() &&
                                            <div className="col-span-3">
                                                <InputTimepickerTailwind
                                                    label={"End time"}
                                                    error={adgroupFacebook.validate(this.state.updated).end_date}
                                                    placeholder={!this.renders.endDate() ? "No end time" : ""}
                                                    disabled={!this.renders.endDate()}
                                                    value={moment(this.renders.endDate()).format("HH:mm")}
                                                    onChange={async (value) => {
                                                        let date = moment(this.renders.endDate()).format("YYYY-MM-DD");
                                                        let new_date = date + " " + value;
                                                        this.state.updated.end_date = moment(new_date);
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-3 grid grid-cols-6 gap-4">
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Optimization goal"}
                                        selected={this.renders.optimizationGoal()}
                                        options={
                                            ((this.renders.campaign_objective().id === 3 || this.renders.campaign_objective().id === 4) && this.renders.destination().id ? this.state.optimization_goals_new[this.renders.campaign_objective().id][this.renders.destination().id] : (this.renders.destination().id === 0 && (this.renders.campaign_objective().id === 3 || this.renders.campaign_objective().id === 4) ? [] : this.state.optimization_goals_new[this.renders.campaign_objective().id]))
                                        }
                                        locked={(this.renders.campaign_objective().id === 5 && this.renders.campaign_smart_promotion_type().id === 1 ? true : false)}
                                        onChange={(value) => {
                                            this.state.updated.optimization_goal = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>

                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Billing event"}
                                        selected={this.renders.billingEvent()}
                                        options={this.state.billing_events}
                                        onChange={(value) => {
                                            this.state.updated.billing_event = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            {
                                this.renders.campaign_objective().id === 1 && this.renders.optimizationGoal().id === 13 &&
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3">
                                        <InputTailwind
                                            label={"Frequency (impressions)"}
                                            leftSection={false}
                                            value={this.renders.frequency()}
                                            onChange={(value) => {
                                                this.state.updated.frequency = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <InputTailwind
                                            label={"Frequency schedule (days)"}
                                            leftSection={false}
                                            value={this.renders.frequency_schedule()}
                                            onChange={(value) => {
                                                this.state.updated.frequency_schedule = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                this.state.wizard && this.renders.campaign_budget_optimization().id === 1 &&
                                <div className="col-span-3">
                                    <div className='grid grid-cols-6 gap-4'>
                                        <div className={"col-span-3"}>
                                            <DropdownTailwind
                                                label={"Ad Set Budget"}
                                                selected={this.renders.adsetBudget()}
                                                options={[
                                                    { id: 1, name: "Yes", value: true }
                                                ]}
                                                onChange={(value) => {
                                                    if (value.id === 2) {
                                                        this.state.updated.daily_budget = 0;
                                                        this.state.updated.lifetime_budget = 0;
                                                    }
                                                    this.state.updated.adset_budget = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={(this.renders.adsetBudget().id == 1 ? "" : "opacity-50 cursor-not-allowed") + " col-span-3 grid grid-cols-6 gap-4"}>
                                            <div className="col-span-3">
                                                <DropdownTailwind
                                                    label={"Adset budget"}
                                                    selected={this.state.updated.budget_type ? this.state.updated.budget_type : { id: 1, name: "Daily budget", value: 'daily' }}
                                                    options={[
                                                        { id: 1, name: "Daily budget", value: 'daily' },
                                                        { id: 2, name: "Lifetime budget", value: 'total' }
                                                    ]}
                                                    disabled={this.renders.adsetBudget().id !== 1 || (this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer)}
                                                    locked={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer ? true : false}
                                                    onChange={(value) => {
                                                        if (value.id === 1) {
                                                            this.state.updated.lifetime_budget = 0;
                                                        } else {
                                                            this.state.updated.daily_budget = 0;
                                                        }
                                                        this.state.updated.budget_type = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                        adgroupFacebook.validate(this.state.updated)
                                                    }}
                                                />
                                            </div>
                                            {
                                                (!this.state.updated.budget_type || (this.state.updated.budget_type && this.state.updated.budget_type.id === 1)) &&
                                                <div className="col-span-3">
                                                    <InputTailwind
                                                        error={adgroupFacebook.validate(this.state.updated).budget}
                                                        disabled={this.renders.adsetBudget().id !== 1 || (this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer)}
                                                        locked={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer ? true : false}
                                                        label={"Amount"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.daily_budget}
                                                        onChange={(value) => {
                                                            value = value.replace(/[\D]/g, '')
                                                            this.state.updated.daily_budget = value;
                                                            this.setState({
                                                                updated: this.state.updated
                                                            })
                                                            adgroupFacebook.validate(this.state.updated)
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                this.state.updated.budget_type && this.state.updated.budget_type.id === 2 &&
                                                <div className="col-span-3">
                                                    <InputTailwind
                                                        error={adgroupFacebook.validate(this.state.updated).budget}
                                                        disabled={this.renders.adsetBudget().id !== 1}
                                                        label={"Amount"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.lifetime_budget}
                                                        onChange={(value) => {
                                                            value = value.replace(/[\D]/g, '')
                                                            this.state.updated.lifetime_budget = value;
                                                            this.setState({
                                                                updated: this.state.updated
                                                            })
                                                            adgroupFacebook.validate(this.state.updated)
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.renders.campaign_budget_optimization().id === 2 &&
                                <div className='grid grid-cols-6 gap-4 col-span-3'>
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Ad Set Spend limits"}
                                            selected={this.renders.spendLimit()}
                                            options={[
                                                { id: 1, name: "Yes", value: true },
                                                { id: 2, name: "No", value: false }
                                            ]}
                                            onChange={(value) => {
                                                this.state.updated.spend_limit = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={(this.renders.spendLimit().id == 1 ? "" : "opacity-50 cursor-not-allowed") + " col-span-3"}>
                                        <div className="grid grid-cols-6 gap-4">
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    leftSection={(this.state.currency !== "" ? true : false)}
                                                    leftSectionLabel={(this.state.currency)}
                                                    disabled={this.renders.spendLimit().id !== 1}
                                                    label={"Daily minimum"}
                                                    type={"number"}
                                                    value={this.renders.dailyMinimum()}
                                                    onChange={(value) => {
                                                        this.state.updated.daily_minimum = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    leftSection={(this.state.currency !== "" ? true : false)}
                                                    leftSectionLabel={(this.state.currency)}
                                                    disabled={this.renders.spendLimit().id !== 1}
                                                    label={"Daily maximum"}
                                                    type={"number"}
                                                    value={this.renders.dailyMaximum()}
                                                    onChange={(value) => {
                                                        this.state.updated.daily_maximum = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }

                    {/*Audience*/}
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-3">
                                <div className="text-xs font-medium text-gray-700 mb-1">
                                    Gender
                                </div>
                                <div className="flex flex-row flex-wrap">
                                    {
                                        this.state.genders.map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    item.selected = true;
                                                    this.state.updated.gender = item;
                                                    this.state.genders = this.state.genders.map((inner_item) => {
                                                        if (inner_item.id !== item.id) {
                                                            inner_item.selected = false;
                                                        } else {
                                                            inner_item.selected = true;
                                                        }
                                                        return inner_item;
                                                    })
                                                    this.setState({
                                                        updated: this.state.updated,
                                                        genders: this.state.genders
                                                    })
                                                    this.functions.reach();

                                                }} className={(item.selected ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{item.name}</div>
                                                    <div className="ml-3">
                                                        <div className={(item.selected ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(item.selected ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Age min"}
                                            selected={this.renders.ageMin()}
                                            options={this.state.ages}
                                            onChange={(value) => {
                                                this.state.updated.age_min = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.reach();
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Age max"}
                                            selected={this.renders.ageMax()}
                                            options={this.state.ages}
                                            onChange={(value) => {
                                                this.state.updated.age_max = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.reach();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    ref="searchDropdown"
                                    label={"Languages"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={Array.isArray(this.state.languages_options) ? this.state.languages_options : []}
                                    loadingSearch={this.state.loading_languages}
                                    onChange={(value) => {
                                        if (!Array.isArray(this.state.updated.languages)) {
                                            this.state.updated.languages = [];
                                        }
                                        if (this.state.updated.languages.filter((item) => { return item.id === value.id }).length < 1) {
                                            this.state.updated.languages.push(value);
                                        }
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                        this.functions.reach();
                                    }}
                                    onSearch={async (value) => {
                                        this.functions.searchLanguages(value);
                                    }}
                                />
                            </div>
                            {
                                this.renders.languages().length > 0 &&
                                <div className="col-span-3 flex flex-row flex-wrap">
                                    {
                                        this.renders.languages().map((item, index) => {
                                            let last = this.renders.languages().length - 1 === index;
                                            return (
                                                <Fragment>
                                                    <div className={" bg-indigo-100 text-indigo-700 flex m-1 rounded-full items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <div className="flex flex-1">{item.name}</div>
                                                        <button
                                                            onClick={() => {
                                                                this.state.updated.languages = this.renders.languages().filter((language) => {
                                                                    return language.id !== item.id;
                                                                })
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.reach();
                                                            }}
                                                            type="button"
                                                            className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove language</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            }

                            <div className="col-span-3 text-lg font-semibold">
                                Targeting
                            </div>
                            <div className='col-span-3'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Advantage+ audience</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.state.updated.advantage_audience.value ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.updated.advantage_audience.value ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.advantage_audience.value = !this.state.updated.advantage_audience.value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Type"}
                                    selected={this.state.audience_new}
                                    options={[
                                        { id: 1, name: "Interests", value: "interests" },
                                        { id: 2, name: "Behaviors", value: "behaviors" },
                                        { id: 3, name: "Education school", value: "education_schools" },
                                        { id: 4, name: "Education majors", value: "education_majors" },
                                        { id: 5, name: "Work employers", value: "work_employers" },
                                        { id: 6, name: "Job title", value: "work_positions" },
                                    ]
                                    }
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            audience_new: value
                                        })
                                    }}
                                />
                            </div>

                            {
                                this.state.audience_new.id !== 0 &&
                                <div className="col-span-3">
                                    <div className='flex'>
                                        <div className='mx-4 self-center'>
                                            <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                        </div>
                                        <div className='flex-col flex-1'>
                                            <DropdownTailwind
                                                ref="searchDropdown"
                                                label={"Search: " + this.state.audience_new.name}
                                                rightLabel={true}
                                                rightLabelText={this.state.audience_types.value ? "Included" : "Excluded"}
                                                onRightLabelClick={async () => {
                                                    await this.promisedSetState({ audience_types: !this.state.audience_types.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                                }}
                                                searchInput={true}
                                                loader={this.state.loading_targeting}
                                                selected={{ name: "Search ..." }}
                                                options={this.renders.audience_options(this.state.audience_new.value)}
                                                loadingSearch={this.state.loading_targeting}
                                                onChange={(value) => {
                                                    let buffer = JSON.parse(JSON.stringify(value));

                                                    buffer.name = buffer.name + " [" + this.state.audience_new.name + "] ";
                                                    if (!Array.isArray(this.state.updated[this.state.audience_new.value])) {
                                                        this.state.updated[this.state.audience_new.value] = [];
                                                    }
                                                    if (this.state.audience_types.id == 1) {
                                                        buffer.included = true;
                                                    } else {
                                                        buffer.included = false;
                                                    }
                                                    if (this.state.updated[this.state.audience_new.value].filter((item) => { return item.id === buffer.id }).length < 1) {
                                                        this.state.updated[this.state.audience_new.value].push(buffer);
                                                    }
                                                    //this.state.updated[this.state.audience_new.value].push(buffer);
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                    this.functions.reach();
                                                }}
                                                onSearch={async (value) => {
                                                    if (this.state.audience_new.id === 1) {
                                                        await this.promisedSetState({ search_interests: value });
                                                        this.functions.searchInterests();
                                                    } else if (this.state.audience_new.id === 2) {
                                                    } else if (this.state.audience_new.id === 3) {
                                                        await this.promisedSetState({ search_school: value });
                                                        this.functions.searchSchool();
                                                    } else if (this.state.audience_new.id === 4) {
                                                        await this.promisedSetState({ search_education: value });
                                                        this.functions.searchEducation();
                                                    } else if (this.state.audience_new.id === 5) {
                                                        await this.promisedSetState({ search_employer: value });
                                                        this.functions.searchEmployer();;
                                                    } else if (this.state.audience_new.id === 6) {
                                                        await this.promisedSetState({ search_job: value });
                                                        this.functions.searchJob();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-span-3 flex flex-row flex-wrap">
                                {
                                    selected_targeting()
                                }
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Custom audience"}
                                    selected={{ id: 0, name: "Search ..." }}
                                    rightLabel={true}
                                    rightLabelText={this.state.audience_type.value ? "Included" : "Excluded"}
                                    onRightLabelClick={async () => {
                                        await this.promisedSetState({ audience_type: !this.state.audience_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                    }}
                                    searchInput={true}
                                    options={this.state.audience}
                                    onChange={(value) => {
                                        if (!Array.isArray(this.state.updated.audience)) {
                                            this.state.updated.audience = [];
                                            if (this.state.adset.targeting && Array.isArray(this.state.adset.targeting.custom_audiences)) {
                                                this.state.updated.audience = this.state.updated.audience.concat(this.state.adset.targeting.custom_audiences.map((item) => { item.included = true; return item }));
                                            }
                                            if (this.state.adset.targeting && Array.isArray(this.state.adset.targeting.excluded_custom_audiences)) {
                                                this.state.updated.audience = this.state.updated.audience.concat(this.state.adset.targeting.excluded_custom_audiences.map((item) => { item.included = false; return item }));
                                            }
                                        }
                                        if (this.state.audience_type.id == 1) {
                                            value.included = true;
                                        } else {
                                            value.included = false;
                                        }
                                        if (this.state.updated.audience.filter((item) => { return item.id === value.id }).length < 1) {
                                            this.state.updated.audience.push(value);
                                        }
                                        //this.state.updated.audience.push(value);
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                        this.functions.reach();
                                    }}
                                />
                            </div>
                            {
                                this.renders.audience().length > 0 &&
                                <div className="col-span-3 flex flex-col">
                                    {
                                        this.renders.audience().sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            return (
                                                <Fragment>
                                                    <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <div className="flex flex-1">{item.name}</div>
                                                        <button
                                                            onClick={() => {
                                                                this.state.updated.audience = this.renders.audience().filter((audience) => {
                                                                    return audience.id !== item.id;
                                                                })
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.reach();
                                                            }}
                                                            type="button"
                                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove location</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </>
                    }


                    {/*Beneficiary*/}
                    {
                        this.state.tab.id == 8 &&
                        <>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Beneficiary"}
                                    error={adgroupFacebook.validate(this.state.updated).beneficiary}
                                    value={this.renders.beneficiary()}
                                    onChange={(value) => {
                                        this.state.updated.beneficiary = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Payer"}
                                    error={adgroupFacebook.validate(this.state.updated).payor}
                                    value={this.renders.payer()}
                                    onChange={(value) => {
                                        this.state.updated.payor = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }

                    {/*Locations*/}
                    {
                        this.state.tab.id == 5 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Location type"}
                                    selected={this.renders.places()}
                                    options={this.state.places}
                                    onChange={(value) => {
                                        this.state.updated.places = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3 grid grid-cols-6 gap-4">
                                <div className="col-span-2">
                                    <DropdownTailwind
                                        label={"View type"}
                                        selected={this.state.map ? this.state.map : { id: 1, name: "List View", value: false }}
                                        options={[
                                            { id: 1, name: "List View", value: false },
                                            { id: 2, name: "Map view", value: true }
                                        ]}
                                        // onChange={async (value) => {
                                        //     this.refs.searchDropdown.functions.resetSearch();
                                        //     await this.promisedSetState({
                                        //         map: value,
                                        //         location_options: [],
                                        //         search_locations: ""
                                        //     })
                                        // }}
                                        onChange={async (value) => {
                                            let buff = null;
                                            if (!this.state.updated) {
                                                this.state.updated = []
                                            }
                                            if (this.state.updated && this.state.updated.locations && this.state.updated.locations.length > 0) {
                                                buff = this.state.updated.locations.find((item) => item.custom);
                                                console.log("buff", buff)
                                                if (buff === undefined || buff === null) {
                                                    this.state.place = this.state.place_default;
                                                } else {
                                                    if (!buff.lat) {
                                                        buff.lat = buff.latitude;
                                                    }
                                                    if (!buff.lng) {
                                                        buff.lng = buff.longitude
                                                    }
                                                    this.state.place = buff;
                                                }
                                            } else {
                                                this.state.place = this.state.place_default;
                                            }
                                            this.refs.searchDropdown.functions.resetSearch();
                                            await this.promisedSetState({
                                                map: value,
                                                location_options: [],
                                                search_locations: "",
                                                place: this.state.place
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        error={adgroupFacebook.validate(this.state.updated).location}
                                        ref="searchDropdown"
                                        rightLabel={true}
                                        rightLabelText={this.state.location_type.value ? "Included" : "Excluded"}
                                        onRightLabelClick={async () => {
                                            await this.promisedSetState({ location_type: !this.state.location_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                        }}
                                        label={"Locations"}
                                        searchInput={true}
                                        selected={{ name: "Search ..." }}
                                        options={Array.isArray(this.state.location_options) ? this.state.location_options : []}
                                        loadingSearch={this.state.loading_locations}
                                        onChange={(value) => {
                                            let location = null;
                                            if (!this.state.map.value) {
                                                if (!this.state.location_type || (this.state.location_type && this.state.location_type.id == 1)) {
                                                    value.included = true;
                                                } else {
                                                    value.included = false;
                                                }
                                                location = value;
                                                location.id = Math.floor((Math.random() * 9999999999) + 1);
                                                if (!Array.isArray(this.state.updated.locations)) {
                                                    this.state.updated.locations = [];
                                                }
                                                if (this.state.updated.locations.filter((item) => { return item.key === location.key }).length < 1) {
                                                    this.state.updated.locations.unshift(location);
                                                }
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.reach();
                                            } else {
                                                let self = this;
                                                this.geocoder.geocode({ 'placeId': value.place_id }, async function (results, status) {
                                                    if (status === 'OK' && results && results.length > 0) {
                                                        location = {
                                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                                            custom: true,
                                                            included: self.state.location_type.value,
                                                            include: self.state.location_type.value,
                                                            new_location: true,
                                                            campaign_edit: self.state.location_type.value,
                                                            name: (parseFloat(results[0].geometry.location.lat().toFixed(3)) + " lat, " + parseFloat(results[0].geometry.location.lng()).toFixed(3) + " lng"),
                                                            lat: parseFloat(results[0].geometry.location.lat()),
                                                            lng: parseFloat(results[0].geometry.location.lng()),
                                                            radius: 30000,
                                                            distance_unit: 'kilometer'
                                                        };
                                                        if (!Array.isArray(self.state.updated.locations)) {
                                                            self.state.updated.locations = [];
                                                        }
                                                        self.state.updated.locations.unshift(location);
                                                        await self.promisedSetState({
                                                            place: location,
                                                            updated: self.state.updated,
                                                            updateMap: true
                                                        });
                                                        await self.promisedSetState({
                                                            updateMap: false
                                                        });
                                                        self.functions.reach();
                                                    }
                                                });
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            await this.promisedSetState({ search_locations: value });
                                            if (this.state.map.value) {
                                                this.functions.searchGoogleLocations();
                                            } else {
                                                this.functions.searchLocations();
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <DropdownTailwind
                                        label={"Location type"}
                                        selected={this.state.updated.location_type ? this.state.updated.location_type : { id: 1, name: "All", value: "" }}
                                        options={[
                                            { id: 1, name: "All", value: "" },
                                            { id: 2, name: "Country", value: "country" },
                                            { id: 3, name: "City", value: "city" },
                                        ]}
                                        onChange={async (value) => {
                                            this.state.updated.location_type = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            {
                                Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 &&
                                <div className="col-span-3 flex flex-col">
                                    {
                                        this.state.updated.locations.sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            let included_amount = this.state.updated.locations.filter((item) => { return item.included }).length;
                                            let included = this.state.updated.locations.filter((item) => { return item.included });
                                            let excluded = this.state.updated.locations.filter((item) => { return !item.included });
                                            return (
                                                <>
                                                    {included.length > 0 && index === 0 && (
                                                        <>
                                                            <div className="text-xs font-medium text-gray-700 mb-1">Included</div>
                                                            <div className='flex flex-wrap'>
                                                                {included.map((item, index) => (
                                                                    <div className={`${(((item.custom && this.state.map.value) || (!item.custom && !this.state.map.value)) ? " cursor-pointer " : " opacity-50 ") + " bg-indigo-100 text-indigo-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "} ${index === included.length + 1 ? "ml-1" : "mr-1"}`}
                                                                        style={{ minWidth: "fit-content", maxWidth: "fit-content", width: "fit-content" }}
                                                                    >
                                                                        <LocationMarkerIcon onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className={"text-indigo-500 h-5 w-5 mr-1"} aria-hidden="true" />
                                                                        <div onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                                        {
                                                                            this.state.map.value &&
                                                                            this.state.place && this.state.place.id == item.id &&
                                                                            <CursorClickIcon onClick={async () => {
                                                                                if (this.state.map.value && item.custom) {
                                                                                    await this.promisedSetState({
                                                                                        place: item,
                                                                                        updateMap: true
                                                                                    });
                                                                                    await this.promisedSetState({
                                                                                        updateMap: false
                                                                                    });
                                                                                }
                                                                            }} className={"text-indigo-500 h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                                        }
                                                                        <button
                                                                            onClick={() => {
                                                                                this.state.updated.locations = this.state.updated.locations.filter((location) => {
                                                                                    if (location.custom && item.custom) {
                                                                                        return !(location.lat == item.lat && location.lng == item.lng);
                                                                                    } else {
                                                                                        return location.key !== item.key;
                                                                                    }
                                                                                })
                                                                                this.setState({
                                                                                    updated: this.state.updated
                                                                                })
                                                                            }}
                                                                            type="button"
                                                                            className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove location</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {/* (included_amount == index) && excluded_amount > 0 && */}
                                                    {
                                                        // excluded.length > 0 && index === included_amount && (
                                                        (included_amount == index) && excluded.length > 0 && (
                                                            <>
                                                                <div className="text-xs font-medium text-gray-700 mb-1">Excluded</div>
                                                                <div className='flex flex-wrap space-x-2'>
                                                                    {excluded.map((item, index) => (
                                                                        <div className={(((item.custom && this.state.map.value) || (!item.custom && !this.state.map.value)) ? " cursor-pointer " : " opacity-50 ") + " bg-red-100 text-red-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}
                                                                            style={{ minWidth: "fit-content", maxWidth: "fit-content", width: "fit-content" }}
                                                                        >
                                                                            <LocationMarkerIcon onClick={async () => {
                                                                                if (this.state.map.value && item.custom) {
                                                                                    await this.promisedSetState({
                                                                                        place: item,
                                                                                        updateMap: true
                                                                                    });
                                                                                    await this.promisedSetState({
                                                                                        updateMap: false
                                                                                    });
                                                                                }
                                                                            }} className={"text-red-500 h-5 w-5 mr-1"} aria-hidden="true" />
                                                                            <div onClick={async () => {
                                                                                if (this.state.map.value && item.custom) {
                                                                                    await this.promisedSetState({
                                                                                        place: item,
                                                                                        updateMap: true
                                                                                    });
                                                                                    await this.promisedSetState({
                                                                                        updateMap: false
                                                                                    });
                                                                                }
                                                                            }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                                            {
                                                                                this.state.map.value &&
                                                                                this.state.place && this.state.place.id == item.id &&
                                                                                <CursorClickIcon onClick={async () => {
                                                                                    if (this.state.map.value && item.custom) {
                                                                                        await this.promisedSetState({
                                                                                            place: item,
                                                                                            updateMap: true
                                                                                        });
                                                                                        await this.promisedSetState({
                                                                                            updateMap: false
                                                                                        });
                                                                                    }
                                                                                }} className={"text-red-500 h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                                            }
                                                                            <button
                                                                                onClick={() => {
                                                                                    this.state.updated.locations = this.state.updated.locations.filter((location) => {
                                                                                        if (location.custom && item.custom) {
                                                                                            return !(location.lat == item.lat && location.lng == item.lng);
                                                                                        } else {
                                                                                            return location.key !== item.key;
                                                                                        }
                                                                                    })
                                                                                    this.setState({
                                                                                        updated: this.state.updated
                                                                                    })
                                                                                }}
                                                                                type="button"
                                                                                className={"focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                            >
                                                                                <span className="sr-only">Remove location</span>
                                                                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        )}
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            }

                            {/* {
                                Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 &&
                                <div className="col-span-3 flex flex-col">
                                    {
                                        this.state.updated.locations.sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            let included_amount = this.state.updated.locations.filter((item) => { return item.included }).length;
                                            let excluded_amount = this.state.updated.locations.filter((item) => { return !item.included }).length;
                                            return (
                                                <Fragment>
                                                    {
                                                        index == 0 && included_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Included
                                                        </div>
                                                    }
                                                    {
                                                        (included_amount == index) && excluded_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Excluded
                                                        </div>
                                                    }
                                                    <div className={(((item.custom && this.state.map.value) || (!item.custom && !this.state.map.value)) ? " cursor-pointer " : " opacity-50 ") + (item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <LocationMarkerIcon onClick={async () => {
                                                            if (this.state.map.value && item.custom) {
                                                                await this.promisedSetState({
                                                                    place: item,
                                                                    updateMap: true
                                                                });
                                                                await this.promisedSetState({
                                                                    updateMap: false
                                                                });
                                                            }
                                                        }} className={(item.included ? "text-indigo-500" : "text-red-500") + " h-5 w-5 mr-1"} aria-hidden="true" />
                                                        {
                                                            item.custom &&
                                                            <div onClick={async () => {
                                                                if (this.state.map.value && item.custom) {
                                                                    await this.promisedSetState({
                                                                        place: item,
                                                                        updateMap: true
                                                                    });
                                                                    await this.promisedSetState({
                                                                        updateMap: false
                                                                    });
                                                                }
                                                            }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                        }
                                                        {
                                                            !item.custom &&
                                                            <div className="flex flex-1">{item.name + (item.region ? (", " + item.region) : "") + (item.country_name ? ", " + item.country_name : "") + (item.type ? (" (" + item.type + ")") : "")}</div>
                                                        }
                                                        {
                                                            this.state.map.value &&
                                                            this.state.place && this.state.place.id == item.id &&
                                                            <CursorClickIcon onClick={async () => {
                                                                if (this.state.map.value && item.custom) {
                                                                    await this.promisedSetState({
                                                                        place: item,
                                                                        updateMap: true
                                                                    });
                                                                    await this.promisedSetState({
                                                                        updateMap: false
                                                                    });
                                                                }
                                                            }} className={(item.included ? "text-indigo-500" : "text-red-500") + " h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                        }
                                                        <button
                                                            onClick={() => {
                                                                this.state.updated.locations = this.state.updated.locations.filter((location) => {
                                                                    if (location.custom && item.custom) {
                                                                        return !(location.lat == item.lat && location.lng == item.lng);
                                                                    } else {
                                                                        return location.key !== item.key;
                                                                    }
                                                                })
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.reach();
                                                            }}
                                                            type="button"
                                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove location</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            } */}

                            {
                                this.state.place &&
                                this.state.map.value &&
                                <div className="col-span-3">
                                    <div className="overflow-hidden border-1.5 border-estatery-400 rounded-md">
                                        <AdvertisingLocationMapRegions
                                            ref="googleMapsRef"
                                            update={this.state.updateMap}
                                            center={this.state.center}
                                            zoom={this.state.zoom}
                                            place={this.state.place}
                                            places={this.state.updated && Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 && this.state.updated.locations.filter((item) => { return item.custom })}
                                            // selected={this.state.updated.locations.filter((item) => { return item.custom })}
                                            selected={this.state.updated && Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 && this.state.updated.locations.filter((item) => { return item.custom })}
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow"
                                            loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                                            containerElement={<div style={{
                                                height: "500px",
                                                width: '100%',
                                                borderRadius: '0px',
                                                overflow: 'hidden',
                                                border: 'none'
                                            }} />}
                                            mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                                            onUpdateLocations={(values) => {
                                                console.log("onUpdateLocations")
                                            }}
                                            onUpdateSelected={(values, location) => {
                                                this.state.updated.locations = this.state.updated.locations.map((item) => {
                                                    if (item.id == location.id) {
                                                        item = location;
                                                        item.updated = true;
                                                        item.name = parseFloat(item.lat).toFixed(3) + " lat, " + parseFloat(item.lng).toFixed(3) + " lng";
                                                    }
                                                    return item;
                                                })
                                                this.setState({
                                                    place: location,
                                                    updated: this.state.updated
                                                })
                                                this.functions.reach();
                                            }}
                                            onUpdate={(value) => {
                                                console.log("onUpdate")

                                            }}
                                        />
                                    </div>
                                </div>
                            }

                        </>
                    }

                    {/*Placements*/}
                    {
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Auto placements"}
                                    selected={this.renders.autoPlacements()}
                                    options={[
                                        { id: 1, name: "Yes (recommended)", value: true },
                                        { id: 2, name: "No", value: false }
                                    ]}
                                    onChange={(value) => {
                                        this.state.updated.auto_placements = value;
                                        if (!value.value) {
                                            this.state.platforms = this.state.platforms.map((item) => { item.selected = true; return item });
                                            this.state.updated.devices = { id: 1, name: "All devices (recommended)", value: ["mobile", "desktop"] };
                                            this.state.facebook_positions = this.state.facebook_positions.map((item) => { item.selected = true; return item });
                                            this.state.instagram_positions = this.state.instagram_positions.map((item) => { item.selected = true; return item });
                                            this.state.messenger_positions = this.state.messenger_positions.map((item) => { item.selected = true; return item });
                                            this.state.audience_network_positions = this.state.audience_network_positions.map((item) => { item.selected = true; return item });
                                        } else {
                                            this.state.platforms = this.state.platforms.map((item) => { item.selected = false; return item });
                                            this.state.facebook_positions = this.state.facebook_positions.map((item) => { item.selected = false; return item });
                                            this.state.instagram_positions = this.state.instagram_positions.map((item) => { item.selected = false; return item });
                                            this.state.messenger_positions = this.state.messenger_positions.map((item) => { item.selected = false; return item });
                                            this.state.audience_network_positions = this.state.audience_network_positions.map((item) => { item.selected = false; return item });


                                        }
                                        this.state.updated.platforms = this.state.platforms;
                                        this.state.updated.facebook_positions = this.state.facebook_positions;
                                        this.state.updated.instagram_positions = this.state.instagram_positions;
                                        this.state.updated.messenger_positions = this.state.messenger_positions;
                                        this.state.updated.audience_network_positions = this.state.audience_network_positions;

                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.autoPlacements().id == 2 &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Devices"}
                                        selected={this.renders.devices()}
                                        options={this.state.devices}
                                        onChange={(value) => {
                                            this.state.updated.devices = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.autoPlacements().id == 2 &&
                                <div className="col-span-3">
                                    <div className={"text-xs font-medium mb-1" + (adgroupFacebook.validate(this.state.updated).placements ? " text-red-500" : " text-gray-700")}>
                                        Platforms
                                    </div>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            this.state.platforms.map((item) => {
                                                return (
                                                    <div onClick={() => {
                                                        item.selected = !item.selected;
                                                        if (item.value == "facebook") {
                                                            this.state.facebook_positions = this.state.facebook_positions.map((position) => { position.selected = item.selected; return position });
                                                        } else if (item.value == "instagram") {
                                                            this.state.instagram_positions = this.state.instagram_positions.map((position) => { position.selected = item.selected; return position });
                                                        } else if (item.value == "messenger") {
                                                            this.state.messenger_positions = this.state.messenger_positions.map((position) => { position.selected = item.selected; return position });
                                                        } else if (item.value == "audience_network") {
                                                            this.state.audience_network_positions = this.state.audience_network_positions.map((position) => { position.selected = item.selected; return position });
                                                        }
                                                        this.state.updated.platforms = this.state.platforms;
                                                        this.state.updated.facebook_positions = this.state.facebook_positions;
                                                        this.state.updated.instagram_positions = this.state.instagram_positions;
                                                        this.state.updated.messenger_positions = this.state.messenger_positions;
                                                        this.state.updated.audience_network_positions = this.state.audience_network_positions;
                                                        this.setState({
                                                            platforms: this.state.platforms,
                                                            facebook_positions: this.state.facebook_positions,
                                                            instagram_positions: this.state.instagram_positions,
                                                            messenger_positions: this.state.messenger_positions,
                                                            audience_network_positions: this.state.audience_network_positions,
                                                            updated: this.state.updated
                                                        })
                                                    }} className={(item.selected ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        {
                                                            item.value == "facebook" &&
                                                            <BookOpenIcon className={"h-5 w-5 mr-2"} aria-hidden="true" />
                                                        }
                                                        {
                                                            item.value == "instagram" &&
                                                            <CameraIcon className={"h-5 w-5 mr-2"} aria-hidden="true" />
                                                        }
                                                        {
                                                            item.value == "audience_network" &&
                                                            <NewspaperIcon className={"h-5 w-5 mr-2"} aria-hidden="true" />
                                                        }
                                                        {
                                                            item.value == "messenger" &&
                                                            <ChatIcon className={"h-5 w-5 mr-2"} aria-hidden="true" />
                                                        }
                                                        <div className="">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(item.selected ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(item.selected ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.renders.autoPlacements().id == 2 &&
                                <>
                                    {
                                        [
                                            { name: "Facebook positions", value: "facebook" },
                                            { name: "Instagram positions", value: "instagram" },
                                            { name: "Audience network positions", value: "audience_network" },
                                            { name: "Messenger positions", value: "messenger" }
                                        ].filter((item) => {
                                            return this.state[item.value + "_positions"] && this.state.platforms.filter((platform) => { return platform.selected && platform.value == item.value }).length > 0
                                        }).map((item) => {
                                            return (
                                                <div className="col-span-3 -mt-2">
                                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                                        {item.name}
                                                    </div>
                                                    <div className="flex flex-row flex-wrap">
                                                        {
                                                            this.state[item.value + "_positions"].map((inner_item) => {
                                                                return (
                                                                    <div onClick={async () => {
                                                                        inner_item.selected = !inner_item.selected;

                                                                        await this.promisedSetState({
                                                                            facebook_positions: this.state.facebook_positions,
                                                                            instagram_positions: this.state.instagram_positions,
                                                                            messenger_positions: this.state.messenger_positions,
                                                                            audience_network_positions: this.state.audience_network_positions
                                                                        })
                                                                        if (this.state.facebook_positions.filter((item) => { return item.selected }).length == 0) {
                                                                            this.state.platforms = this.state.platforms.map((item) => {
                                                                                if (item.value == "facebook") {
                                                                                    item.selected = false;
                                                                                }
                                                                                return item;
                                                                            })

                                                                        } else if (this.state.instagram_positions.filter((item) => { return item.selected }).length == 0) {
                                                                            this.state.platforms = this.state.platforms.map((item) => {
                                                                                if (item.value == "instagram") {
                                                                                    item.selected = false;
                                                                                }
                                                                                return item;
                                                                            })

                                                                        } else if (this.state.messenger_positions.filter((item) => { return item.selected }).length == 0) {
                                                                            this.state.platforms = this.state.platforms.map((item) => {
                                                                                if (item.value == "messenger") {
                                                                                    item.selected = false;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        } else if (this.state.audience_network_positions.filter((item) => { return item.selected }).length == 0) {
                                                                            this.state.platforms = this.state.platforms.map((item) => {
                                                                                if (item.value == "audience_network") {
                                                                                    item.selected = false;
                                                                                }
                                                                                return item;
                                                                            })
                                                                        }
                                                                        this.state.updated.platforms = this.state.platforms;
                                                                        this.state.updated.facebook_positions = this.state.facebook_positions;
                                                                        this.state.updated.instagram_positions = this.state.instagram_positions;
                                                                        this.state.updated.messenger_positions = this.state.messenger_positions;
                                                                        this.state.updated.audience_network_positions = this.state.audience_network_positions;

                                                                        await this.promisedSetState({
                                                                            platforms: this.state.platforms,
                                                                            updated: this.state.updated
                                                                        })

                                                                    }} className={(inner_item.selected ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                                        <div className="">{inner_item.name}</div>
                                                                        <div className="ml-3">
                                                                            <div className={(inner_item.selected ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                                <CheckIcon className={(inner_item.selected ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }
                            <div className="col-span-3 text-lg font-bold">
                                <span>Brand Safety</span>
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Applies only to: Facebook in-stream videos, Facebook Instant Articles, and Ads on Facebook Reels"}
                                    selected={this.state.updated.safety_facebook ? this.state.updated.safety_facebook : { id: 2, name: "Standard", value: "FACEBOOK_STANDARD" }}
                                    options={[
                                        { id: 1, name: "Full", value: "FACEBOOK_RELAXED" },
                                        { id: 2, name: "Standard", value: "FACEBOOK_STANDARD" },
                                        { id: 3, name: "Limited", value: "FACEBOOK_STRICT" },
                                    ]}
                                    onChange={async (value) => {
                                        this.state.updated.safety_facebook = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Applies only to: Audience Network"}
                                    selected={this.state.updated.safety_network ? this.state.updated.safety_network : { id: 2, name: "Standard", value: "AN_STANDARD" }}
                                    options={[
                                        { id: 1, name: "Full", value: "AN_RELAXED" },
                                        { id: 2, name: "Standard", value: "AN_STANDARD" },
                                        { id: 3, name: "Limited", value: "AN_STRICT" },
                                    ]}
                                    onChange={async (value) => {
                                        this.state.updated.safety_network = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>


                        </>
                    }

                    {/*Conversion*/}
                    {
                        this.state.tab.id == 6 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Pixel"}
                                    selected={this.renders.pixel()}
                                    options={this.state.pixels}
                                    onChange={(value) => {
                                        this.state.updated.pixel = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Conversion Event"}
                                    selected={this.renders.eventType()}
                                    options={this.state.event_types[this.renders.campaign_objective().id]}
                                    onChange={(value) => {
                                        this.state.updated.event_type = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }

                    {/*APP*/}
                    {
                        this.state.tab.id == 7 && this.renders.campaign_objective().id > 1 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"App Name"}
                                    selected={this.renders.application()}
                                    error={adgroupFacebook.validate(this.state.updated).app && this.renders.application().id === 0}
                                    options={this.state.app_options}
                                    onChange={(value) => {
                                        this.state.updated.application = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.application().id > 0 &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Mobile app store"}
                                        error={adgroupFacebook.validate(this.state.updated).app}
                                        selected={this.renders.store()}
                                        options={this.state.store_options.filter((item) => {
                                            return this.renders.application().object_store_urls[item.value];
                                        })}
                                        onChange={(value) => {
                                            this.state.updated.store = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }

                        </>
                    }

                    {/*SCHEDULER */}

                    {
                        this.state.tab.id == 11 &&
                        <>
                            <div className='col-span-6'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Schedule</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.state.updated.scheduler.schedule ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.updated.scheduler.schedule ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.scheduler.schedule = !this.state.updated.scheduler.schedule;
                                                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer) {

                                                    this.state.updated.budget_type = { id: 1, name: "Daily budget", value: 'daily' };
                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                    } else {
                                                        this.state.updated.daily_budget = 0;
                                                    }
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-span-6 grid grid-cols-12 gap-4 transform transition-all duration-500 ease-in-out overflow-hidden' + (!this.state.updated.scheduler.schedule ? " h-0" : "")}>
                                <div className='col-span-12'>
                                    <div className={"text-xs font-medium mb-1 text-gray-700"}>
                                        Days
                                    </div>
                                    <div className='flex flex-wrap'>
                                        {
                                            [
                                                { name: "Monday", property: "scheduleMonday" },
                                                { name: "Tuesday", property: "scheduleTuesday" },
                                                { name: "Wednesday", property: "scheduleWednesday" },
                                                { name: "Thursday", property: "scheduleThursday" },
                                                { name: "Friday", property: "scheduleFriday" },
                                                { name: "Saturday", property: "scheduleSaturday" },
                                                { name: "Sunday", property: "scheduleSunday" }
                                            ].map((weekday) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (this.state.updated.scheduler.weekdays[weekday.property]) {
                                                            delete (this.state.updated.scheduler.weekdays[weekday.property]);
                                                        } else {
                                                            this.state.updated.scheduler.weekdays[weekday.property] = true;
                                                        }
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })


                                                    }} className={(this.state.updated.scheduler.weekdays[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div>{weekday.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(this.state.updated.scheduler.weekdays[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(this.state.updated.scheduler.weekdays[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-span-12 overflow-hidden ease-in-out transition-all duration-500 grid grid-cols-4 gap-4'>
                                    <div className='col-span-4'>
                                        <div className='flex flex-row overflow-hidden ease-in-out transition-all duration-500'>
                                            <div className={'flex flex-col transition-all transform duration-500 overflow-hidden' + (!this.state.updated.scheduler.planer ? " flex-1" : " pr-4")}>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 min-w-24">
                                                        {(this.state.updated.scheduler.planer ? "Active" : "Disabled")}
                                                    </div>
                                                    <div className={"relative overflow-hidden mr-5 "}>
                                                        <SwitchTailwind
                                                            disabled={false}
                                                            value={this.state.updated.scheduler.planer}
                                                            onSwitch={async () => {

                                                                this.state.updated.scheduler.planer = !this.state.updated.scheduler.planer;
                                                                if (this.state.updated.scheduler.planer) {
                                                                    this.state.updated.budget_type = { id: 1, name: "Daily budget", value: 'daily' };
                                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                                    } else {
                                                                        this.state.updated.daily_budget = 0;
                                                                    }
                                                                }

                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "pr-4" : "w-0 hidden")}>
                                                <div className=' pr-2'>
                                                    <InputTailwind
                                                        locked={!this.state.updated.scheduler.planer}
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        label={"Total monthly"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.scheduler.schedule_budget}
                                                        type={"number"}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.schedule_budget = value;
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <InputTailwind
                                                        locked={true}
                                                        disabled={true}
                                                        label={"Daily budget"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.daily_budget}
                                                        type={"number"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                <div className='w-full'>
                                                    <InputDatepickerTailwind
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        locked={!this.state.updated.scheduler.planer}
                                                        placeholder={moment(this.state.updated.scheduler.start_date).format("MM/DD/YYYY")}
                                                        label={"Start date"}
                                                        value={moment(this.state.updated.scheduler.start_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.start_date = moment(value).format("YYYY-MM-DD");
                                                            if (this.state.updated.scheduler) {
                                                                this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                                            }
                                                            if (this.renders.endDate() && new Date(this.state.updated.start_date).getTime() > new Date(this.renders.endDate()).getTime()) {
                                                                this.state.updated.end_date = moment(value).add(1, 'days').format("YYYY-MM-DD");
                                                            }
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.updated.scheduler.order_info && this.state.updated.scheduler.order_info.maxBudgetSubAction &&
                                                <div className={'flex transition-all transform duration-500 overflow-hidden pl-4 ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                    <div className=''>
                                                        <InputTailwind
                                                            locked={true}
                                                            disabled={true}
                                                            label={"Order pacing"}
                                                            value={this.state.updated.scheduler.order_info.maxBudgetSubAction && this.state.updated.scheduler.order_info.maxBudgetSubAction.id ? (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 1 ? "Spend total" : (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 2 ? "Spend pacing" : "Unkown")) : "Unkown"}
                                                        />
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.updated.scheduler.planer && this.state.updated.scheduler.schedule_budget > 0 && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity &&
                                            <div className='flex flex-row border-t mt-4'>
                                                <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                    OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='col-span-4'>
                                        <div className={" mt-4 pt-4" + (this.state.updated.scheduler.planer ? " h-64" : " h-0")}>
                                            <Bar
                                                options={{
                                                    maintainAspectRatio: false,
                                                    interaction: {
                                                        intersect: false,
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                suggestedMin: 0
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={budgetPlannerGraph.main(this.state.updated.scheduler)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }

                </div>
            </>
        )
    }
}

export default EditFacebookAdset;
