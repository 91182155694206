import { PlusIcon, XIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import SwitchTailwind from './switchTailwind';
import currencies from "../assets/json/currencies.json";
import CopyButton from './copyButton';

class AccountDv360 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            me: {},
            agency: {},
            agency_user: {},
            message: "",
            throttling: {},
            loading: false,
            loading_managers: false,
            loading_customers: false,
            is_manager: false,
            manager: {},
            managers: [],
            customer: {},
            customers: [],
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            healthcheck: {},
            include_adaccounts: { id: 1, name: "Include all" },
            include_campaigns: { id: 1, name: "Include all" },
            owner: false,
            disabled: false,
            syncMessage: "",
            syncManagers: false,
            currency: {},
            currencies: currencies,
            customers_next: null
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            agency_user: this.props.agencyUser ? this.props.agencyUser : {},
            agency: this.props.agency ? this.props.agency : {},
            me: this.props.me ? this.props.me : {},
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.dv360 && !this.state.agency.dv360) {
            this.functions.getAgents(true);
        }
        try {
            if (window.location.href.indexOf("v2/profile/connections") !== -1) {
                await this.promisedSetState({
                    open: true
                });
            }
        } catch (error) { }
    }

    async componentWillReceiveProps(nextprops) {
        await this.promisedSetState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            agency_user: nextprops.agencyUser ? nextprops.agencyUser : {},
            agency: nextprops.agency ? nextprops.agency : {},
            me: nextprops.me ? nextprops.me : {},
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    componentWillUnmount() {

    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "dv360",
                    token: token
                });

                if (this.props.onLogin) {
                    this.props.onLogin()
                }

                //TEMP SOLUTION
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
                //TEMP SOLUTION

                await self.promisedSetState({
                    user: { ...this.state.user, ...{ dv360: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "dv360"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ dv360: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listManagers: async () => {
            await this.promisedSetState({
                loading_managers: true
            });
            try {
                let response = await this.calls.listManagers();
                await this.promisedSetState({
                    managers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_managers: false
            });
        },
        listCustomers: async (init) => {
            await this.promisedSetState({
                loading_customers: true,
                loading_customers_pagination: this.state.customers_next
            });
            try {
                let response = await this.calls.listCustomers(init);
                await this.promisedSetState({
                    customers: init ? response.data : this.state.customers.concat(response.data),
                    customers_next: response.meta.nextPageToken
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_customers: false,
                loading_customers_pagination: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.customer.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    manager: this.state.manager,
                    account: this.state.customer,
                    ocid: this.state.ocid
                });
                this.state.client.dv360 = {
                    defaultAgent: this.state.agent,
                    managerId: this.state.manager.id,
                    managerName: this.state.manager.name,
                    accountId: this.state.customer.id,
                    accountName: this.state.customer.name,
                    currency: this.state.currency.id,
                    ocid: this.state.ocid
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    customer: {},
                    manager: {},
                    currency: {},
                    client: this.state.client
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.dv360;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    customer: {},
                    manager: {},
                    ocid: "",
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        connectToAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                let response = await this.calls.connectToAgency({
                    channel: 'dv360'
                });
                this.state.agency.dv360 = {
                    dv360_connected: response.data.dv360_connected,
                    matching_managers: response.data.matching_managers,
                    matching_accounts: response.data.matching_accounts,
                    authenticated: true
                };
                this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromAgency({
                    channel: 'dv360'
                });
                this.state.agency.dv360 = { authenticated: false }
                await this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        updateCurrency: async () => {
            let data = {
                channel: 'dv360',
                currency: this.state.currency.id
            }
            await this.promisedSetState({
                loading_update: true
            });
            await this.calls.updateClientAccount(data);
            this.state.client.dv360.currency = this.state.currency.id;
            await this.promisedSetState({
                client: this.state.client,
                loading_update: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/dv360/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateClientAccount: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateClientAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/dv360/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        connectToAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/connectAgencyAccount";
            return apiRegister.call(options, url);
        },
        disconnectFromAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAccount";
            return apiRegister.call(options, url);
        },
        listManagers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/dv360/managers?client=" + this.state.client.id + (this.state.agency_user.id ? ("&agent=" + this.state.agency_user.id) : ("&agent=" + this.state.agent.id));
            return apiRegister.call(options, url);
        },
        listCustomers: (init) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/dv360/accounts?client=" + this.state.client.id + "&manager=" + this.state.manager.id + "&agent=" + this.state.agent.id + (!init && this.state.customers_next !== "" ? "&nextPageToken=" + this.state.customers_next : "");
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=dv360";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={(this.props.border ? "border rounded-md p-4" : "") + " relative"}>

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout DV 360"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-dv360-500 rounded-md flex justify-center items-center">
                        <div className="w-4 h-4">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            DV360 {(this.state.client && this.state.client.dv360) && <span>connected</span>}
                        </div>
                        {
                            !(this.state.client && this.state.client.dv360) &&
                            <div className="text-xs text-gray-500">
                                Connect DV360 to this client
                            </div>
                        }
                        {
                            (this.state.client && this.state.client.dv360) &&
                            <div className="text-xs text-purple-500">
                                <span onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                }} className="underline cursor-pointer font-semibold">Click here</span> to show {!this.state.open && <span>selected values</span>}{this.state.open && <span>less</span>}
                            </div>
                        }
                    </div>
                    {
                        !this.state.user.id &&
                        !(this.state.client && this.state.client.dv360) &&
                        <div>
                            <button
                                onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                    if (this.state.is_manager && !this.state.client.dv360) {
                                        if (this.state.open) {
                                            this.functions.getAgents(true);
                                        }
                                    }
                                }}
                                className={(this.state.open ? "bg-red-500" : "bg-purple-500") + " cursor-pointer shadow inline-flex relative justify-center rounded-md border border-transparent items-center h-10 w-10 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                {
                                    !this.state.open &&
                                    <span><PlusIcon className="w-5" /></span>
                                }
                                {
                                    this.state.open &&
                                    <span><XIcon className="w-5" /></span>
                                }
                            </button>
                        </div>
                    }
                    {
                        (
                            (
                                !this.state.user.id &&
                                this.state.client.dv360 &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.dv360 && this.state.client.dv360.defaultAgent && this.state.client.dv360.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={this.state.client.dv360}
                                onSwitch={async () => {
                                    if (!this.state.client.dv360) {
                                        this.state.client.dv360 = {};
                                        await this.promisedSetState({
                                            client: this.state.client
                                        });
                                    } else {
                                        this.functions.disconnectFromClient();
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className={(this.props.withBackgroundColor && this.state.open ? "bg-gray-100 p-4 rounded-md mt-4 " : "") + (this.state.open ? "" : "h-0 overflow-hidden") + " transform ease-in-out duration-100 transition"}>
                    {
                        !this.state.agency.dv360 &&
                        !this.state.user.id &&
                        <div className={this.props.withBackgroundColor ? "" : "mt-4"}>
                            <DropdownTailwind
                                label={"Default agent"}
                                small={false}
                                locked={this.state.client.dv360}
                                skipInternalSearch={this.state.is_manager}
                                loader={this.state.loading_agents}
                                loadingPagination={this.state.loading_agents_pagination}
                                loadingSearch={this.state.loading_agents_search}
                                total={this.state.total}
                                searchInput={true}
                                placeholder={"..."}
                                defaultOptions={this.state.me.dv360 && this.state.me.dv360.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                                pagination={this.state.total > (this.state.page * this.state.limit)}
                                selected={this.state.client.dv360 && this.state.client.dv360.defaultAgent ? this.state.client.dv360.defaultAgent : this.state.agent}
                                options={this.state.agents}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        agent: value,
                                        manager: {},
                                        customer: {},
                                        ocid: ""
                                    });
                                    this.functions.listManagers();
                                }}
                                onPagination={async () => {
                                    if (!this.state.loading_agents_pagination) {
                                        await this.promisedSetState({
                                            page: this.state.page + 1
                                        });
                                        this.functions.getAgents(false, true, false);
                                    }
                                }}
                                onSearch={async (value) => {
                                    if (value && value === "") {
                                        await this.promisedSetState({
                                            search: "",
                                            page: 1
                                        });
                                        this.functions.getAgents(false, false, true);
                                    } else {
                                        if (this.state.throttling.current) {
                                            //SKIP
                                        } else {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    search: value,
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            } else {
                                                this.state.throttling.current = true;
                                                await this.promisedSetState({
                                                    search: value,
                                                    throttling: this.state.throttling
                                                });
                                                setTimeout(async () => {
                                                    this.state.throttling.current = false;
                                                    await this.promisedSetState({
                                                        throttling: this.state.throttling
                                                    });
                                                    await this.promisedSetState({
                                                        page: 1
                                                    });
                                                    this.functions.getAgents(false, false, true);
                                                }, 300);
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    }
                    {
                        ((this.state.client.id && (this.state.client.dv360 || this.state.agent.id !== 0))) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    label={!this.state.agency.dv360 ? (this.state.client.dv360 && this.state.client.dv360.managerId ? "Manager" : "Managers") : (this.state.agency.dv360 && this.state.agency.dv360.managerId ? "Manager" : "Managers")}
                                    loader={this.state.loading_managers}
                                    searchInput={true}
                                    locked={!this.state.agency.dv360 ? this.state.client.dv360 : this.state.agency.dv360.managerId}
                                    selected={!this.state.agency.dv360 ? (this.state.client.dv360 && this.state.client.dv360.managerId ? { name: this.state.client.dv360.managerName } : (this.state.manager.id ? this.state.manager : { name: "Select manager" })) : (this.state.agency.dv360 && this.state.agency.dv360.managerId ? { name: this.state.agency.dv360.managerName } : (this.state.manager.id ? this.state.manager : { name: "Select manager" }))}
                                    options={this.state.managers}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            manager: value,
                                            customer: {},
                                            ocid: ""
                                        });
                                        if (!this.state.agency.dv360) {
                                            this.functions.listCustomers(true);
                                        }
                                    }}
                                />
                            </div>
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.dv360 && this.state.client.dv360.managerId) || this.state.manager.id)}
                                copyText={(this.state.client.dv360 && this.state.client.dv360.managerId) ? this.state.client.dv360.managerId : this.state.manager.id}
                            />
                        </div>
                    }
                    {
                        !this.state.agency.dv360 &&
                        this.state.client.id &&
                        (this.state.manager.id || (this.state.client.dv360 && this.state.client.dv360.managerId)) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    label={this.state.client.dv360 && this.state.client.dv360.clientId ? "Account" : "Accounts"}
                                    skipInternalSearch={false}
                                    loader={this.state.loading_customers}
                                    loadingPagination={this.state.loading_customers_pagination}
                                    searchInput={true}
                                    pagination={this.state.customers_next}
                                    dontShowPaginationAmount={true}
                                    placeholder={"..."}
                                    selected={this.state.client.dv360 && this.state.client.dv360.accountId ? { name: this.state.client.dv360.accountName } : (this.state.customer.id ? this.state.customer : { name: "Select Account" })}
                                    options={this.state.customers}
                                    onChange={async (value) => {
                                        this.setState({
                                            customer: value
                                        });
                                    }}
                                    onPagination={async () => {
                                        this.functions.listCustomers();
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.dv360 && this.state.client.dv360.accountId) || this.state.customer.id)}
                                copyText={(this.state.client.dv360 && this.state.client.dv360.accountId) ? this.state.client.dv360.accountId : this.state.customer.id}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        (this.state.customer.id || this.state.client.dv360) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={"Currency"}
                                    selected={this.state.currency.id
                                        ? this.state.currency
                                        : this.state.client.dv360 && this.state.client.dv360.accountId
                                            ? { name: this.state.client.dv360.currency }
                                            : { name: "Select currency" }}
                                    options={this.state.currencies}
                                    onChange={async (value) => {
                                        this.setState({
                                            currency: value
                                        });
                                    }}
                                />
                            </div>
                            {
                                this.state.client.dv360 &&
                                this.state.currency && this.state.currency.id && this.state.client.dv360.currency !== this.state.currency.name &&
                                <button onClick={async () => {
                                    if (this.state.currency && this.state.currency.id && this.state.client.dv360.currency !== this.state.currency.name) {
                                        await this.functions.updateCurrency();
                                    }
                                }} className={"mt-5.5 ml-2 relative bg-custom-input h-12 justify-center border-1.5 rounded-md w-32 text-sm font-medium"}>
                                    <span>Update</span>
                                    {
                                        this.state.loading_update &&
                                        <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            }
                        </div>
                    }
                    {
                        ((this.state.agency.dv360 && this.state.agency.dv360.authenticated && this.state.manager.id) || (this.state.client.id &&
                            !this.state.client.dv360 &&
                            this.state.customer.id &&
                            this.state.manager.id)) &&
                        <div className="flex w-full flex-row">
                            <div className="flex flex-1">
                                <button
                                    onClick={() => {
                                        if (!this.state.loading_account) {
                                            if (!this.state.agency.dv360) {
                                                this.functions.connectToClient();
                                            } else {
                                                this.functions.connectToAgency();
                                            }
                                        }
                                    }}
                                    className={(((this.state.agency.dv360 && this.state.agency.dv360.authenticated && this.state.manager.id) || this.state.customer.id) ? "bg-dv360-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                    }
                                >
                                    <span>Save DV 360 account</span>
                                    {
                                        this.state.loading_account &&
                                        <div className={"bg-dv360-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    }
                    {
                        !this.state.agency.dv360 &&
                        !this.state.client.id &&
                        (!this.state.user.dv360 || (this.state.user.dv360 && !this.state.user.dv360.authenticated)) &&
                        <div onClick={() => {
                            let self = this;
                            const script = document.createElement('script');
                            script.setAttribute("id", "google_api");
                            script.src = "https://apis.google.com/js/platform.js";
                            script.async = true;
                            script.defer = true;
                            script.onload = () => window.gapi.load('auth2', initClient);
                            document.body.appendChild(script);
                            const initClient = () => {
                                window['gapi'].auth2.init({
                                    client_id: '592015831042-6825tmbeuvhqq5eaaqf12jcvhrcr6noh.apps.googleusercontent.com',
                                    cookiepolicy: 'single_host_origin',
                                    scope: 'https://www.googleapis.com/auth/display-video https://www.googleapis.com/auth/doubleclickbidmanager'
                                }).then(() => {
                                    const auth = window['gapi'].auth2.getAuthInstance();
                                    auth.grantOfflineAccess().then(res => {
                                        self.functions.login(res.code);
                                        document.body.removeChild(script);
                                    }).catch(function (error) {
                                        document.body.removeChild(script);
                                    });
                                });
                            };
                        }} className="mt-5">
                            <img className="w-48 cursor-pointer" src={require("../assets/images/google_signin_button_2.png")} />
                        </div>
                    }
                    {/* TEMP */}
                    {
                        false &&
                        !this.state.agency.dv360 &&
                        !this.state.client.id &&
                        (!this.state.user.dv360 || (this.state.user.dv360 && !this.state.user.dv360.authenticated)) &&
                        <div className="mt-5">
                            <button onClick={() => {
                                let self = this;
                                const script = document.createElement('script');
                                script.setAttribute("id", "google_api");
                                script.src = "https://apis.google.com/js/platform.js";
                                script.async = true;
                                script.defer = true;
                                script.onload = () => window.gapi.load('auth2', initClient);
                                document.body.appendChild(script);
                                const initClient = () => {
                                    window['gapi'].auth2.init({
                                        client_id: '592015831042-6825tmbeuvhqq5eaaqf12jcvhrcr6noh.apps.googleusercontent.com',
                                        cookiepolicy: 'single_host_origin',
                                        scope: 'https://www.googleapis.com/auth/display-video https://www.googleapis.com/auth/doubleclickbidmanager'
                                    }).then(() => {
                                        const auth = window['gapi'].auth2.getAuthInstance();
                                        auth.grantOfflineAccess().then(res => {
                                            self.functions.login(res.code);
                                            document.body.removeChild(script);
                                        }).catch(function (error) {
                                            document.body.removeChild(script);
                                        });
                                    });
                                };
                            }} className={(!(this.state.user.dv360 && this.state.user.dv360.authenticated) ?
                                "cursor-pointer bg-dv360-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                            >
                                <span>Login with DV 360</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.dv360 && this.state.user.dv360.authenticated) ? "bg-dv360-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        !this.state.client.id &&
                        this.state.user.dv360 && this.state.user.dv360.authenticated &&
                        <div className="mt-5">
                            <button
                                onClick={() => {
                                    this.setState({
                                        remove: true
                                    });
                                }}
                                className={(!(this.state.user.dv360 && this.state.user.dv360.authenticated) ?
                                    "cursor-pointer bg-dv360-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Remove</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.dv360 && this.state.user.dv360.authenticated) ? "bg-dv360-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        false &&
                        this.state.agency.dv360 &&
                        this.state.agency.dv360.authenticated &&
                        <div className="mt-5 w-full">
                            <dl className={"mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3"}>
                                {[{ name: "Clients", stat: this.state.agency.dv360.dv360_connected }, { name: "Managers", stat: this.state.agency.dv360.matching_managers }, { name: "Ad accounts", stat: this.state.agency.dv360.matching_accounts }].map((item) => (
                                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                        <dt className="text-sm font-medium text-gray-500">{item.name}</dt>
                                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    }
                    {
                        this.state.agency.dv360 &&
                        this.state.agency.dv360.authenticated &&
                        this.state.syncManagers &&
                        <div className="bg-yellow-100 p-4 mt-4 text-yellow-500 text-sm font-medium rounded-md">
                            {this.state.syncMessage}
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            {this.state.message}
                        </div>
                    }
                    {
                        !this.state.agency.dv360 &&
                        this.state.healthcheck && this.state.healthcheck.dv360 && this.state.healthcheck.dv360.status != 200 &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            Token Check: {this.state.healthcheck.dv360.message}. We recommend to sign out and sign in again.
                        </div>
                    }
                    {
                        !this.state.agency.dv360 &&
                        !this.state.user.id &&
                        this.state.client.dv360 &&
                        !(this.state.is_manager || (!this.state.is_manager && this.state.client.dv360 && this.state.client.dv360.defaultAgent && this.state.client.dv360.defaultAgent.id === this.state.me.id)) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You are not "default agent" or "agency manager"
                        </div>
                    }
                    {
                        !this.state.agency.dv360 &&
                        !this.state.user.id &&
                        !this.state.is_manager &&
                        !this.state.client.dv360 &&
                        !(this.state.me.dv360 && this.state.me.dv360.authenticated) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You have not authenticated dv360, go to My Profile.
                        </div>
                    }
                    {
                        !this.state.agency.dv360 &&
                        !this.state.user.id &&
                        this.state.is_manager &&
                        !this.state.client.dv360 &&
                        this.state.agents.length < 1 &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! No available agents who has authenticated dv360
                        </div>
                    }
                </div>
            </div >
        )
    }
}

export default AccountDv360;
