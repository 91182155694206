import { CheckCircleIcon, ExclamationCircleIcon, HeartIcon, KeyIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import SideNotification from '../moduleFiles/sideNotification';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import AccountTiktok from '../moduleFiles/accountTiktok';
import AccountTwitter from '../moduleFiles/accountTwitter';
import AccountGoogle from '../moduleFiles/accountGoogle';
import AccountFacebook from '../moduleFiles/accountFacebook';
import AccountLinkedin from '../moduleFiles/accountLinkedin';
import AccountGoogleAnalytics from '../moduleFiles/accountGoogleAnalytics';
import AccountAdform from '../moduleFiles/accountAdform';
import AccountGoogleAnalytics4 from '../moduleFiles/accountGoogleAnalytics4';
import AccountBing from '../moduleFiles/accountBing';
import formated_timezones from '../assets/json/formated_timezones.json';
import AccountDv360 from '../moduleFiles/accountDv360';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import AccountSnapchat from "../moduleFiles/accountSnapchat";
import AccountBidtheatre from '../moduleFiles/accountBidtheatre';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import UploadImage from '../components/image-upload';

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            old_password: "",
            new_password: "",
            remove_name: "",
            remove: null,
            loading: true,
            success: false,
            errors: {},
            user: {},
            healthcheck: {},
            timezone: {},
            timezones: formated_timezones,
            whitelabel_settings: {
                color_navbar: "FFFFFF",
                color_background: "F1F0F8",
                color_accent: "FFFFFF",
                color_font: "333333",
                logo_primary: "",
                logo_size: { id: 2, name: 'Medium', value: "h-5" },
                shadows: false,
            },
            whitelabel_loading: false,
            selectedId: "",
            tab: { id: 1, name: "Information" },
            tabs: [
                { id: 1, name: "Information" },
                { id: 2, name: "Security" },
            ]
        }
    };

    componentDidMount() {
        this.functions.getAgent();
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {},
                loading: false
            })
        },
        getAgent: async () => {
            await this.promisedSetState({ loading: true, errors: {} });
            try {
                let response = await this.calls.getAgent();
                await this.promisedSetState({ user: response.data });
                console.log("user state", this.state.user);
                if (this.state.user && this.state.user.whitelabel) {
                    this.promisedSetState({
                        whitelabel_settings: this.state.user.whitelabel
                    })
                }
            } catch (error) {
                if (error && error.body && error.body.data) {
                    await this.promisedSetState({ errors: error.body.data });
                }
            }
            await this.promisedSetState({ loading: false });
        },
        updatePassword: async () => {
            await this.promisedSetState({ loading_password: true, errors: {} });
            try {
                await this.calls.updatePassword({
                    old_password: this.state.old_password,
                    new_password: this.state.new_password
                });
                await this.promisedSetState({ success: true, old_password: "", new_password: "" });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    await this.promisedSetState({ errors: error.body.data });
                }
            }
            await this.promisedSetState({ loading_password: false });
        },
        updateAgencyFromManager: async () => {
            await this.promisedSetState({ loading_timezone: true, errors: {} });
            try {
                await this.calls.updateAgencyFromManager({
                    timezone: this.state.user.agency_timezone,
                    external_link: this.state.user.agency_external_link,
                    external_link_password: this.state.user.agency_external_link_password
                });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    await this.promisedSetState({ errors: error.body.data });
                }
            }
            await this.promisedSetState({ loading_timezone: false });
        },
        updateVerification: async () => {
            await this.promisedSetState({ loading_verfication: true });
            try {
                await this.calls.updateVerification({
                    auth_verification: this.state.user.auth_verification
                });
                userRegister.set(this.state.user, false);
            } catch (error) {
                if (error && error.body && error.body.data) {
                    await this.promisedSetState({ errors: error.body.data });
                }
            }
            await this.promisedSetState({ loading_verfication: false });
        },
        healthcheck: async () => {
            await this.promisedSetState({ loading_healthcheck: true });
            try {
                let response = await this.calls.healthcheck();
                await this.promisedSetState({ healthcheck: response.data });
                let error = false;
                for (let key in this.state.healthcheck) {
                    if (this.state.healthcheck[key].status != 200) {
                        error = true
                    }
                }
                await this.promisedSetState({ error_healthcheck: error });
                this.refs.SideNotification.functions.trigger();
            } catch (error) {

            }
            await this.promisedSetState({ loading_healthcheck: false });
        },
        removeCachedData: async () => {
            await this.promisedSetState({ loading_remove: true, remove: false });
            try {
                let response = await this.calls.removeCachedData();
            } catch (error) { }
            await this.promisedSetState({ loading_remove: false });
        }
    };

    calls = {
        getAgent: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgent";
            return apiRegister.call(options, url);
        },
        updatePassword: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updatePassword";
            return apiRegister.call(options, url);
        },
        updateAgencyFromManager: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgencyFromManager";
            return apiRegister.call(options, url);
        },
        updateVerification: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/update2StepAuthOption";
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents";
            return apiRegister.call(options, url);
        },
        healthcheck: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/platformsHealthCheck";
            return apiRegister.call(options, url);
        },
        removeCachedData: () => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeCachedData";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/* TOP NAVIGATION */}
                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={(type) => {

                        }}
                        history={this.props.history}
                        breadcrumb={"My profile"}
                        buttons={[]}
                        hideUserDropdown={true}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        onSubtab={(tab) => {
                            this.setState({
                                tab: tab
                            })
                        }}
                        subtab={this.state.tab}
                        show_subtabs={true}
                        subtabs={this.state.tabs}
                    />
                </div>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeCachedData();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/* SIDE NOTIFICATION */}
                <SideNotification
                    ref={"SideNotification"}
                    icon={this.state.error_healthcheck ? ExclamationCircleIcon : CheckCircleIcon}
                    title={this.state.error_healthcheck ? "Token Check Error" : "Token Check Success"}
                    text={this.state.error_healthcheck ? "No errors with your tokens, scroll down." : "All tokens are working!"}
                />

                {/* SUCCESS MODAL */}
                <SuccessModal
                    open={this.state.success}
                    title={"Success"}
                    text={(this.state.success_text ? this.state.success_text : "Your password has been updated")}
                    button={"OK"}
                    onClose={() => {
                        this.setState({
                            success: false
                        })
                    }}
                />

                {/* LOADING VIEW */}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading profile ...</div>
                    </div>
                }

                {/* CONTENT */}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col gap-4">

                        {
                            this.state.tab.id == 1 &&
                            <div className="shadow bg-white rounded-lg w-full">
                                <div className="py-5 px-4 sm:px-6 border-b font-semibold text-sm">
                                    Information
                                </div>
                                <div className="py-5 px-4 sm:px-6 ">
                                    <div className="grid grid-cols-4 gap-6">
                                        <div className="col-span-4 sm:col-span-2">
                                            <InputTailwind
                                                label={"Name"}
                                                locked={true}
                                                readOnly={true}
                                                type={"text"}
                                                value={this.state.user.name}
                                                onChange={(value) => {

                                                }}
                                            />
                                        </div>
                                        <div className="col-span-4 sm:col-span-2">
                                            <InputTailwind
                                                label={"Email"}
                                                locked={true}
                                                readOnly={true}
                                                type={"text"}
                                                value={this.state.user.email}
                                                onChange={(value) => {

                                                }}
                                            />
                                        </div>
                                        <div className="col-span-4 flex flex-row">
                                            <div className="flex flex-1"></div>
                                            <button
                                                onClick={() => {

                                                }}
                                                className={(false ?
                                                    "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                }
                                            >
                                                Save
                                                {
                                                    this.state.loading_profile &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.id == 2 &&
                            <div className="shadow bg-white rounded-lg w-full">
                                <div className="py-5 px-4 sm:px-6 border-b font-semibold text-sm">
                                    2 step Verification
                                </div>
                                <div className="py-5 px-4 sm:px-6 ">
                                    <div className="grid grid-cols-4 gap-6">
                                        <div className="col-span-4">
                                            <DropdownTailwind
                                                label={"Send email with verification code (recommended)"}
                                                searchInput={false}
                                                selected={this.state.user.auth_verification ? { id: 1, name: "Enabled", value: true } : { id: 2, name: "Disabled", value: false }}
                                                options={[
                                                    { id: 1, name: "Enabled", value: true },
                                                    { id: 2, name: "Disabled", value: false }
                                                ]}
                                                onChange={async (option) => {
                                                    if (!this.state.loading_verfication) {
                                                        this.state.user.auth_verification = option.value;
                                                        this.setState({
                                                            user: this.state.user
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-4 flex flex-row">
                                            <div className="flex flex-1"></div>
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading_verfication) {
                                                        this.functions.updateVerification();
                                                    }
                                                }}
                                                className={(!this.state.disabled ?
                                                    "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                }
                                            >
                                                Save
                                                {
                                                    this.state.loading_verfication &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            this.state.tab.id == 2 &&
                            <div className="shadow bg-white rounded-lg w-full">
                                <div className="py-5 px-4 sm:px-6 border-b font-semibold text-sm">
                                    Update password
                                </div>
                                <div className="py-5 px-4 sm:px-6 ">
                                    <div className="grid grid-cols-4 gap-6">
                                        <div className="col-span-4 sm:col-span-2">
                                            <InputTailwind
                                                label={"Old password"}
                                                error={this.state.errors.old_password}
                                                errorMessage={this.state.errors.old_password}
                                                type={"password"}
                                                value={this.state.old_password}
                                                onChange={(value) => {
                                                    this.setState({
                                                        old_password: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-4 sm:col-span-2">
                                            <InputTailwind
                                                label={"New password"}
                                                error={this.state.errors.new_password}
                                                errorMessage={this.state.errors.new_password}
                                                type={"password"}
                                                value={this.state.new_password}
                                                onChange={(value) => {
                                                    this.setState({
                                                        new_password: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-4 flex flex-row">
                                            <div className="flex flex-1"></div>
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading_password) {
                                                        this.functions.updatePassword();
                                                    }
                                                }}
                                                className={(!this.state.disabled ?
                                                    "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                }
                                            >
                                                Save
                                                {
                                                    this.state.loading_password &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }

            </div >
        )
    }
}

export default Account;
