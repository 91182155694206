import React, { Component, Fragment } from 'react';
import { CameraIcon, CheckIcon, GiftIcon, SwitchVerticalIcon, VideoCameraIcon, AnnotationIcon, ChevronDoubleUpIcon, ChevronDoubleDownIcon, SwitchHorizontalIcon, DeviceMobileIcon, LightBulbIcon, ExclamationIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import UploadImageTailwind from './uploadImageTailwind';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import moment from 'moment';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { BeatLoader } from 'react-spinners';
import { random } from '@turf/turf';
import AdvancedReportCellChart from './advancedReportCellChart';
import AdvancedReportCellTable from './advancedReportCellTable';
import { CheckCircleIcon, StarIcon } from '@heroicons/react/solid';

class AbTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            test_123: "",
            reload: false,
            show: true,
            client: {},
            order: {},
            abtest: {},
            metrics: [],
            campaigns: [],
            adgroups: [],
            metric: { id: 0, name: "Select a metric" },
            goal: { id: 0, name: "Highest (Default)", value: "highest" },
            channels: [],
            tab: { id: 1, name: "Overview", value: "bar" },
            chart: {
                line: false,
                bar: false,
                age: false,
                gender: false,
                table: false,
            },
            loading_data: true,
            source: {
                bar: {
                    chart: {
                        value: "bar",
                        tittle: "Bar"
                    },
                    settings: {
                        title: "Total result",
                    },
                    data: false
                },
                line: {
                    chart: {
                        value: "line",
                        tittle: "Line"
                    },
                    settings: {
                        title: "Result per day",
                    },
                    data: false
                },
                age: {
                    chart: {
                        value: "bar",
                        tittle: "Bar"
                    },
                    settings: {
                        title: "Age Breakdown",
                    },
                    data: false
                },
                gender: {
                    chart: {
                        value: "bar",
                        tittle: "Bar"
                    },
                    settings: {
                        title: "Gender Breakdown",
                    },
                    data: false
                },
                table: {
                    chart: {
                        value: "table",
                        tittle: "Table"
                    },
                    settings: {
                        title: "Metrics",
                    },
                    data: false,
                    columns: ["name"]
                },
            }
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            client: this.props.client,
            order: this.props.order,
            abtest: this.props.abtest,
        });
        await this.functions.getMetrics();
        await this.functions.parseData();
        if (this.state.campaigns.length > 0) {
            await this.functions.getCampaignsData();
        } else if (this.state.adgroups.length > 0) {
            await this.functions.getAdgroupsData();
        }
        await this.functions.getMetrics();

        if (moment().isAfter(moment(this.state.start_date))) {
            await this.functions.getLineChart("bar");
            await this.functions.getLineChart("line");
            await this.functions.getLineChart("table");
        }



        console.log("state", this.state)



    }

    functions = {
        updateCharts: async () => {
            return new Promise(async (resolve) => {
                try {
                    if (moment().isAfter(moment(this.state.start_date))) {
                        await this.functions.getLineChart("bar");
                        await this.functions.getLineChart("line");
                        await this.functions.getLineChart("table");
                    }
                } catch (error) {
                    console.log(error, "ERROR CHART")
                }
                resolve();
            })

        },
        getMetrics: async () => {
            return new Promise(async (resolve) => {
                try {
                    if (this.state.channels.length === 0) {
                        let response = await this.calls.getMetrics();

                        await this.promisedSetState({
                            metrics: { calculated: response.data.calculated.filter((item) => { return item.channels && item.calculation_options.length > 0 }), custom: response.data.custom.filter((item) => { return item.channels }) }
                        });
                    } else {

                        if (this.state.metrics.custom) {
                            this.state.metrics.custom = this.state.metrics.custom.filter((metric) => { return this.state.channels.map((channel) => { return metric.channels[channel] }).filter((item) => { return item }).length === this.state.channels.length })
                        }
                        if (this.state.metrics.calculated) {
                            this.state.metrics.calculated = this.state.metrics.calculated.filter((metric) => { return this.state.channels.map((channel) => { return metric.calculation_options.filter((inner) => { return inner.type === "metric" }).map((inner) => { if (inner.value.channels[channel]) { return inner } }).filter((item) => { return item }).length === metric.calculation_options.filter((inner) => { return inner.type === "metric" }).length }).filter((item) => { return item }).length === this.state.channels.length })
                        }
                        await this.promisedSetState({
                            metrics: this.state.metrics
                        });
                    }
                } catch (error) {
                    console.log(error, "ERROR")
                }
                resolve();
            })

        },
        parseData: async () => {
            return new Promise(async (resolve) => {
                try {
                    let metric = {};
                    Object.keys(this.state.metrics).map((key) => {
                        if (this.state.metrics[key].filter((item) => { return item.id == this.state.abtest.metric }).length > 0) {
                            metric = this.state.metrics[key].filter((item) => { return item.id == this.state.abtest.metric })[0]
                        }
                    });
                    await this.promisedSetState({
                        id: this.state.abtest.id,
                        campaigns: this.state.abtest.campaigns,
                        adgroups: this.state.abtest.adgroups,
                        metric: metric,
                        goal: metric.goal ? metric.goal : this.state.goal,//this.state.abtest.goal,
                        start_date: this.state.abtest.startDate,
                        end_date: this.state.abtest.endDate,
                        name: this.state.abtest.name,
                    });
                } catch (error) {
                    console.log(error);
                }
                resolve();
            })
        },
        getCampaignsData: async () => {
            return new Promise(async (resolve) => {
                try {

                    this.promisedSetState({
                        loading_data: true
                    });
                    let campaigns_string = "";

                    this.state.campaigns.map((campaign) => {
                        campaigns_string = campaigns_string + ("&" + campaign.channel + "_campaigns[]=" + campaign.id);
                    });
                    let response = await this.calls.getCampaignsData(campaigns_string);
                    this.state.campaigns = this.state.campaigns.map((campaign) => {
                        if (!this.state.channels.includes(campaign.channel)) {
                            this.state.channels.push(campaign.channel);
                        }
                        if (response.data[campaign.channel]) {
                            response.data[campaign.channel].map((item) => {
                                if (campaign.id === item.id) {
                                    campaign = { ...campaign, ...item };
                                }
                            })
                        }
                        return campaign;
                    });
                    this.promisedSetState({
                        channels: this.state.channels,
                        campaigns: this.state.campaigns,
                        loading_data: false
                    })
                } catch (error) {
                    this.promisedSetState({
                        error: true,
                        loading_data: false
                    })
                }
                resolve();
            })
        },
        getAdgroupsData: async () => {
            try {
                this.promisedSetState({
                    loading_data: true
                });
                let adgroups_string = "";

                this.state.adgroups.map((adgroup) => {
                    adgroups_string = adgroups_string + ("&" + adgroup.channel + "_adgroups[]=" + adgroup.id);
                });
                let response = await this.calls.getAdgroupsData(adgroups_string);
                this.state.adgroups = this.state.adgroups.map((adgroup) => {
                    if (!this.state.channels.includes(adgroup.channel)) {
                        this.state.channels.push(adgroup.channel);
                    }
                    if (response.data[adgroup.channel]) {
                        response.data[adgroup.channel].map((item) => {
                            if (adgroup.id == item.id) {
                                adgroup = { ...adgroup, ...item };
                            }
                        })
                    }
                    return adgroup;
                });
                this.promisedSetState({
                    channels: this.state.channels,
                    adgroups: this.state.adgroups,
                    loading_data: false
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_data: false
                })
            }
        },
        getLineChart: async (type) => {
            return new Promise(async (resolve) => {
                try {
                    await this.promisedSetState({
                        loading_data: true
                    });
                    let colors = {};
                    if (Object.keys(this.state.campaigns).length > 0) {
                        Object.keys(this.state.campaigns).map((key) => {
                            colors[this.state.campaigns[key].id] = this.state.campaigns[key].hex;
                        })
                    }
                    if (Object.keys(this.state.adgroups).length > 0) {
                        Object.keys(this.state.adgroups).map((key) => {
                            colors[this.state.adgroups[key].id] = this.state.adgroups[key].hex;
                        })
                    }

                    let body = [{
                        id: 1,
                        campaigns: this.state.campaigns.map((campaign) => { return { id: campaign.id, channel: campaign.channel, name: campaign.name } }),
                        adgroups: this.state.adgroups.map((adgroup) => { return { id: adgroup.id, channel: adgroup.channel, name: adgroup.name } }),
                        start_date: this.state.start_date,
                        end_date: this.state.end_date,
                        metrics: [{ id: this.state.metric.id, name: this.state.metric.name }],
                        information: {
                            type: "breakdown/metric",
                            color: colors
                        },
                        dimensions: {
                            adform: "overview",
                            basic: "overview",
                            bidtheatre: "overview",
                            bing: "overview",
                            facebook: "overview",
                            google: "overview",
                            google_analytics: "overview",
                            google_analytics_4: "overview",
                            linkedin: "overview",
                            snapchat: "overview",
                            tiktok: "overview",
                            twitter: "overview"
                        }
                    }];
                    if (type === "bar") {
                        body[0].type = "chart_bar";
                        body.push(JSON.parse(JSON.stringify(body[0])));
                        body[1].id = 2;
                        body[1].dimensions = {
                            adform: "age",
                            basic: "age",
                            bidtheatre: "age",
                            bing: "age",
                            facebook: "age",
                            google: "age",
                            google_analytics: "age",
                            google_analytics_4: "age",
                            linkedin: "age",
                            snapchat: "age",
                            tiktok: "age",
                            twitter: "age"
                        };
                        body.push(JSON.parse(JSON.stringify(body[0])));
                        body[2].id = 3;
                        body[2].dimensions = {
                            adform: "gender",
                            basic: "gender",
                            bidtheatre: "gender",
                            bing: "gender",
                            facebook: "gender",
                            google: "gender",
                            google_analytics: "gender",
                            google_analytics_4: "gender",
                            linkedin: "gender",
                            snapchat: "gender",
                            tiktok: "gender",
                            twitter: "gender"
                        };

                    } else if (type === "line") {
                        body[0].type = "chart_line";
                        body[0].dimensions = {
                            adform: "date",
                            basic: "date",
                            bidtheatre: "date",
                            bing: "date",
                            facebook: "date",
                            google: "date",
                            google_analytics: "date",
                            google_analytics_4: "date",
                            linkedin: "date",
                            snapchat: "date",
                            tiktok: "date",
                            twitter: "date"
                        }
                    } else if (type === "table") {
                        body[0].type = "table";
                        body[0].information = {};
                        body[0].metrics.push({ name: "Impressions (default)" });
                        body[0].metrics.push({ name: "Clicks (default)" });
                        body[0].metrics.push({ name: "Spend (default)" });
                        this.state.source.table.columns = ["name", "channel", this.state.metric.name, "Impressions (default)", "Clicks (default)", "Spend (default)"];
                    }


                    let response = await this.calls.getLineChart(body);

                    if (type === "bar") {
                        this.state.chart.bar = response.data.filter((data) => { return data.id == 1 }).length > 0 ? response.data.filter((data) => { return data.id == 1 })[0].data : false;
                        this.state.source.bar.data = response.data.filter((data) => { return data.id == 1 }).length > 0 ? response.data.filter((data) => { return data.id == 1 })[0].data : false;
                        this.state.chart.age = response.data.filter((data) => { return data.id == 2 }).length > 0 ? response.data.filter((data) => { return data.id == 2 })[0].data : false;
                        this.state.source.age.data = response.data.filter((data) => { return data.id == 2 }).length > 0 ? response.data.filter((data) => { return data.id == 2 })[0].data : false;
                        this.state.chart.gender = response.data.filter((data) => { return data.id == 3 }).length > 0 ? response.data.filter((data) => { return data.id == 3 })[0].data : false;
                        this.state.source.gender.data = response.data.filter((data) => { return data.id == 3 }).length > 0 ? response.data.filter((data) => { return data.id == 3 })[0].data : false;
                    } else if (type === "line") {
                        this.state.chart.line = response.data[0].data;
                        this.state.source.line.data = response.data[0].data;
                    } else if (type === "table") {
                        this.state.chart.table = response.data[0].data;
                        this.state.source.table.data = response.data[0].data;
                        if (body[0].campaigns.length > 0) {
                            this.state.campaigns = this.state.campaigns.map((campaign) => {
                                if (response.data[0].data.filter((data) => { return data.id === campaign.id }).length > 0) {
                                    campaign.number = response.data[0].data.filter((data) => { return data.id === campaign.id })[0][this.state.metric.name];
                                }
                                return campaign;
                            })
                        } else {
                            this.state.adgroups = this.state.adgroups.map((adgroup) => {
                                if (response.data[0].data.filter((data) => { return data.id === adgroup.id }).length > 0) {
                                    adgroup.number = response.data[0].data.filter((data) => { return data.id === adgroup.id })[0][this.state.metric.name];
                                }
                                return adgroup;
                            })
                        }
                    }

                    await this.promisedSetState({
                        chart: this.state.chart,
                        loading_data: false,
                        source: this.state.source
                    });
                } catch (error) {
                    console.log(error, "EROR HEre");
                    this.promisedSetState({
                        error: true,
                        loading_data: false
                    })
                }
                resolve();
            })
        }

    };

    calls = {
        getCampaignsData: (campaigns) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?statistics=false&client=" + this.state.client.id + campaigns + "&information=true";
            return apiRegister.call(options, url);
        },
        getAdgroupsData: (adgroups) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?statistics=false&client=" + this.state.client.id + adgroups + "&information=true";
            return apiRegister.call(options, url);
        },
        getMetrics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v2/metrics?custom=true";
            return apiRegister.call(options, url);
        },
        getLineChart: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/reportABTest?&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        let table_outcome = (income, positive = false, negative = false, upcoming = false) => {
            let buffer = income;
            if (positive && !upcoming) {
                buffer = income.filter((item) => { return item.number > 0 });
            } else if (negative) {
                buffer = income.filter((item) => { return item.number === 0 });
            }
            return (
                buffer.sort((a, b) => (this.state.goal.value === "highest" ? b.number - a.number : a.number - b.number)).map((item, index) => {
                    return (
                        <Fragment>
                            <div className="grid grid-cols-12 gap-4">
                                <div className={"col-span-6 truncate text-sm" + (negative ? " text-red-700" : "")}>
                                    {
                                        positive &&
                                        (index + 1) + ". " + item.name
                                    }
                                    {
                                        negative &&
                                        (index + income.filter((inner) => { return inner.number > 0 }).length + 1) + "." + item.name
                                    }
                                </div>
                                <div className="col-span-2">
                                    <div className="flex h-full items-center justify-center">
                                        <div className="h-2 w-2" style={{ background: item.hex }}></div>
                                    </div>
                                </div>
                                <div className={"col-span-1 items-center text-sm text-center" + (negative ? " text-red-700" : "")} >
                                    {item.number}
                                </div>
                                <div className="col-span-2" >
                                    <div className="flex h-full items-center justify-center">
                                        {
                                            negative &&
                                            <ExclamationIcon className="h-5 w-5 text-red-700 mr-2" />
                                        }
                                        {
                                            positive && index === 0 && moment().isAfter(moment(this.state.start_date)) && moment().isBefore(moment(this.state.end_date)) &&
                                            <StarIcon className="h-5 w-5 text-green-700 mr-2" />
                                        }
                                        {
                                            positive && index === 0 && moment().isAfter(moment(this.state.end_date)) && moment().isAfter(moment(this.state.end_date)) &&
                                            <CheckCircleIcon className="h-5 w-5 text-green-700 mr-2" />

                                        }
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div
                                        className={"w-6 h-6 bg-" + item.channel + "-500 rounded-full p-1 border-2 border-white"}>
                                        {
                                            ["facebook", "linkedin", "google", "bing", "twitter", "adform", "bidtheatre", "snapchat"].map((inner_item) => {
                                                if (item.channel === inner_item) {
                                                    if (inner_item === "snapchat") {
                                                        return <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}>
                                                        </div>
                                                    } else if (inner_item === "bidtheatre") {
                                                        return <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}>
                                                        </div>
                                                    } else if (inner_item === "bing") {
                                                        return <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}>
                                                        </div>
                                                    } else {
                                                        return <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/' + inner_item + '_icon.svg') + ")" }}>
                                                        </div>
                                                    }
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                })
            )
        }
        return (
            <>
                <div className="flex flex-1 flex-col h-full">
                    <div className="grid grid-cols-12 gap-4 mb-4 pb-4 border-b">
                        <div className="col-span-12 grid grid-cols-12 gap-4">
                            <div className="col-span-2">
                                <InputTailwind
                                    label={"Name"}
                                    value={this.state.name}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-span-2">
                                <InputTailwind
                                    label={"Status"}
                                    value={moment().isAfter(moment(this.state.start_date)) && moment().isBefore(moment(this.state.end_date)) ? "Active" : (moment().isBefore(moment(this.state.start_date)) ? "Upcoming" : "Ended")}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-span-2">
                                <InputTailwind
                                    label={"Start"}
                                    value={moment(this.state.start_date).format("YYYY-MM-DD")}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-span-2">
                                <InputTailwind
                                    label={"End"}
                                    value={moment(this.state.end_date).format("YYYY-MM-DD")}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    label={"Metric"}
                                    selected={this.state.metric && this.state.metric.name ? this.state.metric : { id: 0, name: "Select a metric" }}
                                    options={Array.isArray(this.state.metrics) ? [] : this.state.metrics.custom.concat(this.state.metrics.calculated)}
                                    onChange={async (value) => {
                                        this.state.metric = value;
                                        await this.promisedSetState({
                                            metric: this.state.metric,
                                            goal: value.goal ? value.goal : this.state.goal
                                        });
                                        this.functions.updateCharts();
                                    }}
                                />
                            </div>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    label={"Goal"}
                                    selected={this.state.goal}
                                    options={[{ id: 1, name: "Highest (Default)", value: "highest" }, { id: 2, name: "Lowest", value: "lowest" }]}
                                    locked={this.state.metric.goal ? true : false}
                                    onChange={async (value) => {
                                        this.state.goal = value.value;
                                        await this.promisedSetState({ reload: true });
                                        await this.promisedSetState({
                                            goal: this.state.goal
                                        });
                                        await this.promisedSetState({ reload: false });
                                    }}
                                />

                            </div>
                        </div>
                    </div>


                    <div className="flex flex-row flex-1">
                        <div className="flex-1">
                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <div className="text-md font-medium mb-2">
                                        {this.state.campaigns.length > 0 ? "Campaigns" : "Adgroups"}
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="text-xs text-center">
                                        Color
                                    </div>
                                </div>
                                <div className="col-span-1 text-center">
                                    <div className="text-xs">
                                        {this.state.metric.name}
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="text-xs text-center">
                                        {
                                            moment().isAfter(moment(this.state.start_date)) && moment().isBefore(moment(this.state.end_date)) &&
                                            "Leader"
                                        }
                                        {
                                            moment().isAfter(moment(this.state.end_date)) &&
                                            "Winner"
                                        }
                                    </div>
                                </div>
                                <div className="col-span-1">
                                </div>
                            </div>
                            {
                                this.state.campaigns.length > 0 &&
                                moment().isBefore(moment(this.state.start_date)) &&
                                !this.state.loading_data &&
                                !this.state.reload &&
                                table_outcome(this.state.campaigns, true, false, true)
                            }
                            {/*ACTIVE CAMPAIGNS*/}
                            {
                                this.state.campaigns.length > 0 &&
                                this.state.campaigns.filter((item) => { return item.number > 0 }).length > 0 &&
                                !this.state.loading_data &&
                                !this.state.reload &&
                                table_outcome(this.state.campaigns, true, false, false)
                            }
                            {/*FAILED CAMPAIGNS*/}
                            {
                                this.state.campaigns.length > 0 &&
                                this.state.campaigns.filter((item) => { return item.number == 0 }).length > 0 &&
                                !this.state.loading_data &&
                                !this.state.reload &&
                                <div className="grid grid-cols-12 gap-4 mt-5">
                                    <div className="col-span-6">
                                        <div className="text-md font-medium mb-2">
                                            Zero value
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="text-xs text-center">
                                            Color
                                        </div>
                                    </div>
                                    <div className="col-span-1 text-center">
                                        <div className="text-xs">
                                            {this.state.metric.name}
                                        </div>
                                    </div>
                                    <div className="col-span-2">

                                    </div>
                                    <div className="col-span-1"></div>
                                </div>
                            }

                            {
                                this.state.campaigns.length > 0 &&
                                this.state.campaigns.filter((item) => { return item.number == 0 }).length > 0 &&
                                !this.state.loading_data &&
                                !this.state.reload &&
                                table_outcome(this.state.campaigns, false, true, false)
                            }

                            {
                                this.state.adgroups.length > 0 &&
                                moment().isBefore(moment(this.state.start_date)) &&
                                !this.state.loading_data &&
                                !this.state.reload &&
                                table_outcome(this.state.adgroups, true, false, true)
                            }
                            {
                                this.state.adgroups.length > 0 &&
                                this.state.adgroups.filter((item) => { return item.number > 0 }).length > 0 &&
                                !this.state.loading_data &&
                                !this.state.reload &&
                                table_outcome(this.state.adgroups, true, false, false)
                            }
                            {/*FAILED ADGROUPS*/}
                            {
                                this.state.adgroups.length > 0 &&
                                this.state.adgroups.filter((item) => { return item.number == 0 }).length > 0 &&
                                !this.state.loading_data &&
                                !this.state.reload &&
                                <div className="grid grid-cols-12 gap-4 mt-5">
                                    <div className="col-span-6">
                                        <div className="text-md font-medium mb-2">
                                            Zero value
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="text-xs text-center">
                                            Color
                                        </div>
                                    </div>
                                    <div className="col-span-1 text-center">
                                        <div className="text-xs">
                                            {this.state.metric.name}
                                        </div>
                                    </div>
                                    <div className="col-span-2">

                                    </div>
                                    <div className="col-span-1"></div>
                                </div>
                            }
                            {
                                this.state.adgroups.length > 0 &&
                                this.state.adgroups.filter((item) => { return item.number === 0 }).length > 0 &&
                                !this.state.loading_data &&
                                !this.state.reload &&
                                table_outcome(this.state.adgroups, false, true, false)
                            }
                            {
                                this.state.loading_data &&
                                <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                        <div class="pl-4 pr-4"><div class="border-r h-full"></div></div>
                        <div className="flex flex-col flex-2">
                            <div>
                                <div className="flex flex-row flex-1">
                                    {
                                        !this.state.loading_data &&
                                        [{ id: 1, name: "Total result", value: "bar" }, { id: 2, name: "Result per day", value: "line" }, { id: 3, name: "Age Breakdown", value: "age" }, { id: 4, name: "Gender Breakdown", value: "gender" }, { id: 5, name: "Metrics", value: "table" }].map((item) => {
                                            return (
                                                <Fragment>
                                                    <div className="ml-4">
                                                        <div onClick={async () => {
                                                            await this.promisedSetState({ show: false });
                                                            await this.promisedSetState({ tab: item });
                                                            await this.promisedSetState({ show: true });
                                                        }} className={(this.state.tab.id === item.id ? "bg-purple-500 text-white " : "bg-gray-300 cursor-pointer") + " hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}>
                                                            {item.name}
                                                        </div>

                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="flex flex-1 relative">
                                {
                                    !this.state.loading_data &&
                                    this.state.show &&
                                    this.state.tab.value !== "table" &&
                                    <AdvancedReportCellChart
                                        external={true}
                                        campaigns={this.state.campaigns}
                                        adgroups={this.state.adgroups}
                                        data={this.state.chart[this.state.tab.value]}
                                        header={"HEADER"}
                                        loading={this.state.loading_data}
                                        source={this.state.source[this.state.tab.value]}


                                    />
                                }
                                {
                                    !this.state.loading_data &&
                                    this.state.show &&
                                    this.state.tab.value === "table" &&
                                    <AdvancedReportCellTable
                                        external={true}
                                        campaigns={this.state.campaigns}
                                        adgroups={this.state.adgroups}
                                        data={this.state.chart[this.state.tab.value]}
                                        header={"HEADER"}
                                        loading={this.state.loading_data}
                                        source={this.state.source[this.state.tab.value]}


                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AbTest;
