import React, { Component, Fragment, useEffect } from 'react';
import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import { apiRegister } from '../services/apiRegister';
import AdvancedReportCellCreative from '../moduleFiles/advancedReportCellCreative';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { Disclosure, Popover, Transition } from '@headlessui/react';
import { CalendarIcon, ChevronDownIcon, DownloadIcon, MenuIcon, RefreshIcon, XIcon } from '@heroicons/react/outline';
import AdvancedReportCellChart from '../moduleFiles/advancedReportCellChart';
import AdvancedReportCellText from '../moduleFiles/advancedReportCellText';
import AdvancedReportCellNumber from '../moduleFiles/advancedReportCellNumber';
import AdvancedReportCellTable from '../moduleFiles/advancedReportCellTable';
import AdvancedReportCellPreview from '../moduleFiles/advancedReportCellPreview';
import InputTailwind from '../moduleFiles/inputTailwind';
import SingleDatepicker from "../modules/singleDatepicker";
import ReportHtml from '../hooks/report/ReportHtml';
import exportPdf from '../hooks/report/exportPdf';
import SuccessModal from "../moduleFiles/successModal";
import ReactTooltip from 'react-tooltip';
import queryString from "query-string";

var moment = require('moment');

class AdvancedReportPublic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            adgroups: [],
            ads: [],
            temp: {},
            selected_campaigns: {},
            selected_adgroups: {},
            custom_daterange: {
                end_date: false,
                start_date: false,
            },
            selected_new_custom_daterange: {
                end_date: false,
                start_date: false,
            },
            selected_ads: {},
            selected_tab: {},
            name: "",
            tabs: [],
            report_fonts: [],
            report_number_cell_font: {},
            report_text_cell_font: {},
            loading_partial: true,
            loading: true,
            dateSpan: { id: 1, name: "Default", value: "default" },
            selected_new_date_span: { id: 1, name: "Default", value: "default" },
            password: "",
            footerSettings: {},
            show_date_picker: false,
            is_scrolling: false,
            pdf_success: false,
            renderedItemsCount: 4
        };

        this.containerRef = React.createRef();
        this.scrollTimeout = null;
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentWillMount() {
        this.functions.report();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.loading !== this.state.loading) {
            if (this.containerRef.current) {
                this.containerRef.current.addEventListener("scroll", this.handleScrollContainer);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }
        if (this.containerRef.current) {
            this.containerRef.current.removeEventListener("scroll", this.handleScrollContainer);
        }
    }

    handleScrollContainer = () => {
        const container = this.containerRef.current;
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 50) {
            this.setState((prevState) => ({
                renderedItemsCount: prevState.renderedItemsCount + 3,
            }));
        }
    };

    handleScroll() {
        this.setState({ is_scrolling: true });

        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }

        this.scrollTimeout = setTimeout(() => {
            this.setState({ is_scrolling: false });
        }, 1000);
    }

    getUniqueErrors(errors) {
        const uniqueErrors = new Set();

        errors.forEach(item => {
            if (typeof item === 'string') {
                uniqueErrors.add(item);
            } else if (item.message) {
                uniqueErrors.add(item.message);
            }
        });

        return Array.from(uniqueErrors);
    }

    functions = {
        saveDatePicker: async () => {
            if (!this.state.loading_partial) {
                this.state.tabs = this.state.tabs.map((tab) => {
                    tab.fetched = false;
                    return tab;
                });
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    dateSpan: this.state.selected_new_date_span,
                    custom_daterange: this.state.selected_new_custom_daterange
                });
                this.functions.partialReport(this.state.selected_tab);
            }
        },
        onDateStartChange: async (date) => {
            if (this.state.selected_new_custom_daterange.start_date < date) {
                this.state.selected_new_custom_daterange.end_date = date;
            } else {
                this.state.selected_new_custom_daterange.start_date = date;
            }
            await this.promisedSetState({
                selected_new_date_span: { id: 0, name: moment(this.state.selected_new_custom_daterange.start_date).format("DD/MM/YYYY") + " - " + moment(this.state.selected_new_custom_daterange.end_date).format("DD/MM/YYYY"), value: "custom" },
                selected_new_custom_daterange: this.state.selected_new_custom_daterange
            })
        },
        onDateEndChange: async (date) => {
            if (this.state.selected_new_custom_daterange.start_date > date) {
                this.state.selected_new_custom_daterange.start_date = date;
            } else {
                this.state.selected_new_custom_daterange.end_date = date;
            }
            await this.promisedSetState({
                selected_new_date_span: { id: 0, name: moment(this.state.selected_new_custom_daterange.start_date).format("DD/MM/YYYY") + " - " + moment(this.state.selected_new_custom_daterange.end_date).format("DD/MM/YYYY"), value: "custom" },
                selected_new_custom_daterange: this.state.selected_new_custom_daterange
            })
        },
        onDateOptionChange: async (item) => {
            if (item.id === 6) {
                return;
            }
            if (!this.state.loading_partial) {
                this.state.tabs = this.state.tabs.map((tab) => {
                    tab.fetched = false;
                    return tab;
                });
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    selected_new_date_span: item,
                });
            }
            let date_range = {}
            switch (item.value) {
                case "default":
                    date_range.start_date = false;
                    date_range.end_date = false;
                    break;
                case "last_7":
                    date_range.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
                    date_range.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    break;
                case "last_14":
                    date_range.start_date = moment().subtract(14, 'days').format('YYYY-MM-DD');
                    date_range.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    break;
                case "last_20":
                    date_range.start_date = moment().subtract(20, 'days').format('YYYY-MM-DD');
                    date_range.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    break;
                case "last_30":
                    date_range.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
                    date_range.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    break;
                case "last_week":
                    date_range.start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
                    date_range.end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
                    break;
                case "last_month":
                    date_range.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                    date_range.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                    break;
                case "this_month":
                    date_range.start_date = moment().startOf('month').format("YYYY-MM-DD");
                    date_range.end_date = moment().format("DD") !== "01" ? moment().subtract(1, 'days').format("YYYY-MM-DD") : "01";
                    break;
            }
            await this.promisedSetState({
                selected_new_custom_daterange: date_range,
            });
        },
        report: async (password) => {
            await this.promisedSetState({
                loading: !password,
                loading_password: password,
                error: false
            });
            this.calls.getReport().then(async (response) => {
                if (response && response.data && response.data.tabs && response.data.tabs.length === 0) {
                    await this.promisedSetState({
                        tabs: response.data.tabs,
                        logo: response.data.logo,
                        name: response.data.name,
                        title: response.data.title,
                        description: response.data.description,
                        loading: false,
                        background_color: response.data.background_color,
                        shadows: response.data.shadows,
                        datelock: response.data.datelock,
                        pdf_download: response.data.pdfDownload,
                        page_sections: response.data.pageSections,
                        transparent_cells: response.data.transparent_cells,
                        navbar_color: response.data.navbar_color ? response.data.navbar_color : "ffffff",
                        accent_color: response.data.accent_color ? response.data.accent_color : "453FF1",
                        font_color: response.data.font_color ? response.data.font_color : "333333",
                        logo_size: response.data.logo_size,
                        password_protected: response.data.password_protected,
                        password_correct: response.data.password_correct,
                        footerSettings: response.data.footerSettings ? response.data.footerSettings : {},
                        report_fonts: response.data.fonts || [],
                        report_number_cell_font: response.data.number_cell_font ? response.data.number_cell_font : {},
                        report_text_cell_font: response.data.text_cell_font ? response.data.text_cell_font : {},
                    })
                } else {

                    response.data.tabs[0].name = response.data.tabs[0].title;

                    //ONLY DISPLAY SPECIFIC TABS
                    try {
                        let query = window.location.search;
                        if (query) {
                            query = queryString.parse(query);
                        }
                        if (query && query.tabs) {
                            let tabs_array = query.tabs.toLowerCase().split(",");
                            response.data.tabs = response.data.tabs.filter((item) => {
                                return tabs_array.includes(item.title.toLowerCase());
                            });
                        }
                    } catch (error) { }

                    //CHECK IF TAB IN QUERY
                    try {
                        let query = window.location.search;
                        if (query) {
                            query = queryString.parse(query);
                        }
                        let tab = null;
                        response.data.tabs.map((item) => {
                            if (query && query.tab.toLowerCase() == item.title.toLowerCase()) {
                                tab = item;
                                tab.name = tab.title;
                            }
                        })
                        if (tab) {
                            await this.promisedSetState({
                                selected_tab: tab,
                                renderedItemsCount: 4,
                                temp: tab
                            })
                        } else {
                            await this.promisedSetState({
                                selected_tab: response.data.tabs[0],
                                renderedItemsCount: 4,
                                temp: response.data.tabs[0]
                            })
                        }
                    } catch (error) {
                        await this.promisedSetState({
                            selected_tab: response.data.tabs[0],
                            renderedItemsCount: 4,
                            temp: response.data.tabs[0]
                        })
                    }

                    await this.promisedSetState({
                        tabs: response.data.tabs,
                        logo: response.data.logo,
                        name: response.data.name,
                        title: response.data.title,
                        description: response.data.description,
                        background_color: response.data.background_color,
                        shadows: response.data.shadows,
                        datelock: response.data.datelock,
                        pdf_download: response.data.pdfDownload,
                        page_sections: response.data.pageSections,
                        transparent_cells: response.data.transparent_cells,
                        navbar_color: response.data.navbar_color ? response.data.navbar_color : "ffffff",
                        accent_color: response.data.accent_color ? response.data.accent_color : "453FF1",
                        font_color: response.data.font_color ? response.data.font_color : "333333",
                        logo_size: response.data.logo_size,
                        password_protected: response.data.password_protected,
                        password_correct: response.data.password_correct,
                        footerSettings: response.data.footerSettings ? response.data.footerSettings : {},
                        report_fonts: response.data.fonts || [],
                        report_number_cell_font: response.data.number_cell_font ? response.data.number_cell_font : {},
                        report_text_cell_font: response.data.text_cell_font ? response.data.text_cell_font : {},
                    });
                    await this.promisedSetState({
                        loading: false,
                    });
                }
                this.functions.loadFonts();
                if (password && this.state.password_protected && !this.state.password_correct) {
                    this.setState({
                        error: true,
                        error_message: "Wrong password",
                        loading_password: false
                    })
                } else {
                    if (!this.state.password_protected || this.state.password_correct) {
                        this.functions.partialReport(this.state.selected_tab);
                    }
                }
            }, (error) => {
                this.promisedSetState({
                    error: true,
                    error_message: error && error.body && error.body.message ? error.body.message : "",
                    loading: false,
                    loading_password: false
                })
            });
        },
        partialReport: async (tab, init) => {
            tab.name = tab.title;
            await this.promisedSetState({
                loading: init,
                loading_partial: true,
                temp: tab,
                error: false
            });
            try {
                let response = await this.calls.getPartialReport({
                    report_id: this.props.match.params.id,
                    tab_id: tab.id,
                    ...this.state.dateSpan.value === "custom" && { ...this.state.custom_daterange }
                });
                response.data.tabs[0].fetched = true;
                this.state.tabs = this.state.tabs.map((item) => {
                    if (item.id == response.data.tabs[0].id) {
                        item = response.data.tabs[0];
                        item.name = item.title;
                        item.fetched = true;
                    }
                    return item;
                });
                await this.promisedSetState({
                    loading: false,
                    tabs: this.state.tabs,
                    selected_tab: response.data.tabs[0],
                    renderedItemsCount: 4,
                    temp: response.data.tabs[0],
                    loading_partial: false,
                });
                await this.promisedSetState({
                    change_tab: false
                });
            } catch (error) {
                console.log(error);
                this.promisedSetState({
                    error: true,
                    error_message: error && error.body && error.body.message ? error.body.message : "",
                    loading: false,
                    loading_partial: false,
                    change_tab: false
                })
            }
        },
        renderYear: () => {
            let date = new Date();
            return moment(date).format("YYYY");
        },
        loadFullReport: async () => {
            try {
                await this.promisedSetState({
                    loading: true,
                    loading_partial: true,
                    error: false
                });

                const tabs = await Promise.all(this.state.tabs.map(async (tab) => {
                    if (tab.fetched) {
                        return tab;
                    }

                    const response = await this.calls.getPartialReport({
                        report_id: this.props.match.params.id,
                        tab_id: tab.id,
                        ...this.state.dateSpan.value === "custom" && { ...this.state.custom_daterange }
                    });

                    const fetchedTab = response.data.tabs[0];
                    fetchedTab.name = fetchedTab.title;
                    fetchedTab.fetched = true;
                    return fetchedTab;
                }));

                await this.promisedSetState({
                    loading: false,
                    tabs,
                    loading_partial: false
                });

            } catch (error) {
                console.error('loadFullReport:', error);
                await this.promisedSetState({
                    loading: false,
                    loading_partial: false,
                    error: true
                });
            }
        },
        getReportHtml: () => {
            return <ReportHtml
                tabs={this.state.tabs}
                renders={this.renders}
                fonts={this.state.report_fonts}
                report_text_cell_font={this.state.report_text_cell_font}
                report_number_cell_font={this.state.report_number_cell_font}
                transparent_cells={this.state.transparent_cells}
                background_color={this.state.background_color}
            />
        },
        exportPdf: async () => {
            try {
                await this.functions.loadFullReport();
                this.setState({ loading_pdf: true });
                await exportPdf(this.functions.getReportHtml);
                this.setState({ pdf_success: true, loading_pdf: false });
            } catch (error) {
                this.setState({ pdf_success: false, loading_pdf: false });
            }
        },
        loadFonts: () => {
            this.state.report_fonts.forEach((font) => {
                const fontFace = new FontFace(font.name, `url(${font.url})`);
                fontFace.load().then(() => {
                    document.fonts.add(fontFace);
                }).catch((error) => {
                    console.error(`Failed to load font ${font.name}:`, error);
                });
            });
        }
    };

    calls = {
        getReport: (data) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getPublicReport?report=" + this.props.match.params.id + (this.state.password_protected ? "&password=" + this.state.password : "");
            return apiRegister.call(options, url);
        },
        getPartialReport: (data) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/publicPartialReport?dateSpan=" + this.state.dateSpan.value + (this.state.password_protected ? "&password=" + this.state.password : "") + "&tab_id=" + data.tab_id + "&report_id=" + data.report_id + (this.state.dateSpan.value == "custom" ? ("&start_date=" + data.start_date + "&end_date=" + data.end_date) : "") + (data.cell_id ? ("&cell_id=" + data.cell_id) : "");
            return apiRegister.call(options, url);
        },
    };

    cells = {
        update: async (cell) => {
            let response = await this.calls.getPartialReport({
                report_id: this.props.match.params.id,
                tab_id: this.state.temp.id,
                cell_id: cell.i,
                ...this.state.dateSpan.value === "custom" && { ...this.state.custom_daterange }
            });

            this.state.tabs = this.state.tabs.map((tab) => {
                if (tab.id == response.data.tabs[0].id) {
                    tab.grid_data = tab.grid_data.map(item => {
                        if (item.i == cell.i || item.parent_cell == cell.i) {
                            item = response.data.tabs[0].grid_data.find(response_item => response_item.i == item.i);
                        }

                        return item;
                    });

                    tab.groups = tab.groups.map(group => {
                        if (group.id == cell.group) {
                            group = response.data.tabs[0].groups.find(response_group => response_group.id == cell.group);
                        }

                        return group;
                    });
                }
                return tab;
            });

            this.promisedSetState({
                tabs: this.state.tabs,
            })
        }
    };

    renders = {
        translateCellCoordinatesToGrid: (cell) => {
            let x = 0;
            let y = 0;
            let w = 0;
            let h = 0;
            if ('x_desktop' in cell) {
                x = cell.x_desktop;
            } else {
                x = cell.x;
            }
            if ('y_desktop' in cell) {
                y = cell.y_desktop;
            } else {
                y = cell.y;
            }
            if ('w_desktop' in cell) {
                w = cell.w_desktop;
            } else {
                w = cell.w;
            }
            if ('h_desktop' in cell) {
                h = cell.h_desktop;
            } else {
                h = cell.h;
            }
            let stylingObject = {
                gridArea: (y + 1) + " / " + (x + 1) + " / span " + h + " / span " + w
            }
            return stylingObject;
        },
        translateCellPadding: (direction, cell, page) => {
            let x = 0;
            let y = 0;
            let h = 0;
            let w = 0;
            if ('x_desktop' in cell) {
                x = cell.x_desktop;
            } else {
                x = cell.x;
            }
            if ('y_desktop' in cell) {
                y = cell.y_desktop;
            } else {
                y = cell.y;
            }
            if ('h_desktop' in cell) {
                h = cell.h_desktop;
            } else {
                h = cell.h;
            }
            if ('h_desktop' in cell) {
                w = cell.w_desktop;
            } else {
                w = cell.w;
            }
            let rows = typeof page.rows == 'number' ? +page.rows : 0;
            if (y + 1 === 1 && direction == "top") {
                return " pt-4 ";
            } else if (x + 1 === 1 && direction == "left") {
                return " pl-4 ";
            } else if (((x + w >= 7) || (w == 8)) && direction == "right") {
                return " pr-4 ";
            } else if (y + h >= rows && direction == "bottom") {
                return " pb-4 ";
            } else {
                if (direction == "top") {
                    return " pt-2 ";
                } else if (direction == "bottom") {
                    return " pb-2 ";
                } else if (direction == "right") {
                    return " pr-2 ";
                } else if (direction == "left") {
                    return " pl-2 ";
                } else {
                    return "";
                }
            }
        },
        translateCellPaddingMobile: (direction, cell, page) => {
            let x = 0;
            let y = 0;
            let h = 0;
            let w = 0;
            if ('x_mobile' in cell) {
                x = cell.x_mobile;
            } else {
                x = cell.x;
            }
            if ('y_mobile' in cell) {
                y = cell.y_mobile;
            } else {
                y = cell.y;
            }
            if ('h_mobile' in cell) {
                h = cell.h_mobile;
            } else {
                h = cell.h;
            }
            if ('w_mobile' in cell) {
                w = cell.w_mobile;
            } else {
                w = cell.w;
            }
            let rows = typeof page.mobile_rows == 'number' ? +page.mobile_rows : 0;
            if (y + 1 === 1 && direction == "top") {
                return " pt-4 ";
            } else if (x + 1 === 1 && direction == "left") {
                return " pl-4 ";
            } else if (((x + 1 === 2) || (w == 8)) && direction == "right") {
                return " pr-4 ";
            } else if (y + h >= rows && direction == "bottom") {
                return " pb-4 ";
            } else {
                if (direction == "top") {
                    return " pt-2 ";
                } else if (direction == "bottom") {
                    return " pb-2 ";
                } else if (direction == "right") {
                    return " pr-2 ";
                } else if (direction == "left") {
                    return " pl-2 ";
                } else {
                    return "";
                }
            }
        },
        translateCellCoordinatesToGridMobile: (cell) => {
            let x = 0;
            let y = 0;
            let w = 0;
            let h = 0;
            if ('x_mobile' in cell) {
                x = cell.x_mobile;
            } else {
                x = cell.x;
            }
            if ('y_mobile' in cell) {
                y = cell.y_mobile;
            } else {
                y = cell.y;
            }
            if ('w_mobile' in cell) {
                w = cell.w_mobile;
            } else {
                w = cell.w;
            }
            if ('h_mobile' in cell) {
                h = cell.h_mobile;
            } else {
                h = cell.h;
            }
            let stylingObject = {
                gridArea: (y + 1) + " / " + (x + 1) + " / span " + h + " / span " + w,
            }
            return stylingObject;
        },
        mobileCellOrder: (items) => {
            let arranged = [];
            items.sort(function (a, b) {
                return a.y == b.y ? a.x - b.x : a.y - b.y;
            });
            for (var i = 0; i < items.length; i++) {
                if (typeof (items[i].wasAdded) == "undefined") {
                    arranged.push(items[i]);
                    items[i].wasAdded = "true";
                    for (let j = i + 1; j < items.length; j++) {
                        if (items[i].y > items[j].y && typeof (items[j].wasAdded) == "undefined") {
                            arranged.push(items[j]);
                            items[j].wasAdded = "true";
                        }
                    }
                }
            }
            items = items.map((item) => {
                delete item.wasAdded;
                return item;
            });
            return arranged;
        },
        rowHeight: (page) => {
            let style_string = "";
            if (page.rows) {
                for (let i = 1; i < page.rows; i++) {
                    style_string = style_string + "100px ";
                }
            }
            return style_string;
        },
        rowHeightMobile: (page) => {
            let style_string = "";
            if (page.mobile_rows) {
                for (let i = 1; i < page.mobile_rows; i++) {
                    style_string = style_string + "100px ";
                }
            }
            return style_string;
        },
        sameCellDatesInTab: (tab) => {
            let dates = [];
            if (Array.isArray(tab.grid_data)) {
                tab.grid_data.map((cell) => {
                    if (cell.daterange && !cell.abstract) {
                        let date_string = "";
                        date_string = cell.daterange.start_date + " -> " + cell.daterange.end_date;
                        if (!dates.includes(date_string)) {
                            dates.push(date_string);
                        }
                    }
                });
            }
            return dates.length == 1 ? dates[0] : null;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        let dateOptions = [
            { id: 1, name: "Default", value: "default" },
            { id: 2, name: "Last 7 days", value: "last_7" },
            { id: 3, name: "Last 14 days", value: "last_14" },
            { id: 4, name: "Last 20 days", value: "last_20" },
            { id: 5, name: "Last 30 days", value: "last_30" },
            { id: 7, name: "Last week", value: "last_week" },
            { id: 8, name: "Last month", value: "last_month" },
            { id: 8, name: "This month", value: "this_month" },
            //{ id: 6, name: "Custom dates", value: this.state.custom_daterange ? this.state.custom_daterange.toString() : "custom" }
        ]

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div
                style={{ backgroundColor: "#" + this.state.background_color }}
                className={`flex flex-1 flex-col min-h-full w-full absolute`}
            >

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={this.state.accent_color ? { borderColor: "#" + this.state.accent_color, borderTopColor: this.state.background_color ? ("#" + this.state.background_color) : "#ffffff" } : { borderColor: "#453FF1", borderTopColor: this.state.background_color ? ("#" + this.state.background_color) : "#ffffff" }} className="w-10 h-10 border-2 border-solid rounded-full animate-spin"></div>
                    </div>
                }

                {/*PASSWORD VIEW*/}
                {
                    !this.state.loading &&
                    this.state.password_protected &&
                    !this.state.password_correct &&
                    <div className="fixed left-0 right-0 bottom-0 top-0 flex justify-center items-center">
                        <div className="flex flex-col justify-center w-full max-w-md">
                            {
                                this.state.logo && this.state.logo !== "" &&
                                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                    <img
                                        className="mx-auto h-20 w-auto"
                                        src={this.state.logo}
                                        alt=""
                                    />
                                </div>
                            }
                            <div className="mt-8 w-full">
                                <div className="bg-white p-6 shadow rounded-lg">
                                    <div className="">
                                        <div className="mt-1">
                                            <InputTailwind
                                                label={"Password"}
                                                value={this.state.password}
                                                onChange={(value) => {
                                                    this.setState({
                                                        password: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="w-full mt-6">
                                            <button
                                                onClick={() => {
                                                    if (this.state.password !== "") {
                                                        this.functions.report(true);
                                                    }
                                                }}
                                                style={this.state.accent_color ? { backgroundColor: "#" + this.state.accent_color } : { backgroundColor: "#453FF1" }}
                                                className={" text-white h-10 w-full flex shadow relative items-center justify-center rounded-md text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                            >
                                                Verify
                                                {
                                                    this.state.loading_password &&
                                                    <div style={this.state.accent_color ? { backgroundColor: "#" + this.state.accent_color } : { backgroundColor: "#453FF1" }} className="w-full h-full absolute inset-0 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} className="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                        <div>
                                            <div>
                                                {this.state.error && (
                                                    <div
                                                        className="block text-sm font-medium text-red-700 text-center mt-4"
                                                    >
                                                        {this.state.error_message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    (!this.state.password_protected || (this.state.password_protected && this.state.password_correct)) &&
                    !this.state.loading &&
                    <Disclosure as="nav" className="shadow sticky top-0 z-50"
                        style={this.state.navbar_color ? { backgroundColor: "#" + this.state.navbar_color } : {}}
                    >
                        {({ open }) => (
                            <>
                                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                                    <div className="relative w-full flex h-16 justify-between">

                                        {/* MOBILE TOP MENU */}
                                        <div className="w-full absolute inset-y-0 left-0 flex items-center sm:hidden justify-between">
                                            <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500">
                                                <span className="absolute -inset-0.5" />
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XIcon className="block h-6 w-6" aria-hidden="true"
                                                        style={this.state.font_color ? { color: "#" + this.state.font_color } : {}}
                                                    />
                                                ) : (
                                                    <MenuIcon className="block h-6 w-6" aria-hidden="true"
                                                        style={this.state.font_color ? { color: "#" + this.state.font_color } : {}}
                                                    />
                                                )}
                                            </Disclosure.Button>
                                        </div>

                                        {/* DESKTOP NAVBAR */}
                                        <div className="flex w-full items-center justify-center sm:items-stretch sm:justify-start">
                                            {
                                                this.state.logo && this.state.logo !== "" &&
                                                <div className="inline-flex items-center">
                                                    <img
                                                        className={`${this.state.logo_size ? this.state.logo_size : "h-5"}`}
                                                        src={this.state.logo}
                                                    />
                                                </div>
                                            }
                                            <div style={{ transform: "rotateX(180deg)" }} className="hidden items-center overflow-auto flex-1 sm:mx-6 sm:flex sm:space-x-8">
                                                {
                                                    Array.isArray(this.state.tabs) &&
                                                    this.state.tabs.map((item, index) => {
                                                        return (
                                                            <div
                                                                className={"inline-flex h-full justify-center items-center border-b-4 border-transparent cursor-pointer px-1 pt-1 text-sm font-medium"}
                                                                style={item.id === this.state.temp.id ? { borderColor: "#" + this.state.accent_color, transform: "rotateX(180deg)" } : { transform: "rotateX(180deg)" }}
                                                            >
                                                                <div onClick={async (e) => {
                                                                    if (!this.state.loading_partial && this.state.selected_tab.id !== item.id) {
                                                                        if (!item.fetched) {
                                                                            await this.promisedSetState({
                                                                                change_tab: true
                                                                            });
                                                                            this.functions.partialReport(item);
                                                                        } else {
                                                                            await this.promisedSetState({
                                                                                change_tab: true
                                                                            });
                                                                            item.name = item.title;
                                                                            await this.promisedSetState({
                                                                                selected_tab: item,
                                                                                renderedItemsCount: 4,
                                                                                temp: item
                                                                            });
                                                                            await this.promisedSetState({
                                                                                change_tab: false
                                                                            });
                                                                        }
                                                                    }
                                                                }} className={`font-medium p-2 px-3 text-sm ${item.id === this.state.temp.id ? "opacity-100" : "opacity-50 hover:opacity-100"}`} title={item.title}
                                                                    style={this.state.font_color ? { color: "#" + this.state.font_color } : {}}
                                                                >
                                                                    <div className="relative whitespace-no-wrap">
                                                                        <div>{item.title}</div>
                                                                        {
                                                                            false &&
                                                                            item.open_menu &&
                                                                            <div className="left-0 min-w-32 max-w-32 absolute w-full">
                                                                                {item.groups.map((group) => (
                                                                                    <div
                                                                                        key={group.name}
                                                                                        className="flex p-4 hover:bg-gray-50"
                                                                                        onClick={() => {
                                                                                            const element = document.getElementById(group.name);
                                                                                            if (element) {
                                                                                                window.scrollTo({
                                                                                                    top: element.offsetTop,
                                                                                                    behavior: 'smooth'
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <div>
                                                                                            <div className="font-medium truncate overflow-hidden text-sm"
                                                                                                style={this.state.font_color ? { color: "#" + this.state.font_color } : {}}
                                                                                            >
                                                                                                {group.name ? group.name : index + 1}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                (this.state.datelock || this.state.pdf_download) &&
                                                <div className="absolute sm:relative right-0 inline-flex items-center">

                                                    {/* PDF DOWNLOAD */}
                                                    {
                                                        this.state.pdf_download &&
                                                        <div
                                                            onClick={async () => {
                                                                if (!this.state.loading_pdf && !this.state.loading_partial) {
                                                                    await this.promisedSetState({
                                                                        loading_pdf: true
                                                                    });
                                                                    this.functions.exportPdf();
                                                                }
                                                            }}
                                                            data-tip='' data-for={"download_pdf"} key={"download_pdf"}
                                                            className={`relative max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none p-1 rounded-md bg-gray-50 cursor-pointer`}>
                                                            <DownloadIcon className="w-5 h-5"></DownloadIcon>
                                                            <ReactTooltip effect='solid' id={"download_pdf"}>
                                                                <div className="text-sm">Download as PDF</div>
                                                            </ReactTooltip>
                                                            {
                                                                this.state.loading_pdf &&
                                                                <div
                                                                    className="w-full h-full absolute inset-0 bg-gray-50 flex justify-center items-center z-20 rounded-md">
                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                        className="w-4 h-4 border-2 border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.loading_partial &&
                                                                <div className="bg-white cursor-not-allowed z-50 absolute opacity-50 left-0 top-0 right-0 bottom-0 rounded-md"></div>
                                                            }
                                                        </div>
                                                    }

                                                    {/* DATE PICKER */}
                                                    {
                                                        this.state.datelock &&
                                                        this.state.selected_tab.daterange &&
                                                        <div className={`items-center ml-3 relative`}>
                                                            {
                                                                this.state.loading_partial &&
                                                                <div className="bg-white cursor-not-allowed z-50 absolute opacity-50 left-0 top-0 right-0 bottom-0 rounded-md"></div>
                                                            }
                                                            <Popover as="div" className="relative">
                                                                {({ open, close }) => {
                                                                    useEffect(() => {
                                                                        const managePopoverState = async () => {
                                                                            if (open) {
                                                                                await this.promisedSetState({
                                                                                    selected_new_date_span: this.state.dateSpan,
                                                                                    selected_new_custom_daterange: this.state.custom_daterange
                                                                                });
                                                                            }
                                                                        };
                                                                        managePopoverState();
                                                                    }, [open]);
                                                                    return (
                                                                        <>
                                                                            <div>
                                                                                <Popover.Button
                                                                                    data-tip='' data-for={"calender_button"} key={"calender_button"}
                                                                                    className={`max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none p-1 rounded-md bg-gray-50`}>
                                                                                    {
                                                                                        this.renders.sameCellDatesInTab(this.state.selected_tab) &&
                                                                                        <span className="mr-3 text-gray-700 text-sm pt-0.5 font-medium block">
                                                                                            {this.renders.sameCellDatesInTab(this.state.selected_tab)}
                                                                                        </span>
                                                                                    }
                                                                                    <CalendarIcon className="w-5 h-5" />
                                                                                    <ReactTooltip effect='solid' id={"calender_button"}>
                                                                                        <div className="text-sm">Update date</div>
                                                                                    </ReactTooltip>
                                                                                </Popover.Button>
                                                                            </div>
                                                                            <Transition
                                                                                as={Fragment}
                                                                                enter="transition ease-out duration-100"
                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                enterTo="transform opacity-100 scale-100"
                                                                                leave="transition ease-in duration-75"
                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                leaveTo="transform opacity-0 scale-95"
                                                                            >
                                                                                <Popover.Panel
                                                                                    className="origin-top-right absolute z-50 border-1.5 right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                    <div style={{ minWidth: "400px" }} className="relative">
                                                                                        <div className="p-4">
                                                                                            <DropdownTailwind
                                                                                                label={""}
                                                                                                selected={this.state.selected_new_date_span}
                                                                                                options={dateOptions}
                                                                                                onChange={async (item) => {
                                                                                                    await this.functions.onDateOptionChange(item);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ minWidth: "400px" }} className='px-4 rounded bg-white'>
                                                                                        <SingleDatepicker
                                                                                            maxDate={new Date()}
                                                                                            from={this.state.selected_new_custom_daterange.start_date}
                                                                                            to={this.state.selected_new_custom_daterange.end_date}
                                                                                            onChange={async (data) => {
                                                                                                await this.promisedSetState({
                                                                                                    selected_new_custom_daterange: data,
                                                                                                    selected_new_date_span: {
                                                                                                        id: 0,
                                                                                                        name: moment(data.start_date).format("DD/MM/YYYY") + " - " + moment(data.end_date).format("DD/MM/YYYY"),
                                                                                                        value: "custom"
                                                                                                    }
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="p-4 w-full">
                                                                                        <div className='flex justify-between'>
                                                                                            <button
                                                                                                className={`bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2`}
                                                                                                onClick={() => {
                                                                                                    close();
                                                                                                }}
                                                                                            >
                                                                                                Close
                                                                                            </button>
                                                                                            <button
                                                                                                onClick={async () => {
                                                                                                    if (this.state.selected_new_custom_daterange.end_date) {
                                                                                                        await this.functions.saveDatePicker();
                                                                                                        close();
                                                                                                    }
                                                                                                }}
                                                                                                className={(this.state.selected_new_custom_daterange.end_date ? "cursor-pointer" : "cursor-not-allowed opacity-50") + " bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}>
                                                                                                Save
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Popover.Panel>
                                                                            </Transition>
                                                                        </>
                                                                    )
                                                                }}
                                                            </Popover>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {/* DESKTOP TOP MENU END */}

                                    </div>
                                </div>

                                {/* MOBILE VIEW */}
                                <Disclosure.Panel className="sm:hidden">
                                    <div className="space-y-1 pb-4 pt-2">
                                        {
                                            Array.isArray(this.state.tabs) &&
                                            this.state.tabs.map((item, index) => {
                                                return (
                                                    <div
                                                        className="block border-l-4 border-transparent bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium"
                                                        style={item.id === this.state.temp.id ? { borderColor: "#" + this.state.accent_color } : {}}
                                                    >
                                                        <div onClick={async (e) => {
                                                            if (!this.state.loading_partial && this.state.selected_tab.id !== item.id) {
                                                                if (!item.fetched) {
                                                                    await this.promisedSetState({
                                                                        change_tab: true
                                                                    });
                                                                    this.functions.partialReport(item);
                                                                } else {
                                                                    await this.promisedSetState({
                                                                        change_tab: true
                                                                    });
                                                                    item.name = item.title;
                                                                    await this.promisedSetState({
                                                                        selected_tab: item,
                                                                        renderedItemsCount: 4,
                                                                        temp: item
                                                                    });
                                                                    await this.promisedSetState({
                                                                        change_tab: false
                                                                    });
                                                                }
                                                            }
                                                        }}
                                                            className="font-medium cursor-pointer truncate p-2 px-3 text-sm"
                                                            title={item.title}
                                                            style={this.state.font_color ? { color: "#" + this.state.font_color } : {}}
                                                        >
                                                            <div className="truncate whitespace-no-wrap">
                                                                <span>{item.title}</span>
                                                                {
                                                                    false &&
                                                                    item.fetched && item.groups && item.groups.length > 1 && item.groups.filter((group) => { return group.name }).length && (
                                                                        <Popover>
                                                                            <Popover.Button
                                                                                className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6">
                                                                                <span>{item.title}</span>
                                                                                <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                                                                            </Popover.Button>

                                                                            <Popover.Panel
                                                                                transition
                                                                                className="relative left-1/2 z-10 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                                                            >
                                                                                <div className="w-screen max-w-md flex-auto overflow-hidden text-sm leading-6">
                                                                                    <div>
                                                                                        {item.groups.filter((group) => {
                                                                                            return group.name;
                                                                                        }).map((group) => (
                                                                                            <div
                                                                                                key={group.name}
                                                                                                className="group relative flex gap-x-6 p-4"
                                                                                                onClick={() => {
                                                                                                    const element = document.getElementById(group.name + 'mobile');
                                                                                                    if (element) {
                                                                                                        window.scrollTo({
                                                                                                            top: element.offsetTop,
                                                                                                            behavior: 'smooth'
                                                                                                        });
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <div>
                                                                                                    <div className="font-medium truncate overflow-hidden text-sm"
                                                                                                        styleно={this.state.font_color ? { color: "#" + this.state.font_color } : {}}
                                                                                                    >
                                                                                                        {group.name}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </Popover.Panel>
                                                                        </Popover>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                }

                {/*VIEW*/}
                {
                    (!this.state.password_protected || (this.state.password_protected && this.state.password_correct)) && !this.state.loading &&
                    <div className="flex flex-1 flex-col">
                        <div className={(this.state.loading_partial ? "overflow-hidden h-160" : "") + " flex justify-center relative w-full"}>

                            {/*LOADING PARTIAL*/}
                            {
                                this.state.loading_partial &&
                                <div style={{ backgroundColor: "#" + this.state.background_color }} className="absolute left-0 z-50 rounded-b-md right-0 top-0 bottom-0 flex justify-center items-center flex-col flex-1 w-full">
                                    <div style={this.state.accent_color ? { borderColor: "#" + this.state.accent_color, borderTopColor: this.state.background_color ? ("#" + this.state.background_color) : "#ffffff" } : { borderColor: "#453FF1", borderTopColor: this.state.background_color ? ("#" + this.state.background_color) : "#ffffff" }} className="w-10 h-10 border-2 border-solid rounded-full animate-spin"></div>
                                </div>
                            }

                            {/* GRID DESKTOP*/}
                            <div ref={this.containerRef} className="w-full hidden md:block relative"
                                style={{ height: `${window.innerHeight - 64}px`, overflowY: "auto" }} >

                                {/* TITLE AND DESCRIPTION */}
                                {
                                    (this.state.title || this.state.description) &&
                                    <div className="mx-auto" style={{ maxWidth: "1200px" }}>
                                        <div className={"px-4 pb-5 mt-10"}>
                                            <h3 className="text-base font-semibold leading-6">{this.state.title && this.state.title !== "" && this.state.title}</h3>
                                            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                                {this.state.description && this.state.description !== "" && this.state.description}
                                            </p>
                                        </div>
                                    </div>
                                }

                                {
                                    Array.isArray(this.state.selected_tab.groups) &&
                                    this.state.selected_tab.groups.slice(0, this.state.renderedItemsCount).map((group, index) => {
                                        return (
                                            <Fragment>
                                                <div style={{
                                                    ...(group.enable_background && group.enable_background_full && !group.enable_image && { backgroundColor: "#" + group.background_color }),
                                                    ...(group.enable_background_full && group.enable_image && { backgroundImage: "url(" + group.pageImage + ")", backgroundSize: "cover" }),
                                                }} className={(!group.enable_background_full ? " px-4 " : "") + (group.enable_margin ? " mt-4 mb-4 " : "") + " w-full"}>
                                                    {/*group.enable_background && group.enable_background_full ? { ...{ backgroundColor: "#" + group.background_color } } : {}*/}
                                                    <div className="mx-auto" style={{ maxWidth: "1200px" }}>
                                                        {
                                                            this.state.page_sections &&
                                                            <div
                                                                key={group.name}
                                                                id={group.id}
                                                                style={{ top: '70px' }}
                                                                className={`rounded-md inline-flex shadow px-2 py-1 bg-white sticky z-40 text-xs font-medium transition-opacity duration-300 ease-in-out ${this.state.is_scrolling ? 'opacity-100' : 'opacity-0'}`}
                                                            >
                                                                {group.name ? group.name : index + 1}
                                                            </div>
                                                        }
                                                        <div
                                                            style={{
                                                                ...({ height: (100 * +group.rows) + "px", gridTemplateRows: this.renders.rowHeight(group) }),
                                                                ...(group.enable_background && !group.enable_image && { backgroundColor: "#" + group.background_color }),
                                                                ...(!group.enable_background_full && group.enable_image && { backgroundImage: "url(" + group.pageImage + ")", backgroundSize: "cover" }),
                                                            }}
                                                            className={(group.rows ? ("grid-rows-" + group.rows) : "row-auto") + (group.enable_round_corners ? " rounded-md " : "") + " w-full overflow-hidden grid-cols-8 grid"}
                                                        >
                                                            {/*
                                                                group.enable_background ? { ...{ backgroundColor: "#" + group.background_color }, ...{ height: (100 * +group.rows) + "px" }, ...{ gridTemplateRows: this.renders.rowHeight(group) } } : {
                                                                height: (100 * +group.rows) + "px",
                                                                gridTemplateRows: this.renders.rowHeight(group)
                                                                */}
                                                            {
                                                                !this.state.change_tab &&
                                                                Array.isArray(this.state.selected_tab.grid_data) &&
                                                                this.state.selected_tab.grid_data.filter((cell) => {
                                                                    return !cell.abstract
                                                                }).filter((cell) => {
                                                                    return cell.group === group.id;
                                                                }).map((cell) => {
                                                                    return (
                                                                        <div
                                                                            style={this.renders.translateCellCoordinatesToGrid(cell)}
                                                                            className={(!(cell.settings && cell.settings.disable_margin_top) ? this.renders.translateCellPadding('top', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_bottom) ? this.renders.translateCellPadding('bottom', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_right) ? this.renders.translateCellPadding('right', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_left) ? this.renders.translateCellPadding('left', cell, group) : "")}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    ...(cell.settings && cell.settings.background_color && !cell.settings.transparent && { backgroundColor: "#" + cell.settings.background_color }),
                                                                                    ...(cell.settings && !cell.settings.background_color && !cell.settings.transparent && { backgroundColor: "#FFFFFF" })
                                                                                }}
                                                                                className={(!(cell.settings && (cell.settings.disable_margin_top || cell.settings.disable_margin_left)) ? " rounded-tl-md " : "") + (!(cell.settings && (cell.settings.disable_margin_top || cell.settings.disable_margin_right)) ? " rounded-tr-md " : "") + (!(cell.settings && (cell.settings.disable_margin_bottom || cell.settings.disable_margin_right)) ? " rounded-br-md " : "") + (!(cell.settings && (cell.settings.disable_margin_bottom || cell.settings.disable_margin_left)) ? " rounded-bl-md " : "") + (cell.settings && cell.settings.shadow ? " shadow-xl " : "") + " w-full h-full relative overflow-hidden"}
                                                                            >
                                                                                {/*backgroundColor: cell.settings && cell.settings.background_color ? "#" + cell.settings.background_color : "#FFFFFF"*/}
                                                                                <div id={cell.i + "_preview"} className="absolute w-full h-full">
                                                                                    {
                                                                                        cell.typeOfBlock === "img" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                        <AdvancedReportCellCreative
                                                                                            header={cell.header}
                                                                                            data={cell.data}
                                                                                            format={cell.format}
                                                                                            typeOfBlock={cell.typeOfBlock}
                                                                                            static={true}
                                                                                            external={true}
                                                                                            source={cell}
                                                                                            transparent_cells={this.state.transparent_cells}
                                                                                            report_background_color={this.state.background_color}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        cell.typeOfBlock === "preview" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                        <AdvancedReportCellPreview
                                                                                            header={cell.header}
                                                                                            source={cell}
                                                                                            format={cell.format}
                                                                                            typeOfBlock={cell.typeOfBlock}
                                                                                            static={true}
                                                                                            external={true}
                                                                                            transparent_cells={this.state.transparent_cells}
                                                                                            report_background_color={this.state.background_color}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        cell.typeOfBlock === "chart" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                        <AdvancedReportCellChart
                                                                                            source={cell}
                                                                                            data={cell.data}
                                                                                            items={this.state.selected_tab.grid_data.filter((cell) => {
                                                                                                return cell.group === group.id;
                                                                                            })}
                                                                                            metric={cell.metric}
                                                                                            header={cell.header}
                                                                                            typeOfBlock={cell.typeOfBlock}
                                                                                            static={true}
                                                                                            external={true}
                                                                                            transparent_cells={this.state.transparent_cells}
                                                                                            report_background_color={this.state.background_color}
                                                                                            base_font={this.state.report_text_cell_font}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        cell.typeOfBlock === "input" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                        <AdvancedReportCellText
                                                                                            header={cell.header}
                                                                                            data={cell.data}
                                                                                            format={cell.format}
                                                                                            fonts={this.state.report_fonts}
                                                                                            base_font={this.state.report_text_cell_font}
                                                                                            typeOfBlock={cell.typeOfBlock}
                                                                                            static={true}
                                                                                            external={true}
                                                                                            onInput={() => { }}
                                                                                            source={cell}
                                                                                            transparent_cells={this.state.transparent_cells}
                                                                                            report_background_color={this.state.background_color}
                                                                                            base_font={this.state.report_text_cell_font}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        cell.typeOfBlock === "number" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                        <AdvancedReportCellNumber
                                                                                            source={cell}
                                                                                            base_font={this.state.report_number_cell_font}
                                                                                            items={this.state.selected_tab.grid_data.filter((cell) => {
                                                                                                return cell.group === group.id;
                                                                                            })}
                                                                                            typeOfBlock={cell.typeOfBlock}
                                                                                            static={true}
                                                                                            external={true}
                                                                                            transparent_cells={this.state.transparent_cells}
                                                                                            report_background_color={this.state.background_color}
                                                                                            base_font={this.state.report_text_cell_font}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        cell.typeOfBlock === "table" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                        <AdvancedReportCellTable
                                                                                            source={cell}
                                                                                            items={this.state.selected_tab.grid_data.filter((cell) => {
                                                                                                return cell.group === group.id;
                                                                                            })}
                                                                                            typeOfBlock={cell.typeOfBlock}
                                                                                            static={true}
                                                                                            external={true}
                                                                                            transparent_cells={this.state.transparent_cells}
                                                                                            report_background_color={this.state.background_color}
                                                                                            base_font={this.state.report_text_cell_font}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        (Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                        <div style={{ cursor: "default" }} className="text text-center w-full h-full overflow-hidden flex justify-center items-center">
                                                                                            <div className="rounded-md bg-orange-100 p-4">
                                                                                                <div className="flex">
                                                                                                    <div className="">
                                                                                                        <div className="text-xs text-orange-700">
                                                                                                            <ul role="list" className="list-disc text-xs space-y-1 pl-5">
                                                                                                                {this.getUniqueErrors(cell.errors).map((error, index) => (
                                                                                                                    <li key={index}>{error}</li>
                                                                                                                ))}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div onClick={() => {
                                                                                                if (!cell.loading) {
                                                                                                    cell.data = null;
                                                                                                    cell.loading = true;
                                                                                                    this.cells.update(cell)
                                                                                                }
                                                                                            }} style={{ top: "22px", right: "22px" }}
                                                                                                className={(cell.loading ? " cursor-not-allowed " : "cursor-pointer ") + " transition-all duration-200 absolute px-2 leading-none flex hover:bg-purple-200 items-center justify-center mr-1 h-10 bg-purple-100 text-purple-500 rounded"}>
                                                                                                <RefreshIcon className={"stroke-current w-4"} icon={"align-justify"} />
                                                                                                <div className="ml-2 whitespace-no-wrap text-sm font-medium">Refresh data</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    })
                                }

                            </div>

                            {/* GRID MOBILE*/}
                            <div id={"mobile_grid_public"} className="w-full block md:hidden relative">

                                {/* TITLE AND DESCRIPTION */}
                                {
                                    (this.state.title || this.state.description) &&
                                    <div style={{ maxWidth: "450px" }} className="pb-5 mt-10 mx-auto">
                                        <h3 className="text-base font-semibold leading-6">{this.state.title && this.state.title !== "" && this.state.title}</h3>
                                        <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                            {this.state.description && this.state.description !== "" && this.state.description}
                                        </p>
                                    </div>
                                }

                                {
                                    Array.isArray(this.state.selected_tab.groups) &&
                                    this.state.selected_tab.groups.map((group, group_index) => {
                                        return (
                                            <Fragment>

                                                {/* OLD GRID */}
                                                {
                                                    Array.isArray(this.state.selected_tab.grid_data) &&
                                                    this.renders.mobileCellOrder(this.state.selected_tab.grid_data.filter((cell) => {
                                                        return !cell.abstract
                                                    }).filter((cell) => {
                                                        return cell.group === group.id && "x_mobile" in cell;
                                                    })).length < 1 &&
                                                    <div
                                                        //style={group.enable_background && group.enable_background_full ? { ...{ backgroundColor: "#" + group.background_color } } : {}}
                                                        style={{
                                                            ...(group.enable_background && group.enable_background_full && !group.enable_image && { backgroundColor: "#" + group.background_color }),
                                                            ...(group.enable_background_full && group.enable_image && { backgroundImage: "url(" + group.pageImage + ")", backgroundSize: "cover" }),
                                                        }}
                                                        className={(group.enable_margin ? " mt-4 mb-4 " : "") + " w-full"}
                                                    >
                                                        <div
                                                            style={{
                                                                ...({ maxWidth: "450px" }),
                                                                ...(group.enable_background && !group.enable_background_full && !group.enable_image && { backgroundColor: "#" + group.background_color }),
                                                                ...(!group.enable_background_full && group.enable_image && { backgroundImage: "url(" + group.pageImage + ")", backgroundSize: "cover" }),
                                                            }}
                                                            //style={group.enable_background ? { backgroundColor: "#" + group.background_color, maxWidth: "450px" } : { maxWidth: "450px" }}
                                                            className={(group.enable_round_corners ? " rounded-lg overflow-hidden " : "") + " w-full mx-auto"}
                                                        >
                                                            {
                                                                this.state.page_sections &&
                                                                <div
                                                                    key={group.name}
                                                                    id={group.id + 'mobile'}
                                                                    style={{ top: '70px' }}
                                                                    className={`rounded-md inline-flex shadow px-2 py-1 bg-white sticky z-40 text-xs font-medium transition-opacity duration-300 ease-in-out ${this.state.is_scrolling ? 'opacity-100' : 'opacity-0'}`}
                                                                >
                                                                    {group.name ? group.name : group_index + 1}
                                                                </div>
                                                            }
                                                            {
                                                                Array.isArray(this.state.selected_tab.grid_data) &&
                                                                this.renders.mobileCellOrder(this.state.selected_tab.grid_data.filter((cell) => {
                                                                    return !cell.abstract
                                                                }).filter((cell) => {
                                                                    return cell.group === group.id;
                                                                })).map((cell, index) => {
                                                                    let last = index + 1 == this.renders.mobileCellOrder(this.state.selected_tab.grid_data.filter((cell) => {
                                                                        return !cell.abstract
                                                                    }).filter((cell) => {
                                                                        return cell.group === group.id;
                                                                    })).length;
                                                                    return (
                                                                        <div
                                                                            style={{ height: cell.h * 100 + "px" }}
                                                                            className={(!(cell.settings && cell.settings.disable_margin_top) ? (index === 0 ? " pt-4 " : " pt-2 ") : "") + (!(cell.settings && cell.settings.disable_margin_bottom) ? (last ? " pb-4 " : " pb-2 ") : "") + (!(cell.settings && cell.settings.disable_margin_right) ? " pr-4 " : "") + (!(cell.settings && cell.settings.disable_margin_left) ? " pl-4 " : "")}
                                                                        >
                                                                            <div
                                                                                mobile={true}
                                                                                id={cell.i + "_preview_mobile"}
                                                                                style={{
                                                                                    ...(cell.settings && cell.settings.background_color && !cell.settings.transparent && { backgroundColor: "#" + cell.settings.background_color }),
                                                                                    ...(cell.settings && !cell.settings.background_color && !cell.settings.transparent && { backgroundColor: "#FFFFFF" })
                                                                                    //backgroundColor: cell.settings && cell.settings.background_color ? "#" + cell.settings.background_color : "#FFFFFF" 
                                                                                }}
                                                                                className={(!(cell.settings && (cell.settings.disable_margin_top || cell.settings.disable_margin_left)) ? " rounded-tl-md " : "") + (!(cell.settings && (cell.settings.disable_margin_top || cell.settings.disable_margin_right)) ? " rounded-tr-md " : "") + (!(cell.settings && (cell.settings.disable_margin_bottom || cell.settings.disable_margin_right)) ? " rounded-br-md " : "") + (!(cell.settings && (cell.settings.disable_margin_bottom || cell.settings.disable_margin_left)) ? " rounded-bl-md " : "") + (cell.settings && cell.settings.shadow ? " shadow-xl " : "") + " w-full h-full relative overflow-hidden"}
                                                                            >
                                                                                {
                                                                                    cell.typeOfBlock === "img" &&
                                                                                    <AdvancedReportCellCreative
                                                                                        header={cell.header}
                                                                                        page={group}
                                                                                        data={cell.data}
                                                                                        format={cell.format}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        source={cell}
                                                                                        transparent_cells={this.state.transparent_cells}
                                                                                        report_background_color={this.state.background_color}
                                                                                        base_font={this.state.report_text_cell_font}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "preview" &&
                                                                                    <AdvancedReportCellPreview
                                                                                        header={cell.header}
                                                                                        source={cell}
                                                                                        page={group}
                                                                                        format={cell.format}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        transparent_cells={this.state.transparent_cells}
                                                                                        report_background_color={this.state.background_color}
                                                                                        base_font={this.state.report_text_cell_font}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "chart" &&
                                                                                    <AdvancedReportCellChart
                                                                                        source={cell}
                                                                                        page={group}
                                                                                        data={cell.data}
                                                                                        metric={cell.metric}
                                                                                        header={cell.header}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        transparent_cells={this.state.transparent_cells}
                                                                                        report_background_color={this.state.background_color}
                                                                                        base_font={this.state.report_text_cell_font}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "input" &&
                                                                                    <AdvancedReportCellText
                                                                                        header={cell.header}
                                                                                        data={cell.data}
                                                                                        page={group}
                                                                                        format={cell.format}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        source={cell}
                                                                                        transparent_cells={this.state.transparent_cells}
                                                                                        report_background_color={this.state.background_color}
                                                                                        base_font={this.state.report_text_cell_font}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "number" &&
                                                                                    <AdvancedReportCellNumber
                                                                                        source={cell}
                                                                                        page={group}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        transparent_cells={this.state.transparent_cells}
                                                                                        report_background_color={this.state.background_color}
                                                                                        base_font={this.state.report_text_cell_font}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "table" &&
                                                                                    <AdvancedReportCellTable
                                                                                        source={cell}
                                                                                        page={group}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        transparent_cells={this.state.transparent_cells}
                                                                                        report_background_color={this.state.background_color}
                                                                                        base_font={this.state.report_text_cell_font}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                {/* NEW GRID*/}
                                                {
                                                    Array.isArray(this.state.selected_tab.grid_data) &&
                                                    this.renders.mobileCellOrder(this.state.selected_tab.grid_data.filter((cell) => {
                                                        return !cell.abstract
                                                    }).filter((cell) => {
                                                        return cell.group === group.id && "x_mobile" in cell;
                                                    })).length > 0 &&
                                                    <div style={group.enable_background && group.enable_background_full ? { ...{ backgroundColor: "#" + group.background_color } } : {}} className={(!group.enable_background_full ? " px-4 " : "") + (group.enable_margin ? " mt-4 mb-4 " : "") + " w-full"}>
                                                        <div
                                                            className={(group.enable_round_corners ? " rounded-lg overflow-hidden " : "") + " w-full mx-auto h-full"}
                                                            style={group.enable_background ? { backgroundColor: "#" + group.background_color, maxWidth: "450px" } : { maxWidth: "450px" }}
                                                        >
                                                            <div
                                                                style={group.enable_background ? { ...{ backgroundColor: "#" + group.background_color }, ...{ height: (100 * +group.mobile_rows) + "px" }, ...{ gridTemplateRows: this.renders.rowHeightMobile(group) } } : { height: (100 * +group.mobile_rows) + "px", gridTemplateRows: this.renders.rowHeightMobile(group) }}
                                                                className={(group.mobile_rows ? ("grid-rows-" + group.mobile_rows) : "row-auto") + (group.enable_round_corners ? " rounded-md " : "") + " w-full overflow-hidden grid-cols-3 grid"}
                                                            >
                                                                {
                                                                    !this.state.change_tab &&
                                                                    Array.isArray(this.state.selected_tab.grid_data) &&
                                                                    this.state.selected_tab.grid_data.filter((cell) => {
                                                                        return !cell.abstract
                                                                    }).filter((cell) => {
                                                                        return cell.group === group.id;
                                                                    }).map((cell) => {
                                                                        return (
                                                                            <div
                                                                                style={this.renders.translateCellCoordinatesToGridMobile(cell)}
                                                                                className={(!(cell.settings && cell.settings.disable_margin_top) ? this.renders.translateCellPaddingMobile('top', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_bottom) ? this.renders.translateCellPaddingMobile('bottom', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_right) ? this.renders.translateCellPaddingMobile('right', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_left) ? this.renders.translateCellPaddingMobile('left', cell, group) : "")}
                                                                            >
                                                                                <div
                                                                                    mobile={true}
                                                                                    id={cell.i + "_preview_mobile"}
                                                                                    style={{ backgroundColor: cell.settings && cell.settings.background_color ? "#" + cell.settings.background_color : "#FFFFFF" }}
                                                                                    className={(!(cell.settings && (cell.settings.disable_margin_top || cell.settings.disable_margin_left)) ? " rounded-tl-md " : "") + (!(cell.settings && (cell.settings.disable_margin_top || cell.settings.disable_margin_right)) ? " rounded-tr-md " : "") + (!(cell.settings && (cell.settings.disable_margin_bottom || cell.settings.disable_margin_right)) ? " rounded-br-md " : "") + (!(cell.settings && (cell.settings.disable_margin_bottom || cell.settings.disable_margin_left)) ? " rounded-bl-md " : "") + (cell.settings && cell.settings.shadow ? " shadow-xl " : "") + " w-full h-full relative overflow-hidden"}
                                                                                >
                                                                                    <div className="absolute w-full h-full">
                                                                                        {
                                                                                            cell.typeOfBlock === "img" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                            <AdvancedReportCellCreative
                                                                                                header={cell.header}
                                                                                                data={cell.data}
                                                                                                format={cell.format}
                                                                                                typeOfBlock={cell.typeOfBlock}
                                                                                                static={true}
                                                                                                external={true}
                                                                                                source={cell}
                                                                                                transparent_cells={this.state.transparent_cells}
                                                                                                report_background_color={this.state.background_color}
                                                                                                base_font={this.state.report_text_cell_font}
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            cell.typeOfBlock === "preview" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                            <AdvancedReportCellPreview
                                                                                                header={cell.header}
                                                                                                source={cell}
                                                                                                format={cell.format}
                                                                                                typeOfBlock={cell.typeOfBlock}
                                                                                                static={true}
                                                                                                external={true}
                                                                                                transparent_cells={this.state.transparent_cells}
                                                                                                report_background_color={this.state.background_color}
                                                                                                base_font={this.state.report_text_cell_font}
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            cell.typeOfBlock === "chart" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                            <AdvancedReportCellChart
                                                                                                source={cell}
                                                                                                data={cell.data}
                                                                                                items={this.state.selected_tab.grid_data.filter((cell) => {
                                                                                                    return cell.group === group.id;
                                                                                                })}
                                                                                                metric={cell.metric}
                                                                                                header={cell.header}
                                                                                                typeOfBlock={cell.typeOfBlock}
                                                                                                static={true}
                                                                                                external={true}
                                                                                                transparent_cells={this.state.transparent_cells}
                                                                                                report_background_color={this.state.background_color}
                                                                                                base_font={this.state.report_text_cell_font}
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            cell.typeOfBlock === "input" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                            <AdvancedReportCellText
                                                                                                header={cell.header}
                                                                                                data={cell.data}
                                                                                                format={cell.format}
                                                                                                typeOfBlock={cell.typeOfBlock}
                                                                                                static={true}
                                                                                                external={true}
                                                                                                onInput={() => { }}
                                                                                                source={cell}
                                                                                                transparent_cells={this.state.transparent_cells}
                                                                                                report_background_color={this.state.background_color}
                                                                                                base_font={this.state.report_text_cell_font}
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            cell.typeOfBlock === "number" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                            <AdvancedReportCellNumber
                                                                                                source={cell}
                                                                                                items={this.state.selected_tab.grid_data.filter((cell) => {
                                                                                                    return cell.group === group.id;
                                                                                                })}
                                                                                                typeOfBlock={cell.typeOfBlock}
                                                                                                static={true}
                                                                                                external={true}
                                                                                                transparent_cells={this.state.transparent_cells}
                                                                                                report_background_color={this.state.background_color}
                                                                                                base_font={this.state.report_text_cell_font}
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            cell.typeOfBlock === "table" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                            <AdvancedReportCellTable
                                                                                                source={cell}
                                                                                                items={this.state.selected_tab.grid_data.filter((cell) => {
                                                                                                    return cell.group === group.id;
                                                                                                })}
                                                                                                typeOfBlock={cell.typeOfBlock}
                                                                                                static={true}
                                                                                                external={true}
                                                                                                transparent_cells={this.state.transparent_cells}
                                                                                                report_background_color={this.state.background_color}
                                                                                                base_font={this.state.report_text_cell_font}
                                                                                            />
                                                                                        }
                                                                                        {
                                                                                            (Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                            <div style={{ cursor: "default" }} className="text text-center w-full h-full overflow-hidden flex justify-center items-center">
                                                                                                <div className="rounded-md bg-orange-100 p-4">
                                                                                                    <div className="flex">
                                                                                                        <div className="">
                                                                                                            <div className="text-xs text-orange-700">
                                                                                                                <ul role="list" className="list-disc text-xs space-y-1 pl-5">
                                                                                                                    {
                                                                                                                        cell.errors.map((item) => {
                                                                                                                            if (typeof item === "string") {
                                                                                                                                return (
                                                                                                                                    <li>{item}</li>
                                                                                                                                )
                                                                                                                            } else {
                                                                                                                                return (
                                                                                                                                    <li><span className="text-xs font-medium">{item.channel}, {item.level}, {item.id}: </span>{item.message}</li>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }

                            </div>

                        </div>

                    </div>
                }
                {
                    this.state.footerSettings &&
                    this.state.footerSettings.enabled &&
                    <footer className="bg-white"
                        style={this.state.footerSettings && this.state.footerSettings.color_footer ? { backgroundColor: "#" + this.state.footerSettings.color_footer } : {}}
                    >
                        <div className="mx-auto px-6 py-6 md:py-14">
                            <nav className="-mb-6 justify-center items-center flex flex-col columns-2 sm:flex sm:flex-row sm:justify-center sm:space-x-12" aria-label="Footer">
                                {
                                    this.state.footerSettings.link_1 && this.state.footerSettings.link_1.name !== "" &&
                                    <div className="cursor-pointer mb-2 sm:mb-0">
                                        <a href={this.state.footerSettings.link_1.url}
                                            target="_blank"
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900 hover:text-opacity-50"
                                            style={this.state.footerSettings && this.state.footerSettings.color_footer_font ? {
                                                color: "#" + this.state.footerSettings.color_footer_font,
                                            } : {}}
                                        >
                                            {this.state.footerSettings.link_1.name}
                                        </a>
                                    </div>
                                }
                                {
                                    this.state.footerSettings.link_2 && this.state.footerSettings.link_2.name !== "" &&
                                    <div className="cursor-pointer mb-2 sm:mb-0">
                                        <a href={this.state.footerSettings.link_2.url}
                                            target="_blank"
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900 hover:text-opacity-50"
                                            style={this.state.footerSettings && this.state.footerSettings.color_footer_font ? {
                                                color: "#" + this.state.footerSettings.color_footer_font,
                                            } : {}}
                                        >
                                            {this.state.footerSettings.link_2.name}
                                        </a>
                                    </div>
                                }
                                {
                                    this.state.footerSettings.link_3 && this.state.footerSettings.link_3.name !== "" &&
                                    <div className="cursor-pointer mb-2 sm:mb-0">
                                        <a href={this.state.footerSettings.link_3.url}
                                            target="_blank"
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900 hover:text-opacity-50"
                                            style={this.state.footerSettings && this.state.footerSettings.color_footer_font ? {
                                                color: "#" + this.state.footerSettings.color_footer_font,
                                            } : {}}
                                        >
                                            {this.state.footerSettings.link_3.name}
                                        </a>
                                    </div>
                                }
                                {
                                    this.state.footerSettings.link_4 && this.state.footerSettings.link_4.name !== "" &&
                                    <div className="cursor-pointer mb-2 sm:mb-0">
                                        <a href={this.state.footerSettings.link_4.url}
                                            target="_blank"
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900 hover:text-opacity-50"
                                            style={this.state.footerSettings && this.state.footerSettings.color_footer_font ? {
                                                color: "#" + this.state.footerSettings.color_footer_font,
                                            } : {}}
                                        >
                                            {this.state.footerSettings.link_4.name}
                                        </a>
                                    </div>
                                }
                                {
                                    this.state.footerSettings.link_5 && this.state.footerSettings.link_5.name !== "" &&
                                    <div className="cursor-pointer mb-2 sm:mb-0">
                                        <a href={this.state.footerSettings.link_5.url}
                                            target="_blank"
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900 hover:text-opacity-50"
                                            style={this.state.footerSettings && this.state.footerSettings.color_footer_font ? {
                                                color: "#" + this.state.footerSettings.color_footer_font,
                                            } : {}}
                                        >
                                            {this.state.footerSettings.link_5.name}
                                        </a>
                                    </div>
                                }
                            </nav>
                            {
                                this.state.footerSettings && this.state.footerSettings.footer_text !== "" &&
                                <p className="mt-14 text-center text-xs leading-5 text-gray-500"
                                    style={this.state.footerSettings && this.state.footerSettings.color_footer_font ? { color: "#" + this.state.footerSettings.color_footer_font } : {}}
                                >
                                    {
                                        this.state.footerSettings && this.state.footerSettings.copyright &&
                                        <span className='mr-1'>&copy; {this.functions.renderYear()}</span>
                                    }
                                    {this.state.footerSettings && this.state.footerSettings.footer_text !== "" ? this.state.footerSettings.footer_text : ""}
                                </p>
                            }
                        </div>
                    </footer>
                }
                <SuccessModal
                    open={this.state.pdf_success ? true : false}
                    title={"Success"}
                    text={"Check your download folder"}
                    button={"Ok"}
                    onClose={() => {
                        this.setState({
                            pdf_success: false
                        })
                    }}
                />


            </div >
        )
    }
}

export default AdvancedReportPublic;
