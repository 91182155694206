import React, { Component } from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon, CameraIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ExclamationIcon, GiftIcon, SwitchVerticalIcon, UploadIcon, VideoCameraIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import languages from '../assets/json/languages.json';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { json } from 'body-parser';
const fuzzysort = require('fuzzysort');

class CreateOrderCreativeKeywords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            group: {
                name: "",
                keywords: [],
            },
            client: {},
            language: {
                "name": "Swedish",
                "google_id": 1015
            },
            languages: languages,
            phrase: false,
            exact: false,
            broad: true,
            customMatch: false,
            sort_suggestions: 'avg_monthly_searches',
            direction_suggestions: 'descending',
            sort_list: 'own_keyword',
            direction_list: 'descending',
            search: "",
            keywords: [],
            suggestions: [],
            location_options: []
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            groups: this.props.groups,
            client: this.props.client
        });
        //IF ONLY ONE GROUP SELETED
        if (Array.isArray(this.state.groups) && this.state.groups.length === 1) {
            await this.promisedSetState({
                group: this.state.groups[0] ? this.state.groups[0] : {}
            });
        } else if (this.state.groups) {
            if (!this.state.groups.name && !this.state.groups.keywords) {
            } else {
                await this.promisedSetState({
                    group: this.state.groups ? this.state.groups : {}
                });
            }
        }

    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    functions = {
        animate: async (id, property, style = false) => {
            return new Promise(async (resolve) => {
                try {


                    document.getElementById(id).classList.remove(...property.remove);
                    document.getElementById(id).classList.add(...property.add);
                    await this.sleep(5000);
                    document.getElementById(id).classList.remove(...property.add);
                    document.getElementById(id).classList.add(...property.remove);

                } catch (error) {

                }
                resolve();
            });
        },
        save: async () => {
            if (this.props.onSave) {
                this.props.onSave(this.state.group);
            }
        },
        listKeywordIdeas: async () => {
            this.props.onLoadingKeywords(true);
            try {
                let options = {
                    language: this.state.language.google_id,
                    includeAdultKeywords: false,
                    regions: [],
                    location: this.state.location
                };
                if (this.state.url && this.state.url !== "") {
                    options.url = this.state.url;
                }
                if (Array.isArray(this.state.suggestions) && this.state.suggestions.filter((item) => { return item.own_keyword }).length > 0) {
                    options.keywords = this.state.suggestions.filter((item) => { return item.own_keyword }).map((item) => { return item.keyword });
                }
                let response = await this.calls.listKeywordIdeas(options);
                if (!Array.isArray(response.data)) {
                    response.data = [];
                }
                response.data = response.data.map((item) => {
                    item.id = Math.floor((Math.random() * 9999999999) + 1);
                    return item;
                });

                //CHECK IF EXISTING KEYWORDS IS IN LIST
                let your_keywords = [];
                this.state.suggestions.filter((item) => { return item.own_keyword }).map((inner_item) => {
                    let exist = null;
                    response.data.map((item) => {
                        if (inner_item.own_keyword && typeof item.keyword === "string" && typeof inner_item.keyword === "string" && item.keyword.toLowerCase() == inner_item.keyword.toLowerCase()) {
                            exist = item;
                        }
                    });
                    if (exist) {
                        exist.own_keyword = true;
                        exist.id = inner_item.id;
                        exist.exact = inner_item.exact;
                        exist.broad = inner_item.broad;
                        exist.phrase = inner_item.phrase;
                        your_keywords.push(exist);
                    } else {
                        your_keywords.push(inner_item);
                    }
                });

                //REMOVE DUPLICATES
                response.data = response.data.filter((item) => {
                    let exist = false;
                    this.state.suggestions.filter((item) => { return item.own_keyword }).map((inner_item) => {
                        if (inner_item.own_keyword && typeof item.keyword === "string" && typeof inner_item.keyword === "string" && item.keyword.toLowerCase() == inner_item.keyword.toLowerCase()) {
                            exist = true;
                        }
                    });
                    return !exist;
                });

                this.promisedSetState({
                    suggestions: response.data.concat(your_keywords)
                });

                if (Array.isArray(this.state.group.keywords)) {
                    options.keywords = this.state.group.keywords.map((item, index) => { return item.keyword });//.filter((item) => { return item });
                    response = await this.calls.listKeywordHistoric(options);
                    if (Array.isArray(response.data)) {
                        this.state.group.keywords = JSON.parse(JSON.stringify(this.state.group.keywords)).map((item) => {
                            if (response.data.filter((inner_item) => { return inner_item.keyword == item.keyword }).length > 0) {
                                let buff = response.data.filter((inner_item) => { return inner_item.keyword == item.keyword })[0];
                                item.monthly_search_volumes = buff.monthly_search_volumes;
                                item.competition = buff.competition;
                                item.avg_monthly_searches = buff.avg_monthly_searches;
                                item.low_bid = buff.low_bid;
                                item.high_bid = buff.high_bid;
                            }
                            return item;
                        })
                        this.promisedSetState({
                            group: this.state.group
                        });
                    }
                }


            } catch (error) { }
            this.props.onLoadingKeywords(false);
        },
        searchLocations: async () => {
            try {
                await this.promisedSetState({ loading_locations: true });
                let response = await this.calls.getLocations();
                this.promisedSetState({
                    loading_locations: false,
                    location_options: response.data.map(((item) => {
                        if (item.geoTargetConstant.canonicalName) {
                            item.name = item.geoTargetConstant.canonicalName;
                        } else {
                            item.name = item.geoTargetConstant.name;
                        }
                        item.key = item.geoTargetConstant.resourceName;
                        return item;
                    }))
                });
            } catch (error) {
                this.promisedSetState({
                    location_options: []
                })
            }
        },
        onSelectFile: (event, suggestions) => {
            let self = this;
            event.preventDefault();
            try {
                var files = event.target.files;
                var f = files[0];
                var reader = new FileReader();
                reader.onload = function (e) {
                    const text = e.target.result;
                    const data = csvToArray(text);
                    if (Array.isArray(data)) {
                        if (!Array.isArray(self.state.suggestions)) {
                            self.state.suggestions = [];
                        }
                        data.map((item) => {
                            for (let key in item) {
                                item[key.toLocaleLowerCase().trim()] = item[key];
                            }
                            if (item.keyword && item.keyword !== "") {
                                if (suggestions) {
                                    self.state.suggestions.unshift({
                                        own_keyword: true,
                                        id: Math.floor((Math.random() * 9999999999) + 1),
                                        keyword: item.keyword ? item.keyword : "",
                                        broad: typeof item.type === "string" && item.type.toLowerCase().indexOf("broad") !== -1,
                                        phrase: typeof item.type === "string" && item.type.toLowerCase().indexOf("phrase") !== -1,
                                        exact: typeof item.type === "string" && item.type.toLowerCase().indexOf("exact") !== -1,
                                    });
                                } else {
                                    if (!self.state.group.keywords) {
                                        self.state.group.keywords = [];
                                    }
                                    if (!self.renders.exist(item.keyword)) {
                                        self.state.group.keywords.unshift({
                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                            keyword: item.keyword ? item.keyword : "",
                                            broad: typeof item.type === "string" && item.type.toLowerCase().indexOf("broad") !== -1,
                                            phrase: typeof item.type === "string" && item.type.toLowerCase().indexOf("phrase") !== -1,
                                            exact: typeof item.type === "string" && item.type.toLowerCase().indexOf("exact") !== -1
                                        });
                                    }
                                }
                            }
                        });
                        self.promisedSetState({
                            group: self.state.group,
                            suggestions: self.state.suggestions
                        });
                    }
                };
                reader.readAsText(f);
                function csvToArray(str, delimiter = ",") {
                    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
                    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
                    const arr = rows.map(function (row) {
                        const values = row.split(delimiter);
                        const el = headers.reduce(function (object, header, index) {
                            object[header] = values[index];
                            return object;
                        }, {});
                        return el;
                    });
                    return arr;
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    calls = {
        listKeywordIdeas: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/listKeywordIdeas?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listKeywordHistoric: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/listKeywordHistoric?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getLocations: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listLocations?client=" + this.state.client.id + "&search=" + this.state.search_locations;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        sort: (list, sort, order) => {
            if (!Array.isArray(list)) {
                list = [];
            }
            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : 0) : (x[sort] ? x[sort] : 0);
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : 0) : (y[sort] ? y[sort] : 0);
                    if (order == 'descending') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }
            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : "") : (x[sort] ? x[sort] : "");
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : "") : (y[sort] ? y[sort] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (order == 'descending') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }
            if (sort === "keyword" || sort === "competition") {
                return sortCharacter(list);
            } else {
                return sortNumber(list);
            }
        },
        filter: (list) => {
            let options = list;

            //CHECK ITS NOT ALREADY SELECTED
            if (Array.isArray(this.state.group.keywords)) {
                options = options.filter((item) => {
                    if (!item.own_keyword) {
                        return this.state.group.keywords.filter((inner_item) => { return inner_item.keyword === item.keyword }).length < 1
                    } else {
                        return true
                    }
                });
            }

            if (this.state.search !== "") {
                let result = fuzzysort.go(this.state.search, list.map((item) => { return item.keyword }));
                options = [];
                let old_options = JSON.parse(JSON.stringify(list));
                result.filter((item, index) => {
                    return index < 20
                }).map((item) => {
                    for (let i = 0; i < old_options.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_options[i].keyword.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_options[i].html = html_string;
                            options.push(old_options[i]);
                            old_options.splice(i, 1);
                            break;
                        }
                    }
                });
            }
            return options;
        },
        graph: () => {
            let labels = {};
            let months = { "JANUARY": "jan", "FEBRUARY": "feb", "MARCH": "mar", "APRIL": "apr", "MAY": "may", "JUNE": "jun", "JULY": "jul", "AUGUST": "aug", "SEPTEMBER": "sep", "OCTOBER": "oct", "NOVEMBER": "nov", "DECEMBER": "dec" };
            if (Array.isArray(this.state.group.keywords)) {
                this.state.group.keywords.map((item) => {
                    if (item.monthly_search_volumes) {
                        item.monthly_search_volumes.map((item) => {
                            if (!labels[months[item.month] + "-" + item.year.replace("20", "")]) {
                                labels[months[item.month] + "-" + item.year.replace("20", "")] = 0;
                            }
                            labels[months[item.month] + "-" + item.year.replace("20", "")] += +item.monthlySearches;
                        })
                    }
                })
            }
            try {
                return {
                    labels: Object.keys(labels),
                    datasets: [
                        {
                            label: 'Searches',
                            data: Object.keys(labels).map((item) => { return labels[item] }),
                            borderColor: 'rgb(69, 63, 241)',
                            backgroundColor: 'rgba(69, 63, 241, 0.7)'
                        },
                    ]
                };
            } catch (error) {
                return {
                    labels: [],
                    datasets: []
                }
            }
        },
        exist: (item) => {
            return Array.isArray(this.state.group.keywords) && this.state.group.keywords.filter((inner_item) => { return typeof item.keyword === "string" && typeof inner_item.keyword === "string" && item.keyword.toLowerCase() == inner_item.keyword.toLowerCase() }).length > 0;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="flex flex-1 flex-col h-full">

                {/* TOP SECTION */}
                <div className="grid grid-cols-12 col-gap-4 mb-4 pb-4 border-b">
                    <div className="col-span-3">
                        <InputTailwind
                            error={this.state.group.name === "" ? true : false}
                            label={"List name"}
                            value={this.state.group.name}
                            onChange={async (value) => {
                                this.state.group.name = value;
                                this.setState({
                                    group: this.state.group
                                })
                            }}
                        />
                    </div>
                    <div className="col-span-3">
                        <DropdownTailwind
                            label={"List type"}
                            selected={('negative' in this.state.group) ? this.state.group.negative : { id: 1, name: "Positive", value: false }}
                            options={[{ id: 1, name: "Positive", value: false }, { id: 2, name: "Negative", value: true }]}
                            onChange={async (value) => {
                                this.state.group.negative = value;
                                this.setState({
                                    group: this.state.group
                                })
                            }}
                        />
                    </div>
                    <div className="col-span-3">
                        <DropdownTailwind
                            label={"Language"}
                            searchInput={true}
                            selected={this.state.language}
                            options={this.state.languages}
                            disabled={this.state.loading_keywords}
                            onChange={async (value) => {
                                this.setState({
                                    language: value
                                })
                            }}
                        />
                    </div>
                    <div className="col-span-3">
                        <DropdownTailwind
                            rightLabel={this.state.location}
                            onRightLabelClick={() => {
                                this.setState({
                                    location: null
                                })
                            }}
                            rightLabelText={"Remove"}
                            rightLabelColor={"text-red-500"}
                            label={"Location (optional)"}
                            searchInput={true}
                            selected={this.state.location ? this.state.location : { name: "Select location" }}
                            options={Array.isArray(this.state.location_options) ? this.state.location_options : []}
                            loadingSearch={this.state.loading_locations}
                            disabled={this.state.loading_keywords}
                            onChange={async (value) => {
                                this.setState({
                                    location: value
                                })
                            }}
                            onSearch={async (value) => {
                                await this.promisedSetState({ search_locations: value });
                                this.functions.searchLocations();
                            }}
                        />
                    </div>
                </div>

                <div className="flex flex-row">

                    {/* SUGGESTIONS */}
                    <div className="flex-1">
                        <div className="text-md font-medium mb-4 flex items-center">
                            <div className="flex flex-1">Suggestions</div>
                            <div className="flex relative hover:text-purple-600 text-sm font-medium cursor-pointer text-purple-500 flex-row items-center">
                                <UploadIcon className="h-5 w-5 mr-2" />
                                Upload own suggestions (csv)
                                <input
                                    onChange={(e) => this.functions.onSelectFile(e, true)}
                                    type="file"
                                    accept={".csv"}
                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-4 mb-4">
                            <div className="col-span-12">
                                <InputTailwind
                                    label={"Url (optional)"}
                                    value={this.state.url}
                                    onChange={async (value) => {
                                        this.setState({
                                            url: value
                                        })
                                    }}
                                    disabled={this.state.loading_keywords}
                                    onKeyDown={(value) => {
                                        if (value === 13) {
                                            this.functions.listKeywordIdeas();
                                        }
                                    }}
                                />
                            </div>

                            <div className="col-span-12">
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-1">
                                        <div className="w-full">
                                            <>
                                                <div className='flex flex-row'>
                                                    <div className='mr-1'>
                                                        <div className='bg-google-500 h-4 w-4 rounded-full flex justify-center items-center'>
                                                            <img className="w-2"
                                                                src={require('../assets/images/google_icon.svg')} />
                                                        </div>
                                                    </div>
                                                    <label class="block text-xs font-medium text-gray-700">Keywords (You can separate each keyword by commas or enter one per line.)</label>
                                                </div>
                                                <div className='mt-1 relative'>
                                                    <textarea
                                                        rows={2}
                                                        className={" bg-custom-input rounded-md block w-full border-1.5 pr-10 px-2 py-2 focus:outline-none sm:text-sm"}
                                                        value={this.state.keyword}
                                                        onChange={async (event) => {
                                                            if (event.target.value !== "\n") {
                                                                await this.promisedSetState({
                                                                    keyword: event.target.value
                                                                })
                                                            }
                                                            // if (!this.state.ai_loading) { await this.promisedSetState({ searchOpenAi: event.target.value }) }

                                                        }}
                                                        onKeyDown={async (event) => {
                                                            try {
                                                                if (event.keyCode === 13) {
                                                                    if (!event.shiftKey) {
                                                                        this.state.keyword = this.state.keyword.split("\n");
                                                                        this.state.keyword = this.state.keyword.join().split(",");
                                                                        this.state.keyword.filter((item) => { return item !== "" }).map((item) => {
                                                                            if (this.state.customMatch) {
                                                                                let exact_reg = /\[(.*?)\]/s;
                                                                                let phrase_reg = /\"(.*?)\"/s;
                                                                                let exact = item.match(exact_reg);
                                                                                let phrase = item.match(phrase_reg);
                                                                                let keyword = {
                                                                                    own_keyword: true,
                                                                                    id: Math.floor((Math.random() * 9999999999) + 1),
                                                                                    //keyword: item.trim(),
                                                                                    broad: false,
                                                                                    phrase: false,
                                                                                    exact: false
                                                                                }
                                                                                if (exact) {
                                                                                    keyword.keyword = exact[1].trim();
                                                                                    keyword.exact = true;
                                                                                } else if (phrase) {
                                                                                    keyword.keyword = phrase[1].trim();
                                                                                    keyword.phrase = true;
                                                                                } else {
                                                                                    keyword.keyword = item.trim();
                                                                                    keyword.broad = true;
                                                                                }
                                                                                this.state.suggestions.unshift(keyword);
                                                                            } else {
                                                                                this.state.suggestions.unshift({
                                                                                    own_keyword: true,
                                                                                    id: Math.floor((Math.random() * 9999999999) + 1),
                                                                                    keyword: item.trim(),
                                                                                    broad: this.state.broad,
                                                                                    phrase: this.state.phrase,
                                                                                    exact: this.state.exact
                                                                                });
                                                                            }
                                                                        });
                                                                        await this.promisedSetState({
                                                                            suggestions: this.state.suggestions,
                                                                            keyword: ""
                                                                        });
                                                                    }
                                                                }
                                                            } catch (e) { }
                                                        }}
                                                    />
                                                </div>
                                            </>
                                            {/*
                                            <InputTailwind
                                                label={"Add keyword (click enter to add)"}
                                                value={this.state.keyword}
                                                onChange={async (value) => {
                                                    this.setState({
                                                        keyword: value
                                                    })
                                                }}
                                                onKeyDown={async (value) => {
                                                    if (value === 13) {
                                                        this.state.suggestions.unshift({
                                                            own_keyword: true,
                                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                                            keyword: this.state.keyword,
                                                            broad: this.state.broad,
                                                            phrase: this.state.phrase,
                                                            exact: this.state.exact
                                                        });
                                                        await this.promisedSetState({
                                                            suggestions: this.state.suggestions,
                                                            keyword: ""
                                                        });
                                                    }
                                                }}
                                            />
                                            */}
                                        </div>
                                    </div>
                                    <div className="pl-4">
                                        <div className="text-xs font-medium mb-3">
                                            Exact
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.exact}
                                            onSwitch={async () => {
                                                if (!this.state.exact) {
                                                    this.setState({
                                                        customMatch: false
                                                    })
                                                }
                                                if (!this.state.phrase && !this.state.broad && this.state.exact) {

                                                    await this.functions.animate("error_type", { remove: ['h-0'], add: ['h-12'] });
                                                } else {
                                                    this.setState({
                                                        exact: !this.state.exact
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="pl-4">
                                        <div className="text-xs font-medium mb-3">
                                            Phrase
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.phrase}
                                            onSwitch={async () => {
                                                if (!this.state.phrase) {
                                                    this.setState({
                                                        customMatch: false
                                                    })
                                                }
                                                if (!this.state.exact && !this.state.broad && this.state.phrase) {
                                                    await this.functions.animate("error_type", { remove: ['h-0'], add: ['h-12'] });
                                                } else {
                                                    this.setState({
                                                        phrase: !this.state.phrase
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="pl-4">
                                        <div className="text-xs font-medium mb-3">
                                            Broad
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.broad}
                                            onSwitch={async () => {
                                                if (!this.state.broad) {
                                                    this.setState({
                                                        customMatch: false
                                                    })
                                                }
                                                if (!this.state.phrase && !this.state.exact && this.state.broad) {
                                                    await this.functions.animate("error_type", { remove: ['h-0'], add: ['h-12'] });
                                                } else {
                                                    this.setState({
                                                        broad: !this.state.broad
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="px-4">
                                        <div className="text-xs font-medium mb-3">
                                            Custom
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.customMatch}
                                            onSwitch={() => {
                                                if (!this.state.customMatch) {
                                                    this.setState({
                                                        exact: false,
                                                        phrase: false,
                                                        broad: false,
                                                    })
                                                }
                                                this.setState({
                                                    customMatch: !this.state.customMatch,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id={"error_type"} className={"bg-red-100 col-span-12 text-red-500 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex h-0"}>
                                <span className='p-4 inline-flex items-center'>
                                    <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                    {"Either 1 type must be selected (EXACT, PHRASE, BROAD) or Custom."}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/*  DIVIDER */}
                    <div className="pl-4 pr-4">
                        <div className="border-r h-full"></div>
                    </div>

                    {/*  LIST */}
                    <div className="flex-1 flex-col flex">
                        <div className="text-md font-medium mb-4 flex items-center">
                            <div className="flex flex-1">List</div>
                            <div className="flex relative hover:text-purple-600 text-sm font-medium cursor-pointer text-purple-500 flex-row items-center">
                                <UploadIcon className="h-5 w-5 mr-2" />
                                Upload keywords (csv)
                                <input
                                    onChange={(e) => this.functions.onSelectFile(e, false)}
                                    type="file"
                                    accept={".csv"}
                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                />
                            </div>
                        </div>
                        <div className="pt-4 pb-4 px-2 flex-1 flex relative">
                            <div className="w-full h-full">
                                <Line
                                    options={{
                                        maintainAspectRatio: false,
                                        interaction: {
                                            intersect: false,
                                        },
                                        legend: {
                                            display: false
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    callback: function (value, index, values) {
                                                        return nFormatter(parseInt(value))
                                                        function nFormatter(num) {
                                                            if (num >= 1000000000) {
                                                                return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                                                            }
                                                            if (num >= 1000000) {
                                                                return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                                            }
                                                            if (num >= 1000) {
                                                                return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                                                            }
                                                            return num;
                                                        }
                                                    }
                                                }
                                            }],
                                        },
                                    }}
                                    data={this.renders.graph()}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {
                    <div className="flex flex-1 flex-row" style={{ minHeight: "400px" }}>

                        {/* KEYWORD SUGGESTION TABLE */}
                        <div className="flex flex-1 overflow-x-scroll relative">
                            <div className="left-0 absolute right-0 top-0 bottom-0">
                                <div className="h-full overflow-x-scroll border rounded-md">
                                    {
                                        this.state.loading_keywords &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50 sticky top-0">
                                            <tr>
                                                <th scope="col" className="px-3 py-2 text-left whitespace-no-wrap text-xs font-medium text-gray-900">
                                                    <div onClick={() => {
                                                        this.setState({
                                                            sort_suggestions: 'keyword',
                                                            direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                        })
                                                    }} className="flex flex-row items-center cursor-pointer">
                                                        <div className="mr-1">Keyword</div>
                                                        <div className="flex flex-col">
                                                            {
                                                                this.state.sort_suggestions === 'keyword' && this.state.direction_suggestions === 'ascending' &&
                                                                <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                            {
                                                                this.state.sort_suggestions === 'keyword' && this.state.direction_suggestions === 'descending' &&
                                                                <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left cursor-pointer text-xs whitespace-no-wrap font-medium text-gray-900">
                                                    <div onClick={() => {
                                                        this.setState({
                                                            sort_suggestions: 'avg_monthly_searches',
                                                            direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                        })
                                                    }} className="flex flex-row items-center cursor-pointer">
                                                        <div className="mr-1">Searches</div>
                                                        <div className="flex flex-col">
                                                            {
                                                                this.state.sort_suggestions === 'avg_monthly_searches' && this.state.direction_suggestions === 'ascending' &&
                                                                <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                            {
                                                                this.state.sort_suggestions === 'avg_monthly_searches' && this.state.direction_suggestions === 'descending' &&
                                                                <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left cursor-pointer text-xs whitespace-no-wrap font-medium text-gray-900">
                                                    <div onClick={() => {
                                                        this.setState({
                                                            sort_suggestions: 'competition',
                                                            direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                        })
                                                    }} className="flex flex-row items-center cursor-pointer">
                                                        <div className="mr-1">Competition</div>
                                                        <div className="flex flex-col">
                                                            {
                                                                this.state.sort_suggestions === 'competition' && this.state.direction_suggestions === 'ascending' &&
                                                                <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                            {
                                                                this.state.sort_suggestions === 'competition' && this.state.direction_suggestions === 'descending' &&
                                                                <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left cursor-pointer text-xs whitespace-no-wrap font-medium text-gray-900">
                                                    <div onClick={() => {
                                                        this.setState({
                                                            sort_suggestions: 'low_bid',
                                                            direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                        })
                                                    }} className="flex flex-row items-center cursor-pointer">
                                                        <div className="mr-1">Low bid</div>
                                                        <div className="flex flex-col">
                                                            {
                                                                this.state.sort_suggestions === 'low_bid' && this.state.direction_suggestions === 'ascending' &&
                                                                <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                            {
                                                                this.state.sort_suggestions === 'low_bid' && this.state.direction_suggestions === 'descending' &&
                                                                <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left cursor-pointer text-xs whitespace-no-wrap font-medium text-gray-900">
                                                    <div onClick={() => {
                                                        this.setState({
                                                            sort_suggestions: 'high_bid',
                                                            direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                        })
                                                    }} className="flex flex-row items-center cursor-pointer">
                                                        <div className="mr-1">High bid</div>
                                                        <div className="flex flex-col">
                                                            {
                                                                this.state.sort_suggestions === 'high_bid' && this.state.direction_suggestions === 'ascending' &&
                                                                <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                            {
                                                                this.state.sort_suggestions === 'high_bid' && this.state.direction_suggestions === 'descending' &&
                                                                <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left cursor-pointer text-xs whitespace-no-wrap font-medium text-gray-900">
                                                    Types
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left cursor-pointer text-xs whitespace-no-wrap font-medium text-gray-900">

                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left cursor-pointer text-xs whitespace-no-wrap font-medium text-gray-900">

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            <tr className="bg-gray-100">
                                                <td colSpan={this.renders.filter(this.renders.sort(this.state.suggestions.filter((item) => { return item.own_keyword }), this.state.sort_suggestions, this.state.direction_suggestions)).length > 0 ? 6 : 8} className="text-xs font-medium px-3 py-2">
                                                    Your keywords
                                                </td>
                                                {
                                                    this.renders.filter(this.renders.sort(this.state.suggestions.filter((item) => { return item.own_keyword }), this.state.sort_suggestions, this.state.direction_suggestions)).length > 0 &&
                                                    <td onClick={async () => {
                                                        this.renders.filter(this.renders.sort(this.state.suggestions.filter((item) => { return item.own_keyword }), this.state.sort_suggestions, this.state.direction_suggestions)).map((person) => {
                                                            this.state.group.keywords = this.state.group.keywords.filter((item) => { return item.id !== person.id });
                                                        })
                                                        await this.promisedSetState({
                                                            group: this.state.group,
                                                        });
                                                        this.renders.graph();
                                                    }} colSpan={1} className={"cursor-pointer whitespace-nowrap text-red-500 hover:text-red-700 px-3 py-4 text-right text-xs font-medium"}>
                                                        Remove All
                                                    </td>
                                                }
                                                {
                                                    this.renders.filter(this.renders.sort(this.state.suggestions.filter((item) => { return item.own_keyword }), this.state.sort_suggestions, this.state.direction_suggestions)).length > 0 &&
                                                    <td onClick={async () => {
                                                        this.renders.filter(this.renders.sort(this.state.suggestions.filter((item) => { return item.own_keyword }), this.state.sort_suggestions, this.state.direction_suggestions)).map((person) => {
                                                            if (!this.renders.exist(person)) {
                                                                if (!this.state.group.keywords) {
                                                                    this.state.group.keywords = [];
                                                                }
                                                                this.state.group.keywords.unshift(person);
                                                            }
                                                        })
                                                        await this.promisedSetState({
                                                            group: this.state.group,
                                                        });
                                                        this.renders.graph();
                                                    }} colSpan={1} className={"cursor-pointer whitespace-nowrap text-purple-500 hover:text-purple-700 px-3 py-4 text-right text-xs font-medium"}>
                                                        Add All
                                                    </td>
                                                }


                                            </tr>
                                            {this.renders.filter(this.renders.sort(this.state.suggestions.filter((item) => { return item.own_keyword }), this.state.sort_suggestions, this.state.direction_suggestions)).map((person) => (
                                                <tr key={person.id}>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs font-medium text-gray-900">
                                                        {person.keyword}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs">{person.avg_monthly_searches}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs">{person.competition}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs">{person.low_bid}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs">{person.high_bid}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xxs">
                                                        <div>{person.exact ? "Exact" : ""}</div>
                                                        <div>{person.phrase ? "Phrase" : ""}</div>
                                                        <div>{person.broad ? "Broad" : ""}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap text-red-500 hover:text-red-700 px-3 py-4 cursor-pointer text-right text-xs font-medium">
                                                        {
                                                            person.own_keyword &&
                                                            <div onClick={() => {
                                                                this.state.suggestions = this.state.suggestions.filter((item) => {
                                                                    return item.id !== person.id;
                                                                });
                                                                this.setState({
                                                                    suggestions: this.state.suggestions
                                                                });
                                                            }} >
                                                                Remove
                                                            </div>
                                                        }
                                                    </td>
                                                    <td onClick={async () => {
                                                        if (!this.renders.exist(person)) {
                                                            if (!this.state.group.keywords) {
                                                                this.state.group.keywords = [];
                                                            }
                                                            this.state.group.keywords.unshift(person);
                                                            await this.promisedSetState({
                                                                group: this.state.group,
                                                            });
                                                            this.renders.graph();
                                                        }
                                                    }} className={(this.renders.exist(person) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + " whitespace-nowrap text-purple-500 hover:text-purple-700 px-3 py-4 text-right text-xs font-medium"}>
                                                        Add
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr className="bg-gray-100">
                                                <td colSpan={8} className="text-xs font-medium px-3 py-2">
                                                    Suggetsions
                                                </td>
                                            </tr>
                                            {this.renders.filter(this.renders.sort(this.state.suggestions.filter((item) => { return !item.own_keyword }), this.state.sort_suggestions, this.state.direction_suggestions)).map((person) => (
                                                <tr key={person.id}>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs font-medium text-gray-900">
                                                        {person.keyword}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs">{person.avg_monthly_searches}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs">{person.competition}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs">{person.low_bid}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xs">{person.high_bid}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-xxs">
                                                        <div>{person.exact ? "Exact" : ""}</div>
                                                        <div>{person.phrase ? "Phrase" : ""}</div>
                                                        <div>{person.broad ? "Broad" : ""}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap text-red-500 hover:text-red-700 px-3 py-4 cursor-pointer text-right text-xs font-medium">
                                                        {
                                                            person.own_keyword &&
                                                            <div onClick={() => {

                                                            }} >
                                                                Remove
                                                            </div>
                                                        }
                                                    </td>
                                                    <td onClick={() => {
                                                        if (!this.state.group.keywords) {
                                                            this.state.group.keywords = [];
                                                        }
                                                        let keyword = JSON.parse(JSON.stringify(person));
                                                        keyword.id = Math.floor((Math.random() * 9999999999) + 1);
                                                        keyword.exact = this.state.exact;
                                                        keyword.phrase = this.state.phrase;
                                                        keyword.broad = this.state.broad;
                                                        this.state.group.keywords.unshift(keyword);
                                                        this.setState({
                                                            group: this.state.group,
                                                        })
                                                    }} className="whitespace-nowrap text-purple-500 hover:text-purple-700 px-3 py-4 cursor-pointer text-right text-xs font-medium">
                                                        Add
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="pl-4 pr-4">
                            <div className="border-r h-full"></div>
                        </div>

                        {/* KEYWORD LIST TABLE */}
                        <div className="flex flex-1 overflow-x-scroll relative">
                            <div className="left-0 absolute right-0 top-0 bottom-0">
                                <div className={"h-full overflow-x-scroll border rounded-md" + (Array.isArray(this.state.group.keywords) && this.state.group.keywords.length < 1 ? " border-red-500" : "")} >

                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50 sticky top-0">
                                            <tr>
                                                <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-900">
                                                    Keyword
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-900">
                                                    Searches
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-900">
                                                    Competition
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-900">
                                                    Low bid
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-900">
                                                    High bid
                                                </th>
                                                <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-900">
                                                    Types
                                                </th>
                                                <th scope="col" className="relative px-3 py-2 text-xs">
                                                    <span className="sr-only">Remove</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {
                                                Array.isArray(this.state.group.keywords) &&
                                                this.state.group.keywords.map((person, index) => (
                                                    <tr key={index}>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xs font-medium text-gray-900">
                                                            {person.keyword}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xs">{person.avg_monthly_searches}</td>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xs">{person.competition}</td>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xs">{person.low_bid}</td>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xs">{person.high_bid}</td>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xxs">
                                                            <div>{person.exact ? "Exact" : ""}</div>
                                                            <div>{person.phrase ? "Phrase" : ""}</div>
                                                            <div>{person.broad ? "Broad" : ""}</div>
                                                        </td>
                                                        <td onClick={() => {
                                                            this.state.group.keywords = this.state.group.keywords.filter((item) => { return item.id !== person.id });
                                                            this.setState({
                                                                group: this.state.group
                                                            });
                                                        }} className="whitespace-nowrap text-red-500 hover:text-red-700 px-3 py-4 cursor-pointer text-right text-xs font-medium">
                                                            Remove
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                }
            </div>
        )
    }
}

export default CreateOrderCreativeKeywords;
