import React, { Component, Fragment } from 'react';
import { LockClosedIcon, CheckIcon, TrashIcon, PlusIcon, ChevronDownIcon, ChevronUpIcon, AdjustmentsIcon, UserGroupIcon, CogIcon, CalculatorIcon, XIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import currencies from '../assets/json/currencies.json';
import InputTailwind from './inputTailwind';
import SlideDown from 'react-slidedown';
import SwitchTailwind from './switchTailwind';
const fuzzysort = require('fuzzysort');

class CreateCustomNewMetric extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            loading: true,
            type: {},
            name: "",
            calculation: "",
            clients: [],
            rates: [],
            custom_variables: [],
            channel: { name: "Google", value: "google", metric: "google" },
            custom_channels: [],
            channels: [
                { name: "Google", value: "google", metric: "google" },
                { name: "Tik tok", value: "tiktok", metric: "tiktok" },
                { name: "Facebook", value: "facebook", metric: "facebook" },
                { name: "Linkedin", value: "linkedin", metric: "linkedin" },
                { name: "Twitter", value: "twitter", metric: "twitter" },
                { name: "Adform", value: "adform", metric: "adform" },
                { name: "Bidtheatre", value: "bidtheatre", metric: "bidtheatre" },
                { name: "Google Analytics", value: "google_analytics", metric: "google_analytics" },
                { name: "Google Analytics 4", value: "google_analytics_4", metric: "google_analytics_4" },
                { name: "Bing", value: "bing", metric: "bing" },
                { name: "Snapchat", value: "snapchat", metric: "snapchat" },
                { name: "DV360", value: "dv360", metric: "dv360" },
                { name: "CM360", value: "cm360", metric: "cm360" },
                //{ name: "Google search console", value: "google_search_console", metric: "google_search_console" }
            ],
            calculation_options: [],
            numbers: [{ id: 1, value: "" }],
            level: {},
            metrics: {},
            client: {},
            agency: {},
            goal: {},
            comparison: {},
            rounding: {},
            currencyOption: {},
            currency: {},
            currencies: currencies,
            suffix: { id: 0, name: "No suffix", value: "" },
            selected: [],
            open: {
                type: false
            },
            missing_custom_metrics: [],
            page: 1,
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            throttling: {},
        };
    }

    async componentDidMount() {
        if (this.props.metric) {
            if (this.props.metric.level === "system") {
                this.state.level = { name: "System", value: "system" };
            } else if (this.props.metric.level === "agency") {
                this.state.level = { name: "Agency", value: "agency" };
            } else if (this.props.metric.level === "client") {
                this.state.level = { name: "Client", value: "client" };
            } else {
                this.state.level = { name: "Agency", value: "agency" };
            }
            if (this.props.metric.name) {
                this.state.name = this.props.metric.name;
            }
            if (this.props.metric.calculation_options) {
                this.state.calculation_options = this.props.metric.calculation_options;
            }
            if (this.props.metric.suffix) {
                this.state.suffix = this.props.metric.suffix;
            }
            if (this.props.metric.goal) {
                this.state.goal = this.props.metric.goal;
            }
            if (this.props.metric.comparison) {
                this.state.comparison = this.props.metric.comparison;
            }
            if (this.props.metric.rounding) {
                this.state.rounding = this.props.metric.rounding;
            }
            if (this.props.metric.currencyOption) {
                this.state.currencyOption = this.props.metric.currencyOption;
            }
            if (this.props.metric.currency) {
                this.state.currency = this.props.metric.currency;
            }
            if ("showExternalName" in this.props.metric) {
                this.state.showExternalName = this.props.metric.showExternalName;
            }
            if (this.props.metric.externalName) {
                this.state.externalName = this.props.metric.externalName;
            }
            if (!this.props.metric.calculated) {
                this.state.type = { name: "Custom", value: "custom" };
                if (this.props.metric.channels) {
                    for (let channel in this.props.metric.channels) {
                        this.state.selected.push(this.props.metric.channels[channel]);
                    }
                }
                this.functions.getChannelMetrics();
            }
            if (this.props.metric.calculated) {
                this.functions.getClientsCustomVariables();
                this.state.type = { name: "Calculated", value: "calculated" };
                this.functions.getDefaultMetrics(this.props.metric.metrics);

            }
            if (this.props.metric.rates && this.props.metric.rates.length) {
                this.state.rates = this.props.metric.rates;
            }
            await this.promisedSetState({
                name: this.state.name,
                level: this.state.level,
                rates: this.state.rates,
                type: this.state.type,
                calculation_options: this.state.calculation_options,
                suffix: this.state.suffix,
                currencyOption: this.state.currencyOption,
                currency: this.state.currency,
                showExternalName: this.state.showExternalName,
                externalName: this.state.externalName,
                goal: this.state.goal,
                comparison: this.state.comparison,
                rounding: this.state.rounding,
                selected: this.state.selected,
                open: {
                    type: false,
                    level: false,
                    metric: true,
                }
            });
        } else {
            this.setState({
                open: {
                    type: true
                }
            })
        }
    }

    functions = {
        getClientsCustomVariables: async () => {
            await this.promisedSetState({ loading_custom_variables: true });
            try {
                let response = await this.calls.getClientsCustomVariables();

                if (response.data.length && response.data[0].names.length) {
                    this.state.custom_variables = response.data[0].names;
                }
            } catch (error) {
                console.error(error)
            }

            await this.promisedSetState({
                loading_custom_variables: false,
                custom_variables: this.state.custom_variables,
            });
        },
        getChannelMetrics: async () => {
            await this.promisedSetState({ loading_metrics: true });
            try {
                let response = await this.calls.getChannelMetrics();
                await this.promisedSetState({
                    channel: { name: "Google", value: "google", metric: "google" },
                    channels: [
                        { name: "Google", value: "google", metric: "google" },
                        { name: "Tik tok", value: "tiktok", metric: "tiktok" },
                        { name: "Bidtheatre", value: "bidtheatre", metric: "bidtheatre" },
                        { name: "Facebook", value: "facebook", metric: "facebook" },
                        { name: "Linkedin", value: "linkedin", metric: "linkedin" },
                        { name: "Twitter", value: "twitter", metric: "twitter" },
                        { name: "Adform", value: "adform", metric: "adform" },
                        { name: "Google Analytics", value: "google_analytics", metric: "google_analytics" },
                        { name: "Google Analytics 4", value: "google_analytics_4", metric: "google_analytics_4" },
                        { name: "Bing", value: "bing", metric: "bing" },
                        { name: "Snapchat", value: "snapchat", metric: "snapchat" },
                        { name: "DV360", value: "dv360", metric: "dv360" },
                        { name: "CM360", value: "cm360", metric: "cm360" },
                        { name: "Google search console", value: "google_search_console", metric: "google_search_console" },
                    ],
                    custom_channels: response.data.custom && Object.keys(response.data.custom).length > 0 ? Object.keys(response.data.custom).map((key) => {
                        return { name: response.data.custom[key].name, value: response.data.custom[key].name, metric: response.data.custom[key].name, logo: response.data.custom[key].logo, color: response.data.custom[key].color }
                    }) : [],
                    metrics: response.data
                });

                //PARSE OLD METRICS
                if (this.props.metric && !this.props.metric.calculated) {
                    let selected = [];
                    if (this.props.metric.channels) {
                        for (let key in this.props.metric.channels) {
                            if (!this.props.metric.channels[key].parent) {
                                if (Array.isArray(this.state.metrics[key])) {
                                    this.state.metrics[key].map((category) => {
                                        if (Array.isArray(category.nested_values)) {
                                            category.nested_values.map((metric) => {
                                                if (metric.name === this.props.metric.channels[key].name) {
                                                    this.props.metric.channels[key].parent = category.name;
                                                    if (!this.props.metric.channels[key].alias) {
                                                        this.props.metric.channels[key].alias = metric.alias;
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                            selected.push(this.props.metric.channels[key]);
                        }
                        this.setState({
                            selected: selected
                        })
                    }
                }

            } catch (error) {
                this.setState({
                    error: error.body.message
                });
                this.props.onError();
            }
            await this.promisedSetState({ loading_metrics: false });
        },
        getDefaultMetrics: async (metrics) => {
            if (Object.keys(this.state.metrics).length < 1) {
                await this.promisedSetState({ loading_metrics: true });
                try {
                    let response = await this.calls.getDefaultMetrics();
                    await this.promisedSetState({
                        channel: { name: "Default", value: "default", metric: "default" },
                        channels: [{ name: "Default", value: "default", metric: "default" }, { name: "Calculated", value: "calculated", metric: "calculated" }],
                        metrics: {
                            calculated: [{
                                name: "calculated", nested_values: response.data.filter((item) => { return item.calculated }).map((item) => { item.alias = item.name; return item })
                            }],
                            default: [{
                                name: "default", nested_values: response.data.filter((item) => { return !item.calculated }).map((item) => { item.alias = item.name; return item })
                            }]
                        }
                    });
                    if (metrics) {
                        let missing_custom_metrics = [];
                        let selected = [];
                        for (let key in metrics) {
                            let exist = false;
                            ["default", "calculated"].map((type) => {
                                if (this.state.metrics[type]) {
                                    this.state.metrics[type].map((item) => {
                                        item.nested_values.map((inner_item) => {
                                            if (metrics[key].id == inner_item.id) {
                                                exist = true;
                                                inner_item.index = key;
                                                let metric = {
                                                    parent: item.name,
                                                    channel: type,
                                                    channel_metric: type
                                                };
                                                metric = { ...metric, ...inner_item };
                                                selected.push(metric);
                                            }
                                        });
                                    });
                                }
                            });
                            if (!exist) {
                                missing_custom_metrics.push(metrics[key]);
                            }
                        }
                        this.setState({
                            selected: selected,
                            missing_custom_metrics: missing_custom_metrics
                        });
                    }
                } catch (error) {
                    console.log(error);
                    this.setState({
                        error: error && error.body ? error.body.message : "Something went wrong"
                    });
                    this.props.onError();
                }
                await this.promisedSetState({ loading_metrics: false });
            }
        },
        createMetric: async () => {
            await this.promisedSetState({ loading: true });
            let metric = {
                name: this.state.name,
                suffix: this.state.suffix,
                goal: this.state.goal,
                rates: this.state.rates.filter(rate => rate.rate && rate.currency),
                comparison: this.state.comparison,
                rounding: this.state.rounding,
                showExternalName: this.state.showExternalName,
                externalName: this.state.externalName,
                currency: this.state.currency,
                currencyOption: this.state.currencyOption
            };
            if (this.state.type.value === "custom") {

                this.state.selected.map((item) => {
                    metric[item.channel_metric] = item;
                    delete metric[item.channel_metric].html;
                });

                //ADD CUSTOM
                metric.custom = {};
                this.state.custom_channels.map((channel) => {
                    this.state.selected.map((selected_metric) => {
                        if (channel.channel == metric.value) {
                            metric.custom[selected_metric.channel_metric] = selected_metric;
                            delete metric.custom[selected_metric.channel_metric].html;
                        }
                    });
                });

            } else if (this.state.type.value === "calculated") {
                metric.calculation_options = this.state.calculation_options;
                let calculation = "";
                let metrics = {};
                let channels = {};
                this.state.selected.map((item, index) => {
                    for (let channel in item.channels) {
                        channels[channel] = true;
                    }
                    metrics[index] = item;
                    delete metrics[index].html;
                });
                metric.custom_metrics = metrics;
                metric.channels = channels;
                this.state.calculation_options.map((item) => {
                    if (item.type === "number") {
                        calculation = calculation + item.value;
                    } else if (item.type === "metric" || item.type === "calculated_metric") {
                        calculation = calculation + "<" + (item.id ? item.id : item.index) + ">";
                    } else if (item.type === "custom_variable") {
                        calculation = calculation + "{" + item.value + "}";
                    } else {
                        calculation = calculation + item.value;
                    }
                });
                metric.calculation = calculation;
            }

            if (this.state.type.value) {
                metric.calculated = this.state.type.value === "calculated";
            }
            if (this.state.level.value) {
                metric.level = this.state.level.value;
            }
            if (this.state.client.id) {
                metric.client = this.state.client.id;
            }
            if (this.state.agency.id) {
                metric.agency = this.state.agency.id;
            }

            try {
                if (this.props.metric) {
                    let response = await this.calls.updateMetric(metric);
                    this.props.onCreated(response.data);
                } else {
                    let response = await this.calls.createMetric(metric);
                    this.props.onCreated(response.data);
                }
            } catch (error) {
                this.setState({
                    error: error.body.message
                });
                this.props.onError();
            }

        },
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        }
    };

    calls = {
        createMetric: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createMetric";
            return apiRegister.call(options, url);
        },
        updateMetric: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateMetric?id=" + this.props.metric.id;
            return apiRegister.call(options, url);
        },
        getClientsCustomVariables: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientsCustomVariables";
            return apiRegister.call(options, url);
        },
        getChannelMetrics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listMetrics" + (this.state.client.id ? ("?client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        },
        getDefaultMetrics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCustomMetrics" + (this.state.level.value === "system" ? "?default=true&agency=system" : "");
            return apiRegister.call(options, url);
        },
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "");
            return apiRegister.call(options, url);
        }
    };

    filterList = (options) => {
        let old_options = JSON.parse(JSON.stringify(options));
        try {
            if (this.state.search && this.state.search !== "") {
                let result = fuzzysort.go(this.state.search, options.map((item) => { return item.alias }));
                options = [];
                result.map((item) => {
                    for (let i = 0; i < old_options.length; i++) {
                        if (("alias" in old_options[i]) && item.target && item.target.toLowerCase() === old_options[i].alias.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_options[i].html = html_string;
                            options.push(old_options[i]);
                            old_options.splice(i, 1);
                            break;
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        return options;
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid">
                                <div className={"col-span-12 px-6"}>
                                    <div className="flex flex-row">
                                        <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CogIcon className="w-5 h-5 " />
                                        </div>
                                        <div className="flex flex-1 flex-col justify-center ml-3">
                                            <div className="font-semibold text-sm">Select metric type</div>
                                            {
                                                this.state.type.value &&
                                                <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.type.value ? this.state.type.name : "..."}</div></div>
                                            }
                                            {
                                                !this.state.type.value &&
                                                <div className="font-medium text-xs text-gray-500">Select type</div>
                                            }
                                        </div>
                                        <div className="flex flex-row">
                                            {
                                                this.state.type.value &&
                                                <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                    <CheckIcon className="w-5 h-5 " />
                                                </div>
                                            }
                                            <div onClick={() => {
                                                if (!this.props.metric) {
                                                    this.state.open.type = !this.state.open.type;
                                                    if (this.state.open.type) {
                                                        this.state.open.level = false;
                                                        this.state.open.settings = false;
                                                        this.state.open.metrics = false;
                                                        this.state.open.calculation = false;
                                                    }
                                                    this.setState({
                                                        open: this.state.open
                                                    });
                                                }
                                            }} className={((this.state.type.value && !this.state.open.type) ? "opacity-50 " : "") + (this.props.metric ? "bg-custom-input w-10" : "bg-gray-100 ") + " h-10 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                {
                                                    false &&
                                                    !this.props.metric &&
                                                    !this.state.open.type &&
                                                    <div className="mr-2 px-2 text-sm font-medium">Open</div>
                                                }
                                                {
                                                    false &&
                                                    !this.props.metric &&
                                                    this.state.open.type &&
                                                    <div className="mr-2 px-2 text-sm font-medium">Close</div>
                                                }
                                                {
                                                    !this.props.metric &&
                                                    !this.state.open.type &&
                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                }
                                                {
                                                    !this.props.metric &&
                                                    this.state.open.type &&
                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                }
                                                {
                                                    this.props.metric &&
                                                    <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <SlideDown closed={!this.state.open.type}>
                                        <div className="mt-4 grid-cols-12 grid gap-4">
                                            {
                                                [
                                                    { name: "Custom", value: "custom", description: "Select channel metrics from Google, Facebook, Tiktok, Linkedin, Google Analytics and combine them into one custom metric." },
                                                    { name: "Calculated", value: "calculated", description: "Select custom metrics and combine them with operators (+,-,/,*) to create a custom calculated metric." }
                                                ].map((item, index) => {
                                                    return (
                                                        <div onClick={() => {
                                                            if (!this.props.fixedType || this.props.fixedType === item.value) {
                                                                this.state.open.type = false;
                                                                this.state.open.settings = false;
                                                                this.state.open.metrics = false;
                                                                this.state.open.calculation = false;
                                                                this.state.open.level = true;
                                                                this.setState({
                                                                    type: item,
                                                                    open: this.state.open,
                                                                    level: {},
                                                                    metrics: {},
                                                                    selected: [],
                                                                    calculation_options: [],
                                                                    suffix: { id: 0, name: "No suffix", value: "" },
                                                                    name: "",
                                                                    search: "",
                                                                    client: {}
                                                                });
                                                            }
                                                        }} className={(!this.props.fixedType || this.props.fixedType === item.value ? "cursor-pointer" : "opacity-25 cursor-not-allowed") + " " + (this.state.type.value === item.value ? "bg-purple-100 border-purple-500" : "border-gray-300 bg-custom-input") + " flex hover:border-purple-500 flex-col col-span-6 relative p-4 rounded-md border-1.5 "}>
                                                            <div className={(this.state.type.value === item.value ? "text-purple-500" : "") + " text-sm font-semibold mb-1"}>
                                                                {item.name}
                                                            </div>
                                                            <div className={(this.state.type.value === item.value ? "text-gray-700" : "text-gray-500") + " text-sm font-medium "}>
                                                                {item.description}
                                                            </div>
                                                            <div className="flex flex-1">

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                !this.props.metric &&
                                                this.state.open.type &&
                                                <div className="col-span-12 flex items-center justify-center">
                                                    <div onClick={async () => {
                                                        if (this.state.type.value) {
                                                            this.state.open.type = false;
                                                            this.state.open.settings = false;
                                                            this.state.open.metrics = false;
                                                            this.state.open.calculation = false;
                                                            this.state.open.level = true;
                                                            this.setState({
                                                                open: this.state.open
                                                            });
                                                        }
                                                    }}
                                                        className={(this.state.type.value ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                        <span style={{ whiteSpace: "nowrap" }}
                                                            className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                            Next step
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </SlideDown>
                                </div>
                                {
                                    this.state.type.value &&
                                    <div className="col-span-12">
                                        <div className="border-b w-full"></div>
                                    </div>
                                }
                                {
                                    this.state.type.value &&
                                    <div className={"col-span-12 px-6"}>
                                        <div className="flex flex-row">
                                            <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                <UserGroupIcon className="w-5 h-5 " />
                                            </div>
                                            <div className="flex flex-1 flex-col justify-center ml-3">
                                                <div className="font-semibold text-sm">Select owner</div>
                                                {
                                                    this.state.level.value &&
                                                    <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="ml-1 text-purple-500">{this.state.level.value ? (this.state.level.name + (this.state.level.value === "client" && this.state.client.id ? (": " + this.state.client.name) : "")) : "..."}</div></div>
                                                }
                                                {
                                                    !this.state.level.value &&
                                                    <div className="font-medium text-xs text-gray-500">Select owner</div>
                                                }
                                            </div>
                                            <div className="flex flex-row">
                                                {
                                                    this.state.level.value &&
                                                    <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <CheckIcon className="w-5 h-5 " />
                                                    </div>
                                                }
                                                <div onClick={() => {
                                                    if (!this.props.metric) {
                                                        this.state.open.level = !this.state.open.level;

                                                        if (this.state.open.level) {
                                                            this.state.open.settings = false;
                                                            this.state.open.metrics = false;
                                                            this.state.open.type = false;
                                                            this.state.open.calculation = false;
                                                        }

                                                        this.setState({
                                                            open: this.state.open
                                                        });
                                                    }
                                                }} className={((this.state.level.value && !this.state.open.level) ? "opacity-50 " : "") + (this.props.metric ? "bg-custom-input w-10" : "bg-gray-100 ") + " h-10 w-10 cursor-pointer  border-1.5 flex justify-center items-center rounded-md"}>
                                                    {
                                                        false &&
                                                        !this.props.metric &&
                                                        !this.state.open.level &&
                                                        <div className="mr-2 px-2 text-sm font-medium">Open</div>
                                                    }
                                                    {
                                                        false &&
                                                        !this.props.metric &&
                                                        this.state.open.level &&
                                                        <div className="mr-2 px-2 text-sm font-medium">Close</div>
                                                    }
                                                    {
                                                        !this.props.metric &&
                                                        !this.state.open.level &&
                                                        <ChevronDownIcon className="w-5 h-5 " />
                                                    }
                                                    {
                                                        !this.props.metric &&
                                                        this.state.open.level &&
                                                        <ChevronUpIcon className="w-5 h-5 " />
                                                    }
                                                    {
                                                        this.props.metric &&
                                                        <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <SlideDown closed={!this.state.open.level}>
                                            <div className="mt-4 grid-cols-12 grid gap-4">
                                                {
                                                    [
                                                        { name: "System", size: "col-span-6", value: "system", description: "Create default metrics that are available for the entire system and all agencies." },
                                                        { name: "Agency", size: "col-span-6", value: "agency", description: "Create a metric available for your entire agency and agents." },
                                                        { name: "Client", size: "col-span-6", value: "client", description: "Create a metric only available for a certain client, access client specific channel metrics." }
                                                    ].filter((item) => {
                                                        return (!this.props.admin && item.value !== "system") || (this.props.admin && item.value == "system")
                                                    }).map((item, index) => {
                                                        return (
                                                            <div onClick={async () => {

                                                                this.state.open.metrics = false;
                                                                this.state.open.settings = false;
                                                                this.state.open.calculation = false;

                                                                if (item.value !== "client") {
                                                                    this.state.open.level = false;
                                                                    if (this.state.type.value === 'calculated') {
                                                                        //this.state.open.default = true;
                                                                        this.state.open.metric = true;
                                                                    } else {
                                                                        this.state.open.metric = true;
                                                                    }
                                                                }
                                                                if (item.value === "client") {
                                                                    this.state.metrics = {};
                                                                    this.state.clients = [];
                                                                    this.functions.getClients();
                                                                }
                                                                await this.promisedSetState({
                                                                    clients: this.state.clients,
                                                                    metrics: this.state.metrics,
                                                                    open: this.state.open,
                                                                    level: item,
                                                                    selected: [],
                                                                    client: {},
                                                                    calculation_options: [],
                                                                    suffix: { id: 0, name: "No suffix", value: "" },
                                                                    name: "",
                                                                    search: ""
                                                                });
                                                                if (this.state.type.value === "custom" && this.state.level.value === "agency") {
                                                                    this.functions.getChannelMetrics();
                                                                } else if (this.state.type.value === "calculated") {
                                                                    this.functions.getClientsCustomVariables();
                                                                    this.functions.getDefaultMetrics();
                                                                }
                                                            }} className={(item.size) + (this.state.level.value === item.value ? " bg-purple-100 border-purple-500" : " border-gray-300 bg-custom-input") + " flex flex-col relative p-4 rounded-md cursor-pointer border-1.5 "}>
                                                                <div className={(this.state.level.value === item.value ? "text-purple-500" : "") + " text-sm font-semibold mb-1"}>
                                                                    {item.name}
                                                                </div>
                                                                <div className={"text-sm font-medium text-gray-500"}>
                                                                    {item.description}
                                                                </div>
                                                                <div className="flex flex-1">

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    this.state.level.value === "client" &&
                                                    <div className="col-span-12">
                                                        <DropdownTailwind
                                                            label={"Select client"}
                                                            small={false}
                                                            locked={this.props.metric}
                                                            skipInternalSearch={true}
                                                            loader={this.state.loading_clients}
                                                            loadingPagination={this.state.loading_clients_pagination}
                                                            loadingSearch={this.state.loading_clients_search}
                                                            total={this.state.clients_total}
                                                            searchInput={true}
                                                            placeholder={"Search ..."}
                                                            pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                                            selected={this.state.client.id !== 1 ? this.state.client : { id: 1, name: "Select client" }}
                                                            options={this.state.clients}
                                                            onChange={async (value) => {
                                                                this.state.open.level = false;

                                                                if (this.state.type.value === 'calculated') {
                                                                    this.state.open.default = true;
                                                                } else {
                                                                    this.state.open.metric = true;
                                                                }

                                                                await this.promisedSetState({
                                                                    client: value,
                                                                    open: this.state.open
                                                                });
                                                                if (this.state.type.value === "custom") {
                                                                    this.functions.getChannelMetrics();
                                                                }
                                                            }}
                                                            onPagination={async () => {
                                                                if (!this.state.loading_clients_pagination) {
                                                                    await this.promisedSetState({
                                                                        client_page: this.state.client_page + 1
                                                                    });
                                                                    this.functions.getClients(false, true, false);
                                                                }
                                                            }}
                                                            onSearch={async (value) => {
                                                                await this.promisedSetState({
                                                                    loading_clients_search: true,
                                                                    client_search: value
                                                                });
                                                                setTimeout(async () => {
                                                                    if (value === this.state.client_search) {
                                                                        await this.promisedSetState({
                                                                            client_page: 1
                                                                        });
                                                                        this.functions.getClients(false, false, true, value);
                                                                    }
                                                                }, 400);
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    !this.props.metric &&
                                                    this.state.open.level &&
                                                    <div className="col-span-12 flex items-center justify-center">
                                                        <div onClick={async () => {
                                                            if (this.state.level.value) {
                                                                this.state.open.level = false;
                                                                if (this.state.type.value === 'calculated') {
                                                                    this.state.open.default = true;
                                                                } else {
                                                                    this.state.open.metric = true;
                                                                }
                                                                this.setState({
                                                                    open: this.state.open
                                                                });
                                                            }
                                                        }}
                                                            className={(this.state.level.value ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                            <span style={{ whiteSpace: "nowrap" }}
                                                                className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                Next step
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </SlideDown>
                                    </div>
                                }
                                {
                                    this.state.level.value &&
                                    <div className="col-span-12">
                                        <div className="border-b w-full"></div>
                                    </div>
                                }
                                {
                                    this.state.level.value &&
                                    <div className={"col-span-12 px-6"}>
                                        <div className="flex flex-row">
                                            <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                <AdjustmentsIcon className="w-5 h-5 " />
                                            </div>
                                            <div className="flex flex-1 flex-col justify-center ml-3">
                                                <div className="font-semibold text-sm">Select metrics</div>
                                                {
                                                    this.state.selected.length > 0 &&
                                                    <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="ml-1 text-purple-500">{this.state.selected.length} metrics</div></div>
                                                }
                                                {
                                                    this.state.selected.length < 1 &&
                                                    <div className="font-medium text-xs text-gray-500">Select metrics</div>
                                                }
                                            </div>
                                            {
                                                this.state.type.value === 'calculated' &&
                                                <div className="flex flex-row"></div>
                                            }
                                            {
                                                this.state.type.value !== 'calculated' &&
                                                <div className="flex flex-row">
                                                    {
                                                        this.state.selected.length > 0 &&
                                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                            <CheckIcon className="w-5 h-5 " />
                                                        </div>
                                                    }
                                                    <div onClick={() => {
                                                        this.state.open.metric = !this.state.open.metric;
                                                        if (this.state.open.metric) {
                                                            this.state.open.settings = false;
                                                            this.state.open.level = false;
                                                            this.state.open.type = false;
                                                            this.state.open.calculation = false;
                                                        }
                                                        this.setState({
                                                            open: this.state.open
                                                        });
                                                    }} className={((this.state.selected.length > 0 && !this.state.open.metric) ? "opacity-50 " : "") + (true ? "bg-custom-input w-10" : "bg-gray-100 ") + " w-10 h-10 cursor-pointer  border-1.5 flex justify-center items-center rounded-md"}>
                                                        {
                                                            //!this.props.metric &&
                                                            !this.state.open.metric &&
                                                            <ChevronDownIcon className="w-5 h-5 " />
                                                        }
                                                        {
                                                            //!this.props.metric &&
                                                            this.state.open.metric &&
                                                            <ChevronUpIcon className="w-5 h-5 " />
                                                        }
                                                        {
                                                            false &&
                                                            this.props.metric &&
                                                            <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <SlideDown closed={!this.state.open.metric}>
                                            <div className={"pl-12 mt-4 grid-cols-12 grid gap-4"}>
                                                {
                                                    this.state.channels.filter((item) => {
                                                        if (this.state.type.value === 'calculated') {
                                                            return item.value === 'default' || item.value === 'calculated';
                                                        } else {
                                                            return item.value !== 'default' && item.value !== 'calculated';
                                                        }
                                                    }).sort((a, b) => (a.name > b.name) ? 1 : -1).map((channel) => {
                                                        return (
                                                            <div className={"col-span-12"}>
                                                                <div className="flex flex-row">
                                                                    {
                                                                        false &&
                                                                        (channel.value === 'default' || channel.value === 'calculated') &&
                                                                        <div className={"h-10 w-10 cursor-pointer bg-purple-100 flex justify-center items-center rounded-md"}>
                                                                            <AdjustmentsIcon className="w-5 h-5 text-purple-500" />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        channel.value !== 'calculated' &&
                                                                        channel.value !== 'default' &&
                                                                        <div className={"h-10 w-10 cursor-pointer bg-" + channel.value + "-500 p-3 flex justify-center items-center rounded-md"}>
                                                                            {
                                                                                channel.value === 'facebook' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'google' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'tiktok' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'adform' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'linkedin' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'twitter' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'google_analytics' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'google_analytics_4' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'bing' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'snapchat' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'bidtheatre' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'dv360' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'cm360' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/cm360-logo.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel.value === 'google_search_console' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_search_console.png') + ")" }}></div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className={(this.state.type.value === 'calculated' ? "ml-1" : "ml-3") + " flex flex-1 flex-col justify-center"}>
                                                                        {
                                                                            this.state.type.value === "custom" &&
                                                                            <div className="font-semibold text-sm">{channel.name}</div>
                                                                        }
                                                                        {
                                                                            this.state.type.value === "calculated" && channel.value == "default" &&
                                                                            <div className="font-semibold text-sm">Custom metrics</div>
                                                                        }
                                                                        {
                                                                            this.state.type.value === "calculated" && channel.value == "calculated" &&
                                                                            <div className="font-semibold text-sm">Calculated metrics</div>
                                                                        }
                                                                        <div className="flex flex-row">
                                                                            {
                                                                                this.state.selected.filter((item) => { return item.channel === channel.value }).length > 0 &&
                                                                                <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                                    You have selected
                                                                                    <div className="text-purple-500 ml-1">
                                                                                        {this.state.selected.filter((item) => { return item.channel === channel.value }).length > 0 ? this.state.selected.filter((item) => { return item.channel === channel.value }).map((item) => {
                                                                                            if (this.state.type.value === "calculated") {
                                                                                                return item.name
                                                                                            } else {
                                                                                                return item.alias + " (" + item.parent + ")"
                                                                                            }
                                                                                        }).join(", ") : ""}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.selected.filter((item) => { return item.channel === channel.value }).length === 0 &&
                                                                                <div className="font-medium text-xs text-gray-500 flex">
                                                                                    Not selected
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.missing_custom_metrics.length > 0 &&
                                                                                <div className="font-medium text-xs text-red-500 ml-1">
                                                                                    {this.state.missing_custom_metrics.map((item) => { return item.alias }).join(", ")} have been removed.Please update!
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div onClick={() => {
                                                                            this.state.open[channel.value] = !this.state.open[channel.value];
                                                                            this.setState({
                                                                                open: this.state.open
                                                                            });
                                                                        }} className={(this.props.metric ? "bg-custom-input" : "bg-white") + " h-10 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                                            {
                                                                                false &&
                                                                                !this.state.open[channel.value] &&
                                                                                <div className="mr-2 px-2 text-sm font-medium">Open</div>
                                                                            }
                                                                            {
                                                                                false &&
                                                                                this.state.open[channel.value] &&
                                                                                <div className="mr-2 px-2 text-sm font-medium">Close</div>
                                                                            }
                                                                            {
                                                                                !this.state.open[channel.value] &&
                                                                                <ChevronDownIcon className="w-5 h-5 " />
                                                                            }
                                                                            {
                                                                                this.state.open[channel.value] &&
                                                                                <ChevronUpIcon className="w-5 h-5 " />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <SlideDown closed={!this.state.open[channel.value]}>
                                                                    <div className="mt-2 relative">
                                                                        {
                                                                            this.state.loading_metrics &&
                                                                            <div className="absolute bg-white bg-opacity-50 left-0 top-0 bottom-0 right-0 flex items-center justify-center">
                                                                                <div style={{ borderTopColor: "transparent" }} class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            <div className={"col-span-12 mt-4"}>
                                                                                <div className="flex flex-row">
                                                                                    <div className="flex flex-1">
                                                                                        <div className="w-full">
                                                                                            <InputTailwind
                                                                                                label={""}
                                                                                                placeholder={"Search metric ..."}
                                                                                                value={this.state.search}
                                                                                                onChange={async (value) => {
                                                                                                    this.setState({
                                                                                                        search: value
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            <div className={"col-span-12 mt-4"}>
                                                                                {
                                                                                    this.state.open[channel.value] &&
                                                                                    Array.isArray(this.state.metrics[channel.metric]) &&
                                                                                    this.state.metrics[channel.metric].filter((item) => {
                                                                                        return Array.isArray(item.nested_values) && this.filterList(item.nested_values).length > 0
                                                                                    }).map((item, index) => {
                                                                                        return (
                                                                                            <Fragment>
                                                                                                {
                                                                                                    index !== 0 &&
                                                                                                    <div className="border-b mt-2 mb-2"></div>
                                                                                                }
                                                                                                {
                                                                                                    this.state.type.value !== "calculated" &&
                                                                                                    <div className="flex flex-row">
                                                                                                        <div className="flex flex-1 flex-col justify-center">
                                                                                                            <div className="font-semibold text-sm">{item.name}</div>
                                                                                                            <div className="flex flex-row">
                                                                                                                <div className="font-medium text-xs text-gray-500">{Array.isArray(item.nested_values) ? this.filterList(item.nested_values).length : "0"} metrics</div>
                                                                                                                {
                                                                                                                    this.state.selected.filter((selected) => { return selected.parent === item.name && selected.channel === channel.value }).length === 1 &&
                                                                                                                    <div onClick={() => {
                                                                                                                        this.setState({
                                                                                                                            search: this.state.selected.filter((selected) => { return selected.parent === item.name && selected.channel === channel.value })[0].alias
                                                                                                                        })
                                                                                                                    }} className="ml-2 text-purple-500 font-medium text-xs cursor-pointer hover:text-purple-700">{
                                                                                                                            this.state.selected.filter((selected) => { return selected.parent === item.name && selected.channel === channel.value })[0].alias
                                                                                                                        }</div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            this.state.search === "" &&
                                                                                                            <div>
                                                                                                                <div onClick={() => {
                                                                                                                    item.open = !item.open;
                                                                                                                    this.setState({
                                                                                                                        metrics: this.state.metrics
                                                                                                                    });
                                                                                                                }} className="h-10 w-10 cursor-pointer bg-gray-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                                    {
                                                                                                                        !item.open &&
                                                                                                                        <ChevronDownIcon className="w-5 h-5 " />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        item.open &&
                                                                                                                        <ChevronUpIcon className="w-5 h-5 " />
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    (item.open || this.state.search !== "" || this.state.type.value === "calculated") &&
                                                                                                    Array.isArray(item.nested_values) &&
                                                                                                    <div className="mt-2">
                                                                                                        {
                                                                                                            this.state.open[channel.value] &&
                                                                                                            this.filterList(item.nested_values).map((inner_item, index) => {
                                                                                                                return (
                                                                                                                    <Fragment>
                                                                                                                        <div onClick={async () => {
                                                                                                                            if ((
                                                                                                                                (this.state.type.value === "calculated" && this.state.selected.filter((selected) => { return selected.id === inner_item.id }).length < 1)
                                                                                                                                ||
                                                                                                                                (this.state.type.value === "custom" && this.state.selected.filter((selected) => { return selected.channel === channel.value }).length < 1)
                                                                                                                            )) {

                                                                                                                                let metric = {
                                                                                                                                    parent: item.name,
                                                                                                                                    channel: channel.value,
                                                                                                                                    channel_metric: channel.metric
                                                                                                                                };
                                                                                                                                metric = { ...metric, ...inner_item };
                                                                                                                                if (channel.value === "adform" || channel.value === 'facebook' || channel.value === 'snapchat') {
                                                                                                                                    metric.specs = {};
                                                                                                                                    if (Array.isArray(metric.nested_values)) {
                                                                                                                                        metric.nested_values.map((item) => {
                                                                                                                                            metric.specs[item.name] = item.default_value;
                                                                                                                                            metric.specs[item.name].name = item.default_value.key;
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                this.state.selected.unshift(metric);
                                                                                                                                if (this.state.type.value !== "calculated") {
                                                                                                                                    this.state.open[channel.value] = false;
                                                                                                                                }
                                                                                                                                await this.promisedSetState({
                                                                                                                                    metrics: this.state.metrics,
                                                                                                                                    selected: this.state.selected,
                                                                                                                                    open: this.state.open
                                                                                                                                });
                                                                                                                            } else {
                                                                                                                                let exist = false;
                                                                                                                                if (this.state.type.value === "custom") {
                                                                                                                                    exist = this.state.selected.filter((selected) => { return (selected.name == inner_item.name || selected.original_name == inner_item.name) && selected.channel === channel.value }).length > 0;
                                                                                                                                }
                                                                                                                                this.state.selected = this.state.selected.filter((selected) => {
                                                                                                                                    if (this.state.type.value === "calculated") {
                                                                                                                                        return selected.id !== inner_item.id;
                                                                                                                                    } else {
                                                                                                                                        return selected.channel !== channel.value
                                                                                                                                    }
                                                                                                                                });
                                                                                                                                this.promisedSetState({
                                                                                                                                    calculation_options: [],
                                                                                                                                    metrics: this.state.metrics,
                                                                                                                                    selected: this.state.selected
                                                                                                                                });
                                                                                                                                if (this.state.type.value === "custom" && !exist) {
                                                                                                                                    let metric = {
                                                                                                                                        parent: item.name,
                                                                                                                                        channel: channel.value,
                                                                                                                                        channel_metric: channel.metric
                                                                                                                                    };
                                                                                                                                    metric = { ...metric, ...inner_item };
                                                                                                                                    if (channel.value === 'adform' || channel.value === 'facebook' || channel.value === 'snapchat') {
                                                                                                                                        metric.specs = {};
                                                                                                                                        if (Array.isArray(metric.nested_values)) {
                                                                                                                                            metric.nested_values.map((item) => {
                                                                                                                                                metric.specs[item.name] = item.default_value;
                                                                                                                                                metric.specs[item.name].name = item.default_value.key;
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    this.state.selected.unshift(metric);
                                                                                                                                }
                                                                                                                                await this.promisedSetState({
                                                                                                                                    calculation_options: [],
                                                                                                                                    metrics: this.state.metrics,
                                                                                                                                    selected: this.state.selected
                                                                                                                                });
                                                                                                                                //console.log(this.state.selected);
                                                                                                                            }
                                                                                                                        }} className="flex flex-row cursor-pointer mt-2">
                                                                                                                            <div className={(this.state.selected.filter((item) => { return (item.name == inner_item.name || item.original_name == inner_item.name) && item.channel === channel.value }).length > 0 ? "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100") + " h-10 mr-3 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                                                                                                {
                                                                                                                                    <CheckIcon className="h-5 w-5" />
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            <div className="flex flex-1 flex-col justify-center">
                                                                                                                                <div dangerouslySetInnerHTML={{ __html: inner_item.html ? inner_item.html : inner_item.alias }} className="font-semibold text-sm"></div>
                                                                                                                                <div class="flex flex-row">
                                                                                                                                    <div className="font-medium text-xs text-gray-500">{this.state.type.value === "custom" ? item.name : !inner_item.calculated ? Object.keys(inner_item.channels).join(", ") : inner_item.calculation}</div>
                                                                                                                                    {
                                                                                                                                        (channel.value === 'adform' || channel.value === 'facebook' || channel.value === 'snapchat') &&
                                                                                                                                        this.state.selected.filter((selected) => { return (selected.name == inner_item.name || selected.original_name == inner_item.name) && selected.channel === channel.value }).length === 1 &&
                                                                                                                                        this.state.selected.filter((selected) => { return (selected.name == inner_item.name || selected.original_name == inner_item.name) && selected.channel === channel.value })[0].specs &&
                                                                                                                                        <div className="ml-2 text-purple-500 font-medium text-xs cursor-pointer hover:text-purple-700">
                                                                                                                                            {
                                                                                                                                                Object.keys(this.state.selected.filter((selected) => { return (selected.name == inner_item.name || selected.original_name == inner_item.name) && selected.channel === channel.value })[0].specs).map((key) => {
                                                                                                                                                    return key + " (" + this.state.selected.filter((selected) => { return (selected.name == inner_item.name || selected.original_name == inner_item.name) && selected.channel === channel.value })[0].specs[key].name + ")"
                                                                                                                                                }).join(", ")
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        {/* ADFORM */}
                                                                                                                        {
                                                                                                                            (channel.value === 'adform' || channel.value === 'facebook' || channel.value === 'snapchat') &&
                                                                                                                            this.state.selected.filter((selected) => { return (selected.name == inner_item.name || selected.original_name == inner_item.name) && selected.channel === channel.value }).length > 0 &&
                                                                                                                            Array.isArray(inner_item.nested_values) &&
                                                                                                                            <Fragment>
                                                                                                                                {
                                                                                                                                    inner_item.nested_values.map((inner_inner_item, index) => {
                                                                                                                                        return (
                                                                                                                                            <div class="pl-14">
                                                                                                                                                <Fragment>
                                                                                                                                                    {
                                                                                                                                                        <div className="border-b mt-2 mb-2"></div>
                                                                                                                                                    }
                                                                                                                                                    <div onClick={async () => {
                                                                                                                                                        item.nested_values = item.nested_values.map((item) => {
                                                                                                                                                            item.nested_values = item.nested_values.map((item) => {
                                                                                                                                                                if (item.name === inner_inner_item.name) {
                                                                                                                                                                    item.open = !item.open;
                                                                                                                                                                }
                                                                                                                                                                return item;
                                                                                                                                                            })
                                                                                                                                                            return item;
                                                                                                                                                        })
                                                                                                                                                        await this.promisedSetState({
                                                                                                                                                            metrics: this.state.metrics
                                                                                                                                                        });
                                                                                                                                                    }} className="flex flex-row cursor-pointer">
                                                                                                                                                        <div className="flex flex-1 flex-col justify-center">
                                                                                                                                                            <div className="font-semibold text-sm">{inner_inner_item.alias}</div>
                                                                                                                                                            <div className="flex flex-row">
                                                                                                                                                                <div className="font-medium text-xs text-gray-500">{inner_inner_item.nested_values.length} sub values</div>
                                                                                                                                                                {
                                                                                                                                                                    this.state.selected.filter((selected) => { return selected.parent === item.name && selected.channel === channel.value }).length === 1 &&
                                                                                                                                                                    <div className="ml-2 text-purple-500 font-medium text-xs cursor-pointer hover:text-purple-700">
                                                                                                                                                                        {
                                                                                                                                                                            this.state.selected.filter((selected) => {
                                                                                                                                                                                return selected.parent === item.name && selected.channel === channel.value
                                                                                                                                                                            })[0].specs && this.state.selected.filter((selected) => {
                                                                                                                                                                                return selected.parent === item.name && selected.channel === channel.value
                                                                                                                                                                            })[0].specs[inner_inner_item.name] &&
                                                                                                                                                                            <div>
                                                                                                                                                                                {
                                                                                                                                                                                    this.state.selected.filter((selected) => {
                                                                                                                                                                                        return selected.parent === item.name && selected.channel === channel.value
                                                                                                                                                                                    })[0].specs[inner_inner_item.name].displayName
                                                                                                                                                                                }
                                                                                                                                                                            </div>
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        {
                                                                                                                                                            <div>
                                                                                                                                                                <div className="h-10 w-10 cursor-pointer bg-gray-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                                                                                    {
                                                                                                                                                                        !inner_inner_item.open &&
                                                                                                                                                                        <ChevronDownIcon className="w-5 h-5 " />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        inner_inner_item.open &&
                                                                                                                                                                        <ChevronUpIcon className="w-5 h-5 " />
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    {
                                                                                                                                                        inner_inner_item.open &&
                                                                                                                                                        Array.isArray(inner_inner_item.nested_values) &&
                                                                                                                                                        <Fragment>
                                                                                                                                                            {
                                                                                                                                                                inner_inner_item.nested_values.map((inner_inner_inner_item) => {
                                                                                                                                                                    return (
                                                                                                                                                                        <div onClick={async () => {
                                                                                                                                                                            if (channel.value === "adform" || channel.value === "facebook" || channel.value === 'snapchat') {
                                                                                                                                                                                if (Array.isArray(this.state.selected)) {
                                                                                                                                                                                    this.state.selected = this.state.selected.map((selected) => {
                                                                                                                                                                                        if (selected.parent === item.name && selected.channel === channel.value) {
                                                                                                                                                                                            if (!selected.specs) {
                                                                                                                                                                                                selected.specs = {};
                                                                                                                                                                                            }
                                                                                                                                                                                            selected.specs[inner_inner_item.name] = inner_inner_inner_item;
                                                                                                                                                                                            selected.specs[inner_inner_item.name].displayName = inner_inner_inner_item.alias
                                                                                                                                                                                        }
                                                                                                                                                                                        return selected;
                                                                                                                                                                                    })

                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            await this.promisedSetState({
                                                                                                                                                                                selected: this.state.selected
                                                                                                                                                                            });
                                                                                                                                                                        }} className="flex flex-row cursor-pointer mt-2">
                                                                                                                                                                            <div className={((this.state.selected.filter((selected) => {
                                                                                                                                                                                return selected.parent === item.name && selected.channel === channel.value
                                                                                                                                                                            })[0].specs && this.state.selected.filter((selected) => {
                                                                                                                                                                                return selected.parent === item.name && selected.channel === channel.value
                                                                                                                                                                            })[0].specs[inner_inner_item.name].name === inner_inner_inner_item.name) ? "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100") + " h-10 mr-3 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                                                                                                                                                {
                                                                                                                                                                                    <CheckIcon className="h-5 w-5" />
                                                                                                                                                                                }
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className="flex flex-1 flex-col justify-center">
                                                                                                                                                                                <div className="font-semibold text-sm">{inner_inner_inner_item.alias}</div>
                                                                                                                                                                                <div className="font-medium text-xs text-gray-500">{inner_inner_item.alias}</div>
                                                                                                                                                                            </div>

                                                                                                                                                                        </div>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                        </Fragment>
                                                                                                                                                    }
                                                                                                                                                </Fragment>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </Fragment>
                                                                                                                        }

                                                                                                                    </Fragment>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.missing_custom_metrics.length > 0 &&
                                                                                                            this.state.missing_custom_metrics.map((item, index) => {
                                                                                                                return (
                                                                                                                    <Fragment>
                                                                                                                        <div className="border-b mt-2 mb-2"></div>
                                                                                                                        <div onClick={async () => {
                                                                                                                            item.removed = true;
                                                                                                                            this.setState({
                                                                                                                                calculation_options: [],
                                                                                                                                missing_custom_metrics: this.state.missing_custom_metrics
                                                                                                                            })
                                                                                                                        }} className="flex flex-row cursor-pointer">
                                                                                                                            <div className={(!item.removed ? "text-red-500  border-red-500 bg-red-100 cursor-pointer" : "text-red-100 border-red-500 bg-red-100 cursor-not-allowed") + " h-10 mr-3 w-10 border-1.5 flex justify-center items-center rounded-md"}>
                                                                                                                                {
                                                                                                                                    <CheckIcon className="h-5 w-5" />
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            <div className="flex flex-1 flex-col justify-center">
                                                                                                                                <div className="font-semibold text-sm">{item.alias}</div>
                                                                                                                                <div className="font-medium text-xs text-gray-500">{Object.keys(item.channels).join(", ")}</div>
                                                                                                                            </div>
                                                                                                                            <div className="flex justify-center items-center pr-4">
                                                                                                                                <div className="flex justify-center items-center text-sm font-medium  cursor-pointer text-red-500 hover:text-red-700">
                                                                                                                                    Remove metric
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Fragment>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </Fragment>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.type.value == "calculated" &&
                                                                            this.state.missing_custom_metrics.length > 0 &&
                                                                            <div className="mt-4 bg-red-100 text-red-500 p-4 text-sm font-medium rounded-md">
                                                                                Custom metrics:
                                                                                <span className="ml-1"></span>
                                                                                {
                                                                                    this.state.missing_custom_metrics.map((item) => { return '"' + item.alias + '"' }).join(", ")
                                                                                }
                                                                                <span className="ml-1"></span>.Have been removed, please remove and update calculation.
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </SlideDown>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {/* CUSTOM DATASOURCE METRICS */}
                                                {
                                                    this.state.custom_channels.sort((a, b) => (a.name > b.name) ? 1 : -1).map((channel) => {
                                                        return (
                                                            <div className={"col-span-12"}>
                                                                <div className="flex flex-row">
                                                                    <div style={{ backgroundColor: "#" + channel.color }} className={"h-10 w-10 cursor-pointer p-3 flex justify-center items-center rounded-md"}>
                                                                        <div className="block w-full h-full bg-contain bg-no-repeat bg-center" style={{ backgroundImage: "url(" + channel.logo + ")" }}></div>
                                                                    </div>
                                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                                        <div className="font-semibold text-sm">{channel.name}</div>
                                                                        <div className="flex flex-row">
                                                                            {
                                                                                this.state.selected.filter((item) => { return item.channel === channel.value }).length > 0 &&
                                                                                <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                                    You have selected
                                                                                    <div className="text-purple-500 ml-1">
                                                                                        {this.state.selected.filter((item) => { return item.channel === channel.value }).length > 0 ? this.state.selected.filter((item) => { return item.channel === channel.value }).map((item) => {
                                                                                            return item.alias + " (" + item.parent + ")"
                                                                                        }).join(", ") : ""}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.selected.filter((item) => { return item.channel === channel.value }).length === 0 &&
                                                                                <div className="font-medium text-xs text-gray-500 flex">
                                                                                    Not selected
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div onClick={() => {
                                                                            this.state.open[channel.value] = !this.state.open[channel.value];
                                                                            this.setState({
                                                                                open: this.state.open
                                                                            });
                                                                        }} className={(this.props.metric ? "bg-custom-input" : "bg-white") + " h-10 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                                            {
                                                                                !this.state.open[channel.value] &&
                                                                                <ChevronDownIcon className="w-5 h-5 " />
                                                                            }
                                                                            {
                                                                                this.state.open[channel.value] &&
                                                                                <ChevronUpIcon className="w-5 h-5 " />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <SlideDown closed={!this.state.open[channel.value]}>
                                                                    <div className="mt-2 relative">
                                                                        {
                                                                            this.state.loading_metrics &&
                                                                            <div className="absolute bg-white bg-opacity-50 left-0 top-0 bottom-0 right-0 flex items-center justify-center">
                                                                                <div style={{ borderTopColor: "transparent" }} class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            <div className={"col-span-12 mt-4"}>
                                                                                <div className="flex flex-row">
                                                                                    <div className="flex flex-1">
                                                                                        <div className="w-full">
                                                                                            <InputTailwind
                                                                                                label={""}
                                                                                                placeholder={"Search metric ..."}
                                                                                                value={this.state.search}
                                                                                                onChange={async (value) => {
                                                                                                    this.setState({
                                                                                                        search: value
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            <div className={"col-span-12 mt-4"}>
                                                                                {
                                                                                    this.state.open[channel.value] &&
                                                                                    this.state.metrics.custom &&
                                                                                    this.state.metrics.custom[channel.metric] &&
                                                                                    Array.isArray(this.state.metrics.custom[channel.metric].metrics) &&
                                                                                    this.state.metrics.custom[channel.metric].metrics.filter((item) => {
                                                                                        return Array.isArray(item.nested_values) && this.filterList(item.nested_values).length > 0
                                                                                    }).map((item, index) => {
                                                                                        return (
                                                                                            <Fragment>
                                                                                                {
                                                                                                    index !== 0 &&
                                                                                                    <div className="border-b mt-2 mb-2"></div>
                                                                                                }
                                                                                                {
                                                                                                    this.state.type.value !== "calculated" &&
                                                                                                    <div className="flex flex-row">
                                                                                                        <div className="flex flex-1 flex-col justify-center">
                                                                                                            <div className="font-semibold text-sm">{item.name}</div>
                                                                                                            <div className="flex flex-row">
                                                                                                                <div className="font-medium text-xs text-gray-500">{Array.isArray(item.nested_values) ? this.filterList(item.nested_values).length : "0"} metrics</div>
                                                                                                                {
                                                                                                                    this.state.selected.filter((selected) => { return selected.parent === item.name && selected.channel === channel.value }).length === 1 &&
                                                                                                                    <div onClick={() => {
                                                                                                                        this.setState({
                                                                                                                            search: this.state.selected.filter((selected) => { return selected.parent === item.name && selected.channel === channel.value })[0].alias
                                                                                                                        })
                                                                                                                    }} className="ml-2 text-purple-500 font-medium text-xs cursor-pointer hover:text-purple-700">{
                                                                                                                            this.state.selected.filter((selected) => { return selected.parent === item.name && selected.channel === channel.value })[0].alias
                                                                                                                        }</div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            this.state.search === "" &&
                                                                                                            <div>
                                                                                                                <div onClick={() => {
                                                                                                                    item.open = !item.open;
                                                                                                                    this.setState({
                                                                                                                        metrics: this.state.metrics
                                                                                                                    });
                                                                                                                }} className="h-10 w-10 cursor-pointer bg-gray-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                                    {
                                                                                                                        !item.open &&
                                                                                                                        <ChevronDownIcon className="w-5 h-5 " />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        item.open &&
                                                                                                                        <ChevronUpIcon className="w-5 h-5 " />
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    (item.open || this.state.search !== "") &&
                                                                                                    Array.isArray(item.nested_values) &&
                                                                                                    <div className="mt-2">
                                                                                                        {
                                                                                                            this.state.open[channel.value] &&
                                                                                                            this.filterList(item.nested_values).map((inner_item, index) => {
                                                                                                                return (
                                                                                                                    <Fragment>
                                                                                                                        <div onClick={async () => {
                                                                                                                            if (this.state.type.value === "custom" && this.state.selected.filter((selected) => { return selected.channel === channel.value }).length < 1) {
                                                                                                                                let metric = {
                                                                                                                                    parent: item.name,
                                                                                                                                    channel: channel.value,
                                                                                                                                    channel_metric: channel.metric
                                                                                                                                };
                                                                                                                                metric = { ...metric, ...inner_item };
                                                                                                                                this.state.selected.unshift(metric);
                                                                                                                                if (this.state.type.value !== "calculated") {
                                                                                                                                    this.state.open[channel.value] = false;
                                                                                                                                }
                                                                                                                            } else {
                                                                                                                                this.state.selected = this.state.selected.filter((selected) => {
                                                                                                                                    if (this.state.type.value === "calculated") {
                                                                                                                                        return selected.id !== inner_item.id;
                                                                                                                                    } else {
                                                                                                                                        return selected.channel !== channel.value
                                                                                                                                    }
                                                                                                                                });
                                                                                                                            }
                                                                                                                            await this.promisedSetState({
                                                                                                                                metrics: this.state.metrics,
                                                                                                                                selected: this.state.selected,
                                                                                                                                open: this.state.open
                                                                                                                            });
                                                                                                                        }} className="flex flex-row cursor-pointer mt-2">
                                                                                                                            <div className={(this.state.selected.filter((item) => { return item.name == inner_item.name && item.channel === channel.value }).length > 0 ? "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100") + " h-10 mr-3 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                                                                                                {
                                                                                                                                    <CheckIcon className="h-5 w-5" />
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            <div className="flex flex-1 flex-col justify-center">
                                                                                                                                <div dangerouslySetInnerHTML={{ __html: inner_item.html ? inner_item.html : inner_item.alias }} className="font-semibold text-sm"></div>
                                                                                                                                <div class="flex flex-row">
                                                                                                                                    <div className="font-medium text-xs text-gray-500">{this.state.type.value === "custom" ? item.name : Object.keys(inner_item.channels).join(", ")}</div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Fragment>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </Fragment>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </SlideDown>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {/* CUSTOM DATASOURCE METRICS END */}

                                                {
                                                    !this.props.metric &&
                                                    this.state.selected.length > 0 &&
                                                    this.state.open.metric &&
                                                    !this.state.open.calculation &&
                                                    !this.state.open.settings &&
                                                    <div className="col-span-12 flex items-center justify-center">
                                                        <div onClick={async () => {
                                                            if (this.state.type.value === 'calculated') {
                                                                this.state.open.default = false;
                                                                this.state.open.calculation = true;
                                                            } else {
                                                                this.state.open.metric = false;
                                                                this.state.open.settings = true;
                                                            }
                                                            this.setState({
                                                                open: this.state.open
                                                            });
                                                        }}
                                                            className={(this.state.selected.length > 0 ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                            <span style={{ whiteSpace: "nowrap" }}
                                                                className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                Next step
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </SlideDown>
                                    </div>
                                }
                                {
                                    this.state.level.value &&
                                    this.state.type.value == "calculated" &&
                                    <div className="col-span-12">
                                        <div className="border-b w-full"></div>
                                    </div>
                                }
                                {
                                    this.state.level.value &&
                                    this.state.type.value == "calculated" &&
                                    <div className={"col-span-12 px-6"}>
                                        <div className="flex flex-row">
                                            <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                <CalculatorIcon className="w-5 h-5 " />
                                            </div>
                                            <div className="flex flex-1 flex-col justify-center ml-3">
                                                <div className="font-semibold text-sm">Calculation</div>
                                                {
                                                    this.state.calculation_options.length > 0 &&
                                                    <div className="font-medium text-xs text-purple-500 flex flex-row"><div className="text-gray-500 mr-1">You have selected</div>{this.state.calculation_options.length > 0 ? this.state.calculation_options.map((item) => { return item.type === "number" ? item.value : item.title }).join(" ") : "..."}</div>
                                                }
                                                {
                                                    this.state.calculation_options.length < 1 &&
                                                    <div className="font-medium text-xs text-gray-500">Set calcuation</div>
                                                }
                                            </div>

                                            <div>
                                                <div onClick={() => {
                                                    this.state.open.calculation = !this.state.open.calculation;
                                                    this.setState({
                                                        open: this.state.open
                                                    });
                                                }} className={(this.props.metric ? "bg-custom-input" : "bg-white") + " h-10 w-10 cursor-pointer items-center border-1.5 flex justify-center rounded-md"}>
                                                    {
                                                        false &&
                                                        !this.state.open.calculation &&
                                                        <div className="mr-2 px-2 text-sm font-medium">Open</div>
                                                    }
                                                    {
                                                        false &&
                                                        this.state.open.calculation &&
                                                        <div className="mr-2 px-2 text-sm font-medium">Close</div>
                                                    }
                                                    {

                                                        !this.state.open.calculation &&
                                                        <ChevronDownIcon className="w-5 h-5 " />
                                                    }
                                                    {

                                                        this.state.open.calculation &&
                                                        <ChevronUpIcon className="w-5 h-5 " />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <SlideDown closed={!this.state.open.calculation}>
                                            <div className="mt-4">
                                                <div className={"col-span-12"}>
                                                    <div className="text-xs mb-1 font-medium">
                                                        Calculation
                                                    </div>
                                                    <div className="border-1.5 min-h-32 bg-custom-input rounded-md p-4">
                                                        {
                                                            this.state.calculation_options.map((item, index) => {
                                                                return (
                                                                    <div onClick={() => {
                                                                        this.state.calculation_options = this.state.calculation_options.filter((inner_item, inner_index) => {
                                                                            return inner_index !== index;
                                                                        });
                                                                        this.setState({
                                                                            calculation_options: this.state.calculation_options
                                                                        })
                                                                    }} className="h-10 mr-2 inline-flex items-center justify-center rounded-md font-medium text-sm bg-gray-100  cursor-pointer">
                                                                        <div className="px-4">{item.type === "number" ? item.value : item.title}</div>
                                                                        {
                                                                            false &&
                                                                            <div className="border-l h-full"></div>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            <div onClick={() => {
                                                                                this.state.calculation_options = this.state.calculation_options.filter((inner_item, inner_index) => {
                                                                                    return inner_index !== index;
                                                                                });
                                                                                this.setState({
                                                                                    calculation_options: this.state.calculation_options
                                                                                })
                                                                            }} className="px-2 text-xs text-purple-500 hover:text-purple-600"><XIcon className="w-4"></XIcon></div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.state.selected.length > 0 &&
                                                    this.state.selected.filter((item) => { return !item.calculated }).length > 0 &&
                                                    <div className={"col-span-12 mt-4 relative"}>
                                                        <div className="text-xs mb-1 font-medium">
                                                            Custom metrics
                                                        </div>
                                                        <div>
                                                            {
                                                                this.state.selected.filter((item) => { return !item.calculated }).map((item, index) => {
                                                                    return (
                                                                        <div className="h-10 mr-2 inline-flex items-center justify-center rounded-md font-medium text-sm bg-gray-100  cursor-pointer">
                                                                            <div className="px-4">{item.name}</div>
                                                                            <div className="border-l h-full"></div>
                                                                            <div onClick={() => {
                                                                                this.state.calculation_options.push({
                                                                                    id: Math.floor((Math.random() * 9999999999) + 1),
                                                                                    title: item.name,
                                                                                    type: "metric",
                                                                                    index: index,
                                                                                    value: item
                                                                                });
                                                                                this.setState({
                                                                                    calculation_options: this.state.calculation_options
                                                                                });
                                                                            }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.selected.length > 0 &&
                                                    this.state.selected.filter((item) => { return item.calculated }).length > 0 &&
                                                    <div className={"col-span-12 mt-4 relative"}>
                                                        <div className="text-xs mb-1 font-medium">
                                                            Calculated metrics
                                                        </div>
                                                        <div>
                                                            {
                                                                this.state.selected.filter((item) => { return item.calculated }).map((item, index) => {
                                                                    return (
                                                                        <div className="h-10 mr-2 inline-flex items-center justify-center rounded-md font-medium text-sm bg-gray-100  cursor-pointer">
                                                                            <div className="px-4">{item.name}</div>
                                                                            <div className="border-l h-full"></div>
                                                                            <div onClick={() => {
                                                                                this.state.calculation_options.push({
                                                                                    id: Math.floor((Math.random() * 9999999999) + 1),
                                                                                    title: item.name,
                                                                                    type: "calculated_metric",
                                                                                    index: index,
                                                                                    value: item
                                                                                });
                                                                                this.setState({
                                                                                    calculation_options: this.state.calculation_options
                                                                                });
                                                                            }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className={"col-span-12 mt-4 relative"}>
                                                    <div className="text-xs mb-1 font-medium">
                                                        Operators
                                                    </div>
                                                    <div className="flex flex-row ">
                                                        <div className="relative flex flex-row">
                                                            <div className="h-10 mr-2 flex flex-row items-center justify-center rounded-md font-medium text-sm bg-gray-100  cursor-pointer">
                                                                <div className="px-4">+</div>
                                                                <div className="border-l h-full"></div>
                                                                <div onClick={() => {
                                                                    this.state.calculation_options.push({
                                                                        title: "+",
                                                                        type: "math",
                                                                        value: "+",
                                                                    });
                                                                    this.setState({
                                                                        calculation_options: this.state.calculation_options
                                                                    });
                                                                }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                            </div>
                                                            <div className="h-10 mr-2 flex items-center justify-center rounded-md font-medium text-sm bg-gray-100  cursor-pointer">
                                                                <div className="px-4">-</div>
                                                                <div className="border-l h-full"></div>
                                                                <div onClick={() => {
                                                                    this.state.calculation_options.push({
                                                                        title: "-",
                                                                        type: "math",
                                                                        value: "-",
                                                                    });
                                                                    this.setState({
                                                                        calculation_options: this.state.calculation_options
                                                                    });
                                                                }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                            </div>
                                                            <div className="h-10 mr-2 flex items-center justify-center rounded-md font-medium text-sm bg-gray-100  cursor-pointer">
                                                                <div className="px-4">/</div>
                                                                <div className="border-l h-full"></div>
                                                                <div onClick={() => {
                                                                    this.state.calculation_options.push({
                                                                        title: "/",
                                                                        type: "math",
                                                                        value: "/",
                                                                    });
                                                                    this.setState({
                                                                        calculation_options: this.state.calculation_options
                                                                    });
                                                                }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                            </div>
                                                            <div className="h-10 mr-2 flex items-center justify-center rounded-md font-medium text-sm bg-gray-100 cursor-pointer">
                                                                <div className="px-4">*</div>
                                                                <div className="border-l h-full"></div>
                                                                <div onClick={() => {
                                                                    this.state.calculation_options.push({
                                                                        title: "*",
                                                                        type: "math",
                                                                        value: "*",
                                                                    });
                                                                    this.setState({
                                                                        calculation_options: this.state.calculation_options
                                                                    });
                                                                }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                            </div>
                                                        </div>
                                                        <div className="relative">
                                                            <div className="h-10 mr-2 flex items-center justify-center rounded-md font-medium text-sm bg-gray-100 cursor-pointer">
                                                                <div className="px-4">(</div>
                                                                <div className="border-l h-full"></div>
                                                                <div onClick={() => {
                                                                    this.state.calculation_options.push({
                                                                        title: "(",
                                                                        type: "math",
                                                                        parenthese: true,
                                                                        value: "(",
                                                                    });
                                                                    this.setState({
                                                                        calculation_options: this.state.calculation_options
                                                                    });
                                                                }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                            </div>
                                                        </div>
                                                        <div className="relative">
                                                            <div className="h-10 mr-2 flex items-center justify-center rounded-md font-medium text-sm bg-gray-100 cursor-pointer">
                                                                <div className="px-4">)</div>
                                                                <div className="border-l h-full"></div>
                                                                <div onClick={() => {
                                                                    this.state.calculation_options.push({
                                                                        title: ")",
                                                                        type: "math",
                                                                        parenthese: true,
                                                                        value: ")",
                                                                    });
                                                                    this.setState({
                                                                        calculation_options: this.state.calculation_options
                                                                    });
                                                                }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-span-12 mt-4 relative"}>
                                                    <div className="text-xs mb-1 font-medium">
                                                        Number
                                                    </div>
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.numbers.map((item, index) => {
                                                                return (
                                                                    <div id={index} className="h-10 mr-2 flex items-center justify-center rounded-md font-medium text-sm bg-gray-100  cursor-pointer">
                                                                        <div className="px-4">
                                                                            <input className="font-medium w-24 text-sm bg-gray-100 border-none"
                                                                                maxLength={12}
                                                                                placeholder={"Click to type"}
                                                                                value={item.value}
                                                                                onChange={(event) => {
                                                                                    item.value = event.target.value;
                                                                                    this.setState({
                                                                                        numbers: this.state.numbers
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="border-l h-full"></div>
                                                                        <div onClick={() => {
                                                                            if (item.value !== "") {
                                                                                if (this.state.numbers.filter((item) => { return item.value === "" }).length < 1) {
                                                                                    this.state.numbers.unshift({ value: "", focus: false });
                                                                                    this.setState({
                                                                                        numbers: this.state.numbers
                                                                                    })
                                                                                }
                                                                                this.state.calculation_options.push({
                                                                                    title: "number",
                                                                                    type: "number",
                                                                                    value: item.value
                                                                                });
                                                                                this.setState({
                                                                                    calculation_options: this.state.calculation_options
                                                                                });
                                                                            }
                                                                        }} className="px-4 text-xs text-purple-500 hover:text-purple-600">Add</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    (this.state.custom_variables && this.state.custom_variables.length > 0) &&
                                                    <div className={"col-span-12 mt-4 relative"}>
                                                        <div className="text-xs mb-1 font-medium">
                                                            Client custom variables
                                                        </div>
                                                        {
                                                            this.state.custom_variables.map((item, index) => {
                                                                return (
                                                                    <div
                                                                        className="h-10 mr-2 inline-flex items-center justify-center rounded-md font-medium text-sm bg-gray-100  cursor-pointer">
                                                                        <div className="px-4">{item}</div>
                                                                        <div className="border-l h-full"></div>
                                                                        <div onClick={() => {
                                                                            this.state.calculation_options.push({
                                                                                type: "custom_variable",
                                                                                title: item,
                                                                                value: item,
                                                                            });
                                                                            this.setState({
                                                                                calculation_options: this.state.calculation_options
                                                                            });
                                                                        }}
                                                                            className="px-4 text-xs text-purple-500 hover:text-purple-600">Add
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    !this.props.metric &&
                                                    <div className="col-span-12 flex items-center mt-4 justify-center">
                                                        <div onClick={async () => {
                                                            this.state.open.calculation = false;
                                                            this.state.open.settings = true;
                                                            this.setState({
                                                                open: this.state.open
                                                            });
                                                        }}
                                                            className={(this.state.level.value ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                            <span style={{ whiteSpace: "nowrap" }}
                                                                className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                Next step
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </SlideDown>
                                    </div>
                                }
                                {
                                    this.state.level.value &&
                                    <div className="col-span-12">
                                        <div className="border-b w-full"></div>
                                    </div>
                                }
                                {
                                    this.state.level.value &&
                                    ((this.state.type.value !== "calculated" && this.state.selected.length > 0) || this.state.type.value == "calculated") &&
                                    <div className={"col-span-12 px-6 grid grid-cols-12 gap-4"}>
                                        <div className={"col-span-6"}>
                                            <div className={"w-full"}>
                                                <InputTailwind
                                                    locked={this.props.metric}
                                                    label={"Metric name"}
                                                    value={this.state.name}
                                                    onChange={async (value) => {
                                                        this.setState({
                                                            name: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-span-6 relative"}>
                                            <div className={"w-full"}>
                                                <InputTailwind
                                                    label={"External name (table cell)"}
                                                    value={this.state.externalName}
                                                    onChange={async (value) => {
                                                        this.setState({
                                                            externalName: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="absolute right-0 top-0 pt-5.5 pr-4">
                                                <div className="h-12 flex justify-center items-center">
                                                    <SwitchTailwind
                                                        value={this.state.showExternalName}
                                                        onSwitch={async () => {
                                                            this.setState({
                                                                showExternalName: !this.state.showExternalName
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.level.value &&
                                    ((this.state.type.value !== "calculated" && this.state.selected.length > 0) || this.state.type.value == "calculated") &&
                                    <div className={"col-span-12 px-6 grid grid-cols-12 gap-4"}>
                                        <div className={"col-span-4"}>
                                            <DropdownTailwind
                                                label={"Suffix (number/table cell)"}
                                                selected={this.state.suffix}
                                                options={[
                                                    { id: 0, name: "No suffix", value: "" },
                                                    { id: 1, name: "Percentage", value: "%" },
                                                    { id: 2, name: "Currency", value: "currency" },
                                                ]}
                                                onChange={async (value) => {
                                                    if (value.value == "currency") {
                                                        this.state.currencyOption = { id: 1, name: "Ad account", value: "adaccount" };
                                                    }
                                                    this.setState({
                                                        currencyOption: this.state.currencyOption,
                                                        suffix: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.suffix && this.state.suffix.value == "currency" &&
                                            <div className={"col-span-4"}>
                                                <DropdownTailwind
                                                    label={"Currency option"}
                                                    selected={this.state.currencyOption}
                                                    options={[
                                                        { id: 1, name: "Ad account (suffix)", value: "adaccount" },
                                                        { id: 2, name: "Static (suffix)", value: "static" },
                                                        { id: 3, name: "Exchange (calculation)", value: "dynamic" }
                                                    ].filter((item) => {
                                                        return (item.value == "dynamic" && this.state.type.value !== "calculated") || item.value !== "dynamic"
                                                    })}
                                                    onChange={async (value) => {
                                                        if (value.value == "dynamic") {
                                                            if (!(Array.isArray(this.state.rates) && this.state.rates.length > 0)) {
                                                                this.state.rates = [];
                                                                this.state.rates.push({});
                                                                this.setState({
                                                                    rates: this.state.rates
                                                                });
                                                            }
                                                        }
                                                        this.setState({
                                                            currencyOption: value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.suffix && this.state.suffix.value == "currency" &&
                                            this.state.currencyOption && (this.state.currencyOption.value == "static" || this.state.currencyOption.value == "dynamic") &&
                                            <div className={"col-span-4"}>
                                                <DropdownTailwind
                                                    searchInput={true}
                                                    label={this.state.currencyOption && this.state.currencyOption.value == "dynamic" ? "Base currency" : "Static currency"}
                                                    selected={this.state.currency}
                                                    options={this.state.currencies}
                                                    onChange={async (value) => {
                                                        this.setState({
                                                            currency: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.currencyOption && this.state.currencyOption.value == "dynamic" &&
                                            this.state.suffix && this.state.suffix.value == "currency" &&
                                            this.state.currency && this.state.currency.name &&
                                            <div className="col-span-12 bg-gray-50 border rounded-lg p-4">
                                                {
                                                    <div className="w-full">
                                                        {
                                                            this.state.rates.map((rate, index) => {
                                                                return (
                                                                    <div className={(index > 0 ? "mt-2" : "") + " flex"}>
                                                                        <div className="flex-1 flex mr-2">
                                                                            <div className="w-full">
                                                                                <DropdownTailwind
                                                                                    searchInput={true}
                                                                                    label={"Currency"}
                                                                                    selected={rate.currency ? rate.currency : {}}
                                                                                    options={this.state.currencies}
                                                                                    onChange={async (value) => {
                                                                                        this.state.rates[index].currency = value;
                                                                                        this.setState({
                                                                                            rates: this.state.rates
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-1 flex ml-2">
                                                                            <div className={"w-full"}>
                                                                                <InputTailwind
                                                                                    label={("Convert " + (rate.currency ? rate.currency.name : "...") + " to " + (this.state.currency ? this.state.currency.name : "-"))}
                                                                                    value={rate.rate}
                                                                                    locked={!(rate.currency && rate.currency.name)}
                                                                                    onChange={async (value) => {
                                                                                        this.state.rates[index].rate = value;
                                                                                        this.setState({
                                                                                            rates: this.state.rates
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            <div onClick={async () => {
                                                                                this.state.rates.splice(index, 1);
                                                                                this.setState({
                                                                                    rates: this.state.rates
                                                                                })
                                                                            }}
                                                                                className="mt-5.5 bg-red-500 hover:bg-red-600 cursor-pointer focus:ring-red-500 text-white relative rounded-md flex items-center justify-center h-12 w-12 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2">
                                                                                <TrashIcon className="w-5"></TrashIcon>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        <div onClick={async () => {
                                                            this.state.rates.push({})
                                                            this.setState({
                                                                rates: this.state.rates
                                                            })
                                                        }}
                                                            className="text-purple-500 hover:text-purple-700 cursor-pointer text-sm font-medium flex flex-row mt-5">
                                                            <PlusIcon className="w-5 mr-2" />
                                                            Add another currency rate
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div className={"col-span-4"}>
                                            <DropdownTailwind
                                                label={"Metric goal"}
                                                selected={this.state.goal}
                                                options={[
                                                    { id: 1, name: "Highest value", value: "highest" },
                                                    { id: 2, name: "Lowest value", value: "lowest" },
                                                    { id: 3, name: "No value", value: "none" },
                                                ]}
                                                onChange={async (value) => {
                                                    this.setState({
                                                        goal: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={"col-span-4"}>
                                            <DropdownTailwind
                                                label={"Comparison value"}
                                                selected={this.state.comparison}
                                                options={[
                                                    { id: 1, name: "Difference in Percentage", value: "percentarge" },
                                                    { id: 2, name: "Previous value", value: "number" }
                                                ]}
                                                onChange={async (value) => {
                                                    this.setState({
                                                        comparison: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={"col-span-4"}>
                                            <DropdownTailwind
                                                label={"Round metric"}
                                                selected={this.state.rounding}
                                                options={[
                                                    { id: 1, name: "None", value: "none" },
                                                    { id: 2, name: "Round up", value: "up" },
                                                    { id: 3, name: "Round down", value: "down" },
                                                    { id: 4, name: "Nearest", value: "nearest" }
                                                ]}
                                                onChange={async (value) => {
                                                    this.setState({
                                                        rounding: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={"col-span-12"}>
                                            <div className="bg-blue-100 col-span-12 rounded-md p-4 text-sm font-medium text-blue-500">
                                                OBS! <b>Metric goal</b> defines if the metric value performance is expected to be as high or low as possible.
                                            </div>
                                        </div>
                                        {
                                            this.state.error &&
                                            <div className={"col-span-12"}>
                                                <div className="bg-red-100 p-4 rounded-md">
                                                    <div className="text-sm text-red-500">
                                                        {this.state.error}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="h-12 w-full"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CreateCustomNewMetric;