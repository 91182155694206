import React, { Component, Fragment } from 'react';
import { ExclamationCircleIcon, LockClosedIcon } from '@heroicons/react/outline'

class InputDatepickerTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            label: "",
            placeholder: "",
            error: false,
            disabled: false,
            loading: false,
            labelRight: false,
            locked: false,
            minDate: "",
        }
    };

    componentDidMount() {
        this.setState({
            value: this.props.value,
            error: this.props.error,
            loading: this.props.loading,
            disabled: this.props.disabled,
            labelRight: this.props.labelRight,
            placeholder: this.props.placeholder,
            locked: this.props.locked,
            minDate: this.props.minDate,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            error: nextProps.error,
            loading: nextProps.loading,
            disabled: nextProps.disabled,
            labelRight: nextProps.labelRight,
            placeholder: nextProps.placeholder,
            locked: nextProps.locked,
            minDate: nextProps.minDate,
        })
    }

    render() {
        return (
            <div>
                <label className={"flex flex-row text-xs font-medium " + (this.state.error ? "text-red-500" : "text-gray-700")}>
                    {this.props.label}
                    {
                        this.state.labelRight &&
                        <div onClick={() => {
                            if (this.props.onLabelRightClick) {
                                this.props.onLabelRightClick();
                            }
                        }} className="flex flex-1 justify-end hover:text-purple-500">
                            {this.state.labelRight}
                        </div>
                    }
                </label>
                <div className="mt-1 relative flex flex-row rounded-md">
                    {
                        this.props.leftSection &&
                        <span className="inline-flex items-center whitespace-no-wrap px-3 rounded-l-md border border-r-0 border-gray-300 sm:text-sm">
                            {this.props.leftSectionLabel}
                        </span>
                    }
                    <input
                        data-date-inline-picker="true"
                        type={this.state.disabled ? "text" : "date"}
                        disabled={this.state.disabled}
                        className={(this.state.disabled ? " py-2 " : " py-1.5 ") + (this.state.error ? " border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 " : "  border-estatery-400") + (this.props.leftSection ? " rounded-r-md " : " rounded-md ") + " block w-full h-12 border-1.5 px-4 focus:outline-none sm:text-sm"}
                        placeholder={this.state.placeholder}
                        value={this.state.disabled ? this.state.placeholder : this.state.value}
                        min={this.state.minDate}
                        onChange={(event) => {
                            if (this.props.onChange && !this.state.disabled) {
                                this.props.onChange(event.target.value);
                            }
                        }}
                    />
                    {
                        this.state.error &&
                        <div className="absolute inset-y-0 right-0 pr-10 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                    }
                    {
                        this.state.loading &&
                        <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                            <div style={{ borderTopColor: "transparent" }} class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    }
                    {
                        this.state.locked &&
                        <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                            <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                    }
                </div>
                {
                    this.state.error &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        {this.state.errorMessage}
                    </p>
                }
            </div>
        )
    }
}

export default InputDatepickerTailwind;
