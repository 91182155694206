import React, { Component, Fragment } from 'react';
import { BeakerIcon, CheckIcon, CursorClickIcon, MinusIcon, PlusIcon, LocationMarkerIcon, CheckCircleIcon, BookOpenIcon, ChatIcon, CameraIcon, NewspaperIcon, ExclamationIcon, ReplyIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import cn from "classnames";
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import moment from 'moment';
import { isEmpty } from 'draft-js/lib/DefaultDraftBlockRenderMap';

import { adgroupLinkedin } from '../validators/adgroupLinkedin';

import languagesLinkedin from "../assets/json/linkedin_languages.json";
import SwitchTailwind from './switchTailwind';

import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';
import { Bar } from 'react-chartjs-2';

class EditLinkedinAdgroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            adgroup: {},
            updated: {},
            client: {},
            locations: [],
            currency: "",
            audience: [],
            reach: {},
            tabs: [
                { id: 1, name: "Basic", value: "basic" },
                { id: 2, name: "Locations", value: "location" },
                { id: 3, name: "Audience", value: "audience" },
                { id: 5, name: "Placements", value: "placements" },
                { id: 4, name: "Conversion tracking", value: "conversion" }
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            loader: false,
            audience_network: { id: 1, name: "Yes", value: true },
            iab_categories_options: [],
            loading_iab_categories: false,
            iab_category: { id: 0, name: "Click here", value: "" },
            gender_options: [
                {
                    id: 1,
                    name: "All gender",
                    included: true
                },
                {
                    id: 2,
                    name: "Female",
                    facetUrn: "urn:li:adTargetingFacet:genders",
                    value: "urn:li:gender:FEMALE",
                    included: false
                },
                {
                    id: 3,
                    name: "Male",
                    facetUrn: "urn:li:adTargetingFacet:genders",
                    value: "urn:li:gender:MALE",
                    included: false
                },
            ],
            gender: [],
            age: [],
            age_options: [
                {
                    id: 1,
                    name: "All ages",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "ALL",
                    included: true
                },
                {
                    id: 2,
                    name: "18 to 24",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "urn:li:ageRange:(18,24)",
                    included: false
                },
                {
                    id: 3,
                    name: "25 to 34",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "urn:li:ageRange:(25,34)",
                    included: false
                },
                {
                    id: 4,
                    name: "35 to 54",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "urn:li:ageRange:(35,54)",
                    included: false
                },
                {
                    id: 5,
                    name: "55+",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "urn:li:ageRange:(55,2147483647)",
                    included: false
                }
            ],
            company_size_options: [
                {
                    id: 1,
                    name: "Myself Only",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(1,1)",
                    urn: "urn:li:staffCountRange:(1,1)",
                    included: false
                },
                {
                    id: 2,
                    name: "2-10 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(2,10)",
                    urn: "urn:li:staffCountRange:(2,10)",
                    included: false
                },
                {
                    id: 3,
                    name: "11-50 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(11,50)",
                    urn: "urn:li:staffCountRange:(11,50)",
                    included: false
                },
                {
                    id: 4,
                    name: "51-200 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(51,200)",
                    urn: "urn:li:staffCountRange:(51,200)",
                    included: false
                },
                {
                    id: 5,
                    name: "201-500 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(201,500)",
                    urn: "urn:li:staffCountRange:(201,500)",
                    included: false
                },
                {
                    id: 6,
                    name: "501-1000 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(501,1000)",
                    urn: "urn:li:staffCountRange:(501,1000)",
                    included: false
                },
                {
                    id: 7,
                    name: "1001-5000 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(1001,5000)",
                    urn: "urn:li:staffCountRange:(1001,5000)",
                    included: false
                },
                {
                    id: 8,
                    name: "5001-10,000 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(5001,10000)",
                    urn: "urn:li:staffCountRange:(5001,10000)",
                    included: false
                },
                {
                    id: 9,
                    name: "10,001+ employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(10001,2147483647)",
                    urn: "urn:li:staffCountRange:(10001,2147483647)",
                    included: false
                }

            ],
            targeting_types: [
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company follower of",
                    value: "followedCompanies",
                    URN: "urn:li:adTargetingFacet:followedCompanies",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company names",
                    value: "employers",
                    URN: "urn:li:adTargetingFacet:employers",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company Connections",
                    value: "firstDegreeConnectionCompanies",
                    URN: "urn:li:adTargetingFacet:firstDegreeConnectionCompanies",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company industries",
                    value: "industries",
                    URN: "urn:li:adTargetingFacet:industries",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company growth rate",
                    value: "growthRate",
                    URN: "urn:li:adTargetingFacet:growthRate",
                    TYPEAHEAD: false
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company categories",
                    value: "companyCategory",
                    URN: "urn:li:adTargetingFacet:companyCategory",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company size",
                    value: "staffCountRanges",
                    URN: "urn:li:adTargetingFacet:staffCountRanges",
                    TYPEAHEAD: false
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education Degrees",
                    value: "degrees",
                    URN: "urn:li:adTargetingFacet:degrees",
                    TYPEAHEAD: true
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education fields of study",
                    value: "fieldsOfStudy",
                    URN: "urn:li:adTargetingFacet:fieldsOfStudy",
                    TYPEAHEAD: true
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education member schools",
                    value: "schools",
                    URN: "urn:li:adTargetingFacet:schools",
                    TYPEAHEAD: true
                },

                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job experience functions",
                    value: "jobFunctions",
                    URN: "urn:li:adTargetingFacet:jobFunctions",
                    TYPEAHEAD: false
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job titles",
                    value: "titlesPast",
                    URN: "urn:li:adTargetingFacet:titlesPast",
                    TYPEAHEAD: true
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job seniorities",
                    value: "seniorities",
                    URN: "urn:li:adTargetingFacet:seniorities",
                    TYPEAHEAD: false
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Member skills",
                    value: "skills",
                    URN: "urn:li:adTargetingFacet:skills",
                    TYPEAHEAD: true
                },
                { disabled: true, name: "staffCountRanges", URN: "urn:li:adTargetingFacet:staffCountRanges", TYPEAHEAD: false },
                { disabled: true, name: "titles", URN: "urn:li:adTargetingFacet:titles", TYPEAHEAD: true },
                { disabled: true, name: "groups", URN: "urn:li:adTargetingFacet:groups", TYPEAHEAD: true },
                { disabled: true, name: "employersPast", URN: "urn:li:adTargetingFacet:employersPast", TYPEAHEAD: true },
                { disabled: true, name: "yearsOfExperienceRanges", URN: "urn:li:adTargetingFacet:yearsOfExperienceRanges", TYPEAHEAD: false },
                { disabled: true, name: "titlesAll", URN: "urn:li:adTargetingFacet:titlesAll", TYPEAHEAD: true },
                { disabled: true, name: "Company names", URN: "urn:li:adTargetingFacet:employers", TYPEAHEAD: true },
                { disabled: true, name: "employersAll", URN: "urn:li:adTargetingFacet:employersAll", TYPEAHEAD: true },
                { disabled: true, name: "interfaceLocales", URN: "urn:li:adTargetingFacet:interfaceLocales", TYPEAHEAD: false },
                { disabled: true, name: "interests", URN: "urn:li:adTargetingFacet:interests", TYPEAHEAD: true },
                { disabled: true, name: "memberBehaviors", URN: "urn:li:adTargetingFacet:memberBehaviors", TYPEAHEAD: true },
            ],
            selected_type: {},
            targeting_options: [],
            search_targeting_options: [],
            targeting_type: { id: 1, name: "Included", value: true },
            location_type: { id: 1, name: "Included", value: true },
            location_options: [],
            included_criterias: [],
            excluded_criterias: [],
            conversion_options: [],
            saved_audiences: [],
            bidding: {},
            bidding_options: [],
            adgroup_types: [
                { id: 1, name: 'Image ads', value: "STANDARD_UPDATE" },
                { id: 2, name: 'Video ads', value: "SINGLE_VIDEO" },
                { id: 3, name: 'Carousel ads', value: "CAROUSEL" }
            ],
            objectives: [
                {
                    name: "Website visits",
                    value: "WEBSITE_VISIT",
                    optimization_goals: [
                        {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPM",
                                    set_cost: true
                                }
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ],
                        }
                    ]
                },
                {
                    name: "Engagement",
                    value: "ENGAGEMENT",
                    optimization_goals: [
                        {
                            name: "Engagement clicks (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPC",
                                    set_cost: true
                                }
                            ],
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ],
                        }
                    ]
                },
                {
                    name: "Video views",
                    value: "VIDEO_VIEW",
                    optimization_goals: [
                        {
                            name: "Video views",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_VIDEO_VIEW",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Target cost per video view",
                                    value: "TARGET_COST_PER_VIDEO_VIEW",
                                    cost_type: "CPV",
                                    set_cost: true
                                },
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ],
                        }
                    ]
                },
                {
                    name: "Lead generation",
                    value: "LEAD_GENERATION",
                    optimization_goals: [
                        {
                            name: "Leads (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Max delivery (recommended)",
                                    value: "MAX_LEAD",
                                    cost_type: "CPC",
                                    set_cost: false
                                }
                            ]
                        },
                        {
                            name: "Click",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPC",
                                    set_cost: true
                                },
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ]
                        }
                    ]
                },
                {
                    name: "Website conversions",
                    value: "WEBSITE_CONVERSION",
                    optimization_goals: [
                        {
                            name: "Website conversion (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Max delivery (recommended)",
                                    value: "MAX_CONVERSION",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ]
                        },
                        {
                            name: "Landing page click",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPC",
                                    set_cost: true
                                },
                            ],
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ]
                        }
                    ]
                },
                {
                    name: "Job applicants",
                    value: "JOB_APPLICANT",
                    optimization_goals: [
                        {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum Delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Manual bidding",
                                    value: "NONE",
                                    cost_type: "CPC",
                                    set_cost: true
                                },
                            ],
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ]
                        }
                    ]
                },
                {
                    name: "Brand awareness",
                    value: "BRAND_AWARENESS",
                    optimization_goals: [
                        {
                            name: "Reach (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum Delivery (recommended)",
                                    value: "MAX_REACH",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Max Delivery (recommended)",
                                    value: "MAX_IMPRESSION",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_IMPRESSION",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ]
                        }
                    ]
                },

            ],
            languages: languagesLinkedin
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            adgroup: this.props.adgroup,
            client: this.props.client,
            wizard: this.props.wizard
        });
        if (!this.state.wizard) {
            this.functions.targetingCriterias();
            this.functions.locations();
            this.functions.audience();
            this.functions.bidding();
        }
        this.functions.listSavedAudiences();
        this.functions.listIABCategories();
        this.functions.conversions();
        this.functions.listIABCategories();
        if (this.props.updated) {
            await this.promisedSetState({
                updated: this.props.updated
            });

            if (this.state.updated.scheduler) {
                this.state.tabs.push({ id: 11, name: "Scheduler", value: "schedule" });
                if (this.state.updated.scheduler.planer) {
                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                    } else {
                        this.state.updated.daily_budget = 0;
                    }
                }
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    updated: this.state.updated
                });
            }
        }
        this.functions.ages();
        this.functions.gender();
        this.functions.estimateAudience();
        if (this.state.client.channels.filter((item) => { return item.value === "linkedin" }).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => { return item.value === "linkedin" })[0].currency
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        /*
        this.promisedSetState({
            adgroup: nextProps.adgroup,
            client: nextProps.client,
            wizard: nextProps.wizard
        });
        if (nextProps.updated) {
            this.promisedSetState({
                updated: nextProps.updated
            });
        }
        */
    }

    functions = {
        update: async () => {
            if (!this.state.wizard) {
                this.props.onError(false);
                this.props.onSuccess(false);
                this.props.onLoader(true);
                let body = {
                    "patch": {
                        "$set": {
                            name: this.renders.name(),
                            status: this.renders.status().value,
                        }
                    }
                };
                if (this.state.updated.start_date || this.state.updated.end_date) {
                    body.patch["$set"].runSchedule = {
                        start: new Date(this.renders.startDate()).getTime(),
                        end: this.renders.endDate() ? new Date(this.renders.endDate()).getTime() : null
                    }
                }
                if (this.state.updated.daily_budget) {
                    body.patch["$set"].dailyBudget = {
                        currencyCode: this.state.adgroup.dailyBudget.currencyCode,
                        amount: this.renders.budgetDaily()
                    }
                }
                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return item.included }).length > 0) {
                    body.patch["$set"].targetingCriteria = {
                        include: {
                            and: [
                                {
                                    or: {
                                        "urn:li:adTargetingFacet:locations": this.state.updated.locations.filter((item) => { return item.included }).map((item) => { return item.urn })
                                    }
                                }
                            ]
                        }
                    }
                    if (Array.isArray(this.state.updated.audience) && this.state.updated.audience.filter((item) => { return item.included }).length > 0) {
                        let buff = {};
                        this.state.updated.audience.filter((item) => { return item.included }).map((item) => {
                            buff[item.facetUrn] = []
                        });
                        this.state.updated.audience.filter((item) => { return item.included }).map((item) => {
                            buff[item.facetUrn].push(item.urn);
                        });
                        body.patch["$set"].targetingCriteria.include.and.push({ or: buff });
                    }

                }
                let exclude = {}
                if (Array.isArray(this.state.updated.audience) && this.state.updated.audience.filter((item) => { return item.included == false }).length > 0) {
                    this.state.updated.audience.filter((item) => { return item.included == false }).map((item) => {
                        exclude[item.facetUrn] = []
                    });
                    this.state.updated.audience.filter((item) => { return item.included == false }).map((item) => {
                        exclude[item.facetUrn].push(item.urn);
                    });
                }
                if (Array.isArray(this.state.updated.locations) && this.state.updated.locations.filter((item) => { return item.included == false }).length > 0) {
                    exclude["urn:li:adTargetingFacet:locations"] = this.state.updated.locations.filter((item) => { return item.included == false }).map((item) => { return item.urn })
                }
                if (Object.keys(exclude).length !== 0) {
                    body.patch["$set"].targetingCriteria.exclude = { or: exclude };
                }
                if (this.state.updated.bidding) {
                    if (this.state.updated.bidding.selected.id === 1) {
                        body.patch["$set"].costType = this.state.updated.bidding.costType.auto;
                        body.patch["$set"].optimizationTargetType = this.state.updated.bidding.strategy.auto;
                    } else {
                        body.patch["$set"].costType = this.state.updated.bidding.costType.manual;
                        body.patch["$set"].optimizationTargetType = this.state.updated.bidding.strategy.manual;
                    }
                    if (this.state.updated.target_cost) {
                        body.patch["$set"].unitCost = {
                            amount: this.renders.target_cost().toString(),
                            currencyCode: this.state.adgroup.unitCost.currencyCode
                        };
                    }
                }
                if (this.state.updated.conversion_options) {
                    let delete_url_query = '';
                    let create_url_query = '';
                    if (Array.isArray(this.state.updated.conversion_options) && this.state.updated.conversion_options.filter((item) => { return item.selected }).length > 0) {
                        create_url_query = "ids=List(" + this.state.updated.conversion_options.filter((item) => { return item.selected }).map((item) => { return "(campaign:urn%3Ali%3AsponsoredCampaign%3A" + this.state.adgroup.id + ",conversion:urn%3Alla%3AllaPartnerConversion%3A" + item.id + ")" }).toString() + ")";
                        let entity = {
                            entities: {}
                        };
                        this.state.updated.conversion_options.filter((item) => { return item.selected }).map((item) => {
                            let buff = "(campaign:urn%3Ali%3AsponsoredCampaign%3A" + this.state.adgroup.id + ",conversion:urn%3Alla%3AllaPartnerConversion%3A" + item.id + ")";
                            entity.entities[buff] = {
                                campaign: "urn:li:sponsoredCampaign:" + this.state.adgroup.id,
                                conversion: "urn:lla:llaPartnerConversion:" + item.id
                            };
                        });

                        let creating = await this.calls.createConversions({ url: create_url_query, body: entity });
                    }
                    if (Array.isArray(this.state.updated.conversion_options) && this.state.updated.conversion_options.filter((item) => { return item.selected == false }).length > 0) {
                        delete_url_query = "ids=List(" + this.state.updated.conversion_options.filter((item) => { return item.selected == false }).map((item) => { return "(campaign:urn%3Ali%3AsponsoredCampaign%3A" + this.state.adgroup.id + ",conversion:urn%3Alla%3AllaPartnerConversion%3A" + item.id + ")" }).toString() + ")";
                        let deleting = await this.calls.deleteConversions({ url: delete_url_query });
                    }

                }
                try {
                    let response = await this.calls.update(body);
                    //let updated_camaign = { ...this.state.campaign, ...response.data };
                    this.props.onSuccess("Adgroup updated");
                    //this.props.onUpdate(updated_camaign);
                } catch (error) {
                    this.props.onError(error.body ? error.body.message : "Something went wrong");
                }
                this.props.onLoader(false);
            } else {
                console.log(this.state.updated);
                this.props.onUpdate(this.state.updated);
            }
        },
        ages: async () => {
            try {
                if (this.state.updated.age && this.state.updated.age.length > 0) {
                    this.state.age_options = this.state.age_options.map((item) => {
                        if (this.state.updated.age.filter((age) => { return age.id === item.id && age.included }).length > 0) {
                            item.included = true;
                        } else {
                            item.included = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ age_options: this.state.age_options });
                }
            } catch (error) {

            }
        },
        gender: async () => {
            try {
                if (this.state.updated.gender) {
                    this.state.gender_options = this.state.gender_options.map((item) => {
                        if (item.value !== this.state.updated.gender.value) {
                            item.included = false;
                        } else {
                            item.included = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ gender_options: this.state.gender_options });
                }
            } catch (error) {

            }
        },
        locations: () => {
            let locations = [];
            if (Array.isArray(this.state.included_criterias)) {
                this.state.included_criterias.map((item) => {
                    if (item.parent == "Locations") {
                        locations.push({ id: Math.floor((Math.random() * 9999999999) + 1), included: true, name: item.name, facetUrn: item.facetUrn, urn: item.urn })
                    }
                })
            }
            if (Array.isArray(this.state.excluded_criterias)) {
                this.state.excluded_criterias.map((item) => {
                    if (item.parent == "Locations") {
                        locations.push({ id: Math.floor((Math.random() * 9999999999) + 1), included: false, name: item.name, facetUrn: item.facetUrn, urn: item.urn })
                    }
                })
            }
            this.promisedSetState({ locations: locations });
        },
        listIABCategories: async () => {
            try {
                this.promisedSetState({ loading_iab_categories: true });
                let response = await this.calls.listIABCategories();
                this.promisedSetState({ loading_iab_categories: false, iab_categories_options: response.data });
            } catch (error) {
                this.promisedSetState({
                    iab_categories_options: []
                })
            }
        },
        audience: () => {
            let audience = [];
            if (Array.isArray(this.state.included_criterias)) {
                this.state.included_criterias.map((item) => {
                    if (item.parent !== "Locations" && item.parent !== "Interface Locales") {
                        audience.push({ id: Math.floor((Math.random() * 9999999999) + 1), included: true, name: item.name, facetUrn: item.facetUrn, urn: item.urn, parent: item.parent })
                    }
                })
            }
            if (Array.isArray(this.state.excluded_criterias)) {
                this.state.excluded_criterias.map((item) => {
                    if (item.parent !== "Locations" && item.parent !== "Interface Locales") {
                        audience.push({ id: Math.floor((Math.random() * 9999999999) + 1), included: false, name: item.name, facetUrn: item.facetUrn, urn: item.urn, parent: item.parent })
                    }
                })
            }
            this.promisedSetState({ audience: audience });
        },
        estimateAudience: async () => {
            try {
                let estimate_audience = await this.calls.estimateAudience({
                    audience: this.renders.audience(),
                    gender: this.renders.gender(),
                    age: this.renders.age(),
                    locations: this.renders.locations(),
                    language: this.renders.language(),
                });
                let reach = {};
                if (estimate_audience.data[0].total) {
                    reach = {
                        users_lower_bound: estimate_audience.data[0].total,
                        users_upper_bound: estimate_audience.data[0].total,
                        estimate_ready: true
                    }
                }
                if (this.props.onReach) {
                    this.props.onReach(reach ? reach : {});
                }
                //await this.promisedSetState({ estimate_audience: estimate_audience[0].total });
            } catch (error) {
                console.log("ERROE", error)
                if (this.props.onReach) {
                    this.props.onReach({});
                }
            }
        },
        targetingCriterias: async () => {
            if (this.state.adgroup.targetingCriteria) {
                let buff = {};
                this.state.adgroup.targetingCriteria.include.and.map((item) => {
                    Object.keys(item.or).map((key) => {
                        buff[key] = item.or[key];
                    });
                });
                let body = {
                    "urns": buff
                };
                try {
                    let response = await this.calls.selectedCriterias(body);
                    this.promisedSetState({ included_criterias: response.data });
                } catch (error) {

                }
                if (this.state.adgroup.targetingCriteria.exclude) {
                    try {
                        let response = await this.calls.selectedCriterias({ "urns": this.state.adgroup.targetingCriteria.exclude.or });
                        this.promisedSetState({ excluded_criterias: response.data });
                    } catch (error) {

                    }
                }
            }
        },
        bidding: async () => {
            if (Array.isArray(this.state.bidding_goal)) {
                let bid = this.state.bidding_goal.filter((item) => { return item.objective_type === this.state.adgroup.objectiveType }).map((item) => {
                    return item.options.filter((item2) => {
                        return ((item2.costType.auto === this.state.adgroup.costType && item2.strategy.auto === this.state.adgroup.optimizationTargetType) || (item2.costType.manual === this.state.adgroup.costType && item2.strategy.manual === this.state.adgroup.optimizationTargetType))
                    });
                })[0][0];
                if (bid.costType.auto === this.state.adgroup.costType && bid.strategy.auto === this.state.adgroup.optimizationTargetType) {
                    bid.selected = { id: 1, name: "Maximum delivery" };
                } else bid.selected = { id: 2, name: "Manual" };

                this.promisedSetState({ bidding: bid });
                let options = [];
                if (bid.auto === true) options.push({ id: 1, name: "Maximum delivery" });
                if (bid.manual === true) options.push({ id: 2, name: "Manual" });
                this.promisedSetState({ bidding_options: options });
            }
        },
        searchLocations: async () => {
            try {
                this.promisedSetState({ loading_locations: true });
                let response = await this.calls.locations();
                this.promisedSetState({ loading_locations: false, location_options: response.data[0] });
            } catch (error) {
                this.promisedSetState({
                    location_options: []
                })
            }
        },
        listSavedAudiences: async () => {
            try {
                let response = await this.calls.listSavedAudiences();
                this.promisedSetState({ saved_audiences: response.data });
            } catch (error) {

            }
        },
        listIABCategories: async () => {
            try {
                this.promisedSetState({ loading_iab_categories: true });
                let response = await this.calls.listIABCategories();
                this.promisedSetState({ loading_iab_categories: false, iab_categories_options: response.data });
            } catch (error) {
                this.promisedSetState({
                    iab_categories_options: []
                })
            }
        },
        conversions: async () => {
            try {
                this.promisedSetState({ loading_conversions: true });
                let response = await this.calls.conversions();
                //this.state.updated.conversion_options = response.data;
                this.promisedSetState({ loading_conversions: false, conversion_options: response.data });
            } catch (error) {
                this.promisedSetState({
                    conversion_options: []
                })
            }
        },
        searchTargeting: async () => {
            try {
                this.promisedSetState({ loading_targeting: true });
                let response = await this.calls.targeting();
                if (this.renders.audience().length > 0 && this.renders.audience().slice(-1)[0] !== null) {
                    this.renders.audience().slice(-1).map((item) => {
                        response.data = response.data.filter((buff) => { return buff.urn !== item.urn })
                    })
                }

                await this.promisedSetState({ loading_targeting: false, targeting_options: response.data });
            } catch (error) {
                this.promisedSetState({
                    targeting_options: [],
                    loading_targeting: false
                })
            }
        }
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/updateAdgroup?client=" + this.state.client.id + "&id=" + this.state.adgroup.id;
            return apiRegister.call(options, url);
        },
        estimateAudience: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/getEstimateAudience?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        locations: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listLocations?client=" + this.state.client.id + "&search=" + this.state.search_locations;
            return apiRegister.call(options, url);
        },
        listSavedAudiences: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listSavedAudience?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listIABCategories: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listIABCategories?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        targeting: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listTargeting?client=" + this.state.client.id + (this.state.updated.selected_type.TYPEAHEAD ? "&search=" + this.state.search_targeting : "") + "&urn=" + this.state.updated.selected_type.URN;
            return apiRegister.call(options, url);
        },
        selectedCriterias: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/getUrns?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        conversions: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = "";
            if (this.state.adgroup.id) {
                url = apiRegister.url.api + "/v3/linkedin/listConversions?client=" + this.state.client.id + "&id=" + this.state.adgroup.account + "&campaign_id=" + this.state.adgroup.id;
            } else {
                url = apiRegister.url.api + "/v3/linkedin/listConversions?client=" + this.state.client.id;
            }
            return apiRegister.call(options, url);
        },
        createConversions: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/createConversions?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        deleteConversions: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/deleteConversions?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        adgroupType: () => {
            try {
                if ("adgroup_type" in this.state.updated) {
                    return this.state.updated.adgroup_type;
                } else {
                    return { id: 0, name: "Click here ..." }
                }
            } catch (error) {
                return { id: 0, name: "Click here ..." }
            }
        },
        language: () => {
            try {
                if ("language" in this.state.updated) {
                    return this.state.updated.language;
                } else {
                    return { id: 0, name: "Click here ..." }
                }
            } catch (error) {
                return { id: 0, name: "Click here ..." }
            }
        },
        locationType: () => {
            try {
                if ("location_type" in this.state.updated) {
                    return this.state.updated.location_type;
                } else {
                    return { id: 1, name: "Recent or permanent", value: "recent" }
                }
            } catch (error) {
                return { id: 1, name: "Recent or permanent", value: "recent" }
            }
        },
        adgroupBudget: () => {
            try {
                if ("adgroup_budget" in this.state.updated) {
                    return this.state.updated.adgroup_budget;
                } else {
                    return { id: 1, name: 'Daily budget', value: "daily" }
                }
            } catch (error) {
                return { id: 1, name: 'Daily budget', value: "daily" }
            }
        },
        objective: () => {
            try {
                if ('objective' in this.state.updated) {
                    return this.state.updated.objective;
                } else {
                    return { id: 0, name: "Click here ..." };
                }
            } catch (error) {
                return { id: 0, name: "Click here ..." };
            }
        },
        optimizationGoal: () => {
            try {
                if ('optimization_goal' in this.state.updated) {
                    return this.state.updated.optimization_goal;
                } else {
                    return { id: 0, name: "Click here ..." };
                }
            } catch (error) {
                return { id: 0, name: "Click here ..." };
            }
        },
        biddingStrategy: () => {
            try {
                if ('bidding_strategy' in this.state.updated) {
                    return this.state.updated.bidding_strategy;
                } else {
                    return { id: 0, name: "Click here ..." };
                }
            } catch (error) {
                return { id: 0, name: "Click here ..." };
            }
        },
        conversion: () => {
            try {
                if ('conversion_options' in this.state.updated) {
                    return this.state.updated.conversion_options;
                } else {
                    return this.state.conversion_options;
                }
            } catch (error) {
                return "";
            }
        },
        locations: () => {
            try {
                if ('locations' in this.state.updated) {
                    return this.state.updated.locations;
                } else {
                    return this.state.locations;
                }
            } catch (error) {
                return "";
            }
        },
        audience_network: () => {
            try {
                if ('audience_network' in this.state.updated) {
                    return this.state.updated.audience_network;
                } else {
                    return this.state.audience_network;
                }
            } catch (error) {
                return null;
            }
        },
        selected_iab_categories: () => {
            try {
                if ('selected_iab_categories' in this.state.updated) {
                    return this.state.updated.selected_iab_categories;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        audience: () => {
            try {
                if ('audience' in this.state.updated) {
                    return this.state.updated.audience;
                } else {
                    return this.state.audience;
                }
            } catch (error) {
                return "";
            }
        },
        targetCost: () => {
            try {
                if ('target_cost' in this.state.updated) {
                    return this.state.updated.target_cost;
                } else if (this.state.adgroup.unitCost) {
                    return +this.state.adgroup.unitCost.amount;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        bidding_strategy_options: () => {
            try {
                if ('bidding' in this.state.updated) {
                    let options = [];
                    if (this.state.updated.bidding.auto === true) options.push({ id: 1, name: "Maximum delivery" });
                    if (this.state.updated.bidding.manual === true) options.push({ id: 2, name: "Manual" });
                    return options;
                } else {
                    let options = [];
                    if (this.state.bidding.auto === true) options.push({ id: 1, name: "Maximum delivery" });
                    if (this.state.bidding.manual === true) options.push({ id: 2, name: "Manual" });
                    return options;
                }
            } catch (error) {
                return "";
            }
        },
        bidding_strategy: () => {
            try {
                if ('bidding' in this.state.updated) {
                    if (this.state.updated.bidding.selected === "") {
                        if (this.state.updated.bidding.auto === true) {
                            return { id: 1, name: "Maximum delivery" };
                        } else return { id: 2, name: "Manual" };
                    } else return this.state.updated.bidding.selected;
                } else {
                    return this.state.bidding.selected;
                }
            } catch (error) {
                return "";
            }
        },
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.adgroup.name) {
                    return this.state.adgroup.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        selected_type: () => {
            try {
                if ('selected_type' in this.state.updated) {
                    return this.state.updated.selected_type;
                } else {
                    return { name: "Select type" };
                }
            } catch (error) {
                return "";
            }
        },
        age: () => {
            try {
                if ('age' in this.state.updated) {
                    return this.state.updated.age;
                } else if (this.state.adgroup.age) {
                    return this.state.adgroup.age;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        gender: () => {
            try {
                if ('gender' in this.state.updated) {
                    return this.state.updated.gender;
                } else if (this.state.adgroup.gender) {
                    return this.state.adgroup.gender;
                } else {
                    return { id: 1, name: "All gender", included: false };
                }
            } catch (error) {
                return "";
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return moment(this.state.updated.start_date).format("YYYY-MM-DD");
                } else if (this.state.adgroup.runSchedule) {
                    return moment(this.state.adgroup.runSchedule.start).format("YYYY-MM-DD");
                } else {
                    return moment().format("YYYY-MM-DD");
                }
            } catch (error) {
                return moment().format("YYYY-MM-DD");
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date ? moment(this.state.updated.end_date).format("YYYY-MM-DD") : null;
                } else if (this.state.adgroup.runSchedule) {
                    return this.state.adgroup.runSchedule.end ? moment(this.state.adgroup.runSchedule.end).format("YYYY-MM-DD") : null;
                } else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        },
        selected_custom_audience: () => {
            try {
                if ('custom_audience' in this.state.updated) {
                    return this.state.updated.custom_audience;
                } else {
                    return { id: 0, name: "Click here ..." };
                }
            } catch (error) {
                return { id: 0, name: "Click here ..." };
            }
        },
        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.adgroup.status === 'ACTIVE') {
                    return { id: 1, name: 'Active', value: "ACTIVE" }
                } else if (this.state.adgroup.status === 'PAUSED') {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                } else if (this.state.adgroup.status === 'CANCELED') {
                    return { id: 3, name: 'Canceled', value: "CANCELED" }
                } else if (this.state.adgroup.status === 'DRAFT') {
                    return { id: 4, name: 'Draft', value: "DRAFT" }
                } else {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 2, name: 'Paused', value: "PAUSED" }
            }
        },
        budgetTotal: () => {
            try {
                if ('total_budget' in this.state.updated) {
                    return this.state.updated.total_budget;
                } else if (this.state.adgroup.totalBudget) {
                    return +this.state.adgroup.totalBudget.amount;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        budgetDaily: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else if (this.state.adgroup.dailyBudget) {
                    return +this.state.adgroup.dailyBudget.amount;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && (adgroupLinkedin.validate(this.state.updated).budget || adgroupLinkedin.validate(this.state.updated).start_date || adgroupLinkedin.validate(this.state.updated).end_date || adgroupLinkedin.validate(this.state.updated).name)) ||
                                                    (tab.id === 3 && adgroupLinkedin.validate(this.state.updated).language) ||
                                                    (tab.id === 2 && adgroupLinkedin.validate(this.state.updated).location) ||
                                                    (tab.id === 11 && ((this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity) || Object.keys(this.state.updated.scheduler.weekdays).length < 1) && this.state.updated.scheduler.schedule) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*Basic*/}
                    {
                        this.state.tab.id == 1 &&
                        <>
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Name"}
                                    error={adgroupLinkedin.validate(this.state.updated).name}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    label={"Allowed Ad format"}
                                    locked={true}
                                    selected={this.renders.adgroupType()}
                                    options={this.state.adgroup_types}
                                    onChange={(value) => {
                                        /*
                                        this.state.updated.adgroup_type = value;
                                        this.setState({
                                            updated: this.state.updated
                                        });
                                        */
                                    }}
                                />
                            </div>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    label={"Objective"}
                                    locked={true}
                                    selected={this.renders.objective()}
                                    options={this.state.objectives}
                                    onChange={(value) => {
                                        /*
                                        this.state.updated.objective = value;
                                        delete this.state.updated.optimization_goal;
                                        delete this.state.updated.bidding_strategy;
                                        delete this.state.updated.cost_type;
                                        this.setState({
                                            updated: this.state.updated
                                        });
                                        */
                                    }}
                                />
                            </div>
                            {
                                this.renders.objective().id !== 0 &&
                                <div className="col-span-2">
                                    <DropdownTailwind
                                        label={"Optimizaton goal"}
                                        selected={this.renders.optimizationGoal()}
                                        options={Array.isArray(this.renders.objective().optimization_goals) ? this.renders.objective().optimization_goals : []}
                                        onChange={(value) => {
                                            this.state.updated.optimization_goal = value;
                                            if (Array.isArray(value.bidding_strategies) && value.bidding_strategies.length > 0) {
                                                this.state.updated.bidding_strategy = value.bidding_strategies[0];
                                                this.state.updated.cost_type = { value: value.bidding_strategies[0].cost_type };
                                            } else {
                                                delete this.state.updated.bidding_strategy;
                                            }
                                            this.setState({
                                                updated: this.state.updated
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.optimizationGoal().id !== 0 &&
                                <div className={(this.renders.biddingStrategy().set_cost ? "col-span-3" : "col-span-6")}>
                                    <DropdownTailwind
                                        label={"Bidding strategy"}
                                        selected={this.renders.biddingStrategy()}
                                        options={Array.isArray(this.renders.optimizationGoal().bidding_strategies) ? this.renders.optimizationGoal().bidding_strategies : []}
                                        onChange={(value) => {
                                            this.state.updated.bidding_strategy = value;
                                            this.state.updated.cost_type = { value: value.cost_type };
                                            this.setState({
                                                updated: this.state.updated
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.biddingStrategy().set_cost &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Target cost (unit cost)"}
                                        value={this.renders.targetCost()}
                                        onChange={(value) => {
                                            this.state.updated.target_cost = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                !this.state.wizard &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Status"}
                                        selected={this.renders.status()}
                                        options={[
                                            { id: 1, name: 'Active', value: "ACTIVE" },
                                            { id: 2, name: 'Paused', value: "PAUSED" }
                                        ]}
                                        onChange={(value) => {
                                            this.state.updated.status = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Budget"}
                                    selected={this.renders.adgroupBudget()}
                                    locked={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                    options={[
                                        { id: 1, name: 'Daily budget', value: "daily" },
                                        { id: 2, name: 'Total budget', value: "total" },
                                        { id: 3, name: 'Daily and Total budget', value: "daily_total" }
                                    ]}
                                    onChange={(value) => {
                                        this.state.updated.adgroup_budget = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3 grid grid-cols-6 col-gap-4">
                                {
                                    (this.renders.adgroupBudget().id === 1 || this.renders.adgroupBudget().id === 3) &&
                                    <div className={(this.renders.adgroupBudget().id === 1 ? "col-span-6" : "col-span-3")}>
                                        <InputTailwind
                                            error={adgroupLinkedin.validate(this.state.updated).budget}
                                            label={"Daily budget"}
                                            locked={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                            disabled={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                            leftSection={(this.state.currency !== "" ? true : false)}
                                            leftSectionLabel={(this.state.currency)}
                                            value={this.renders.budgetDaily()}
                                            onChange={(value) => {
                                                value = value.replace(/[\D]/g, '')
                                                this.state.updated.daily_budget = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (this.renders.adgroupBudget().id === 2 || this.renders.adgroupBudget().id === 3) &&
                                    <div className={(this.renders.adgroupBudget().id === 2 ? "col-span-6" : "col-span-3")}>
                                        <InputTailwind
                                            error={adgroupLinkedin.validate(this.state.updated).budget}
                                            label={"Total budget"}
                                            leftSection={(this.state.currency !== "" ? true : false)}
                                            leftSectionLabel={(this.state.currency)}
                                            value={this.renders.budgetTotal()}
                                            onChange={(value) => {
                                                value = value.replace(/[\D]/g, '')
                                                this.state.updated.total_budget = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="col-span-6">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3">
                                        <InputDatepickerTailwind
                                            label={"Start date"}
                                            error={adgroupLinkedin.validate(this.state.updated).start_date}
                                            value={this.renders.startDate()}
                                            onChange={(value) => {
                                                this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                                if (this.state.updated.scheduler) {
                                                    this.state.updated.scheduler.start_date = moment(value).format("YYYY-MM-DD");
                                                }
                                                if (this.renders.endDate() && new Date(this.renders.endDate()) < new Date(this.renders.startDate())) {
                                                    this.state.updated.end_date = moment(value).add(1, 'days').format("YYYY-MM-DD");
                                                }
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <InputDatepickerTailwind
                                            label={"End date"}
                                            minDate={this.renders.startDate()}
                                            error={adgroupLinkedin.validate(this.state.updated).end_date}
                                            labelRight={this.renders.endDate() ? "Enabled" : "Disabled"}
                                            placeholder={!this.renders.endDate() ? "No end date" : ""}
                                            disabled={!this.renders.endDate()}
                                            value={this.renders.endDate()}
                                            onChange={(value) => {
                                                this.state.updated.end_date = moment(value).format("YYYY-MM-DD");
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                            onLabelRightClick={(value) => {
                                                if (this.renders.endDate()) {
                                                    this.state.updated.end_date = null;
                                                } else {
                                                    let new_date = moment(this.renders.startDate(), "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                                                    this.state.updated.end_date = new_date;
                                                }
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {/*Location*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className="col-span-4">
                                <DropdownTailwind
                                    error={adgroupLinkedin.validate(this.state.updated).location}
                                    ref="searchDropdown"
                                    rightLabel={true}
                                    rightLabelText={this.state.location_type.value ? "Included" : "Excluded"}
                                    onRightLabelClick={async () => {
                                        await this.promisedSetState({ location_type: !this.state.location_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                    }}
                                    label={"Locations"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={Array.isArray(this.state.location_options) ? this.state.location_options : []}
                                    loadingSearch={this.state.loading_locations}
                                    onChange={(value) => {
                                        let location = null;
                                        if (!this.state.location_type || (this.state.location_type && this.state.location_type.id == 1)) {
                                            value.included = true;
                                        } else {
                                            value.included = false;
                                        }
                                        location = value;
                                        location.id = Math.floor((Math.random() * 9999999999) + 1);
                                        if (!Array.isArray(this.state.updated.locations)) {
                                            this.state.updated.locations = this.state.locations;
                                        }
                                        if (this.state.updated.locations.filter((item) => { return item.urn === location.urn }).length < 1) {
                                            this.state.updated.locations.unshift(location);
                                        }
                                        this.setState({
                                            updated: this.state.updated
                                        });
                                        this.functions.estimateAudience();
                                    }}
                                    onSearch={async (value) => {
                                        await this.promisedSetState({ search_locations: value });
                                        this.functions.searchLocations();
                                    }}
                                />
                            </div>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    label={"Location type"}
                                    selected={this.renders.locationType()}
                                    options={[
                                        { id: 1, name: 'Recent or permanent', value: "recent" },
                                        { id: 2, name: 'Permanent', value: "permanent" },

                                    ]}
                                    onChange={(value) => {
                                        this.state.updated.location_type = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.locations().length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.renders.locations().sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            let included_amount = this.renders.locations().filter((item) => { return item.included }).length;
                                            let excluded_amount = this.renders.locations().filter((item) => { return !item.included }).length;
                                            return (
                                                <Fragment>
                                                    {
                                                        index == 0 && included_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Included
                                                        </div>
                                                    }
                                                    {
                                                        (included_amount == index) && excluded_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Excluded
                                                        </div>
                                                    }
                                                    <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <LocationMarkerIcon onClick={async () => {

                                                        }} className={(item.included ? "text-indigo-500" : "text-red-500") + " h-5 w-5 mr-1"} aria-hidden="true" />
                                                        {
                                                            !item.custom &&
                                                            <div className="flex flex-1">{item.name}</div>
                                                        }
                                                        <button
                                                            onClick={() => {
                                                                this.state.updated.locations = this.renders.locations().filter((location) => {
                                                                    return location.id !== item.id;
                                                                })
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.estimateAudience();
                                                            }}
                                                            type="button"
                                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove location</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </>
                    }
                    {/*Audience targeting*/}
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    error={adgroupLinkedin.validate(this.state.updated).language}
                                    label={"Language"}
                                    selected={this.renders.language()}
                                    options={this.state.languages}
                                    onChange={(value) => {
                                        this.state.updated.language = value;
                                        this.setState({
                                            updated: this.state.updated
                                        });
                                        this.functions.estimateAudience();
                                    }}
                                />
                            </div>
                            <div className="col-span-6">
                                <div className="text-xs font-medium text-gray-700 mb-1">
                                    Gender
                                </div>
                                <div className="flex flex-row flex-wrap">
                                    {
                                        this.state.gender_options.map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    item.included = true;
                                                    this.state.updated.gender = item;
                                                    this.state.gender_options = this.state.gender_options.map((inner_item) => {
                                                        if (inner_item.id !== item.id) {
                                                            inner_item.included = false;
                                                        } else {
                                                            inner_item.included = true;
                                                        }
                                                        return inner_item;
                                                    })
                                                    this.setState({
                                                        updated: this.state.updated,
                                                        gender_options: this.state.gender_options
                                                    })
                                                    this.functions.estimateAudience();

                                                }} className={(item.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{item.name}</div>
                                                    <div className="ml-3">
                                                        <div className={(item.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(item.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="col-span-6">
                                <div className="text-xs font-medium text-gray-700 mb-1">
                                    Ages
                                </div>
                                <div className="flex flex-row flex-wrap">
                                    {
                                        this.state.age_options.map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    //item.selected = !item.selected;
                                                    let buff = !item.included;

                                                    if (item.id === 1) {
                                                        this.state.updated.age = [item];
                                                        this.state.age_options = this.state.age_options.map((inner_item) => {
                                                            if (inner_item.id !== item.id) {
                                                                inner_item.included = false;
                                                            } else {
                                                                inner_item.included = true;
                                                            }
                                                            return inner_item;
                                                        })
                                                    } else {
                                                        if (!Array.isArray(this.state.updated.ages)) {
                                                            this.state.updated.age = [];
                                                        }
                                                        this.state.age_options = this.state.age_options.map((inner_item) => {
                                                            if (inner_item.id === 1) {
                                                                inner_item.included = false;
                                                            } else if (inner_item.id === item.id) {
                                                                if (this.state.age_options.filter((inner_item) => { return inner_item.included; }).length === 1 && !buff) {
                                                                } else {
                                                                    inner_item.included = buff;
                                                                }
                                                            }
                                                            return inner_item;
                                                        })
                                                        this.state.updated.age = this.state.age_options.filter((inner_item) => {
                                                            return inner_item.id !== 1;
                                                        })
                                                        console.log("buff", this.state.age_options)
                                                        //this.state.updated.ages.push(item);
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated,
                                                        age_options: this.state.age_options
                                                    })
                                                    this.functions.estimateAudience();

                                                }} className={(item.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{item.name}</div>
                                                    <div className="ml-3">
                                                        <div className={(item.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(item.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Saved Audience"}
                                    selected={this.renders.selected_custom_audience()}
                                    options={this.state.saved_audiences}
                                    onChange={async (value) => {
                                        this.state.updated.custom_audience = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated,
                                        });
                                        if (value.audience) {
                                            this.state.updated.audience = value.audience;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }
                                    }}
                                />
                            </div>

                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Type"}
                                    searchInput={true}
                                    selected={this.renders.selected_type()}
                                    options={this.state.targeting_types.filter((item) => { return !item.disabled }).map((item) => {
                                        item.id = item.value;
                                        return item;
                                    })}
                                    onChange={async (value) => {
                                        this.state.updated.selected_type = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated,
                                            search_targeting: "",
                                            targeting_options: []
                                        });
                                        if (value.value === "staffCountRanges") {
                                            await this.promisedSetState({
                                                targeting_options: this.state.company_size_options
                                            });
                                        } else if (value.value === "followedCompanies") {
                                            if (this.state.client && Array.isArray(this.state.client.channels) && this.state.client.channels.filter((item) => { return item.value === "linkedin" && item.org_id }).length > 0) {
                                                await this.promisedSetState({
                                                    targeting_options: [{
                                                        name: this.state.client.name,
                                                        value: {
                                                            segmentUrn: "urn:li:organization:" + this.state.client.channels.filter((item) => { return item.value === "linkedin" && item.org_id })[0].org_id,
                                                        },
                                                        facetUrn: "urn:li:adTargetingFacet:followedCompanies",
                                                        urn: "urn:li:organization:" + this.state.client.channels.filter((item) => { return item.value === "linkedin" && item.org_id })[0].org_id,
                                                    }]
                                                });
                                            }

                                        }
                                        this.refs.searchDropdown.functions.resetSearch();
                                        if (!value.TYPEAHEAD && value.value !== "staffCountRanges") {
                                            this.functions.searchTargeting();
                                        }
                                    }}
                                />
                            </div>

                            {
                                this.state.updated.selected_type &&
                                <div className="col-span-6">
                                    <div className='flex'>
                                        <div className='mx-4 self-center'>
                                            <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                        </div>
                                        <div className='flex-col flex-1'>
                                            <DropdownTailwind
                                                ref="searchDropdown"
                                                rightLabel={true}
                                                rightLabelText={this.state.targeting_type.value ? "Included" : "Excluded"}
                                                onRightLabelClick={async () => {
                                                    await this.promisedSetState({ targeting_type: !this.state.targeting_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                                }}
                                                label={"Audience"}
                                                searchInput={true}
                                                loader={this.state.loading_targeting}
                                                selected={{ name: "Search ..." }}
                                                options={Array.isArray(this.state.targeting_options) ? this.state.targeting_options : []}
                                                loadingSearch={this.state.loading_targeting}
                                                onChange={(value) => {
                                                    let audience = null;
                                                    if (!this.state.targeting_type || (this.state.targeting_type && this.state.targeting_type.id == 1)) {
                                                        value.included = true;
                                                    } else {
                                                        value.included = false;
                                                    }
                                                    audience = value;
                                                    audience.id = Math.floor((Math.random() * 9999999999) + 1);
                                                    audience.parent = this.state.updated.selected_type.name;
                                                    if (!Array.isArray(this.state.updated.audience) || this.state.updated.audience.length === 0) {
                                                        this.state.updated.audience = this.state.audience;
                                                        this.state.updated.audience.push([]);
                                                    }
                                                    if (this.state.updated.audience[this.state.updated.audience.length - 1].filter((item) => { return item.urn === audience.urn }).length === 0) {
                                                        this.state.updated.audience[this.state.updated.audience.length - 1].push(audience);
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated,
                                                        targeting_options: this.state.targeting_options
                                                    });
                                                    this.functions.estimateAudience();
                                                }}
                                                onSearch={async (value) => {
                                                    await this.promisedSetState({ search_targeting: value });
                                                    if (this.state.updated.selected_type.TYPEAHEAD && this.state.updated.selected_type.value !== "followedCompanies") {
                                                        this.functions.searchTargeting();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                <div onClick={() => {

                                    if (!Array.isArray(this.state.updated.audience)) {
                                        this.state.updated.audience = this.state.audience;
                                    }
                                    if (this.state.updated.audience.slice(-1)[0].length > 0) {
                                        this.state.updated.audience.push([]);
                                    }

                                    this.setState({
                                        updated: this.state.updated
                                    });

                                }} className="cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                                    Add "AND" selector
                                </div>
                            }
                            {
                                this.renders.audience().length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.renders.audience().map((audience_group, audience_index) => {
                                            return (
                                                audience_group &&
                                                <div className="col-span-6 rounded-md">
                                                    {
                                                        audience_index > 0 && audience_group && audience_group !== this.renders.audience().filter((first) => { return first })[0] &&
                                                        <div className=" text-base font-medium text-blue-700 mb-1 m-2">
                                                            AND
                                                        </div>
                                                    }
                                                    {
                                                        audience_group &&
                                                        audience_group.sort(function (x, y) {
                                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                                        }).map((item, index) => {
                                                            let included_amount = audience_group.filter((item) => { return item.included }).length;
                                                            let excluded_amount = audience_group.filter((item) => { return !item.included }).length;
                                                            return (
                                                                <Fragment>
                                                                    {
                                                                        index == 0 && included_amount > 0 &&
                                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                                            Included
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (included_amount == index) && excluded_amount > 0 &&
                                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                                            Excluded
                                                                        </div>
                                                                    }
                                                                    {
                                                                        ((index > 0 && index < included_amount) || (index > included_amount)) &&
                                                                        <div className="text-xs font-medium text-blue-500 mb-1">
                                                                            Or
                                                                        </div>
                                                                    }
                                                                    <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                                        <div className="flex flex-1">{item.parent + "->" + item.name}</div>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.state.updated.audience[audience_index] = audience_group.filter((audience) => {
                                                                                    return audience.id !== item.id;
                                                                                })
                                                                                if (this.state.updated.audience[audience_index].length < 1) {
                                                                                    delete this.state.updated.audience[audience_index];
                                                                                }
                                                                                this.state.updated.audience = this.state.updated.audience.filter((audience) => { return audience });

                                                                                this.setState({
                                                                                    updated: this.state.updated
                                                                                })
                                                                                this.functions.estimateAudience();
                                                                            }}
                                                                            type="button"
                                                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove targeting</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })

                                    }
                                </div>
                            }
                        </>
                    }
                    {/*PLACEMENTS*/}
                    {
                        this.state.tab.id == 5 &&
                        <>
                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-3">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>LinkedIn</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {"Yes"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-3">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>LinkedIn Audience Network</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.audience_network().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.audience_network().value ? true : false}
                                                onSwitch={async (value) => {
                                                    if (this.renders.audience_network().value) {
                                                        this.state.updated.audience_network = { id: 2, name: "No", value: false };
                                                        this.state.updated.selected_iab_categories = [];

                                                    } else {
                                                        this.state.updated.audience_network = { id: 1, name: "Yes", value: true };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.renders.audience_network().value &&
                                    <div className="col-span-6">
                                        <div className='flex'>
                                            <div className='flex-col flex-1'>
                                                <DropdownTailwind
                                                    ref="searchDropdown"
                                                    label={"Exclude categories from the LinkedIn Audience Network (optional)"}
                                                    searchInput={true}
                                                    selected={this.state.iab_category}
                                                    options={Array.isArray(this.state.iab_categories_options) && this.state.iab_categories_options.length > 0 ? this.state.iab_categories_options.filter(item => {
                                                        return !item.parentId
                                                    }) : []}
                                                    onChange={async (value) => {
                                                        this.setState({
                                                            iab_category: value
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className='flex justify-end ml-3 mt-5 items-center'>
                                                <button onClick={async () => {
                                                    if (this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length < this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length) {
                                                        if (!Array.isArray(this.state.updated.selected_iab_categories)) {
                                                            this.state.updated.selected_iab_categories = [];
                                                        }
                                                        this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).map((item) => {
                                                            if (this.renders.selected_iab_categories().filter((inner_item) => { return inner_item.id === item.id }).length === 0) {
                                                                item.included = false;
                                                                this.state.updated.selected_iab_categories.unshift(item);
                                                            }
                                                        })
                                                        //this.state.updated.selected_lifestyles.unshift(value);
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    } else if (this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length === this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length) {
                                                        this.state.updated.selected_iab_categories = this.renders.selected_iab_categories().filter((item) => { return item.parentId !== this.state.iab_category.id })
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }
                                                }} className={(this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length < this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length ? "cursor-pointer bg-purple-500 hover:bg-purple-600 " : (this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length === this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length ? "bg-red-500 hover:bg-red-600 focus:ring-red-500" : "bg-gray-500")) + "  text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-24 py-3 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}>
                                                    {this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length === this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length ? "Remove all" : "Add all"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.iab_category.id !== 0 &&
                                    <div className="col-span-6">
                                        <div className='flex'>
                                            <div className='mx-4 self-center'>
                                                <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                            </div>
                                            <div className='flex-col flex-1'>
                                                <DropdownTailwind
                                                    ref="searchDropdown"
                                                    label={this.state.iab_category.name}
                                                    searchInput={true}
                                                    selected={{ id: 0, name: "Click here", value: "" }}
                                                    options={Array.isArray(this.state.iab_categories_options) && this.state.iab_categories_options.length > 0 ? this.state.iab_categories_options.filter(item => {
                                                        return item.parentId === this.state.iab_category.id
                                                    }) : []}
                                                    onChange={async (value) => {
                                                        value.included = false;
                                                        if (!Array.isArray(this.state.updated.selected_iab_categories)) {
                                                            this.state.updated.selected_iab_categories = [];
                                                        }
                                                        if (this.renders.selected_iab_categories().filter((item) => { return item.id === value.id }).length === 0) this.state.updated.selected_iab_categories.unshift(value);
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.renders.selected_iab_categories().length > 0 &&
                                    <div className="col-span-6 grid grid-cols-6 gap-4">
                                        <div className="col-span-6 text-xs font-medium text-gray-700 mb-1">
                                            Excluded categories
                                        </div>
                                        <div className="col-span-6 flex flex-row flex-wrap">

                                            {
                                                this.renders.selected_iab_categories().map((item) => {
                                                    return (
                                                        <Fragment>
                                                            <div className={"bg-red-100 text-red-700 flex m-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                                <div className="flex flex-1">{item.name}</div>
                                                                <button
                                                                    onClick={() => {
                                                                        this.state.updated.selected_iab_categories = this.renders.selected_iab_categories().filter((inner_item) => { return item.id !== inner_item.id })
                                                                        this.setState({
                                                                            updated: this.state.updated
                                                                        })
                                                                    }}
                                                                    type="button"
                                                                    className={"focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                >
                                                                    <span className="sr-only">Remove</span>
                                                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                }
                            </div>
                        </>
                    }
                    {/*Audience targeting*/}
                    {
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Type"}
                                    searchInput={true}
                                    selected={{ name: "Select conversion" }}
                                    options={this.state.conversion_options}
                                    onChange={(value) => {
                                        this.state.updated.conversion_options = this.renders.conversion().map((conversion) => {
                                            if (value.id == conversion.id) {
                                                conversion.selected = true;
                                            }
                                            return conversion;
                                        })
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            {
                                this.renders.conversion().length > 0 &&
                                <div className="col-span-6">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Selected
                                    </div>
                                    {
                                        this.renders.conversion().filter((item) => { return item.selected }).map((item) => {
                                            return (
                                                <Fragment>
                                                    <div className={"bg-indigo-100 text-indigo-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <div className="flex flex-1">{item.name}</div>
                                                        <button
                                                            onClick={() => {
                                                                this.state.updated.conversion_options = this.renders.conversion().map((conversion) => {
                                                                    if (item.id == conversion.id) {
                                                                        conversion.selected = false;
                                                                    }
                                                                    return conversion;
                                                                })
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                            type="button"
                                                            className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove conversion</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>

                            }
                        </>
                    }


                    {/*SCHEDULER */}

                    {
                        this.state.tab.id == 11 &&
                        <>
                            <div className='col-span-6'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Schedule</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.state.updated.scheduler.schedule ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.updated.scheduler.schedule ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.scheduler.schedule = !this.state.updated.scheduler.schedule;

                                                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer) {
                                                    this.state.updated.adgroup_budget = { id: 1, name: 'Daily budget', value: "daily" };
                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                    } else {
                                                        this.state.updated.daily_budget = 0;
                                                    }
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-span-6 grid grid-cols-12 gap-4 transform transition-all duration-500 ease-in-out overflow-hidden' + (!this.state.updated.scheduler.schedule ? " h-0" : "")}>
                                <div className='col-span-12'>
                                    <div className={"text-xs font-medium mb-1 text-gray-700"}>
                                        Days
                                    </div>
                                    <div className='flex flex-wrap'>
                                        {
                                            [
                                                { name: "Monday", property: "scheduleMonday" },
                                                { name: "Tuesday", property: "scheduleTuesday" },
                                                { name: "Wednesday", property: "scheduleWednesday" },
                                                { name: "Thursday", property: "scheduleThursday" },
                                                { name: "Friday", property: "scheduleFriday" },
                                                { name: "Saturday", property: "scheduleSaturday" },
                                                { name: "Sunday", property: "scheduleSunday" }
                                            ].map((weekday) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (this.state.updated.scheduler.weekdays[weekday.property]) {
                                                            delete (this.state.updated.scheduler.weekdays[weekday.property]);
                                                        } else {
                                                            this.state.updated.scheduler.weekdays[weekday.property] = true;
                                                        }
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })


                                                    }} className={(this.state.updated.scheduler.weekdays[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div>{weekday.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(this.state.updated.scheduler.weekdays[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(this.state.updated.scheduler.weekdays[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-span-12 overflow-hidden ease-in-out transition-all duration-500 grid grid-cols-4 gap-4'>
                                    <div className='col-span-4'>
                                        <div className='flex flex-row overflow-hidden ease-in-out transition-all duration-500'>
                                            <div className={'flex flex-col transition-all transform duration-500 overflow-hidden' + (!this.state.updated.scheduler.planer ? " flex-1" : " pr-4")}>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 min-w-24">
                                                        {(this.state.updated.scheduler.planer ? "Active" : "Disabled")}
                                                    </div>
                                                    <div className={"relative overflow-hidden mr-5 "}>
                                                        <SwitchTailwind
                                                            disabled={false}
                                                            value={this.state.updated.scheduler.planer}
                                                            onSwitch={async () => {
                                                                this.state.updated.scheduler.planer = !this.state.updated.scheduler.planer;

                                                                if (this.state.updated.scheduler.planer) {
                                                                    this.state.updated.adgroup_budget = { id: 1, name: 'Daily budget', value: "daily" };
                                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                                    } else {
                                                                        this.state.updated.daily_budget = 0;
                                                                    }
                                                                }
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "pr-4" : "w-0 hidden")}>
                                                <div className=' pr-2'>
                                                    <InputTailwind
                                                        locked={!this.state.updated.scheduler.planer}
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        label={"Total monthly"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.scheduler.schedule_budget}
                                                        type={"number"}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.schedule_budget = value;

                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <InputTailwind
                                                        locked={true}
                                                        disabled={true}
                                                        label={"Daily budget"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.daily_budget}
                                                        type={"number"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                <div className='w-full'>
                                                    <InputDatepickerTailwind
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        locked={!this.state.updated.scheduler.planer}
                                                        placeholder={moment(this.state.updated.scheduler.start_date).format("MM/DD/YYYY")}
                                                        label={"Start date"}
                                                        value={moment(this.state.updated.scheduler.start_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.start_date = moment(value).format("YYYY-MM-DD");
                                                            if (this.state.updated.scheduler) {
                                                                this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                                            }
                                                            if (this.renders.endDate() && new Date(this.renders.endDate()) < new Date(this.renders.startDate())) {
                                                                this.state.updated.end_date = moment(value).add(1, 'days').format("YYYY-MM-DD");
                                                            }
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.updated.scheduler.order_info && this.state.updated.scheduler.order_info.maxBudgetSubAction &&
                                                <div className={'flex transition-all transform duration-500 overflow-hidden pl-4 ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                    <div className=''>
                                                        <InputTailwind
                                                            locked={true}
                                                            disabled={true}
                                                            label={"Order pacing"}
                                                            value={this.state.updated.scheduler.order_info.maxBudgetSubAction && this.state.updated.scheduler.order_info.maxBudgetSubAction.id ? (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 1 ? "Spend total" : (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 2 ? "Spend pacing" : "Unkown")) : "Unkown"}
                                                        />
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.updated.scheduler.planer && this.state.updated.scheduler.schedule_budget > 0 && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity &&
                                            <div className='flex flex-row border-t mt-4'>
                                                <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                    OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='col-span-4'>
                                        <div className={" mt-4 pt-4" + (this.state.updated.scheduler.planer ? " h-64" : " h-0")}>
                                            <Bar
                                                options={{
                                                    maintainAspectRatio: false,
                                                    interaction: {
                                                        intersect: false,
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                suggestedMin: 0
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={budgetPlannerGraph.main(this.state.updated.scheduler)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }
}

export default EditLinkedinAdgroup;
