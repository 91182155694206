import React, { Component } from "react";
import { ChevronUpIcon, DotsHorizontalIcon } from '@heroicons/react/solid'
import { XIcon, PlayIcon, PauseIcon, RefreshIcon } from '@heroicons/react/outline';

class PreviewFacebookStory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {},
            video: null,
            image: null,
            body: "",
            cta: {},
            adData: {},
            primary: null,
            secondary: null,
            read_more: false,
            for_report: false,
            thumbnail: null,
            show_overlay: false,
            video_ended: false,
            video_playing: false,
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            page: this.props.page,
            video: this.props.video,
            image: this.props.image,
            body: this.props.body,
            cta: this.props.cta,
            adData: this.props.adData,
            colors: this.props.colors,
            for_report: this.props.for_report,
            thumbnail: this.props.thumbnail,
            show_overlay: this.props.show_overlay,
            video_ended: this.props.video_ended,
            video_playing: this.props.video_playing
        })
        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                    this.refs.video.addEventListener('ended', this.functions.videoEnded, false);
                }
            } catch (error) {
                console.log(error)
            }
        }, 200)
        Array.isArray(this.state.colors) && this.state.colors.length > 0 && this.state.colors.map((color, index) => {
            if (index === 0) {
                this.state.primaryColor = color[0]
            }
            else if (index === 1) {
                this.state.secondaryColor = color[0]
            }
        })
        if (!this.state.primaryColor) {
            this.state.primaryColor = "#000000"
        }
        if (!this.state.secondaryColor) {
            this.state.secondaryColor = this.state.primaryColor
        }
        this.setState({
            primary: this.state.primaryColor,
            secondary: this.state.secondaryColor
        })
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            page: nextProps.page,
            video: nextProps.video,
            image: nextProps.image,
            body: nextProps.body,
            cta: nextProps.cta,
            adData: nextProps.adData,
            colors: nextProps.colors,
            for_report: nextProps.for_report,
            thumbnail: nextProps.thumbnail,
            show_overlay: nextProps.show_overlay,
            video_ended: nextProps.video_ended,
            video_playing: nextProps.video_playing
        })
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        },
        handleClickVideo: async () => {
            if (this.refs.video.ended) {
                await this.promisedSetState({ video_ended: true, video_playing: false });
                this.refs.video.currentTime = 0;
                this.refs.video.play();
                await this.promisedSetState({ video_ended: false, video_playing: true });
            } else if (this.refs.video.paused) {
                this.refs.video.play();
                await this.promisedSetState({ video_playing: true });
            } else {
                this.refs.video.pause();
                await this.promisedSetState({ video_playing: false });
            }
        },
        videoEnded: async () => {
            await this.promisedSetState({ video_ended: true, video_playing: false })
        }
    };

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.title !== "" ? this.state.title : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.body !== "" ? this.state.body : "Body ...";
                body = body.replace("{location}", this.state.location);

                const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
                body = body.replace(regex, '<br>');
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }

                return body;
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                let description = this.state.description !== "" ? this.state.description : "";
                description = description.replace("{location}", this.state.location);
                return description
            } catch (e) {
                return ""
            }
        },
        image: () => {
            try {
                return this.state.image
            } catch (e) {
                return ""
            }
        },
        cover: () => {
            try {
                return this.state.facebook_event.cover.source
            } catch (e) {
                return ""
            }
        },
        media: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.props.linkedin) {
                    if (this.state.creatives[0].call_to_action_linkedin.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.state.creatives[0].call_to_action_linkedin.name;
                    }
                } else {
                    if (this.state.cta.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.cta.name;
                    }
                }
            } catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div
                className='flex flex-col justify-center'
            >
                <div
                    style={{
                        height: 568,
                        background: `linear-gradient(${this.state.primary}, ${this.state.secondary})`,
                    }}
                    className={`flex flex-col w-80 justify-center rounded-lg overflow-hidden ${this.state.video && "relative"}`}
                >
                    <div
                        onMouseEnter={async () => {
                            await this.promisedSetState({
                                show_overlay: true
                            });
                        }}
                        onMouseLeave={async () => {
                            await this.promisedSetState({
                                show_overlay: false
                            });
                        }}
                        className="h-full w-full"
                        style={this.state.image || this.state.thumbnail ? {
                            backgroundImage: `url(${this.state.video === null && this.state.thumbnail !== null ? this.state.thumbnail : this.state.image})`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat"
                        } : { background: `linear-gradient(${this.state.primary}, ${this.state.secondary})` }}
                    >
                        {this.state.video &&
                            < div
                                className="absolute flex flex-col justify-center items-center inset-0"
                            >
                                {
                                    this.state.show_overlay &&
                                    <div
                                        className="absolute z-50 h-full w-full flex flex-col justify-center items-center bg-black bg-opacity-0 hover:bg-opacity-25 transition-all duration-300">
                                        <div
                                            onClick={() => this.functions.handleClickVideo()}
                                            className="z-60 flex flex-col justify-center items-center w-12 h-12 rounded-full bg-white opacity-75 cursor-pointer">
                                            {
                                                !this.state.video_playing && !this.state.video_ended &&
                                                <PlayIcon className="text-gray-600 h-12 w-12 stroke-1" />
                                            }
                                            {
                                                this.state.video_playing &&
                                                <PauseIcon className="text-gray-600 h-12 w-12 stroke-1" />
                                            }
                                            {
                                                this.state.video_ended && !this.state.video_playing &&
                                                <RefreshIcon className="text-gray-600 h-10 w-10 stroke-1" />
                                            }
                                        </div>
                                    </div>
                                }
                                <video src={this.state.video} autoplay={false} controls={false} muted className="w-full" ref="video" />
                            </div>
                        }
                        <div className={`flex flex-col h-full w-full justify-between ${this.state.video && "absolute"}`}>
                            <div className="flex flex-row flex-1 p-2">
                                {this.state.page.image &&
                                    <div>
                                        <div
                                            className="h-10 w-10 mr-2"
                                        >
                                            <img src={this.state.page.image}
                                                className="rounded-full"
                                                alt={this.state.page.name}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    !this.state.page.image &&
                                    <div>
                                        <div
                                            className="h-10 w-10 mr-2 border rounded-full"
                                        ></div>
                                    </div>
                                }
                                <div className="flex flex-col flex-1 text-sm text-white">
                                    <div>
                                        {this.state.page.name ? this.state.page.name : "Page Name"}
                                    </div>
                                    <div className="text-xxs">Sponsored</div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex text-white">
                                        <DotsHorizontalIcon className='w-4 mr-3' />
                                        <XIcon className='w-5' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={`px-2 text-center font-semibold text-sm text-white ${this.state.adData && this.state.adData.file && this.state.adData.file.ratio === "9:16" && "hidden"}`}>
                                    {
                                        this.state.body !== "" ?
                                            <span dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="text-sm whitespace-pre-wrap">

                                            </span>
                                            :
                                            "Body..."
                                    }
                                    {
                                        !this.state.read_more &&
                                        this.renders.body().length > 125 &&
                                        <span
                                            onClick={async () => {
                                                await this.promisedSetState({
                                                    read_more: true
                                                });
                                            }} className="cursor-pointer font-semibold text-sm text-center pb-1">
                                            {" "}See more
                                        </span>
                                    }
                                </div>
                                <div
                                    className="flex flex-col items-center justify-center rounded-t-lg py-2">
                                    <div>
                                        <ChevronUpIcon className="w-5 text-white" />
                                    </div>
                                    <div className="bg-white rounded-full py-3 px-5 text-xs">
                                        {this.state.cta ? this.state.cta.name : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default PreviewFacebookStory;