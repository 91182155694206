import React, { Component, Fragment } from 'react';
import PreviewSnapchatStories from '../previews/snapchat/stories';
import PreviewSnapchatSpotlight from '../previews/snapchat/spotlight';
import snapchat_cta from '../assets/json/snapchat_cta.json';

class PreviewSectionSnapchat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: {},
            channel: {},
            for_report: false,
            preview_placement: "",
        };
    }


    componentWillMount() {
    }

    async componentDidMount() {
        await this.promisedSetState({
            ad: this.props.ad,
            channel: this.props.channel,
            for_report: this.props.for_report,
            preview_placement: this.props.preview_placement,
        });
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            channel: nextProps.channel,
            for_report: nextProps.for_report,
            preview_placement: nextProps.preview_placement,
        });
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {

    }

    renders = {
        title: (item) => {
            console.log(item)
            try {
                if (this.state.for_report && item.headline) {
                    return item.headline
                }
            } catch (error) {
                console.log(error)
            }
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.headlines)) {
                        let headline = "";
                        item.headlines.map((item) => {
                            headline = item.value;
                        });
                        return headline;
                    }
                } else if ('title' in item) {
                    return item.title;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        body: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.bodies)) {
                        let body = "";
                        item.bodies.map((item) => {
                            body = item.value;
                        });
                        return body;
                    }
                } else if ('body' in item) {
                    return item.body;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        description: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.descriptions)) {
                        let description = "";
                        item.descriptions.map((item) => {
                            description = item.value;
                        });
                        return description;
                    }
                } else if ('description' in item) {
                    return item.description;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        caption: (item) => {
            try {
                if ('caption' in item) {
                    return item.caption;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.links)) {
                        let link = "";
                        item.links.map((item) => {
                            link = item.value;
                        });
                        return link;
                    }
                } else if ('link' in item) {
                    return item.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        cta: (item) => {
            try {
                try {
                    if (this.state.for_report && item.call_to_action) {
                        return snapchat_cta.find((cta => cta.id.includes(item.call_to_action)))
                    }
                } catch (error) {
                    console.log(error)
                    return ""
                }
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.call_to_actions)) {
                        let cta = { id: 0, name: "No Button", type: "NO_BUTTON" };
                        item.call_to_actions.map((item) => {
                            cta = item.value;
                        });
                        return cta;
                    }
                } else if (item.cta) {
                    return item.cta;
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" }
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        page: (item) => {
            try {
                if (item.page) {
                    return item.page
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        creative: (item, placement_format) => {
            let creative = null;
            try {
                if (this.state.for_report && item.image) {
                    creative = item.image
                } else if (this.state.for_report && item.video) {
                    creative = item.video
                }
            } catch (eror) {
                console.log(eror)
            }
            try {
                if (item.ad_type && item.ad_type.value === "video_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "video" && !item.files)) {
                    if (item.video) {
                        creative = { thumbnail: item.thumbnail ? item.thumbnail : "", video: item.video };
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "image_ad") {
                    if (item.picture && item.picture !== "") {
                        creative = item.picture;
                    }
                }
            } catch (eror) {
            }
            try {
                if ((item.ad_type && item.ad_type.value === "posts" && item.files)) {
                    if (Array.isArray(item.files)) {
                        creative = item.files.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: (item.type === "VIDEO" || item.type === "video") ? true : false,
                                image: (item.type === "IMAGE" || item.type === "image") ? true : false,
                                loading: item.loading,
                                creative: {
                                    thumbnail: { url: item.thumbnail ? item.thumbnail : "" },
                                    url: item.url,
                                    ratio: "1:1"
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "carousel_ad") {
                    if (Array.isArray(item.slides)) {
                        creative = item.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        item.slides.map((slide) => {
                            if (item.preview && item.preview.ratio === slide.ratio) {
                                if (slide.type === 'image') {
                                    creative = slide.picture;
                                } else {
                                    creative = { thumbnail: slide.thumbnail, video: slide.url };
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "catalog_ad") {
                    if (Array.isArray(item.preview_products)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "search_ad") {
                    if (Array.isArray(item.combinations)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            return creative;
        },
        brandname: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.brandname)) {
                        let brandname = "";
                        item.brandnames.map((item) => {
                            brandname = item.value;
                        });
                        return brandname;
                    }
                } else if ('brandname' in item || 'snapchat_brandname' in item.file) {
                    return item.brandname || item.file.snapchat_brandname;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        attachment: (item) => {
            try {
                if ('attachment' in item || 'snapchat_attachment' in item.file) {
                    return item.attachment || item.file.snapchat_attachment;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        appIcon: (item) => {
            try {
                if ('app_icon' in item || 'snapchat_app_icon' in item.file) {
                    return item.app_icon || item.file.snapchat_app_icon.url;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        favourit: (item) => {
            try {
                if ('favourit' in item || 'snapchat_favourit' in item.file) {
                    return item.favourit || item.file.snapchat_favourit.value;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        }
    }

    render() {

        let ad = this.state.ad;

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                {
                    ad.preview && ad.preview.value === "video_feed" &&
                    <PreviewSnapchatStories
                        video={this.renders.creative(ad).video}
                        text={this.renders.body(ad)}
                        profile={this.renders.page(ad)}
                        thumbnail={this.renders.creative(ad).thumbnail}
                        cta={this.renders.cta(ad)}
                        page={this.renders.page(ad)}
                        ad={ad}
                        brandname={this.renders.brandname(ad)}
                        title={this.renders.title(ad)}
                        attachment={this.renders.attachment(ad)}
                        app_icon={this.renders.appIcon(ad)}
                        favourit={this.renders.favourit(ad)}
                    />
                }
                {
                    ad.preview && ad.preview.value === "image_feed" &&
                    <PreviewSnapchatStories
                        picture={this.renders.creative(ad)}
                        text={this.renders.body(ad)}
                        profile={this.renders.page(ad)}
                        cta={this.renders.cta(ad)}
                        page={this.renders.page(ad)}
                        ad={ad}
                        brandname={this.renders.brandname(ad)}
                        title={this.renders.title(ad)}
                        attachment={this.renders.attachment(ad)}
                    />
                }
                {/* {
                    ad.preview && ad.preview.value === "video_feed" &&
                    <PreviewSnapchatSpotlight
                        video={this.renders.creative(ad).video}
                        text={this.renders.body(ad)}
                        profile={this.renders.page(ad)}
                        thumbnail={this.renders.creative(ad).thumbnail}
                        cta={this.renders.cta(ad)}
                        page={this.renders.page(ad)}
                    />
                } */}

                {/* FOR REPORT  */}
                {
                    this.state.for_report &&
                    this.state.ad && this.state.ad.image &&
                    this.state.preview_placement === "story" && this.state.preview_placement !== "no_frame" &&
                    <PreviewSnapchatStories
                        picture={this.renders.creative(ad)}
                        text={this.renders.body(ad)}
                        profile={this.renders.page(ad)}
                        thumbnail={this.renders.creative(ad).thumbnail}
                        cta={this.renders.cta(ad)}
                        page={this.renders.page(ad)}
                        ad={ad}
                        brandname={this.renders.brandname(ad)}
                        title={this.renders.title(ad)}
                        attachment={this.renders.attachment(ad)}
                    />
                }
                {
                    this.state.for_report &&
                    this.state.ad && this.state.ad.video &&
                    this.state.preview_placement === "story" && this.state.preview_placement !== "no_frame" &&
                    <PreviewSnapchatStories
                        video={this.renders.creative(ad)}
                        text={this.renders.body(ad)}
                        profile={this.renders.page(ad)}
                        thumbnail={this.renders.creative(ad).thumbnail}
                        cta={this.renders.cta(ad)}
                        page={this.renders.page(ad)}
                        ad={ad}
                        brandname={this.renders.brandname(ad)}
                        title={this.renders.title(ad)}
                        attachment={this.renders.attachment(ad)}
                    />
                }

                {/* IMAGE NO FRAME */}
                {
                    this.state.for_report &&
                    this.state.ad && this.state.ad.image &&
                    this.state.preview_placement === "no_frame" &&
                    <div>
                        <img
                            className="h-full object-contain"
                            src={this.renders.creative(ad)}
                            alt={"Creative media"}
                        />
                    </div>
                }

                {/* VIDEO NO FRAME */}
                {
                    this.state.for_report &&
                    this.state.ad.video &&
                    this.state.preview_placement === "no_frame" &&
                    <div>
                        <video className="w-full h-full" autoplay={false} controls={true} loop={true} muted={true}>
                            <source src={this.renders.creative(ad)} type="video/mp4" />
                        </video>
                    </div>
                }


            </>
        )
    }
}

export default PreviewSectionSnapchat;
