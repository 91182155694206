import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, CursorClickIcon, MinusIcon, PlusIcon, LocationMarkerIcon, CheckCircleIcon, BookOpenIcon, ChatIcon, CameraIcon, NewspaperIcon, ExclamationIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import cn from "classnames";
import DropdownTailwind from './dropdownTailwind';
import DropdownComboTailwind from './dropdownComboTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import moment from 'moment';
import { adgroupTwitter } from '../validators/adgroupTwitter';
import SwitchTailwind from './switchTailwind';
import { Bar } from 'react-chartjs-2';
import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';

class EditTwitterAdset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: [],
            open: false,
            adset: {},
            updated: {},
            client: {},
            apps: {
                loading: false,
                options: []
            },
            currency: "",
            events: [],
            location_options: [],
            conversations_topic: { id: 0, name: "Select category (optional)" },
            conversations_topics: [
                { id: 1, name: "Actors", value: "ACTORS" },
                { id: 2, name: "Athletes", value: "ATHLETES" },
                { id: 3, name: "Book genres", value: "BOOK_GENRES" },
                { id: 4, name: "Books", value: "BOOKS" },
                { id: 5, name: "Brand categories", value: "BRAND_CATEGORIES" },
                { id: 6, name: "Brands", value: "BRANDS" },
                { id: 7, name: "Celebrities", value: "CELEBRITIES" },
                { id: 8, name: "Coaches", value: "COACHES" },
                { id: 9, name: "Digital creators", value: "DIGITAL_CREATORS" },
                { id: 10, name: "Entertainment brands", value: "ENTERTAINMENT_BRANDS" },
                { id: 11, name: "Entertainment personalities", value: "ENTERTAINMENT_PERSONALITIES" },
                { id: 12, name: "Fictional characters", value: "FICTIONAL_CHARACTERS" },
                { id: 13, name: "Journalists", value: "JOURNALISTS" },
                { id: 14, name: "Lifestyles", value: "LIFESTYLES" },
                { id: 15, name: "Movie genres", value: "MOVIE_GENRES" },
                { id: 16, name: "Movies", value: "MOVIES" },
                { id: 17, name: "Music genres", value: "MUSIC_GENRES" },
                { id: 18, name: "Musicians", value: "MUSICIANS" },
                { id: 19, name: "News stories", value: "NEWS_STORIES" },
                { id: 20, name: "News", value: "NEWS" },
                { id: 21, name: "Persons", value: "PERSONS" },
                { id: 22, name: "Places", value: "PLACES" },
                { id: 23, name: "Podcasts", value: "PODCASTS" },
                { id: 24, name: "Political affiliations", value: "POLITICAL_AFFILIATIONS" },
                { id: 25, name: "Politicians", value: "POLITICIANS" },
                { id: 26, name: "Products", value: "PRODUCTS" },
                { id: 27, name: "Radio stations", value: "RADIO_STATIONS" },
                { id: 28, name: "Sports leagues", value: "SPORTS_LEAGUES" },
                { id: 29, name: "Sports personalities", value: "SPORTS_PERSONALITIES" },
                { id: 30, name: "Sports teams", value: "SPORTS_TEAMS" },
                { id: 31, name: "Sports", value: "SPORTS" },
                { id: 32, name: "Trends", value: "TRENDS" },
                { id: 33, name: "TV show", value: "TV_SHOWS" },
                { id: 34, name: "Video game platforms", value: "VIDEO_GAME_PLATFORMS" },
                { id: 35, name: "Video game publishers", value: "VIDEO_GAME_PUBLISHERS" },
                { id: 36, name: "Video games", value: "VIDEO_GAMES" }
            ],
            min_ages: [
                { id: 1, name: "13", value: 13 },
                { id: 2, name: "18", value: 18 },
                { id: 3, name: "21", value: 21 },
                { id: 4, name: "25", value: 25 },
                { id: 5, name: "35", value: 35 },
                { id: 6, name: "50", value: 50 }
            ],
            max_ages: [
                { id: 1, name: "24", value: 24 },
                { id: 2, name: "34", value: 34 },
                { id: 3, name: "49", value: 49 },
                { id: 4, name: "54", value: 54 },
                { id: 5, name: "And up", value: null }
            ],
            genders: [
                { id: 1, name: "All genders", value: null },
                { id: 2, name: "Men only", value: "MALE" },
                { id: 3, name: "Female only", value: "FEMALE" }
            ],
            goals: [
                { id: 1, name: "App clicks", value: "APP_CLICKS" },
                { id: 2, name: "App installs", value: "APP_INSTALLS" },
                { id: 3, name: "App purchases", value: "APP_PURCHASES" },
                { id: 4, name: "Followers", value: "FOLLOWERS" },
                { id: 5, name: "Engagements", value: "ENGAGEMENT" },
                { id: 6, name: "Link clicks", value: "LINK_CLICKS" },
                { id: 7, name: "Max reach", value: "MAX_REACH" },
                //{ id: 8, name: "Preroll", value: "PREROLL" },
                //{ id: 9, name: "Preroll starts", value: "PREROLL_STARTS" },
                { id: 10, name: "Reach with engagement", value: "REACH_WITH_ENGAGEMENT" },
                { id: 11, name: "Site visits", value: "SITE_VISITS" },
                { id: 12, name: "Video view", value: "VIDEO_VIEW" },
                { id: 13, name: "View 3s/100%", value: "VIEW_3S_100PCT" },
                { id: 14, name: "View 6s", value: "VIEW_6S" },
                { id: 15, name: "View 15s", value: "VIEW_15S" },
                { id: 16, name: "Website conversions", value: "WEBSITE_CONVERSIONS" }
            ],
            bid_strategies: [
                { id: 1, name: "Autobid (recommended)", value: "AUTO" },
                { id: 2, name: "Target cost", value: "TARGET" },
                { id: 3, name: "Maximum bid", value: "MAX" }
            ],
            pacings: [
                { id: 1, name: "Standard (recommended)", value: true },
                { id: 2, name: "Accelerated", value: false }
            ],
            loader: false,
            tab: { id: 1, name: "Ad group details", value: "basic" },
            tabs: [
                { id: 1, name: "Ad group details", value: "basic" },
                { id: 2, name: "Budget & Schedule", value: "budget" },
                { id: 3, name: "Delivery", value: "delivery" },
                { id: 4, name: "Demographics", value: "demographics" },
                //{ id: 5, name: "Devices", value: "devices" },
                //{ id: 6, name: "Custom audiences", value: "custom audiences" },
                { id: 7, name: "Targeting features", value: "targeting features" },
                { id: 8, name: "Apps", value: "apps" },
                //{ id: 9, name: "Twitter Audience Platform", value: "twitter audience platform" }
            ],
            location_type: { id: 1, name: "Included", value: true },
            targeting: {
                locations: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                languages: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                keywords: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                followers: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                interests: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                movies: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                events: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                conversations: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                }
            },
            audience_min: 0,
            audience_max: 0,
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            adset: this.props.adset,
            client: this.props.client,
            wizard: this.props.wizard,
            campaign: this.props.campaign ? this.props.campaign : []
        })

        if (this.props.updated) {
            await this.promisedSetState({
                updated: this.props.updated
            });
            if (this.state.campaign.length > 0 && this.state.campaign[0].scheduler && !this.state.campaign[0].scheduler.schedule && this.state.updated.scheduler) {
                console.log("scheduler");
                this.state.tabs.push({ id: 11, name: "Scheduler", value: "schedule" });
                this.state.updated.adgroup_budget_optimization = { id: 1, name: "Yes", value: true };
                if (this.state.updated.scheduler.planer) {
                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                    } else {
                        this.state.updated.daily_budget = 0;
                    }
                }
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    updated: this.state.updated
                });
            }
        }
        if (this.state.client.channels.filter((item) => { return item.value === "twitter" }).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => { return item.value === "twitter" })[0].currency
            })
        }

        this.functions.listApps();
        this.functions.listEvents();
    }

    componentWillReceiveProps(nextProps) {
        /*this.setState({
            wizard: nextProps.wizard
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }*/
    }

    functions = {
        update: async () => {

            if (!this.state.wizard) {

            } else {
                this.props.onUpdate(this.state.updated);
            }

        },
        searchLocations: async () => {
            try {
                this.promisedSetState({ loading_locations: true });
                let response = await this.calls.locations();
                this.promisedSetState({
                    loading_locations: false,
                    location_options: response.data
                });
            } catch (error) {
                this.promisedSetState({
                    location_options: []
                })
            }
        },
        searchTargeting: async (type, search) => {
            try {
                this.state.targeting[type].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.targeting(type, search);
                this.state.targeting[type].options = response.data;
                this.state.targeting[type].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting[type].options = [];
                this.state.targeting[type].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        audienceSize: async () => {
            try {
                await this.promisedSetState({ loading_audience: true });
                let targeting = { targeting_criteria: [] };

                //GENDER
                if (this.renders.gender().id !== 0) {
                    let gender_criteria = {
                        "operator_type": "EQ",
                        "targeting_type": "GENDER",
                        "targeting_value": this.renders.gender().value
                    };
                    targeting.targeting_criteria.push(gender_criteria);
                }

                //AGE
                let age_span = "";
                if (this.renders.ageMin().value && this.renders.ageMax().value) {
                    age_span = "AGE_" + this.renders.ageMin().value + "_TO_" + this.renders.ageMax().value;
                }
                if (this.renders.ageMin().value && !this.renders.ageMax().value) {
                    age_span = "AGE_OVER_" + this.renders.ageMin().value;
                }
                let age_criteria = {
                    "operator_type": "EQ",
                    "targeting_type": "AGE",
                    "targeting_value": age_span
                };
                targeting.targeting_criteria.push(age_criteria);

                //OTHER
                let targeting_options = [
                    { option: "locations", targeting_type: "LOCATION", operator: "EQ" },
                    { option: "languages", targeting_type: "LANGUAGE", operator: "EQ" },
                    { option: "keywords", targeting_type: "BROAD_KEYWORD", operator: null },
                    { option: "interests", targeting_type: "INTEREST", operator: null },
                    { option: "events", targeting_type: "EVENT", operator: null },
                    { option: "conversations", targeting_type: "CONVERSATION", operator: null }
                ];
                targeting_options.map((item) => {
                    if (Array.isArray(this.state.updated[item.option])) {
                        this.state.updated[item.option].map((inner_item) => {
                            let other_criteria = {
                                "operator_type": item.operator ? item.operator : (inner_item.included ? "EQ" : "NE"),
                                "targeting_type": item.targeting_type,
                                "targeting_value": inner_item.targeting_value
                            };
                            targeting.targeting_criteria.push(other_criteria);
                        })
                    }
                });
                let response = await this.calls.audienceSize(targeting);
                this.promisedSetState({
                    loading_audience: false,
                    audience_min: response.data.audience_size.min,
                    audience_max: response.data.audience_size.max,
                });
            } catch (error) {
                await this.promisedSetState({ loading_audience: false });
            }
        },
        listApps: async () => {
            try {
                this.state.apps.loading = true;
                await this.promisedSetState({ apps: this.state.apps });
                let response = await this.calls.apps();
                this.state.apps.options = response.data;
                this.state.apps.loading = false;
                await this.promisedSetState({ apps: this.state.apps });
            } catch (error) {
                this.state.apps.options = [];
                this.state.apps.loading = false;
                await this.promisedSetState({ apps: this.state.apps });
            }
        },
        listEvents: async () => {
            try {
                let response = await this.calls.events();
                this.state.events = response.data;
                await this.promisedSetState({ events: this.state.events });
            } catch (error) {

            }
        },
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/updateAdset?client=" + this.state.client.id + "&id=" + this.state.adset.id;
            return apiRegister.call(options, url);
        },
        locations: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/listLocations?client=" + this.state.client.id + "&search=" + this.state.search_locations;
            return apiRegister.call(options, url);
        },
        targeting: (type, search) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/listTargeting?client=" + this.state.client.id + "&targeting=" + type + "&search=" + search;
            return apiRegister.call(options, url);
        },
        audienceSize: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/twitter/getAudienceSize?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        apps: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/listApps?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        events: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/listEvents?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }

    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.adset.name) {
                    return this.state.adset.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        gender: () => {
            try {
                if (this.state.updated.gender) {
                    return this.state.updated.gender;
                } else {
                    return { id: 1, name: "All genders", value: null };
                }
            } catch (error) {
                return { id: 1, name: "All genders", value: null };
            }
        },
        ageMin: () => {
            try {
                if ("age_min" in this.state.updated) {
                    return this.state.updated.age_min;
                } else {
                    return { id: 13, name: "13", value: 13 };
                }
            } catch (error) {
                return { id: 1, name: "13", value: 13 };
            }
        },
        ageMax: () => {
            try {
                if ("age_max" in this.state.updated) {
                    return this.state.updated.age_max;
                } else {
                    return { id: 5, name: "And up", value: null };
                }
            } catch (error) {
                return { id: 5, name: "And up", value: null };
            }
        },
        goal: () => {
            try {
                if ("goal" in this.state.updated) {
                    return this.state.updated.goal;
                } else {
                    return {
                        id: 6, name: "Link clicks", value: "LINK_CLICKS", sub_name: "link click",
                        pay_by_default: { id: 2, name: "Impressions (recommended)", value: "IMPRESSION" },
                        pay_by: [
                            { id: 2, name: "Impressions (recommended)", value: "IMPRESSION" },
                            { id: 1, name: "Link Clicks", value: "LINK_CLICK" },
                        ]
                    };
                }
            } catch (error) {
                return {
                    id: 6, name: "Link clicks", value: "LINK_CLICKS", sub_name: "link click",
                    pay_by_default: { id: 2, name: "Impressions (recommended)", value: "IMPRESSION" },
                    pay_by: [
                        { id: 2, name: "Impressions (recommended)", value: "IMPRESSION" },
                        { id: 1, name: "Link Clicks", value: "LINK_CLICK" },
                    ]
                };
            }
        },
        bidStrategy: () => {
            try {
                if ("bid_strategy" in this.state.updated) {
                    return this.state.updated.bid_strategy;
                } else {
                    return { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                }
            } catch (error) {
                return { id: 1, name: "Autobid (recommended)", value: "AUTO" };
            }
        },
        objective: () => {
            try {
                if ("objective" in this.state.updated) {
                    return this.state.updated.objective;
                } else {
                    return { id: 0, name: "Select objetive", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select objective", value: null };
            }
        },
        targetCost: () => {
            try {
                if ('target_cost' in this.state.updated) {
                    return +this.state.updated.target_cost;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        maximumBid: () => {
            try {
                if ('maximum_bid' in this.state.updated) {
                    return +this.state.updated.maximum_bid;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        pacing: () => {
            try {
                if ("pacing" in this.state.updated) {
                    return this.state.updated.pacing;
                } else {
                    return { id: 1, name: "Standard (recommended)", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Standard (recommended)", value: true };
            }
        },
        payBy: () => {
            try {
                if ("pay_by" in this.state.updated) {
                    return this.state.updated.pay_by;
                } else {
                    return { id: 0, name: "Select goal", value: null };
                }
            } catch (error) {
                return { id: 0, name: "Select goal", value: null };
            }
        },
        budgetOptimization: () => {
            try {
                if ("adgroup_budget_optimization" in this.state.updated) {
                    return this.state.updated.adgroup_budget_optimization;
                } else {
                    return { id: 2, name: "No", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        totalBudget: () => {
            try {
                if ('total_budget' in this.state.updated) {
                    return this.state.updated.total_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        autoPlacements: () => {
            try {
                if ("auto_placements" in this.state.updated) {
                    return this.state.updated.auto_placements;
                } else if (this.state.adset.targeting && this.state.adset.targeting.device_platforms) {
                    return { id: 2, name: "No", value: false };
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Yes", value: true };
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return moment(this.state.updated.start_date).format("YYYY-MM-DD");
                } else {
                    return moment(this.state.adset.start_time).format("YYYY-MM-DD");
                }
            } catch (error) {
                return 0;
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date ? moment(this.state.updated.end_date).format("YYYY-MM-DD") : null;
                } else {
                    return this.state.adset.end_time ? moment(this.state.adset.end_time).format("YYYY-MM-DD") : null;
                }
            } catch (error) {
                return 0;
            }
        },
        format: (num) => {
            return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
        },
        app: () => {
            try {
                if ("app" in this.state.updated) {
                    return this.state.updated.app;
                } else {
                    return { id: 0, name: "Select app", value: false };
                }
            } catch (error) {
                return { id: 0, name: "Select app", value: false };
            }
        },
        event: () => {
            try {
                if ("event" in this.state.updated) {
                    return this.state.updated.event;
                } else {
                    return { id: 0, name: "Select event", value: false };
                }
            } catch (error) {
                return { id: 0, name: "Select event", value: false };
            }
        },
        app_location: () => {
            try {
                if ("app" in this.state.updated) {
                    if ("location" in this.state.updated.app) {
                        return this.state.updated.app.location;
                    } else {
                        return { id: 0, name: "Select location", value: false };
                    }
                } else {
                    return { id: 0, name: "Select location", value: false };
                }
            } catch (error) {
                return { id: 0, name: "Select location", value: false };
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        let selected_targeting = (type) => {
            return (
                <div className="col-span-3 flex flex-col">
                    {
                        this.state.updated[type].sort(function (x, y) {
                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                        }).map((item, index) => {
                            let included_amount = this.state.updated[type].filter((item) => { return item.included }).length;
                            let excluded_amount = this.state.updated[type].filter((item) => { return !item.included }).length;
                            return (
                                <Fragment>
                                    {
                                        index == 0 && included_amount > 0 &&
                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                            Included
                                        </div>
                                    }
                                    {
                                        (included_amount == index) && excluded_amount > 0 &&
                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                            Excluded
                                        </div>
                                    }
                                    <div className={(index !== 0 ? "mt-2 " : "") + (item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full items-center py-1.5 px-3 text-sm font-medium "}>
                                        <div className="flex flex-1">{item.name}</div>
                                        <button
                                            onClick={() => {
                                                this.state.updated[type] = this.state.updated[type].filter((location) => {
                                                    return location.id !== item.id;
                                                })
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                            type="button"
                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                        >
                                            <span className="sr-only">Remove</span>
                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                            </svg>
                                        </button>
                                    </div>
                                </Fragment>
                            )
                        })
                    }
                </div>
            )
        }

        return (
            <>
                <div className="grid grid-cols-3 gap-4">

                    <div className="col-span-3">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.filter((item) => {
                                    return (this.renders.objective().id === 1 || this.renders.objective().id === 2) || ((this.renders.objective().id !== 1 || this.renders.objective().id !== 2) && item.id !== 8)
                                }).map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {

                                                (tab.id === 1 && adgroupTwitter.validate(this.state.updated).name) ||
                                                    (tab.id === 2 && (adgroupTwitter.validate(this.state.updated).start_date || adgroupTwitter.validate(this.state.updated).end_date || adgroupTwitter.validate(this.state.updated).budget)) ||
                                                    (tab.id === 4 && adgroupTwitter.validate(this.state.updated).location) ||
                                                    (tab.id === 11 && ((this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity) || Object.keys(this.state.updated.scheduler.weekdays).length < 1) && this.state.updated.scheduler.schedule)
                                                    ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*Ad group details*/}
                    {
                        this.state.tab.id == 1 &&
                        <div className="col-span-3">
                            <InputTailwind
                                label={"Adset name"}
                                error={adgroupTwitter.validate(this.state.updated).name}
                                value={this.renders.name()}
                                onChange={(value) => {
                                    this.state.updated.name = value;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                            />
                        </div>
                    }

                    {/*Budget & Schedule*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className="col-span-3">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3">
                                        <InputDatepickerTailwind
                                            label={"Start date"}
                                            error={adgroupTwitter.validate(this.state.updated).start_date}
                                            value={this.renders.startDate()}
                                            onChange={(value) => {
                                                this.state.updated.start_date = value;
                                                if (this.state.updated.scheduler) {
                                                    this.state.updated.scheduler.start_date = moment(value);

                                                    if (this.state.updated.scheduler.planer) {
                                                        if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                            this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                        } else {
                                                            this.state.updated.daily_budget = 0;
                                                        }
                                                    }
                                                }
                                                if (this.renders.endDate() && new Date(this.renders.endDate()).getTime() < new Date(moment(value)).getTime()) {
                                                    this.state.updated.end_date = moment(value).add(1, 'days').format("YYYY-MM-DD");
                                                }
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <InputDatepickerTailwind
                                            label={"End date"}
                                            minDate={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                            error={adgroupTwitter.validate(this.state.updated).end_date}
                                            labelRight={this.renders.endDate() ? "Enabled" : "Disabled"}
                                            placeholder={!this.renders.endDate() ? "No end date" : ""}
                                            disabled={!this.renders.endDate()}
                                            value={this.renders.endDate()}
                                            onChange={(value) => {
                                                this.state.updated.end_date = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                            onLabelRightClick={(value) => {
                                                if (this.renders.endDate()) {
                                                    this.state.updated.end_date = null;
                                                } else {
                                                    let new_date = moment(this.renders.startDate(), "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                                                    this.state.updated.end_date = new_date;
                                                }
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Adgroup budget optimization"}
                                    selected={this.renders.budgetOptimization()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    onChange={(value) => {
                                        this.state.updated.adgroup_budget_optimization = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.budgetOptimization().id === 1 &&
                                <div className="grid grid-cols-6 gap-4 col-span-3">
                                    <div className="col-span-3">
                                        <InputTailwind
                                            error={adgroupTwitter.validate(this.state.updated).budget}
                                            label={"Daily ad group budget"}
                                            leftSection={(this.state.currency !== "" ? true : false)}
                                            leftSectionLabel={(this.state.currency)}
                                            type={'number'}
                                            locked={this.state.campaign.length > 0 && this.state.campaign[0].scheduler && !this.state.campaign[0].scheduler.schedule  && this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                            disabled={this.state.campaign.length > 0 && this.state.campaign[0].scheduler && !this.state.campaign[0].scheduler.schedule && this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                            value={this.renders.dailyBudget()}
                                            onChange={(value) => {
                                                this.state.updated.daily_budget = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <InputTailwind
                                            error={adgroupTwitter.validate(this.state.updated).budget}
                                            label={"Total ad group budget (optional)"}
                                            leftSection={(this.state.currency !== "" ? true : false)}
                                            leftSectionLabel={(this.state.currency)}
                                            type={'number'}
                                            value={this.renders.totalBudget()}
                                            onChange={(value) => {
                                                this.state.updated.total_budget = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    }

                    {/*Delivery*/}
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Goal"}
                                    selected={this.renders.goal()}
                                    options={Array.isArray(this.state.updated.goals) ? this.state.updated.goals : []}
                                    onChange={(value) => {
                                        this.state.updated.goal = value;
                                        //this.state.updated.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                                        //this.state.updated.pay_by = value.pay_by_default;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 3 &&
                        this.state.updated.objective.id === 8 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Site visit event"}
                                    selected={this.renders.event()}
                                    options={this.state.events}
                                    onChange={(value) => {
                                        this.state.updated.event = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Bid Strategy"}
                                    selected={this.renders.bidStrategy()}
                                    options={this.state.bid_strategies.filter((item) => {
                                        return item.id !== 2 || (item.id === 2 && this.renders.goal().value !== "APP_INSTALLS")
                                    })}
                                    onChange={(value) => {
                                        this.state.updated.bid_strategy = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.bidStrategy().id === 2 &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Target cost (average cost per " + this.renders.goal().sub_name + ')'}
                                        leftSection={false}
                                        value={this.renders.targetCost()}
                                        onChange={(value) => {
                                            this.state.updated.target_cost = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.bidStrategy().id === 3 &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Maximum bid (average cost per " + this.renders.goal().sub_name + ')'}
                                        leftSection={false}
                                        value={this.renders.maximumBid()}
                                        onChange={(value) => {
                                            this.state.updated.maximum_bid = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.budgetOptimization().id === 1 &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Pacing"}
                                        selected={this.renders.pacing()}
                                        options={this.state.pacings}
                                        onChange={(value) => {
                                            this.state.updated.pacing = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Pay by"}
                                    selected={this.state.updated.pay_by ? this.state.updated.pay_by : { id: 0, name: "Select value" }}
                                    options={Array.isArray(this.state.updated.pay_bys) ? this.state.updated.pay_bys : []}
                                    onChange={(value) => {
                                        this.state.updated.pay_by = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }

                    {/*Demographics*/}
                    {
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Gender"}
                                    selected={this.renders.gender()}
                                    options={this.state.genders}
                                    onChange={async (value) => {
                                        this.state.updated.gender = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        //this.functions.audienceSize();
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Age min"}
                                            searchInput={true}
                                            selected={this.renders.ageMin()}
                                            options={this.state.min_ages}
                                            onChange={async (value) => {
                                                this.state.updated.age_min = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                                //this.functions.audienceSize();
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Age max"}
                                            searchInput={true}
                                            selected={this.renders.ageMax()}
                                            options={this.state.max_ages}
                                            onChange={async (value) => {
                                                this.state.updated.age_max = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                                //this.functions.audienceSize();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    error={adgroupTwitter.validate(this.state.updated).location}
                                    ref="searchDropdown"
                                    label={"Locations"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={Array.isArray(this.state.location_options) ? this.state.location_options : []}
                                    loadingSearch={this.state.loading_locations}
                                    onChange={async (location) => {
                                        location.included = true;
                                        if (!Array.isArray(this.state.updated.locations)) {
                                            this.state.updated.locations = [];
                                        }
                                        this.state.updated.locations.unshift(location);
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        //this.functions.audienceSize();
                                    }}
                                    onSearch={async (value) => {
                                        await this.promisedSetState({ search_locations: value });
                                        this.functions.searchLocations();
                                    }}
                                />
                            </div>
                            {
                                Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 &&
                                selected_targeting("locations")
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Languages"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={Array.isArray(this.state.targeting.languages.options) ? this.state.targeting.languages.options : []}
                                    loadingSearch={this.state.targeting.languages.loading}
                                    onChange={async (lang) => {
                                        lang.included = true;
                                        if (!Array.isArray(this.state.updated.languages)) {
                                            this.state.updated.languages = [];
                                        }
                                        this.state.updated.languages.unshift(lang);
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                        //this.functions.audienceSize();
                                    }}
                                    onSearch={async (value) => {
                                        this.functions.searchTargeting("languages", value);
                                    }}
                                />
                            </div>
                            {
                                Array.isArray(this.state.updated.languages) && this.state.updated.languages.length > 0 &&
                                selected_targeting("languages")
                            }
                        </>
                    }

                    {/*Targeting features*/}
                    {
                        this.state.tab.id == 7 &&
                        <>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Keywords"}
                                    value={this.state.keyword}
                                    onChange={async (value) => {
                                        this.setState({
                                            keyword: value
                                        })

                                    }}
                                    onKeyDown={async (value) => {
                                        if (value === 13) {
                                            let item = {
                                                id: this.state.keyword,
                                                name: this.state.keyword,
                                                targeting_value: this.state.keyword,
                                                value: this.state.keyword,
                                                included: true
                                            };
                                            if (!Array.isArray(this.state.updated["keywords"])) {
                                                this.state.updated["keywords"] = [];
                                            }
                                            this.state.updated["keywords"].unshift(item);
                                            await this.promisedSetState({
                                                updated: this.state.updated,
                                                keyword: ""
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {
                                Array.isArray(this.state.updated["keywords"]) && this.state.updated["keywords"].length > 0 &&
                                selected_targeting("keywords")
                            }
                            {
                                [
                                    //{ type: "keywords", title: "Keywords", labelButton: true },
                                    { type: "interests", title: "Interests (optional)", labelButton: false },
                                    { type: "events", title: "Events (optional)", labelButton: false },
                                    { type: "conversations", title: "Conversation topics (optional)", labelButton: false }
                                ].map((targeting_type) => {
                                    return (
                                        <>
                                            {
                                                targeting_type.type === "conversations" &&
                                                <div className="col-span-3">
                                                    <DropdownTailwind
                                                        label={"Conversation category"}
                                                        searchInput={true}
                                                        selected={this.state.conversations_topic}
                                                        options={this.state.conversations_topics}
                                                        onChange={async (value) => {
                                                            await this.promisedSetState({
                                                                conversations_topic: value
                                                            });
                                                            this.functions.searchTargeting(targeting_type.type, value.value);
                                                        }}
                                                    />
                                                </div>
                                            }
                                            <div className="col-span-3">
                                                <DropdownTailwind
                                                    label={targeting_type.title}
                                                    rightLabel={targeting_type.labelButton}
                                                    rightLabelText={this.state.targeting[targeting_type.type].type.value ? "Included" : "Excluded"}
                                                    onRightLabelClick={async () => {
                                                        this.state.targeting[targeting_type.type].type = !this.state.targeting[targeting_type.type].type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false };
                                                        await this.promisedSetState({ targeting: this.state.targeting });
                                                    }}
                                                    searchInput={true}
                                                    selected={{ name: "Search ..." }}
                                                    options={Array.isArray(this.state.targeting[targeting_type.type].options) ? this.state.targeting[targeting_type.type].options : []}
                                                    loadingSearch={this.state.targeting[targeting_type.type].loading}
                                                    loader={targeting_type.type === "conversations" && this.state.targeting[targeting_type.type].loading}
                                                    onChange={async (item) => {
                                                        item.included = this.state.targeting[targeting_type.type].type.value;
                                                        if (!Array.isArray(this.state.updated[targeting_type.type])) {
                                                            this.state.updated[targeting_type.type] = [];
                                                        }
                                                        this.state.updated[targeting_type.type].unshift(item);
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                        //this.functions.audienceSize();
                                                    }}
                                                    onSearch={async (value) => {
                                                        if (targeting_type.type === "keywords") {
                                                            this.state.targeting.keywords.options = [{ name: value, id: value, targeting_value: value }];
                                                            await this.promisedSetState({ targeting: this.state.targeting });
                                                        } else if (targeting_type.type !== "conversations") {
                                                            this.functions.searchTargeting(targeting_type.type, value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {
                                                Array.isArray(this.state.updated[targeting_type.type]) && this.state.updated[targeting_type.type].length > 0 &&
                                                selected_targeting(targeting_type.type)
                                            }
                                        </>
                                    )
                                })
                            }
                        </>
                    }

                    {/*Apps*/}
                    {
                        this.state.tab.id == 8 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"App"}
                                    selected={this.renders.app()}
                                    options={this.state.apps.options}
                                    onChange={async (value) => {
                                        this.state.updated.app = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>

                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Primary app store"}
                                    searchInput={true}
                                    selected={this.renders.app_location()}
                                    options={Array.isArray(this.state.location_options) ? this.state.location_options : []}
                                    loadingSearch={this.state.loading_locations}
                                    onChange={async (location) => {
                                        if (!Array.isArray(this.state.updated.app.location)) {
                                            this.state.updated.app.location = [];
                                        }
                                        this.state.updated.app.location = location;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    onSearch={async (value) => {
                                        await this.promisedSetState({ search_locations: value });
                                        this.functions.searchLocations();
                                    }}
                                />
                            </div>
                        </>
                    }

                    {/*SCHEDULER */}

                    {
                        this.state.tab.id == 11 &&
                        <>
                            <div className='col-span-6'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Schedule</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.state.updated.scheduler.schedule ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.updated.scheduler.schedule ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.scheduler.schedule = !this.state.updated.scheduler.schedule;
                                                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer) {

                                                    this.state.updated.adgroup_budget_optimization = { id: 1, name: "Yes", value: true };
                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                    } else {
                                                        this.state.updated.daily_budget = 0;
                                                    }
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-span-6 grid grid-cols-12 gap-4 transform transition-all duration-500 ease-in-out overflow-hidden' + (!this.state.updated.scheduler.schedule ? " h-0" : "")}>
                                <div className='col-span-12'>
                                    <div className={"text-xs font-medium mb-1 text-gray-700"}>
                                        Days
                                    </div>
                                    <div className='flex flex-wrap'>
                                        {
                                            [
                                                { name: "Monday", property: "scheduleMonday" },
                                                { name: "Tuesday", property: "scheduleTuesday" },
                                                { name: "Wednesday", property: "scheduleWednesday" },
                                                { name: "Thursday", property: "scheduleThursday" },
                                                { name: "Friday", property: "scheduleFriday" },
                                                { name: "Saturday", property: "scheduleSaturday" },
                                                { name: "Sunday", property: "scheduleSunday" }
                                            ].map((weekday) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (this.state.updated.scheduler.weekdays[weekday.property]) {
                                                            delete (this.state.updated.scheduler.weekdays[weekday.property]);
                                                        } else {
                                                            this.state.updated.scheduler.weekdays[weekday.property] = true;
                                                        }
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                        
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })


                                                    }} className={(this.state.updated.scheduler.weekdays[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div>{weekday.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(this.state.updated.scheduler.weekdays[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(this.state.updated.scheduler.weekdays[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-span-12 overflow-hidden ease-in-out transition-all duration-500 grid grid-cols-4 gap-4'>
                                    <div className='col-span-4'>
                                        <div className='flex flex-row overflow-hidden ease-in-out transition-all duration-500'>
                                            <div className={'flex flex-col transition-all transform duration-500 overflow-hidden' + (!this.state.updated.scheduler.planer ? " flex-1" : " pr-4")}>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 min-w-24">
                                                        {(this.state.updated.scheduler.planer ? "Active" : "Disabled")}
                                                    </div>
                                                    <div className={"relative overflow-hidden mr-5 "}>
                                                        <SwitchTailwind
                                                            disabled={false}
                                                            value={this.state.updated.scheduler.planer}
                                                            onSwitch={async () => {

                                                                this.state.updated.scheduler.planer = !this.state.updated.scheduler.planer;
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                                if (this.state.updated.scheduler.planer) {
                                                                    this.state.updated.adgroup_budget_optimization = { id: 1, name: "Yes", value: true };
                                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                                    } else {
                                                                        this.state.updated.daily_budget = 0;
                                                                    }
                                                                }

                                                
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "pr-4" : "w-0 hidden")}>
                                                <div className=' pr-2'>
                                                    <InputTailwind
                                                        locked={!this.state.updated.scheduler.planer}
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        label={"Total monthly"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.scheduler.schedule_budget}
                                                        type={"number"}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.schedule_budget = value;

                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <InputTailwind
                                                        locked={true}
                                                        disabled={true}
                                                        label={"Daily budget"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.daily_budget}
                                                        type={"number"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                <div className='w-full'>
                                                    <InputDatepickerTailwind
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        locked={!this.state.updated.scheduler.planer}
                                                        placeholder={moment(this.state.updated.scheduler.start_date).format("MM/DD/YYYY")}
                                                        label={"Start date"}
                                                        value={moment(this.state.updated.scheduler.start_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.start_date = moment(value).format("YYYY-MM-DD");
                                                            if (this.state.updated.scheduler) {
                                                                this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                                            }
                                                            if (this.renders.endDate() && new Date(this.renders.endDate()).getTime() < new Date(moment(value)).getTime()) {
                                                                this.state.updated.end_date = moment(value).add(1, 'days').format("YYYY-MM-DD");
                                                            }
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.updated.scheduler.order_info && this.state.updated.scheduler.order_info.maxBudgetSubAction &&
                                                <div className={'flex transition-all transform duration-500 overflow-hidden pl-4 ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                    <div className=''>
                                                        <InputTailwind
                                                            locked={true}
                                                            disabled={true}
                                                            label={"Order pacing"}
                                                            value={this.state.updated.scheduler.order_info.maxBudgetSubAction && this.state.updated.scheduler.order_info.maxBudgetSubAction.id ? (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 1 ? "Spend total" : (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 2 ? "Spend pacing" : "Unkown")) : "Unkown"}
                                                        />
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.updated.scheduler.planer && this.state.updated.scheduler.schedule_budget > 0 && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity &&
                                            <div className='flex flex-row border-t mt-4'>
                                                <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                    OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='col-span-4'>
                                        <div className={" mt-4 pt-4" + (this.state.updated.scheduler.planer ? " h-64" : " h-0")}>
                                            <Bar
                                                options={{
                                                    maintainAspectRatio: false,
                                                    interaction: {
                                                        intersect: false,
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                suggestedMin: 0
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={budgetPlannerGraph.main(this.state.updated.scheduler)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }

                </div>
            </>
        )
    }
}

export default EditTwitterAdset;
