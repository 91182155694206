import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationIcon, FolderIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import ToolTip from './toolTip';
import { cli } from 'tailwindcss/lib/constants';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class ChannelBudgetModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            budget: false,
            budgetPlaner: false,
            auto: false,
            channel: ""
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            open: this.props.open,
            budget: this.props.budget,
            budgetPlaner: this.props.budgetPlaner,
            auto: this.props.auto,
            channel: this.props.channel
        })
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            open: nextProps.open,
            budget: nextProps.budget,
            budgetPlaner: nextProps.budgetPlaner,
            auto: nextProps.auto,
            channel: nextProps.channel
        })

    }
    functions = {

    }
    calls = {

    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }
    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed z-60 inset-0 overflow-y-auto" onClose={() => {
                    if (this.props.onClose) {
                        this.props.onClose(true, false);
                    }
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* CHANGED OVERFLOW-HIDDEN TO NORMAL BELOW */}
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
                                        <div className={"bg-" + (this.state.channel) + "-500" + " mx-auto flex items-center justify-center h-12 w-12 rounded-full "}>
                                            {
                                                this.state.channel === 'google' &&
                                                <img className="w-4"
                                                    alt=""
                                                    src={require('../assets/images/google_icon.png')} />
                                            }
                                            {
                                                this.state.channel === 'facebook' &&
                                                <img className="w-2"
                                                    alt=""
                                                    src={require('../assets/images/facebook_icon.svg')} />
                                            }
                                            {
                                                this.state.channel === 'linkedin' &&
                                                <img className="w-4"
                                                    alt=""
                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                            }
                                            {
                                                this.state.channel === 'twitter' &&
                                                <img className="w-4"
                                                    alt=""
                                                    src={require('../assets/images/twitter_icon.svg')} />
                                            }
                                            {
                                                this.state.channel === 'tiktok' &&
                                                <img className="w-4"
                                                    alt=""
                                                    src={require('../assets/images/tiktok_icon.png')} />
                                            }
                                            {
                                                this.state.channel === 'snapchat' &&
                                                <img className="w-4"
                                                    alt=""
                                                    src={require('../assets/images/snapchat.svg')} />
                                            }
                                            {
                                                this.state.channel === 'bing' &&
                                                <img className="w-3"
                                                    alt=""
                                                    src={require('../assets/images/bing-logo.png')} />
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 flex justify-center items-center">
                                            {this.props.title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm">
                                                {
                                                    this.state.auto &&
                                                    "This will automatically adjust all linked campaign budgets by their current % allocation."
                                                }
                                                {
                                                    !this.state.auto &&
                                                    "Update linked campaign budgets based on current % allocation? If \"No\", you'll need to distribute manually."
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        {
                                            !this.state.auto &&
                                            <div className={"col-span-2"}>
                                                <button
                                                    type="button"
                                                    className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                    onClick={() => {
                                                        if (this.props.onClose) {
                                                            this.props.onClose();
                                                        }
                                                    }}
                                                >
                                                    {"Cancel"}
                                                </button>
                                            </div>
                                        }
                                        <div className={!this.state.auto ? "col-span-2" : "col-span-3"}>
                                            <button
                                                type="button"
                                                className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => {
                                                    if (!this.state.auto && this.props.onManual) {
                                                        this.props.onManual();
                                                    } else {
                                                        if (this.props.onClose) {
                                                            this.props.onClose();
                                                        }
                                                    }
                                                }}
                                            >
                                                {!this.state.auto ? "No" : "Cancel"}
                                            </button>
                                        </div>
                                        <div className={!this.state.auto ? "col-span-2" : "col-span-3"}>
                                            <button
                                                type="button"
                                                className={(true ? "bg-purple-500  hover:bg-purple-600  text-white " : "cursor-not-allowed bg-gray-300 text-gray-600 ") + " inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"}
                                                onClick={async () => {
                                                    if (this.props.onSave) {
                                                        this.props.onSave();
                                                    }
                                                }}
                                            >
                                                {!this.state.auto ? "Yes" : "Update"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default ChannelBudgetModal;
