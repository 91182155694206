import React, { Component, Fragment } from 'react';
import { SelectorIcon, LockClosedIcon, SearchIcon, TrashIcon, StarIcon, ChartPieIcon } from '@heroicons/react/outline'
import { Listbox, Transition } from '@headlessui/react';
const fuzzysort = require('fuzzysort');

class DropdownTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locked: false,
            search: "",
            label: "",
            options: [],
            selected: {},
            default: {},
            placeholder: "",
            total: 0,
            defaultOptions: [],
            error: false,
            addPlusExtra: 0,
            icon: false,
            channel_icon: false,
            smallText: false,
            removeButton: false,
            showTags: false,
            subTitle: null
        }
    };

    componentDidMount() {
        this.setState({
            search: this.props.search,
            label: this.props.label,
            selected: this.props.selected,
            options: this.props.options,
            default: this.props.default,
            loader: this.props.loader,
            disabled: this.props.disabled,
            locked: this.props.locked,
            placeholder: this.props.placeholder,
            channel: this.props.channel,
            loadingSearch: this.props.loadingSearch,
            loadingPagination: this.props.loadingPagination,
            rightLabelText: this.props.rightLabelText,
            pagination: this.props.pagination,
            small: this.props.small,
            total: this.props.total,
            defaultOptions: this.props.defaultOptions,
            skipInternalSearch: this.props.skipInternalSearch,
            error: this.props.error,
            addPlusExtra: this.props.addPlusExtra,
            icon: this.props.icon,
            channel_icon: this.props.channel_icon,
            smallText: this.props.smallText,
            removeButton: this.props.removeButton,
            showTags: this.props.showTags,
            subTitle: this.props.subTitle
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            search: nextProps.search,
            label: nextProps.label,
            selected: nextProps.selected,
            options: nextProps.options,
            loader: nextProps.loader,
            disabled: nextProps.disabled,
            locked: nextProps.locked,
            placeholder: nextProps.placeholder,
            channel: nextProps.channel,
            loadingSearch: nextProps.loadingSearch,
            loadingPagination: nextProps.loadingPagination,
            rightLabelText: nextProps.rightLabelText,
            pagination: nextProps.pagination,
            small: nextProps.small,
            total: nextProps.total,
            defaultOptions: nextProps.defaultOptions,
            skipInternalSearch: nextProps.skipInternalSearch,
            error: nextProps.error,
            addPlusExtra: nextProps.addPlusExtra,
            icon: nextProps.icon,
            channel_icon: nextProps.channel_icon,
            smallText: nextProps.smallText,
            removeButton: nextProps.removeButton,
            showTags: nextProps.showTags,
            subTitle: nextProps.subTitle
        })

    }

    functions = {
        resetSearch: () => {
            this.setState({
                search: ""
            })
        }
    };

    filter = () => {
        let options = this.state.options;
        try {
            if (!this.state.skipInternalSearch && this.state.search && this.props.searchInput && this.state.search !== "") {
                let result = fuzzysort.go(this.state.search, this.state.options.map((item) => { return item.name }));
                options = [];
                let old_options = JSON.parse(JSON.stringify(this.state.options));
                result.filter((item, index) => {
                    return index < 20
                }).map((item) => {
                    for (let i = 0; i < old_options.length; i++) {
                        if (("name" in old_options[i]) && item.target && item.target.toLowerCase() === old_options[i].name.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_options[i].html = html_string;
                            options.push(old_options[i]);
                            old_options.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                if (this.props.limitList) {
                    options = options.filter((item, index) => {
                        return index < 20
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
        return options;
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <Listbox disabled={this.state.disabled} value={this.state.selected} onChange={(value) => {
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            }}>
                {({ open }) => (
                    <>
                        <div className={(this.props.fillOut ? "flex-1" : "") + " flex flex-row"}>
                            {
                                this.state.channel &&
                                <div className="mr-1">
                                    <div className={"bg-" + this.state.channel + "-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>
                                        {
                                            this.state.channel === 'google' &&
                                            <img className="w-2"
                                                alt=""
                                                src={require('../assets/images/google_icon.png')} />
                                        }
                                        {
                                            this.state.channel === 'facebook' &&
                                            <img className="w-1"
                                                alt=""
                                                src={require('../assets/images/facebook_icon.svg')} />
                                        }
                                        {
                                            this.state.channel === 'linkedin' &&
                                            <img className="w-2"
                                                alt=""
                                                src={require('../assets/images/linkedin_icon.svg')} />
                                        }
                                        {
                                            this.state.channel === 'twitter' &&
                                            <img className="w-2"
                                                alt=""
                                                src={require('../assets/images/twitter_icon.svg')} />
                                        }
                                        {
                                            this.state.channel === 'tiktok' &&
                                            <img className="w-2"
                                                alt=""
                                                src={require('../assets/images/tiktok_icon.png')} />
                                        }
                                        {
                                            this.state.channel === 'instagram' &&
                                            <img className="w-2"
                                                alt=""
                                                src={require('../assets/images/instagram_icon.svg')} />
                                        }
                                        {
                                            this.state.channel === 'snapchat' &&
                                            <img className="w-2"
                                                alt=""
                                                src={require('../assets/images/snapchat.svg')} />
                                        }
                                        {
                                            this.state.channel === 'bing' &&
                                            <img className="w-1.5"
                                                alt=""
                                                src={require('../assets/images/bing-logo.png')} />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.label && this.state.label !== "" &&
                                <Listbox.Label className={"block text-xs font-medium text-gray-700" + (this.state.error ? " text-red-600" : "")}>{this.state.label}</Listbox.Label>
                            }
                            {
                                this.props.rightLabel &&
                                <div onClick={() => {
                                    if (this.props.onRightLabelClick) {
                                        this.props.onRightLabelClick();
                                    }
                                }} className={(this.props.rightLabelColor ? this.props.rightLabelColor : "hover:text-purple-500 text-gray-700") + " flex flex-1 justify-end text-xs cursor-pointer font-medium "}>
                                    {this.state.rightLabelText}
                                </div>
                            }
                        </div>
                        <div onClick={() => {
                            if (this.props.searchInput) {
                                setTimeout(() => {
                                    try {
                                        document.getElementById("test").focus();
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }, 100);
                            }
                        }} className={(this.props.fillOut ? "w-full " : "") + (this.state.label && this.state.label !== "" ? "mt-1" : "") + " relative"}>

                            {
                                this.state.loader &&
                                <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    <div className="ml-2 text-xs">
                                        Loading ...
                                    </div>
                                </div>
                            }

                            <Listbox.Button className={(this.props.clientSelect ? "bg-white bg-opacity-15 " : "bg-custom-input ") + (this.props.scale ? "h-10 text-xs" : (this.props.small ? "h-10 py-2 sm:text-sm " : "h-12 py-2 sm:text-sm ")) + (this.state.error ? "  border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500 " : " border-gray-300 ") + (this.props.noBorderSides ? " border-t-1.5 border-b-1.5 " : " border-1.5 rounded-md ") + " relative w-full  pl-3 pr-10 text-left cursor-default focus:outline-none"}>
                                <span className={((this.state.icon || this.state.channel_icon) ? "pl-11 " : "") + " flex items-center"}>
                                    {
                                        this.state.icon && !this.state.channel_icon &&
                                        <div className='px-3 absolute bg-gray-50 flex items-center rounded-l-md justify-center border-r-1.5 left-0 top-0 bottom-0'>
                                            <this.state.icon className="w-5" />
                                        </div>
                                    }
                                    {
                                        this.state.channel_icon && this.state.channel_icon !== "" &&
                                        <div className='px-3 absolute bg-gray-50 flex items-center rounded-l-md justify-center border-r-1.5 left-0 top-0 bottom-0'>
                                            <div className="">
                                                <div className={`bg-${this.state.selected.value}-500 p-1 h-5 w-5 items-center flex justify-center rounded-full`}>
                                                    <div>
                                                        <img className="w-2 h-2" src={
                                                            this.state.channel_icon === "facebook" ?
                                                                require('../assets/images/facebook_icon.svg')
                                                                : this.state.channel_icon === "google" ?
                                                                    require('../assets/images/google_icon.png')
                                                                    : this.state.channel_icon === "linkedin" ?
                                                                        require('../assets/images/linkedin_icon.svg')
                                                                        : this.state.channel_icon === "twitter" ?
                                                                            require('../assets/images/twitter_icon.svg')
                                                                            : this.state.channel_icon === "tiktok" ?
                                                                                require('../assets/images/tiktok_icon.png')
                                                                                : this.state.channel_icon === "instagram" ?
                                                                                    require('../assets/images/instagram_icon.svg')
                                                                                    : this.state.channel_icon === "snapchat" ?
                                                                                        require('../assets/images/snapchat.svg')
                                                                                        : ""
                                                        } alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.selected.id != 0 && this.state.selected.image &&
                                        <div className="h-5 w-5 mr-3 rounded-full flex-shrink-0 overflow-hidden justify-center flex items-center border-1.5 bg-white">
                                            <img src={this.state.selected.image} alt="" className="w-auto" />
                                        </div>
                                    }
                                    {
                                        this.state.selected.id != 0 && this.state.selected.color &&
                                        <div className={this.state.selected.color + " h-5 w-5 mr-3 rounded-full overflow-hidden justify-center flex items-center"}>

                                        </div>
                                    }
                                    {
                                        this.state.selected.icon &&
                                        <this.state.selected.icon className={(this.props.clientSelect ? "text-white " : "") + "w-5 h-5 mr-3"} />
                                    }
                                    {
                                        this.state.subTitle &&
                                        <span className={(this.state.disabled ? "opacity-25" : "") + " block truncate"}>{this.state.subTitle}</span>
                                    }
                                    {
                                        !this.state.subTitle &&
                                        <span className={(this.props.clientSelect ? "text-white " : "") + (this.state.disabled ? "opacity-25" : "") + " block truncate"}>{this.state.selected.name}</span>
                                    }
                                    {
                                        this.state.addPlusExtra > 0 &&
                                        <span className="text-purple-500 pl-1">+{this.state.addPlusExtra}</span>
                                    }
                                    {
                                        !this.state.selected.name && !this.state.placeholder &&
                                        <span className={(this.state.disabled ? "opacity-25" : "") + " block truncate"}>Click here</span>
                                    }
                                    {
                                        !this.state.selected.name && this.state.placeholder &&
                                        <span className={(this.state.disabled ? "opacity-25" : "") + " block truncate"}>{this.state.placeholder}</span>
                                    }
                                    {
                                        this.props.showTemplateType &&
                                        this.state.selected.template &&
                                        <span
                                            className={(this.state.selected.masterTemplate ? "bg-purple-100 text-purple-500 " : "bg-gray-100 text-gray-500 ") + " ml-2 px-3 py-1 inline-flex text-xxs font-bold rounded-full items-center justify-center"}>
                                            {this.state.selected.masterTemplate ? "Scale" : "Regular"}
                                            {
                                                this.state.selected.masterTemplate &&
                                                <StarIcon className="w-4 ml-1"></StarIcon>
                                            }
                                            {
                                                !this.state.selected.masterTemplate &&
                                                <ChartPieIcon className="w-4 ml-1"></ChartPieIcon>
                                            }
                                        </span>
                                    }
                                </span>
                                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                    {
                                        !this.state.locked && !this.state.removeButton &&
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    }
                                    {
                                        this.state.locked &&
                                        <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    }
                                </span>
                                {
                                    !this.state.locked && this.state.removeButton &&
                                    <div onClick={() => {
                                        if (this.props.onRemove) {
                                            this.props.onRemove();
                                        }
                                    }} className="inline-flex absolute right-0 top-0 bottom-0 cursor-pointer mr-4 items-center justify-center hover:text-red-700 text-red-500 flex-row">
                                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                        <div className="text-xs ml-1">Remove</div>
                                    </div>
                                }
                            </Listbox.Button>

                            {
                                !this.state.locked &&
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute border-1.5 border-gray-300 z-70 mt-1 w-full bg-white shadow-lg max-h-40 rounded-md p-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
                                        {
                                            this.props.searchInput &&
                                            <div className="sticky top-0 z-55 left-0 right-0">
                                                <div className={(this.filter().length > 0 ? "mb-1" : "") + " bg-gray-100 rounded-md justify-center items-center overflow-hidden flex flex-row"}>
                                                    <div className="px-3 text-gray-500 text-opacity-75">
                                                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                                    </div>
                                                    <input
                                                        id={"test"}
                                                        type={this.props.type}
                                                        disabled={this.state.disabled}
                                                        className={(this.props.scale ? "h-10 tiny-input" : (this.props.small ? "h-10 py-2 text-sm " : "h-12 py-2 text-sm ")) + " bg-gray-100 block font-normal w-full border-none pr-4 focus:outline-none"}
                                                        placeholder={this.state.placeholder}
                                                        value={this.state.search}
                                                        onChange={(event) => {
                                                            if (this.props.onSearch) {
                                                                this.props.onSearch(event.target.value);
                                                            }
                                                            this.setState({
                                                                search: event.target.value
                                                            })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode == 32) {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className={"relative"}>
                                            {
                                                this.props.searchInput &&
                                                this.filter().length > 0 &&
                                                this.state.loadingSearch &&
                                                <div className="bg-white z-55 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            {
                                                Array.isArray(this.state.defaultOptions) &&
                                                this.state.defaultOptions.length > 0 &&
                                                this.state.defaultOptions.map((item) => {
                                                    return (
                                                        <Listbox.Option
                                                            key={item.id}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'text-gray-900 bg-gray-100' : 'text-gray-900 bg-gray-50',
                                                                    !this.props.scale && this.props.small ? "h-10 py-2 text-sm" : 'h-12 py-2 text-sm',
                                                                    this.props.scale ? "h-10 text-xs" : '',
                                                                    'cursor-pointer select-none relative pl-3 pr-9 rounded-md focus:outline-none items-center flex'
                                                                )
                                                            }
                                                            value={item}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <div className="flex items-center">
                                                                        {
                                                                            item.icon &&
                                                                            <item.icon className={this.props.scale ? "w-5 mr-3" : "w-5 h-5 mr-3"} />
                                                                        }
                                                                        <span className={(this.props.scale ? "text-xs " : "text-sm") + (selected ? " text-purple-500 " : "") + " truncate"}>
                                                                            {item.name}
                                                                        </span>
                                                                        {
                                                                            this.props.showType &&
                                                                            <span className="text-xxs ml-2 font-medium px-2 py-1 rounded-full bg-gray-200">
                                                                                {item.type}
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                            }
                                                        </Listbox.Option>
                                                    )
                                                })
                                            }
                                            {
                                                this.filter().map((item) => {
                                                    return (
                                                        <Listbox.Option
                                                            key={item.id}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'text-gray-900 bg-gray-100' : 'text-gray-900',
                                                                    !this.props.scale && this.props.small ? "h-10 py-2" : 'h-12 py-2',
                                                                    this.props.scale ? "h-10 text-xs" : 'text-sm"',
                                                                    this.state.smallText ? "text-sm" : "",
                                                                    'cursor-pointer select-none relative pl-3 pr-9 rounded-md focus:outline-none items-center flex'
                                                                )
                                                            }
                                                            value={item}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <div className="flex items-center">
                                                                        {
                                                                            ("image" in item) &&
                                                                            <div className={(this.props.showStatus ? ((item.status == "paused" || item.status == "disabled") ? "border-orange-500" : "border-green-500") : "") + " h-5 w-5 mr-3 rounded-full overflow-hidden justify-center flex items-center border-1.5 bg-white"}>
                                                                                <img src={item.image} alt="" className="w-auto" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.color &&
                                                                            <div className={item.color + " h-5 w-5 mr-3 rounded-full overflow-hidden justify-center flex items-center"}>

                                                                            </div>
                                                                        }
                                                                        <span
                                                                            dangerouslySetInnerHTML={{ __html: item.html ? item.html : item.name }}
                                                                            className={(this.props.scale ? " text-xs " : " text-sm ") + (selected ? ' text-purple-500 ' : ' font-normal ') + ' block truncate font-normal'}
                                                                        >
                                                                        </span>
                                                                        {
                                                                            this.props.showType &&
                                                                            <span className="text-xxs ml-2 font-medium px-2 py-1 rounded-full bg-gray-200">
                                                                                {item.type}
                                                                            </span>
                                                                        }
                                                                        {
                                                                            this.state.showTags && Array.isArray(item.tags) &&
                                                                            item.tags.map((item) => {
                                                                                return (
                                                                                    <span className="text-xxs ml-2 font-medium px-2 py-1 rounded-full bg-gray-200">
                                                                                        {item.name}
                                                                                    </span>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            this.props.showTemplateType &&
                                                                            <span
                                                                                className={(item.masterTemplate ? "bg-purple-100 text-purple-500 " : "bg-gray-100 text-gray-500 ") + " ml-2 px-3 py-1 inline-flex text-xxs font-bold rounded-full items-center justify-center"}>
                                                                                {item.masterTemplate ? "Scale" : "Regular"}
                                                                                {
                                                                                    item.masterTemplate &&
                                                                                    <StarIcon className="w-4 ml-1"></StarIcon>
                                                                                }
                                                                                {
                                                                                    !item.masterTemplate &&
                                                                                    <ChartPieIcon className="w-4 ml-1"></ChartPieIcon>
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            this.state.pagination &&
                                            <div className="sticky bottom-0 z-55 left-0 right-0 pt-1">
                                                <div onClick={() => {
                                                    if (this.props.onPagination && !this.state.loadingPagination) {
                                                        this.props.onPagination();
                                                    }
                                                }} className={(this.props.scale ? "h-10 text-xs" : (this.props.small ? "h-10 text-sm " : "h-12 text-sm ")) + " bg-purple-500 relative flex text-center font-medium cursor-pointer hover:bg-purple-600 text-white rounded-md justify-center items-center overflow-hidden"}>
                                                    Load more {!this.props.dontShowPaginationAmount && <span>({this.state.options.length}/{this.state.total})</span>}
                                                    {
                                                        this.state.loadingPagination &&
                                                        <div className="absolute flex items-center justify-center left-0 top-0 bottom-0 right-0 bg-purple-500">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                className="w-4 h-4 border-2 border-white-500 border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Listbox.Options>
                                </Transition>
                            }
                        </div>
                    </>
                )
                }
            </Listbox>
        )
    }
}

export default DropdownTailwind;
