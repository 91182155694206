import React, { Component, Fragment } from 'react';
import AdvertisingCreativesVertical from '../components/advertising-creatives-vertical';
import PreviewFacebookCarousel from '../previews/facebook/carousel';
import PreviewInstagramCarousel from '../previews/instagram/carousel';
import PreviewInstagramStory from '../previews/instagram/story';
import PreviewInstagramFeed from '../previews/instagram/feed';
import PreviewFacebookStory from '../previews/facebook/story';
import PreviewFacebookFeed from '../previews/facebook/feed';
import PreviewFacebookCollectionAd from '../previews/facebook/collection';
import PreviewInstagramCollectionAd from '../previews/instagram/collection';
import facebook_cta from '../assets/json/facebook_cta.json';

class PreviewSectionFacebook extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: {},
            channel: {},
            for_report: false,
            colors: [],
            preview_placement: "",
            is_post: false,
        };
    }

    componentWillMount() { }

    async componentDidMount() {
        await this.promisedSetState({
            ad: this.props.ad ? this.props.ad : {},
            channel: this.props.channel,
            for_report: this.props.for_report ? this.props.for_report : false,
            colors: this.props.ad.colors ? this.props.ad.colors : [],
            preview_placement: this.props.preview_placement ? this.props.preview_placement : "feed",
            is_post: this.props.is_post ? this.props.is_post : false
        });
        try {
            const slider = document.querySelector('#carousel_container');
            let isDown = false;
            let startX;
            let scrollLeft;
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 1; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        } catch (e) { }
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            channel: nextProps.channel,
            for_report: nextProps.for_report,
            colors: nextProps.ad && nextProps.ad.colors ? nextProps.ad.colors : [],
            preview_placement: nextProps.preview_placement ? nextProps.preview_placement : "",
            is_post: nextProps.is_post ? nextProps.is_post : false,
        });
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {

    }

    renders = {
        title: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.headlines)) {
                        let headline = "";
                        item.headlines.map((item) => {
                            headline = item.value;
                        });
                        return headline;
                    }
                } else if ('title' in item) {
                    return item.title
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        image: (item) => {
            try {
                if (this.state.for_report && item.ad_type === "merge_ad" && Array.isArray(item.slides)) {
                    if (this.state.preview_placement === "story" || this.state.preview_placement === "story_instagram") {
                        if (item.slides.filter(slide => slide.media_type === "vertical").length > 0) {
                            return item.slides.filter(slide => slide.media_type === "vertical")[0].image
                        } else if (item.slides.length > 0) {
                            return item.slides[0].image
                        }
                    } else {
                        if (item.slides.filter(slide => slide.media_type === "square").length > 0) {
                            return item.slides.filter(slide => slide.media_type === "square")[0].image
                        } else if (item.slides.length > 0) {
                            return item.slides[0].image
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
            try {
                if (item.ad_type && item.ad_type.value === "posts" || item.adData && item.adData.ad_type && item.adData.ad_type.value === "posts") {
                    if (item.image && item.image !== "") {
                        return item.image
                    } else if (item.picture && item.picture !== "") {
                        return item.picture;
                    } else {
                        return ""
                    }
                }
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        if (item.slides.filter((slide) => { return slide.ratio === "16:9" }).length > 0) {
                            return item.slides.filter((slide) => { return slide.ratio === "16:9" })[0].url
                        } else if (item.slides.filter((slide) => { return slide.ratio === item.preview.ratio }).length > 0) {
                            return item.slides.filter((slide) => { return slide.ratio === item.preview.ratio })[0].picture
                        } else {
                            return "";
                        }
                    }
                } else if ('picture' in item) {
                    return item.picture
                }
                else if ('image' in item) {
                    return item.image
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        video: (item) => {
            try {
                if (this.state.for_report && item.ad_type === "merge_ad" && Array.isArray(item.slides)) {
                    if (this.state.preview_placement === "story" || this.state.preview_placement === "story_instagram") {
                        if (item.slides.filter(slide => slide.media_type === "vertical").length > 0) {
                            return item.slides.filter(slide => slide.media_type === "vertical")[0].video
                        } else if (item.slides.length > 0) {
                            return item.slides[0].video
                        }
                    } else {
                        if (item.slides.filter(slide => slide.media_type === "square").length > 0) {
                            return item.slides.filter(slide => slide.media_type === "square")[0].video
                        } else if (item.slides.length > 0) {
                            return item.slides[0].video
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
            try {
                if (item.adData && item.adData.ad_type && item.adData.ad_type.value === "posts") {
                    if (item.adData.ad_type.type === "video") {
                        return item.video
                    }
                } else if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        if (item.slides.filter((slide) => { return slide.ratio === "16:9" }).length > 0) {
                            return item.slides.filter((slide) => { return slide.ratio === "16:9" })[0].url
                        } else if (item.slides.filter((slide) => { return slide.ratio === item.preview.ratio }).length > 0) {
                            return item.slides.filter((slide) => { return slide.ratio === item.preview.ratio })[0].url
                        } else {
                            return "";
                        }
                    }
                } else if ('video' in item) {
                    return item.video
                } else if ('url' in item) {
                    return item.url
                    // } else if ('video' in item) {
                    //     return item.video
                } else {
                    return "";
                }
            } catch (error) {
                console.log(error);
                return "";
            }
        },
        body: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.bodies)) {
                        let body = "";
                        item.bodies.map((item) => {
                            body = item.value;
                        });
                        return body;
                    }
                } else if ('body' in item) {
                    return item.body;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        description: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.descriptions)) {
                        let description = "";
                        item.descriptions.map((item) => {
                            description = item.value;
                        });
                        return description;
                    }
                } else if ('description' in item) {
                    return item.description
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        caption: (item) => {
            try {
                if ('caption' in item) {
                    return item.caption;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.links)) {
                        let link = "";
                        item.links.map((item) => {
                            link = item.value;
                        });
                        return link;
                    }
                } else if ('link' in item) {
                    return item.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        cta: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.call_to_actions)) {
                        let cta = { id: 0, name: "No Button", type: "NO_BUTTON" };
                        item.call_to_actions.map((item) => {
                            cta = item.value;
                        });
                        return cta;
                    }
                } else if (this.state.for_report && (this.state.preview_placement === "story_instagram" || this.state.preview_placement === "feed_instagram") && item.ad_type === "carousel_feed") {
                    let buff = item.cta ? item.cta : (item.call_to_action ? item.call_to_action : false);
                    if (buff) {
                        return facebook_cta.find((cta => cta.type.includes(buff)));
                    } else {
                        return { id: 0, name: "No Button", type: "NO_BUTTON" };
                    }
                    //return facebook_cta.find((cta => cta.type.includes(item.call_to_action ? item.call_to_action : item.cta)));
                } else if (this.state.for_report && (this.state.preview_placement === "feed" || this.state.preview_placement === "story") && item.ad_type === "carousel_feed") {

                    if (item.slides && item.slides.length > 0) {
                        item.slides.map((slide) => {
                            let buff = slide.call_to_action;
                            return slide.cta = facebook_cta.find((cta => cta.type.includes(buff)));
                        })
                    }

                } else if (this.state.for_report && (this.state.preview_placement === "feed" || this.state.preview_placement === "story" || this.state.preview_placement === "feed_instagram" || this.state.preview_placement === "story_instagram") && item.adData && item.adData.ad_type.value === "carousel_posts") {
                    let buff = item.cta ? item.cta : (item.call_to_action ? item.call_to_action : false);
                    if (buff) {
                        return facebook_cta.find((cta => cta.type.includes(buff)));
                    } else {
                        return false;
                    }
                    //console.log("HERE WE ARE", facebook_cta.find(cta => cta.id === item.cta ? item.cta : item.call_to_action))
                    //return facebook_cta.find(cta => cta.id === item.cta ? item.cta : item.call_to_action);
                } else if (item.cta) {
                    return item.cta;
                } else if (item.call_to_action) {
                    return facebook_cta.find((cta => cta.type.includes(item.call_to_action)));
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" }
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        page: (item) => {
            try {
                if (this.state.preview_placement === "story" || this.state.preview_placement === "feed") {
                    return item.page
                } else if (this.state.preview_placement === "story_instagram" || this.state.preview_placement === "feed_instagram") {
                    return item.instagram_page ? item.instagram_page : item.page
                } else if ('page' in item) {
                    return item.page
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        creative: (item, placement_format) => {
            let creative = null;
            try {
                if (item.ad_type && item.ad_type.value === "video_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "video" && !item.files)) {
                    if (item.video) {
                        creative = { thumbnail: item.thumbnail ? item.thumbnail : "", video: item.video };
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "image_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "image" && !item.files)) {
                    if (item.picture && item.picture !== "") {
                        creative = item.picture;
                    }
                }
            } catch (eror) {
            }
            try {
                if ((item.ad_type && item.ad_type.value === "posts" && item.files)) {
                    if (Array.isArray(item.files)) {
                        creative = item.files.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: (item.type === "VIDEO" || item.type === "video") ? true : false,
                                image: (item.type === "IMAGE" || item.type === "image") ? true : false,
                                loading: item.loading,
                                creative: {
                                    thumbnail: { url: item.thumbnail ? item.thumbnail : "" },
                                    url: item.url,
                                    ratio: "1:1"
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "carousel_ad") {
                    if (Array.isArray(item.slides)) {
                        creative = item.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        item.slides.map((slide) => {
                            if (item.preview && item.preview.ratio === slide.ratio) {
                                if (slide.type === 'image') {
                                    creative = slide.picture;
                                } else {
                                    creative = { thumbnail: slide.thumbnail, video: slide.url };
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "catalog_ad") {
                    if (Array.isArray(item.preview_products)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "search_ad") {
                    if (Array.isArray(item.combinations)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            return creative;
        },
        thumbnail: (item) => {
            try {
                if (Array.isArray(item.slides)) {
                    return item.slides[0].thumbnail
                } else {
                    return ""
                }
            } catch (error) {
                console.log(error)
                return ""
            }
        }
    }

    render() {

        let ad = this.state.ad;

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                {
                    ad.preview && (ad.preview.value === "video_story" || ad.preview.value === "image_story") && false &&
                    <AdvertisingCreativesVertical
                        page={this.renders.page(ad)}
                        loader={false}
                        creatives={[
                            {
                                video: ad.preview.value === 'video_story',
                                call_to_action: this.renders.cta(ad, 'vertical'),
                                headline: this.renders.title(ad, 'vertical'),
                                description: this.renders.description(ad, 'vertical'),
                                display_website: this.renders.caption(ad, 'vertical'),
                                body: this.renders.body(ad, 'vertical'),
                                ...(ad.preview.value === 'video_story' && {
                                    creative: {
                                        url: (this.renders.creative(ad, 'vertical') ? this.renders.creative(ad, 'vertical').video : ""),
                                        thumbnail: { url: (this.renders.creative(ad, 'vertical') ? this.renders.creative(ad, 'vertical').thumbnail : "") }
                                    }
                                }),
                                ...(ad.preview.value === 'image_story' && {
                                    creative: { url: this.renders.creative(ad, 'vertical') }
                                })
                            }
                        ]}
                        noContainer={true}
                    />
                }
                {
                    this.state.channel.value === "facebook" &&
                    ad.preview && ad.preview.value === "video_story" && !ad.collection_ad &&
                    <PreviewFacebookStory
                        page={this.renders.page(ad)}
                        video={this.renders.video(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        adData={this.state.ad}
                        colors={this.state.ad.colors}
                        is_post={this.state.is_post}

                    />

                }
                {
                    this.state.channel.value === "facebook" &&
                    ad.preview && ad.preview.value === "image_story" && !ad.collection_ad &&
                    <PreviewFacebookStory
                        page={this.renders.page(ad)}
                        image={this.renders.image(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        adData={this.state.ad}
                        colors={this.state.ad.colors}
                        is_post={this.state.is_post}

                    />
                }
                {
                    this.state.channel.value === "facebook" &&
                    ad.preview && ad.preview.value === "image_feed" && !ad.collection_ad &&
                    <PreviewFacebookFeed
                        page={this.renders.page(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        body={this.renders.body(ad, 'not_vertical')}
                        title={this.renders.title(ad, 'not_vertical')}
                        image={this.renders.image(ad)}
                        description={this.renders.description(ad, 'not_vertical')}
                        link={this.renders.link(ad, 'not_vertical')}
                        caption={this.renders.caption(ad, 'not_vertical')}
                        adData={this.state.ad}
                        is_post={this.state.is_post}
                    />
                }
                {
                    this.state.channel.value === "facebook" &&
                    ad.preview && ad.preview.value === "video_feed" && !ad.collection_ad &&
                    <PreviewFacebookFeed
                        page={this.renders.page(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        body={this.renders.body(ad, 'not_vertical')}
                        title={this.renders.title(ad, 'not_vertical')}
                        video={this.renders.video(ad)}
                        description={this.renders.description(ad, 'not_vertical')}
                        link={this.renders.link(ad, 'not_vertical')}
                        caption={this.renders.caption(ad, 'not_vertical')}
                        adData={this.state.ad}
                        is_post={this.state.is_post}
                    />
                }
                {
                    this.state.channel.value === "instagram" &&
                    ad.preview && ad.preview.value === "video_story" &&
                    <PreviewInstagramStory
                        page={this.renders.page(ad)}
                        video={this.renders.video(ad)}
                        body={this.renders.body(ad, 'vertical')}
                        cta={this.renders.cta(ad, 'vertical')}
                        colors={this.state.ad.colors}
                        adData={this.state.ad}
                        is_post={this.state.is_post}

                    />
                }
                {
                    this.state.channel.value === "instagram" &&
                    ad.preview && ad.preview.value === "image_story" &&
                    <PreviewInstagramStory
                        page={this.renders.page(ad)}
                        image={this.renders.image(ad)}
                        body={this.renders.body(ad, 'vertical')}
                        cta={this.renders.cta(ad, 'vertical')}
                        colors={this.state.ad.colors}
                        adData={this.state.ad}
                        is_post={this.state.is_post}

                    />
                }
                {
                    this.state.channel.value === "instagram" &&
                    ad.preview && ad.preview.value === "video_feed" && !ad.collection_ad &&
                    <PreviewInstagramFeed
                        page={this.renders.page(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        title={this.renders.title(ad, 'not_vertical')}
                        video={this.renders.video(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        adData={this.state.ad}
                        is_post={this.state.is_post}
                    />
                }
                {
                    this.state.channel.value === "instagram" &&
                    ad.preview && ad.preview.value === "image_feed" && !ad.collection_ad &&
                    <PreviewInstagramFeed
                        page={this.renders.page(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        title={this.renders.title(ad, 'not_vertical')}
                        picture={this.renders.image(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        adData={this.state.ad}
                        is_post={this.state.is_post}

                    />
                }
                {
                    this.state.channel.value === "facebook" &&
                    ad.preview && ad.preview.value === "carousel_feed" && !ad.collection_ad &&
                    <PreviewFacebookCarousel
                        page={this.renders.page(ad)}
                        slides={this.state.ad.ad_type.value === "catalog_ad" ? this.state.ad.preview_products.map((item) => {
                            return {
                                picture: item.image_url,
                                name: item.name,
                                type: "image",
                                body: item.price,
                                title: item.name
                            }
                        }) : (this.state.ad.ad_type.value === "posts" || this.state.ad.type === "posts") && Array.isArray(this.state.ad.files) ? this.state.ad.files.map(item => {
                            return {
                                type: item.type,
                                ...item.type === "image" && { picture: item.url },
                                ...item.type === "video" && { url: item.url },
                                cta: this.state.ad && this.state.ad.cta || { id: 0, name: "No Button", type: "NO_BUTTON" },
                            }
                        })
                            :
                            this.state.ad.slides}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        body={this.renders.body(ad, 'not_vertical')}
                        adData={this.state.ad}
                        // noCta={this.state.ad.ad_type.value === "catalog_ad" ? true : false}
                        noCta={false}
                        for_report={this.state.for_report}
                        is_post={this.state.is_post}

                    />
                }
                {
                    this.state.channel.value === "facebook" && ad.collection_ad &&
                    <PreviewFacebookCollectionAd
                        page={this.renders.page(ad)}
                        slides={this.state.ad.ad_type.value === "catalog_ad" ? (this.state.ad.instantExperience && this.state.ad.instantExperience.elements && this.state.ad.instantExperience.elements.carousel ? this.state.ad.instantExperience.elements.carousel.map((item) => {
                            return {
                                picture: item.image_url,
                                name: item.id,
                                type: "image",
                                title: item.id
                            }
                        }) : (this.state.ad.instantExperience && this.state.ad.instantExperience.elements && this.state.ad.instantExperience.elements.product_set ? this.state.ad.instantExperience.elements.product_set.map((item) => {
                            return {
                                picture: item.image_url,
                                name: item.id,
                                type: "image",
                                title: item.id
                            }
                        }) : this.state.ad.slides)) : this.state.ad.slides}
                        video={this.state.ad.instantExperience && this.state.ad.instantExperience.elements && this.state.ad.instantExperience.elements.video ? this.state.ad.instantExperience.elements.video.video : null}
                        image={this.state.ad.instantExperience && this.state.ad.instantExperience.elements && this.state.ad.instantExperience.elements.photo ? this.state.ad.instantExperience.elements.photo.photo : null}
                        title={this.renders.title(ad, 'not_vertical')}
                        body={this.renders.body(ad, 'not_vertical')}
                        adData={this.state.ad}
                        is_post={this.state.is_post}

                    />
                }
                {
                    this.state.channel.value === "instagram" &&
                    ad.preview && ad.preview.value === "carousel_feed" && !ad.collection_ad &&
                    <PreviewInstagramCarousel
                        page={this.renders.page(ad)}
                        slides={ad && ad.ad_type && ad.ad_type.value === "catalog_ad" ? this.state.ad.preview_products.map((item) => {
                            return {
                                picture: item.image_url,
                                name: item.name,
                                type: "image",
                                body: item.price,
                                headline: item.name
                            }
                        }) : ((ad.ad_type.value === "posts") || (this.state.ad.type === "posts")) && Array.isArray(this.state.ad.files) ? this.state.ad.files.map(item => {
                            return {
                                type: item.type,
                                ...item.type === "image" && { picture: item.url },
                                ...item.type === "video" && { url: item.url },
                                cta: this.state.ad && this.state.ad.cta || { id: 0, name: "No Button", type: "NO_BUTTON" },
                            }
                        }) : this.state.ad.slides}
                        body={this.renders.body(ad, 'not_vertical')}
                        headline={this.renders.title(ad, 'not_vertical')}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        // noCta={ad && ad.ad_type && ad.ad_type.value === "catalog_ad" ? true : false}
                        noCta={false}
                        adData={this.state.ad}
                        is_post={this.state.is_post}

                    />
                }
                {
                    this.state.channel.value === "instagram" && ad.collection_ad &&
                    <PreviewInstagramCollectionAd
                        page={this.renders.page(ad)}
                        slides={this.state.ad.ad_type.value === "catalog_ad" ? (this.state.ad.instantExperience && this.state.ad.instantExperience.elements && this.state.ad.instantExperience.elements.carousel ? this.state.ad.instantExperience.elements.carousel.map((item) => {
                            return {
                                picture: item.image_url,
                                name: item.id,
                                type: "image",
                                title: item.id
                            }
                        }) : (this.state.ad.instantExperience && this.state.ad.instantExperience.elements && this.state.ad.instantExperience.elements.product_set ? this.state.ad.instantExperience.elements.product_set.map((item) => {
                            return {
                                picture: item.image_url,
                                name: item.id,
                                type: "image",
                                title: item.id
                            }
                        }) : this.state.ad.slides)) : this.state.ad.slides}
                        body={this.renders.body(ad, 'not_vertical')}
                        headline={this.renders.title(ad, 'not_vertical')}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        // noCta={this.state.ad.ad_type.value === "catalog_ad" ? true : false}
                        noCta={false}
                        video={this.state.ad.instantExperience && this.state.ad.instantExperience.elements && this.state.ad.instantExperience.elements.video ? this.state.ad.instantExperience.elements.video.video : null}
                        picture={this.state.ad.instantExperience && this.state.ad.instantExperience.elements && this.state.ad.instantExperience.elements.photo ? this.state.ad.instantExperience.elements.photo.photo : null}
                        adData={this.state.ad}
                        is_post={this.state.is_post}

                    />
                }

                {/* FOR REPORTS  */}
                {
                    this.state.for_report &&
                    this.state.preview_placement === "feed" &&
                    (ad.ad_type === "merge_ad" || (!ad.ad_type && !ad.adData) || (!ad.ad_type && ad.adData && ad.adData.ad_type && ad.adData.ad_type.value !== "carousel_posts")) &&
                    <PreviewFacebookFeed
                        page={this.renders.page(ad)}
                        image={this.renders.image(ad)}
                        video={this.renders.video(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        caption={this.renders.caption(ad)}
                        title={this.renders.title(ad)}
                        link={this.renders.link(ad)}
                        description={this.renders.description(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        adData={this.state.ad}
                        colors={this.state.ad.colors}
                        thumbnail={this.renders.thumbnail(ad)}
                        for_report={true}
                        is_post={this.state.is_post ? this.state.is_post : false}

                    />
                }
                {
                    this.state.for_report &&
                    this.state.preview_placement === "story" &&
                    (ad.ad_type === "merge_ad" || (!ad.ad_type && !ad.adData) || (!ad.ad_type && ad.adData && ad.adData.ad_type && ad.adData.ad_type.value !== "carousel_posts")) &&
                    <PreviewFacebookStory
                        page={this.renders.page(ad)}
                        image={this.renders.image(ad)}
                        video={this.renders.video(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        caption={this.renders.caption(ad)}
                        title={this.renders.title(ad)}
                        link={this.renders.link(ad)}
                        description={this.renders.description(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        adData={this.state.ad}
                        colors={this.state.ad.colors}
                        for_report={true}
                        thumbnail={this.renders.thumbnail(ad)}
                    />
                }

                {
                    this.state.for_report &&
                    (this.state.preview_placement !== "no_frame" && this.state.preview_placement !== "feed_instagram" || this.state.preview_placement !== "story_instagram") &&
                    (this.state.preview_placement === "feed" || this.state.preview_placement === "story") &&
                    ad.ad_type === "carousel_feed" &&
                    <PreviewFacebookCarousel
                        page={this.renders.page(ad)}
                        slides={this.state.ad.slides}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        body={this.renders.body(ad, 'not_vertical')}
                        adData={this.state.ad}
                        // noCta={this.state.ad.ad_type.value === "catalog_ad" ? true : false}
                        noCta={false}
                        for_report={true}
                    />
                }
                {
                    this.state.for_report &&
                    (this.state.preview_placement !== "no_frame" && this.state.preview_placement !== "feed_instagram" || this.state.preview_placement !== "story_instagram") &&
                    (this.state.preview_placement === "feed" || this.state.preview_placement === "story") &&
                    ad.adData && ad.adData && ad.adData.ad_type && ad.adData.ad_type.value === "carousel_posts" &&
                    <PreviewFacebookCarousel
                        page={this.renders.page(ad)}
                        slides={this.state.ad.slides ? this.state.ad.slides.map((item) => {
                            return {
                                ...item.type === "image" && { picture: item.picture },
                                ...item.type === "video" && { url: item.video },
                                headline: null,
                                type: item.type,
                                cta: this.renders.cta(ad, 'not_vertical'),
                            }
                        }) : []}
                        // cta={this.renders.cta(ad, 'not_vertical')}
                        body={this.renders.body(ad, 'not_vertical')}
                        adData={this.state.ad}
                        share={this.state.ad.share ? true : false}
                        noCta={false}
                        for_report={true}
                        is_post={this.state.is_post ? this.state.is_post : false}

                    />
                }

                {
                    this.state.for_report &&
                    this.state.preview_placement !== "no_frame" &&
                    (this.state.preview_placement !== "no_frame" && this.state.preview_placement !== "feed_instagram" || this.state.preview_placement !== "story_instagram") &&
                    (this.state.preview_placement === "feed" || this.state.preview_placement === "story") &&
                    ad.ad_type === "collection" &&
                    <PreviewFacebookCollectionAd
                        page={this.renders.page(ad)}
                        slides={this.state.ad.slides}
                        video={this.renders.video(ad)}
                        image={this.renders.image(ad)}
                        title={this.renders.title(ad)}
                        body={this.renders.body(ad)}
                        adData={{}}
                        description={this.renders.description(ad)}
                        link={this.renders.link}
                        caption={this.renders.caption(ad)}
                        for_report={true}
                    />
                }

                {
                    this.state.for_report &&
                    (this.state.preview_placement === "feed" || this.state.preview_placement === "story") &&
                    ad.ad_type === "catalog_ad" &&
                    <PreviewFacebookCarousel
                        page={this.renders.page(ad)}
                        slides={this.state.ad.slides}
                        body={this.renders.body(ad, 'not_vertical')}
                        adData={this.state.ad}
                        // noCta={this.state.ad.ad_type === "catalog_ad" ? true : false}
                        noCta={false}
                        for_report={true}
                    />
                }

                {/* REPORT INSTAGRAM */}

                {
                    this.state.for_report &&
                    this.state.preview_placement === "feed_instagram" &&
                    (ad.ad_type === "merge_ad" || (!ad.ad_type && !ad.adData) || (!ad.ad_type && ad.adData && ad.adData.ad_type && ad.adData.ad_type.value !== "carousel_posts")) &&
                    <PreviewInstagramFeed
                        page={this.renders.page(ad)}
                        picture={this.renders.image(ad)}
                        video={this.renders.video(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        adData={this.state.ad}
                        colors={this.state.ad.colors}
                        thumbnail={this.renders.thumbnail(ad)}
                        for_report={true}
                    />
                }

                {
                    this.state.for_report &&
                    this.state.preview_placement === "story_instagram" &&
                    (ad.ad_type === "merge_ad" || (!ad.ad_type && !ad.adData) || (!ad.ad_type && ad.adData && ad.adData.ad_type && ad.adData.ad_type.value !== "carousel_posts")) &&
                    <PreviewInstagramStory
                        page={this.renders.page(ad)}
                        image={this.renders.image(ad)}
                        video={this.renders.video(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        adData={this.state.ad}
                        colors={this.state.ad.colors}
                        thumbnail={this.renders.thumbnail(ad)}
                        for_report={true}
                    />
                }

                {
                    this.state.for_report &&
                    (this.state.preview_placement === "feed_instagram" || this.state.preview_placement === "story_instagram") &&
                    ad.ad_type === "carousel_feed" &&
                    <PreviewInstagramCarousel
                        page={this.renders.page(ad)}
                        cta={this.renders.cta(ad)}
                        body={this.renders.body(ad)}
                        adData={this.state.ad}
                        noCta={false}
                        slides={this.state.ad.slides.map(slide => {
                            return {
                                ...slide.type === "image" && { picture: slide.picture },
                                ...slide.type === "video" && { url: slide.url },
                                headline: slide.title,
                                type: slide.type,
                            }
                        })}
                        for_report={true}
                    />
                }
                {
                    this.state.for_report &&
                    (this.state.preview_placement === "feed_instagram" || this.state.preview_placement === "story_instagram") &&
                    ad.adData && ad.adData.ad_type.value === "carousel_posts" &&
                    <>
                        <PreviewInstagramCarousel
                            page={this.renders.page(ad)}
                            cta={this.renders.cta(ad)}
                            body={this.renders.body(ad)}
                            adData={this.state.ad}
                            noCta={false}
                            slides={this.state.ad.slides.map(slide => {
                                return {
                                    ...slide.type === "image" && { picture: slide.picture },
                                    ...slide.type === "video" && slide.video ? { video: slide.video } : { url: slide.url },
                                    headline: slide.title,
                                    type: slide.type,
                                }
                            })}
                            for_report={true}
                            is_post={this.state.is_post ? this.state.is_post : false}
                        />
                    </>

                }

                {
                    this.state.for_report &&
                    (this.state.preview_placement === "feed_instagram" || this.state.preview_placement === "story_instagram") &&
                    ad.ad_type === "catalog_ad" &&
                    <PreviewInstagramCarousel
                        page={this.renders.page(ad)}
                        cta={this.renders.cta(ad)}
                        body={this.renders.body(ad)}
                        adData={this.state.ad}
                        noCta={true}
                        slides={this.state.ad.slides.map(slide => {
                            return {
                                picture: slide.picture,
                                headline: slide.title,
                                type: slide.type
                            }
                        })}
                        for_report={true}
                    />
                }

                {
                    this.state.for_report &&
                    (this.state.preview_placement === "feed_instagram" || this.state.preview_placement === "story_instagram") &&
                    ad.ad_type === "collection" &&
                    <PreviewInstagramCollectionAd
                        page={this.renders.page(ad)}
                        slides={ad.ad_type === "carousel_feed" ? ad.slides.map((item) => {
                            return {
                                picture: item.picture,
                                name: item.name,
                                type: "image",
                                body: item.body,
                                title: item.name
                            }
                        }) : this.state.ad.slides}
                        video={this.renders.video(ad)}
                        picture={this.renders.image(ad)}
                        title={this.renders.title(ad)}
                        body={this.renders.body(ad)}
                        adData={{}}
                        description={this.renders.description(ad)}
                        link={this.renders.link(ad)}
                        caption={this.renders.caption(ad)}
                        for_report={true}
                    />
                }

                {/* REPORT NO FRAME */}

                {/* IMAGE OR VIDEO */}
                {
                    this.state.for_report &&
                    (this.state.preview_placement === "" || this.state.preview_placement === "no_frame") &&
                    !ad.ad_type &&
                    <div>
                        {
                            ad.video && ad.video !== "" &&
                            (<video className="w-full h-full" autoplay={false} controls={true} loop={false} muted={true}>
                                <source src={this.renders.video(ad)} type="video/mp4" />
                            </video>)
                        }
                        {
                            ad.image && ad.image !== "" &&
                            (<img
                                className="h-full object-contain"
                                src={this.renders.image(ad)}
                                alt={ad && ad.body ? ad.body : "Creative image"}
                            />)
                        }
                    </div>
                }

                {/* CAROUSEL NO FRAME */}
                {
                    this.state.for_report &&
                    (this.state.preview_placement === "" || this.state.preview_placement === "no_frame") &&
                    (ad.ad_type === "carousel_feed" || ad.ad_type === "catalog_ad") &&
                    <div
                        id={"carousel_container"}
                        className='flex flex-col overflow-x-auto justify-center cursor-pointer select-none'>
                        <div className='flex space-x-1'>
                            {
                                Array.isArray(ad.slides) && ad.slides.length > 0 &&
                                ad.slides.map((slide, index) => {
                                    return (
                                        <span key={index}
                                            className='flex min-w-68 rounded'>
                                            {slide.type === "image" &&
                                                <img src={slide.picture} alt={slide.name ? slide.name : "Alt text"}
                                                    className="w-full overflow-hidden rounded"
                                                />
                                            }
                                            {slide.type === "video" &&
                                                <video src={slide.url} autoplay={false} controls={true} muted
                                                    className="h-full w-full overflow-hidden rounded object-cover"
                                                />
                                            }
                                        </span>
                                    )
                                }
                                )
                            }
                        </div>
                    </div>
                }

                {/* COLLECTION AD NO FRAME */}
                {
                    this.state.for_report &&
                    (this.state.preview_placement === "" || this.state.preview_placement === "no_frame") &&
                    ad.ad_type === "collection" &&
                    <div className='flex flex-col justify-center max-w-xs'>
                        <div className='pb-1'>
                            {
                                ad.video && ad.video !== "" &&
                                <video
                                    className='w-full rounded'
                                    controls={true} loop={false} autoPlay={false} muted={true}>
                                    <source src={this.renders.video(ad)} type="video/mp4" />
                                </video>
                            }
                            {
                                ad.image && ad.image !== "" &&
                                <img src={this.renders.image(ad)} alt={"Creative image"} className='rounded' />
                            }
                        </div>
                        {/* COMMENT OUT BELOW CODE TO ONLY SHOW TOP MEDIA */}
                        <div className='grid grid-cols-3 gap-1'>
                            {
                                Array.isArray(ad.slides) && ad.slides.length > 0 && ad.slides.map((slide, index) => {
                                    return (
                                        <span key={index} className='col-span-1'>
                                            <img src={slide.picture} className='rounded' />
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {/* MERGE AD NO FRAME */}
                {
                    this.state.for_report &&
                    (this.state.preview_placement === "" || this.state.preview_placement === "no_frame") &&
                    (ad.ad_type === "merge_ad" || (!ad.ad_type && !ad.adData) || (!ad.ad_type && ad.adData && ad.adData.ad_type && ad.adData.ad_type.value === "carousel_posts")) &&
                    <>
                        <div className='flex flex-col justify-center space-y-2'>
                            {
                                Array.isArray(ad.slides) && ad.slides.length > 0 &&
                                ad.slides.map((slide, index) => {
                                    return (
                                        <>
                                            <div key={index}
                                                className='rounded'
                                            >
                                                {
                                                    !(slide.video && slide.video !== null) &&
                                                    <img src={slide.image ? slide.image : slide.picture} className='rounded' />
                                                }
                                                {
                                                    slide.video && slide.video !== null &&
                                                    <video autoplay={false} controls={true} muted src={slide.video} className='rounded' />
                                                }
                                                {
                                                    slide.video === null && !slide.image && slide.thumbnail && slide.thumbnail !== "" &&
                                                    <img src={slide.thumbnail} className='rounded' />
                                                }
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </>

                }
            </>
        )
    }
}

export default PreviewSectionFacebook;
