import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import languages from '../assets/json/languages.json';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import moment from 'moment';
import cn from "classnames";
import { campaignGoogle } from '../validators/campaignGoogle';
import SwitchTailwind from './switchTailwind';
import SlideDown from 'react-slidedown';
import { Bar } from 'react-chartjs-2';
import bing_timezones from '../assets/json/bing_timezones.json';

import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';

class EditGoogleCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currency: "",
            open: false,
            campaign: {},
            updated: {},
            client: {},
            loader: false,
            languages: languages,
            asset_sets: [],
            stats: [
                { id: 1, name: 'Enabled', value: "ENABLED" },
                { id: 2, name: 'Paused', value: "PAUSED" }
            ],
            search_locations: "",
            tabs: [
                { id: 1, name: "Basic", value: "basic" },
                { id: 2, name: "Budget", value: "budget" },
                { id: 3, name: "Locations", value: "locations" },
                { id: 4, name: "Settings", value: "settings" },
                { id: 5, name: "Targeting", value: "targeting" },
                //{ id: 6, name: "CRM settings (internal only)", value: "crm_settings" }
            ],
            adgroups: [],
            bidding_strategies: [],
            tab: { id: 1, name: "Basic", value: "basic" },
            center: { lat: 40.64, lng: -73.96 },
            zoom: 6,
            place: null,
            place_default: {
                "latitude": 59.32932349999999,
                "longitude": 18.0685808,
                "radius": 30000,
                "name": "(SE) Stockholm, Sweden, (59.329 lat, 18.069 lng, 30km)",
                "custom": true,
                "negative": false,
                "included": true,
                "include": true,
                "id": 4919442428,
                "code": "se",
                "lat": 59.32932349999999,
                "lng": 18.0685808
            },
            map: { id: 1, name: "List View", value: false },
            location_type: { id: 1, name: "Included", value: true },
            target_impression_share_location: [
                { id: 1, name: "Anywhere on results page", value: "ANYWHERE_ON_PAGE" },
                { id: 2, name: "Top of results page", value: "TOP_OF_PAGE" },
                { id: 3, name: "Absolute top of results page", value: "ABSOLUTE_TOP_OF_PAGE" }
            ],
            strategies: [
                { id: 1, name: "Clicks", value: "TARGET_SPEND" },
                { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS" },
                { id: 3, name: "Conversions value", value: "MAXIMIZE_CONVERSIONS_VALUE" },
                { id: 4, name: "Impression share", value: "TARGET_IMPRESSION_SHARE" },
                { id: 5, name: "Manual CPC", value: "MANUAL_CPC" },
                { id: 6, name: "Target ROAS", value: "MAXIMIZE_CONVERSIONS_VALUE" },
                { id: 7, name: "Maximize clicks", value: "TARGET_SPEND" },
                { id: 8, name: "Viewable CPM", value: "MANUAL_CPM" },
            ],
            merchant_accounts: [],
            feed_labels: [],
            networks: [
                { id: 1, name: "Google search", value: "google_search", locked: false },
                { id: 2, name: "Search network", value: "search_network", locked: false },
                { id: 3, name: "Content network", value: "content_network", locked: false },
                { id: 4, name: "Partner search network", value: "partner_search_network", locked: false }
            ],
            content_exclusions: [
                { id: 2, name: "Sexually suggestive content.", value: "SEXUALLY_SUGGESTIVE", locked: false },
                { id: 3, name: "Below the fold placement.", value: "BELOW_THE_FOLD", locked: false },
                { id: 4, name: "Parked domain.", value: "PARKED_DOMAIN", locked: false },
                { id: 5, name: "Juvenile, gross & bizarre content.", value: "JUVENILE", locked: false },
                { id: 6, name: "Profanity & rough language.", value: "PROFANITY", locked: false },
                { id: 7, name: "Death & tragedy.", value: "TRAGEDY", locked: false },
                { id: 8, name: "Video.", value: "VIDEO", locked: false },
                { id: 9, name: "Content rating: G.", value: "VIDEO_RATING_DV_G", locked: false },
                { id: 10, name: "Content rating: PG", value: "VIDEO_RATING_DV_PG", locked: false },
                { id: 11, name: "Content rating: T.", value: "VIDEO_RATING_DV_T", locked: false },
                { id: 12, name: "Content rating: MA.", value: "VIDEO_RATING_DV_MA", locked: false },
                { id: 13, name: "Content rating: not yet rated.", value: "VIDEO_NOT_YET_RATED", locked: false },
                { id: 14, name: "Embedded video.", value: "EMBEDDED_VIDEO", locked: false },
                { id: 15, name: "Live streaming video.", value: "LIVE_STREAMING_VIDEO", locked: false },
                { id: 16, name: "Sensitive social issues.", value: "SOCIAL_ISSUES", locked: false },
            ],
            bussinesTypes: [
                { id: 1, name: "Education", value: "Education" },
                { id: 1, name: "Flights", value: "Flights" },
                { id: 1, name: "Hotels and rentals", value: "Hotels and rentals" },
                { id: 1, name: "Jobs", value: "Jobs" },
                { id: 1, name: "Local deals", value: "Local deals" },
                { id: 1, name: "Property", value: "Property" },
                { id: 1, name: "Retail", value: "Retail" },
                { id: 1, name: "Travel", value: "Travel" },
                { id: 1, name: "Other", value: "Other" },
            ],
            targeting: {
                people: {
                    open: true,
                    audiance: []
                },
                demographics: {
                    open: false,
                    gender: [
                        { id: 1, name: "Female", value: "FEMALE" },
                        { id: 2, name: "Male", value: "MALE" },
                    ],
                    age: [
                        { id: 1, name: "18-24", value: 'AGE_RANGE_18_24' },
                        { id: 2, name: "25-34", value: 'AGE_RANGE_25_34' },
                        { id: 3, name: "35-44", value: 'AGE_RANGE_35_44' },
                        { id: 4, name: "45-54", value: 'AGE_RANGE_45_54' },
                        { id: 5, name: "55-64", value: 'AGE_RANGE_55_64' },
                        { id: 6, name: "65+", value: 'AGE_RANGE_65_UP' },
                    ],
                    parental_status: [
                        { id: 1, name: "Not a Parent", value: "NOT_A_PARENT" },
                        { id: 2, name: "Parent", value: "PARENT" },
                    ]
                }
            },
            weekOptions: [
                { id: 1, name: 'Sunday', value: 0 },
                { id: 2, name: 'Monday', value: 1 },
                { id: 3, name: 'Tuesday', value: 2 },
                { id: 4, name: 'Wednesday', value: 3 },
                { id: 5, name: 'Thursday', value: 4 },
                { id: 6, name: 'Friday', value: 5 },
                { id: 7, name: 'Saturday', value: 6 }
            ],
            hours_options: [
                { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, { id: 2, name: "00:15", hourValue: 0, minuteValue: "FIFTEEN" }, { id: 3, name: "00:30", hourValue: 0, minuteValue: "THIRTY" }, { id: 4, name: "00:45", hourValue: 0, minuteValue: "FORTY_FIVE" },
                { id: 5, name: "01:00", hourValue: 1, minuteValue: "ZERO" }, { id: 6, name: "01:15", hourValue: 1, minuteValue: "FIFTEEN" }, { id: 7, name: "01:30", hourValue: 1, minuteValue: "THIRTY" }, { id: 8, name: "01:45", hourValue: 1, minuteValue: "FORTY_FIVE" },
                { id: 9, name: "02:00", hourValue: 2, minuteValue: "ZERO" }, { id: 10, name: "02:15", hourValue: 2, minuteValue: "FIFTEEN" }, { id: 11, name: "02:30", hourValue: 2, minuteValue: "THIRTY" }, { id: 12, name: "02:45", hourValue: 2, minuteValue: "FORTY_FIVE" },
                { id: 13, name: "03:00", hourValue: 3, minuteValue: "ZERO" }, { id: 14, name: "03:15", hourValue: 3, minuteValue: "FIFTEEN" }, { id: 15, name: "03:30", hourValue: 3, minuteValue: "THIRTY" }, { id: 16, name: "03:45", hourValue: 3, minuteValue: "FORTY_FIVE" },
                { id: 17, name: "04:00", hourValue: 4, minuteValue: "ZERO" }, { id: 18, name: "04:15", hourValue: 4, minuteValue: "FIFTEEN" }, { id: 19, name: "04:30", hourValue: 4, minuteValue: "THIRTY" }, { id: 20, name: "04:45", hourValue: 4, minuteValue: "FORTY_FIVE" },
                { id: 21, name: "05:00", hourValue: 5, minuteValue: "ZERO" }, { id: 22, name: "05:15", hourValue: 5, minuteValue: "FIFTEEN" }, { id: 23, name: "05:30", hourValue: 5, minuteValue: "THIRTY" }, { id: 24, name: "05:45", hourValue: 5, minuteValue: "FORTY_FIVE" },
                { id: 25, name: "06:00", hourValue: 6, minuteValue: "ZERO" }, { id: 26, name: "06:15", hourValue: 6, minuteValue: "FIFTEEN" }, { id: 27, name: "06:30", hourValue: 6, minuteValue: "THIRTY" }, { id: 28, name: "06:45", hourValue: 6, minuteValue: "FORTY_FIVE" },
                { id: 29, name: "07:00", hourValue: 7, minuteValue: "ZERO" }, { id: 30, name: "07:15", hourValue: 7, minuteValue: "FIFTEEN" }, { id: 31, name: "07:30", hourValue: 7, minuteValue: "THIRTY" }, { id: 32, name: "07:45", hourValue: 7, minuteValue: "FORTY_FIVE" },
                { id: 33, name: "08:00", hourValue: 8, minuteValue: "ZERO" }, { id: 34, name: "08:15", hourValue: 8, minuteValue: "FIFTEEN" }, { id: 35, name: "08:30", hourValue: 8, minuteValue: "THIRTY" }, { id: 36, name: "08:45", hourValue: 8, minuteValue: "FORTY_FIVE" },
                { id: 37, name: "09:00", hourValue: 9, minuteValue: "ZERO" }, { id: 38, name: "09:15", hourValue: 9, minuteValue: "FIFTEEN" }, { id: 39, name: "09:30", hourValue: 9, minuteValue: "THIRTY" }, { id: 40, name: "09:45", hourValue: 9, minuteValue: "FORTY_FIVE" },
                { id: 41, name: "10:00", hourValue: 10, minuteValue: "ZERO" }, { id: 42, name: "10:15", hourValue: 10, minuteValue: "FIFTEEN" }, { id: 43, name: "10:30", hourValue: 10, minuteValue: "THIRTY" }, { id: 44, name: "10:45", hourValue: 10, minuteValue: "FORTY_FIVE" },
                { id: 45, name: "11:00", hourValue: 11, minuteValue: "ZERO" }, { id: 46, name: "11:15", hourValue: 11, minuteValue: "FIFTEEN" }, { id: 47, name: "11:30", hourValue: 11, minuteValue: "THIRTY" }, { id: 48, name: "11:45", hourValue: 11, minuteValue: "FORTY_FIVE" },
                { id: 49, name: "12:00", hourValue: 12, minuteValue: "ZERO" }, { id: 50, name: "12:15", hourValue: 12, minuteValue: "FIFTEEN" }, { id: 51, name: "12:30", hourValue: 12, minuteValue: "THIRTY" }, { id: 52, name: "12:45", hourValue: 12, minuteValue: "FORTY_FIVE" },
                { id: 53, name: "13:00", hourValue: 13, minuteValue: "ZERO" }, { id: 54, name: "13:15", hourValue: 13, minuteValue: "FIFTEEN" }, { id: 55, name: "13:30", hourValue: 13, minuteValue: "THIRTY" }, { id: 56, name: "13:45", hourValue: 13, minuteValue: "FORTY_FIVE" },
                { id: 57, name: "14:00", hourValue: 14, minuteValue: "ZERO" }, { id: 58, name: "14:15", hourValue: 14, minuteValue: "FIFTEEN" }, { id: 59, name: "14:30", hourValue: 14, minuteValue: "THIRTY" }, { id: 60, name: "14:45", hourValue: 14, minuteValue: "FORTY_FIVE" },
                { id: 61, name: "15:00", hourValue: 15, minuteValue: "ZERO" }, { id: 62, name: "15:15", hourValue: 15, minuteValue: "FIFTEEN" }, { id: 63, name: "15:30", hourValue: 15, minuteValue: "THIRTY" }, { id: 64, name: "15:45", hourValue: 15, minuteValue: "FORTY_FIVE" },
                { id: 65, name: "16:00", hourValue: 16, minuteValue: "ZERO" }, { id: 66, name: "16:15", hourValue: 16, minuteValue: "FIFTEEN" }, { id: 67, name: "16:30", hourValue: 16, minuteValue: "THIRTY" }, { id: 68, name: "16:45", hourValue: 16, minuteValue: "FORTY_FIVE" },
                { id: 69, name: "17:00", hourValue: 17, minuteValue: "ZERO" }, { id: 70, name: "17:15", hourValue: 17, minuteValue: "FIFTEEN" }, { id: 71, name: "17:30", hourValue: 17, minuteValue: "THIRTY" }, { id: 72, name: "17:45", hourValue: 17, minuteValue: "FORTY_FIVE" },
                { id: 73, name: "18:00", hourValue: 18, minuteValue: "ZERO" }, { id: 74, name: "18:15", hourValue: 18, minuteValue: "FIFTEEN" }, { id: 75, name: "18:30", hourValue: 18, minuteValue: "THIRTY" }, { id: 76, name: "18:45", hourValue: 18, minuteValue: "FORTY_FIVE" },
                { id: 77, name: "19:00", hourValue: 19, minuteValue: "ZERO" }, { id: 78, name: "19:15", hourValue: 19, minuteValue: "FIFTEEN" }, { id: 79, name: "19:30", hourValue: 19, minuteValue: "THIRTY" }, { id: 80, name: "19:45", hourValue: 19, minuteValue: "FORTY_FIVE" },
                { id: 81, name: "20:00", hourValue: 20, minuteValue: "ZERO" }, { id: 82, name: "20:15", hourValue: 20, minuteValue: "FIFTEEN" }, { id: 83, name: "20:30", hourValue: 20, minuteValue: "THIRTY" }, { id: 84, name: "20:45", hourValue: 20, minuteValue: "FORTY_FIVE" },
                { id: 85, name: "21:00", hourValue: 21, minuteValue: "ZERO" }, { id: 86, name: "21:15", hourValue: 21, minuteValue: "FIFTEEN" }, { id: 87, name: "21:30", hourValue: 21, minuteValue: "THIRTY" }, { id: 88, name: "21:45", hourValue: 21, minuteValue: "FORTY_FIVE" },
                { id: 89, name: "22:00", hourValue: 22, minuteValue: "ZERO" }, { id: 90, name: "22:15", hourValue: 22, minuteValue: "FIFTEEN" }, { id: 91, name: "22:30", hourValue: 22, minuteValue: "THIRTY" }, { id: 92, name: "22:45", hourValue: 22, minuteValue: "FORTY_FIVE" },
                { id: 93, name: "23:00", hourValue: 23, minuteValue: "ZERO" }, { id: 94, name: "23:15", hourValue: 23, minuteValue: "FIFTEEN" }, { id: 95, name: "23:30", hourValue: 23, minuteValue: "THIRTY" }, { id: 96, name: "23:45", hourValue: 23, minuteValue: "FORTY_FIVE" },
                { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" }
            ],
            days: [
                { id: 1, name: "Monday", value: "MONDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 2, name: "Tuesday", value: "TUESDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 3, name: "Wednesday", value: "WEDNESDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 4, name: "Thursday", value: "THURSDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 5, name: "Friday", value: "FRIDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 6, name: "Saturday", value: "SATURDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 7, name: "Sunday", value: "SUNDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } }
            ],
            weekOptions: [
                { id: 1, name: 'Sunday', value: 0 },
                { id: 2, name: 'Monday', value: 1 },
                { id: 3, name: 'Tuesday', value: 2 },
                { id: 4, name: 'Wednesday', value: 3 },
                { id: 5, name: 'Thursday', value: 4 },
                { id: 6, name: 'Friday', value: 5 },
                { id: 7, name: 'Saturday', value: 6 }
            ],
            daysOfMonthOptions: Array.from({ length: 28 }, (_, index) => ({ id: index, name: (index + 1) })),
            timeOptions: Array.from({ length: 24 }, (_, index) => ({ id: index, name: `${index.toString().padStart(2, '0')}:00`, value: index.toString().padStart(2, '0') })),
            timezoneOptions: bing_timezones,
            deviceSetting: [
                { id: 1, name: "Computer", value: "DESKTOP", included: true },
                { id: 2, name: "Mobile Phone", value: "MOBILE", included: true },
                { id: 3, name: "Tablets", value: "TABLET", included: true },
            ]
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            adgroups: this.props.adgroups
        })
        if (this.state.client.channels.filter((item) => { return item.value === "google" }).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => { return item.value === "google" })[0].currency
            })
        }


        if (!this.props.wizard) {
            await this.functions.locations();
        }

        this.functions.assetsets();
        this.functions.merchantAccounts();
        this.functions.biddingStrategies();

        if (this.props.updated) {
            if (this.props.updated.advertising_type && this.props.updated.advertising_type.value === "DISPLAY" && !this.state.targeting.people.audiance.length) {
                //await this.functions.getDetailedDemographics();
            }

            this.state.content_exclusions.map(item => {
                if (
                    this.props.updated.display_settings &&
                    this.props.updated.display_settings.contentLabel &&
                    this.props.updated.display_settings.contentLabel.map(c => c.value).includes(item.value)) {
                    item.included = true;
                }
                return item;
            })

            if (this.props.updated.deviceType) {
                this.state.deviceSetting = JSON.parse(JSON.stringify(this.props.updated.deviceType));
            }

            await this.promisedSetState({
                deviceSetting: this.state.deviceSetting,
                updated: this.props.updated
            });

            if (this.state.updated.scheduler) {
                this.state.tabs.push({ id: 11, name: "Scheduler", value: "schedule" });
                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                    this.state.updated.budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                }
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    updated: this.state.updated
                })
            }
            if (this.state.updated.bingSync) {
                this.state.tabs.push({ id: 12, name: "Bing Sync", value: "bing_sync" });
                await this.promisedSetState({
                    tabs: this.state.tabs
                })
            }
        }

        this.functions.map().then((response) => {
            try {
                this.autocomplete = new response.maps.places.AutocompleteService();
                this.geocoder = new response.maps.Geocoder;
            } catch (e) { }
        });

        try {
            if (Object.keys(this.state.updated).length > 0 && Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0) {
                let only_custom = true;
                for (let location of this.state.updated.locations) {
                    if (!location.hasOwnProperty('custom')) {
                        only_custom = false;
                        break;
                    }
                }

                if (only_custom) {
                    this.state.map = { id: 2, name: "Map View", value: true }
                    this.state.place = this.state.updated.locations[0];
                    await this.promisedSetState({ map: this.state.map, place: this.state.place });
                }
            }
        } catch (error) { }

        await this.promisedSetState({ loading_map: false })
    }

    functions = {
        update: async () => {
            if (!this.props.wizard) {
                if (this.state.tab.id == 1) {
                    this.functions.updateCampaign();
                } else if (this.state.tab.id == 2) {
                    this.functions.updateBudget();
                } else if (this.state.tab.id == 3) {
                    this.functions.updateLocations();
                }
            } else {
                this.props.onUpdate(this.state.updated, this.state.adgroups);
            }
        },
        updateCampaign: async () => {
            this.props.onError(false);
            this.props.onSuccess(false);
            this.props.onLoader(true);
            let body_campaign = {
                operations: [
                    {
                        "updateMask": "name,status,startDate,endDate",
                        "update": {
                            resourceName: this.state.campaign.resource_name,
                            name: this.renders.name(),
                            status: this.renders.status().value,
                            startDate: this.renders.startDate(),
                            endDate: this.renders.endDate() ? this.renders.endDate() : "2037-12-30",
                        }
                    }
                ]
            }
            if (this.renders.strategy().value === "TARGET_SPEND") {
                body_campaign.operations[0].updateMask += ",targetSpend.cpc_bid_ceiling_micros";
                body_campaign.operations[0].update.targetSpend = {};
                if (this.renders.targetSpendCpcBidCeiling() > 0) {
                    body_campaign.operations[0].update.targetSpend.cpcBidCeilingMicros = Math.round(this.renders.targetSpendCpcBidCeiling() * 1000000);
                }
            } else if (this.renders.strategy().value === "TARGET_CPM") {
                body_campaign.operations[0].updateMask += ",targetCpm";
                body_campaign.operations[0].update.targetCpm = {};
            } else if (this.renders.strategy().value === "MANUAL_CPC") {
                body_campaign.operations[0].updateMask += ",manualCpc.enhancedCpcEnabled";
                body_campaign.operations[0].update.manualCpc = {
                    enhancedCpcEnabled: this.renders.manualCpcEnhanced().value,
                };
            } else if (this.renders.strategy().value === "TARGET_IMPRESSION_SHARE") {
                body_campaign.operations[0].updateMask += ",targetImpressionShare.location,targetImpressionShare.location_fraction_micros,targetImpressionShare.cpc_bid_ceiling_micros";
                body_campaign.operations[0].update.targetImpressionShare = {
                    location: this.renders.targetImpressionShareLocation().value,
                    locationFractionMicros: Math.round(this.renders.targetImpressionShareFraction() * 10000),
                    cpcBidCeilingMicros: Math.round(this.renders.targetImpressionShareCeiling() * 1000000)
                };
            } else if (this.renders.strategy().value === "MAXIMIZE_CONVERSIONS") {
                body_campaign.operations[0].updateMask += ",maximizeConversions.targetCpa";
                if (this.renders.maximizeConversionsTargetCPA() > 0) {
                    body_campaign.operations[0].update.maximizeConversions = {
                        targetCpa: Math.round(this.renders.maximizeConversionsTargetCPA() * 1000000)
                    };
                }
            } else if (this.renders.strategy().value === "MAXIMIZE_CONVERSION_VALUE") {
                body_campaign.operations[0].updateMask += ",maximizeConversionValue.targetRoas";
                if (this.renders.maximizeConversionsValuesTargetROAS() > 0) {
                    body_campaign.operations[0].update.maximizeConversionValue = {
                        targetRoas: this.renders.maximizeConversionsValuesTargetROAS() / 100
                    };
                }
            }
            try {
                let response = await this.calls.updateCampaign(body_campaign);
                let updated_camaign = { ...this.state.campaign, ...response.data };
                this.props.onSuccess("Basic updated");
                this.props.onUpdate(updated_camaign);
            } catch (error) {
                this.props.onError(error.body ? error.body.message : "Something went wrong");
            }
            this.props.onLoader(false);
        },
        updateBudget: async () => {
            this.props.onError(false);
            this.props.onSuccess(false);
            this.props.onLoader(true);

            if (this.state.campaign.budget.amount != this.renders.budget()) {
                let budget = 0;
                budget = parseInt(this.renders.budget()) * 1000000;
                budget = Math.floor((budget / 1000000));
                budget = budget * 1000000;
                let body_budget = {
                    operations: [
                        {
                            "updateMask": "amountMicros",
                            "update": {
                                resourceName: this.state.campaign.budget.resource_name,
                                amountMicros: budget.toString()
                            }
                        }
                    ]
                };
                try {
                    let response = await this.calls.updateBudget(body_budget);
                    let updated_camaign = { ...this.state.campaign, ...response.data };
                    this.props.onSuccess("Budget updated");
                    this.props.onUpdate(updated_camaign);
                } catch (error) {
                    this.props.onError(error.body ? error.body.message : "Something went wrong");
                }
            }

            this.props.onLoader(false);
        },
        updateLocations: async () => {

            this.props.onError(false);
            this.props.onSuccess(false);
            this.props.onLoader(true);

            let remove = [];
            let add_properties = [];
            let add_coordinates = [];

            //REMOVED LOCATIONS
            this.state.campaign.locations.map((item) => {
                let exist = false;
                this.state.updated.locations.map((inner_item) => {
                    if (item.criterion_resource_name == inner_item.criterion_resource_name) {
                        if (!inner_item.updated) {
                            exist = true;
                        }
                    }
                });
                if (!exist) {
                    remove.push({ id: item.criterion_resource_name });
                }
            })

            //NEW ADDED LOCATIONS
            this.state.updated.locations.map((item) => {
                if ((item.custom && item.new_location) || (item.custom && item.updated)) {
                    add_coordinates.push(item);
                } else {
                    let exist = false;
                    this.state.campaign.locations.map((inner_item) => {
                        if (item.key == inner_item.resource_name) {
                            exist = true;
                        }
                    });
                    if (!exist) {
                        add_properties.push(item);
                    }
                }
            })

            if (remove.length > 0 || add_properties.length > 0 || add_coordinates.length > 0) {
                let body = {
                    operations: []
                };
                if (remove.length > 0) {
                    remove.map((item) => {
                        body.operations.push({ "remove": item.id });
                    })
                }
                if (add_properties.length > 0) {
                    add_properties.map((item) => {
                        body.operations.push({
                            "create": {
                                campaign: this.state.campaign.resource_name,
                                negative: !item.included,
                                location: {
                                    geoTargetConstant: item.key
                                }
                            }
                        });
                    })
                }
                if (add_coordinates.length > 0) {
                    add_coordinates.map((item) => {
                        body.operations.push({
                            "create": {
                                campaign: this.state.campaign.resource_name,
                                negative: false,
                                proximity: {
                                    geoPoint: {
                                        longitudeInMicroDegrees: parseInt(+item.lng * 1000000),
                                        latitudeInMicroDegrees: parseInt(+item.lat * 1000000),
                                    },
                                    radiusUnits: "KILOMETERS",
                                    radius: item.radius / 1000
                                }
                            }
                        });
                    })
                }
                try {
                    let response = await this.calls.updateLocations(body);
                    let updated_camaign = { ...this.state.campaign, ...response.data };
                    this.props.onSuccess("Locations updated");
                    this.props.onUpdate(updated_camaign);
                } catch (error) {
                    this.props.onError(error.body ? error.body.message : "Something went wrong");
                }
            }
            this.props.onLoader(false);
        },
        locations: async () => {
            this.state.updated.locations = [];
            let place = null;
            try {
                if (this.state.campaign.locations) {
                    this.state.updated.locations = this.state.campaign.locations.map((item) => {
                        item.id = Math.floor((Math.random() * 9999999999) + 1);
                        item.key = item.resource_name;
                        item.included = !item.negative;
                        if (item.canonical_name) {
                            item.name = item.canonical_name;
                        }
                        if (item.geo_point) {
                            item.custom = true;
                            item.included = true;
                            item.include = true;
                            item.campaign_edit = true;
                            item.name = parseFloat(item.lat).toFixed(3) + " lat, " + parseFloat(item.lng).toFixed(3) + " lng";
                            place = item;
                        }
                        return item;
                    })
                }
            } catch (error) { }
            this.promisedSetState({
                place: place,
                updated: this.state.updated
            })
        },
        searchLocations: async () => {
            try {
                await this.promisedSetState({ loading_locations: true });
                let response = await this.calls.getLocations();
                this.promisedSetState({
                    loading_locations: false,
                    location_options: response.data.map(((item) => {
                        if (item.geoTargetConstant.canonicalName) {
                            item.name = item.geoTargetConstant.canonicalName;
                        } else {
                            item.name = item.geoTargetConstant.name;
                        }
                        item.key = item.geoTargetConstant.resourceName;
                        return item;
                    }))
                });
            } catch (error) {
                this.promisedSetState({
                    location_options: []
                })
            }
        },
        searchGoogleLocations: async () => {
            let self = this;
            if (self.state.search_locations !== "") {
                await self.promisedSetState({ loading_locations: true });
                self.autocomplete.getPlacePredictions({
                    input: this.state.search_locations
                }, function (predictions, status) {
                    if (status === 'OK' && predictions && predictions.length > 0) {
                        self.promisedSetState({
                            loading_locations: false,
                            location_options: predictions.map((item) => {
                                item.name = item.description;
                                item.custom = true;
                                return item;
                            })
                        });
                    } else {
                        self.promisedSetState({ loading_locations: true, location_options: [] });
                    }
                });
            } else {
                self.promisedSetState({ loading_locations: true, location_options: [] });
            }
        },
        map: () => {
            if (!this.googleMapsPromise && !window.google) {
                this.googleMapsPromise = new Promise((resolve) => {
                    window.resolveGoogleMapsPromise = () => {
                        resolve(window.google);
                        delete window.resolveGoogleMapsPromise;
                    };
                    const script = document.createElement("script");
                    const API = "AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow";
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
                    script.async = true;
                    document.body.appendChild(script);
                });
            } else {
                this.googleMapsPromise = new Promise((resolve) => {
                    resolve(window.google);
                });
            }
            return this.googleMapsPromise;
        },
        updateAdgroups: async () => {
            let campaigns = [];
            if (this.state.updated.id && this.state.updated.id !== "multiple") {
                campaigns.push(this.state.updated.id);
            } else if (this.state.updated.ids) {
                campaigns = this.state.updated.ids;
            }
            campaigns.map((campaign_id) => {
                this.state.adgroups = this.state.adgroups.map((item) => {
                    if (item.campaign === campaign_id) {
                        if (this.state.updated.advertising_sub_type) {
                            item.advertising_type = this.state.updated.advertising_sub_type ? this.state.updated.advertising_sub_type.adgroup : null;
                            item.advertising_type_ad = this.state.updated.advertising_sub_type ? this.state.updated.advertising_sub_type.ad : null;
                        }
                        if (this.state.updated.preview_products) {
                            item.preview_products = this.state.updated.preview_products;
                        }
                        if (this.state.updated.feed_label) {
                            item.feed_label = this.state.updated.feed_label;
                        }
                    }
                    return item;
                });
            });
            this.promisedSetState({
                adgroups: this.state.adgroups
            });
        },
        assetsets: async () => {
            try {
                await this.promisedSetState({ loading_assets: true });
                let response = await this.calls.getAssetSets();
                this.promisedSetState({
                    loading_assets: false,
                    asset_sets: response.data
                });
            } catch (error) {
                this.promisedSetState({
                    loading_assets: false,
                    asset_sets: []
                })
            }
        },
        biddingStrategies: async () => {
            try {
                //await this.promisedSetState({ loading_merchants: true });
                let response = await this.calls.listBiddingStrategies();
                response.data.map((item) => {
                    this.state.bidding_strategies.push(item.biddingStrategy);
                })

                //response.data.unshift({ id: 0, name: "None" });
                console.log(response.data);
                await this.promisedSetState({
                    bidding_strategies: this.state.bidding_strategies
                });
            } catch (error) {
                this.promisedSetState({

                })
            }
        },
        merchantAccounts: async () => {
            try {
                await this.promisedSetState({ loading_merchants: true });
                let response = await this.calls.getMerchantAccount();
                response.data.unshift({ id: 0, name: "None" });
                await this.promisedSetState({
                    loading_merchants: false,
                    merchant_accounts: response.data
                });
                console.log(this.state.merchant_accounts);
            } catch (error) {
                this.promisedSetState({
                    loading_merchants: false,
                    merchant_accounts: []
                })
            }
        },
        dataFeedStats: async (merchant) => {
            try {
                await this.promisedSetState({ loading_datafeeds: true });
                let response = await this.calls.getDataFeedStats(merchant);
                this.promisedSetState({
                    loading_datafeeds: false,
                    data_feeds: response.data
                });
            } catch (error) {
                this.promisedSetState({
                    loading_datafeeds: false,
                    data_feeds: []
                })
            }
        },
        getProducts: async (merchant) => {
            try {
                await this.promisedSetState({ loading_products: true });
                let response = await this.calls.getProducts(merchant);
                this.promisedSetState({
                    loading_products: false,
                    feed_labels: response.meta && Array.isArray(response.meta.feed_labels) ? response.meta.feed_labels : [],
                    products: response.data
                });
            } catch (error) {
                this.promisedSetState({
                    loading_products: false,
                    products: []
                })
            }
        },
        getDetailedDemographics: async () => {
            this.calls.getDetailedDemographics().then(async res => {
                this.state.targeting.people.audiance = res.data.flatMap(item =>
                    item.detailedDemographic.availabilities
                        .filter(availability =>
                            availability.channel.advertisingChannelType === 'DISPLAY')
                        .map(availability => ({
                            id: item.detailedDemographic.id,
                            name: item.detailedDemographic.name,
                            resourceName: item.detailedDemographic.resourceName,
                        }))
                );
                this.state.targeting.people.audiance.map(item => {
                    if (this.props.updated.targeting.people.audiance && this.props.updated.targeting.people.audiance.includes(item.resourceName)) {
                        item.included = true;
                    }
                    return item;
                })
                await this.promisedSetState({ targeting: this.state.targeting });

            });

        }
    };

    calls = {
        updateCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateCampaign?client=" + this.state.client.id + "&id=" + this.state.campaign.id;
            return apiRegister.call(options, url);
        },
        updateBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateCampaignBudget?client=" + this.state.client.id + "&id=" + this.state.campaign.id;
            return apiRegister.call(options, url);
        },
        updateLocations: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateCampaignCriteria?client=" + this.state.client.id + "&id=" + this.state.campaign.id;
            return apiRegister.call(options, url);
        },
        getLocations: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listLocations?client=" + this.state.client.id + "&search=" + this.state.search_locations;
            return apiRegister.call(options, url);
        },
        getAssetSets: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listAssetSets?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getMerchantAccount: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listMerchantAccounts?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listBiddingStrategies: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listBiddingStrategies?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getDataFeedStats: (merchant) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/getDataFeedsStatus?client=" + this.state.client.id + "&merchant=" + merchant;
            return apiRegister.call(options, url);
        },
        getProducts: (merchant) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/getProducts?client=" + this.state.client.id + "&merchant=" + merchant;
            return apiRegister.call(options, url);
        },
        getDetailedDemographics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET');
            let url = apiRegister.url.api + "/v3/google/listDetailedDemographics?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.campaign.status === 'UNSPECIFIED') {
                    return { id: 3, name: 'Unspecified', value: "UNSPECIFIED" }
                } else if (this.state.campaign.status === 'UNKNOWN') {
                    return { id: 4, name: 'Unknown', value: "UNKNOWN" }
                } else if (this.state.campaign.status === 'ENABLED') {
                    return { id: 1, name: 'Enabled', value: "ENABLED" }
                } else if (this.state.campaign.status === 'PAUSED') {
                    return { id: 1, name: 'Paused', value: "PAUSED" }
                } else if (this.state.campaign.status === 'REMOVED') {
                    return { id: 5, name: 'Removed', value: "REMOVED" }
                } else {
                    return { id: 1, name: 'Unknown', value: "UNKNOWN" }
                }
            } catch (error) {
                return { id: 1, name: 'Unknown', value: "UNKNOWN" }
            }
        },
        network: () => {
            try {
                if (this.props.wizard) {
                    return { id: 3, name: 'Search', value: "SEARCH" }
                } else {
                    if ("advertising_type" in this.state.updated) {
                        return this.state.updated.advertising_type;
                    } else if (this.state.campaign.advertising_type === 'UNSPECIFIED') {
                        return { id: 1, name: 'Unspecified', value: "UNSPECIFIED" }
                    } else if (this.state.campaign.advertising_type === 'UNKNOWN') {
                        return { id: 2, name: 'Unknown', value: "UNKNOWN" }
                    } else if (this.state.campaign.advertising_type === 'SEARCH') {
                        return { id: 3, name: 'Search', value: "SEARCH" }
                    } else if (this.state.campaign.advertising_type === 'DISPLAY') {
                        return { id: 4, name: 'Display', value: "DISPLAY" }
                    } else if (this.state.campaign.advertising_type === 'SHOPPING') {
                        return { id: 5, name: 'Shopping', value: "SHOPPING" }
                    } else if (this.state.campaign.advertising_type === 'SMART') {
                        return { id: 6, name: 'Smart', value: "SMART" }
                    } else {
                        return { id: 2, name: 'Unknown', value: "UNKNOWN" }
                    }
                }
            } catch (error) {
                return { id: 2, name: 'Unknown', value: "UNKNOWN" }
            }
        },
        strategy: () => {
            try {
                if (this.props.wizard) {
                    if ("strategy_type" in this.state.updated) {
                        return this.state.updated.strategy_type;
                    } else {
                        return { id: 0, name: 'Select', value: "UNKNOWN" }
                    }
                } else {
                    if ("strategy_type" in this.state.updated) {
                        return this.state.updated.strategy_type;
                    } else if (this.state.campaign.strategy_type === 'TARGET_SPEND') {
                        return { id: 1, name: "Maximise clicks", value: "TARGET_SPEND" };
                    } else if (this.state.campaign.strategy_type === 'MAXIMIZE_CONVERSIONS') {
                        return { id: 2, name: "Maximise conversions", value: "MAXIMIZE_CONVERSIONS" }
                    } else if (this.state.campaign.strategy_type === 'MAXIMIZE_CONVERSIONS_VALUE') {
                        return { id: 3, name: "Maximise conversions value", value: "MAXIMIZE_CONVERSION_VALUE" }
                    } else if (this.state.campaign.strategy_type === 'TARGET_IMPRESSION_SHARE') {
                        return { id: 4, name: "Target impression share", value: "TARGET_IMPRESSION_SHARE" }
                    } else if (this.state.campaign.strategy_type === 'MANUAL_CPC') {
                        return { id: 5, name: "Manual CPC", value: "MANUAL_CPC" }
                    } else {
                        return { id: 0, name: 'Unknown', value: "UNKNOWN" }
                    }
                }
            } catch (error) {
                return { id: 0, name: 'Unknown', value: "UNKNOWN" }
            }
        },
        targetImpressionShareLocation: () => {
            try {
                if ("target_impression_share_location" in this.state.updated) {
                    return this.state.updated.target_impression_share_location;
                } else if (this.state.campaign.target_impression_share.location === 'ANYWHERE_ON_PAGE') {
                    return { id: 1, name: "Anywhere on results page", value: "ANYWHERE_ON_PAGE" }
                } else if (this.state.campaign.target_impression_share.location === 'TOP_OF_PAGE') {
                    return { id: 2, name: "Top of results page", value: "TOP_OF_PAGE" }
                } else if (this.state.campaign.target_impression_share.location === 'ABSOLUTE_TOP_OF_PAGE') {
                    return { id: 3, name: "Absolute top of results page", value: "ABSOLUTE_TOP_OF_PAGE" }
                } else {
                    return { id: 1, name: "Anywhere on results page", value: "ANYWHERE_ON_PAGE" }
                }
            } catch (error) {
                return { id: 1, name: "Anywhere on results page", value: "ANYWHERE_ON_PAGE" }
            }
        },
        targetImpressionShareFraction: () => {
            try {
                if ('target_impression_share_fraction' in this.state.updated) {
                    return this.state.updated.target_impression_share_fraction;
                } else if (this.state.campaign.target_impression_share) {
                    return +this.state.campaign.target_impression_share.location_fraction_micros / 10000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        targetImpressionShareCeiling: () => {
            try {
                if ('target_impression_share_ceiling' in this.state.updated) {
                    return this.state.updated.target_impression_share_ceiling;
                } else if (this.state.campaign.target_impression_share) {
                    return +this.state.campaign.target_impression_share.cpc_bid_ceiling_micros / 1000000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        targetSpendCpcBidCeiling: () => {
            try {
                if ('target_spend_cpc_bid_ceiling' in this.state.updated) {
                    return this.state.updated.target_spend_cpc_bid_ceiling;
                } else if (this.state.campaign.target_spend) {
                    return +this.state.campaign.target_spend.cpc_bid_ceiling_micros / 1000000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        allowMaximizeConversionsTargetCPA: () => {
            try {
                if ('allow_maximize_conversions_target_cpa' in this.state.updated) {
                    return this.state.updated.allow_maximize_conversions_target_cpa;
                } else {
                    return { id: 1, name: 'Disabled', value: false };
                }
            } catch (error) {
                return { id: 1, name: 'Disabled', value: false };
            }
        },
        allowMaximizeConversionsValueTargetRoas: () => {
            try {
                if ('allow_maximize_conversions_value_target_roas' in this.state.updated) {
                    return this.state.updated.allow_maximize_conversions_value_target_roas;
                } else {
                    return { id: 1, name: 'Disabled', value: false };
                }
            } catch (error) {
                return { id: 1, name: 'Disabled', value: false };
            }
        },
        allowTargetSpendCeiling: () => {
            try {
                if ('allow_target_spend_ceiling' in this.state.updated) {
                    return this.state.updated.allow_target_spend_ceiling;
                } else {
                    return { id: 1, name: 'Disabled', value: false };
                }
            } catch (error) {
                return { id: 1, name: 'Disabled', value: false };
            }
        },
        maximizeConversionsTargetCPA: () => {
            try {
                if ('maximize_conversions_target_cpa' in this.state.updated) {
                    return this.state.updated.maximize_conversions_target_cpa;
                } else if (this.state.campaign.maximize_conversions) {
                    return +this.state.campaign.maximize_conversions.target_cpa / 1000000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        maximizeConversionsValuesTargetROAS: () => {
            try {
                if ('maximize_conversions_values_target_roas' in this.state.updated) {
                    return this.state.updated.maximize_conversions_values_target_roas;
                } else if (this.state.campaign.maximize_conversion_value) {
                    return +this.state.campaign.maximize_conversion_value.target_roas * 100;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        manualCpcEnhanced: () => {
            try {
                if ("enhanced_cpc_enabled" in this.state.updated) {
                    return this.state.updated.enhanced_cpc_enabled;
                } else if (this.state.campaign.manual_cpc.enhanced_cpc_enabled) {
                    return { id: 1, name: "Yes", value: true }
                } else if (!this.state.campaign.manual_cpc.enhanced_cpc_enabled) {
                    return { id: 2, name: "No", value: false }
                } else {
                    return { id: 2, name: "No", value: false }
                }
            } catch (error) {
                return { id: 2, name: "No", value: false }
            }
        },
        budget: () => {
            try {
                if ('budget' in this.state.updated) {
                    return this.state.updated.budget;
                } else if (this.state.campaign.budget) {
                    return +this.state.campaign.budget.amount / 1000000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        startDate: () => {
            try {
                if (this.props.wizard) {
                    return this.state.updated.start_date;
                } else {
                    if ('start_date' in this.state.updated) {
                        return this.state.updated.start_date;
                    } else {
                        return this.state.campaign.start_date;
                    }
                }
            } catch (error) {
                return 0;
            }
        },
        endDate: () => {
            try {
                if (this.props.wizard) {
                    return this.state.updated.end_date ? this.state.updated.end_date : null;
                } else {
                    return this.state.campaign.end_date ? this.state.campaign.end_date : null;
                }
            } catch (error) {
                return 0;
            }
        },
        startHour: (index) => {
            try {
                if ('days' in this.state.updated) {
                    return this.state.updated.days[index].start;
                } else {
                    return this.state.days[index].start;
                }
            } catch (error) {
                return 0;
            }
        },
        endHour: (index) => {
            try {
                if ('days' in this.state.updated) {
                    return this.state.updated.days[index].end;
                } else {
                    return this.state.days[index].end;
                }
            } catch (error) {
                return 0;
            }
        },
        dayTime: (index) => {
            try {
                if ('days' in this.state.updated) {
                    return this.state.updated.days[index].time;
                } else {
                    return this.state.days[index].time;
                }
            } catch (error) {
                return 0;
            }
        },
        start_end: (index) => {
            try {
                if ('days' in this.state.updated) {
                    if (this.state.updated.days[index].time.id === 2) {
                        return false
                    } else return true
                } else {
                    if (this.state.days[index].time.id === 2) {
                        return false
                    } else return true
                }
            } catch (error) {
                return 0;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">
                    {
                        !(this.state.updated.advertising_type && this.state.updated.advertising_type.value === "VIDEO") &&
                        <div className="col-span-6">
                            <div className="block overflow-scroll w-ful">
                                <nav className="flex space-x-4" aria-label="Tabs">
                                    {this.state.tabs.map((tab) => (
                                        <div
                                            onClick={() => (
                                                this.setState({
                                                    tab: tab
                                                })
                                            )}
                                            key={tab.name}
                                            style={{ whiteSpace: "nowrap" }}
                                            className={cn(
                                                tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                            )}
                                            aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                        >
                                            <div>
                                                {
                                                    (tab.id === 1 && (campaignGoogle.validate(this.state.updated).start_date || campaignGoogle.validate(this.state.updated).end_date)) ||
                                                        (tab.id === 2 && campaignGoogle.validate(this.state.updated).budget) ||
                                                        (tab.id === 3 && campaignGoogle.validate(this.state.updated).location) ||
                                                        (tab.id === 11 && ((this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity) || Object.keys(this.state.updated.scheduler.weekdays).length < 1) && this.state.updated.scheduler.schedule) ||
                                                        (tab.id === 12 && campaignGoogle.validate(this.state.updated).bing)
                                                        ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                }
                                            </div>
                                            {tab.name}
                                        </div>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    }

                    {/*Basic*/}
                    {
                        this.state.tab.id == 1 &&
                        <>
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Campaign name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                !this.props.wizard &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Campaign status"}
                                        selected={this.renders.status()}
                                        options={this.state.stats}
                                        onChange={(value) => {
                                            this.state.updated.status = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-6">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3">
                                        <InputDatepickerTailwind
                                            label={"Start date"}
                                            placeholder={moment(this.renders.startDate()).format("MM/DD/YYYY")}
                                            error={campaignGoogle.validate(this.state.updated).start_date}
                                            value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                            onChange={(value) => {
                                                this.state.updated.start_date = value;
                                                if (this.state.updated.scheduler) {
                                                    this.state.updated.scheduler.start_date = value;
                                                    if (this.state.updated.scheduler.planer) {
                                                        if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                            this.state.updated.budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                        } else {
                                                            this.state.updated.budget = 0;
                                                        }
                                                    }
                                                }
                                                if (this.renders.endDate() && new Date(this.renders.endDate()) < new Date(value)) {
                                                    this.state.updated.end_date = moment(value).add(1, 'days').format("YYYY-MM-DD");
                                                }
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <InputDatepickerTailwind
                                            label={"End date"}
                                            minDate={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                            error={campaignGoogle.validate(this.state.updated).end_date}
                                            labelRight={this.renders.endDate() ? "Enabled" : "Disabled"}
                                            placeholder={!this.renders.endDate() ? "No end date" : ""}
                                            disabled={!this.renders.endDate()}
                                            value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                            onChange={(value) => {
                                                this.state.updated.end_date = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                            onLabelRightClick={(value) => {
                                                if (this.renders.endDate()) {
                                                    this.state.updated.end_date = null;
                                                } else {
                                                    let new_date = moment(this.renders.startDate()).add(1, 'days');
                                                    this.state.updated.end_date = moment(new_date);
                                                }
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {
                                !(this.state.updated.advertising_type && this.state.updated.advertising_type.value === "VIDEO") &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Bidding"}
                                        locked={this.state.updated.advertising_type && this.state.updated.advertising_type.value === "VIDEO"}
                                        selected={this.renders.strategy()}
                                        options={this.state.strategies.filter((item) => {
                                            if (this.state.updated.advertising_type && this.state.updated.advertising_type.value === "SHOPPING") {
                                                if (item.id === 6 || item.id === 5 || item.id === 7) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else if (this.state.updated.advertising_type && this.state.updated.advertising_type.value === "PERFORMANCE_MAX") {
                                                if (item.id == 2 || item.id == 3) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else if (this.state.updated.advertising_type && this.state.updated.advertising_type.value === "VIDEO") {
                                                return false;
                                            } else if (this.state.updated.advertising_type && this.state.updated.advertising_type.value === "DISPLAY") {
                                                if (item.id == 2 || item.id == 3 || item.id == 8 || item.id == 7) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else {
                                                if (item.id !== 6 && item.value !== 5 && item.value !== 7 && item.value !== 8) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }
                                        })}
                                        onChange={async (value) => {
                                            if (value.value === "TARGET_IMPRESSION_SHARE") {
                                                this.state.updated.target_impression_share_location = { id: 1, name: "Anywhere on results page", value: "ANYWHERE_ON_PAGE" };
                                                this.state.updated.target_impression_share_fraction = 0;
                                                this.state.updated.target_impression_share_ceiling = 0;
                                            } else if (value.value === "TARGET_SPEND") {
                                                this.state.updated.allow_target_spend_ceiling = { id: 1, name: 'Disabled', value: false };
                                            } else if (value.value === "MAXIMIZE_CONVERSIONS") {
                                                this.state.updated.allow_maximize_conversions_target_cpa = { id: 1, name: 'Disabled', value: false };
                                            } else if (value.value === "MAXIMIZE_CONVERSIONS_VALUE") {
                                                this.state.updated.allow_maximize_conversions_value_target_roas = { id: 1, name: 'Disabled', value: false };
                                            }
                                            if (value.value !== "MAXIMIZE_CONVERSIONS" && value.value !== "MAXIMIZE_CONVERSIONS_VALUE") {
                                                this.state.updated.portfolio = false;
                                                this.state.updated.portfolio_option = { id: 0, name: 'Create new', value: "new" };
                                            }
                                            this.state.updated.strategy_type = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "MANUAL_CPC" &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Help increase conversions with Enhanced CPC"}
                                        selected={this.renders.manualCpcEnhanced()}
                                        options={[
                                            { id: 1, name: "Yes", value: true },
                                            { id: 2, name: "No", value: false }
                                        ]}
                                        onChange={(value) => {
                                            this.state.updated.enhanced_cpc_enabled = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "TARGET_IMPRESSION_SHARE" &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Where do you want your ads to appear"}
                                        selected={this.renders.targetImpressionShareLocation()}
                                        options={this.state.target_impression_share_location}
                                        onChange={(value) => {
                                            this.state.updated.target_impression_share_location = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "TARGET_IMPRESSION_SHARE" &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        leftSection={true}
                                        leftSectionLabel={"%"}
                                        label={"Percent (%) impression share to target"}
                                        value={this.renders.targetImpressionShareFraction()}
                                        onChange={(value) => {
                                            this.state.updated.target_impression_share_fraction = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "TARGET_IMPRESSION_SHARE" &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        leftSection={(this.state.currency !== "" ? true : false)}
                                        leftSectionLabel={(this.state.currency)}
                                        label={"Maximum CPC bid limit"}
                                        value={this.renders.targetImpressionShareCeiling()}
                                        onChange={(value) => {
                                            this.state.updated.target_impression_share_ceiling = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "TARGET_SPEND" &&
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className={(this.state.updated.allow_target_spend_ceiling && this.state.updated.allow_target_spend_ceiling.value) ? "col-span-3" : "col-span-6"}>
                                            <DropdownTailwind
                                                label={"Set a maximum cost per click bid limit"}
                                                selected={this.renders.allowTargetSpendCeiling()}
                                                options={[
                                                    { id: 1, name: 'Disabled', value: false },
                                                    { id: 2, name: 'Enabled', value: true }
                                                ]}
                                                onChange={(value) => {
                                                    this.state.updated.target_spend_cpc_bid_ceiling = 0;
                                                    this.state.updated.allow_target_spend_ceiling = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.updated.allow_target_spend_ceiling &&
                                            this.state.updated.allow_target_spend_ceiling.value &&
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    leftSection={(this.state.currency !== "" ? true : false)}
                                                    leftSectionLabel={(this.state.currency)}
                                                    label={"Maximum CPC bid limit"}
                                                    value={this.renders.targetSpendCpcBidCeiling()}
                                                    onChange={(value) => {
                                                        this.state.updated.target_spend_cpc_bid_ceiling = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.renders.strategy().value === "MAXIMIZE_CONVERSIONS" &&
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className={(this.state.updated.allow_maximize_conversions_target_cpa && this.state.updated.allow_maximize_conversions_target_cpa.value) ? "col-span-3" : "col-span-6"}>
                                            <DropdownTailwind
                                                label={"Set a target cost per action (optional)"}
                                                selected={this.renders.allowMaximizeConversionsTargetCPA()}
                                                locked={this.state.updated.portfolio && this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? true : false}
                                                disabled={this.state.updated.portfolio && this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? true : false}
                                                options={[
                                                    { id: 1, name: 'Disabled', value: false },
                                                    { id: 2, name: 'Enabled', value: true }
                                                ]}
                                                onChange={(value) => {
                                                    this.state.updated.maximize_conversions_target_cpa = 0;
                                                    this.state.updated.allow_maximize_conversions_target_cpa = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.updated.allow_maximize_conversions_target_cpa &&
                                            this.state.updated.allow_maximize_conversions_target_cpa.value &&
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    leftSection={(this.state.currency !== "" ? true : false)}
                                                    leftSectionLabel={(this.state.currency)}
                                                    label={"Target CPA"}
                                                    value={this.renders.maximizeConversionsTargetCPA()}
                                                    locked={this.state.updated.portfolio && this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? true : false}
                                                    disabled={this.state.updated.portfolio && this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? true : false}
                                                    onChange={(value) => {
                                                        this.state.updated.maximize_conversions_target_cpa = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.renders.strategy().value === "MAXIMIZE_CONVERSIONS_VALUE" &&
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className={(this.state.updated.allow_maximize_conversions_value_target_roas && this.state.updated.allow_maximize_conversions_value_target_roas.value) ? "col-span-3" : "col-span-6"}>
                                            <DropdownTailwind
                                                label={"Set a target return on ad spend (optional)"}
                                                selected={this.renders.allowMaximizeConversionsValueTargetRoas()}
                                                locked={this.state.updated.portfolio && this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? true : false}
                                                disabled={this.state.updated.portfolio && this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? true : false}
                                                options={[
                                                    { id: 1, name: 'Disabled', value: false },
                                                    { id: 2, name: 'Enabled', value: true }
                                                ]}
                                                onChange={(value) => {
                                                    this.state.updated.maximize_conversions_value_target_roas = 0;
                                                    this.state.updated.allow_maximize_conversions_value_target_roas = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.updated.allow_maximize_conversions_value_target_roas &&
                                            this.state.updated.allow_maximize_conversions_value_target_roas.value &&
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    label={"Percent (%) target ROAS"}
                                                    leftSection={true}
                                                    leftSectionLabel={"%"}
                                                    locked={this.state.updated.portfolio && this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? true : false}
                                                    disabled={this.state.updated.portfolio && this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? true : false}
                                                    value={this.renders.maximizeConversionsValuesTargetROAS()}
                                                    onChange={(value) => {
                                                        this.state.updated.maximize_conversions_values_target_roas = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                (this.renders.strategy().value === "MAXIMIZE_CONVERSIONS" || this.renders.strategy().value === "MAXIMIZE_CONVERSIONS_VALUE") &&
                                <>
                                    <div className="col-span-3">
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Use portfolio strategy</div>
                                        <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5">
                                                {this.state.updated.portfolio ? "Active" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    disabled={(this.renders.strategy().value === "MAXIMIZE_CONVERSIONS_VALUE" || this.renders.strategy().value === "MAXIMIZE_CONVERSIONS") ? false : true}
                                                    value={this.state.updated.portfolio ? true : false}
                                                    onSwitch={async () => {
                                                        this.state.updated.portfolio = !this.state.updated.portfolio;
                                                        if (!this.state.updated.portfolio_option) {
                                                            this.state.updated.portfolio_option = { id: 0, name: 'Create new', value: "new" };
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-3">
                                        <div className="grid grid-cols-6 col-gap-4">
                                            <div className={(this.state.updated.portfolio && ((this.state.updated.portfolio_option && this.state.updated.portfolio_option.id == 0) || !this.state.updated.portfolio_option)) ? "col-span-3" : "col-span-6"}>
                                                <DropdownTailwind
                                                    label={"Select a portfolio option"}
                                                    locked={!this.state.updated.portfolio}
                                                    disabled={!this.state.updated.portfolio}
                                                    selected={this.state.updated.portfolio_option && this.state.updated.portfolio_option.id !== 0 ? this.state.updated.portfolio_option : { id: 0, name: 'Create new', value: "new" }}
                                                    options={[
                                                        { id: 0, name: 'Create new', value: "new" }
                                                    ].concat(this.state.bidding_strategies.filter((inner_item) => {
                                                        if (this.renders.strategy().value === "MAXIMIZE_CONVERSIONS") {
                                                            return inner_item.type === "MAXIMIZE_CONVERSIONS"
                                                        } else {
                                                            return inner_item.type === "MAXIMIZE_CONVERSION_VALUE"
                                                        }
                                                    }))}
                                                    onChange={(value) => {
                                                        this.state.updated.portfolio_option = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            {
                                                ((this.state.updated.portfolio_option && this.state.updated.portfolio_option.id === 0) || !this.state.updated.portfolio_option) && this.state.updated.portfolio &&
                                                <div className="col-span-3">
                                                    <InputTailwind
                                                        label={"Portfolio name"}
                                                        locked={!this.state.updated.portfolio}
                                                        disabled={!this.state.updated.portfolio}
                                                        value={this.state.updated.portfolio_name ? this.state.updated.portfolio_name : ""}
                                                        onChange={(value) => {
                                                            this.state.updated.portfolio_name = value;
                                                            this.setState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }

                    {/*Budget*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className="col-span-6">
                                {
                                    !this.state.updated.googleBudgets &&
                                    <InputTailwind
                                        error={campaignGoogle.validate(this.state.updated).budget}
                                        label={"Daily budget"}
                                        locked={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer ? true : false}
                                        disabled={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer ? true : false}
                                        value={this.renders.budget()}
                                        type={"number"}
                                        leftSection={(this.state.currency !== "" ? true : false)}
                                        leftSectionLabel={(this.state.currency)}
                                        onChange={(value) => {
                                            this.state.updated.budget = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                }
                                {
                                    this.state.updated.googleBudgets &&
                                    <DropdownTailwind
                                        label={"Shared budget"}
                                        selected={this.state.updated.shared_budget}
                                        options={this.state.updated.googleBudgets}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                shared_budget: value
                                            });
                                        }}
                                    />
                                }
                            </div>
                        </>
                    }

                    {/*Locations*/}
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    label={"View type"}
                                    selected={this.state.map ? this.state.map : { id: 1, name: "List View", value: false }}
                                    options={[
                                        { id: 1, name: "List View", value: false },
                                        { id: 2, name: "Map view", value: true }
                                    ]}
                                    // onChange={async (value) => {
                                    //     this.refs.searchDropdown.functions.resetSearch();
                                    //     await this.promisedSetState({
                                    //         map: value,
                                    //         location_options: [],
                                    //         search_locations: ""
                                    //     })
                                    //     await this.promisedSetState({
                                    //         location_type: this.state.map.value ? { id: 1, name: "Included", value: true } : this.state.location_type
                                    //     });
                                    // }}
                                    onChange={async (value) => {
                                        console.log("state", this.state)
                                        let buff = null;
                                        if (!this.state.updated) {
                                            this.state.updated = []
                                        }
                                        if (this.state.updated && this.state.updated.locations && this.state.updated.locations.length > 0) {
                                            buff = this.state.updated.locations.find((item) => item.custom);
                                            console.log("buff", buff)
                                            if (buff === undefined || buff === null) {
                                                this.state.place = this.state.place_default;
                                            } else {
                                                if (!buff.lat) {
                                                    buff.lat = buff.latitude;
                                                }
                                                if (!buff.lng) {
                                                    buff.lng = buff.longitude
                                                }
                                                this.state.place = buff;
                                            }
                                        } else {
                                            this.state.place = this.state.place_default;
                                        }
                                        this.refs.searchDropdown.functions.resetSearch();
                                        await this.promisedSetState({
                                            map: value,
                                            location_options: [],
                                            search_locations: "",
                                            place: this.state.place
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-4">
                                <DropdownTailwind
                                    error={campaignGoogle.validate(this.state.updated).location}
                                    ref="searchDropdown"
                                    rightLabel={true}
                                    rightLabelText={this.state.location_type.value ? "Included" : "Excluded"}
                                    onRightLabelClick={async () => {
                                        if (!this.state.map.value) {
                                            await this.promisedSetState({ location_type: !this.state.location_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                        }
                                    }}
                                    label={"Locations"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={Array.isArray(this.state.location_options) ? this.state.location_options : []}
                                    loadingSearch={this.state.loading_locations}
                                    onChange={(value) => {
                                        let location = null;
                                        if (!this.state.map.value) {
                                            if (!this.state.location_type || (this.state.location_type && this.state.location_type.id == 1)) {
                                                value.included = true;
                                            } else {
                                                value.included = false;
                                            }
                                            location = value;
                                            location.id = Math.floor((Math.random() * 9999999999) + 1);
                                            if (!Array.isArray(this.state.updated.locations)) {
                                                this.state.updated.locations = [];
                                            }
                                            this.state.updated.locations.push(location);
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        } else {
                                            let self = this;
                                            this.geocoder.geocode({ 'placeId': value.place_id }, async function (results, status) {
                                                if (status === 'OK' && results && results.length > 0) {
                                                    location = {
                                                        id: Math.floor((Math.random() * 9999999999) + 1),
                                                        custom: true,
                                                        included: true,
                                                        include: true,
                                                        new_location: true,
                                                        campaign_edit: true,
                                                        name: (parseFloat(results[0].geometry.location.lat().toFixed(3)) + " lat, " + parseFloat(results[0].geometry.location.lng()).toFixed(3) + " lng"),
                                                        lat: parseFloat(results[0].geometry.location.lat()),
                                                        lng: parseFloat(results[0].geometry.location.lng()),
                                                        radius: 30000,
                                                        geo_point: {
                                                            lat: parseFloat(results[0].geometry.location.lat()),
                                                            lng: parseFloat(results[0].geometry.location.lng())
                                                        }
                                                    };
                                                    if (!Array.isArray(self.state.updated.locations)) {
                                                        self.state.updated.locations = [];
                                                    }
                                                    self.state.updated.locations.push(location);
                                                    await self.promisedSetState({
                                                        place: location,
                                                        updated: self.state.updated,
                                                        updateMap: true
                                                    });
                                                    await self.promisedSetState({
                                                        updateMap: false
                                                    });
                                                }
                                            });
                                        }
                                    }}
                                    onSearch={async (value) => {
                                        await this.promisedSetState({ search_locations: value });
                                        if (this.state.map.value) {
                                            this.functions.searchGoogleLocations();
                                        } else {
                                            this.functions.searchLocations();
                                        }
                                    }}
                                />
                            </div>

                            {
                                Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.state.updated.locations.sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            let included_amount = this.state.updated.locations.filter((item) => { return item.included }).length;
                                            let included = this.state.updated.locations.filter((item) => { return item.included });
                                            let excluded = this.state.updated.locations.filter((item) => { return !item.included });
                                            return (
                                                <>
                                                    {included.length > 0 && index === 0 && (
                                                        <>
                                                            <div className="text-xs font-medium text-gray-700 mb-1">Included</div>
                                                            <div className='flex flex-wrap'>
                                                                {included.map((item, index) => (
                                                                    <div className={`${(((item.custom && this.state.map.value) || (!item.custom && !this.state.map.value)) ? " cursor-pointer " : " opacity-50 ") + " bg-indigo-100 text-indigo-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "} ${index === included.length + 1 ? "ml-1" : "mr-1"}`}
                                                                        style={{ minWidth: "fit-content", maxWidth: "fit-content", width: "fit-content" }}
                                                                    >
                                                                        <LocationMarkerIcon onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className={"text-indigo-500 h-5 w-5 mr-1"} aria-hidden="true" />
                                                                        <div onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                                        {
                                                                            this.state.map.value &&
                                                                            this.state.place && this.state.place.id == item.id &&
                                                                            <CursorClickIcon onClick={async () => {
                                                                                if (this.state.map.value && item.custom) {
                                                                                    await this.promisedSetState({
                                                                                        place: item,
                                                                                        updateMap: true
                                                                                    });
                                                                                    await this.promisedSetState({
                                                                                        updateMap: false
                                                                                    });
                                                                                }
                                                                            }} className={"text-indigo-500 h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                                        }
                                                                        <button
                                                                            onClick={() => {
                                                                                this.state.updated.locations = this.state.updated.locations.filter((location) => {
                                                                                    if (location.custom && item.custom) {
                                                                                        return !(location.lat == item.lat && location.lng == item.lng);
                                                                                    } else {
                                                                                        return location.key !== item.key;
                                                                                    }
                                                                                })
                                                                                this.setState({
                                                                                    updated: this.state.updated
                                                                                })
                                                                            }}
                                                                            type="button"
                                                                            className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove location</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {excluded.length > 0 && index === included_amount && (
                                                        <>
                                                            <div className="text-xs font-medium text-gray-700 mb-1">Excluded</div>
                                                            <div className='flex flex-wrap space-x-2'>
                                                                {excluded.map((item, index) => (
                                                                    <div className={(((item.custom && this.state.map.value) || (!item.custom && !this.state.map.value)) ? " cursor-pointer " : " opacity-50 ") + " bg-red-100 text-red-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}
                                                                        style={{ minWidth: "fit-content", maxWidth: "fit-content", width: "fit-content" }}
                                                                    >
                                                                        <LocationMarkerIcon onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className={"text-red-500 h-5 w-5 mr-1"} aria-hidden="true" />
                                                                        <div onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                                        {
                                                                            this.state.map.value &&
                                                                            this.state.place && this.state.place.id == item.id &&
                                                                            <CursorClickIcon onClick={async () => {
                                                                                if (this.state.map.value && item.custom) {
                                                                                    await this.promisedSetState({
                                                                                        place: item,
                                                                                        updateMap: true
                                                                                    });
                                                                                    await this.promisedSetState({
                                                                                        updateMap: false
                                                                                    });
                                                                                }
                                                                            }} className={"text-red-500 h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                                        }
                                                                        <button
                                                                            onClick={() => {
                                                                                this.state.updated.locations = this.state.updated.locations.filter((location) => {
                                                                                    if (location.custom && item.custom) {
                                                                                        return !(location.lat == item.lat && location.lng == item.lng);
                                                                                    } else {
                                                                                        return location.key !== item.key;
                                                                                    }
                                                                                })
                                                                                this.setState({
                                                                                    updated: this.state.updated
                                                                                })
                                                                            }}
                                                                            type="button"
                                                                            className={"focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove location</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            }

                            {/* {
                                Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.state.updated.locations.sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            let included_amount = this.state.updated.locations.filter((item) => { return item.included }).length;
                                            let excluded_amount = this.state.updated.locations.filter((item) => { return !item.included }).length;
                                            return (
                                                <Fragment>
                                                    {
                                                        index == 0 && included_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Included
                                                        </div>
                                                    }
                                                    {
                                                        (included_amount == index) && excluded_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Excluded
                                                        </div>
                                                    }
                                                    <div className={(((item.geo_point && this.state.map.value) || (!item.geo_point && !this.state.map.value)) ? " cursor-pointer " : " opacity-50 ") + (item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <LocationMarkerIcon onClick={async () => {
                                                            if (this.state.map.value && item.custom) {
                                                                await this.promisedSetState({
                                                                    place: item,
                                                                    updateMap: true
                                                                });
                                                                await this.promisedSetState({
                                                                    updateMap: false
                                                                });
                                                            }
                                                        }} className={(item.included ? "text-indigo-500" : "text-red-500") + " h-5 w-5 mr-1"} aria-hidden="true" />
                                                        <div onClick={async () => {
                                                            if (this.state.map.value && item.custom) {
                                                                await this.promisedSetState({
                                                                    place: item,
                                                                    updateMap: true
                                                                });
                                                                await this.promisedSetState({
                                                                    updateMap: false
                                                                });
                                                            }
                                                        }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                        {
                                                            this.state.map.value &&
                                                            this.state.place && this.state.place.id == item.id &&
                                                            <CursorClickIcon onClick={async () => {
                                                                if (this.state.map.value && item.custom) {
                                                                    await this.promisedSetState({
                                                                        place: item,
                                                                        updateMap: true
                                                                    });
                                                                    await this.promisedSetState({
                                                                        updateMap: false
                                                                    });
                                                                }
                                                            }} className={(item.included ? "text-indigo-500" : "text-red-500") + " h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                        }
                                                        <button
                                                            onClick={() => {
                                                                this.state.updated.locations = this.state.updated.locations.filter((location) => {
                                                                    if (location.custom && item.custom) {
                                                                        return !(location.lat == item.lat && location.lng == item.lng);
                                                                    } else {
                                                                        return location.key !== item.key;
                                                                    }
                                                                })
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                            type="button"
                                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove location</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            } */}

                            {
                                this.state.place &&
                                this.state.map.value &&
                                <div className="col-span-6">
                                    <div className="overflow-hidden border-1.5 border-estatery-400 rounded-md">
                                        <AdvertisingLocationMapRegions
                                            ref="googleMapsRef"
                                            update={this.state.updateMap}
                                            center={this.state.center}
                                            zoom={this.state.zoom}
                                            place={this.state.place}
                                            // places={this.state.updated.locations.filter((item) => { return item.geo_point })}
                                            // selected={this.state.updated.locations.filter((item) => { return item.geo_point })}
                                            places={this.state.updated && Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 && this.state.updated.locations.filter((item) => { return item.geo_point })}
                                            selected={this.state.updated && Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 && this.state.updated.locations.filter((item) => { return item.geo_point })}
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow"
                                            loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                                            containerElement={<div style={{
                                                height: "500px",
                                                width: '100%',
                                                borderRadius: '0px',
                                                overflow: 'hidden',
                                                border: 'none'
                                            }} />}
                                            mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                                            onUpdateLocations={(values) => {

                                            }}
                                            onUpdateSelected={(values, location) => {
                                                this.state.updated.locations = this.state.updated.locations.map((item) => {
                                                    if (item.id == location.id) {
                                                        item = location;
                                                        item.updated = true;
                                                        item.name = parseFloat(item.lat).toFixed(3) + " lat, " + parseFloat(item.lng).toFixed(3) + " lng";
                                                    }
                                                    return item;
                                                })
                                                this.setState({
                                                    place: location,
                                                    updated: this.state.updated
                                                })
                                            }}
                                            onUpdate={(value) => {

                                            }}
                                        />
                                    </div>
                                </div>
                            }

                        </>
                    }

                    {/*Settings*/}
                    {
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Advertising type"}
                                    searchInput={true}
                                    locked={true}
                                    selected={this.state.updated.advertising_type ? this.state.updated.advertising_type : { id: 0, name: "Select type" }}
                                    options={[
                                        { id: 1, name: 'Search', value: "SEARCH" },
                                        { id: 2, name: "Display network", value: "DISPLAY" },
                                        { id: 3, name: "Video (Youtube) - Preview only", value: "VIDEO" },
                                        { id: 4, name: "Shopping Standard", value: "SHOPPING" },
                                        //{ id: 5, name: "Shopping Max", value: "PERFORMANCE_MAX_SHOPPING" },
                                        { id: 6, name: "Performance Max", value: "PERFORMANCE_MAX" }
                                    ]}
                                    onChange={async (value) => {
                                        /*
                                        this.state.updated.advertising_type = value;
                                        if (value.value === "SEARCH") {

                                            this.state.updated.google_search = { id: 1, name: "Enabled", value: true, locked: true };
                                            this.state.updated.search_network = { id: 1, name: "Enabled", value: true };
                                            this.state.updated.content_network = { id: 1, name: "Enabled", value: true };
                                            this.state.updated.partner_search_network = { id: 2, name: "Disabled", value: false, locked: true };

                                            this.state.updated.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "SEARCH_STANDARD", ad: "RESPONSIVE_SEARCH_AD" };

                                        } else if (value.value === "DISPLAY") {

                                            this.state.updated.google_search = { id: 2, name: "Disabled", value: false, locked: true };
                                            this.state.updated.search_network = { id: 2, name: "Disabled", value: false, locked: true };
                                            this.state.updated.content_network = { id: 2, name: "Disabled", value: false, locked: true };
                                            this.state.updated.partner_search_network = { id: 1, name: "Enabled", value: true };

                                            this.state.updated.dsa = { id: 2, name: "Disable", value: false };

                                        } else if (value.value === "SHOPPING") {

                                            this.state.updated.google_search = { id: 2, name: "Disabled", value: false, locked: true };
                                            this.state.updated.search_network = { id: 2, name: "Disabled", value: false, locked: true };
                                            this.state.updated.content_network = { id: 1, name: "Enabled", value: true };
                                            this.state.updated.partner_search_network = { id: 2, name: "Disabled", value: false, locked: true };

                                            this.state.updated.enable_local = { id: 1, name: "Disabled", value: false };
                                            this.state.updated.strategy_type = { id: 7, name: "Maximize clicks", value: "TARGET_SPEND" };
                                            this.state.updated.shopping_priority = { id: 1, name: "Low (default) – Recommended if you only have one Shopping campaign", value: 0 };
                                            this.state.updated.dsa = { id: 2, name: "Disable", value: false };
                                            this.state.updated.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "SHOPPING_PRODUCT_ADS", ad: "SHOPPING_PRODUCT_AD" };

                                        } else if (value.value === "VIDEO") {

                                            this.state.updated.google_search = { id: 2, name: "Disabled", value: false, locked: true };
                                            this.state.updated.search_network = { id: 2, name: "Disabled", value: false, locked: true };
                                            this.state.updated.content_network = { id: 1, name: "Enabled", value: true };
                                            this.state.updated.partner_search_network = { id: 2, name: "Disabled", value: false, locked: true };

                                            this.state.updated.dsa = { id: 2, name: "Disable", value: false };
                                            this.state.updated.advertising_sub_type = { id: 1, name: "Video" };
                                            //this.state.updated.advertising_sub_type = { id: 1, name: "Video non skippable", value: "VIDEO_NON_SKIPPABLE", adgroup: "VIDEO_NON_SKIPPABLE_IN_STREAM", ad: "VIDEO_NON_SKIPPABLE_IN_STREAM_AD" };

                                        } else if (value.value === "PERFORMANCE_MAX") {

                                            this.state.updated.strategy_type = { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS" };
                                            this.state.updated.dsa = { id: 2, name: "Disable", value: false };
                                            this.state.updated.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "PERFORMANCE_MAX", ad: "PERFORMANCE_MAX" };

                                        }
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                        this.functions.updateAdgroups();
                                        */
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && this.state.updated.advertising_type.value === "VIDEO" &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Advertising Sub type"}
                                    searchInput={true}
                                    locked={true}
                                    selected={this.state.updated.advertising_sub_type ? this.state.updated.advertising_sub_type : { id: 0, name: "Select sub type" }}
                                    options={[
                                        { id: 1, name: "Video non skippable", value: "VIDEO_NON_SKIPPABLE", adgroup: "VIDEO_NON_SKIPPABLE_IN_STREAM", ad: "VIDEO_NON_SKIPPABLE_IN_STREAM_AD" },
                                        { id: 2, name: "Video outstream", value: "VIDEO_OUTSTREAM", adgroup: "VIDEO_OUTSTREAM", ad: "VIDEO_OUTSTREAM_AD" }
                                    ]}
                                    onChange={async (value) => {
                                        this.state.updated.advertising_sub_type = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                        this.functions.updateAdgroups();
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && !["PERFORMANCE_MAX", "DISPLAY"].includes(this.state.updated.advertising_type.value) &&
                        <div className="col-span-6 grid grid-cols-12 gap-4">
                            {
                                this.state.networks.map((item) => {
                                    return (
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={item.name}
                                                searchInput={false}
                                                locked={this.state.updated[item.value] && this.state.updated[item.value].locked}
                                                selected={this.state.updated[item.value] ? this.state.updated[item.value] : { id: 2, name: "Disabled", value: false }}
                                                options={[
                                                    { id: 1, name: "Enabled", value: true },
                                                    { id: 2, name: "Disabled", value: false }
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.updated[item.value] = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    });
                                                    this.functions.updateAdgroups();
                                                }}
                                                onSearch={async (value) => {

                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && (this.state.updated.advertising_type.value === "DISPLAY") &&
                        <>
                            <div className="col-span-6 grid grid-cols-12">
                                <div class="col-span-12 text-xs font-medium text-gray-700 mb-1 w-full">Content Exclusions</div>
                                {this.state.content_exclusions.map((content, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={async () => {
                                                if (!this.state.updated.display_settings) {
                                                    this.state.updated.display_settings = { contentLabel: [] };
                                                }

                                                const find = this.state.updated.display_settings.contentLabel.findIndex(item => item.value === content.value);
                                                if (find >= 0) {
                                                    content.included = false;
                                                    this.state.updated.display_settings.contentLabel = this.state.updated.display_settings.contentLabel.filter(item => item.value !== content.value)
                                                } else {
                                                    content.included = true;
                                                    this.state.updated.display_settings.contentLabel.push(content);
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                            className={"col-span-12 md:col-span-6 lg:col-span-4 " + (content.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                            <div className="">{content.name}</div>
                                            <div className="ml-auto">
                                                <div className={(content.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                    <CheckIcon className={(content.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="col-span-6 flex flex-wrap">
                                <div class="text-xs font-medium text-gray-700 mb-1 w-full">Set specific targeting for devices (Default is all devices)</div>
                                {this.state.deviceSetting.map((content, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={async () => {

                                                content.included = !content.included;
                                                //if (!this.state.updated.deviceType) {
                                                this.state.updated.deviceType = JSON.parse(JSON.stringify(this.state.deviceSetting));
                                                //}

                                                //const find = this.state.updated.deviceType.findIndex(item => item.value === content.value);
                                                //if (find >= 0) {
                                                //this.state.updated.deviceType = this.state.updated.deviceType.filter(device => device.value !== content.value);
                                                //} else {
                                                //this.state.updated.deviceType.push(content)
                                                //}
                                                //this.state.updated.devices = !!this.state.updated.deviceType.length;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                            className={"sm:w-full md:w-1/4 lg:w-1/4 " + (content.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                            <div className="">{content.name}</div>
                                            <div className="ml-auto">
                                                <div className={(content.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                    <CheckIcon className={(content.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {
                                //NEED REVIEW
                                false &&
                                <div className="col-span-6 flex flex-row">
                                    <div className="col-span-3 flex-1">
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Dynamic Ads</div>
                                        <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5">
                                                {this.state.updated.dynamic_ads ? "Active" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    disabled={false}
                                                    value={!!this.state.updated.dynamic_ads}
                                                    onSwitch={async () => {
                                                        this.state.updated.dynamic_ads = !this.state.updated.dynamic_ads;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-3 flex-1 ml-1">
                                        <DropdownTailwind
                                            label={"Bussines type"}
                                            searchInput={true}
                                            disabled={!this.state.updated.dynamic_ads}
                                            selected={this.state.updated.bussinesType || { name: "Select bussines type" }}
                                            options={this.state.bussinesTypes}
                                            onChange={(value) => {
                                                this.state.updated.bussinesType = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && (this.state.updated.advertising_type.value === "SHOPPING" || this.state.updated.advertising_type.value === "PERFORMANCE_MAX") &&
                        <div className="text-sm font-medium col-span-6">
                            Shopping
                        </div>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && (this.state.updated.advertising_type.value === "SHOPPING" || this.state.updated.advertising_type.value === "PERFORMANCE_MAX") &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"To advertise products on your website, select a Merchant Center account"}
                                    searchInput={true}
                                    selected={this.state.updated.merchant ? this.state.updated.merchant : { name: "Select merchant" }}
                                    options={this.state.merchant_accounts}
                                    onChange={(value) => {
                                        delete this.state.updated.feed_label;
                                        this.state.updated.merchant = value;
                                        this.setState({
                                            updated: this.state.updated
                                        });
                                        if (value.id !== 0) {
                                            try {
                                                this.functions.getProducts(value.id.split("/")[3]);
                                            } catch (error) { }
                                        }
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && (this.state.updated.advertising_type.value === "SHOPPING" || this.state.updated.advertising_type.value === "PERFORMANCE_MAX") &&
                        this.state.updated.merchant && this.state.updated.merchant.id && this.state.updated.merchant.id !== 0 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Choose feeds by feed label"}
                                    searchInput={true}
                                    loader={this.state.loading_products}
                                    selected={this.state.updated.feed_label ? this.state.updated.feed_label : { name: "Select label" }}
                                    options={this.state.feed_labels}
                                    onChange={(value) => {
                                        this.state.updated.feed_label = value;
                                        this.state.updated.preview_products = this.state.products.filter((item, index) => {
                                            return item.feed_label === value.id && index < 10;
                                        });
                                        this.setState({
                                            updated: this.state.updated
                                        });
                                        this.functions.updateAdgroups();
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && (this.state.updated.advertising_type.value === "SHOPPING") &&
                        this.state.updated.merchant && this.state.updated.merchant.id && this.state.updated.merchant.id !== 0 &&
                        this.state.updated.feed_label && this.state.updated.feed_label.id &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Campaign priority"}
                                    searchInput={true}
                                    selected={this.state.updated.shopping_priority ? this.state.updated.shopping_priority : { name: "Select a campaign priority" }}
                                    options={[
                                        { id: 1, name: "Low (default) – Recommended if you only have one Shopping campaign", value: 0 },
                                        { id: 2, name: "Medium", value: 1 },
                                        { id: 3, name: "High", value: 2 }
                                    ]}
                                    onChange={(value) => {
                                        this.state.updated.shopping_priority = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && (this.state.updated.advertising_type.value === "SHOPPING") &&
                        this.state.updated.merchant && this.state.updated.merchant.id && this.state.updated.merchant.id !== 0 &&
                        this.state.updated.feed_label && this.state.updated.feed_label.id &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Local products"}
                                    searchInput={true}
                                    selected={this.state.updated.enable_local ? this.state.updated.enable_local : { id: 1, name: "Disabled", value: false }}
                                    options={[
                                        { id: 1, name: "Disabled", value: false },
                                        { id: 2, name: "Enabled", value: true },
                                    ]}
                                    onChange={(value) => {
                                        this.state.updated.enable_local = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.advertising_type && this.state.updated.advertising_type.value !== "SHOPPING" &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Languages"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={this.state.languages}
                                    onChange={(value) => {
                                        let location = value;
                                        location.id = Math.floor((Math.random() * 9999999999) + 1);
                                        if (!Array.isArray(this.state.updated.languages)) {
                                            this.state.updated.languages = [];
                                        }
                                        this.state.updated.languages.push(location);
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        Array.isArray(this.state.updated.languages) && this.state.updated.languages.length > 0 &&
                        <div className="col-span-6 flex flex-col">
                            {
                                this.state.updated.languages.map((item, index) => {
                                    return (
                                        <Fragment>
                                            <div className={" bg-indigo-100 text-indigo-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                <div className="flex flex-1 whitespace-no-wrap truncate">{item.name}</div>
                                                <button
                                                    onClick={() => {
                                                        this.state.updated.languages = this.state.updated.languages.filter((location) => {
                                                            return location.code !== item.code;
                                                        })
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                    type="button"
                                                    className={" focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                >
                                                    <span className="sr-only">Remove language</span>
                                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.state.updated.advertising_type && this.state.updated.advertising_type.value === "SEARCH" &&
                        this.state.tab.id == 4 &&
                        <div className="text-sm font-medium col-span-6">
                            Dynamic search ad
                        </div>
                    }
                    {
                        this.state.updated.advertising_type && this.state.updated.advertising_type.value === "SEARCH" &&
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Dynamic search ad"}
                                    searchInput={false}
                                    selected={this.state.updated.dsa ? this.state.updated.dsa : { id: 2, name: "Disable", value: false }}
                                    options={[
                                        { id: 1, name: "Enable", value: true },
                                        { id: 2, name: "Disable", value: false }
                                    ]}
                                    onChange={async (value) => {
                                        this.state.updated.dsa = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.updated.dsa && this.state.updated.dsa.value &&
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Domain (ex www.website.com or website.com)"}
                                    value={this.state.updated.dsa_domain}
                                    onChange={(value) => {
                                        this.state.updated.dsa_domain = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.updated.dsa && this.state.updated.dsa.value &&
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Language"}
                                    searchInput={true}
                                    selected={this.state.updated.dsa_language ? this.state.updated.dsa_language : { id: 0, name: "Select language", value: null }}
                                    options={this.state.languages}
                                    onChange={async (value) => {
                                        this.state.updated.dsa_language = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.updated.dsa && this.state.updated.dsa.value &&
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Select URLs to use"}
                                    searchInput={false}
                                    selected={this.state.updated.enable_feeds ? this.state.updated.enable_feeds : { id: 1, name: "Use all URLs that Google knows about the website (including any page feeds)", value: false }}
                                    options={[
                                        { id: 1, name: "Use all URLs that Google knows about the website (including any page feeds)", value: false },
                                        //{ id: 2, name: "Use only URLs from page feeds", value: true }
                                    ]}
                                    onChange={async (value) => {
                                        this.state.updated.enable_feeds = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.updated.dsa && this.state.updated.dsa.value &&
                        this.state.tab.id == 4 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Page feeds"}
                                    searchInput={true}
                                    selected={{ id: 0, name: "Select feed", value: null }}
                                    options={this.state.asset_sets}
                                    onChange={async (value) => {
                                        if (!Array.isArray(this.state.updated.dsa_assets)) {
                                            this.state.updated.dsa_assets = [];
                                        }
                                        this.state.updated.dsa_assets.push(value);
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        this.state.tab.id == 4 &&
                        this.state.updated.dsa && this.state.updated.dsa.value &&
                        Array.isArray(this.state.updated.dsa_assets) && this.state.updated.dsa_assets.length > 0 &&
                        <div className="col-span-6 flex flex-col">
                            {
                                this.state.updated.dsa_assets.map((item, index) => {
                                    return (
                                        <Fragment>
                                            <div className={" bg-indigo-100 text-indigo-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                <div className="flex flex-1 whitespace-no-wrap truncate">{item.name}</div>
                                                <button
                                                    onClick={() => {
                                                        this.state.updated.dsa_assets = this.state.updated.dsa_assets.filter((location) => {
                                                            return location.resource_name !== item.resource_name;
                                                        })
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                    type="button"
                                                    className={" focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                >
                                                    <span className="sr-only">Remove asset</span>
                                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    }

                    {/*Targeting*/}
                    {
                        this.state.tab.id == 5 &&
                        this.state.updated.days.map((item, index) => {
                            return (
                                <div className="col-span-12 mt-2">
                                    <div className="grid grid-cols-6 gap-4">
                                        <div className="col-span-6 grid grid-cols-12 gap-4 pr-">
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"Day"}
                                                    selected={item}
                                                    options={this.state.days.filter((inner_item) => {
                                                        let buffer = true;
                                                        this.state.updated.days.map((inner_inner_item) => {
                                                            if (inner_inner_item.value === inner_item.value) {
                                                                buffer = false
                                                            }
                                                        })
                                                        return buffer
                                                    })}
                                                    onChange={(value) => {
                                                        this.state.updated.days[index] = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                    rightLabel={Array.isArray(this.state.updated.days) && this.state.updated.days.length > 1}
                                                    rightLabelColor={"hover:text-red-700 text-red-500"}
                                                    rightLabelText={"Remove"}
                                                    onRightLabelClick={() => {
                                                        this.state.updated.days = this.state.updated.days.filter((number) => { return number.id !== item.id });
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"Start time"}
                                                    locked={this.renders.start_end(index)}
                                                    selected={this.renders.startHour(index)}
                                                    options={this.state.hours_options.slice(0, this.state.updated.days ? this.state.updated.days[index].end.id : -1)}
                                                    onChange={(value) => {
                                                        this.state.updated.days[index].start = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"End time"}
                                                    locked={this.renders.start_end(index)}
                                                    selected={this.renders.endHour(index)}
                                                    options={this.state.hours_options.slice(this.state.updated.days ? this.state.updated.days[index].start.id : 1, this.state.hours_options.length)}
                                                    onChange={(value) => {
                                                        this.state.updated.days[index].end = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        this.state.tab.id == 5 && this.state.updated.days.length < 7 &&
                        <div onClick={() => {
                            this.state.updated.days.push({ id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } });
                            this.setState({
                                updated: this.state.updated
                            });
                        }} className="col-span-12 mt-4 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                            Add new schedule
                        </div>
                    }
                    {/*SCHEDULER */}
                    {
                        this.state.tab.id == 11 &&
                        <>
                            <div className='col-span-6'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Schedule</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.state.updated.scheduler.schedule ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            disabled={this.state.updated.bingSync ? true : false}
                                            value={this.state.updated.scheduler.schedule ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.scheduler.schedule = !this.state.updated.scheduler.schedule;
                                                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer) {
                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                        this.state.updated.budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                    } else {
                                                        this.state.updated.budget = 0;
                                                    }
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-span-6 grid grid-cols-12 gap-4 transform transition-all duration-500 ease-in-out overflow-hidden' + (!this.state.updated.scheduler.schedule ? " h-0" : "")}>
                                <div className='col-span-12'>
                                    <div className={"text-xs font-medium mb-1 text-gray-700"}>
                                        Days
                                    </div>
                                    <div className='flex flex-wrap'>
                                        {
                                            [
                                                { name: "Monday", property: "scheduleMonday" },
                                                { name: "Tuesday", property: "scheduleTuesday" },
                                                { name: "Wednesday", property: "scheduleWednesday" },
                                                { name: "Thursday", property: "scheduleThursday" },
                                                { name: "Friday", property: "scheduleFriday" },
                                                { name: "Saturday", property: "scheduleSaturday" },
                                                { name: "Sunday", property: "scheduleSunday" }
                                            ].map((weekday) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (this.state.updated.scheduler.weekdays[weekday.property]) {
                                                            delete (this.state.updated.scheduler.weekdays[weekday.property]);
                                                        } else {
                                                            this.state.updated.scheduler.weekdays[weekday.property] = true;
                                                        }
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.budget = 0;
                                                            }
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })


                                                    }} className={(this.state.updated.scheduler.weekdays[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div>{weekday.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(this.state.updated.scheduler.weekdays[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(this.state.updated.scheduler.weekdays[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-span-12 overflow-hidden ease-in-out transition-all duration-500 grid grid-cols-4 gap-4'>
                                    <div className='col-span-4'>
                                        <div className='flex flex-row pt-4 overflow-hidden ease-in-out transition-all duration-500'>
                                            <div className={'flex flex-col transition-all transform duration-500 overflow-hidden' + (!this.state.updated.scheduler.planer ? " flex-1" : " pr-4")}>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 min-w-24">
                                                        {(this.state.updated.scheduler.planer ? "Active" : "Disabled")}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            disabled={this.state.updated.bingSync || this.state.updated.googleBudgets ? true : false}
                                                            value={this.state.updated.scheduler.planer}
                                                            onSwitch={async () => {
                                                                this.state.updated.scheduler.planer = !this.state.updated.scheduler.planer;
                                                                if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                    this.state.updated.budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                                } else {
                                                                    this.state.updated.budget = 0;
                                                                }
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "pr-4" : "w-0 hidden")}>
                                                <div className=' pr-2'>
                                                    <InputTailwind
                                                        locked={!this.state.updated.scheduler.planer || this.state.updated.bingSync}
                                                        disabled={!this.state.updated.scheduler.planer || this.state.updated.bingSync}
                                                        label={"Total monthly"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.scheduler.schedule_budget}
                                                        type={"number"}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.schedule_budget = value;
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <InputTailwind
                                                        locked={true}
                                                        disabled={true}
                                                        label={"Daily budget"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.budget}
                                                        type={"number"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                <div className='w-full'>
                                                    <InputDatepickerTailwind
                                                        error={campaignGoogle.validate(this.state.updated).start_date}
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        locked={!this.state.updated.scheduler.planer}
                                                        placeholder={moment(this.state.updated.scheduler.start_date).format("MM/DD/YYYY")}
                                                        label={"Start date"}
                                                        value={moment(this.state.updated.scheduler.start_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.start_date = moment(value).format("YYYY-MM-DD");
                                                            this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.budget = 0;
                                                            }
                                                            if (this.renders.endDate() && new Date(this.renders.endDate()) < new Date(value)) {
                                                                this.state.updated.end_date = moment(value).add(1, 'days').format("YYYY-MM-DD");
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.updated.scheduler.order_info && this.state.updated.scheduler.order_info.maxBudgetSubAction &&
                                                <div className={'flex transition-all transform duration-500 overflow-hidden pl-4 ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                    <div className=''>
                                                        <InputTailwind
                                                            locked={true}
                                                            disabled={true}
                                                            label={"Order pacing"}
                                                            value={this.state.updated.scheduler.order_info.maxBudgetSubAction && this.state.updated.scheduler.order_info.maxBudgetSubAction.id ? (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 1 ? "Spend total" : (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 2 ? "Spend pacing" : "Unkown")) : "Unkown"}
                                                        />
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.updated.scheduler.planer && this.state.updated.scheduler.schedule_budget > 0 && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity &&
                                            <div className='flex flex-row border-t mt-4'>
                                                <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                    OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='col-span-4'>
                                        <div className={" mt-4 pt-4" + (this.state.updated.scheduler.planer ? " h-64" : " h-0")}>
                                            <Bar
                                                options={{
                                                    maintainAspectRatio: false,
                                                    interaction: {
                                                        intersect: false,
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                suggestedMin: 0
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={budgetPlannerGraph.main(this.state.updated.scheduler)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }

                    {/*BING SYNC */}
                    {
                        this.state.tab.id == 12 &&
                        <>
                            <div className='col-span-6'>
                                <InputTailwind
                                    channel={"bing"}
                                    label={"Campaign name"}
                                    value={this.renders.name()}
                                    locked={true}
                                    disabled={true}
                                />
                            </div>
                            <div className='col-span-3'>
                                <InputTailwind
                                    channel={"bing"}
                                    label={"Total monthly"}
                                    leftSection={(this.state.currency !== "" ? true : false)}
                                    leftSectionLabel={(this.state.currency)}
                                    value={+this.state.updated.bingSync.budget * (+this.state.updated.bingSync.bing_budget / 100)}
                                    locked={true}
                                    disabled={true}
                                />
                            </div>
                            <div className='col-span-3'>
                                <InputTailwind
                                    channel={"bing"}
                                    label={"Daily budget"}
                                    leftSection={(this.state.currency !== "" ? true : false)}
                                    leftSectionLabel={(this.state.currency)}
                                    value={budgetPlannerGraph.main(this.state.updated.scheduler, +this.state.updated.bingSync.budget * (+this.state.updated.bingSync.bing_budget / 100)).datasets[0].data[0] === Infinity ? 0 : budgetPlannerGraph.main(this.state.updated.scheduler, +this.state.updated.bingSync.budget * (+this.state.updated.bingSync.bing_budget / 100)).datasets[0].data[0]}
                                    locked={true}
                                    disabled={true}
                                />
                            </div>
                            <div className='col-span-6'>
                                <div className={" mt-4 pt-4" + (this.state.updated.scheduler.planer ? " h-64" : " h-0")}>
                                    <Bar
                                        options={{
                                            maintainAspectRatio: false,
                                            interaction: {
                                                intersect: false,
                                            },
                                            legend: {
                                                display: false
                                            },
                                            scales: {
                                                yAxes: [{}]
                                            },
                                        }}
                                        data={budgetPlannerGraph.main(this.state.updated.scheduler, +this.state.updated.bingSync.budget * (+this.state.updated.bingSync.bing_budget / 100))}
                                    />
                                </div>
                            </div>
                            <div className='col-span-6'>
                                <DropdownTailwind
                                    channel={"bing"}
                                    label={"Schedule"}
                                    selected={this.state.updated.bingSync.schedule}
                                    options={[{ id: 2, name: "Once", value: "Once" }, { id: 3, name: "Daily", value: "Daily" }, { id: 4, name: "Weekly", value: "Weekly" }, { id: 5, name: "Monthly", value: "Monthly" }]}
                                    onChange={(value) => {
                                        this.state.updated.bingSync.schedule = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            {
                                this.state.updated.bingSync && this.state.updated.bingSync.schedule && this.state.updated.bingSync.schedule.value === "Once" &&
                                <div className='col-span-2'>
                                    <InputDatepickerTailwind
                                        channel={"bing"}
                                        error={this.state.updated.bingSync.date ? false : true}
                                        label={"Date"}
                                        value={this.state.updated.bingSync.date ? this.state.updated.bingSync.date : ""}
                                        onChange={(value) => {
                                            this.state.updated.bingSync.date = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }

                            {
                                this.state.updated.bingSync && this.state.updated.bingSync.schedule && this.state.updated.bingSync.schedule.value === "Weekly" &&
                                <div className='col-span-2'>
                                    <DropdownTailwind
                                        searchInput={false}
                                        channel={"bing"}
                                        error={this.state.updated.bingSync.week ? false : true}
                                        label={"Week"}
                                        selected={this.state.updated.bingSync.week ? this.state.updated.bingSync.week : { id: 0, name: "Select week", value: null }}
                                        options={this.state.weekOptions}
                                        placeholder={"Select day of the week"}
                                        onChange={async (value) => {
                                            this.state.updated.bingSync.week = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.updated.bingSync && this.state.updated.bingSync.schedule && this.state.updated.bingSync.schedule.value === "Monthly" &&
                                <div className='col-span-2'>
                                    <DropdownTailwind
                                        searchInput={false}
                                        error={this.state.updated.bingSync.day ? false : true}
                                        label={"Day of the Month"}
                                        selected={this.state.updated.bingSync.day ? this.state.updated.bingSync.day : { id: 0, name: "Select day of the month", value: null }}
                                        options={this.state.daysOfMonthOptions}
                                        placeholder={"Select day of the month"}
                                        onChange={async (value) => {
                                            this.state.updated.bingSync.day = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }}
                                    />
                                </div>
                            }

                            {
                                this.state.updated.bingSync && this.state.updated.bingSync.schedule &&
                                <div className={this.state.updated.bingSync && this.state.updated.bingSync.schedule && this.state.updated.bingSync.schedule.value === "Daily" ? 'col-span-3' : "col-span-1"}>
                                    <DropdownTailwind
                                        searchInput={false}
                                        label={"At (24-hours)"}
                                        error={this.state.updated.bingSync.time ? false : true}
                                        selected={this.state.updated.bingSync.time ? this.state.updated.bingSync.time : { id: 0, name: "Select time", value: null }}
                                        options={this.state.timeOptions}
                                        placeholder={"Select time"}
                                        onChange={async (value) => {
                                            this.state.updated.bingSync.time = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.updated.bingSync && this.state.updated.bingSync.schedule &&
                                <div className='col-span-3'>
                                    <DropdownTailwind
                                        searchInput={true}
                                        error={this.state.updated.bingSync.timeZone ? false : true}
                                        label={"Time Zone"}
                                        selected={this.state.updated.bingSync.timeZone ? this.state.updated.bingSync.timeZone : { id: 0, name: "Select time zone", value: null }}
                                        options={this.state.timezoneOptions}
                                        placeholder={"Select time"}
                                        onChange={async (value) => {
                                            this.state.updated.bingSync.timeZone = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }}
                                    />
                                </div>
                            }
                            <div className='col-span-6'>
                                <DropdownTailwind
                                    channel={"bing"}
                                    label={"Notifications"}
                                    selected={this.state.updated.bingSync.notificationType}
                                    options={[{ id: 2, name: "Only if there are changes or errors", value: "Changes" },
                                    { id: 3, name: "Only if there are errors", value: "Errors" },
                                    { id: 4, name: "Every time this import runs", value: "EveryTime" }]}
                                    onChange={(value) => {
                                        this.state.updated.bingSync.notificationType = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className='col-span-6'>
                                <InputTailwind
                                    channel={"bing"}
                                    label={"Email"}
                                    value={this.state.updated.bingSync.email}
                                    onChange={(value) => {
                                        this.state.updated.bingSync.email = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }

                </div>
            </>
        )
    }
}

export default EditGoogleCampaign;
