import React, { Component, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, DownloadIcon, ExternalLinkIcon, FolderIcon, PencilAltIcon, LightBulbIcon, NewspaperIcon, SearchIcon, ThumbDownIcon, TrashIcon, ViewGridIcon, PlusCircleIcon, BeakerIcon, StopIcon, LockClosedIcon, PlusIcon, SaveAsIcon, ExclamationIcon, PresentationChartLineIcon, ReplyIcon, MinusIcon, XIcon, ShieldExclamationIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import WizardModal from '../moduleFiles/wizardModal';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import SuccessModal from '../moduleFiles/successModal';
import moment from 'moment';
import { random, round } from '@turf/turf';
import SyncWithBing from '../moduleFiles/syncWithBing';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import Loader from '../components/loader';
import InputTailwind from '../moduleFiles/inputTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import CreateBudgetPlanItem from '../moduleFiles/createBudgetPlanItem';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { PauseIcon, PlayIcon } from '@heroicons/react/solid';
import ToolTip from '../moduleFiles/toolTip';
import ReactTooltip from 'react-tooltip';
import ChannelBudgetModal from '../moduleFiles/updateChannelBudgetModal';
import { update } from 'immutable';
import SlideDown from 'react-slidedown';
import { Doughnut } from 'react-chartjs-2';
class OrderBudgetPlanerTemp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            allow_updates: {},
            order: {},
            client: {},
            errors: {},
            updated: {},
            loading_channels: {},
            tab: { id: 1, name: "Max budget" },
            tabs: [
                { id: 1, name: "Budget" },
                { id: 2, name: "Upcoming" },
                { id: 3, name: "History" }
            ],
            currency: "",
            today: moment().format("YYYY-MM-DD"),
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
            show: {},
            currencies: [],
            open_channel: {},
            test_123: true,
            update_channel_items: {},
        }
    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getOrder();
        this.functions.animate();
        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    functions = {
        updateToBudgetChannels: async () => {
            await this.promisedSetState({ loading_planer: true });
            this.state.order.budgetChannels = {};
            Object.keys(this.state.budget_planer).map((channel) => {
                this.state.order.budgetChannels[channel] = {
                    value: 0,
                    off_budget: 0,
                };
                if (Array.isArray(this.state.budget_planer[channel])) {
                    this.state.budget_planer[channel].map((item) => {
                        if ((item.scheduleActivated || item.budgetPlanerActivated) && item.budgetPlanerTotal && +item.budgetPlanerTotal > 0 && !isNaN(+item.budgetPlanerTotal)) {
                            this.state.order.budgetChannels[channel].value += +item.budgetPlanerTotal;
                        }
                        if (item.external_lifetime_budget && (item.scheduleActivated || item.budgetPlanerActivated)) {
                            this.state.order.budgetChannels[channel].value += +item.external_lifetime_budget;
                        }
                        if (Array.isArray(item.adgroups)) {
                            item.adgroups.map((inner_item) => {
                                if ((inner_item.scheduleActivated || inner_item.budgetPlanerActivated) && inner_item.budgetPlanerTotal && +inner_item.budgetPlanerTotal > 0 && !isNaN(+inner_item.budgetPlanerTotal)) {
                                    this.state.order.budgetChannels[channel].value += +inner_item.budgetPlanerTotal;

                                }
                                if (inner_item.external_lifetime_budget && (inner_item.scheduleActivated || inner_item.budgetPlanerActivated)) {
                                    this.state.order.budgetChannels[channel].value += +inner_item.external_lifetime_budget;
                                }
                            });
                        }
                    });
                    if (this.state.order.maxBudget && +this.state.order.maxBudget > 0) {
                        this.state.order.budgetChannels[channel].off_budget = round((this.state.order.budgetChannels[channel].value / +this.state.order.maxBudget) * 100, 2);
                    }
                }
            });
            await this.promisedSetState({ order: this.state.order });
            Object.keys(this.state.budget_planer).map((channel) => {
                this.state.budget_planer[channel] = this.state.budget_planer[channel].map((item) => {
                    if (item.scheduleActivated || item.budgetPlanerActivated && item.budgetPlanerTotal > 0) {
                        item.tempPercentage = this.renders.percentageOfMaxbudget(item, item.channel)
                    }
                    if (Array.isArray(item.adgroups)) {
                        item.adgroups = item.adgroups.map((inner_item) => {
                            if (inner_item.scheduleActivated || inner_item.budgetPlanerActivated && inner_item.budgetPlanerTotal > 0) {
                                inner_item.tempPercentage = this.renders.percentageOfMaxbudget(inner_item, item.channel)
                            }
                            return inner_item;
                        });
                    }
                    return item;
                });
            });
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
            let buff = Object.keys(this.state.budget_planer)
            for (let i = 0; i < buff.length; i++) {
                for (let j = 0; j < this.state.budget_planer[buff[i]].length; j++) {
                    let item = this.state.budget_planer[buff[i]][j];
                    if (item.scheduleActivated || item.budgetPlanerActivated) {
                        await this.functions.updateOrderCampaignBudgetPlan(item, item.channel);
                    }
                    if (Array.isArray(item.adgroups)) {
                        for (let k = 0; k < item.adgroups.length; k++) {
                            let adgroup = item.adgroups[k];
                            if (adgroup.scheduleActivated || adgroup.budgetPlanerActivated) {
                                await this.functions.updateOrderAdgroupBudgetPlan(item, adgroup, item.channel);
                            }
                        }
                    }
                }
            }
            try {
                await this.calls.updateOrder({
                    budgetChannels: this.state.order.budgetChannels
                });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_planer: false });
        },
        days: async (day, campaign, channel, type, adgroup = false) => {
            if (type === "adgroup") {
                if (adgroup.scheduleActivated) {
                    await this.promisedSetState({ errors: {} });
                    //await this.functions.animate("error_" + adgroup.id, { remove: ["h-12", 'mb-4'], add: ["h-0"] });
                    if (["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].filter((item) => { return adgroup[item] }).length === 1 && adgroup[day]) {
                        this.state.errors[adgroup.id] = "You need to have at least one day selected";
                        await this.promisedSetState({ errors: this.state.errors });
                        //await this.functions.animate("error_" + adgroup.id, { remove: ["h-0"], add: ["h-12", 'mb-4'] });
                    } else {
                        adgroup[day] = !adgroup[day];
                        adgroup.external_daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                        this.state.budget_planer[channel] = this.state.budget_planer[channel].map((item) => {
                            if (item.id == campaign.id) {
                                if (Array.isArray(item.adgroups) && item.adgroups.length > 0) {
                                    item.adgroups = item.adgroups.map((inner_item) => {
                                        if (inner_item.id == adgroup.id) {
                                            inner_item = adgroup;
                                        }
                                        return inner_item;
                                    });
                                }
                            }
                            return item;
                        });
                        await this.promisedSetState({
                            budget_planer: this.state.budget_planer
                        });
                        this.functions.updateAdgroupSchedule(campaign, channel, adgroup);
                    }
                }
            } else {
                if (campaign.scheduleActivated) {
                    await this.promisedSetState({ errors: {} });
                    //await this.functions.animate("error_" + campaign.id, { remove: ["h-12", 'mb-4'], add: ["h-0"] });
                    if (["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].filter((item) => { return campaign[item] }).length === 1 && campaign[day]) {
                        this.state.errors[campaign.id] = "You need to have at least one day selected";
                        await this.promisedSetState({ errors: this.state.errors });
                        //await this.functions.animate("error_" + campaign.id, { remove: ["h-0"], add: ["h-12", 'mb-4'] });
                    } else {
                        campaign[day] = !campaign[day];
                        campaign.external_daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                        this.state.budget_planer[channel] = this.state.budget_planer[channel].map((item) => {
                            if (item.id == campaign.id) {
                                item = campaign;
                            }
                            return item;
                        });
                        await this.promisedSetState({
                            budget_planer: this.state.budget_planer
                        });
                        this.functions.updateCampaignSchedule(campaign, channel);
                    }
                }
            }
        },
        updateCampaignSchedule: async (campaign, channel) => {
            campaign.loading = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            let body = {
                budgetplaner_activated: true,
                schedule_activated: true,
                schedule_monday: campaign.scheduleMonday,
                schedule_tuesday: campaign.scheduleTuesday,
                schedule_wednesday: campaign.scheduleWednesday,
                schedule_thursday: campaign.scheduleThursday,
                schedule_friday: campaign.scheduleFriday,
                schedule_saturday: campaign.scheduleSaturday,
                schedule_sunday: campaign.scheduleSunday,
                budgetplaner_total: campaign.budgetPlanerTotal,
                skip_trigger: true
            };
            try {
                let response = await this.calls.updateCampaignSchedule(body, campaign.internal_id);
            } catch (error) {
                this.state.errors[campaign.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
            }
            campaign.loading = false;
            campaign.edit = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
            this.functions.updateOrderCampaignBudgetPlan(campaign, channel, false, true);
        },
        updateAdgroupSchedule: async (campaign, channel, adgroup) => {
            adgroup.loading = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            let body = {
                budgetplaner_activated: true,
                schedule_activated: true,
                schedule_monday: adgroup.scheduleMonday,
                schedule_tuesday: adgroup.scheduleTuesday,
                schedule_wednesday: adgroup.scheduleWednesday,
                schedule_thursday: adgroup.scheduleThursday,
                schedule_friday: adgroup.scheduleFriday,
                schedule_saturday: adgroup.scheduleSaturday,
                schedule_sunday: adgroup.scheduleSunday,
                budgetplaner_total: adgroup.budgetPlanerTotal,
                skip_trigger: true
            };
            try {
                await this.calls.updateAdgroupSchedule(body, campaign.internal_id, adgroup.id);
            } catch (error) {
                this.state.errors[adgroup.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
            }
            adgroup.loading = false;
            adgroup.edit = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
            this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel, true);
        },
        maxBudgetReached: async (item) => {
            await this.functions.animate("max_error_" + item.id, { remove: ['h-0'], add: ['h-12', 'mb-4'] });
        },
        animate: async (id, property, style = false) => {
            return new Promise(async (resolve) => {
                try {
                    if (!id) {
                        if (this.state.order.maxBudgetEnabled) {
                            document.getElementById('hide_3').classList.replace('w-0', 'w-40');
                            document.getElementById('manual').classList.replace('flex-1', 'w-0');
                            document.getElementById('automatic').classList.replace('w-0', 'flex-1');
                            document.getElementById('manual').classList.add('text-white');
                            document.getElementById('automatic').classList.replace('text-white', 'text-purple-500');
                        } else {
                            if (!document.getElementById('hide_3').classList.contains('w-0') && document.getElementById('hide_3').classList.contains('w-40')) {
                                document.getElementById('hide_3').classList.replace('w-40', 'w-0');
                            }
                            document.getElementById('automatic').classList.replace('flex-1', 'w-0');
                            document.getElementById('manual').classList.replace('w-0', 'flex-1');
                            document.getElementById('automatic').classList.replace('text-purple-500', 'text-white');
                            document.getElementById('manual').classList.remove('text-white');
                        }
                    } else {
                        if (style) {
                            Object.keys(style).map((item) => {
                                document.getElementById(id).style[item] = style[item];
                            });
                            //document.getElementById(id).style.height = style;
                        } else {
                            document.getElementById(id).classList.remove(...property.remove);
                            document.getElementById(id).classList.add(...property.add);
                        }
                    }
                } catch (error) {

                }
                resolve();
            });
        },
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }
            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        updateMaxBudget: async () => {
            await this.promisedSetState({
                loading_maxbudget: true
            });
            try {
                await this.calls.updateOrder({
                    maxBudgetEnabled: this.state.order.maxBudgetEnabled,
                    maxBudget: this.state.order.maxBudget,
                    maxBudgetSubAction: this.state.order.maxBudgetSubAction,
                    crmId: this.state.order.crmId
                });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_maxbudget: false, allow_update: false, temp_order: null });
        },
        updateExternalBudget: async (item, channel, type, daily = false) => {
            item.loading = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            try {
                await this.calls.updateExternalBudget({
                    item: item,
                    channel: channel,
                    type: type,
                    daily: daily
                });
            } catch (error) {
                if (type === "campaign") {
                    this.state.errors[item.id] = error.body && error.body ? error.body.message : "Something went wrong";
                    await this.promisedSetState({ errors: this.state.errors });
                    await this.functions.animate("error_" + item.id, { remove: ["h-0"], add: ["h-12", 'mb-4'] });
                } else {
                    this.state.errors[item.id] = error.body && error.body ? error.body.message : "Something went wrong";
                    await this.promisedSetState({ errors: this.state.errors });
                }
            }
            item.loading = false;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
        },
        unblockMaxBudgetReached: async () => {
            await this.promisedSetState({
                loading_unblock: true
            });
            try {
                await this.calls.updateOrder({
                    unblockMaxBudgetReached: true
                });
                this.functions.getOrder(true);
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_unblock: false });
        },
        updateBudgetChannels: async (percentage = false, channel, skip_items = false) => {
            if (this.state.updated.budgetChannels) {
                this.state.order.budgetChannels = JSON.parse(JSON.stringify(this.state.updated.budgetChannels));
                this.state.loading_channels[channel] = true;
                await this.promisedSetState({
                    order: this.state.order,
                    error: false,
                    loading_channels: this.state.loading_channels
                });
                try {
                    await this.calls.updateOrder({
                        budgetChannels: this.state.order.budgetChannels
                    });
                } catch (error) {
                    await this.promisedSetState({
                        error: true,
                        error_message: error.body.message
                    });
                }
                delete this.state.loading_channels[channel];
                await this.promisedSetState({
                    loading_channels: this.state.loading_channels
                });
                if (!skip_items) {
                    if (!this.state.error) {
                        for (let i = 0; i < this.state.budget_planer[channel].length; i++) {
                            let item = this.state.budget_planer[channel][i];
                            if (item.scheduleActivated || item.budgetPlanerActivated) {
                                if (!percentage) {
                                    item.budgetPlanerTotal = this.renders.amountBasedOnPercentage(item.tempPercentage, item.channel).toFixed(2);
                                } else {
                                    item.tempPercentage = this.renders.percentageOfMaxbudget(item, item.channel)
                                }
                                await this.functions.updateOrderCampaignBudgetPlan(item, item.channel);
                            }
                            if (Array.isArray(item.adgroups)) {
                                for (let j = 0; j < item.adgroups.length; j++) {
                                    let adgroup = item.adgroups[j];
                                    if (adgroup.scheduleActivated || adgroup.budgetPlanerActivated) {
                                        if (!percentage) {
                                            adgroup.budgetPlanerTotal = this.renders.amountBasedOnPercentage(adgroup.tempPercentage, item.channel).toFixed(2);
                                        } else {
                                            adgroup.tempPercentage = this.renders.percentageOfMaxbudget(adgroup, item.channel)
                                        }
                                        await this.functions.updateOrderAdgroupBudgetPlan(item, adgroup, item.channel);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        updateMaxBudgetEnabled: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    loading_max_budget: true
                });
                try {
                    await this.calls.updateOrder({
                        maxBudgetEnabled: !this.state.order.maxBudgetEnabled
                    });
                    this.state.order.maxBudgetEnabled = !this.state.order.maxBudgetEnabled
                    await this.promisedSetState({ loading_max_budget: false, order: this.state.order });
                    if (this.state.order.maxBudgetEnabled) {
                        this.functions.getOrderBudgetPlaner();
                    }
                } catch (error) {
                    await this.promisedSetState({ error: true, error_message: error.body.message });
                }
                await this.functions.animate();
                if (this.state.order.maxBudgetEnabled) {
                    for (let channel in this.state.budget_planer) {
                        for (let campaign of this.state.budget_planer[channel]) {
                            if (campaign.scheduleActivated) {
                                if (+this.renders.total_left(campaign).toFixed(2) < campaign.budgetPlanerTotal) {
                                    await this.functions.maxBudgetReached(campaign);
                                }
                            }
                            if (Array.isArray(campaign.adgroups)) {
                                for (let adgroup of campaign.adgroups) {
                                    if (adgroup.scheduleActivated) {
                                        if (+this.renders.total_left(adgroup).toFixed(2) < adgroup.budgetPlanerTotal) {
                                            await this.functions.maxBudgetReached(adgroup);
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
                resolve();
            });
        },
        removeBudgetItems: async () => {
            this.state.order.budgetPlans = this.state.order.budgetPlans.map((item) => { if (item.selected) { item.loading = true }; return item })
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            await this.promisedSetState({ order: this.state.order });
            let buff = this.state.order.budgetPlans.filter((item) => { return item.selected }).map((item) => { return { ...item, status: "removed" } });
            let changelog = {
                initial_data: this.state.order.budgetPlans,
                updated_data: buff,
                level: "order",
                action: "remove",
                order: this.functions.getIdFromPath(),
                type: "budget_plan_item"
            }
            try {
                await this.calls.removeBudgetItems({ ids: this.state.order.budgetPlans.filter((item) => { return item.selected }).map((item) => { return item.id }), changelog });
                this.state.order.budgetPlans = this.state.order.budgetPlans.filter((item) => { return !item.selected });
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                console.log(error)
                this.state.order.budgetPlans = this.state.order.budgetPlans.map((item) => { item.loading = false; return item })
                await this.promisedSetState({ order: this.state.order });
            }
        },
        getOrder: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({ loading: true });
                try {
                    let response = await this.calls.order();
                    await this.promisedSetState({ order: response.data, meta: response.meta, temp_order: response.data, ...(response.data.client && { client: response.data.client }) });
                    this.functions.getOrderBudgetPlaner();
                    if (this.props.setOrder) {
                        this.props.setOrder(this.state.order);
                    }
                } catch (error) { }
                await this.promisedSetState({ loading: false });
                resolve();
            });
        },
        getOrderBudgetPlaner: async () => {
            await this.promisedSetState({ loading_planer: true });
            try {
                let response = await this.calls.getOrderBudgetPlaner();
                let currencies = {};
                for (let channel in response.data) {
                    if (Array.isArray(response.data[channel])) {
                        response.data[channel].map((item) => {
                            if (item.currency) {
                                currencies[item.currency] = true;
                            }
                        });
                    }
                }
                currencies = Object.keys(currencies);
                await this.promisedSetState({ budget_planer: response.data, initial_data: response.data ? JSON.parse(JSON.stringify(response.data)) : response.data, currencies: currencies });
                for (let channel in this.state.budget_planer) {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((item) => {
                        if (item.scheduleActivated || item.budgetPlanerActivated) {
                            item.tempPercentage = this.renders.percentageOfMaxbudget(item, item.channel)
                            if (item.percentage) {
                                item.tempPercentage = item.percentage;
                            }
                        } else {
                            item.tempPercentage = 0;
                        }
                        if (Array.isArray(item.adgroups)) {
                            item.adgroups = item.adgroups.map((inner_item) => {
                                if (inner_item.scheduleActivated || inner_item.budgetPlanerActivated) {
                                    inner_item.tempPercentage = this.renders.percentageOfMaxbudget(inner_item, item.channel)
                                    if (inner_item.percentage) {
                                        inner_item.tempPercentage = +inner_item.percentage;
                                    }
                                } else {
                                    inner_item.tempPercentage = 0;
                                }
                                return inner_item;
                            });
                        }
                        return item
                    });
                }
                await this.promisedSetState({ budget_planer: this.state.budget_planer });

            } catch (error) { }
            await this.promisedSetState({ loading_planer: false });
        },
        updateOrderAdgroupBudgetPlan: async (campaign, adgroup, channel, keep_edit) => {
            adgroup.loading = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            try {
                let response = await this.calls.updateOrderAdgroupBudgetPlan({
                    ...(this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.value == "spend_total" && { schedule_start_date: moment().format("YYYY-MM-DD") }),
                    budgetplaner_total: adgroup.budgetPlanerTotal,
                    percentage: adgroup.tempPercentage,
                    campaign: campaign.id,
                    adgroup: adgroup.id
                });
                adgroup.external_daily_budget = response.data;
                await this.promisedSetState({ budget_planer: this.state.budget_planer });
            } catch (error) {
                this.state.errors[adgroup.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
            }
            adgroup.loading = false;
            adgroup.edit = keep_edit;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
        },
        updateOrderCampaignBudgetPlan: async (campaign, channel, fix = false, keep_edit) => {
            campaign.loading = true;
            if (fix && campaign.budgetPlanerTotal === 0 && campaign.external_daily_budget > 0) {
                campaign.budgetPlanerTotal = this.renders.calculateTotalByDaily(campaign, false).total_budget;
            }
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            try {
                let response = await this.calls.updateOrderCampaignBudgetPlan({
                    ...(this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.value == "spend_total" && { schedule_start_date: moment().format("YYYY-MM-DD") }),
                    ...(campaign.tempPercentage && { percentage: campaign.tempPercentage }),
                    budgetplaner_total: campaign.budgetPlanerTotal,
                    campaign: campaign.id,
                    channel: campaign.channel,
                    budget_id: campaign.shared_budget ? (campaign.channel === "google" ? campaign.resourceName : campaign.id) : null,
                    name: campaign.name
                });
                campaign.external_daily_budget = response.data;
                await this.promisedSetState({ budget_planer: this.state.budget_planer });
            } catch (error) {
                this.state.errors[campaign.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
            }
            campaign.loading = false;
            campaign.edit = keep_edit;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
        },
        updateScheduler: async (item, value, not_pause = false, channel = false) => {
            item.loading = true;
            if (channel) {
                if (item.campaign) {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((inner_item) => {
                        if (inner_item.id == item.campaign && inner_item.adgroups) {
                            inner_item.adgroups = inner_item.adgroups.map((inner_inner_item) => {
                                if (inner_inner_item.id == item.id) {
                                    inner_inner_item = item;
                                }
                                return inner_inner_item;
                            });
                        }
                        return inner_item;
                    });
                } else {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((inner_item) => {
                        if (inner_item.id == item.id) {
                            inner_item = item;
                        }
                        return inner_item;
                    });
                }
            }
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            await this.functions.animate("error_" + item.id, { remove: ["h-12", 'mb-4'], add: ["h-0"] });
            try {
                await this.calls.updateScheduler({
                    ...item,
                    value: value,
                    ...(not_pause && { not_pause: true })
                });
                item.budgetPlanerActivated = value;
                item.scheduleActivated = value;
                if (!value) {
                    item.budgetPlanerTotal = 0;
                    if (item.shared_budget) {
                        item.campaigns = item.campaigns.map((item) => {
                            item.scheduleActivated = false;
                            item.budgetPlanerActivated = false;
                            return item;
                        });
                    }
                } else {
                    item.scheduleActivated = true;
                    item.budgetPlanerActivated = true;
                    ["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].map((day) => {
                        if (!Object.keys(item).includes(day)) {
                            item[day] = true;
                        }
                    });
                }
            } catch (error) {
                this.state.errors[item.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
                await this.functions.animate("error_" + item.id, { remove: ["h-0"], add: ["h-12", 'mb-4'] });
            }
            item.loading = false;
            if (!value && !this.state.errors[item.id]) {
                if (!not_pause) {
                    item.status = "paused"
                }
            } else if (value && !this.state.errors[item.id]) {
                item.status = "active";

                //CALCULATE % OF TOTAL
                if (+item.external_daily_budget > 0) {
                    let percentage = 0;
                    let monthly_spend = +item.external_daily_budget * 30;
                    console.log(this.state.order.budgetChannels, channel);
                    if (this.state.order.budgetChannels && this.state.order.budgetChannels[channel] && +this.state.order.budgetChannels[channel].value > 0) {
                        percentage = (+monthly_spend / +this.state.order.budgetChannels[channel].value) * 100;
                    } else {
                        percentage = (+monthly_spend / +this.state.order.maxBudget) * 100;
                    }
                    console.log("percentage", percentage);
                    if (+percentage > 0) {
                        item.budgetPlanerTotal = monthly_spend;
                        item.tempPercentage = percentage;
                        if (item.campaign) {
                            this.functions.updateOrderAdgroupBudgetPlan({ id: item.campaign }, item, channel);
                        } else {
                            this.functions.updateOrderCampaignBudgetPlan(item, channel);
                        }
                    }
                }

            }
            if (channel) {
                if (item.campaign) {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((inner_item) => {
                        if (inner_item.id == item.campaign && inner_item.adgroups) {
                            inner_item.adgroups = inner_item.adgroups.map((inner_inner_item) => {
                                if (inner_inner_item.id == item.id) {
                                    inner_inner_item = item;
                                }
                                return inner_inner_item;
                            });
                        }
                        return inner_item;
                    });
                } else {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((inner_item) => {
                        if (inner_item.id == item.id) {
                            inner_item = item;
                        }
                        return inner_item;
                    });

                }
            }
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
        }
    }

    calls = {
        updateAdgroupSchedule: (data, campaign_id, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdgroupSchedule?id=" + id + "&campaign=" + campaign_id;
            return apiRegister.call(options, url);
        },
        updateCampaignSchedule: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCampaignSchedule?id=" + id;
            return apiRegister.call(options, url);
        },
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        order: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrder?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateExternalBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateExternalBudget?order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        removeBudgetItems: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeOrderBudgetPlanItems?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        getOrderBudgetPlaner: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderBudgetPlaner?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateOrderCampaignBudgetPlan: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrderCampaignBudgetPlan?campaign=" + data.campaign + "&order=" + this.functions.getIdFromPath() + "&name=" + data.name;
            return apiRegister.call(options, url);
        },
        updateOrderAdgroupBudgetPlan: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrderAdgroupBudgetPlan?campaign=" + data.campaign + "&id=" + data.adgroup + "&order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateScheduler: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateScheduler?order=" + this.functions.getIdFromPath() + "&name=" + data.name;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        channelBudget: (channel) => {
            let values = {
                value: 0,
                off_budget: 0
            };
            try {
                if (!this.state.order.budgetChannels) {
                    values = {
                        value: this.renders.channelTotal(channel),
                        off_budget: (this.renders.channelTotal(channel) / this.state.order.maxBudget) * 100
                    }
                } else {
                    if ("budgetChannels" in this.state.updated) {
                        values = this.state.updated.budgetChannels[channel] ? {
                            value: this.state.updated.budgetChannels[channel].value,
                            off_budget: this.state.updated.budgetChannels[channel].off_budget
                        } : { value: 0, off_budget: 0 };
                    } else if (this.state.order.budgetChannels && this.state.order.budgetChannels[channel] && this.state.order.budgetChannels[channel].value && this.state.order.budgetChannels[channel].off_budget) {
                        values = this.state.order.budgetChannels[channel] ? {
                            value: this.state.order.budgetChannels[channel].value,
                            off_budget: this.state.order.budgetChannels[channel].off_budget
                        } : { value: 0, off_budget: 0 };
                    }
                }
            } catch (error) { }
            return values;
        },
        budgetLeft: () => {
            if (this.state.order.maxBudget > 0 && Object.keys(this.state.order.budgetChannels).filter((item) => { return this.state.order.budgetChannels[item] }).length > 0) {
                let left = +this.state.order.maxBudget;
                if (this.state.updated.budgetChannels) {
                    Object.keys(this.state.updated.budgetChannels).filter((item) => { return this.state.updated.budgetChannels[item] }).map((item) => {
                        if (this.state.updated.budgetChannels[item].value) {
                            left -= +this.state.updated.budgetChannels[item].value;
                        }
                    });
                } else {
                    Object.keys(this.state.order.budgetChannels).filter((item) => { return this.state.order.budgetChannels[item] }).map((item) => {
                        if (this.state.order.budgetChannels[item].value) {
                            left -= +this.state.order.budgetChannels[item].value;
                        }
                    });
                }
                if (left === 0) {
                    return {
                        text: "Left ",
                        value: 0,
                        percentage: false
                    };
                } else {
                    return {
                        text: left > 0 ? "Budget left: " : "Over budget: ",
                        value: Math.abs(left),
                        percentage: round((Math.abs(left) / +this.state.order.maxBudget) * 100, 2)
                    }
                }
            } else {
                return {
                    text: "",
                    value: 0,
                    percentage: false
                };
            }
        },
        checkBudget2: (campaign) => {
            let check = false;
            try {
                if (this.state.budget_planer) {
                    for (let channel in this.state.budget_planer) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].map((item) => {
                                if (item.id == campaign.id) {
                                    if ('external_lifetime_budget' in item) {
                                        check = true;
                                    }
                                    if (Array.isArray(item.adgroups)) {
                                        item.adgroups.map((adgroup) => {
                                            if ('external_lifetime_budget' in adgroup) {
                                                check = true;
                                            }
                                        });
                                    }
                                }

                            });
                        }
                    }
                }
            } catch (error) { }
            return check;
        },
        checkBudget: () => {
            let check = {
                contain: false,
                locked: false
            };
            let contain = false;
            try {
                if (this.state.budget_planer) {
                    for (let channel in this.state.budget_planer) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].map((campaign) => {
                                if ('external_lifetime_budget' in campaign) {
                                    check.contain = true;
                                    if (campaign.scheduleActivated) {
                                        check.locked = true;
                                    }
                                }
                                if (Array.isArray(campaign.adgroups)) {
                                    campaign.adgroups.map((adgroup) => {
                                        if ('external_lifetime_budget' in adgroup) {
                                            check.contain = true;
                                            if (adgroup.scheduleActivated) {
                                                check.locked = true;
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }
                }
            } catch (error) { }
            return check;
        },
        calculation: (campaign, adgroup = false) => {
            if (campaign && campaign.scheduleActivated && !campaign.external_lifetime_budget) {
                let daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                let difference = Math.abs(((parseFloat(campaign.external_daily_budget).toFixed(2) - +daily_budget.toFixed(2)) / +daily_budget.toFixed(2)) * 100);
                if (difference > 2) {
                    return true
                } else {
                    return false
                }
            } else if (adgroup && adgroup.scheduleActivated && !adgroup.external_lifetime_budget) {
                let daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                let difference = Math.abs(((parseFloat(adgroup.external_daily_budget).toFixed(2) - +daily_budget.toFixed(2)) / +daily_budget.toFixed(2)) * 100);
                if (difference > 2) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        calculateTotalByDaily: (campaign, daily = false) => {
            let today = moment().startOf('month');
            let spend_total = false;
            let spend_rest = false;
            try {
                if (campaign.scheduleStartDate) {
                    if (moment(campaign.scheduleStartDate).format("MM") == moment().format("MM")) {
                        today = moment(campaign.scheduleStartDate);
                        spend_total = true;
                    }
                } else if (this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.value == "spend_rest") {
                    today = moment().startOf('day');
                    spend_rest = true;
                }
            } catch (error) {
                console.log(error);
            }
            if (!spend_total && !spend_rest && campaign.scheduleMonday && campaign.scheduleTuesday && campaign.scheduleWednesday && campaign.scheduleThursday && campaign.scheduleFriday && campaign.scheduleSaturday && campaign.scheduleSunday) {
                let data = { daily_budget: 0, total_budget: 0, left_over: 0, total: 30, month: today.format('MMMM'), year: today.format('YYYY') };
                if (campaign.external_daily_budget > 0 && !daily) {
                    data.total_budget = Math.round((campaign.external_daily_budget * data.total) * 100) / 100;
                    try {
                        data.total_budget = Math.round(data.total_budget * 10) / 10;
                    } catch (error) { }
                } else if (daily && campaign.budgetPlanerTotal > 0) {
                    data.daily_budget = Math.round((campaign.budgetPlanerTotal / data.total) * 100) / 100;
                    try {
                        data.daily_budget = Math.round(data.daily_budget * 10) / 10;
                    } catch (error) { }
                }
                return data;
            } else {
                let month = today.month();
                let data = { last_day: null, daily_budget: 0, left_over: 0, total: 0, month: today.format('MMMM'), year: today.format('YYYY'), "1": 0, "2": 0, '3': 0, "4": 0, "5": 0, "6": 0, "7": 0 };
                let next = false;
                while (!next) {
                    if (month !== today.month()) {
                        next = true;
                    } else {
                        if (campaign.scheduleMonday && today.isoWeekday() == 1) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleTuesday && today.isoWeekday() == 2) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleWednesday && today.isoWeekday() == 3) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleThursday && today.isoWeekday() == 4) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleFriday && today.isoWeekday() == 5) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleSaturday && today.isoWeekday() == 6) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleSunday && today.isoWeekday() == 7) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        data[today.isoWeekday()] += 1;
                        today.add(1, 'd');
                    }
                }
                if (next) {
                    if (campaign.scheduleMonday) {
                        data.total += data["1"];
                    }
                    if (campaign.scheduleTuesday) {
                        data.total += data["2"];
                    }
                    if (campaign.scheduleWednesday) {
                        data.total += data["3"];
                    }
                    if (campaign.scheduleThursday) {
                        data.total += data["4"];
                    }
                    if (campaign.scheduleFriday) {
                        data.total += data["5"];
                    }
                    if (campaign.scheduleSaturday) {
                        data.total += data["6"];
                    }
                    if (campaign.scheduleSunday) {
                        data.total += data["7"];
                    }
                    if (campaign.external_daily_budget > 0 && !daily) {
                        data.total_budget = Math.round((campaign.external_daily_budget * data.total) * 100) / 100;
                        try {
                            data.total_budget = Math.round(data.total_budget * 10) / 10;
                        } catch (error) { }
                        //data.left_over = campaign.budgetPlanerTotal - (data.daily_budget * data.total);
                    } else if (daily && campaign.budgetPlanerTotal > 0) {
                        let total_budget = campaign.budgetPlanerTotal;
                        if (spend_rest) {
                            total_budget = campaign.budgetPlanerTotal - campaign.monthly_spend;
                        }
                        data.daily_budget = Math.round((total_budget / data.total) * 100) / 100;
                        try {
                            data.daily_budget = Math.round(data.daily_budget * 10) / 10;
                        } catch (error) { }
                    }
                    return data;
                }
            }
        },
        maxBudgetHigher: () => {
            try {
                if (this.state.budget_planer) {
                    let total = 0;
                    if (this.state.order.budgetChannels) {
                        for (let channel in this.state.budget_planer) {
                            total = total + +this.renders.channelBudget(channel).value;
                        }
                    } else {
                        for (let channel in this.state.budget_planer) {
                            if (Array.isArray(this.state.budget_planer[channel])) {
                                this.state.budget_planer[channel].map((campaign) => {
                                    if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                        total += +campaign.budgetPlanerTotal;
                                    }
                                    if (campaign.scheduleActivated && campaign.external_lifetime_budget) {
                                        total += +campaign.external_lifetime_budget;
                                    }
                                    if (Array.isArray(campaign.adgroups)) {
                                        campaign.adgroups.map((adgroup) => {
                                            if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                                total += +adgroup.budgetPlanerTotal;
                                            }
                                            if (!campaign.scheduleActivated && adgroup.scheduleActivated && adgroup.external_lifetime_budget) {
                                                total += +adgroup.external_lifetime_budget;
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    }
                    if (+total < +this.state.order.maxBudget) {
                        return true;
                    }
                }
            } catch (error) {
                return false;
            }
        },
        maxBudgetLower: () => {
            try {
                if (this.state.budget_planer) {
                    let total = 0;
                    if (this.state.order.budgetChannels) {
                        for (let channel in this.state.budget_planer) {
                            total = total + +this.renders.channelBudget(channel).value;
                        }
                    } else {
                        for (let channel in this.state.budget_planer) {
                            if (Array.isArray(this.state.budget_planer[channel])) {
                                this.state.budget_planer[channel].map((campaign) => {
                                    if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                        total += +campaign.budgetPlanerTotal;
                                    }
                                    if (Array.isArray(campaign.adgroups)) {
                                        campaign.adgroups.map((adgroup) => {
                                            if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                                total += +adgroup.budgetPlanerTotal;
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    }
                    if (+total > +this.state.order.maxBudget) {
                        return true;
                    }
                }
            } catch (error) {
                return false;
            }
        },
        percentageOfMaxbudget: (item, channel = false) => {
            try {
                if (this.state.order.budgetChannels && this.state.order.budgetChannels[channel]) {
                    if (+this.state.order.budgetChannels[channel].value > 0 && +item.budgetPlanerTotal) {
                        return ((+item.budgetPlanerTotal / +this.state.order.budgetChannels[channel].value) * 100).toFixed(2);
                    }
                } else {
                    if (+this.state.order.maxBudget > 0 && +item.budgetPlanerTotal) {
                        return ((+item.budgetPlanerTotal / +this.state.order.maxBudget) * 100).toFixed(2);
                    }
                }
            } catch (error) {
                return "-";
            }
        },
        amountBasedOnPercentage: (value, channel = false) => {
            try {
                if (this.state.order.budgetChannels && this.state.order.budgetChannels[channel]) {
                    if (+this.state.order.budgetChannels[channel].value > 0) {
                        return (+this.state.order.budgetChannels[channel].value / 100) * +value;
                    } else {
                        return 0;
                    }
                } else {
                    if (+this.state.order.maxBudget > 0) {
                        return (+this.state.order.maxBudget / 100) * +value;
                    } else {
                        return 0;
                    }
                }
            } catch (error) {
                return 0;
            }
        },
        channelTotal: (channel) => {
            let total = 0;
            try {
                if (this.state.order.budgetChannels) {
                    if (this.state.order.budgetChannels[channel]) {
                        if (this.state.order.budgetChannels[channel].value) {
                            total = this.state.order.budgetChannels[channel].value;
                        }
                    }
                } else {
                    if (this.state.budget_planer) {
                        if (channel in this.state.budget_planer) {
                            if (Array.isArray(this.state.budget_planer[channel])) {
                                this.state.budget_planer[channel].map((campaign) => {
                                    if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                        total += +campaign.budgetPlanerTotal;
                                    }
                                    if (Array.isArray(campaign.adgroups)) {
                                        campaign.adgroups.map((adgroup) => {
                                            if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                                total += +adgroup.budgetPlanerTotal;
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    }
                }
            } catch (error) { }
            return total;
        },
        total_left: (item, channel = false) => {
            try {
                if (this.state.budget_planer) {
                    let total = 0;
                    if (this.state.order.budgetChannels && this.state.order.budgetChannels[channel]) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].filter((campaign) => { return campaign.id !== item.id }).map((campaign) => {
                                if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                    total += +campaign.budgetPlanerTotal;
                                }
                                if (Array.isArray(campaign.adgroups)) {
                                    campaign.adgroups.filter((adgroup) => { return adgroup.id !== item.id }).map((adgroup) => {
                                        if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                            total += +adgroup.budgetPlanerTotal;
                                        }
                                    });
                                }
                            });
                        }
                    } else {
                        for (let channel in this.state.budget_planer) {
                            if (Array.isArray(this.state.budget_planer[channel])) {
                                this.state.budget_planer[channel].filter((campaign) => { return campaign.id !== item.id }).map((campaign) => {
                                    if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                        total += +campaign.budgetPlanerTotal;
                                    }
                                    if (Array.isArray(campaign.adgroups)) {
                                        campaign.adgroups.filter((adgroup) => { return adgroup.id !== item.id }).map((adgroup) => {
                                            if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                                total += +adgroup.budgetPlanerTotal;
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    }

                    if (this.state.order.budgetChannels) {
                        if (+total < +this.state.order.budgetChannels[channel].value) {
                            return +this.state.order.budgetChannels[channel].value - +total;
                        } else {
                            return 0;
                        }
                    } else {
                        if (+total < +this.state.order.maxBudget) {
                            return +this.state.order.maxBudget - +total;
                        } else {
                            return 0;
                        }
                    }
                }
            } catch (error) {
                return 0;
            }
        },
        compare: (campaign) => {
            try {
                let percentage = +campaign.tempPercentage / 100 * 100;

                if (this.renders.percentageOfMaxbudget(campaign, campaign.channel) !== percentage.toFixed(2)) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        compareDiff: (campaign) => {
            try {
                let budget = +campaign.budgetPlanerTotal;
                budget = +budget.toFixed(2);
                let diff = +this.renders.amountBasedOnPercentage(campaign.tempPercentage, campaign.channel).toFixed(2) - budget;
                let buffer = budget * 0.01;
                if (Math.abs(diff) > buffer || Math.abs(diff) > 1) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        channelCheck: (channel) => {
            try {
                let error = false;
                this.state.budget_planer[channel].map((campaign) => {
                    if ((campaign.scheduleActivated || campaign.budgetPlanerActivated) && campaign.budgetPlanerTotal > 0) {
                        if ((+this.renders.amountBasedOnPercentage(campaign.tempPercentage, channel).toFixed(2) != +campaign.budgetPlanerTotal && this.renders.compareDiff(campaign)) || this.renders.compare(campaign)) {
                            error = true;
                        }
                    }
                    if (campaign.adgroups) {
                        campaign.adgroups.map((adgroup) => {
                            if ((adgroup.scheduleActivated || adgroup.budgetPlanerActivated) && adgroup.budgetPlanerTotal > 0) {
                                if ((+this.renders.amountBasedOnPercentage(adgroup.tempPercentage, channel).toFixed(2) != +adgroup.budgetPlanerTotal && this.renders.compareDiff(adgroup)) || this.renders.compare(adgroup)) {
                                    error = true;
                                }
                            }
                        });
                    }
                });
                return error;
            } catch (error) {
                return false;
            }
        },
        monthlyTargetExist: () => {
            try {
                return this.state.order.maxBudget && +this.state.order.maxBudget > 0
            } catch (error) {
                return false;
            }
        },
        channelTargetExist: (channel) => {
            try {
                return this.state.order.budgetChannels && this.state.order.budgetChannels[channel] && +this.state.order.budgetChannels[channel].value > 0
            } catch (error) {
                return false;
            }
        },
        maxBudgetSubAction: () => {
            let string = "Spend pacing";
            if (this.state.order && this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.name) {
                string = this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.name;
            }
            return string;
        },
        addCommas(nStr) {
            nStr += '';
            try {
                if (+nStr % 1 != 0) {
                    nStr = +nStr;
                    nStr = nStr.toFixed(2);
                }
            } catch (error) { }
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? ',' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            let number = x1 + x2;
            return number;
        },
        totalPercentage: () => {
            let total_percentage = 0;
            Object.keys(this.state.budget_planer).map((channel) => {
                total_percentage = total_percentage + +this.renders.channelBudget(channel).off_budget
            });
            if (total_percentage % 1 != 0) {
                try {
                    return total_percentage.toFixed(2);
                } catch (error) {
                    return total_percentage;
                }
            } else {
                return total_percentage;
            }
        },
        channelIsZeroOfTotal: (channel) => {
            let is_zero = false;
            if (this.renders.monthlyTargetExist()) {
                if (+this.renders.channelBudget(channel).value == 0) {
                    is_zero = true;
                }
            }
            return is_zero;
        },
        parseDecimal: (value) => {
            if (+value % 1 != 0) {
                try {
                    value = +value;
                    value = value.toFixed(2);
                } catch (error) { }
            }
            return value;
        },
        itemError: (item) => {
            let channel = item.channel;
            let has_errors = "";
            if (item.scheduleActivated || +item.budgetPlanerTotal > 0) {
                if (this.state.order.maxBudgetEnabled) {
                    let channel_total = this.renders.channelBudget(item.channel);
                    let items_total = 0;
                    if (this.state.budget_planer) {
                        if (channel in this.state.budget_planer) {
                            if (Array.isArray(this.state.budget_planer[channel])) {
                                this.state.budget_planer[channel].map((campaign) => {
                                    if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                        items_total += +campaign.budgetPlanerTotal;
                                    }
                                    if (Array.isArray(campaign.adgroups)) {
                                        campaign.adgroups.map((adgroup) => {
                                            if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                                items_total += +adgroup.budgetPlanerTotal;
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    }
                    if (channel_total.value < items_total) {
                        has_errors = "Lower your budget to match " + (this.state.order.budgetChannels ? "Channel Total" : "Monthly Target");
                    }
                }
                if (this.state.errors[item.id]) {
                    has_errors = has_errors + (has_errors ? ". " : "") + this.state.errors[item.id];
                }
            }
            return has_errors !== "" ? has_errors : false;
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    tempHiddeNEwVersionButton = () => {
        let hidden = true;
        try {
            if (window.location.search.indexOf("show_planer") !== -1) {
                hidden = false;
            }
        } catch (error) { }
        return hidden;
    };

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={async (type) => {

                        }}
                        showDateSpan={false}
                        hideUserDropdown={true}
                        breadcrumb={""}
                        history={this.props.history}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                        onSubtab={(tab) => {
                            this.setState({
                                tab: tab
                            })
                        }}
                        subtab={this.state.tab}
                        show_subtabs={true}
                        subtabs={this.state.tabs}
                        //WHY ????
                        //disableAddNewBudget={!(this.state.order.maxBudget && +this.state.order.maxBudget > 0)}
                        disableAddNewBudget={this.state.loading}
                        createBudgetItem={true}
                        /*
                        budget_management={{
                            id: 1,
                            maxBudgetReached: this.state.order.maxBudgetReached,
                            maxBudget: this.state.order.maxBudget,
                            maxBudgetAction: this.state.order.maxBudgetAction,
                            maxBudgetSubAction: this.state.order.maxBudgetSubAction,
                            crmId: this.state.order.crmId,
                            maxBudgetEnabled: this.state.order.maxBudgetEnabled,
                            totalSpendThisMonth: this.state.order.totalSpendThisMonth,
                            loading: this.state.loading_max_budget,
                            locked: this.renders.checkBudget().locked,
                        }}
                        */
                        onBudgetManagement={async () => {
                            await this.functions.updateMaxBudgetEnabled();
                        }}
                        onNewBudgetItem={async () => {
                            await this.promisedSetState({
                                create_item: true
                            });
                        }}
                    />
                </div>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeBudgetItems();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*CHANNEL BUDGET MODAL WARNING MODAL*/}
                <ChannelBudgetModal
                    open={this.state.budget_updated ? true : false}
                    title={"Update channel budget"}
                    channel={this.state.budget_updated}
                    button={"Save"}
                    budget={this.renders.channelBudget(this.state.budget_updated)}
                    budgetPlanner={this.state.budget_updated ? this.state.budget_planer[this.state.budget_updated] : false}
                    auto={this.state.order.maxBudgetEnabled}
                    onSave={(value) => {
                        //this.functions.updateBudgetChannels()
                    }}
                    onManual={(value) => {
                        this.functions.updateBudgetChannels(true)
                    }}
                    onClose={() => {
                        this.promisedSetState({
                            budget_updated: false,
                        });
                    }}
                />

                {/*PAUSE WARNING MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove_schedule ? true : false}
                    title={"Notification"}
                    description={'Do you want to pause' + (this.state.remove_schedule && this.state.remove_schedule.campaign ? " campaign" : (this.state.remove_schedule && this.state.remove_schedule.shared_budget ? " campaigns belong to shared budget" : " adgroup")) + ' too ?'}
                    cancelButtonText={"No"}
                    submitButtonText={"Yes"}
                    onClose={async (value) => {
                        let buff = this.state.remove_schedule.campaign ? JSON.parse(JSON.stringify(this.state.remove_schedule.campaign)) : JSON.parse(JSON.stringify(this.state.remove_schedule.adgroup));
                        await this.promisedSetState({ remove_schedule: null });
                        this.functions.updateScheduler(buff, false, true, buff.channel);

                    }}
                    onSubmit={async (value) => {
                        let buff = this.state.remove_schedule.campaign ? JSON.parse(JSON.stringify(this.state.remove_schedule.campaign)) : JSON.parse(JSON.stringify(this.state.remove_schedule.adgroup));
                        await this.promisedSetState({ remove_schedule: null });
                        this.functions.updateScheduler(buff, false, false, buff.channel);

                    }}
                />

                {/*CREATE CLIENT*/}
                <SlideoutTailwind
                    openStep={this.state.openStep}
                    steps={this.state.steps}
                    white={true}
                    title={"New budget"}
                    submitButtonText={"Create"}
                    small={true}
                    secondaryButton={false}
                    open={this.state.create_item ? true : false}
                    disabled={(!this.state.only_update_monthly_target && this.state.create_budget_step !== "date") || (this.state.only_update_monthly_target && this.state.create_budget_step !== "budget")}
                    loader={this.state.create_loading}
                    onCancel={() => {
                        this.promisedSetState({
                            create_item: false,
                            only_update_monthly_target: false
                        });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_loading: true
                        });
                        this.refs.CreateBudgetPlanItem.functions.createItem();
                    }}
                >
                    {
                        this.state.create_item &&
                        <CreateBudgetPlanItem
                            ref="CreateBudgetPlanItem"
                            admin={true}
                            order={this.state.updated && Object.keys(this.state.updated).length > 0 ? this.state.updated : this.state.order}
                            onlyUpdateMonthlyTarget={this.state.only_update_monthly_target}
                            crmId={this.state.order.crmId}
                            maxBudget={this.state.order.maxBudget}
                            onStep={async (step) => {
                                await this.promisedSetState({ create_budget_step: step })
                            }}
                            updateSteps={async (steps) => {
                                await this.promisedSetState({ steps: steps })
                            }}
                            updateStep={async (openStep) => {
                                await this.promisedSetState({ openStep: openStep })
                            }}
                            onCreated={async (client) => {
                                await this.promisedSetState({
                                    create_loading: false,
                                    create_item: false,
                                    only_update_monthly_target: false
                                });
                                this.functions.getOrder();
                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/* MAXB BUDGET ---------OLD CODE-------- */}
                {
                    this.state.tab.id == 1 &&
                    !this.state.loading &&
                    <div className="p-4 flex">
                        <div className="bg-white flex-1 rounded-lg shadow relative flex flex-col transition-all duration-1000">
                            {
                                this.state.loading_maxbudget &&
                                <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                    <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                            <div className='flex items-center transition-all duration-1000'>
                                <div className='flex-1 flex items-center justify-start h-16 px-3 border-r border-gray-300'>
                                    <div style={{ bottom: "2px", top: "6px", right: "2px" }} class="ml-1 px-2 z-20 bg-custom-input flex justify-center items-center">
                                        <span
                                            className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                {
                                                    ["bg-green-100 text-green-500"]: !this.state.order.maxBudgetAction || (this.state.order.maxBudgetAction && this.state.order.maxBudgetAction.value !== "paused"),
                                                    ["bg-orange-100 text-orange-500 bg-opacity-50"]: this.state.order.maxBudgetAction && this.state.order.maxBudgetAction.value == "paused"
                                                })
                                            }>
                                            {this.state.order.maxBudgetAction && this.state.order.maxBudgetAction.value == "paused" ? "Paused" : "Active"}
                                        </span>
                                    </div>
                                    {
                                        this.state.order.crmId && this.state.order.crmId !== "" &&
                                        <span style={{ paddingTop: "1px" }} className='ml-1 text-sm font-semibold items-center'>
                                            #{this.state.order.crmId ? this.state.order.crmId : ""}
                                        </span>
                                    }
                                </div>
                                <div id="hide_1" className='items-center justify-center border-r flex flex-1 px-3 border-gray-300 h-16 overflow-hidden transition-all duration-1000 whitespace-no-wrap'>
                                    {
                                        this.state.order.maxBudget &&
                                        <span className=' font-semibold text-sm text-gray-600 flex items-center'>
                                            Monthly Target
                                        </span>
                                    }
                                    {
                                        this.state.order.maxBudget &&
                                        <span className='ml-4 text-sm whitespace-no-wrap font-semibold items-center'>
                                            {this.renders.addCommas(this.state.order.maxBudget) + " " + this.state.currencies.join(", ")}
                                        </span>
                                    }
                                    {
                                        !this.state.order.maxBudget &&
                                        <span className='ml-4 text-sm whitespace-no-wrap font-semibold items-center'>
                                            No monthly target
                                        </span>
                                    }
                                </div>
                                <div className='items-center justify-center flex flex-1 px-3 h-16 overflow-hidden transition-all duration-1000 whitespace-no-wrap border-gray-300'>
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div id="budget_management" className="flex transition-all duration-1000 items-center relative">
                                            <div className='flex relative items-center justify-center mt-3 mb-3 border-gray-300 w-full rounded-md' style={{ paddingTop: "0.3rem", paddingBottom: "0.4rem" }}>
                                                {
                                                    this.state.loading_max_budget &&
                                                    <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                        <div style={{ borderTopColor: "transparent" }} className="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div className="flex ml-5 text-sm font-semibold items-center">
                                                    Budget control
                                                </div>
                                                <div className={"relative overflow-hidden mr-5 ml-4"}>
                                                    <SwitchTailwind
                                                        value={this.state.order.maxBudgetEnabled}
                                                        onSwitch={async () => {
                                                            if (!this.state.loading_max_budget) {
                                                                this.functions.updateMaxBudgetEnabled();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.order.maxBudgetReached &&
                                    <div className="border-l px-4 relative flex items-center">
                                        {
                                            this.state.loading_unblock &&
                                            <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                <div style={{ borderTopColor: "transparent" }} className="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center items-center">
                                            <ExclamationIcon className="w-5 mr-2 text-red-500" />
                                            <div className="text-sm">
                                                Max budget reached
                                            </div>
                                        </div>
                                        {
                                            //MATIN ASKED TO KEEP UNBLOCK REACH MAX BUDGET BUTTON
                                            false &&
                                            <div className="ml-2">
                                                <div onClick={() => {
                                                    if (!this.state.loading_unblock) {
                                                        this.functions.unblockMaxBudgetReached();
                                                    }
                                                }} className="text-sm cursor-pointer text-red-500 underline">
                                                    Unblock and Continue
                                                </div>
                                                {
                                                    false &&
                                                    <SwitchTailwind
                                                        color={"bg-red-500"}
                                                        value={this.state.order.maxBudgetReached}
                                                        onSwitch={async () => {
                                                            //this.state.order.maxBudgetReached
                                                            /*
                                                            this.setState({
                                                                allow_update: !this.state.allow_update
                                                            })
                                                            */
                                                        }}
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            {
                                this.state.order.maxBudget && +this.state.order.maxBudget > 0 &&
                                <div className={'border-t flex flex-col flex-1 items-center justify-center border-gray-300 overflow-hidden transition-all duration-1000 min-h-16'}>
                                    <div className="w-full flex items-center py-4 px-4">
                                        <div id="hide_3" className={"overflow-hidden transition-all duration-1000 whitespace-no-wrap text-sm font-medium " + (this.state.order.maxBudgetEnabled ? "w-40" : "w-0")}>
                                            {this.renders.maxBudgetSubAction()}
                                        </div>
                                        <div className="ml-4 mr-4 h-3 w-full bg-gray-100 overflow-hidden flex flex-row rounded-md relative">
                                            <div style={{ width: ((+this.state.order.totalSpendThisMonth / +this.state.order.maxBudget) * 100) + "%" }} className="bg-purple-500 rounded-l-md h-full transform ease-in-out duration-200"></div>
                                            <div className="flex flex-1 h-full bg-gray-100 rounded-r-md"></div>
                                        </div>
                                        {
                                            +this.state.order.totalSpendThisMonth > 0 &&
                                            <div className="text-sm whitespace-no-wrap font-semibold">{this.renders.addCommas((this.state.order.totalSpendThisMonth ? +this.state.order.totalSpendThisMonth : 0))} {this.state.currencies.join(", ")}</div>
                                        }
                                        {/*+ "/" + this.renders.addCommas(this.state.order.maxBudget)} {this.state.currencies.join(", ") */}
                                        {
                                            +this.state.order.totalSpendThisMonth > 0 && +this.state.order.maxBudget > 0 &&
                                            <div className="ml-1 text-sm font-semibold">({((+this.state.order.totalSpendThisMonth / +this.state.order.maxBudget) * 100).toFixed(2)}%)</div>
                                            ||
                                            <div className="ml-1 text-sm font-semibold">(0%)</div>
                                        }
                                    </div>
                                    {
                                        false &&
                                        <div className="w-full px-4">
                                            <div className="flex w-full">
                                                {
                                                    this.state.budget_planer && typeof this.state.budget_planer == "object" && this.state.budget_planer !== null &&
                                                    Object.keys(this.state.budget_planer).filter((channel) => {
                                                        return this.renders.channelBudget(channel).value > 0;
                                                    }).map((channel, index) => {
                                                        return (
                                                            <div style={{ width: this.renders.channelBudget(channel).off_budget + "%" }} className="h-3 bg-gray-100 flex flex-row rounded-md overflow-hidden">
                                                                <div style={{ width: ((+this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] / +this.renders.channelTotal(channel)) * 100) + "%" }} className={"bg-" + channel.toLowerCase() + "-500 h-full transform ease-in-out duration-200"}></div>
                                                                <div className="flex flex-1 h-full bg-gray-100"></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {
                                !(this.state.order.maxBudget && +this.state.order.maxBudget > 0) &&
                                <div className='w-full border-t px-4'>
                                    <div className="p-4 text-blue-500 rounded-md font-medium text-sm w-full justify-center flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-blue-500 mr-1" />
                                        <span className="font-semibold mr-1">OBS!</span>Start with adding a Monthly target
                                        <div className="mx-2">
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    create_item: true,
                                                    only_update_monthly_target: true
                                                });
                                            }} className="cursor-pointer px-2 py-1 bg-blue-500 rounded-md text-white  hover:bg-blue-600 font-medium">
                                                Click here
                                            </div>
                                        </div> to get started.
                                    </div>
                                </div>
                            }
                            {
                                !this.tempHiddeNEwVersionButton() &&
                                !this.state.order.budgetChannels &&
                                <div className='w-full border-t px-4'>
                                    <div className="p-4 text-blue-500 rounded-md font-medium text-sm w-full justify-center flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-blue-500 mr-1" />
                                        <span className="font-semibold mr-1">OBS!</span>Order budget management is on old version.
                                        <div className="mx-2">
                                            <div onClick={() => {
                                                this.functions.updateToBudgetChannels();
                                            }} className="cursor-pointer px-2 py-1 bg-blue-500 rounded-md text-white  hover:bg-blue-600 font-medium">
                                                Click here
                                            </div>
                                        </div> to update to new version with channel budget.
                                    </div>
                                </div>
                            }
                            {
                                /*
                                  this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.value == "spend_total" &&
                                  <div className='w-full border-t px-4'>
                                      <div className="p-4 text-blue-500 rounded-md font-medium text-sm inline-flex items-center">
                                          <ExclamationIcon className="h-4 w-4 text-blue-500 mr-1" />
                                          OBS! <b className='mx-1'>Spend total</b> will insure that daily budget is total / days left in this month when total budget is updated.
                                      </div>
                                  </div>
                                  */
                            }
                            {
                                /*
                                this.renders.checkBudget().contain && false &&
                                <div className='w-full border-t px-4'>
                                    <div className="text-orange-500 items-center inline-flex rounded-md p-4 font-medium text-sm">
                                        <ExclamationIcon className="h-4 w-4 text-orange-500 mr-1" />
                                        Warning: Automatic mode will be disabled if Campaigns or Adgroups without a<b className='mx-1'>Daily budget</b>are activated with budget control.
                                    </div>
                                </div>
                                */
                            }
                            {
                                //!this.state.order.budgetChannels &&
                                this.renders.maxBudgetHigher() &&
                                this.state.order.maxBudgetEnabled &&
                                <div className='w-full border-t'>
                                    <div className="p-4 text-red-500 rounded-md font-medium text-sm inline-flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                        OBS! The <span className='mx-1 font-semibold'>Monthly order budget</span> is higher than sum of all total budgets.
                                    </div>
                                </div>
                            }
                            {
                                //!this.state.order.budgetChannels &&
                                this.renders.maxBudgetLower() &&
                                this.state.order.maxBudgetEnabled &&
                                <div className='w-full border-t'>
                                    <div className="p-4  text-red-500 rounded-md  font-medium text-sm inline-flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                        OBS! The <span className='mx-1 font-semibold'>Monthly order budget</span> is lower than sum of all total budgets.
                                    </div>
                                </div>
                            }
                            {
                                this.state.error && this.state.error_message &&
                                <div className='w-full border-t'>
                                    <div className="p-4 text-red-500 rounded-md  font-medium text-sm inline-flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                        {this.state.error_message}
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            this.state.budget_planer && typeof this.state.budget_planer == "object" && this.state.budget_planer !== null &&
                            this.renders.monthlyTargetExist() &&
                            <div className={((+this.renders.totalPercentage() > 100 || (this.state.order.maxBudgetEnabled && +this.renders.totalPercentage() < 100)) ? "bg-red-100 border-1.5 border-red-500" : "bg-white") + " rounded-lg shadow relative flex transition-all min-h-40 duration-1000 ml-4 pt-5.5 p-6 h-full"}>
                                <div className="flex flex-1 flex-col">
                                    <div className='font-semibold mb-2 text-sm flex items-center'>
                                        Channel budgets
                                    </div>
                                    <div>
                                        {
                                            this.state.budget_planer && typeof this.state.budget_planer == "object" && this.state.budget_planer !== null &&
                                            Object.keys(this.state.budget_planer).sort((a, b) => {
                                                return this.renders.channelBudget(b).value - this.renders.channelBudget(a).value;
                                            }).map((channel, index) => {
                                                return (
                                                    <div className={(this.renders.channelBudget(channel).value < 1 ? "opacity-50" : "") + " flex mt-1 flex-1 text-xs font-medium capitalize"}>
                                                        <div className={"bg-" + (channel) + "-500" + " h-4 w-4 rounded-full mr-2"}></div>
                                                        {channel}
                                                        {
                                                            +this.renders.channelBudget(channel).value > 0 &&
                                                            <span className="ml-1 text-gray-600">{this.renders.parseDecimal(this.renders.channelBudget(channel).off_budget)}%</span>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="flex w-48 flex-1 relative">
                                    {
                                        (+this.renders.totalPercentage() > 100 || (this.state.order.maxBudgetEnabled && +this.renders.totalPercentage() < 100)) &&
                                        <div style={{ top: "-13px", right: "-13px" }} className="absolute">
                                            <ExclamationCircleIcon className="w-6 text-red-500"></ExclamationCircleIcon>
                                        </div>
                                    }
                                    <div className="w-full h-full absolute min">
                                        <Doughnut
                                            width={null}
                                            height={null}
                                            responsive={false}
                                            options={{
                                                animation: false,
                                                legend: {
                                                    display: false
                                                },
                                                layout: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 0
                                                    }
                                                },
                                                maintainAspectRatio: false,
                                                tooltips: {
                                                    enabled: true
                                                },
                                                cutoutPercentage: 65
                                            }}
                                            plugins={{
                                                id: 'myPlugin',
                                                beforeDraw: (chart) => {
                                                    const ctx = chart.ctx;
                                                    const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
                                                    const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
                                                    ctx.save();
                                                    ctx.font = "15px Poppins";
                                                    if ((+this.renders.totalPercentage() > 100 || (this.state.order.maxBudgetEnabled && +this.renders.totalPercentage() < 100))) {
                                                        ctx.fillStyle = '#FF4D54';
                                                    } else {
                                                        ctx.fillStyle = '#333333';
                                                    }
                                                    ctx.textAlign = 'center';
                                                    ctx.textBaseline = 'middle';
                                                    ctx.fillText(`${this.renders.totalPercentage()}%`, xCoor, yCoor);
                                                    ctx.restore();
                                                },
                                            }}
                                            data={{
                                                labels: Object.keys(this.state.budget_planer),
                                                datasets: [
                                                    {
                                                        label: 'Channels',
                                                        data: Object.keys(this.state.budget_planer).map((channel) => {
                                                            return this.renders.channelBudget(channel).value
                                                        }),
                                                        borderRadius: 10,
                                                        backgroundColor: Object.keys(this.state.budget_planer).map((channel) => {
                                                            let colors = {
                                                                facebook: "#3A5998",
                                                                google: "#4384F4",
                                                                linkedin: "#0D65A1",
                                                                tiktok: "#000000",
                                                                twitter: "#50ABF1",
                                                                snapchat: "#FFFC00",
                                                                bing: "#0C8484"
                                                            };
                                                            return colors[channel];
                                                        })
                                                    }
                                                ]
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

                {/* TEST GRID */}
                {
                    this.state.tab.id == 1 &&
                    !this.state.loading_planer &&
                    <div className="px-4 mb-4">
                        <div className="w-full bg-white shadow rounded-lg">
                            {
                                this.state.budget_planer && typeof this.state.budget_planer == "object" && this.state.budget_planer !== null &&
                                Object.keys(this.state.budget_planer).map((channel, index) => {
                                    /*
                                    .sort((a, b) => {
                                        return this.renders.channelBudget(b).value - this.renders.channelBudget(a).value;
                                    })
                                    */
                                    return (
                                        <Fragment>
                                            <div className={(index > 0 ? "border-t" : "") + ""}>
                                                <div className={(this.renders.channelIsZeroOfTotal(channel) ? "opacity-50" : "") + " flex flex-row w-full p-4"}>
                                                    <div className={"flex flex-1"}>
                                                        <div className={"bg-" + (channel) + "-500" + " h-10 w-10 rounded-md flex justify-center items-center mr-3"}>
                                                            {
                                                                channel === 'google' &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/google_icon.png')} />
                                                            }
                                                            {
                                                                channel === 'facebook' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                            }
                                                            {
                                                                channel === 'linkedin' &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                                            }
                                                            {
                                                                channel === 'twitter' &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/twitter_icon.svg')} />
                                                            }
                                                            {
                                                                channel === 'tiktok' &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/tiktok_icon.png')} />
                                                            }
                                                            {
                                                                channel === 'snapchat' &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/snapchat.svg')} />
                                                            }
                                                            {
                                                                channel === 'bing' &&
                                                                <img className="w-3"
                                                                    alt=""
                                                                    src={require('../assets/images/bing-logo.png')} />
                                                            }
                                                        </div>
                                                        <div className="flex flex-1 flex-col">
                                                            <div className="text-sm capitalize justify-center flex-col items-center">
                                                                <div className="">
                                                                    <div className="font-semibold">{channel}</div>
                                                                </div>
                                                                <div className="flex">
                                                                    <div className="font-medium text-gray-600">
                                                                        {(this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] ? this.renders.addCommas(+this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()]) : 0)} {this.state.budget_planer[channel][0].currency ? this.state.budget_planer[channel][0].currency : ""} <span className="text-gray-600">/</span> {this.renders.addCommas(this.renders.channelTotal(channel))} {this.state.budget_planer[channel][0].currency ? this.state.budget_planer[channel][0].currency : ""}
                                                                        {
                                                                            ((+this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] / +this.renders.channelTotal(channel)) * 100) > 0 &&
                                                                            <span className="ml-1">({((+this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] / +this.renders.channelTotal(channel)) * 100).toFixed(2)}%)</span>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        /*
                                                                        + this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] > 0 &&
                                                                        +this.renders.channelTotal(channel) > 0 &&
                                                                        <div className="ml-1">({((+this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] / +this.renders.channelTotal(channel)) * 100).toFixed(2)}%)</div>
                                                                        ||
                                                                        <div className="ml-1">(0%)</div>
                                                                        */
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                false &&
                                                                <div className={'flex items-center text-sm font-medium transition-all duration-1000 overflow-hidden flex-1'}>
                                                                    {
                                                                        typeof channel == "string" &&
                                                                        this.state.order.totalSpendThisMonthPerChannel && this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] &&
                                                                        <>
                                                                            <div className="h-2 flex-1 bg-gray-100 flex flex-row rounded-md overflow-hidden">
                                                                                <div style={{ width: ((+this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] / +this.renders.channelTotal(channel)) * 100) + "%" }} className={"bg-" + channel.toLowerCase() + "-500 h-full transform ease-in-out duration-200"}></div>
                                                                                <div className="flex flex-1 h-full bg-gray-100"></div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            false &&
                                                            <div className="h-10 w-10 mr-3" style={{
                                                                borderRadius: "50%",
                                                                border: "1px solid",
                                                                backgroundImage: "linear-gradient(18deg, white 50%, transparent 50%), linear-gradient(-90deg, red 50%, transparent 50%)"
                                                            }}></div>
                                                        }
                                                    </div>
                                                    <div onClick={() => {
                                                        this.state.open_channel[channel] = !this.state.open_channel[channel];
                                                        this.setState({
                                                            open_channel: this.state.open_channel
                                                        });
                                                    }} className={(this.state.open_channel[channel] ? "bg-red-100 text-red-500" : "bg-purple-100 text-purple-500") + "  cursor-pointer h-10 w-10 ml-4 flex justify-center items-center rounded-md"}>
                                                        {
                                                            !this.state.open_channel[channel] &&
                                                            <PlusIcon className="w-5" />
                                                        }
                                                        {
                                                            this.state.open_channel[channel] &&
                                                            <MinusIcon className="w-5" />
                                                        }
                                                    </div>
                                                </div>
                                                <SlideDown
                                                    closed={!this.state.open_channel[channel]}
                                                >
                                                    <div className="border-t p-4">
                                                        {
                                                            this.renders.monthlyTargetExist() &&
                                                            this.state.order.budgetChannels &&
                                                            <div className="grid grid-cols-12 gap-4 rounded-md w-full">
                                                                <div className={"col-span-2 transition-all duration-1000 relative"}>
                                                                    {
                                                                        this.state.loading_channels[channel] &&
                                                                        <div className="h-full absolute z-51 bg-white opacity-50 pt-5 flex justify-center items-center flex-col flex-1 w-full">
                                                                            <div style={{ borderTopColor: "transparent" }} className="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                    <InputTailwind
                                                                        value={this.renders.channelBudget(channel).value}
                                                                        label={"Channel total"}
                                                                        leftSection={true}
                                                                        leftSectionLabel={this.state.budget_planer[channel][0].currency ? this.state.budget_planer[channel][0].currency : ""}
                                                                        type={"number"}
                                                                        onBlur={async () => {
                                                                            if (this.state.updated && this.state.updated.budgetChannels && this.state.updated.budgetChannels[channel]) {
                                                                                if (this.state.updated.budgetChannels[channel].value != this.state.order.budgetChannels[channel].value) {
                                                                                    if (this.state.order.maxBudgetEnabled) {
                                                                                        this.functions.updateBudgetChannels(false, channel);
                                                                                    } else {
                                                                                        this.functions.updateBudgetChannels(false, channel, true);
                                                                                        this.state.update_channel_items[channel] = true;
                                                                                        this.setState({
                                                                                            update_channel_items: this.state.update_channel_items
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }
                                                                        }}
                                                                        onChange={async (value) => {
                                                                            if (+value < 0) {
                                                                                value = 0
                                                                            };
                                                                            if (!this.state.updated.budgetChannels) {
                                                                                this.state.updated.budgetChannels = JSON.parse(JSON.stringify(this.state.order.budgetChannels));
                                                                            }
                                                                            if (!this.state.updated.budgetChannels[channel]) {
                                                                                this.state.updated.budgetChannels[channel] = {
                                                                                    value: 0,
                                                                                    off_budget: 0
                                                                                };
                                                                            }
                                                                            let buffer = JSON.parse(JSON.stringify(this.state.updated.budgetChannels[channel]));
                                                                            this.state.updated.budgetChannels[channel].value = value;
                                                                            this.state.updated.budgetChannels[channel].off_budget = round((value / +this.state.order.maxBudget) * 100, 2);
                                                                            await this.promisedSetState({
                                                                                updated: this.state.updated
                                                                            });
                                                                            if (this.renders.budgetLeft().text.includes("Over") && this.state.order.maxBudgetEnabled) {
                                                                                this.state.updated.budgetChannels[channel] = buffer;
                                                                                await this.promisedSetState({
                                                                                    updated: this.state.updated
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className={"col-span-2 transition-all duration-1000 relative"}>
                                                                    {
                                                                        this.state.loading_channels[channel] &&
                                                                        <div className="h-full absolute z-51 bg-white opacity-50 pt-5 flex justify-center items-center flex-col flex-1 w-full">
                                                                            <div style={{ borderTopColor: "transparent" }} className="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                    <InputTailwind
                                                                        value={this.renders.channelBudget(channel).off_budget}
                                                                        label={"% of Monthly target"}
                                                                        error={false}
                                                                        rightSection={true}
                                                                        type={"number"}
                                                                        rightSectionLabel={"%"}
                                                                        onBlur={async () => {
                                                                            if (this.state.updated && this.state.updated.budgetChannels && this.state.updated.budgetChannels[channel]) {
                                                                                if (this.state.updated.budgetChannels[channel].value != this.state.order.budgetChannels[channel].value) {
                                                                                    if (this.state.order.maxBudgetEnabled) {
                                                                                        this.functions.updateBudgetChannels(false, channel);
                                                                                    } else {
                                                                                        this.functions.updateBudgetChannels(false, channel, true);
                                                                                        this.state.update_channel_items[channel] = true;
                                                                                        this.setState({
                                                                                            update_channel_items: this.state.update_channel_items
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }
                                                                        }}
                                                                        onChange={async (value) => {
                                                                            try {
                                                                                if (value < 0) value = 0;
                                                                                if (!this.state.updated.budgetChannels) {
                                                                                    this.state.updated.budgetChannels = JSON.parse(JSON.stringify(this.state.order.budgetChannels));
                                                                                }
                                                                                if (!this.state.updated.budgetChannels[channel]) {
                                                                                    this.state.updated.budgetChannels[channel] = {
                                                                                        value: 0,
                                                                                        off_budget: 0
                                                                                    };
                                                                                }
                                                                                let buffer = JSON.parse(JSON.stringify(this.state.updated.budgetChannels[channel]));
                                                                                this.state.updated.budgetChannels[channel].off_budget = value;
                                                                                this.state.updated.budgetChannels[channel].value = round((value / 100) * +this.state.order.maxBudget, 2);
                                                                                await this.promisedSetState({
                                                                                    updated: this.state.updated
                                                                                });
                                                                                if (this.renders.budgetLeft().text.includes("Over") && this.state.order.maxBudgetEnabled) {
                                                                                    this.state.updated.budgetChannels[channel] = buffer;
                                                                                    await this.promisedSetState({
                                                                                        updated: this.state.updated
                                                                                    })
                                                                                }
                                                                            } catch (error) { }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {
                                                                    this.state.update_channel_items[channel] &&
                                                                    <div className={"col-span-8 flex"}>
                                                                        <div className="flex flex-1"></div>
                                                                        <div className={"h-12 px-4 mt-5.5 rounded-md inline-flex whitespace-no-wrap items-center justify-end bg-orange-100 text-orange-500 text-sm font-medium transition-all duration-1000"}>
                                                                            Update all items to match new channel total
                                                                            <span onClick={() => {
                                                                                delete this.state.update_channel_items[channel];
                                                                                this.setState({
                                                                                    update_channel_items: this.state.update_channel_items
                                                                                });
                                                                                this.functions.updateBudgetChannels(false, channel);
                                                                            }} className="underline ml-4 cursor-pointer hover:text-orange-700">Click here to update</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            <div className={(this.renders.monthlyTargetExist() && this.state.order.budgetChannels ? "mt-4" : "") + " bg-gray-50 border overflow-hidden gap-4 rounded-md w-full"}>
                                                                <div className="w-full text-sm font-medium p-4 flex border-b">
                                                                    <div className="flex flex-1">Campaign/Adgroup targets</div>
                                                                    <div className="flex mr-12">
                                                                        <div className="w-40 mr-4">
                                                                            <div className="text-sm font-medium">Daily/Total budget</div>
                                                                        </div>
                                                                        <div className="w-40 mr-4">
                                                                            <div className="text-sm font-medium">Monthly budget</div>
                                                                        </div>
                                                                        <div className="w-40 mr-1">
                                                                            <div className="text-sm font-medium">{this.state.order.budgetChannels && this.state.order.budgetChannels[channel] ? "% of Channel total" : "% of Order budget"}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.budget_planer[channel].filter((campaign) => {
                                                                        if (campaign.shared_budget) {
                                                                            if (campaign.campaigns.length < 1 && !campaign.scheduleActivated) {
                                                                                return false;
                                                                            } else {
                                                                                return true;
                                                                            }
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }).map((campaign, index) => {
                                                                        return (
                                                                            <div className={(index > 0 ? "border-t" : "") + " grid-cols-12 grid w-full relative"}>
                                                                                {
                                                                                    campaign.loading &&
                                                                                    <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                                                        <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    <Fragment>
                                                                                        <div id={"info_" + campaign.id} className={"col-span-12 transition-all duration-1000 overflow-hidden"}>
                                                                                            <div className={(campaign.edit && !campaign.shared_budget && !("no_budget" in campaign) ? "py-1.5" : "py-3") + " grid-cols-12 pl-4 grid gap-2 relative"}>
                                                                                                <div className="col-span-12 flex flex-row items-center w-full">
                                                                                                    <div className="flex items-center">
                                                                                                        <SwitchTailwind
                                                                                                            value={campaign.scheduleActivated}
                                                                                                            onSwitch={async () => {
                                                                                                                if (campaign.scheduleActivated) {
                                                                                                                    await this.promisedSetState({
                                                                                                                        remove_schedule: {
                                                                                                                            ...(campaign.shared_budget && { shared_budget: true }),
                                                                                                                            campaign: campaign,
                                                                                                                        }
                                                                                                                    });
                                                                                                                } else {
                                                                                                                    this.functions.updateScheduler(campaign, !campaign.scheduleActivated, false, channel);
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className={(campaign.scheduleActivated ? "w-56" : "opacity-50") + " mx-2 truncate transition-all duration-1000 pr-1 ml-4"}>

                                                                                                        <div data-tip='' data-for={campaign.id + "_name"} key={campaign.id + "_name"} className='text-sm w-full font-medium truncate'>
                                                                                                            <div className="truncate w-full">{campaign.name}</div>
                                                                                                            {
                                                                                                                campaign.scheduleActivated &&
                                                                                                                <ReactTooltip effect='solid' id={campaign.id + "_name"}>
                                                                                                                    {campaign.name}
                                                                                                                </ReactTooltip>
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className='flex font-medium text-xs'>
                                                                                                            {
                                                                                                                this.renders.checkBudget2(campaign) &&
                                                                                                                <div data-tip='' data-for={campaign.id + "_check_budget"} key={campaign.id + "_check_budget"} className=''>
                                                                                                                    <ExclamationIcon className="h-4 w-4 text-orange-500 mr-1" />
                                                                                                                    <ReactTooltip effect='solid' id={campaign.id + "_check_budget"}>
                                                                                                                        Warning: Automatic mode will be disabled if Campaigns or Adgroups without a<b className='mx-1'>Daily budget</b>are activated with budget control.
                                                                                                                    </ReactTooltip>
                                                                                                                </div>

                                                                                                            }
                                                                                                            {campaign.shared_budget ? "Shared budget • " : "Campaign • "}
                                                                                                            {
                                                                                                                campaign.shared_budget && Array.isArray(campaign.campaigns) &&
                                                                                                                <span className="text-gray-600 ml-1">{campaign.campaigns.length + " campaigns"}</span>
                                                                                                            }
                                                                                                            {
                                                                                                                "status" in campaign &&
                                                                                                                <span className={cn("ml-1 uppercase",
                                                                                                                    {
                                                                                                                        [" text-green-500"]: campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enable" || campaign.status.toLowerCase() === "enabled" || campaign.status.toLowerCase() === "scheduled",
                                                                                                                        [" text-purple-500"]: campaign.status.toLowerCase() === "upcoming" || campaign.status.toLowerCase() === "syncing",
                                                                                                                        [" text-gray-500"]: (campaign.status.toLowerCase() === "archived" || campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "done" || campaign.status.toLowerCase() === "balance_exceed"),
                                                                                                                        [" text-gray-500"]: (campaign.status.toLowerCase() === "expired" || campaign.status.toLowerCase() === "completed" || campaign.status.toLowerCase() === "time_done"),
                                                                                                                        [" text-orange-500 bg-opacity-50"]: campaign.status.toLowerCase() === "paused" || campaign.status.toLowerCase() === "disable" || campaign.status.toLowerCase() === 'campaign_disable' || campaign.status.toLowerCase() === 'adgroup_disable',
                                                                                                                        [" text-gray-500"]: campaign.status.toLowerCase() === "draft" || campaign.status.toLowerCase() === "pending",
                                                                                                                        [" text-red-600"]: campaign.status.toLowerCase() === "error" || campaign.status.toLowerCase() === "removed"
                                                                                                                    })
                                                                                                                }>{campaign.status}
                                                                                                                </span>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        campaign.scheduleActivated &&
                                                                                                        <div className={(campaign.edit ? "" : "cursor-not-allowed") + " flex flex-1 flex-row mr-2"}>
                                                                                                            {
                                                                                                                ["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].map((day) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div className="flex flex-col items-center justify-center mr-2">
                                                                                                                                <div className={(campaign.edit ? "" : "text-gray-600") + " text-xs font-medium mb-1"}>
                                                                                                                                    {
                                                                                                                                        day.replace("schedule", "").slice(0, 2)
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                <div onClick={async () => {
                                                                                                                                    if (campaign.edit) {
                                                                                                                                        this.functions.days(day, campaign, channel, "campaign", false);
                                                                                                                                    }
                                                                                                                                }} className={(campaign.scheduleActivated && campaign[day] ? (campaign.edit ? "border-purple-500 bg-purple-500 cursor-pointer" : "border-gray-300 bg-gray-300") : (campaign.edit ? "border-purple-100 cursor-pointer" : "border-gray-300")) + " w-5 h-5 border-1.5 rounded-md flex items-center justify-center "}>
                                                                                                                                    {
                                                                                                                                        campaign.scheduleActivated && campaign[day] &&
                                                                                                                                        <CheckIcon className="w-4 text-white"></CheckIcon>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        <div className="flex flex-1"></div>
                                                                                                    }
                                                                                                    {
                                                                                                        /*
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        this.renders.calculation(campaign) &&
                                                                                                        !("no_budget" in campaign) &&
                                                                                                        <div id={"error_budget_" + campaign.id} className={"bg-red-100 mb-3 text-red-500 col-span-12 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden inline-flex h-12 mx-3"}>
                                                                                                            <div className='p-4 inline-flex items-center'><ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />Daily budget and total budget is not matching due to manual edits to budget. <span onClick={async () => {
                                                                                                                this.functions.updateOrderCampaignBudgetPlan(campaign, channel, true);
                                                                                                            }} className="text-sm cursor-pointer underline ml-1">
                                                                                                                Click to fix
                                                                                                            </span></div>
                                                                                                        </div>
                                                                                                        */
                                                                                                    }
                                                                                                    {
                                                                                                        /*
                                                                                                      <div id={"error_" + campaign.id} className={"bg-red-100 col-span-12 text-red-500 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex mx-3" + (this.state.errors[campaign.id] ? " h-12" : " h-0")}>
                                                                                                          <span className='p-4 inline-flex items-center'>
                                                                                                              <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                                                                                              {this.state.errors[campaign.id] ? this.state.errors[campaign.id] : ""}
                                                                                                          </span>
                                                                                                      </div>
                                                                                                      */
                                                                                                    }
                                                                                                    {
                                                                                                        /*
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        <div id={"max_error_" + campaign.id} className={"bg-red-100 col-span-12 text-red-500 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex mx-3 h-0"}>
                                                                                                            <span className='p-4 inline-flex items-center'>
                                                                                                                <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                                                                                                {"Warning: Budget exceeded - the new budget you are trying to allocate exceeds the available " + (this.state.order.budgetChannels && this.state.order.budgetChannels[channel] ? " Channel" : " Order") + " Monthly Target of " + (this.state.order.budgetChannels && this.state.order.budgetChannels[channel] ? this.state.order.budgetChannels[channel].value : this.state.order.maxBudget) + ". Your remaining Monthly Budget is " + +this.renders.total_left(campaign, channel).toFixed(2) + " or " + ((+this.renders.total_left(campaign, channel).toFixed(2) / (this.state.order.budgetChannels && this.state.order.budgetChannels[channel] ? +this.state.order.budgetChannels[channel].value : +this.state.order.maxBudget)) * 100).toFixed(2) + " percentage. Please revise and try again."}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        */
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.errors[campaign.id] &&
                                                                                                        <div className="mr-4">
                                                                                                            <div data-tip='' data-for={campaign.id + "_warning"} key={campaign.id + "_warning"} className="w-9 h-9 border border-red-500 bg-red-100 rounded-md flex justify-center items-center">
                                                                                                                <ExclamationCircleIcon className="w-5 text-red-500"></ExclamationCircleIcon>
                                                                                                            </div>
                                                                                                            <ReactTooltip effect='solid' id={campaign.id + "_warning"}>
                                                                                                                {this.state.errors[campaign.id]}
                                                                                                            </ReactTooltip>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        //SHOW IF DAILY BUDGET EXIST ON CAMPAIGN LEVEL (DAILY)
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        !('external_lifetime_budget' in campaign) && campaign.scheduleActivated &&
                                                                                                        !("no_budget" in campaign) &&
                                                                                                        campaign.edit &&
                                                                                                        <div className={"mr-4 w-40 transition-all duration-1000" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                            <InputTailwind
                                                                                                                value={campaign.external_daily_budget}
                                                                                                                //label={"Daily budget"}
                                                                                                                white={true}
                                                                                                                //error={this.renders.calculation(campaign) ? true : false}
                                                                                                                locked={this.state.order.maxBudgetEnabled || !campaign.scheduleActivated ? true : false}
                                                                                                                readOnly={this.state.order.maxBudgetEnabled || !campaign.scheduleActivated ? true : false}
                                                                                                                leftSection={campaign.currency ? true : false}
                                                                                                                leftSectionLabel={campaign.currency ? campaign.currency : ""}
                                                                                                                type={"number"}
                                                                                                                onBlur={async () => {
                                                                                                                    await this.promisedSetState({
                                                                                                                        budget_planer: this.state.budget_planer
                                                                                                                    });
                                                                                                                    if (campaign.budgetPlanerTotal) {
                                                                                                                        this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                                    }
                                                                                                                }}
                                                                                                                onChange={async (value) => {
                                                                                                                    if (value < 0) {
                                                                                                                        value = 0;
                                                                                                                    }
                                                                                                                    campaign.external_daily_budget = value;
                                                                                                                    campaign.budgetPlanerTotal = this.renders.calculateTotalByDaily(campaign, false).total_budget;
                                                                                                                    campaign.tempPercentage = this.renders.percentageOfMaxbudget(campaign, channel);
                                                                                                                    await this.promisedSetState({
                                                                                                                        budget_planer: this.state.budget_planer
                                                                                                                    });
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        //SHOW IF DAILY BUDGET EXIST ON CAMPAIGN LEVEL (MONTHLY TARGET)
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        !('external_lifetime_budget' in campaign) && campaign.scheduleActivated &&
                                                                                                        !("no_budget" in campaign) &&
                                                                                                        campaign.edit &&
                                                                                                        <div className={"mr-4 w-40 transition-all duration-1000" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                            <InputTailwind
                                                                                                                //label={"Monthly budget"}
                                                                                                                //error={((this.state.order.maxBudgetEnabled && +this.renders.total_left(campaign, channel).toFixed(2) < campaign.budgetPlanerTotal)) ? true : false}
                                                                                                                white={true}
                                                                                                                onBlur={async () => {
                                                                                                                    if (campaign.budgetPlanerTotal) {
                                                                                                                        if (this.state.order.maxBudgetEnabled) {
                                                                                                                            if (+this.renders.total_left(campaign, channel).toFixed(2) < campaign.budgetPlanerTotal) {

                                                                                                                            } else {
                                                                                                                                this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                                                await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12', 'mb-4'], add: ['h-0'] });
                                                                                                                            }
                                                                                                                        } else {
                                                                                                                            this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                                        }
                                                                                                                    }
                                                                                                                }}
                                                                                                                type={"number"}
                                                                                                                locked={campaign.error || !campaign.scheduleActivated}
                                                                                                                value={campaign.scheduleActivated ? campaign.budgetPlanerTotal : "-"}
                                                                                                                readOnly={campaign.error || !campaign.scheduleActivated}
                                                                                                                leftSection={campaign.currency ? true : false}
                                                                                                                leftSectionLabel={campaign.currency ? campaign.currency : ""}
                                                                                                                onChange={async (value) => {
                                                                                                                    if (value < 0) {
                                                                                                                        value = 0;
                                                                                                                    }
                                                                                                                    if (this.state.order.maxBudgetEnabled) {
                                                                                                                        if (+this.renders.total_left(campaign, channel).toFixed(2) < value) {
                                                                                                                            this.functions.maxBudgetReached(campaign);
                                                                                                                        } else {
                                                                                                                            await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12', 'mb-4'], add: ['h-0'] });
                                                                                                                        }
                                                                                                                    }
                                                                                                                    campaign.budgetPlanerTotal = value;
                                                                                                                    campaign.external_daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                                                                                                                    campaign.tempPercentage = this.renders.percentageOfMaxbudget(campaign, channel);
                                                                                                                    this.setState({
                                                                                                                        budget_planer: this.state.budget_planer
                                                                                                                    });
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        //SHOW IF DAILY BUDGET EXIST ON CAMPAIGN LEVEL (PERCENTAGE OF TOTAL)
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        !('external_lifetime_budget' in campaign) && campaign.scheduleActivated &&
                                                                                                        !("no_budget" in campaign) &&
                                                                                                        campaign.edit &&
                                                                                                        <div className={"w-40 transition-all duration-1000" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                            <InputTailwind
                                                                                                                //label={this.state.order.budgetChannels && this.state.order.budgetChannels[channel] ? "% of Channel total" : "% of Order budget"}
                                                                                                                //error={((this.state.order.maxBudgetEnabled && +this.renders.total_left(campaign, channel).toFixed(2) < campaign.budgetPlanerTotal)) ? true : false}
                                                                                                                value={campaign.scheduleActivated ? ("tempPercentage" in campaign ? campaign.tempPercentage : this.renders.percentageOfMaxbudget(campaign, channel)) : "-"}
                                                                                                                rightSection={campaign.scheduleActivated ? true : false}
                                                                                                                rightSectionLabel={"%"}
                                                                                                                type={"number"}
                                                                                                                white={true}
                                                                                                                readOnly={campaign.error || !campaign.scheduleActivated}
                                                                                                                locked={campaign.error || !campaign.scheduleActivated}
                                                                                                                onFocus={() => {
                                                                                                                    if (this.state.order.budgetChannels && this.state.order.budgetChannels[channel]) {

                                                                                                                    } else {
                                                                                                                        campaign.tempPercentage = this.renders.percentageOfMaxbudget(campaign, channel);
                                                                                                                    }
                                                                                                                    this.setState({
                                                                                                                        budget_planer: this.state.budget_planer
                                                                                                                    })
                                                                                                                }}
                                                                                                                onBlur={async () => {
                                                                                                                    if (this.state.order.maxBudgetEnabled) {
                                                                                                                        if (+this.renders.total_left(campaign, channel).toFixed(2) < campaign.budgetPlanerTotal) {

                                                                                                                        } else {
                                                                                                                            await this.promisedSetState({
                                                                                                                                budget_planer: this.state.budget_planer
                                                                                                                            });
                                                                                                                            if (campaign.budgetPlanerTotal) {
                                                                                                                                this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                                            }
                                                                                                                            await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12', 'mb-4'], add: ['h-0'] });
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        await this.promisedSetState({
                                                                                                                            budget_planer: this.state.budget_planer
                                                                                                                        });
                                                                                                                        if (campaign.budgetPlanerTotal) {
                                                                                                                            this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                                        }
                                                                                                                    }
                                                                                                                }}
                                                                                                                onChange={async (value) => {
                                                                                                                    if (value < 0) {
                                                                                                                        value = 0;
                                                                                                                    }
                                                                                                                    if (this.state.order.maxBudgetEnabled) {
                                                                                                                        if (+this.renders.total_left(campaign, channel).toFixed(2) < +this.renders.amountBasedOnPercentage(value, channel).toFixed(2)) {
                                                                                                                            this.functions.maxBudgetReached(campaign);
                                                                                                                        } else {
                                                                                                                            await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12', 'mb-4'], add: ['h-0'] });
                                                                                                                        }
                                                                                                                    }
                                                                                                                    campaign.tempPercentage = value;
                                                                                                                    campaign.budgetPlanerTotal = this.renders.amountBasedOnPercentage(value, channel).toFixed(2);
                                                                                                                    campaign.external_daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                                                                                                                    await this.promisedSetState({
                                                                                                                        budget_planer: this.state.budget_planer
                                                                                                                    })
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        //SHOW IF LIFETIME BUDGET EXIST ON CAMPAIGN LEVEL (LIFETIME BUDDGET)
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        'external_lifetime_budget' in campaign && campaign.scheduleActivated &&
                                                                                                        !("no_budget" in campaign) &&
                                                                                                        campaign.edit &&
                                                                                                        <div className={"mr-4 w-40 transition-all duration-1000" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                            <InputTailwind
                                                                                                                //label={"Lifetime budget"}
                                                                                                                onBlur={() => {
                                                                                                                    this.functions.updateExternalBudget(campaign, channel, "campaign")
                                                                                                                }}
                                                                                                                type={"number"}
                                                                                                                white={true}
                                                                                                                locked={campaign.error || !campaign.scheduleActivated}
                                                                                                                value={campaign.scheduleActivated ? campaign.external_lifetime_budget : "-"}
                                                                                                                readOnly={campaign.error || !campaign.scheduleActivated}
                                                                                                                leftSection={campaign.currency ? true : false}
                                                                                                                leftSectionLabel={campaign.currency ? campaign.currency : ""}
                                                                                                                onChange={async (value) => {
                                                                                                                    if (+value < 0) {
                                                                                                                        value = 0;
                                                                                                                    }
                                                                                                                    campaign.external_lifetime_budget = value;
                                                                                                                    await this.promisedSetState({
                                                                                                                        budget_planer: this.state.budget_planer
                                                                                                                    });
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        (!campaign.edit || ("no_budget" in campaign)) &&
                                                                                                        campaign.scheduleActivated &&
                                                                                                        <div className="flex">
                                                                                                            <div className="w-40 mr-4">
                                                                                                                {
                                                                                                                    !('external_lifetime_budget' in campaign) && !("no_budget" in campaign) &&
                                                                                                                    <div className="text-sm  font-medium">{campaign.external_daily_budget ? this.renders.addCommas(campaign.external_daily_budget) : "0"} {campaign.currency ? campaign.currency : ""}</div>
                                                                                                                }
                                                                                                                {
                                                                                                                    ('external_lifetime_budget' in campaign) && !("no_budget" in campaign) &&
                                                                                                                    <div className="text-sm  font-medium">{campaign.external_lifetime_budget ? this.renders.addCommas(campaign.external_lifetime_budget) : "0"} {campaign.currency ? campaign.currency : ""}</div>
                                                                                                                }
                                                                                                                {
                                                                                                                    ("no_budget" in campaign) &&
                                                                                                                    <div className="text-sm  font-medium">No budget</div>
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="w-40 mr-4">
                                                                                                                {
                                                                                                                    !('external_lifetime_budget' in campaign) && !("no_budget" in campaign) &&
                                                                                                                    <div className="text-sm font-medium">{campaign.scheduleActivated ? this.renders.addCommas(campaign.budgetPlanerTotal) : ""} {campaign.currency ? campaign.currency : ""}</div>
                                                                                                                }
                                                                                                                {
                                                                                                                    ("no_budget" in campaign) &&
                                                                                                                    <div className="text-sm font-medium">No budget</div>
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="w-40">
                                                                                                                {
                                                                                                                    !('external_lifetime_budget' in campaign) && !("no_budget" in campaign) &&
                                                                                                                    <div className="text-sm font-medium">{campaign.scheduleActivated ? ("tempPercentage" in campaign ? campaign.tempPercentage : this.renders.percentageOfMaxbudget(campaign, channel)) : ""}%</div>
                                                                                                                }
                                                                                                                {
                                                                                                                    ("no_budget" in campaign) &&
                                                                                                                    <div className="text-sm font-medium">No budget</div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        (campaign.scheduleActivated || campaign.shared_budget) &&
                                                                                                        <div className={"flex mr-4 ml-4"}>
                                                                                                            <div onClick={async () => {
                                                                                                                campaign.edit = !campaign.edit;
                                                                                                                await this.promisedSetState({
                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                });
                                                                                                            }} className={(campaign.edit ? "bg-red-100 text-red-500" : "bg-purple-100 text-purple-500") + " w-9 h-9 cursor-pointer rounded-md items-center justify-center flex "}>
                                                                                                                {
                                                                                                                    !campaign.edit &&
                                                                                                                    <PencilAltIcon className="w-5" />
                                                                                                                }
                                                                                                                {
                                                                                                                    campaign.edit &&
                                                                                                                    <XIcon className="w-5" />
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                campaign.error &&
                                                                                                <div className='w-full mt-4'>
                                                                                                    <div className="bg-red-100 text-red-500 rounded-md p-4 font-medium text-sm mx-3">
                                                                                                        {campaign.message}
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            false &&
                                                                                            campaign.shared_budget &&
                                                                                            <div onClick={async () => {
                                                                                                campaign.show_related = !campaign.show_related;
                                                                                                await this.promisedSetState({
                                                                                                    budget_planer: this.state.budget_planer
                                                                                                })
                                                                                                if (campaign.show_related) {
                                                                                                    await this.functions.animate("campaigns_info_" + campaign.id, {}, { height: (campaign.campaigns.length * 67) + "px" });
                                                                                                } else {
                                                                                                    await this.functions.animate("campaigns_info_" + campaign.id, {}, { height: "0px" });
                                                                                                }
                                                                                            }} className={(campaign.show_related ? "border-b" : "") + " bg-gray-50 col-span-12 flex-row flex justify-center cursor-pointer border-t items-center"}>
                                                                                                {
                                                                                                    !campaign.show_related &&
                                                                                                    <div className="text-sm font-medium items-center hover:underline flex py-2">
                                                                                                        Show related campaigns
                                                                                                        <ChevronDownIcon className="h-4 ml-1 w-4" />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    campaign.show_related &&
                                                                                                    <div className="flex font-medium items-center hover:underline text-sm py-2">
                                                                                                        Hide related campaigns
                                                                                                        <ChevronUpIcon className="h-4 ml-1 w-4" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            /*########## SHARED BUDGETS  #############*/
                                                                                        }
                                                                                        {
                                                                                            Array.isArray(campaign.campaigns) &&
                                                                                            campaign.campaigns.length > 0 &&
                                                                                            <>
                                                                                                <div id={"campaigns_info_" + campaign.id} className={'col-span-12 bg-white bg-opacity-50 grid grid-cols-12 relative transition-all duration-1000 overflow-hidden'} style={{
                                                                                                    //height: (campaign.show_related ? (campaign.campaigns.length * 67) + "px" : "0px")
                                                                                                    height: (campaign.edit ? (campaign.campaigns.length * 67) + "px" : "0px")
                                                                                                }}>
                                                                                                    {
                                                                                                        (Array.isArray(campaign.campaigns) ? campaign.campaigns : []).map((campaign, index) => {
                                                                                                            return (
                                                                                                                <div className={"border-t col-span-12 relative"}>
                                                                                                                    {
                                                                                                                        campaign.loading &&
                                                                                                                        <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                                                                                            <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className="w-full h-full flex items-center">
                                                                                                                        <div className='mx-4 self-center'>
                                                                                                                            <ReplyIcon className='h-5 w-5' style={{ transform: 'rotate(180deg)' }} />
                                                                                                                        </div>
                                                                                                                        <div className="flex items-center">
                                                                                                                            <SwitchTailwind
                                                                                                                                value={campaign.scheduleActivated}
                                                                                                                                onSwitch={async () => {
                                                                                                                                    this.functions.updateScheduler(campaign, !campaign.scheduleActivated, false, channel);
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            <div className={(campaign.scheduleActivated ? "" : "opacity-50") + " mx-2 truncate transition-all duration-1000 pr-1 ml-4"}>
                                                                                                                                <div data-tip='' data-for={campaign.id + "_name"} key={campaign.id + "_name"} className='text-sm w-full font-medium truncate'>
                                                                                                                                    <div className="truncate w-full">{campaign.name}</div>
                                                                                                                                </div>
                                                                                                                                <div className={'flex font-medium text-xs'}>{campaign.shared_budget ? "Shared budget" : "Campaign • "}
                                                                                                                                    {
                                                                                                                                        "status" in campaign &&
                                                                                                                                        <span className={cn("ml-1 uppercase",
                                                                                                                                            {
                                                                                                                                                [" text-green-500"]: campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enable" || campaign.status.toLowerCase() === "enabled" || campaign.status.toLowerCase() === "scheduled",
                                                                                                                                                [" text-purple-500"]: campaign.status.toLowerCase() === "upcoming" || campaign.status.toLowerCase() === "syncing",
                                                                                                                                                [" text-gray-500"]: (campaign.status.toLowerCase() === "archived" || campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "done" || campaign.status.toLowerCase() === "balance_exceed"),
                                                                                                                                                [" text-gray-500"]: (campaign.status.toLowerCase() === "expired" || campaign.status.toLowerCase() === "completed" || campaign.status.toLowerCase() === "time_done"),
                                                                                                                                                [" text-orange-500 bg-opacity-50"]: campaign.status.toLowerCase() === "paused" || campaign.status.toLowerCase() === "disable" || campaign.status.toLowerCase() === 'campaign_disable' || campaign.status.toLowerCase() === 'adgroup_disable',
                                                                                                                                                [" text-gray-500"]: campaign.status.toLowerCase() === "draft" || campaign.status.toLowerCase() === "pending",
                                                                                                                                                [" text-red-600"]: campaign.status.toLowerCase() === "error" || campaign.status.toLowerCase() === "removed"
                                                                                                                                            })
                                                                                                                                        }>{campaign.status}
                                                                                                                                        </span>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        <div className="flex flex-1"></div>
                                                                                                                        {
                                                                                                                            campaign.scheduleActivated &&
                                                                                                                            <div className="flex mr-16">
                                                                                                                                <div className="w-40 mr-4">
                                                                                                                                    -
                                                                                                                                </div>
                                                                                                                                <div className="w-40 mr-4">
                                                                                                                                    -
                                                                                                                                </div>
                                                                                                                                <div className="w-40 mr-1">
                                                                                                                                    -
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            false &&
                                                                                                                            <div className="flex items-center flex-1 pr-4 justify-end text-xs text-purple-500">
                                                                                                                                <div className="ml-1">{(campaign.monthly_spend ? +campaign.monthly_spend.toFixed(2) : "-")}</div>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                                {
                                                                                    Array.isArray(campaign.campaigns) &&
                                                                                    campaign.campaigns.length > 0 &&
                                                                                    campaign.show_related && false &&
                                                                                    <div className={(campaign.show_related ? "border-b" : "") + "  flex flex-row col-span-12 font-medium px-4 py-2 text-xs h-"}>
                                                                                        Campaigns belongs to this <span className="ml-1 font-semibold">shared budget</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    !((Array.isArray(campaign.campaigns) && campaign.campaigns.length > 0) || (Array.isArray(campaign.adgroups) && campaign.adgroups.length > 0)) && false &&
                                                                                    <div className="w-full h-4"></div>
                                                                                }
                                                                                {
                                                                                    (Array.isArray(campaign.adgroups) ? campaign.adgroups : []).filter((item) => {
                                                                                        return !campaign.shared_budget
                                                                                    }).map((adgroup, index) => {
                                                                                        return (
                                                                                            <div className={"border-t col-span-12 relative"}>
                                                                                                {
                                                                                                    adgroup.loading &&
                                                                                                    <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                                                                        <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                                <div className="grid-cols-12 grid">
                                                                                                    <div id={"info_" + adgroup.id} className={"col-span-12 transition-all duration-1000 overflow-hidden"}>
                                                                                                        <div className={(adgroup.edit ? "py-1.5" : "py-3") + " grid-cols-12 pr-4 grid gap-2 relative"}>
                                                                                                            <div className="col-span-12 flex flex-row items-center w-full">
                                                                                                                <div className='mx-4 self-center'>
                                                                                                                    <ReplyIcon className='h-5 w-5' style={{ transform: 'rotate(180deg)' }} />
                                                                                                                </div>
                                                                                                                <div className="flex items-center">
                                                                                                                    <SwitchTailwind
                                                                                                                        value={adgroup.scheduleActivated}
                                                                                                                        //disabled={'external_lifetime_budget' in adgroup ? true : false}
                                                                                                                        onSwitch={async () => {
                                                                                                                            if (adgroup.scheduleActivated) {
                                                                                                                                await this.promisedSetState({
                                                                                                                                    remove_schedule: {
                                                                                                                                        adgroup: adgroup,
                                                                                                                                    }
                                                                                                                                });
                                                                                                                            } else {
                                                                                                                                this.functions.updateScheduler(adgroup, !adgroup.scheduleActivated, false, channel);
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className={(adgroup.scheduleActivated ? "w-56" : "opacity-50") + " mx-2 truncate transition-all duration-1000 pr-1 ml-4"}>
                                                                                                                    <div data-tip='' data-for={adgroup.id + "_name"} key={adgroup.id + "_name"} className='text-sm w-full font-medium truncate'>
                                                                                                                        <div className="truncate w-full">{adgroup.name}</div>
                                                                                                                        {
                                                                                                                            adgroup.scheduleActivated &&
                                                                                                                            <ReactTooltip effect='solid' id={adgroup.id + "_name"}>
                                                                                                                                {adgroup.name}
                                                                                                                            </ReactTooltip>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className='flex text-xs'>Adgroup &#x2022;
                                                                                                                        <span className={cn("ml-1 uppercase",
                                                                                                                            {
                                                                                                                                [" text-green-500"]: adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enable" || adgroup.status.toLowerCase() === "enabled" || adgroup.status.toLowerCase() === "scheduled",
                                                                                                                                [" text-purple-500"]: adgroup.status.toLowerCase() === "upcoming" || adgroup.status.toLowerCase() === "syncing",
                                                                                                                                [" text-gray-500"]: (adgroup.status.toLowerCase() === "archived" || adgroup.status.toLowerCase() === "ended" || adgroup.status.toLowerCase() === "done" || adgroup.status.toLowerCase() === "balance_exceed"),
                                                                                                                                ["  text-gray-500"]: (adgroup.status.toLowerCase() === "expired" || adgroup.status.toLowerCase() === "completed" || adgroup.status.toLowerCase() === "time_done"),
                                                                                                                                [" text-orange-500 bg-opacity-50"]: adgroup.status.toLowerCase() === "paused" || adgroup.status.toLowerCase() === "disable" || adgroup.status.toLowerCase() === 'campaign_disable' || adgroup.status.toLowerCase() === 'adgroup_disable',
                                                                                                                                [" text-gray-500"]: adgroup.status.toLowerCase() === "draft" || adgroup.status.toLowerCase() === "pending",
                                                                                                                                [" text-red-600"]: adgroup.status.toLowerCase() === "error" || adgroup.status.toLowerCase() === "removed"
                                                                                                                            })
                                                                                                                        }>{adgroup.status}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    adgroup.scheduleActivated && !campaign.shared_budget &&
                                                                                                                    <div className={(adgroup.edit ? "" : "cursor-not-allowed") + " flex flex-1 flex-row mr-2"}>
                                                                                                                        {
                                                                                                                            ["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].map((day) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <div className="flex flex-col items-center justify-center mr-2">
                                                                                                                                            <div className={(adgroup.edit ? "" : "text-gray-600") + " text-xs font-medium mb-1"}>
                                                                                                                                                {
                                                                                                                                                    day.replace("schedule", "").slice(0, 2)
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                            <div onClick={async () => {
                                                                                                                                                if (adgroup.edit) {
                                                                                                                                                    this.functions.days(day, campaign, channel, "adgroup", adgroup);
                                                                                                                                                }
                                                                                                                                            }} className={(adgroup.scheduleActivated && adgroup[day] ? (adgroup.edit ? "border-purple-500 bg-purple-500 cursor-pointer" : "border-gray-300 bg-gray-300") : (adgroup.edit ? "border-purple-100 cursor-pointer" : "border-gray-300")) + " w-5 h-5 border-1.5 rounded-md flex items-center justify-center "}>
                                                                                                                                                {
                                                                                                                                                    adgroup.scheduleActivated && adgroup[day] &&
                                                                                                                                                    <CheckIcon className="w-4 text-white"></CheckIcon>
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    <div className="flex flex-1"></div>
                                                                                                                }
                                                                                                                {
                                                                                                                    this.renders.itemError(adgroup) &&
                                                                                                                    <div className="mr-4">
                                                                                                                        <div data-tip='' data-for={adgroup.id + "_warning"} key={adgroup.id + "_warning"} className="w-9 h-9 border border-red-500 bg-red-100 rounded-md flex justify-center items-center">
                                                                                                                            <ExclamationCircleIcon className="w-5 text-red-500"></ExclamationCircleIcon>
                                                                                                                        </div>
                                                                                                                        <ReactTooltip effect='solid' id={adgroup.id + "_warning"}>
                                                                                                                            {this.renders.itemError(adgroup)}
                                                                                                                        </ReactTooltip>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    this.renders.monthlyTargetExist() &&
                                                                                                                    adgroup.scheduleActivated &&
                                                                                                                    !campaign.shared_budget &&
                                                                                                                    !('external_lifetime_budget' in adgroup) &&
                                                                                                                    !("no_budget" in adgroup) &&
                                                                                                                    adgroup.edit &&
                                                                                                                    <div className={"mr-4 w-40 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                                        <InputTailwind
                                                                                                                            value={adgroup.external_daily_budget}
                                                                                                                            //error={this.renders.calculation(adgroup) ? true : false}
                                                                                                                            locked={this.state.order.maxBudgetEnabled || !adgroup.scheduleActivated ? true : false}
                                                                                                                            readOnly={this.state.order.maxBudgetEnabled || !adgroup.scheduleActivated ? true : false}
                                                                                                                            leftSection={adgroup.currency ? true : false}
                                                                                                                            type={"number"}
                                                                                                                            white={true}
                                                                                                                            leftSectionLabel={adgroup.currency ? adgroup.currency : ""}
                                                                                                                            onBlur={async () => {
                                                                                                                                await this.promisedSetState({
                                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                                });
                                                                                                                                if (adgroup.budgetPlanerTotal) {
                                                                                                                                    this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            onChange={async (value) => {
                                                                                                                                if (value < 0) {
                                                                                                                                    value = 0;
                                                                                                                                }
                                                                                                                                adgroup.external_daily_budget = value;
                                                                                                                                adgroup.budgetPlanerTotal = this.renders.calculateTotalByDaily(adgroup, false).total_budget;
                                                                                                                                adgroup.tempPercentage = this.renders.percentageOfMaxbudget(adgroup, channel);
                                                                                                                                await this.promisedSetState({
                                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                                });
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    this.renders.monthlyTargetExist() &&
                                                                                                                    adgroup.scheduleActivated &&
                                                                                                                    !campaign.shared_budget &&
                                                                                                                    !('external_lifetime_budget' in adgroup) &&
                                                                                                                    !("no_budget" in adgroup) &&
                                                                                                                    adgroup.edit &&
                                                                                                                    <div className={"mr-4 w-40 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                                        <InputTailwind
                                                                                                                            //error={((this.state.order.maxBudgetEnabled && +this.renders.total_left(adgroup, channel).toFixed(2) < adgroup.budgetPlanerTotal)) ? true : false}
                                                                                                                            onBlur={async () => {
                                                                                                                                if (adgroup.budgetPlanerTotal) {
                                                                                                                                    if (this.state.order.maxBudgetEnabled) {
                                                                                                                                        if (+this.renders.total_left(adgroup, channel).toFixed(2) < adgroup.budgetPlanerTotal) {

                                                                                                                                        } else {
                                                                                                                                            this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                                                            await this.functions.animate("max_error_" + adgroup.id, { remove: ['h-12', 'mb-4'], add: ['h-0'] });
                                                                                                                                        }
                                                                                                                                    } else {
                                                                                                                                        this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            type={"number"}
                                                                                                                            white={true}
                                                                                                                            locked={adgroup.error || !adgroup.scheduleActivated}
                                                                                                                            value={adgroup.scheduleActivated ? adgroup.budgetPlanerTotal : "-"}
                                                                                                                            readOnly={adgroup.error || !adgroup.scheduleActivated}
                                                                                                                            leftSection={adgroup.currency ? true : false}
                                                                                                                            leftSectionLabel={adgroup.currency ? adgroup.currency : ""}
                                                                                                                            onChange={async (value) => {
                                                                                                                                if (value < 0) {
                                                                                                                                    value = 0;
                                                                                                                                }
                                                                                                                                if (this.state.order.maxBudgetEnabled) {
                                                                                                                                    if (+this.renders.total_left(adgroup, channel).toFixed(2) < value) {
                                                                                                                                        this.functions.maxBudgetReached(adgroup);
                                                                                                                                    } else {
                                                                                                                                        await this.functions.animate("max_error_" + adgroup.id, { remove: ['h-12', 'mb-4'], add: ['h-0'] });
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                adgroup.budgetPlanerTotal = value;
                                                                                                                                adgroup.external_daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                                                                                                                                adgroup.tempPercentage = this.renders.percentageOfMaxbudget(adgroup, channel);
                                                                                                                                await this.promisedSetState({
                                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                                });
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    this.renders.monthlyTargetExist() &&
                                                                                                                    adgroup.scheduleActivated &&
                                                                                                                    !campaign.shared_budget &&
                                                                                                                    !('external_lifetime_budget' in adgroup) &&
                                                                                                                    !("no_budget" in adgroup) &&
                                                                                                                    adgroup.edit &&
                                                                                                                    <div className={"w-40 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                                        <InputTailwind
                                                                                                                            //error={((this.state.order.maxBudgetEnabled && +this.renders.total_left(adgroup, channel).toFixed(2) < adgroup.budgetPlanerTotal)) ? true : false}
                                                                                                                            value={adgroup.scheduleActivated ? ("tempPercentage" in adgroup ? adgroup.tempPercentage : this.renders.percentageOfMaxbudget(adgroup, channel)) : "-"}
                                                                                                                            rightSection={adgroup.scheduleActivated ? true : false}
                                                                                                                            rightSectionLabel={"%"}
                                                                                                                            type={"number"}
                                                                                                                            white={true}
                                                                                                                            readOnly={adgroup.error || !adgroup.scheduleActivated}
                                                                                                                            locked={adgroup.error || !adgroup.scheduleActivated}
                                                                                                                            onFocus={() => {
                                                                                                                                if (this.state.order.budgetChannels && this.state.order.budgetChannels[channel]) {

                                                                                                                                } else {
                                                                                                                                    adgroup.tempPercentage = this.renders.percentageOfMaxbudget(adgroup, channel);
                                                                                                                                }
                                                                                                                                this.setState({
                                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                                })
                                                                                                                            }}
                                                                                                                            onBlur={async () => {
                                                                                                                                if (this.state.order.maxBudgetEnabled) {
                                                                                                                                    if (+this.renders.total_left(adgroup, channel).toFixed(2) < adgroup.budgetPlanerTotal) {

                                                                                                                                    } else {
                                                                                                                                        await this.promisedSetState({
                                                                                                                                            budget_planer: this.state.budget_planer
                                                                                                                                        });
                                                                                                                                        if (adgroup.budgetPlanerTotal) {
                                                                                                                                            this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                                                        }
                                                                                                                                        await this.functions.animate("max_error_" + adgroup.id, { remove: ['h-12', 'mb-4'], add: ['h-0'] });
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    await this.promisedSetState({
                                                                                                                                        budget_planer: this.state.budget_planer
                                                                                                                                    });
                                                                                                                                    if (adgroup.budgetPlanerTotal) {
                                                                                                                                        this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            onChange={async (value) => {
                                                                                                                                if (value < 0) {
                                                                                                                                    value = 0;
                                                                                                                                }
                                                                                                                                if (this.state.order.maxBudgetEnabled) {
                                                                                                                                    if (+this.renders.total_left(adgroup, channel).toFixed(2) < this.renders.amountBasedOnPercentage(value, channel)) {
                                                                                                                                        this.functions.maxBudgetReached(adgroup);
                                                                                                                                    } else {
                                                                                                                                        await this.functions.animate("max_error_" + adgroup.id, { remove: ['h-12', 'mb-4'], add: ['h-0'] });
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                adgroup.tempPercentage = value;
                                                                                                                                adgroup.budgetPlanerTotal = this.renders.amountBasedOnPercentage(value, channel).toFixed(2);
                                                                                                                                adgroup.external_daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                                })
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    //DAILY FOR LINKEDIN ADGROUP
                                                                                                                    this.renders.monthlyTargetExist() &&
                                                                                                                    adgroup.scheduleActivated &&
                                                                                                                    !campaign.shared_budget &&
                                                                                                                    adgroup.channel === "linkedin" &&
                                                                                                                    'external_lifetime_budget' in adgroup &&
                                                                                                                    !("no_budget" in adgroup) &&
                                                                                                                    !("no_daily" in adgroup) &&
                                                                                                                    adgroup.edit &&
                                                                                                                    <div className={"mr-4 w-40 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                                        <InputTailwind
                                                                                                                            value={adgroup.external_daily_budget}
                                                                                                                            //error={this.renders.calculation(adgroup) ? true : false}
                                                                                                                            leftSection={adgroup.currency ? true : false}
                                                                                                                            leftSectionLabel={adgroup.currency ? adgroup.currency : ""}
                                                                                                                            onBlur={async () => {
                                                                                                                                this.functions.updateExternalBudget(adgroup, channel, "adgroup", true);
                                                                                                                            }}
                                                                                                                            white={true}
                                                                                                                            onChange={async (value) => {
                                                                                                                                adgroup.external_daily_budget = value;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                                });
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    //LIFE TIME BUDGET ADGROUP
                                                                                                                    this.renders.monthlyTargetExist() &&
                                                                                                                    adgroup.scheduleActivated &&
                                                                                                                    !campaign.shared_budget &&
                                                                                                                    'external_lifetime_budget' in adgroup &&
                                                                                                                    !("no_budget" in adgroup) &&
                                                                                                                    adgroup.edit &&
                                                                                                                    <div className={"mr-4 w-40 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                                        <InputTailwind
                                                                                                                            onBlur={() => {
                                                                                                                                this.functions.updateExternalBudget(adgroup, channel, "adgroup");
                                                                                                                            }}
                                                                                                                            white={true}
                                                                                                                            locked={adgroup.error || !adgroup.scheduleActivated}
                                                                                                                            value={adgroup.scheduleActivated ? adgroup.external_lifetime_budget : "-"}
                                                                                                                            readOnly={adgroup.error || !adgroup.scheduleActivated}
                                                                                                                            leftSection={adgroup.currency ? true : false}
                                                                                                                            leftSectionLabel={adgroup.currency ? adgroup.currency : ""}
                                                                                                                            type={"number"}
                                                                                                                            onChange={async (value) => {
                                                                                                                                if (value < 0) {
                                                                                                                                    value = 0;
                                                                                                                                }
                                                                                                                                adgroup.external_lifetime_budget = value;
                                                                                                                                await this.promisedSetState({
                                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                                });
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    !adgroup.edit &&
                                                                                                                    !campaign.shared_budget &&
                                                                                                                    adgroup.scheduleActivated &&
                                                                                                                    <div className="flex">
                                                                                                                        <div className="w-40 mr-4">
                                                                                                                            {
                                                                                                                                !('external_lifetime_budget' in adgroup) && !("no_budget" in adgroup) &&
                                                                                                                                <div className="text-sm  font-medium">{adgroup.external_daily_budget ? this.renders.addCommas(adgroup.external_daily_budget) : "0"} {adgroup.currency ? adgroup.currency : ""}</div>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                ('external_lifetime_budget' in adgroup) && !("no_budget" in adgroup) &&
                                                                                                                                <div className="text-sm  font-medium">{adgroup.external_lifetime_budget ? this.renders.addCommas(adgroup.external_lifetime_budget) : "0"} {adgroup.currency ? adgroup.currency : ""}</div>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                ("no_budget" in adgroup) &&
                                                                                                                                <div className="text-sm  font-medium">No budget</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="w-40 mr-4">
                                                                                                                            {
                                                                                                                                !('external_lifetime_budget' in adgroup) && !("no_budget" in adgroup) &&
                                                                                                                                <div className="text-sm  font-medium">{adgroup.scheduleActivated ? this.renders.addCommas(adgroup.budgetPlanerTotal) : ""} {adgroup.currency ? adgroup.currency : ""}</div>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                ("no_budget" in adgroup) &&
                                                                                                                                <div className="text-sm  font-medium">No budget</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="w-40">
                                                                                                                            {
                                                                                                                                !('external_lifetime_budget' in adgroup) && !("no_budget" in adgroup) &&
                                                                                                                                <div className="text-sm  font-medium">{adgroup.scheduleActivated ? ("tempPercentage" in adgroup ? adgroup.tempPercentage : this.renders.percentageOfMaxbudget(adgroup, channel)) : ""}%</div>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                ("no_budget" in adgroup) &&
                                                                                                                                <div className="text-sm  font-medium">No budget</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    !campaign.shared_budget &&
                                                                                                                    adgroup.scheduleActivated &&
                                                                                                                    <div className="flex">
                                                                                                                        <div onClick={async () => {
                                                                                                                            adgroup.edit = !adgroup.edit;
                                                                                                                            await this.promisedSetState({
                                                                                                                                budget_planer: this.state.budget_planer
                                                                                                                            });
                                                                                                                        }} className={(adgroup.edit ? "bg-red-100 text-red-500" : "bg-purple-100 text-purple-500") + " w-9 h-9 ml-4  cursor-pointer rounded-md items-center justify-center flex "}>
                                                                                                                            {
                                                                                                                                !adgroup.edit &&
                                                                                                                                <PencilAltIcon className="w-5" />
                                                                                                                            }
                                                                                                                            {
                                                                                                                                adgroup.edit &&
                                                                                                                                <XIcon className="w-5" />
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }

                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        /*
                                                                                                        (!this.state.order.budgetChannels || (this.state.order.budgetChannels && this.renders.channelTargetExist(channel))) &&
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        adgroup.scheduleActivated &&
                                                                                                        'external_lifetime_budget' in adgroup &&
                                                                                                        !("no_budget" in adgroup) &&
                                                                                                        <div id={"error_budget_" + adgroup.id} className={"bg-red-100 text-red-500 col-span-12 mb-4 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex h-12 mx-3"}>
                                                                                                            <div className='p-4 inline-flex items-center'><ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />Adgroup has lifetime budget. It will cause pacing data show incorrect.</div>
                                                                                                        </div>
                                                                                                        */
                                                                                                    }
                                                                                                    {
                                                                                                        /*false &&
                                                                                                        (!this.state.order.budgetChannels || (this.state.order.budgetChannels && this.renders.channelTargetExist(channel))) &&
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        this.renders.calculation(false, adgroup) &&
                                                                                                        !("no_budget" in adgroup) &&
                                                                                                        <div id={"error_budget_" + adgroup.id} className={"bg-red-100 text-red-500 col-span-12 mb-4 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden inline-flex h-12 mx-3"}>
                                                                                                            <div className='p-4 inline-flex items-center'><ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />Daily budget and total budget is not matching due to manual edits to budget. <span onClick={async () => {
                                                                                                                this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel, true);
                                                                                                            }} className="text-sm cursor-pointer underline ml-1">
                                                                                                                Click to fix
                                                                                                            </span></div>
                                                                                                        </div>
                                                                                                        */
                                                                                                    }
                                                                                                    {
                                                                                                        /*
                                                                                                         (!this.state.order.budgetChannels || (this.state.order.budgetChannels && this.renders.channelTargetExist(channel))) &&
                                                                                                         this.renders.monthlyTargetExist() &&
                                                                                                         <div id={"error_" + adgroup.id} className={"bg-red-100 text-red-500 col-span-12 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden mx-3 flex" + (this.state.errors[adgroup.id] ? " h-12 mb-4" : " h-0")}>
                                                                                                             <div className='p-4 inline-flex items-center'><ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />{this.state.errors[adgroup.id] ? this.state.errors[adgroup.id] : ""}</div>
                                                                                                         </div>
                                                                                                         */
                                                                                                    }
                                                                                                    {
                                                                                                        /*
                                                                                                        (!this.state.order.budgetChannels || (this.state.order.budgetChannels && this.renders.channelTargetExist(channel))) &&
                                                                                                        this.renders.monthlyTargetExist() &&
                                                                                                        <div id={"max_error_" + adgroup.id} className={"bg-red-100 col-span-12 text-red-500 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex mx-3 h-0"}>
                                                                                                            <span className='p-4 inline-flex items-center'>
                                                                                                                <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                                                                                                {"Warning: Budget exceeded - the new budget you are trying to allocate exceeds the available " + (this.state.order.budgetChannels && this.state.order.budgetChannels[channel] ? " Channel" : " Order") + " Monthly Target of " + (this.state.order.budgetChannels && this.state.order.budgetChannels[channel] ? this.state.order.budgetChannels[channel].value : this.state.order.maxBudget) + ". Your remaining Monthly Budget is " + +this.renders.total_left(adgroup, channel).toFixed(2) + " or " + ((+this.renders.total_left(adgroup, channel).toFixed(2) / (this.state.order.budgetChannels && this.state.order.budgetChannels[channel] ? +this.state.order.budgetChannels[channel].value : +this.state.order.maxBudget)) * 100).toFixed(2) + " percentage. Please revise and try again."}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        */
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </SlideDown>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {
                    this.state.tab.id == 1 &&
                    this.state.loading_planer &&
                    <div className="p-4 pt-0">
                        <div className="bg-white rounded-lg shadow w-full h-76">
                            <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                                <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            </div>
                        </div>
                    </div>
                }

                {/* UPCOMING/HISTORY */}
                {
                    (this.state.tab.id == 2 || this.state.tab.id == 3) &&
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow">
                            <div className="w-full">
                                <div className="grid-cols-12 grid">
                                    {
                                        Array.isArray(this.state.order.budgetPlans) && this.state.order.budgetPlans.length > 0 &&
                                        <div className="col-span-12 px-4">
                                            <div className="w-full">
                                                {
                                                    this.state.order.budgetPlans.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((item, index) => {
                                                        if (this.state.tab.id == 2) {
                                                            if (item.date < this.state.today) return;
                                                        } else if (this.state.tab.id == 3) {
                                                            if (item.date >= this.state.today) return;
                                                        }
                                                        return (
                                                            <Fragment>
                                                                <div className={(index > 0 ? "border-t" : "") + " flex flex-row w-full py-4 justify-center items-center"}>
                                                                    <div className={"mr-4"}>
                                                                        <div onClick={() => {
                                                                            item.selected = !item.selected;
                                                                            this.setState({
                                                                                order: this.state.order
                                                                            });
                                                                        }} className={(true ? " cursor-pointer " : " cursor-not-allowed ") + (item.selected ? " bg-purple-500" : "") + " rounded-md flex items-center justify-center relative border h-10 w-10 bg-custom-input"}>
                                                                            {
                                                                                item.selected &&
                                                                                <CheckIcon className="w-5 text-white" />
                                                                            }
                                                                            {
                                                                                item.loading &&
                                                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="text-sm font-medium">
                                                                            <span
                                                                                className={cn("inline-flex mr-1 font-medium rounded-full uppercase ",
                                                                                    {
                                                                                        [" text-green-500"]: !(item.action && item.action.value == "paused"),
                                                                                        [" text-orange-500 bg-opacity-50"]: item.action && item.action.value == "paused"
                                                                                    })
                                                                                }>
                                                                                {item.action && item.action.value == "paused" ? "Pause" : "Activate"}
                                                                            </span>
                                                                            Budget
                                                                            {
                                                                                !(item.action && item.action.value == "paused") &&
                                                                                <span> {"&"} update monthly target to {item.budget}</span>
                                                                            }
                                                                        </div>
                                                                        <div className="text-xs">
                                                                            <span className="">{item.date}</span>
                                                                            {
                                                                                (item.reference && item.reference !== "") &&
                                                                                <span><span className="mx-1">&#x2022;</span>Reference ID <span className="">{item.reference}</span></span>
                                                                            }
                                                                            {
                                                                                item.sub_action && item.sub_action.value &&
                                                                                <span><span className="mx-1">&#x2022;</span> <span className="">{item.sub_action.name}</span></span>
                                                                            }
                                                                            {
                                                                                item.channel_budget &&
                                                                                <span>
                                                                                    {
                                                                                        Object.keys(item.channel_budget).map((channel) => {
                                                                                            return (
                                                                                                <span>
                                                                                                    <span className="mx-1">&#x2022;</span>
                                                                                                    <span className="capitalize">{channel}</span> {item.channel_budget[channel].off_budget}%
                                                                                                </span>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex flex-1 flex-col text-right justify-end">
                                                                        <div className="text-sm opacity-0 cursor-pointer hover:text-purple-600 text-purple-500 font-medium">
                                                                            Edit plan
                                                                        </div>
                                                                        <div className="text-xs">
                                                                            <span className="">{item.createdBy && item.createdBy.email ? item.createdBy.email : "-"}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="col-span-12 border-t">
                                        <div className="flex flex-1 items-center pl-1 py-5 px-6">
                                            <div onClick={() => {
                                                this.setState({
                                                    remove: { name: this.state.order.name }
                                                });
                                            }} className={(Array.isArray(this.state.order.budgetPlans) && this.state.order.budgetPlans.filter((item) => { return item.selected }).length > 0 ? "cursor-pointer hover:text-red-600 text-red-500 " : " cursor-not-allowed text-gray2-600") + " text-sm ml-5 cursor-pointer font-medium mr-4 inline-flex flex-row"}>
                                                <TrashIcon className="w-5 mr-2" />
                                                Remove
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div >
        )
    }

}

export default OrderBudgetPlanerTemp;