import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { orderRegister } from '../services/orderRegister';
import { SparklesIcon, PencilAltIcon, ViewGridIcon, VideoCameraIcon, CameraIcon, TrashIcon, ArrowNarrowRightIcon, CollectionIcon, XCircleIcon, PhoneIcon, XIcon, ArrowLeftIcon, ArrowRightIcon, PlusIcon, DuplicateIcon, GiftIcon, SearchCircleIcon, NewspaperIcon, TemplateIcon, LockClosedIcon, DesktopComputerIcon, PlayIcon, DocumentAddIcon, FilmIcon, CheckIcon, SaveIcon, ExclamationIcon } from '@heroicons/react/outline';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import EditFacebookCampaign from '../moduleFiles/editFacebookCampaign';
import EditFacebookAdset from '../moduleFiles/editFacebookAdset';
import EditFacebookAd from '../moduleFiles/editFacebookAd';
import CampaignTabs from '../moduleFiles/campaignTabs';
import AdgroupTabs from '../moduleFiles/adgroupTabs';
import ToolTip from '../moduleFiles/toolTip';
import { draftRegister } from '../services/draftRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import SuccessNotification from '../moduleFiles/successNotification';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import EditTikTokCampaign from '../moduleFiles/editTikTokCampaign';
import EditTikTokAdset from '../moduleFiles/editTikTokAdset';
import EditTikTokAd from '../moduleFiles/editTikTokAd';
import EditTwitterCampaign from '../moduleFiles/editTwitterCampaign';
import EditTwitterAdset from '../moduleFiles/editTwitterAdset';
import EditLinkedinCampaign from '../moduleFiles/editLinkedinCampaign';
import EditLinkedinAdgroup from '../moduleFiles/editLinkedinAdgroup';
import EditTwitterAd from '../moduleFiles/editTwitterAd';
import EditLinkedinAd from '../moduleFiles/editLinkedAd';
import EditGoogleCampaign from '../moduleFiles/editGoogleCampaign';
import CreateOrderCreativeSearchAd from '../moduleFiles/createOrderCreativeSearchAd';
import CreateOrderCreativeKeywords from '../moduleFiles/createOrderCreativeKeywords';
import EditGoogleAdgroup from '../moduleFiles/editGoogleAdgroup';
import CreateOrderCreativePerformanceMax from '../moduleFiles/createOrderCreativePerformanceMax';
import CreateOrderCreativeSearchExtension from '../moduleFiles/createOrderCreativeSearchExtension';
import AddCampaignModal from '../moduleFiles/addCampaignModal';
import SideNotification from '../moduleFiles/sideNotification';
import EditSnapchatCampaign from '../moduleFiles/editSnapchatCampaign';
import EditSnapchatAdset from '../moduleFiles/editSnapchatAdset';
import EditSnapchatAd from '../moduleFiles/editSnapchatAd';

import { adgroupTikTok } from '../validators/adgroupTiktok';
import { campaignTiktok } from '../validators/campaignTiktok';
import { adTiktok } from '../validators/adTiktok';

import { adgroupSnapchat } from '../validators/adgroupSnapchat';
import { campaignSnapchat } from '../validators/campaignSnapchat';
import { adSnapchat } from '../validators/adSnapchat';

import { campaignFacebook } from '../validators/campaignFacebook';
import { adgroupFacebook } from '../validators/adgroupFacebook';
import { adFacebook } from '../validators/adFacebook';

import { adgroupLinkedin } from '../validators/adgroupLinkedin';
import { campaignLinkedin } from '../validators/campaignLinkedin';
import { adLinkedin } from '../validators/adLinkedin';

import { campaignTwitter } from '../validators/campaignTwitter';
import { adgroupTwitter } from '../validators/adgroupTwitter';
import { adTwitter } from '../validators/adTwitter';

import { campaignGoogle } from '../validators/campaignGoogle';
import { adgroupGoogle } from '../validators/adgroupGoogle';
import { performanceMax } from '../validators/performanceMax';
import { searchGoogle } from '../validators/searchGoogle';
import { extensionGoogle } from '../validators/extensionGoogle';
import { round } from '@turf/turf';
import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';
import { userRegister } from '../services/userRegister';
import internalChannels from '../assets/json/channels.json';
import CreateOrderCreativeDisplay from '../moduleFiles/createOrderCreativeDisplay';
import SlideoutTailwindAsset from '../moduleFiles/slideoutTailwindAsset';
import ReactTooltip from 'react-tooltip';

class CreateDraftChannel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agency: {},
            client: {},
            wizard: {},
            campaign: {},
            adset: {},
            order: {},
            update: {},
            campaigns: [],
            adgroups: [],
            ads: [],
            assetItems: [],
            creatives: [],
            channels: {},
            ad_options: [
                { id: 1, icon: CameraIcon, name: "Image", type: "image", ad_type: "image_ad", channels: ["facebook", "twitter", "linkedin", "snapchat"] },
                { id: 2, icon: VideoCameraIcon, name: "Video", type: "video", ad_type: "video_ad", channels: ["facebook", "twitter", "linkedin", "tiktok", "snapchat"] },
                { id: 3, icon: CollectionIcon, name: "Carousel", type: "carousel", ad_type: "carousel_ad", channels: ["facebook", "twitter", "linkedin"] },
                { id: 4, icon: GiftIcon, name: "Catalog", type: "catalog", ad_type: "catalog_ad", channels: ["facebook"] },
                { id: 5, icon: NewspaperIcon, name: "Ad templates", type: "search_ad", ad_type: "search_ad", channels: ["google"] },
                { id: 6, icon: SearchCircleIcon, name: "Keywords", type: "keywords", ad_type: "keywords", channels: ["google"] },
                { id: 6, icon: GiftIcon, name: "Shopping", type: "shopping", ad_type: "shopping", channels: ["google"] },
                { id: 8, icon: FilmIcon, name: "Youtube", type: "youtube", ad_type: "youtube", channels: ["google"] },
                { id: 8, icon: DocumentAddIcon, name: "Extension", type: "extension", ad_type: "extension", channels: ["google"] },
                { id: 7, icon: NewspaperIcon, name: "Posts", type: "posts", ad_type: "posts", channels: ["facebook", "twitter", "linkedin", "tiktok", "snapchat"] },
                { id: 19, icon: TemplateIcon, name: "Performance max", type: "performance_max", ad_type: "performance_max", channels: ["google"] },
                { id: 20, icon: SparklesIcon, name: "Display Network", type: "display", ad_type: "display", channels: ["google"] },
                //{ id: 5, icon: VideoCameraIcon, name: "Event", type: "event" },
                //{ id: 6, icon: VideoCameraIcon, name: "Sponsored posts", type: "sponsored" }
            ],
            saved_success: false,
            show: {},
            error: false,
            internalChannels: internalChannels,
            new_asset_slideout: false,
            preview_data: false,
            ai_result: false,
        };
    }

    componentWillMount() { }

    async componentDidMount() {
        await this.form.get();
        this.props.setSteps();
        this.functions.query();
        Object.keys(this.state.channels).forEach(async item => {
            if (Array.isArray(this.state.client.channels) && this.state.client.channels.filter((inner_item) => { return inner_item.value === item }).length < 1) {
                await this.promisedSetState({
                    error: true
                })
            }
        });
        // this.functions.snapchatAttachmentError()
    }

    form = {
        set: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                draftRegister.set("creatives", self.state.creatives);
                draftRegister.set("assetItems", self.state.assetItems);
                draftRegister.set("channels", self.state.channels);
                draftRegister.set("assetItems", self.state.assetItems);
                draftRegister.set("wizard", self.state.wizard);
                draftRegister.set("client", self.state.client);
                draftRegister.set("campaigns", self.state.campaigns);
                draftRegister.set("adgroups", self.state.adgroups);
                draftRegister.set("ads", self.state.ads);
                resolve();
            })
        },
        get: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                if (draftRegister.get()) {
                    await self.promisedSetState({
                        creatives: draftRegister.get()["creatives"] ? draftRegister.get()["creatives"] : self.state.creatives,
                        assetItems: draftRegister.get()["assetItems"] ? draftRegister.get()["assetItems"] : self.state.assetItems,
                        channels: draftRegister.get()["channels"] ? draftRegister.get()["channels"] : self.state.channels,
                        wizard: draftRegister.get()["wizard"] ? draftRegister.get()["wizard"] : self.state.wizard,
                        order: draftRegister.get()["order"] ? draftRegister.get()["order"] : self.state.order,
                        client: draftRegister.get()["client"] ? draftRegister.get()["client"] : self.state.client,
                        campaigns: draftRegister.get()["campaigns"] ? draftRegister.get()["campaigns"] : self.state.campaigns,
                        adgroups: draftRegister.get()["adgroups"] ? draftRegister.get()["adgroups"] : self.state.adgroups,
                        ads: draftRegister.get()["ads"] ? draftRegister.get()["ads"] : self.state.ads,
                    })
                    await self.functions.getAgency();
                }
                resolve();
            })
        }
    };

    functions = {
        validation: (item, level) => {
            if (level === "campaign") {
                let error = false;
                if (!item.partial_parced) {
                    if (item.channel === "facebook") {
                        if (campaignFacebook.validate(item)) {
                            error = true;
                        }
                    } else if (item.channel === "google") {
                        if (Object.keys(campaignGoogle.validate(item)).filter((inner_item) => { return campaignGoogle.validate(item)[inner_item] }).length > 0) {
                            error = true;
                        }
                    } else if (item.channel === "linkedin") {
                        if (Object.keys(campaignLinkedin.validate(item)).filter((inner_item) => { return campaignLinkedin.validate(item)[inner_item] }).length > 0) {
                            error = true;
                        }
                    } else if (item.channel === "twitter") {
                        if (campaignTwitter.validate(item)) {
                            error = true;
                        }
                    } else if (item.channel === "tiktok") {
                        if (campaignTiktok.validate(item)) {
                            error = true;
                        }
                    } else if (item.channel === "snapchat") {
                        if (Object.keys(campaignSnapchat.validate(item)).filter((inner_item) => { return campaignSnapchat.validate(item)[inner_item] }).length > 0) {
                            error = true;
                        }
                    }
                }
                return error;
            } else if (level === "adgroup") {
                let adgroup_error = false;

                if (!item.partial_parced) {
                    if (item.channel === "facebook") {
                        if (Object.keys(adgroupFacebook.validate(item)).filter((inner_item) => { return adgroupFacebook.validate(item)[inner_item] }).length > 0) {
                            adgroup_error = true;
                        }
                    } else if (item.channel === "google") {
                        if (Object.keys(adgroupGoogle.validate(item)).filter((inner_item) => { return adgroupGoogle.validate(item)[inner_item] }).length > 0) {
                            adgroup_error = true;
                        }
                    } else if (item.channel === "linkedin") {
                        if (Object.keys(adgroupLinkedin.validate(item)).filter((inner_item) => { return adgroupLinkedin.validate(item)[inner_item] }).length > 0) {
                            adgroup_error = true;
                        }
                    } else if (item.channel === "twitter") {
                        if (Object.keys(adgroupTwitter.validate(item)).filter((inner_item) => { return adgroupTwitter.validate(item)[inner_item] }).length > 0) {
                            adgroup_error = true;
                        }
                    } else if (item.channel === "tiktok") {
                        if (Object.keys(adgroupTikTok.validate(item)).filter((inner_item) => { return adgroupTikTok.validate(item)[inner_item] }).length > 0) {
                            adgroup_error = true;
                        }
                    } else if (item.channel === "snapchat") {
                        if (Object.keys(adgroupSnapchat.validate(item, this.state.campaigns, (this.state.wizard.partial && !item.partial ? true : false))).filter((inner_item) => { return adgroupSnapchat.validate(item, this.state.campaigns, (this.state.wizard.partial && !item.partial ? true : false))[inner_item] }).length > 0) {
                            adgroup_error = true;
                        }
                    }
                }
                return adgroup_error;
            } else if (level === "ad") {
                if (item.channel === "facebook") {
                    if (Object.keys(adFacebook.validate(item)).map((inner_item) => { return adFacebook.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                        return true;
                    } else return false;
                } else if (item.channel === "google") {
                    if (item.ad_type) {
                        if (["performance_max", "display"].includes(item.ad_type.value) && Object.keys(performanceMax.validate(item)).map((inner_item) => { return performanceMax.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                            return true;
                        } else if (item.ad_type.value === "keywords" && (item.name === "" || item.keywords.length === 0)) {
                            return true;
                        } else if (item.ad_type.value === "search_ad" && Object.keys(searchGoogle.validate(item)).map((inner_item) => { return searchGoogle.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                            return true;
                        } else if (item.ad_type.value === "extension" && Object.keys(extensionGoogle.validate(item)).map((inner_item) => { return extensionGoogle.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else if (item.channel === "linkedin") {
                    if (Object.keys(adLinkedin.validate(item)).map((inner_item) => { return adLinkedin.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                        return true;
                    } else return false;
                } else if (item.channel === "tiktok") {
                    if (Object.keys(adTiktok.validate(item)).map((inner_item) => { return adTiktok.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                        return true;
                    } else return false;
                } else if (item.channel === "twitter") {
                    if (Object.keys(adTwitter.validate(item)).map((inner_item) => { return adTwitter.validate(item)[inner_item] }).filter((inner_item) => {
                        return inner_item
                    }).length > 0) {
                        return true;
                    } else return false;
                } else if (item.channel === "snapchat") {
                    if (Object.keys(adSnapchat.validate(item)).map((inner_item) => { return adSnapchat.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                        return true;
                    } else return false;
                }
            }
            //(item.ad_type && item.ad_type.value === "performance_max" && Object.keys(performanceMax.validate(item)).map((inner_item) => { return performanceMax.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) &&
            //(item.ad_type && item.ad_type.value === "keywords" && (item.name === "" || item.keywords.length === 0)) &&
            //(item.ad_type && item.ad_type.value === "search_ad" && Object.keys(searchGoogle.validate(item)).map((inner_item) => { return searchGoogle.validate(item)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) &&
        },
        query: () => {
            let campaign = null;
            if ((window.location.search.match(new RegExp('[?&]' + "campaign" + '=([^&]+)')) || [null])[1]) {
                let id = (window.location.search.match(new RegExp('[?&]' + "campaign" + '=([^&]+)')) || [null])[1];
                this.state.campaigns.map((item) => {
                    if (item.id == id) {
                        campaign = item;
                    }
                });
            }
            this.functions.init(campaign);
        },
        init: (campaign) => {
            if (!campaign) {
                campaign = this.state.campaigns.filter((item) => { return item.channel === this.props.channel })[0];
            }
            this.state.campaigns = this.state.campaigns.map((item) => {
                if (item.channel === this.props.channel) {
                    item.selected = (item.id === campaign.id);
                }
                return item;
            });
            let first = true;
            this.state.adgroups = this.state.adgroups.map((item) => {
                if (item.channel === this.props.channel) {
                    if (first && item.campaign === campaign.id) {
                        item.selected = true;
                        first = false;
                    } else {
                        item.selected = false;
                    }
                }
                return item;
            });
            this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            })
        },
        onEdit: async (level, campaign = false) => {

            let update_value = {};
            if (campaign) {
                if (this.state.campaigns.filter((campaign) => { return campaign.selected && campaign.channel === this.props.channel && Array.isArray(campaign.asset) }).length === 1) {
                    if (this.state.campaigns.filter((campaign) => { return campaign.selected && campaign.channel === this.props.channel })[0].asset.length > 0 && this.state.campaigns.filter((campaign) => { return campaign.selected && campaign.channel === this.props.channel })[0].asset.filter((asset) => { return asset.selected }).length > 0) {
                        update_value = this.state.campaigns.filter((campaign) => { return campaign.selected && campaign.channel === this.props.channel })[0].asset.filter((asset) => { return asset.selected })[0];
                        await this.promisedSetState({
                            update: update_value,
                            new_asset_slideout: false,
                        })
                    }
                }

            } else {
                if ((this.state[level + 's'].filter((property) => { return property.selected && property.channel === this.props.channel }).length > 0)) {
                    if (this.state[level + 's'].filter((property) => { return property.selected && property.channel === this.props.channel }).length > 1) {
                        update_value = {
                            id: this.state[level + 's'].filter((property) => { return property.selected && property.channel === this.props.channel }).length > 1 ? "multiple" : this.state[level + 's'].filter((property) => { return property.selected && property.channel === this.props.channel })[0].id,
                            channel: this.props.channel,
                            level: level,
                            multiple: this.state[level + 's'].filter((property) => { return property.selected && property.channel === this.props.channel }).length > 1,
                            ids: this.state[level + 's'].filter((property) => { return property.selected && property.channel === this.props.channel }).map((item) => { return item.id })
                        }
                    } else {
                        update_value = this.state[level + 's'].filter((property) => { return property.selected && property.channel === this.props.channel })[0];
                    }

                    if (update_value) {
                        // await this.functions.reparseAdData(update_value);
                    }
                    await this.promisedSetState({
                        update: update_value,
                        new_asset_slideout: level === "ad" && update_value && (update_value.type !== 'search_ad' && update_value.type !== 'keywords' && update_value.type !== 'performance_max' && update_value.type !== "display" && update_value.type !== "extension") ? true : false,
                    })
                }
            }

        },
        getAgency: async () => {
            try {
                let response = await this.calls.getAgency();
                await this.promisedSetState({
                    agency: response.data
                });
            } catch (error) {
                console.log(error);
            }
        },
        onClone: async (level) => {

            let self = this;

            if (level === "campaign") {
                this.state.campaigns.map((campaign) => {
                    if (campaign.selected && campaign.channel === this.props.channel) {
                        update(campaign);
                    }
                });
            } else {
                this.state.adgroups.map((adgroup) => {
                    if (adgroup.selected && adgroup.channel === this.props.channel) {
                        update(adgroup);
                    }
                });
            }

            function update(item) {
                if (item) {
                    let new_object = JSON.parse(JSON.stringify(item));
                    new_object.id = Math.floor((Math.random() * 9999999999) + 1);
                    if (new_object.level === 'campaign') {
                        new_object.selected = false;
                        self.state.campaigns.push(new_object);
                        let adgroups = self.state.adgroups.filter((adgroup) => {
                            return adgroup.campaign === item.id;
                        });
                        adgroups = JSON.parse(JSON.stringify(adgroups));
                        adgroups = adgroups.map((item) => {
                            item.selected = false;
                            item.name = item.name;
                            let ads = self.state.ads.filter((ad) => {
                                return ad.adgroup === item.id;
                            });
                            ads = JSON.parse(JSON.stringify(ads));
                            item.campaign = new_object.id;
                            item.id = Math.floor((Math.random() * 9999999999) + 1);
                            ads = ads.map((inner_item) => {
                                inner_item.id = Math.floor((Math.random() * 9999999999) + 1);
                                inner_item.adgroup = item.id;
                                inner_item.campaign = item.campaign;
                                return inner_item
                            });
                            self.state.ads = self.state.ads.concat(ads);
                            return item
                        });
                        self.state.adgroups = self.state.adgroups.concat(adgroups);
                    } else {
                        new_object.selected = false;
                        self.state.adgroups.push(new_object);
                        let ads = self.state.ads.filter((ad) => {
                            return ad.adgroup === item.id;
                        });
                        ads = JSON.parse(JSON.stringify(ads));
                        ads = ads.map((item) => {
                            item.campaign = new_object.campaign;
                            item.adgroup = new_object.id;
                            item.id = Math.floor((Math.random() * 9999999999) + 1);
                            return item
                        });
                        self.state.ads = self.state.ads.concat(ads);
                    }
                    self.promisedSetState({
                        campaigns: self.state.campaigns,
                        adgroups: self.state.adgroups,
                        ads: self.state.ads
                    });
                    self.form.set();
                }
            }

        },
        onNewGoogle: async (existing_campaign, sub_option) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {

                let campaign_object = {
                    id: campaign_id,
                    level: 'campaign',
                    channel: "google",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                    allow_target_spend_ceiling: { id: 1, name: 'Disabled', value: false },
                    days: [{ id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } }]
                };

                if (sub_option && sub_option.value === "search") {
                    campaign_object.strategy_type = { id: 1, name: "Click", value: "TARGET_SPEND" };
                    campaign_object.campaign_type = "Search";
                    campaign_object.advertising_type = { id: 1, name: 'Search', value: "SEARCH" };
                    campaign_object.google_search = { id: 1, name: "Enabled", value: true, locked: true };
                    campaign_object.search_network = { id: 1, name: "Enabled", value: true, locked: false };
                    campaign_object.content_network = { id: 1, name: "Enabled", value: true, locked: false };
                    campaign_object.partner_search_network = { id: 2, name: "Disabled", value: false, locked: true };
                    campaign_object.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "SEARCH_STANDARD", ad: "RESPONSIVE_SEARCH_AD" };
                } else if (sub_option && sub_option.value === "performance_max") {
                    campaign_object.campaign_type = "Performance Max";
                    campaign_object.dsa = { id: 2, name: "Disable", value: false };
                    campaign_object.strategy_type = { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS" };
                    campaign_object.advertising_type = { id: 6, name: "Performance Max", value: "PERFORMANCE_MAX" };
                    campaign_object.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "PERFORMANCE_MAX", ad: "PERFORMANCE_MAX" };
                } else if (sub_option && sub_option.value === "display") {
                    campaign_object.campaign_type = "Display Network";
                    campaign_object.campaign_type_property = "display";
                    campaign_object.dsa = { id: 2, name: "Disable", value: false };
                    campaign_object.strategy_type = { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS" };
                    campaign_object.advertising_type = { id: 6, name: "Display", value: "DISPLAY" };
                    campaign_object.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "DISPLAY", ad: "DISPLAY" };
                } else if (sub_option && sub_option.value === "shopping") {
                    campaign_object.campaign_type = "Shopping";
                    campaign_object.enable_local = { id: 1, name: "Disabled", value: false };
                    campaign_object.shopping_priority = { id: 1, name: "Low (default) – Recommended if you only have one Shopping campaign", value: 0 };
                    campaign_object.dsa = { id: 2, name: "Disable", value: false };
                    campaign_object.google_search = { id: 2, name: "Disabled", value: false, locked: true };
                    campaign_object.search_network = { id: 2, name: "Disabled", value: false, locked: true };
                    campaign_object.content_network = { id: 2, name: "Disabled", value: false, locked: false };
                    campaign_object.partner_search_network = { id: 2, name: "Disabled", value: false, locked: true };
                    campaign_object.strategy_type = { id: 7, name: "Maximize clicks", value: "TARGET_SPEND" };
                    campaign_object.advertising_type = { id: 4, name: "Shopping Standard", value: "SHOPPING" };
                    campaign_object.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "SHOPPING_PRODUCT_ADS", ad: "SHOPPING_PRODUCT_AD" };
                } else if (sub_option && sub_option.value === "video") {
                    campaign_object.campaign_type = "Video";
                    campaign_object.advertising_type = { id: 3, name: "Video (Youtube) - Preview only", value: "VIDEO" };
                    campaign_object.advertising_sub_type = { id: 1, name: "Video non skippable", value: "VIDEO_NON_SKIPPABLE", adgroup: "VIDEO_NON_SKIPPABLE_IN_STREAM", ad: "VIDEO_NON_SKIPPABLE_IN_STREAM_AD" };
                    campaign_object.dsa = { id: 2, name: "Disable", value: false };
                }

                if (this.state.wizard.scheduler) {
                    campaign_object.scheduler = this.state.wizard.scheduler;
                }

                if (this.state.order.budgetSplit && this.state.order.budgetSplit.value && this.state.order.budgetSplit.budget && this.state.order.budgetSplit.google_budget && sub_option && sub_option.value === "search" && this.state.wizard.bingSync) {
                    if (campaign_object.scheduler) {
                        campaign_object.scheduler.schedule = true;
                        campaign_object.scheduler.planer = true;
                        campaign_object.scheduler.schedule_budget = round(+this.state.order.budgetSplit.budget * (+this.state.order.budgetSplit.google_budget / 100));
                    }
                    campaign_object.bingSync = {
                        budget: +this.state.order.budgetSplit.budget,
                        google_budget: +this.state.order.budgetSplit.google_budget,
                        bing_budget: +this.state.order.budgetSplit.bing_budget,
                        total_budget: +this.state.order.budgetSplit.budget * (+this.state.order.budgetSplit.bing_budget / 100),
                        schedule: { id: 3, name: "Daily", value: "Daily" },
                        notificationType: { id: 3, name: "Only if there are errors", value: "Errors" },
                        email: userRegister.get() ? userRegister.get().email : "",
                        bing_percentage_from_google: budgetPlannerGraph.main(campaign_object.scheduler, +this.state.order.budgetSplit.budget * (+this.state.order.budgetSplit.bing_budget / 100)).datasets[0].data[0] === Infinity ? 0 : round(+budgetPlannerGraph.main(campaign_object.scheduler, +this.state.order.budgetSplit.budget * (+this.state.order.budgetSplit.bing_budget / 100)).datasets[0].data[0] * 100 / +budgetPlannerGraph.main(campaign_object.scheduler).datasets[0].data[0]),
                    };
                }
                if (this.state.order.googleBudgets) {
                    campaign_object.budget = 1;
                    campaign_object.googleBudgets = this.state.order.googleBudgets;
                    campaign_object.shared_budget = this.state.order.googleBudgets[0];
                    if (campaign_object.scheduler && campaign_object.scheduler.schedule) {
                        campaign_object.scheduler.schedule = false;
                    }
                }

                if (campaign_object.scheduler) {
                    campaign_object.scheduler.order_info = this.state.order;
                }
                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "google",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                name: "Adset",
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                gender: { id: 1, name: "All genders", value: "ALL" },
                ages: [
                    { id: 1, name: "18 - 24", value: "AGE_RANGE_18_24", included: true },
                    { id: 2, name: "25 - 34", value: "AGE_RANGE_25_34", included: true },
                    { id: 3, name: "35 - 44", value: "AGE_RANGE_35_44", included: true },
                    { id: 4, name: "45 - 54", value: "AGE_RANGE_45_54", included: true },
                    { id: 5, name: "55 - 64", value: "AGE_RANGE_55_64", included: true },
                    { id: 6, name: "65+", value: "AGE_RANGE_65_UP", included: true },
                    { id: 7, name: "Undetermined", value: "AGE_RANGE_UNDETERMINED", included: true }
                ],
            };

            if (!existing_campaign) {
                if (sub_option && sub_option.value === "search") {
                    adgroup_object.advertising_type = "SEARCH_STANDARD";
                    adgroup_object.advertising_type_ad = "RESPONSIVE_SEARCH_AD";
                } else if (sub_option && sub_option.value === "search") {
                    adgroup_object.advertising_type = "PERFORMANCE_MAX";
                    adgroup_object.advertising_type_ad = "PERFORMANCE_MAX";
                } else if (sub_option && sub_option.value === "shopping") {
                    adgroup_object.advertising_type = "SHOPPING_PRODUCT_ADS";
                    adgroup_object.advertising_type_ad = "SHOPPING_PRODUCT_AD";
                } else if (sub_option && sub_option.value === "video") {
                    adgroup_object.advertising_type = "VIDEO_NON_SKIPPABLE_IN_STREAM";
                    adgroup_object.advertising_type_ad = "VIDEO_NON_SKIPPABLE_IN_STREAM_AD";
                }
            } else {
                if (existing_campaign.advertising_sub_type) {
                    adgroup_object.advertising_type = existing_campaign.advertising_sub_type.adgroup;
                    adgroup_object.advertising_type_ad = existing_campaign.advertising_sub_type.ad;
                }
            }

            this.state.adgroups.push(adgroup_object);
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });

            await this.form.set();
            this.props.setSteps();
        },
        onNewLinkedin: async (existing_campaign, sub_option, creatives) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {
                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    level: 'campaign',
                    channel: "linkedin",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                    campaign_budget: { id: 1, name: 'No', value: false }
                };
                this.state.campaigns.push(campaign_object);
            }

            for (let key in creatives) {

                let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

                let adgroup_object = {
                    id: adgroup_id,
                    level: 'adgroup',
                    channel: "linkedin",
                    campaign: existing_campaign ? existing_campaign.id : campaign_id,
                    name: "Adset",
                    audience_network: { id: 2, name: "No", value: false },
                    selected_iab_categories: [],
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                    adgroup_budget: { id: 1, name: 'Daily budget', value: "daily" },
                    daily_budget: 0,
                    total_budget: 0,
                    age: [
                        {
                            id: 1,
                            name: "All ages",
                            facetUrn: "urn:li:adTargetingFacet:ageRanges",
                            value: "ALL",
                            included: true
                        }
                    ]
                };

                if (this.state.wizard.scheduler) {
                    adgroup_object.scheduler = this.state.wizard.scheduler;
                    if (this.state.wizard.scheduler.schedule) {
                        adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                    }

                    adgroup_object.scheduler.order_info = this.state.order;
                }


                if (creatives[key].value === "image") {
                    adgroup_object.adgroup_type = { id: 1, name: creatives[key].name, value: "STANDARD_UPDATE" };
                } else if (creatives[key].value === "video") {
                    adgroup_object.adgroup_type = { id: 2, name: creatives[key].name, value: "SINGLE_VIDEO" };
                } else if (creatives[key].value === "carousel") {
                    adgroup_object.adgroup_type = { id: 3, name: creatives[key].name, value: "CAROUSEL" };
                }

                //BRAND AWARENESS
                if (sub_option && sub_option.value === "brand_awareness") {
                    let adgroup_objective = {
                        objective: {
                            name: "Brand awareness",
                            value: "BRAND_AWARENESS",
                            optimization_goals: [
                                {
                                    name: "Reach (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum Delivery (recommended)",
                                            value: "MAX_REACH",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                    ]
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Max Delivery (recommended)",
                                            value: "MAX_IMPRESSION",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Cost cap",
                                            value: "TARGET_COST_PER_IMPRESSION",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ]
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Reach (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum Delivery (recommended)",
                                    value: "MAX_REACH",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ]
                        },
                        bidding_strategy: {
                            name: "Maximum Delivery (recommended)",
                            value: "MAX_REACH",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //WEBSITE VISITS
                if (sub_option && sub_option.value === "website_visits") {
                    let adgroup_objective = {
                        objective: {
                            name: "Website visits",
                            value: "WEBSITE_VISIT",
                            optimization_goals: [
                                {
                                    name: "Landing page click (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum delivery (recommended)",
                                            value: "MAX_CLICK",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Cost cap",
                                            value: "TARGET_COST_PER_CLICK",
                                            cost_type: "CPM",
                                            set_cost: true
                                        }
                                    ]
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ],
                                }
                            ],
                        },
                        optimization_goal: {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPM",
                                    set_cost: true
                                }
                            ]
                        },
                        bidding_strategy: {
                            name: "Maximum delivery (recommended)",
                            value: "MAX_CLICK",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //ENGAGEMENT
                if (sub_option && sub_option.value === "engagement") {
                    let adgroup_objective = {
                        objective: {
                            name: "Engagement",
                            value: "ENGAGEMENT",
                            optimization_goals: [
                                {
                                    name: "Engagement clicks (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum delivery (recommended)",
                                            value: "MAX_CLICK",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Cost cap",
                                            value: "TARGET_COST_PER_CLICK",
                                            cost_type: "CPC",
                                            set_cost: true
                                        }
                                    ],
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ],
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Engagement clicks (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPC",
                                    set_cost: true
                                }
                            ],
                        },
                        bidding_strategy: {
                            name: "Maximum delivery (recommended)",
                            value: "MAX_CLICK",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //VIDEO VIEWS
                if (sub_option && sub_option.value === "video_views") {
                    let adgroup_objective = {
                        objective: {
                            name: "Video views",
                            value: "VIDEO_VIEW",
                            optimization_goals: [
                                {
                                    name: "Video views",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum delivery (recommended)",
                                            value: "MAX_VIDEO_VIEW",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Target cost per video view",
                                            value: "TARGET_COST_PER_VIDEO_VIEW",
                                            cost_type: "CPV",
                                            set_cost: true
                                        },
                                    ]
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ],
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Video views",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_VIDEO_VIEW",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Target cost per video view",
                                    value: "TARGET_COST_PER_VIDEO_VIEW",
                                    cost_type: "CPV",
                                    set_cost: true
                                },
                            ]
                        },
                        bidding_strategy: {
                            name: "Maximum delivery (recommended)",
                            value: "MAX_VIDEO_VIEW",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //LEAD GENERATION
                if (sub_option && sub_option.value === "lead_generation") {
                    let adgroup_objective = {
                        objective: {
                            name: "Lead generation",
                            value: "LEAD_GENERATION",
                            optimization_goals: [
                                {
                                    name: "Leads (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Max delivery (recommended)",
                                            value: "MAX_LEAD",
                                            cost_type: "CPM",
                                            set_cost: false
                                        }
                                    ]
                                },
                                {
                                    name: "Click",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPC",
                                            set_cost: true
                                        },
                                    ]
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ]
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Leads (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Max delivery (recommended)",
                                    value: "MAX_LEAD",
                                    cost_type: "CPC",
                                    set_cost: false
                                }
                            ]
                        },
                        bidding_strategy: {
                            name: "Max delivery (recommended)",
                            value: "MAX_LEAD",
                            cost_type: "CPC",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //WEBSITE CONVERSIONS
                if (sub_option && sub_option.value === "website_conversions") {
                    let adgroup_objective = {
                        objective: {
                            name: "Website conversions",
                            value: "WEBSITE_CONVERSION",
                            optimization_goals: [
                                {
                                    name: "Website conversion (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Max delivery (recommended)",
                                            value: "MAX_CONVERSION",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                    ]
                                },
                                {
                                    name: "Landing page click",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPC",
                                            set_cost: true
                                        },
                                    ],
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ]
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Website conversion (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Max delivery (recommended)",
                                    value: "MAX_CONVERSION",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ]
                        },
                        bidding_strategy: {
                            name: "Max delivery (recommended)",
                            value: "MAX_CONVERSION",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //WEBSITE CONVERSIONS
                if (sub_option && sub_option.value === "jobb_applications") {
                    let adgroup_objective = {
                        objective: {
                            name: "Job applicants",
                            value: "JOB_APPLICANT",
                            optimization_goals: [
                                {
                                    name: "Landing page click (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum Delivery (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                    ],
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ]
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum Delivery (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ],
                        },
                        bidding_strategy: {
                            name: "Maximum Delivery (recommended)",
                            value: "NONE",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                this.state.adgroups.push(adgroup_object);

            }

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });

            await this.form.set();
            this.props.setSteps();
        },
        onNewFacebook: async (existing_campaign, sub_option) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {
                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    level: 'campaign',
                    channel: "facebook",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                    budget_optimization: { id: 2, name: "Yes", value: true },
                    special_ad_category: { id: 1, name: 'None', value: "NONE" },
                    budget_optimization_type: { id: 1, name: "Daily budget", value: 'daily' },
                    daily_budget: 0,
                    add_spend_limit: { id: 1, name: "No", value: false },
                    ios: { id: 0, name: "No", value: false },
                    app_promotion_type: { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" }
                };
                if (sub_option && sub_option.value === "awareness") {
                    campaign_object.objective = { id: 1, name: 'Awareness', value: "OUTCOME_AWARENESS" };
                } else if (sub_option && sub_option.value === "traffic") {
                    campaign_object.objective = { id: 2, name: 'Traffic', value: "OUTCOME_TRAFFIC" };
                } else if (sub_option && sub_option.value === "engagement") {
                    campaign_object.objective = { id: 3, name: 'Engagement', value: "OUTCOME_ENGAGEMENT" };
                } else if (sub_option && sub_option.value === "leads") {
                    campaign_object.objective = { id: 4, name: 'Leads', value: "OUTCOME_LEADS" };
                } else if (sub_option && sub_option.value === "app_promotion") {
                    campaign_object.objective = { id: 5, name: 'App Promotion', value: "OUTCOME_APP_PROMOTION" };
                    campaign_object.budget_optimization = { id: 1, name: "No", value: false };
                } else if (sub_option && sub_option.value === "sales") {
                    campaign_object.objective = { id: 6, name: 'Sales', value: "OUTCOME_SALES" };
                }
                if (this.state.wizard.scheduler) {
                    campaign_object.scheduler = this.state.wizard.scheduler;
                    if (this.state.wizard.scheduler.schedule) {
                        campaign_object.start_date = this.state.wizard.scheduler.start_date;
                    }
                    campaign_object.scheduler.order_info = this.state.order;
                }
                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "facebook",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                name: "Adset",
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                gender: { id: 1, name: "All genders", value: [], selected: true },
                age_min: { id: 18, name: "18", value: "18" },
                age_max: { id: 65, name: "65", value: "65" },
                adset_budget: { id: 2, name: "No", value: false },
                daily_budget: 0,
                lifetime_budget: 0,
                advantage_audience: { id: 1, name: "Select ...", value: false },
                budget_type: { id: 1, name: "Daily budget", value: 'daily' },
                spend_limit: { id: 2, name: "No", value: false },
                auto_placements: { id: 1, name: "Yes (recommended)", value: true },
                beneficiary: this.state.client.name,
                payor: this.state.agency.name
            };

            if (this.state.wizard.scheduler) {
                adgroup_object.scheduler = this.state.wizard.scheduler;
                if (this.state.wizard.scheduler.schedule) {
                    adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                }
                adgroup_object.scheduler.order_info = this.state.order;
            }
            if (this.state.wizard.partial) {
                adgroup_object.partial = true;
            }

            //AWARENESS
            if ((sub_option && sub_option.value === "awareness") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_AWARENESS")) {
                adgroup_object.optimization_goal = { id: 13, name: "Reach", value: "REACH" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 1, name: 'Awareness', value: "OUTCOME_AWARENESS" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //TRAFFIC
            if ((sub_option && sub_option.value === "traffic") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_TRAFFIC")) {
                adgroup_object.optimization_goal = { id: 8, name: "Link Clicks", value: "LINK_CLICKS" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 2, name: 'Traffic', value: "OUTCOME_TRAFFIC" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //ENGAGEMENT
            if ((sub_option && sub_option.value === "engagement") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_ENGAGEMENT")) {
                adgroup_object.optimization_goal = { id: 11, name: "Post Engagement", value: "POST_ENGAGEMENT" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 3, name: 'Engagement', value: "OUTCOME_ENGAGEMENT" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //LEADS
            if ((sub_option && sub_option.value === "leads") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_LEADS")) {
                adgroup_object.optimization_goal = { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 4, name: 'Leads', value: "OUTCOME_LEADS" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //APP PRMOTION
            if ((sub_option && sub_option.value === "app_promotion") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_APP_PROMOTION")) {
                adgroup_object.optimization_goal = { id: 1, name: "App Installs", value: "APP_INSTALLS" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 5, name: 'App Promotion', value: "OUTCOME_APP_PROMOTION" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //SALES
            if ((sub_option && sub_option.value === "sales") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_SALES")) {
                adgroup_object.optimization_goal = { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 6, name: 'Sales', value: "OUTCOME_SALES" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            this.state.adgroups.push(adgroup_object);

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });

            await this.form.set();
            this.props.setSteps();
        },
        onNewTikTok: async (existing_campaign, sub_option) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {

                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    level: 'campaign',
                    channel: "tiktok",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                    budget_optimization: { id: 1, name: "No", value: false },
                };

                if (sub_option && sub_option.value === "traffic") {
                    campaign_object.objective = { id: 2, name: 'Traffic', value: "TRAFFIC" };
                }
                if (sub_option && sub_option.value === "reach") {
                    campaign_object.objective = { id: 1, name: 'Reach', value: "REACH" };
                }
                if (sub_option && sub_option.value === "app_promotion") {
                    campaign_object.objective = { id: 7, name: 'App promotion', value: "APP_PROMOTION" };
                    campaign_object.app_promotion_type = { id: 1, name: "App install", value: "APP_INSTALL" };
                    campaign_object.ios14_campaign = true;
                }
                if (sub_option && sub_option.value === "website_conversions") {
                    campaign_object.objective = { id: 6, name: 'Website conversion', value: "CONVERSIONS" };
                }

                if (this.state.wizard.scheduler) {
                    campaign_object.scheduler = this.state.wizard.scheduler;
                    campaign_object.scheduler.schedule = false;
                    campaign_object.scheduler.planer = false;
                    if (this.state.wizard.scheduler.schedule) {
                        campaign_object.start_date = this.state.wizard.scheduler.start_date;
                    }
                    campaign_object.scheduler.order_info = this.state.order;
                }

                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "tiktok",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                name: "Adset",
                gender: { id: 1, name: "All genders", value: "GENDER_UNLIMITED", selected: true },
                ages: [{ id: 1, name: "All ages", value: "ALL", selected: true }],
                budget_mode: { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" }
            };
            if (this.state.wizard.scheduler) {
                adgroup_object.scheduler = this.state.wizard.scheduler;
                if (this.state.wizard.scheduler.schedule) {
                    adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                }
                adgroup_object.scheduler.order_info = this.state.order;
            }


            if (this.state.wizard.partial) {
                adgroup_object.partial = true;
            }

            //TRAFFIC
            if ((sub_option && sub_option.value === "traffic") || (existing_campaign && existing_campaign.objective.value === "TRAFFIC")) {
                adgroup_object.goal = { id: 8, name: 'Click', value: "CLICK" };
                adgroup_object.billing_event = { id: 1, name: 'CPC', value: "CPC" };
                adgroup_object.campaign_objective = { value: "TRAFFIC" };
                adgroup_object.targeting_mode = { id: 2, name: "Custom targeting", value: "custom" };
            }

            //REACH
            if ((sub_option && sub_option.value === "reach") || (existing_campaign && existing_campaign.objective.value === "REACH")) {
                adgroup_object.goal = { id: 8, name: 'Reach', value: "REACH" };
                adgroup_object.billing_event = { id: 2, name: 'CPM', value: "CPM" };
                adgroup_object.auto_placements = { id: 2, name: "No", value: false };
                adgroup_object.placements = { tiktok: true, pangle: false };
                adgroup_object.targeting_mode = { id: 2, name: "Custom targeting", value: "custom" };
                adgroup_object.campaign_objective = { value: "REACH" };
                adgroup_object.frequency_cap = { id: 1, name: "Show ads no more than 3 times every 7 days", value: "3_per_7" };
                adgroup_object.frequency = 3;
                adgroup_object.frequency_schedule = 7;
            }

            //APP PROMOTION
            if ((sub_option && sub_option.value === "app_promotion") || (existing_campaign && existing_campaign.objective.value === "APP_INSTALL")) {
                adgroup_object.campaign_objective = { value: "APP_PROMOTION" };
                adgroup_object.goal = { id: 7, name: 'Install', value: "INSTALL" };
                adgroup_object.billing_event = { id: 4, name: 'OCPM', value: "OCPM" };
            }

            //WEB CONVERSIONS
            if ((sub_option && sub_option.value === "website_conversions") || (existing_campaign && existing_campaign.objective.value === "CONVERSIONS")) {
                adgroup_object.goal = { id: 5, name: 'Conversion', value: "CONVERT" };
                adgroup_object.billing_event = { id: 4, name: 'OCPM', value: "OCPM" };
                adgroup_object.campaign_objective = { value: "CONVERSIONS" };
                adgroup_object.targeting_mode = { id: 2, name: "Custom targeting", value: "custom" };
            }
            let buffer_campaign = this.state.campaigns.find(campaign => campaign.id === adgroup_object.campaign);
            if (buffer_campaign.budget_optimization && buffer_campaign.budget_optimization.value) {
                adgroup_object.campaign_budget_optimization = true;
                adgroup_object.campaign_budget = {
                    mode: (typeof buffer_campaign.budget_mode === "string") ? buffer_campaign.budget_mode : (buffer_campaign.budget_mode && buffer_campaign.budget_mode.value ? buffer_campaign.budget_mode.value : "---"),
                    value: (((typeof buffer_campaign.budget_mode === "string") && buffer_campaign.budget_mode === "BUDGET_MODE_DAY") || buffer_campaign.budget_mode.value === "BUDGET_MODE_DAY") ? buffer_campaign.daily_budget : ((((typeof buffer_campaign.budget_mode === "string") && buffer_campaign.budget_mode === "BUDGET_MODE_TOTAL") || buffer_campaign.budget_mode.value === "BUDGET_MODE_TOTAL") ? buffer_campaign.total_budget : "?")
                };
            }
            this.state.adgroups.push(adgroup_object);

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });

            await this.form.set();
            this.props.setSteps();
        },
        onNewTwitter: async (existing_campaign, sub_option) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {
                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    level: 'campaign',
                    channel: "twitter",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    budget_optimization: { id: 1, name: "No", value: false }
                };

                if (sub_option && sub_option.value === "app_engagement") {
                    campaign_object.objective = { id: 1, name: "App re-engagements", value: "APP_ENGAGEMENTS" };
                } else if (sub_option && sub_option.value === "app_installs") {
                    campaign_object.objective = { id: 2, name: "App installs", value: "APP_INSTALLS" };
                } else if (sub_option && sub_option.value === "reach") {
                    campaign_object.objective = { id: 3, name: "Reach", value: "REACH" };
                } else if (sub_option && sub_option.value === "followers") {
                    campaign_object.objective = { id: 4, name: "Followers", value: "FOLLOWERS" };
                } else if (sub_option && sub_option.value === "engagements") {
                    campaign_object.objective = { id: 5, name: "Engagements", value: "ENGAGEMENTS" };
                } else if (sub_option && sub_option.value === "video_views") {
                    campaign_object.objective = { id: 6, name: "Video views", value: "VIDEO_VIEWS" };
                } else if (sub_option && sub_option.value === "website_clicks") {
                    campaign_object.objective = { id: 8, name: "Website traffic", value: "WEBSITE_CLICKS" };
                }
                if (this.state.wizard.scheduler) {
                    campaign_object.scheduler = this.state.wizard.scheduler;
                    if (this.state.wizard.scheduler.planer) {
                        campaign_object.budget_optimization = { id: 2, name: "Yes", value: true };
                    }
                    if (this.state.wizard.scheduler.schedule) {
                        campaign_object.start_date = this.state.wizard.scheduler.start_date;
                    }
                    campaign_object.scheduler.order_info = this.state.order;
                }

                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "twitter",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                start_date: moment(this.state.order.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.state.order.endDate).format("YYYY-MM-DD"),
                name: "Adset",
                adgroup_budget_optimization: { id: 2, name: "No", value: false },
                daily_budget: 0,
                total_budget: 0,
                gender: { id: 1, name: "All genders", value: null },
                age_min: { id: 1, name: "13", value: 13 },
                age_max: { id: 5, name: "And up", value: null }
            };

            if (this.state.wizard.scheduler) {
                adgroup_object.scheduler = this.state.wizard.scheduler;
                if (this.state.wizard.scheduler.schedule) {
                    adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                }
                adgroup_object.scheduler.order_info = this.state.order;
            }
            //APP ENGAGEMENT
            if ((sub_option && sub_option.value === "app_engagement") || (existing_campaign && existing_campaign.objective.value === "APP_ENGAGEMENTS")) {
                adgroup_object.objective = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "App Clicks", value: "APP_CLICKS" };
                adgroup_object.goals = [
                    { id: 1, name: "App Clicks", value: "APP_CLICKS" }
                ];
                adgroup_object.pay_by = { id: 1, name: "App clicks", value: "APP_CLICK" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "App clicks", value: "APP_CLICK" }
                ];
            }

            //APP INSTALL
            if ((sub_option && sub_option.value === "app_installs") || (existing_campaign && existing_campaign.objective.value === "APP_INSTALLS")) {
                adgroup_object.objective = { id: 2, name: "App installs", value: "APP_INSTALLS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "App installs (recommended)", value: "APP_INSTALLS" };
                adgroup_object.goals = [
                    { id: 1, name: "App installs (recommended)", value: "APP_INSTALLS" },
                    { id: 2, name: "App Clicks", value: "APP_CLICKS" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Impressions (recommended)", value: "IMPRESSION" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Impressions (recommended)", value: "IMPRESSION" },
                    { id: 2, name: "App clicks", value: "APP_CLICK" }
                ];
            }

            //REACH
            if ((sub_option && sub_option.value === "reach") || (existing_campaign && existing_campaign.objective.value === "REACH")) {
                adgroup_object.objective = { id: 3, name: "Reach", value: "REACH" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "Max reach (recommended)", value: "MAX_REACH" };
                adgroup_object.goals = [
                    { id: 1, name: "Max reach (recommended)", value: "MAX_REACH" },
                    { id: 2, name: "Reach with engagement", value: "REACH_WITH_ENGAGEMENT" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Impressions", value: "IMPRESSION" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Impressions", value: "IMPRESSION" },
                ];
            }

            //FOLLOWERS
            if ((sub_option && sub_option.value === "followers") || (existing_campaign && existing_campaign.objective.value === "FOLLOWERS")) {
                adgroup_object.objective = { id: 4, name: "Followers", value: "FOLLOWERS" };
                adgroup_object.goal = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "Followers", value: "FOLLOWERS" };
                adgroup_object.goals = [
                    { id: 1, name: "Followers", value: "FOLLOWERS" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Followers", value: "FOLLOWERS" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Followers", value: "FOLLOWERS" },
                ];
            }

            //ENGAGEMENTS
            if ((sub_option && sub_option.value === "engagements") || (existing_campaign && existing_campaign.objective.value === "ENGAGEMENTS")) {
                adgroup_object.objective = { id: 5, name: "Engagements", value: "ENGAGEMENTS" };
                adgroup_object.goal = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "Engagement", value: "ENGAGEMENT" };
                adgroup_object.goals = [
                    { id: 1, name: "Engagement", value: "ENGAGEMENT" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Engagement", value: "ENGAGEMENT" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Engagement", value: "ENGAGEMENT" },
                ];
            }

            //VIDEO VIEWS
            if ((sub_option && sub_option.value === "video_views") || (existing_campaign && existing_campaign.objective.value === "VIDEO_VIEWS")) {
                adgroup_object.objective = { id: 6, name: "Video views", value: "VIDEO_VIEWS" };
                adgroup_object.goal = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "15s video views (recommended)", value: "VIEW_15S" };
                adgroup_object.goals = [
                    { id: 1, name: "15s video views (recommended)", value: "VIEW_15S" },
                    { id: 2, name: "Video views", value: "VIDEO_VIEW" },
                    { id: 3, name: "3s/100% video views", value: "VIEW_3S_100PCT" },
                    { id: 4, name: "6s video views", value: "VIEW_6S" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Impressions", value: "IMPRESSION" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Impressions", value: "IMPRESSION" },
                ];
            }

            //WEBSITE CLICKS
            if ((sub_option && sub_option.value === "website_clicks") || (existing_campaign && existing_campaign.objective.value === "WEBSITE_CLICKS")) {
                adgroup_object.objective = { id: 8, name: "Website clicks", value: "WEBSITE_CLICKS" };
                adgroup_object.goal = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "Site visits (recommended)", value: "SITE_VISITS" };
                adgroup_object.goals = [
                    { id: 1, name: "Site visits (recommended)", value: "SITE_VISITS" },
                    { id: 2, name: "Link clicks", value: "LINK_CLICKS" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Impressions (recommended)", value: "IMPRESSION" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Impressions (recommended)", value: "IMPRESSION" },
                    { id: 2, name: "Link click", value: "LINK_CLICK" }
                ];
            }

            this.state.adgroups.push(adgroup_object);

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });

            await this.form.set();
            this.props.setSteps();
        },
        onNewSnapchat: async (existing_campaign, sub_option) => {
            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);
            if (!existing_campaign) {

                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    campaign_type_property: "regular",
                    level: 'campaign',
                    channel: "snapchat",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                    status: { id: 2, name: "Paused", value: "PAUSED" },

                };


                if (sub_option && sub_option.value === "AWARENESS_AND_ENGAGEMENT") {
                    campaign_object.objective = { id: 1, name: 'Awareness & Engagement', value: "AWARENESS_AND_ENGAGEMENT" };
                }

                if (sub_option && sub_option.value === "TRAFFIC") {
                    campaign_object.objective = { id: 4, name: 'Traffic', value: "TRAFFIC" };
                }

                if (sub_option && sub_option.value === "LEADS") {
                    campaign_object.objective = { id: 8, name: 'Leads', value: "LEADS" };
                }

                if (sub_option && sub_option.value === "APP_PROMOTION") {
                    campaign_object.objective = { id: 3, name: 'App Promotion', value: "APP_PROMOTION" };
                    campaign_object.promotion_type = { id: 1, name: 'App Install', value: "APP_INSTALL" };
                }
                /*
                if (sub_option && sub_option.value === "awareness") {
                    campaign_object.objective = { id: 1, name: 'Awareness', value: "BRAND_AWARENESS" };
                }
                if (sub_option && sub_option.value === "promote_places") {
                    campaign_object.objective = { id: 2, name: 'Promote places', value: "PROMOTE_PLACES" };
                }
                if (sub_option && sub_option.value === "app_installs") {
                    campaign_object.objective = { id: 3, name: 'App installs', value: "APP_INSTALL" };
                }
                if (sub_option && sub_option.value === "traffic_website") {
                    campaign_object.objective = { id: 4, name: 'Drive Traffic To Website', value: "BRAND_AWARENESS" };
                }
                if (sub_option && sub_option.value === "traffic_app") {
                    campaign_object.objective = { id: 5, name: 'Drive Traffic To App', value: "BRAND_AWARENESS" };
                }
                if (sub_option && sub_option.value === "engagement") {
                    campaign_object.objective = { id: 6, name: 'Engagement', value: "ENGAGEMENT" };
                }
                if (sub_option && sub_option.value === "video_views") {
                    campaign_object.objective = { id: 7, name: 'Video Views', value: "VIDEO_VIEW" };
                }
                if (sub_option && sub_option.value === "lead_generation") {
                    campaign_object.objective = { id: 8, name: 'Lead Generation', value: "LEAD_GENERATION" };
                }
                if (sub_option && sub_option.value === "app_conversions") {
                    campaign_object.objective = { id: 9, name: 'App Conversions', value: "APP_CONVERSION" };
                }
                if (sub_option && sub_option.value === "website_converstions") {
                    campaign_object.objective = { id: 10, name: 'Website Conversions', value: "WEBSITE_CONVERSION" };
                }
                if (sub_option && sub_option.value === "catalog_sales") {
                    campaign_object.objective = { id: 11, name: 'Catalog Sales', value: "CATALOG_SALES" };
                }
                */
                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "snapchat",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                status: { id: 1, name: "Active", value: "ACTIVE" },
                billing_event: { id: 1, name: "Impressions", value: "IMPRESSION" },
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? this.state.order.startDate : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? this.state.order.endDate : moment().format("YYYY-MM-DD"),
                name: "Adset",
                // placement_v2 
                targeting: {},
                type: { id: 1, name: "Snap Ads", value: "SNAP_ADS" },
                min_age: { id: 18, name: "18", value: 18 },
                max_age: { id: 50, name: "50+", value: 50 },
                bid_strategy: { id: 1, name: "Auto-Bid (Recommended)", value: "AUTO_BID" },
                budget_mode: { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAILY" },
                gender: { id: 1, name: "All genders", value: "ALL" },
                auto_placements: { id: 1, name: "Yes", value: true },
                auto_placements: { id: 1, name: "Yes", value: true },

            };

            if (this.state.wizard.partial) {
                adgroup_object.partial = true;
            }
            if (this.state.wizard.scheduler) {
                adgroup_object.scheduler = this.state.wizard.scheduler;
                if (this.state.wizard.scheduler.schedule) {
                    adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                }
                adgroup_object.scheduler.order_info = this.state.order;
            }

            //AWARENESS
            if ((sub_option && sub_option.value === "AWARENESS_AND_ENGAGEMENT") || (existing_campaign && (existing_campaign.objective.value === "BRAND_AWARENESS" || existing_campaign.objective === "BRAND_AWARENESS" || existing_campaign.objective === "AWARENESS_AND_ENGAGEMENT" || existing_campaign.objective === "AWARENESS_AND_ENGAGEMENT"))) {
                adgroup_object.campaign_objective = { value: "AWARENESS_AND_ENGAGEMENT" };
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };



            }
            // TRAFFIC
            if ((sub_option && sub_option.value === "TRAFFIC") || (existing_campaign && (existing_campaign.objective.value === "WEB_VIEW" || existing_campaign.objective === "WEB_VIEW" || existing_campaign.objective.value === "TRAFFIC" || existing_campaign.objective === "TRAFFIC"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "TRAFFIC" };
            }

            // LEADS
            if ((sub_option && sub_option.value === "LEADS") || (existing_campaign && (existing_campaign.objective.value === "LEAD_GENERATION" || existing_campaign.objective === "LEAD_GENERATION" || existing_campaign.objective.value === "LEADS" || existing_campaign.objective === "LEADS"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "LEADS" };
            }

            // APP INSTALLS
            if ((sub_option && sub_option.value === "APP_PROMOTION") || (existing_campaign && existing_campaign.objective.value === "APP_PROMOTION")) {
                adgroup_object.optimization_goal = { id: 3, name: 'App installs', value: "APP_INSTALLS" };
                adgroup_object.campaign_objective = { value: "APP_PROMOTION" };
            }

            //PROMOTE PLACES
            /*
            if ((sub_option && sub_option.value === "promote_places") || (existing_campaign && (existing_campaign.objective.value === "PROMOTE_PLACES" || existing_campaign.objective === "PROMOTE_PLACES"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "PROMOTE_PLACES" };
            }*/


            // WEBSITE CONVERSIONS
            /*
            if ((sub_option && sub_option.value === "web_conversion") || (existing_campaign && (existing_campaign.objective.value === "WEB_CONVERSION" || existing_campaign.objective === "WEB_CONVERSION"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "WEB_CONVERSION" };
                // let response = await this.functions.getSnapchatPixel();
                // adgroup_object.snapchat_pixel = response.data
                // adgroup_object.snapchat_pixel_string = snapchat_pixel_data[0].name;
            }
            */

            // VIDEO VIEWS
            /*
            if ((sub_option && sub_option.value === "video_views") || (existing_campaign && (existing_campaign.objective.value === "VIDEO_VIEWS" || existing_campaign.objective === "VIDEO_VIEWS"))) {
                adgroup_object.optimization_goal = { id: 5, name: '15 second video view', value: "VIDEO_VIEWS_15_SEC" };
                adgroup_object.campaign_objective = { value: "VIDEO_VIEWS" };
            }
            */
            // ENGAGEMENT
            /*
            if ((sub_option && sub_option.value === "engagement") || (existing_campaign && (existing_campaign.objective.value === "ENGAGEMENT" || existing_campaign.objective === "ENGAGEMENT"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "ENGAGEMENT" };
            }
            */

            // ADD MORE SUB TYPES HERE

            this.state.adgroups.push(adgroup_object);

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            await this.form.set();
            this.props.setSteps();
        },

        onSubmit: () => {
            try {
                if (this.state.update.level === "campaign") {
                    this.refs.CreateOrderCampaign.functions.update();
                } else if (this.state.update.level === "adgroup") {
                    this.refs.CreateOrderAdgroup.functions.update();
                } else if (this.state.update.level === "ad") {
                    if (this.state.update.type === "search_ad") {
                        this.refs.CreateOrderCreativeSearchAd.functions.save();
                    } else if (this.state.update.type === "keywords") {
                        this.refs.CreateOrderKeywords.functions.save();
                    } else if (this.state.update.type === "extension") {
                        this.refs.CreateOrderCreativeSearchExtension.functions.save();
                    } else if (this.state.update.type === "performance_max") {
                        this.refs.CreateOrderCreativePerformanceMax.functions.save();
                    } else if (this.state.update.type === "display") {
                        this.refs.CreateOrderCreativeDisplay.functions.save();
                    } else {
                        this.refs.CreateOrderAd.functions.update();
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        onUpdateCampaign: async (updateItem) => {
            if (this.state.update.id && this.state.update.id !== "multiple") {
                this.state.campaigns = this.state.campaigns.map((inner_item) => {
                    if (inner_item.id === updateItem.id) {
                        inner_item = updateItem;
                    }
                    return inner_item;
                })
            } else {
                this.state.campaigns = this.state.campaigns.map((inner_item) => {
                    this.state.update.ids.map((id) => {
                        if (inner_item.id === id) {
                            delete updateItem.id;
                            inner_item = { ...inner_item, ...updateItem };
                        }
                    });
                    return inner_item;
                })
            }
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                update: {}
            });
            await this.form.set();
            this.props.setSteps();
        },
        onUpdateAdgroup: async (updateItem, update_ads = false) => {
            if (this.state.update.id && this.state.update.id !== "multiple") {
                this.state.adgroups = this.state.adgroups.map((inner_item) => {
                    if (inner_item.id === updateItem.id) {
                        inner_item = updateItem;
                    }
                    return inner_item;
                })
                if (update_ads) {
                    this.state.ads = this.state.ads.map((inner_item) => {
                        if (inner_item.adgroup === updateItem.id) {
                            inner_item.adgroup_info = updateItem;
                        }
                        return inner_item;
                    })
                    await this.promisedSetState({
                        ads: this.state.ads
                    });
                }
            } else {
                this.state.adgroups = this.state.adgroups.map((inner_item) => {
                    this.state.update.ids.map((id) => {
                        if (inner_item.id === id) {
                            delete updateItem.id;
                            inner_item = { ...inner_item, ...updateItem };
                        }
                    });
                    return inner_item;
                })
            }
            await this.promisedSetState({
                adgroups: this.state.adgroups,
                update: {}
            });
            await this.form.set();
            this.props.setSteps();
        },
        onUpdateAd: async (updateItem) => {
            if (this.state.update.id && this.state.update.id !== "multiple") {
                if (updateItem.campaign && updateItem.channel === "google" && !updateItem.adgroup) {
                    this.state.campaigns = this.state.campaigns.map((inner_item) => {
                        if (inner_item.id === updateItem.campaign) {
                            inner_item.asset = inner_item.asset.map((asset) => {
                                if (asset.id === updateItem.id) {
                                    asset = updateItem;
                                }
                                return asset;
                            })
                        }
                        return inner_item;
                    })
                    await this.promisedSetState({
                        campaigns: this.state.campaigns,
                    })
                } else {
                    this.state.ads = this.state.ads.map((inner_item) => {
                        if (inner_item.id === updateItem.id) {
                            inner_item = updateItem;
                        }
                        return inner_item;
                    })
                }
            } else {
                this.state.ads = this.state.ads.map((inner_item) => {
                    this.state.update.ids.map((id) => {
                        if (inner_item.id === id) {
                            delete updateItem.id;
                            inner_item = { ...inner_item, ...updateItem };
                        }
                    });
                    return inner_item;
                })
            }
            // await this.functions.reparseAdData(updateItem);
            await this.promisedSetState({
                ads: this.state.ads,
                update: {},
            })
            await this.form.set();
            this.props.setSteps();
        },
        property: (key, subkey, level, currency = false) => {
            let value = "";
            let selected = this.state[level].filter((item) => { return item && item.selected && item.channel === this.props.channel });
            if (selected.length > 1) {
                value = "-";
            } else {
                let i = 0;
                if (selected[i]) {
                    if (selected[i][key] && ((typeof selected[i][key] === 'object' && selected[i][key] instanceof moment) || typeof selected[i][key] === 'boolean' || typeof selected[i][key] === 'string' || typeof selected[i][key] === 'number')) {
                        value = selected[i][key];
                        if (currency) {
                            let currencyCode = this.state.client.channels.filter((inner_item) => { return inner_item.value === this.props.channel }).length > 0 ? this.state.client.channels.filter((inner_item) => { return inner_item.value === this.props.channel }) : [{ currency: "" }]
                            if (this.props.channel === "google" && level === "campaigns" && selected[i].shared_budget) {
                                value = selected[i].shared_budget.amount + " " + currencyCode[0].currency
                            } else value = value + " " + currencyCode[0].currency
                        }
                    } else if (Array.isArray(selected[i][key]) && selected[i][key]) {
                        value = selected[i][key].filter((item) => { return item });
                    } else if (subkey && selected[i][key] && subkey in selected[i][key]) {
                        value = selected[i][key][subkey];
                    } else {
                        value = "-";
                    }
                }
            }
            return value;
        },
        updateWizard: async () => {
            await this.promisedSetState({ loading_wizard: true });
            try {
                await this.calls.updateWizard({
                    campaigns: this.state.campaigns,
                    adgroups: this.state.adgroups,
                    ads: this.state.ads,
                    channels: this.state.channels,
                    creatives: this.state.creatives,
                    assetItems: this.state.assetItems,
                });
                this.refs.SideNotification.functions.trigger();
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        // snapchatAttachmentError: () => {
        //     // channels === "snapchat" causes other channels to crash, also spills over to other adgroups even if they are correct
        //     if (
        //         // this.state.channels === "snapchat" &&
        //         (Array.isArray(this.state.adgroups) &&
        //             this.state.adgroups.length > 0 &&
        //             this.state.adgroups.filter(item => item.goal.value === "SWIPES").length > 0)
        //         &&
        //         (Array.isArray(this.state.ads) &&
        //             this.state.ads.length > 0 &&
        //             this.state.ads.filter(item => item.file.snapchat_attachment_error).length > 0)) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // },
        // getSnapGoal: () => {
        //     // channels === "snapchat" causes other channels to crash, also spills over to other adgroups even if they are correct
        //     if ((Array.isArray(this.state.adgroups) && this.state.adgroups.length > 0)) {
        //         // this.state.adgroups.filter(item => item.channel === "snapchat").length > 0)) {
        //         return this.state.adgroups.filter(item => item.channel === "snapchat")[0].goal.value;
        //     }
        // },
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        },
        // reparseAdData: async (data) => {
        //     console.log("Reparsing data...", data)
        //     let buff = data.files ? data.files : data.file;
        //     let preview_data = {}
        //     if (buff && Object.keys(buff).length > 0) {
        //         preview_data.preview_channel = this.props.channel
        //         preview_data.channel_menu = this.props.channel
        //         preview_data.utm_channels = [this.props.channel]
        //         preview_data.validation = { [this.props.channel]: true }
        //         data.files ? preview_data.files = buff : preview_data.files = [buff];
        //         preview_data.body_by_channels = buff.body_by_channels
        //         preview_data.caption = buff.caption
        //         preview_data.carousel = buff.carousel ? buff.carousel : null
        //         // preview_data.cta = this.state.update && this.state.update.cta ? { [this.props.channel]: this.state.update.cta } : buff.cta
        //         preview_data.cta = buff.cta
        //         preview_data.description = buff.description
        //         preview_data.headline_by_channels = buff.headline_by_channels
        //         preview_data.merge = buff.merge ? buff.merge : null
        //         preview_data.page = buff.page ? buff.page : null
        //         preview_data.previews = {
        //             "facebook": [
        //                 {
        //                     "id": 1,
        //                     "value": "feed",
        //                     "name": "Feed"
        //                 },
        //                 {
        //                     "id": 2,
        //                     "value": "story",
        //                     "name": "Story"
        //                 }
        //             ],
        //             "instagram": [
        //                 {
        //                     "id": 1,
        //                     "value": "feed",
        //                     "name": "Feed"
        //                 },
        //                 {
        //                     "id": 2,
        //                     "value": "story",
        //                     "name": "Story"
        //                 }
        //             ],
        //             "twitter": [
        //                 {
        //                     "id": 1,
        //                     "value": "feed",
        //                     "name": "Feed"
        //                 }
        //             ],
        //             "linkedin": [
        //                 {
        //                     "id": 1,
        //                     "value": "feed",
        //                     "name": "Feed"
        //                 }
        //             ],
        //             "tiktok": [
        //                 {
        //                     "id": 1,
        //                     "value": "feed",
        //                     "name": "Feed"
        //                 }
        //             ],
        //             "snapchat": [
        //                 {
        //                     "id": 1,
        //                     "value": "feed",
        //                     "name": "Stories"
        //                 },
        //                 {
        //                     "id": 2,
        //                     "value": "feed",
        //                     "name": "Spotlight"
        //                 }
        //             ]
        //         }
        //         preview_data.snapchat_attachment = buff.snapchat_attachment
        //         preview_data.snapchat_autofill = buff.snapchat_autofill
        //         preview_data.snapchat_brandname = buff.snapchat_brandname
        //         preview_data.snapchat_message = buff.snapchat_message
        //         preview_data.snapchat_phone = buff.snapchat_phone
        //         preview_data.snapchat_prefetching = buff.snapchat_prefetching
        //         preview_data.snapchat_shareable = buff.snapchat_shareable
        //         preview_data.snapchat_status = buff.snapchat_status
        //         preview_data.website_by_channels = buff.website_by_channels
        //     }

        //     await this.promisedSetState({
        //         preview_data: preview_data
        //     });

        // },
        // handlePreviewData: async (data) => {
        //     console.log("preview_data incoming data: ", data)
        //     await this.functions.reparseAdData(data);
        // }
    };

    renders = {
        campaignAsset: () => {
            let selected = this.state.campaigns.filter((item) => { return item && item.selected && item.asset && item.asset.length > 0 && item.channel === this.props.channel });

            console.log("selected", selected)
            if (selected.length > 0) {
                selected = selected[0];
                console.log("selected", selected)
                return (
                    <Fragment>
                        <dl className="flex flex-1 flex-col">
                            <div class="font-semibold text-gray-900 my-2">Campaign asset</div>
                            {
                                this.state.ad_options.filter((item) => {
                                    return item.channels.filter((item) => { return item == this.props.channel }).length > 0
                                }).filter((option) => {
                                    if (this.props.channel === "google" && Array.isArray(selected.asset)) {
                                        return selected.asset.filter((buff) => {
                                            return buff.ad_type && buff.ad_type.value === option.ad_type
                                        }).length > 0
                                    } else {
                                        return true
                                    }
                                }).map((option) => {
                                    return (
                                        // <div className="border-t-1.5 p-4 flex flex-row items-center">
                                        <div className="border-t-1.5 flex flex-1 py-2 items-center">
                                            <div className="flex flex-row px-2"
                                                style={{ width: ([19, 20].includes(option.id) ? 200 : 110) }}
                                            >
                                                <option.icon className="h-6 w-6 text-purple-500" />
                                                <div style={{ paddingTop: "1px" }} className="text-sm font-medium ml-2">
                                                    {option.name}
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-row flex-wrap">
                                                {
                                                    selected.asset.filter((item) => {
                                                        return item.ad_type && item.ad_type.value === option.ad_type
                                                    }).map((item) => {
                                                        return (
                                                            <div onClick={async () => {
                                                                if (!item.locked) {
                                                                    this.state.campaigns = this.state.campaigns.map((inner_item) => {
                                                                        if (inner_item.id == selected.id && inner_item.asset) {
                                                                            inner_item.asset = inner_item.asset.map((asset) => {
                                                                                if (asset.id == item.id) {
                                                                                    asset.selected = !asset.selected;
                                                                                } else {
                                                                                    asset.selected = false;
                                                                                }
                                                                                return asset;
                                                                            })

                                                                        }
                                                                        return inner_item;
                                                                    });
                                                                    this.state.ads = this.state.ads.map((inner_item) => {
                                                                        inner_item.selected = false;
                                                                        return inner_item;
                                                                    });

                                                                    await this.promisedSetState({
                                                                        campaigns: this.state.campaigns,
                                                                        ads: this.state.ads
                                                                    });
                                                                }
                                                            }} className={
                                                                (item.selected ? "border-purple-500" : "")
                                                                + " flex cursor-pointer flex-row border-1.5 bg-gray-50 overflow-hidden rounded-md my-1 mr-2"}>
                                                                <div className={"w-12 h-12 overflow-hidden mr-2 relative"}>
                                                                    {
                                                                        item.locked &&
                                                                        <div className="bg-gray-100 bg-opacity-75 absolute left-0 z-40 top-0 bottom-0 right-0 flex justify-center items-center">
                                                                            <LockClosedIcon className="w-5 h-5" />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        item.ad_type && item.ad_type.value === "keywords" &&
                                                                        <div className=" w-full h-full flex flex-col justify-center items-center text-xs font-medium">
                                                                            {Array.isArray(item.keywords) ? item.keywords.length : 0}
                                                                            <div className="text-xxs">
                                                                                {(item.negative && item.negative.value) ? "Negative" : "Positive"}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        item.ad_type && item.ad_type.value === "extension" &&
                                                                        <div className=" w-full h-full flex flex-col justify-center items-center text-xs font-medium">
                                                                            {
                                                                                item.picture &&
                                                                                <img src={item.picture} />
                                                                            }
                                                                            {
                                                                                !item.picture &&
                                                                                <div>{Array.isArray(item.items) ? item.items.length : 0} </div>
                                                                            }
                                                                            <div className="text-xxs">
                                                                                {
                                                                                    item.subtype && item.subtype.value === "call" &&
                                                                                    <span>Numbers</span>
                                                                                }
                                                                                {
                                                                                    item.subtype && item.subtype.value === "sitelink" &&
                                                                                    <span>Links</span>
                                                                                }
                                                                                {
                                                                                    item.subtype && item.subtype.value === "price" &&
                                                                                    <span>Price</span>
                                                                                }
                                                                                {
                                                                                    item.subtype && item.subtype.value === "promotion" &&
                                                                                    <span>Promotions</span>
                                                                                }
                                                                                {
                                                                                    item.subtype && item.subtype.value === "snippet" &&
                                                                                    <span>Snippets</span>
                                                                                }
                                                                                {
                                                                                    item.subtype && item.subtype.value === "callout" &&
                                                                                    <span>Callouts</span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="flex-1 flex pr-2 items-center justify-start">
                                                                    <div className='flex flex-col'>
                                                                        <div className='flex'>
                                                                            <div className="text-sm font-semibold flex flex-1 w-24">
                                                                                <div className=' truncate'>
                                                                                    {item.ad_type && item.ad_type.value === "catalog_ad" && item.collection_ad ? "Collection Ad" : (item.name ? item.name : "Asset")}
                                                                                    { }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-xs text-gray-900">#{item.id}</div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.functions.validation(item, "ad") &&

                                                                    <div className='text-red-600 items-center justify-center flex'>
                                                                        <ExclamationIcon className="h-6 w-6 text-red-600 flex" />
                                                                    </div>
                                                                }

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='pr-2'>
                                                <button
                                                    onClick={() => {

                                                        this.functions.onEdit("ad", true);
                                                    }}
                                                    className={(selected.asset.filter((item) => {
                                                        if (item.selected && (item.ad_type.type === "video") && item.ad_type.value === "merge_ad" && option.ad_type === "video_ad" && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0) {
                                                            return true
                                                        } else if (item.selected && item.ad_type.type === "image" && item.ad_type.value === "merge_ad" && option.ad_type === "image_ad" && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0) {
                                                            return true
                                                        } else {
                                                            return item.selected && (item.ad_type.value === option.ad_type || item.type === option.ad_type) && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0
                                                        }
                                                    }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + "  text-white shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    <PencilAltIcon className="h-5 w-5" />
                                                </button>
                                                {
                                                    <button
                                                        onClick={async () => {
                                                            selected.asset = selected.asset.filter((item) => {
                                                                if (item.selected && (item.ad_type.type === "video") && item.ad_type.value === "merge_ad" && option.ad_type === "video_ad" && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0) {
                                                                    return false
                                                                } else if (item.selected && item.ad_type.type === "image" && item.ad_type.value === "merge_ad" && option.ad_type === "image_ad" && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0) {
                                                                    return false
                                                                } else if (item.selected && (item.ad_type.value === option.ad_type || item.type === option.ad_type) && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0) {
                                                                    return false;
                                                                } else {
                                                                    return true;
                                                                }
                                                            })
                                                            // this.functions.snapchatAttachmentError();
                                                            this.state.campaigns = this.state.campaigns.map((inner_item) => {
                                                                if (inner_item.id == selected.id) {
                                                                    inner_item.asset = selected.asset;
                                                                }
                                                                return inner_item;
                                                            })
                                                            await this.promisedSetState({
                                                                campaigns: this.state.campaigns
                                                            });
                                                            this.form.set();
                                                        }}
                                                        className={(selected.asset.filter((item) => {
                                                            if (item.selected && (item.ad_type.type === "video") && item.ad_type.value === "merge_ad" && option.ad_type === "video_ad" && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0) {
                                                                return true
                                                            } else if (item.selected && item.ad_type.type === "image" && item.ad_type.value === "merge_ad" && option.ad_type === "image_ad" && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0) {
                                                                return true
                                                            } else {
                                                                return item.selected && (item.ad_type.value === option.ad_type || item.type === option.ad_type) && this.state.campaigns.filter((campaign) => { return campaign.id === selected.id && campaign.selected && campaign.channel === this.props.channel }).length > 0
                                                            }
                                                        }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + "  text-white ml-2 shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"}
                                                    >
                                                        <TrashIcon className="h-5 w-5" />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </dl>
                    </Fragment>
                )
            } else {
                return;
            }
        },
        nextButtonLabel: () => {
            if (this.props.channel === 'facebook') {
                if (this.state.channels['twitter']) {
                    return 'twitter';
                } else if (this.state.channels['tiktok']) {
                    return 'tiktok';
                } else if (this.state.channels['linkedin']) {
                    return 'linkedin';
                } else if (this.state.channels['snapchat']) {
                    return 'snapchat';
                } else {
                    return 'preview';
                }
            } else if (this.props.channel === 'twitter') {
                if (this.state.channels['tiktok']) {
                    return 'tiktok';
                } else if (this.state.channels['linkedin']) {
                    return 'linkedin';
                } else if (this.state.channels['snapchat']) {
                    return 'snapchat';
                } else {
                    return 'preview';
                }
            } else if (this.props.channel === 'tiktok') {
                if (this.state.channels['linkedin']) {
                    return 'linkedin';
                } else if (this.state.channels['google']) {
                    return 'google';
                } else if (this.state.channels['snapchat']) {
                    return 'snapchat';
                } else {
                    return 'preview';
                }
            } else if (this.props.channel === 'linkedin') {
                if (this.state.channels['google']) {
                    return 'google';
                } else if (this.state.channels['snapchat']) {
                    return 'snapchat';
                } else {
                    return 'preview';
                }
            } else if (this.props.channel === 'snapchat') {
                return 'preview';
            } else {
                return 'preview';
            }
        },
        backButtonLabel: () => {
            if (this.props.channel === 'facebook') {
                return 'creatives';
            } else if (this.props.channel === 'twitter') {
                if (this.state.channels['facebook']) {
                    return 'facebook';
                } else {
                    return 'creatives';
                }
            } else if (this.props.channel === 'tiktok') {
                if (this.state.channels['twitter']) {
                    return 'twitter';
                } else if (this.state.channels['facebook']) {
                    return 'facebook';
                } else if (this.state.channels['snapchat']) {
                    return 'snapchat';
                } else {
                    return 'creatives';
                }
            } else if (this.props.channel === 'linkedin') {
                if (this.state.channels['tiktok']) {
                    return 'tiktok';
                } else if (this.state.channels['twitter']) {
                    return 'twitter';
                } else if (this.state.channels['facebook']) {
                    return 'facebook';
                } else if (this.state.channels['snapchat']) {
                    return 'snapchat';
                } else {
                    return 'creatives';
                }
            } else if (this.props.channel === 'google') {
                if (this.state.channels['linkedin']) {
                    return 'linkedin';
                } else if (this.state.channels['tiktok']) {
                    return 'tiktok';
                } else if (this.state.channels['twitter']) {
                    return 'twitter';
                } else if (this.state.channels['facebook']) {
                    return 'facebook';
                } else if (this.state.channels['snapchat']) {
                    return 'snapchat';
                } else {
                    return 'creatives';
                }
            } else if (this.props.channel === 'snapchat') {
                if (this.state.channels['linkedin']) {
                    return 'linkedin';
                } else if (this.state.channels['tiktok']) {
                    return 'tiktok';
                } else if (this.state.channels['twitter']) {
                    return 'twitter';
                } else if (this.state.channels['facebook']) {
                    return 'facebook';
                } else {
                    return 'creatives';
                }
            } else {
                return 'creatives';
            }
        }
    };

    calls = {
        updateWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateWizard?id=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        getAgency: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgency?" + "client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState, dontSave) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative">
                <div className="sm:sticky sm:top-0 z-50">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "cancel") {
                                draftRegister.remove();
                                if (this.state.wizard.template) {
                                    this.props.history.push("/v2/templates");
                                } else {
                                    this.props.history.push("/v2/orders/" + this.state.order.id + "/drafts");
                                }
                            } else if (type === "save") {
                                this.functions.updateWizard();
                            }
                        }}
                        buttons={[
                            { text: "Cancel", icon: XIcon, value: "cancel", disabled: this.state.loading_wizard || this.state.loading_template, loading: false, show: !this.state.loading, red: true },
                            { text: this.state.wizard.template ? "Save template" : "Save draft", icon: SaveIcon, value: "save", disabled: this.state.loading_template, loading: this.state.loading_wizard, show: !this.state.loading },
                        ]}
                        hideUserDropdown={true}
                        history={this.props.history}
                        breadcrumb={this.props.channel}
                        showClient={true}
                        lockClient={true}
                        client={this.state.client}
                    />
                </div>

                {/* SUCCESS NOTIFICATION*/}
                <SideNotification
                    ref={"SideNotification"}
                    icon={CheckIcon}
                    title={"Success"}
                    text={"Wizard updated"}
                />

                {/* NEW CAMPAIGN MODAL*/}
                <AddCampaignModal
                    open={this.state.new_campaign ? true : false}
                    campaign={typeof this.state.new_campaign === "object" ? this.state.new_campaign : false}
                    channel={this.props.channel}
                    onSubmit={(data) => {
                        if (this.props.channel === "linkedin") {
                            this.functions.onNewLinkedin(data.campaign, data.sub_option, data.creative_options);
                        } else if (this.props.channel === "google") {
                            this.functions.onNewGoogle(data.campaign, data.sub_option);
                        } else if (this.props.channel === "twitter") {
                            this.functions.onNewTwitter(data.campaign, data.sub_option);
                        } else if (this.props.channel === "tiktok") {
                            this.functions.onNewTikTok(data.campaign, data.sub_option);
                        } else if (this.props.channel === "facebook") {
                            this.functions.onNewFacebook(data.campaign, data.sub_option);
                        } else if (this.props.channel === "snapchat") {
                            this.functions.onNewSnapchat(data.campaign, data.sub_option);
                        }
                        this.setState({
                            new_campaign: false
                        })
                    }}
                    onClose={() => {
                        this.setState({
                            new_campaign: false
                        })
                    }}
                />

                {/*ERROR HANDLER*/}
                {
                    this.state.wizard.status === "error" &&
                    <div className="bg-white border-b p-4">
                        <div className="">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">There were {this.state.wizard.errors.filter((item) => {
                                        return item.channel === this.props.channel
                                    }).length} errors with your {this.props.channel} submission</h3>
                                    <div className="mt-2 text-sm text-red-700">
                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                            {
                                                this.state.wizard.errors.filter((item) => {
                                                    return item.channel === this.props.channel
                                                }).map((item) => {
                                                    return (
                                                        <li onClick={() => {
                                                            this.props.history.push("/v2/campaigns/new/" + item.channel + "?" + item.level + "=" + item[item.level]);
                                                        }}>{item.level} <span className="font-medium cursor-pointer hover:underline">#{item[item.level]}</span>: {item.message}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* SLIDEOUT UPDATE */}
                <SlideoutTailwind
                    title={this.state.update.level === "ad" && this.state.update.type === 'extension' ? this.state.update.subtype.name + " " + this.state.update.type : this.state.update.level}
                    submitButtonText={"Update"}
                    large={this.state.update.type !== 'search_ad' && this.state.update.type !== 'keywords' && this.state.update.type !== 'performance_max'}
                    full={this.state.update.type === 'search_ad' || this.state.update.type === 'keywords' || this.state.update.type === 'performance_max'}
                    secondaryButton={false}
                    reach={this.state.update.reach}
                    open={this.state.update.id && !this.state.new_asset_slideout ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ update: {}, ai_result: [], keywordLists: false });
                        // this.functions.snapchatAttachmentError()

                    }}
                    onSubmit={() => {
                        this.promisedSetState({ ai_result: [], keywordLists: false });
                        this.functions.onSubmit();
                        // this.functions.snapchatAttachmentError()

                    }}
                    keywordList={this.state.keywordLists ? this.state.keywordLists : false}
                    search_ad={this.state.update.type === 'search_ad' ? true : false}
                    group={this.state.update.type === 'search_ad' ? this.state.update : false}
                    client={this.state.client}
                    onAddKeyword={(person) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.addKeyword(person);
                    }}
                    onAddHeadlines={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.addHeadlines(data);
                    }}
                    onAddDescriptions={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.addDescriptions(data);
                    }}
                    onUpdateChat={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.updateOpenAi(data);
                    }}
                    onRemoveDescription={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.removeDescription(data);
                    }}
                    onRemoveHeadline={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.removeHeadline(data);
                    }}
                    open_chat={false}
                    chat={this.state.ai_result ? this.state.ai_result : []}
                >
                    {/* GOOGLE */}
                    {
                        this.state.update.id &&
                        this.props.channel === "google" &&
                        this.state.update.level === 'campaign' &&
                        <EditGoogleCampaign
                            ref="CreateOrderCampaign"
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            client={this.state.client}
                            campaign={{}}
                            onUpdate={(updateItem, adgroups) => {
                                this.functions.onUpdateCampaign(updateItem);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    this.promisedSetState({ adgroups: this.state.adgroups });
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "google" &&
                        this.state.update.level === 'adgroup' &&
                        <EditGoogleAdgroup
                            ref="CreateOrderAdgroup"
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            client={this.state.client}
                            onUpdate={async (updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "google" &&
                        this.state.update.level === 'ad' &&
                        this.state.update.type === 'search_ad' &&
                        <CreateOrderCreativeSearchAd
                            ref="CreateOrderCreativeSearchAd"
                            //groups={JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected && item.type === "search_ad" })))}
                            groups={JSON.parse(JSON.stringify(this.state.update))}
                            keywordLists={JSON.parse(JSON.stringify(this.state.ads.filter((item) => { return item.type === "keywords" && item.adgroup == this.state.update.adgroup })))}
                            client={this.state.client}
                            onUpdatePreview={async (data) => {
                                await this.promisedSetState({
                                    group: data
                                });
                            }}
                            onUpdateChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                });
                            }}
                            updateKeywordList={async (data) => {
                                await this.promisedSetState({
                                    keywordLists: data
                                });
                            }}
                            onSave={async (updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "google" &&
                        this.state.update.level === 'ad' &&
                        this.state.update.type === 'keywords' &&
                        <CreateOrderCreativeKeywords
                            ref="CreateOrderKeywords"
                            groups={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            onSave={async (updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "google" &&
                        this.state.update.level === 'ad' &&
                        this.state.update.type === 'extension' &&
                        <CreateOrderCreativeSearchExtension
                            ref="CreateOrderCreativeSearchExtension"
                            groups={[JSON.parse(JSON.stringify(this.state.update))]}
                            client={this.state.client}
                            onSave={async (updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "google" &&
                        this.state.update.level === 'ad' &&
                        this.state.update.type === 'performance_max' &&
                        <CreateOrderCreativePerformanceMax
                            wizard={this.state.wizard}
                            ref="CreateOrderCreativePerformanceMax"
                            groups={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            onSave={async (updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "google" &&
                        this.state.update.level === 'ad' &&
                        this.state.update.type === 'display' &&
                        <CreateOrderCreativeDisplay
                            wizard={this.state.wizard}
                            ref="CreateOrderCreativeDisplay"
                            groups={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            onSave={async (updateItem) => {
                                console.log("here we are updation")
                                this.functions.onUpdateAd(updateItem);
                            }}
                        />
                    }

                    {/* TIK TOK */}
                    {
                        this.state.update.id &&
                        this.props.channel === "tiktok" &&
                        this.state.update.level === 'campaign' &&
                        <EditTikTokCampaign
                            ref="CreateOrderCampaign"
                            wizard={true}
                            client={this.state.client}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            onUpdate={(updateItem, adgroups) => {
                                this.functions.onUpdateCampaign(updateItem);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    this.promisedSetState({ adgroups: this.state.adgroups });
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "tiktok" &&
                        this.state.update.level === 'adgroup' &&
                        <EditTikTokAdset
                            ref="CreateOrderAdgroup"
                            wizard={true}
                            campaign={JSON.parse(JSON.stringify(this.state.campaigns.filter((item) => { return item.id === this.state.update.campaign })))}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            adset={{}}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);
                            }}
                            onReach={(reach) => {
                                this.state.update.reach = reach;
                                this.setState({
                                    updated: this.state.update
                                })
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "tiktok" &&
                        this.state.update.level === 'ad' &&
                        <EditTikTokAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                        />
                    }

                    {/* TWITTER */}
                    {
                        this.state.update.id &&
                        this.props.channel === "twitter" &&
                        this.state.update.level === 'campaign' &&
                        <EditTwitterCampaign
                            ref="CreateOrderCampaign"
                            wizard={true}
                            client={this.state.client}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            onUpdate={(updateItem, adgroups) => {
                                this.functions.onUpdateCampaign(updateItem);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    this.promisedSetState({ adgroups: this.state.adgroups });
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "twitter" &&
                        this.state.update.level === 'adgroup' &&
                        <EditTwitterAdset
                            ref="CreateOrderAdgroup"
                            wizard={true}
                            campaign={JSON.parse(JSON.stringify(this.state.campaigns.filter((item) => { return item.id === this.state.update.campaign })))}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            adset={{}}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "twitter" &&
                        this.state.update.level === 'ad' &&
                        <EditTwitterAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                        />
                    }

                    {/* LINKEDIN */}
                    {
                        this.state.update.id &&
                        this.props.channel === "linkedin" &&
                        this.state.update.level === 'campaign' &&
                        <EditLinkedinCampaign
                            ref="CreateOrderCampaign"
                            wizard={true}
                            client={this.state.client}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            campaign={{}}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateCampaign(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "linkedin" &&
                        this.state.update.level === 'adgroup' &&
                        <EditLinkedinAdgroup
                            ref="CreateOrderAdgroup"
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            adset={{}}
                            adgroup={{}}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);
                            }}
                            onReach={(reach) => {
                                this.state.update.reach = reach;
                                this.setState({
                                    updated: this.state.update
                                })
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "linkedin" &&
                        this.state.update.level === 'ad' &&
                        <EditLinkedinAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                        />
                    }

                    {/* FACEBOOK */}
                    {
                        this.state.update.id &&
                        this.props.channel === "facebook" &&
                        this.state.update.level === 'campaign' &&
                        <EditFacebookCampaign
                            ref="CreateOrderCampaign"
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            client={JSON.parse(JSON.stringify(this.state.client))}
                            onUpdate={(updateItem, adgroups) => {
                                this.functions.onUpdateCampaign(updateItem);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    this.promisedSetState({ adgroups: this.state.adgroups });
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "facebook" &&
                        this.state.update.level === 'adgroup' &&
                        <EditFacebookAdset
                            ref="CreateOrderAdgroup"
                            wizard={true}
                            campaign={JSON.parse(JSON.stringify(this.state.campaigns.filter((item) => { return item.id === this.state.update.campaign })))}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            adset={{}}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);
                            }}
                            onReach={(reach) => {
                                this.state.update.reach = reach;
                                this.setState({
                                    updated: this.state.update
                                })
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "facebook" &&
                        this.state.update.level === 'ad' &&
                        <EditFacebookAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                        />
                    }

                    {/* SNAPCHAT */}
                    {
                        this.state.update.id &&
                        this.props.channel === "snapchat" &&
                        this.state.update.level === 'campaign' &&
                        <EditSnapchatCampaign
                            ref="CreateOrderCampaign"
                            wizard={true}
                            client={this.state.client}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            onUpdate={(updateItem, adgroups) => {
                                this.functions.onUpdateCampaign(updateItem);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    this.promisedSetState({ adgroups: this.state.adgroups });
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "snapchat" &&
                        this.state.update.level === 'adgroup' &&
                        <EditSnapchatAdset
                            ref="CreateOrderAdgroup"
                            wizard={true}
                            campaigns={JSON.parse(JSON.stringify(this.state.campaigns))}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            adset={{}}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem, true);
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "snapchat" &&
                        this.state.update.level === 'ad' &&
                        <EditSnapchatAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            // goal={this.functions.getSnapGoal()}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                        />
                    }
                </SlideoutTailwind>

                <SlideoutTailwindAsset
                    title={this.state.update.level === "ad" && this.state.update.files && this.state.update.files.length !== 0 ? "Carousel" : "Media"}
                    full={true}
                    preview_data={this.state.preview_data ? this.state.preview_data : false}
                    disabled={this.state.disabled}
                    submitButtonText={"Save"}
                    client={this.state.client}
                    open={this.state.new_asset_slideout}
                    noPadding={true}
                    onChannel={(channel) => {
                        // if (this.state.collection) {
                        //     this.refs.CreateOrderCreativeCollectionEdit.functions.swithChannel(channel);
                        // } else this.refs.CreateOrderCreativeEdit.functions.swithChannel(channel);
                    }}
                    onPreviewChannel={(channel) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderAd.functions.previewChannel(channel);
                        } else this.refs.CreateOrderAd.functions.previewChannel(channel);
                    }}
                    onUpdateChat={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderAd.functions.updateOpenAi(data);
                        } else this.refs.CreateOrderAd.functions.updateOpenAi(data);
                    }}
                    onAddHeadlines={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderAd.functions.addHeadlines(data);
                        } else this.refs.CreateOrderAd.functions.addHeadlines(data);
                    }}
                    onAddDescriptions={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderAd.functions.addDescriptions(data);
                        } else this.refs.CreateOrderAd.functions.addDescriptions(data);
                    }}
                    onRemoveDescription={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderAd.functions.removeDescription(data);
                        } else this.refs.CreateOrderAd.functions.removeDescription(data);
                    }}
                    onRemoveHeadline={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderAd.functions.removeHeadline(data);
                        } else this.refs.CreateOrderAd.functions.removeHeadline(data);
                    }}
                    chat={this.state.ai_result ? this.state.ai_result : []}
                    onCancel={() => {
                        this.promisedSetState({ new_asset_slideout: false, update: {}, preview_data: false, ai_result: false, updated: {} });
                        // this.state.creatives = this.state.creatives.map((item) => {
                        //     item.selected = false;
                        //     return item;
                        // });
                        // this.state.assetItems = this.state.assetItems.map((item) => {
                        //     item.selected = false;
                        //     return item;
                        // });
                        // this.promisedSetState({
                        //     assetItems: this.state.assetItems,
                        //     creatives: this.state.creatives,
                        //     open: false,
                        //     disabled: false,
                        //     temp_creatives: [],
                        //     collection: false,
                        //     selected_channels: [],
                        //     preview_data: false,
                        //     ai_result: false
                        // });
                    }}
                    onSubmit={async () => {
                        if (!this.state.collection) {
                            this.refs.CreateOrderAd.functions.update();
                            await this.promisedSetState({ new_asset_slideout: false, update: {}, preview_data: false });
                        } else {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.save();
                        }
                    }}
                    onSecondarySubmit={() => {
                    }}
                    changePreview={async (preview) => {
                        this.state.preview_data.preview = preview;
                        await this.promisedSetState({
                            preview_data: this.state.preview_data
                        });

                    }}
                >
                    {
                        this.state.update.id &&
                        this.props.channel === "facebook" &&
                        this.state.update.level === 'ad' &&
                        <EditFacebookAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            preview_data={this.state.preview_data ? this.state.preview_data : false}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                            updatePreview={async (data, update) => {
                                await this.promisedSetState({
                                    preview_data: data,
                                    update: update
                                });
                            }}
                            onUpdatedChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                })
                            }}
                        // onSelectPreview={async (data) => {
                        //     this.functions.
                        // }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "twitter" &&
                        this.state.update.level === 'ad' &&
                        <EditTwitterAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            preview_data={this.state.preview_data ? this.state.preview_data : false}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                            updatePreview={async (data, update) => {
                                await this.promisedSetState({
                                    preview_data: data,
                                    update: update
                                });
                            }}
                            onUpdatedChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                })
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "tiktok" &&
                        this.state.update.level === 'ad' &&
                        <EditTikTokAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            preview_data={this.state.preview_data ? this.state.preview_data : false}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                            updatePreview={async (data, update) => {
                                await this.promisedSetState({
                                    preview_data: data,
                                    update: update
                                });
                            }}
                            onUpdatedChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                })
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "linkedin" &&
                        this.state.update.level === 'ad' &&
                        <EditLinkedinAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            preview_data={this.state.preview_data ? this.state.preview_data : false}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                            updatePreview={async (data, update) => {
                                await this.promisedSetState({
                                    preview_data: data,
                                    update: update
                                });
                            }}
                            onUpdatedChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                })
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.props.channel === "snapchat" &&
                        this.state.update.level === 'ad' &&
                        <EditSnapchatAd
                            ref="CreateOrderAd"
                            client={this.state.client}
                            wizard={true}
                            wizardId={this.state.wizard.id}
                            assetItems={this.state.assetItems}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            ad={{}}
                            preview_data={this.state.preview_data ? this.state.preview_data : false}
                            onUpdate={(updateItem) => {
                                this.functions.onUpdateAd(updateItem);
                            }}
                            updateAssets={async (updateAssets) => {
                                await this.promisedSetState({ assetItems: updateAssets });
                                this.form.set();
                            }}
                            updatePreview={async (data, update) => {
                                await this.promisedSetState({
                                    preview_data: data,
                                    update: update
                                });
                            }}
                            onUpdatedChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                })
                            }}
                        />
                    }

                </SlideoutTailwindAsset>

                <div className="p-4">
                    <div className="rounded-lg flex flex-col">

                        {/* CAMPAIGN TABS */}
                        {
                            <div className="flex flex-1 flex-row">
                                {
                                    this.state.campaigns.filter((item) => {
                                        return item.channel === this.props.channel && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error"))
                                    }).map((campaign, index) => {
                                        return (
                                            <Fragment>
                                                <div onClick={async () => {
                                                    if (window.event && window.event.shiftKey) {
                                                        campaign.selected = !campaign.selected;
                                                    } else {
                                                        this.state.campaigns = this.state.campaigns.map((item) => {
                                                            item.selected = (item.id === campaign.id);
                                                            return item;
                                                        });
                                                        let first = true;
                                                        this.state.adgroups = this.state.adgroups.map((item) => {
                                                            if (first && item.campaign === campaign.id) {
                                                                item.selected = true;
                                                                first = false;
                                                            } else {
                                                                item.selected = false;
                                                            }
                                                            return item;
                                                        });
                                                    }
                                                    await this.promisedSetState({
                                                        campaigns: this.state.campaigns,
                                                        adgroups: this.state.adgroups,
                                                        ads: this.state.ads.map((item) => { item.selected = false; return item })
                                                    });
                                                }}
                                                    style={campaign.selected ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
                                                    className={(campaign.selected ? "bg-white" : "text-gray-300 hover:text-gray-700 bg-gray-50") + " shadow py-3 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                                    {this.state.internalChannels[campaign.channel] &&
                                                        <div className={`bg-${campaign.channel}-500 mr-3 w-6 h-6  rounded-full p-1.5`}>
                                                            <div className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require(`../assets/images/${this.state.internalChannels[campaign.channel].icon}`) + ")" }}></div>
                                                        </div>
                                                    }
                                                    <div className="flex flex-1 truncate">
                                                        <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                            {campaign.name}
                                                        </div>
                                                    </div>
                                                    {/*
                                                    <div className="flex">
                                                        {
                                                            campaign.channel === "facebook" &&
                                                            campaignFacebook.validate(campaign) &&
                                                            <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                        }
                                                        {
                                                            campaign.channel === "linkedin" &&
                                                            Object.keys(campaignLinkedin.validate(campaign)).map((item) => { return (campaignLinkedin.validate(campaign)[item] ? true : false) }).filter((item) => { return item }).length > 0 &&
                                                            <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                        }
                                                        {
                                                            campaign.channel === "google" &&
                                                            Object.keys(campaignGoogle.validate(campaign)).map((item) => { return (campaignGoogle.validate(campaign)[item] ? true : false) }).filter((item) => { return item }).length > 0 &&
                                                            <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                        }
                                                        {
                                                            campaign.channel === "tiktok" &&
                                                            campaignTiktok.validate(campaign) &&
                                                            <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                        }
                                                        {
                                                            campaign.channel === "snapchat" &&
                                                            Object.keys(campaignSnapchat.validate(campaign)).map((item) => { return (campaignSnapchat.validate(campaign)[item] ? true : false) }).filter((item) => { return item }).length > 0 &&
                                                            <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                        }
                                                        {
                                                            campaign.channel === "twitter" &&
                                                            campaignTwitter.validate(campaign) &&
                                                            <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                        }
                                                    </div>
                                                    */}
                                                    {
                                                        !this.state.wizard.partial && this.functions.validation(campaign, "campaign") &&
                                                        <div className="flex">
                                                            <div className='text-red-600 items-center justify-center flex'>
                                                                <ExclamationIcon className="h-4 w-4 text-red-600 flex" />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        <div onClick={async (e) => {
                                                            e.stopPropagation();
                                                            this.state.campaigns = this.state.campaigns.filter((item) => {
                                                                return item.id !== campaign.id;
                                                            });
                                                            this.state.adgroups = this.state.adgroups.filter((item) => {
                                                                return item.campaign !== campaign.id;
                                                            });
                                                            this.state.ads = this.state.ads.filter((item) => {
                                                                return item.campaign !== campaign.id;
                                                            });
                                                            await this.promisedSetState({
                                                                campaigns: this.state.campaigns,
                                                                adgroups: this.state.adgroups,
                                                                ads: this.state.ads.map((item) => { item.selected = false; return item })
                                                            });
                                                            if (campaign.selected) {
                                                                let selected_campaign = null;
                                                                this.state.campaigns = this.state.campaigns.map((item, inner_index) => {
                                                                    if ((index === 0 && inner_index === 0) || (inner_index === index - 1)) {
                                                                        selected_campaign = item;
                                                                        item.selected = true;
                                                                    }
                                                                    return item;
                                                                });
                                                                await this.promisedSetState({
                                                                    campaigns: this.state.campaigns,
                                                                    adgroups: this.state.adgroups
                                                                });
                                                                if (selected_campaign) {
                                                                    let first = true;
                                                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                                                        if (first && item.campaign === selected_campaign.id) {
                                                                            item.selected = true;
                                                                            first = false;
                                                                        } else {
                                                                            item.selected = false;
                                                                        }
                                                                        return item;
                                                                    });
                                                                    await this.promisedSetState({
                                                                        adgroups: this.state.adgroups
                                                                    });
                                                                }
                                                            }
                                                            this.form.set();
                                                        }} className={(this.state.campaigns.filter((item) => {
                                                            return item.channel === this.props.channel
                                                        }).length < 2 ? 'opacity-0' : 'opacity-100') + " border-1.5 -mr-2 h-5 w-5 rounded-full hover:text-red-500 flex justify-center items-center border-opacity-0 border-red-500 hover:border-opacity-100 hover:bg-red-100"}>
                                                            <XIcon className="h-4 w-4" />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="w-2"></div>
                                            </Fragment>
                                        )
                                    })
                                }
                                <div className="flex flex-1 flex-row">
                                    {
                                        !this.state.wizard.partial &&
                                        <div onClick={() => {
                                            //this.functions.onNew(this.props.channel);
                                            this.setState({
                                                new_campaign: true
                                            });
                                        }} className={"text-purple-500 hover:text-purple-700 cursor-pointer py-3 w-14 bg-white shadow flex justify-center items-center rounded-t-md mr-2"}>
                                            <PlusIcon className={"h-6 w-6"} />
                                        </div>
                                    }
                                    {
                                        !this.state.wizard.partial &&
                                        this.state.campaigns.filter((item) => {
                                            return item.selected && item.channel === this.props.channel && item.locked
                                        }).length < 1 &&
                                        <div onClick={() => {
                                            this.functions.onEdit("campaign");
                                        }} className={(this.state.campaigns.filter((item) => {
                                            return item.selected && item.channel === this.props.channel
                                        }).length > 0 ? "text-purple-500 hover:text-purple-700 cursor-pointer" : "text-gray-300  cursor-not-allowed") + " py-3 w-14 bg-white shadow flex justify-center items-center rounded-t-md mr-2"}>
                                            <PencilAltIcon className={"h-6 w-6"} />
                                        </div>
                                    }
                                    {
                                        !this.state.wizard.partial &&
                                        this.state.campaigns.filter((item) => {
                                            return item.selected && item.channel === this.props.channel && item.locked
                                        }).length < 1 &&
                                        <div onClick={() => {
                                            if (this.state.campaigns.filter((item) => {
                                                return item.selected && item.channel === this.props.channel
                                            }).length === 1) {
                                                this.functions.onClone("campaign");
                                            }
                                        }} className={(this.state.campaigns.filter((item) => {
                                            return item.selected && item.channel === this.props.channel
                                        }).length === 1 ? "text-purple-500 hover:text-purple-700 cursor-pointer" : "text-gray-300 cursor-not-allowed") + " py-3 w-14 bg-white shadow flex justify-center items-center rounded-t-md"}>
                                            <DuplicateIcon className={"h-6 w-6"} />
                                        </div>
                                    }
                                    <div className="flex-1 flex"></div>
                                </div>
                            </div>
                        }

                        {/*CAMPAIGN SELECTED CONTENT*/}
                        {
                            Array.isArray(this.state.campaigns) &&
                            this.state.campaigns.filter((item) => {
                                return item.selected && item.channel === this.props.channel
                            }).length > 0 &&
                            <div style={{ borderTopRightRadius: "0.5rem" }} className="bg-white shadow rounded-b-lg">
                                <div className="p-6">
                                    <div className="flex flex-row">
                                        <div className="mb-4">
                                            <div className="font-semibold text-gray-900">{this.functions.property("name", null, "campaigns")}</div>
                                            <div className="text-gray-900 text-xs">#{this.functions.property("id", null, "campaigns")}</div>
                                        </div>
                                        <div className="flex flex-1"></div>
                                    </div>
                                    {
                                        this.props.channel === "google" &&
                                        <>
                                            <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">
                                                <div className={(this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" ? "h-40" : "") + " sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                    {
                                                        this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" &&
                                                        <div className="absolute z-40 bg-gray-100 bg-opacity-75 rounded-lg border-1.5 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                                <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                    <LockClosedIcon className="w-5 h-5" />
                                                                </div>
                                                                Campaign created, not editable
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className={"sm:col-span-1"}>
                                                        <dt className="text-xs font-medium text-gray-500">Bidding</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("strategy_type", "name", "campaigns")}</dd>
                                                    </div>
                                                    {
                                                        this.functions.property("strategy_type", "value", "campaigns") === "TARGET_SPEND" &&
                                                        this.functions.property("allow_target_spend_ceiling", "value", "campaigns") &&
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Maximum CPC bid limit</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("target_spend_cpc_bid_ceiling", null, "campaigns")}</dd>
                                                        </div>
                                                    }
                                                    {
                                                        this.functions.property("strategy_type", "value", "campaigns") === "MAXIMIZE_CONVERSIONS" &&
                                                        this.functions.property("allow_maximize_conversions_target_cpa", "value", "campaigns") > 0 &&
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Target CPA</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("maximize_conversions_target_cpa", null, "campaigns")}</dd>
                                                        </div>
                                                    }
                                                    {
                                                        this.functions.property("strategy_type", "value", "campaigns") === "MAXIMIZE_CONVERSIONS_VALUE" &&
                                                        this.functions.property("allow_maximize_conversions_value_target_roas", "value", "campaigns") &&
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Target ROAS</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("maximize_conversions_values_target_roas", null, "campaigns")}</dd>
                                                        </div>
                                                    }
                                                    {
                                                        this.functions.property("strategy_type", "value", "campaigns") === "TARGET_IMPRESSION_SHARE" &&
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Ads to appear</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("target_impression_share_location", "name", "campaigns")}</dd>
                                                        </div>
                                                    }
                                                    {
                                                        this.functions.property("strategy_type", "value", "campaigns") === "TARGET_IMPRESSION_SHARE" &&
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Impression share to target</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("target_impression_share_fraction", null, "campaigns")}%</dd>
                                                        </div>
                                                    }
                                                    {
                                                        this.functions.property("strategy_type", "value", "campaigns") === "TARGET_IMPRESSION_SHARE" &&
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">CPC bid limit</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("target_impression_share_ceiling", null, "campaigns")}</dd>
                                                        </div>
                                                    }
                                                    <div className={"sm:col-span-1"}>
                                                        <dt className="text-xs font-medium text-gray-500">Budget</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("budget", null, "campaigns", true)} / Daily</dd>

                                                    </div>
                                                    <div className={"sm:col-span-1"}>
                                                        <dt className="text-xs font-medium text-gray-500">Start date</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{moment(this.functions.property("start_date", null, "campaigns")).format("YYYY-MM-DD")}</dd>
                                                    </div>
                                                    <div className={"sm:col-span-1"}>
                                                        <dt className="text-xs font-medium text-gray-500">End date</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("end_date", null, "campaigns") !== "-" ? moment(this.functions.property("end_date", null, "campaigns")).format("YYYY-MM-DD") : "-"}</dd>
                                                    </div>
                                                    {/* HOVER EFFECTS */}
                                                    {
                                                        [
                                                            { show: "locations", title: "Locations", property: "locations" },
                                                            { show: "languages", title: "Languages", property: "languages" }
                                                        ].map((item) => {
                                                            return (
                                                                <div
                                                                    onMouseEnter={() => { this.state.show[item.show] = true; this.setState({ show: this.state.show }) }}
                                                                    onMouseLeave={() => { this.state.show[item.show] = false; this.setState({ show: this.state.show }) }}
                                                                    className={"sm:col-span-1 relative"}
                                                                >
                                                                    {
                                                                        Array.isArray(this.functions.property(item.property, null, "campaigns")) && this.functions.property(item.property, null, "campaigns").length > 0 &&
                                                                        <ToolTip show={this.state.show[item.show]}>
                                                                            {
                                                                                this.functions.property(item.property, null, "campaigns").map((item) => {
                                                                                    return (
                                                                                        <div>
                                                                                            {item.name}
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ToolTip>
                                                                    }
                                                                    <dt className="text-xs font-medium text-gray-500">{item.title}</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">{Array.isArray(this.functions.property(item.property, null, "campaigns")) ? this.functions.property(item.property, null, "campaigns").length : "-"}</dd>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </dl>
                                            {
                                                this.renders.campaignAsset()
                                            }
                                        </>
                                    }

                                    {
                                        this.props.channel === "facebook" &&
                                        <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">

                                            <div className={(this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" ? "h-40" : "") + " sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                {
                                                    this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" &&
                                                    <div className="absolute z-40 bg-gray-100 bg-opacity-75 rounded-lg border-1.5 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                            <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                <LockClosedIcon className="w-5 h-5" />
                                                            </div>
                                                            Campaign created, not editable
                                                        </div>
                                                    </div>
                                                }
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Special ad cateogy</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("special_ad_category", "name", "campaigns")}</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Buying type</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">Auction</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Objective</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("objective", "name", "campaigns")}</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Budget</dt>
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") == 2 &&
                                                        this.functions.property("budget_optimization_type", "id", "campaigns") == 1 &&
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_budget", null, "campaigns", true)} / Daily</dd>
                                                    }
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") == 2 &&
                                                        this.functions.property("budget_optimization_type", "id", "campaigns") == 2 &&
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("lifetime_budget", null, "campaigns", true)} / Lifetime</dd>
                                                    }
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") !== 2 &&
                                                        <span>-</span>
                                                    }
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Spend limit</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("spend_limit", null, "campaigns", true)}</dd>
                                                </div>
                                            </div>
                                        </dl>
                                    }

                                    {
                                        this.props.channel === "twitter" &&
                                        <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">

                                            <div className={(this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" ? "h-40" : "") + " sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                {
                                                    this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" &&
                                                    <div className="absolute z-40 bg-gray-100 bg-opacity-75 rounded-lg border-1.5 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                            <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                <LockClosedIcon className="w-5 h-5" />
                                                            </div>
                                                            Campaign created, not editable
                                                        </div>
                                                    </div>
                                                }
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Objective</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("objective", "name", "campaigns")}</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Campaign budget optimization</dt>
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 1 &&
                                                        <dd className="mt-1 text-sm text-gray-900">Off</dd>
                                                    }
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 2 &&
                                                        <dd className="mt-1 text-sm text-gray-900">On</dd>
                                                    }
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Campaign budget(Daily)</dt>
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 1 &&
                                                        <dd className="mt-1 text-sm text-gray-900">-</dd>
                                                    }
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 2 &&
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_budget", null, "campaigns", true)}</dd>
                                                    }
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Campaign budget(Total)</dt>
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 1 &&
                                                        <dd className="mt-1 text-sm text-gray-900">-</dd>
                                                    }
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 2 &&
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("total_budget", null, "campaigns", true)}</dd>
                                                    }
                                                </div>
                                            </div>
                                        </dl>
                                    }

                                    {
                                        this.props.channel === "tiktok" &&
                                        <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">

                                            <div className={(this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" ? "h-40" : "") + " sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                {
                                                    this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" &&
                                                    <div className="absolute z-40 bg-gray-100 bg-opacity-75 rounded-lg border-1.5 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                            <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                <LockClosedIcon className="w-5 h-5" />
                                                            </div>
                                                            Campaign created, not editable
                                                        </div>
                                                    </div>
                                                }
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Advertising objective</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("objective", "name", "campaigns")}</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Campaign budget optimization</dt>
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 1 &&
                                                        <dd className="mt-1 text-sm text-gray-900">Off</dd>
                                                    }
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 2 &&
                                                        this.functions.property("budget_mode", "id", "campaigns") === 1 &&
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_budget", null, "campaigns", true)}</dd>
                                                    }
                                                    {
                                                        this.functions.property("budget_optimization", "id", "campaigns") === 2 &&
                                                        this.functions.property("budget_mode", "id", "campaigns") === 2 &&
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("total_budget", null, "campaigns", true)}</dd>
                                                    }
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Campaign budget</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("budget_mode", "name", "campaigns")}</dd>
                                                </div>
                                            </div>
                                        </dl>
                                    }

                                    {
                                        this.props.channel === "linkedin" &&
                                        <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">

                                            <div className={(this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" ? "h-40" : "") + " sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                {
                                                    this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" &&
                                                    <div className="absolute z-40 bg-gray-100 bg-opacity-75 rounded-lg border-1.5 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                            <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                <LockClosedIcon className="w-5 h-5" />
                                                            </div>
                                                            Campaign created, not editable
                                                        </div>
                                                    </div>
                                                }
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Start date</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{moment(this.functions.property("start_date", null, "campaigns")).format("YYYY-MM-DD")}</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">End date</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("end_date", null, "campaigns") !== "-" ? moment(this.functions.property("end_date", null, "campaigns")).format("YYYY-MM-DD") : "-"}</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Campaign budget optimization</dt>
                                                    {
                                                        this.functions.property("campaign_budget", "id", "campaigns") === 1 &&
                                                        <dd className="mt-1 text-sm text-gray-900">Off</dd>
                                                    }
                                                    {
                                                        this.functions.property("campaign_budget", "id", "campaigns") === 2 &&
                                                        <dd className="mt-1 text-sm text-gray-900">On</dd>
                                                    }
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Campaign budget</dt>
                                                    {
                                                        this.functions.property("campaign_budget", "id", "campaigns") === 2 &&
                                                        <dd className="mt-1 text-sm text-gray-900">{this.functions.property("total_budget", null, "campaigns", true)}/ Total</dd>
                                                    }
                                                    {
                                                        this.functions.property("campaign_budget", "id", "campaigns") === 1 &&
                                                        <dd className="mt-1 text-sm text-gray-900">-</dd>
                                                    }
                                                </div>
                                            </div>
                                        </dl>
                                    }

                                    {
                                        // THIS IS STUFF RENDERED ABOVE THE TABLE
                                        this.props.channel === "snapchat" &&
                                        <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5">

                                            <div className={(this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" ? "h-40" : "") + " sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                {
                                                    this.functions.property("locked", null, "campaigns") && this.functions.property("locked", null, "campaigns") !== "-" &&
                                                    <div className="absolute z-40 bg-gray-100 bg-opacity-75 rounded-lg border-1.5 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                            <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                <LockClosedIcon className="w-5 h-5" />
                                                            </div>
                                                            Campaign created, not editable
                                                        </div>
                                                    </div>
                                                }
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Advertising objective</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("objective", "name", "campaigns")}</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Daily spend cap</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_spend_cap", null, "campaigns", true)}</dd>
                                                </div>
                                                <div className={"sm:col-span-1"}>
                                                    <dt className="text-xs font-medium text-gray-500">Lifetime spend cap</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("lifetime_spend_cap", null, "campaigns", true)}</dd>
                                                </div>
                                            </div>
                                        </dl>
                                    }

                                    {/* ADGROUPS TABS */}
                                    {
                                        Array.isArray(this.state.campaigns) &&
                                        this.state.campaigns.filter((campaign) => {
                                            return campaign.selected && campaign.channel === this.props.channel
                                        }).length > 0 &&
                                        this.state.adgroups.filter((adgroup) => {
                                            return adgroup.channel === this.props.channel && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error"))
                                        }).length > 0 &&
                                        <div style={{ marginBottom: "-1.5px" }} className="flex flex-1 flex-row mt-6">
                                            {
                                                this.state.adgroups.filter((adgroup) => {
                                                    return adgroup.channel === this.props.channel && this.state.campaigns.filter((campaign) => { return campaign.selected && adgroup.campaign === campaign.id }).length > 0 && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error"))
                                                }).map((adgroup, index) => {
                                                    return (
                                                        <Fragment>
                                                            <div onClick={() => {
                                                                if (window.event && window.event.shiftKey) {
                                                                    adgroup.selected = !adgroup.selected;
                                                                } else {
                                                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                                                        item.selected = (item.id === adgroup.id);
                                                                        return item;
                                                                    });
                                                                }
                                                                this.promisedSetState({
                                                                    adgroups: this.state.adgroups,
                                                                    ads: this.state.ads.map((item) => { item.selected = false; return item })
                                                                });
                                                            }}
                                                                style={adgroup.selected ? { borderBottomColor: "#f9fafb40" } : {}}
                                                                className={(adgroup.selected ? "bg-gray-50" : "text-gray-700 ") + " bg-gray-50 border-b-1.5 border-t-1.5 border-r-1.5 border-l-1.5 py-3 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                                                <ViewGridIcon className={(adgroup.selected ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                                <div className="flex flex-1 truncate">
                                                                    <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                                        {adgroup.name}
                                                                    </div>
                                                                </div>
                                                                {/*
                                                                <div className="flex">
                                                                    {
                                                                        adgroup.channel === "facebook" &&
                                                                        Object.keys(adgroupFacebook.validate(adgroup)).map((item) => { return (adgroupFacebook.validate(adgroup)[item] ? true : false) }).filter((item) => { return item }).length > 0 &&
                                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                                    }
                                                                    {
                                                                        adgroup.channel === "linkedin" &&
                                                                        Object.keys(adgroupLinkedin.validate(adgroup)).map((item) => { return (adgroupLinkedin.validate(adgroup)[item] ? true : false) }).filter((item) => { return item }).length > 0 &&
                                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                                    }
                                                                    {
                                                                        adgroup.channel === "tiktok" &&
                                                                        Object.keys(adgroupTikTok.validate(adgroup)).map((item) => { return (adgroupTikTok.validate(adgroup)[item] ? true : false) }).filter((item) => { return item }).length > 0 &&
                                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                                    }
                                                                    {
                                                                        adgroup.channel === "snapchat" &&
                                                                        Object.keys(adgroupSnapchat.validate(adgroup)).map((item) => { return (adgroupSnapchat.validate(adgroup)[item] ? true : false) }).filter((item) => { return item }).length > 0 &&
                                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                                    }
                                                                    {
                                                                        adgroup.channel === "twitter" &&
                                                                        Object.keys(adgroupTwitter.validate(adgroup)).map((item) => { return (adgroupTwitter.validate(adgroup)[item] ? true : false) }).filter((item) => { return item }).length > 0 &&
                                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" />
                                                                    }
                                                                    
                                                                </div>
                                                                */}
                                                                {
                                                                    this.functions.validation(adgroup, "adgroup") &&
                                                                    <div className="flex">
                                                                        <div className='text-red-600 items-center justify-center flex'>
                                                                            <ExclamationIcon className="h-4 w-4 text-red-600 flex" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div onClick={async (e) => {
                                                                        e.stopPropagation();
                                                                        this.state.adgroups = this.state.adgroups.filter((item) => {
                                                                            return item.id !== adgroup.id;
                                                                        });
                                                                        this.state.ads = this.state.ads.filter((item) => {
                                                                            return item.adgroup !== adgroup.id;
                                                                        });
                                                                        await this.promisedSetState({
                                                                            adgroups: this.state.adgroups,
                                                                            ads: this.state.ads.map((item) => { item.selected = false; return item })
                                                                        });
                                                                        if (adgroup.selected) {
                                                                            this.state.adgroups = this.state.adgroups.map((item, inner_index) => {
                                                                                if ((index === 0 && inner_index === 0) || (inner_index === index - 1)) {
                                                                                    item.selected = true;
                                                                                }
                                                                                return item;
                                                                            });
                                                                            await this.promisedSetState({
                                                                                adgroups: this.state.adgroups
                                                                            });
                                                                        }
                                                                        this.form.set();
                                                                    }} className={(this.state.adgroups.filter((adgroup) => {
                                                                        return adgroup.channel === this.props.channel && this.state.campaigns.filter((campaign) => { return campaign.selected && adgroup.campaign === campaign.id }).length > 0
                                                                    }).length < 2 ? 'opacity-0' : 'opacity-100') + " border-1.5 -mr-2 h-5 w-5 rounded-full hover:text-red-500 flex justify-center items-center border-opacity-0 border-red-500 hover:border-opacity-100 hover:bg-red-100"}>
                                                                        <XIcon className="h-4 w-4" />
                                                                    </div>
                                                                }
                                                                {
                                                                    adgroup.locked &&
                                                                    <LockClosedIcon className="w-5 h-5" />
                                                                }
                                                            </div>
                                                            <div className="w-2"></div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                            <div className="flex flex-1 flex-row">
                                                {
                                                    this.state.campaigns.filter((campaign) => {
                                                        return campaign.selected && campaign.channel === this.props.channel
                                                    }).length < 2 &&
                                                    this.state.adgroups.filter((item) => {
                                                        return item.selected && item.channel === this.props.channel && ((item.partial && this.state.wizard.partial) || !this.state.wizard.partial)
                                                    }).length > 0 &&
                                                    <div onClick={() => {
                                                        let campaign = null;
                                                        this.state.campaigns.filter((campaign) => {
                                                            return campaign.selected && campaign.channel === this.props.channel
                                                        }).map((item) => {
                                                            campaign = item;
                                                        });
                                                        if (this.props.channel === "linkedin") {
                                                            this.setState({
                                                                new_campaign: campaign
                                                            });
                                                        } else {
                                                            if (campaign.channel === "facebook") {
                                                                this.functions.onNewFacebook(campaign);
                                                            }
                                                            if (campaign.channel === "twitter") {
                                                                this.functions.onNewTwitter(campaign);
                                                            }
                                                            if (campaign.channel === "tiktok") {
                                                                this.functions.onNewTikTok(campaign);
                                                            }
                                                            if (campaign.channel === "google") {
                                                                this.functions.onNewGoogle(campaign);
                                                            }
                                                            if (campaign.channel === "snapchat") {
                                                                this.functions.onNewSnapchat(campaign);
                                                            }
                                                        }
                                                    }} className={"text-purple-500 hover:text-purple-700 bg-gray-50 cursor-pointer border-b-1.5 py-3 w-14 flex justify-center border-t-1.5 border-r-1.5 border-l-1.5 items-center rounded-t-md"}>
                                                        <PlusIcon className={"h-6 w-6"} />
                                                    </div>
                                                }
                                                {
                                                    this.state.adgroups.filter((item) => {
                                                        return item.selected && item.channel === this.props.channel && ((item.partial && this.state.wizard.partial) || !this.state.wizard.partial)
                                                    }).length > 0 &&
                                                    this.state.campaigns.filter((campaign) => {
                                                        return campaign.selected && campaign.channel === this.props.channel
                                                    }).length < 2 &&
                                                    <div className="w-2"></div>
                                                }
                                                {
                                                    this.state.adgroups.filter((item) => {
                                                        return item.selected && item.channel === this.props.channel && ((item.partial && this.state.wizard.partial) || !this.state.wizard.partial)
                                                    }).length > 0 &&
                                                    <div onClick={() => {
                                                        if (this.state.adgroups.filter((item) => {
                                                            return item.selected && item.channel === this.props.channel
                                                        }).length > 0 && this.state.adgroups.filter((item) => {
                                                            return item.selected && item.channel === this.props.channel && item.locked
                                                        }).length < 1) {
                                                            this.functions.onEdit("adgroup");
                                                        }
                                                    }} className={(this.state.adgroups.filter((item) => {
                                                        return item.selected && item.channel === this.props.channel
                                                    }).length > 0 && this.state.adgroups.filter((item) => {
                                                        return item.selected && item.channel === this.props.channel && item.locked
                                                    }).length < 1 ? "text-purple-500 hover:text-purple-700 cursor-pointer" : "text-gray-300 cursor-not-allowed") + " py-3 border-b-1.5 bg-gray-50 w-14 border-t-1.5 border-r-1.5 border-l-1.5 flex justify-center items-center rounded-t-md"}>
                                                        <PencilAltIcon className={"h-6 w-6"} />
                                                    </div>
                                                }
                                                {
                                                    this.state.adgroups.filter((item) => {
                                                        return item.selected && item.channel === this.props.channel && ((item.partial && this.state.wizard.partial) || !this.state.wizard.partial)
                                                    }).length > 0 &&
                                                    <div className="w-2 border-b-1.5"></div>
                                                }
                                                {
                                                    this.state.adgroups.filter((item) => {
                                                        return item.selected && item.channel === this.props.channel && ((item.partial && this.state.wizard.partial) || !this.state.wizard.partial)
                                                    }).length > 0 &&
                                                    <div onClick={() => {
                                                        if (this.state.adgroups.filter((item) => {
                                                            return item.selected && item.channel === this.props.channel && !item.locked
                                                        }).length === 1) {
                                                            this.functions.onClone("adgroup");
                                                        }
                                                    }} className={(this.state.adgroups.filter((item) => {
                                                        return item.selected && item.channel === this.props.channel && !item.locked
                                                    }).length === 1 ? "text-purple-500 hover:text-purple-700 cursor-pointer" : "text-gray-300 cursor-not-allowed") + " py-3 border-b-1.5 bg-gray-50 w-14 border-t-1.5 border-r-1.5 border-l-1.5 flex justify-center items-center rounded-t-md"}>
                                                        <DuplicateIcon className={"h-6 w-6"} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {/*SELECTED ADGROUP CONTENT*/}
                                    {
                                        this.state.adgroups.filter((adgroup) => {
                                            return adgroup.channel === this.props.channel && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error"))
                                        }).length > 0 &&
                                        <div style={{ borderTopRightRadius: "0.5rem" }} className="border-1.5 rounded-b-lg bg-gray-50">

                                            {
                                                this.props.channel === "google" &&
                                                <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 p-6">
                                                    <div className="sm:col-span-5">
                                                        <dd className="font-semibold text-gray-900">{this.functions.property("name", null, "adgroups")}</dd>
                                                        <dd className="text-gray-900 text-xs">#{this.functions.property("id", null, "adgroups")}</dd>
                                                    </div>
                                                    <div className={"sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                        {
                                                            this.functions.property("locked", null, "adgroups") && this.functions.property("locked", null, "adgroups") !== "-" &&
                                                            <div className="absolute z-40 bg-gray-100 bg-opacity-75 rounded-lg top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                                    <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                        <LockClosedIcon className="w-5 h-5" />
                                                                    </div>
                                                                    Adset created, not editable
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Gender</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("gender", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Ages</dt>
                                                            {
                                                                Array.isArray(this.functions.property("ages", null, "adgroups")) && this.functions.property("ages", null, "adgroups").length > 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">Multiple</dd>
                                                            }
                                                            {
                                                                Array.isArray(this.functions.property("ages", null, "adgroups")) && this.functions.property("ages", null, "adgroups").length === 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("ages", null, "adgroups")[0].name}</dd>
                                                            }
                                                            {
                                                                !Array.isArray(this.functions.property("ages", null, "adgroups")) &&
                                                                <dd className="mt-1 text-sm text-gray-900">-</dd>
                                                            }
                                                        </div>
                                                    </div>
                                                </dl>
                                            }

                                            {
                                                this.props.channel === "facebook" &&
                                                <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 p-6">
                                                    <div className="sm:col-span-5">
                                                        <dd className="font-semibold text-gray-900">{this.functions.property("name", null, "adgroups")}</dd>
                                                        <dd className="text-gray-900 text-xs">#{this.functions.property("id", null, "adgroups")}</dd>
                                                    </div>
                                                    <div className={"sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                        {
                                                            this.functions.property("locked", null, "adgroups") && this.functions.property("locked", null, "adgroups") !== "-" &&
                                                            <div className="absolute z-40 bg-gray-100 bg-opacity-75 border-1.5 rounded-lg top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                                    <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                        <LockClosedIcon className="w-5 h-5" />
                                                                    </div>
                                                                    Adset created, not editable
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Start date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{moment(this.functions.property("start_date", null, "adgroups")).format("YYYY-MM-DD hh:mm A")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">End date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("end_date", null, "adgroups") !== "-" ? moment(this.functions.property("end_date", null, "adgroups")).format("YYYY-MM-DD hh:mm A") : "-"}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Optimization goal</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("optimization_goal", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Billing event</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("billing_event", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Budget</dt>
                                                            {
                                                                this.functions.property("adset_budget", "id", "adgroups") == 1 &&
                                                                this.functions.property("budget_type", "id", "adgroups") == 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_budget", null, "adgroups", true)} / Daily</dd>
                                                            }
                                                            {
                                                                this.functions.property("adset_budget", "id", "adgroups") == 1 &&
                                                                this.functions.property("budget_type", "id", "adgroups") == 2 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("lifetime_budget", null, "adgroups", true)} / Lifetime</dd>
                                                            }
                                                            {
                                                                this.functions.property("adset_budget", "id", "adgroups") !== 1 &&
                                                                <span>-</span>
                                                            }
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Spend limit (min/max)</dt>
                                                            {
                                                                this.functions.property("spend_limit", "value", "adgroups") &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_minimum", null, "adgroups", true)} / {this.functions.property("daily_maximum", null, "adgroups", true)}</dd>
                                                            }
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Gender</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("gender", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Age</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("age_min", "name", "adgroups")} - {this.functions.property("age_max", "name", "adgroups")}</dd>
                                                        </div>

                                                        {/* HOVER EFFECTS */}
                                                        {
                                                            [
                                                                { show: "interests", title: "Interests", property: "interests" },
                                                                { show: "behaviors", title: "Behaviors", property: "behaviors" },
                                                                { show: "audience", title: "Custom audience", property: "audience" },
                                                                { show: "locations", title: "Locations", property: "locations" }
                                                            ].map((item) => {
                                                                return (
                                                                    <>
                                                                        <div
                                                                            data-tip='' data-for={item.id + "_" + item.property} key={item.id + "_" + item.property}
                                                                            className={"sm:col-span-1 relative"}>
                                                                            <div>
                                                                                <dt className="text-xs font-medium text-gray-500">{item.title}</dt>
                                                                                <dd className="mt-1 text-sm text-gray-900">{Array.isArray(this.functions.property(item.property, null, "adgroups")) ? this.functions.property(item.property, null, "adgroups").length : "-"}</dd>
                                                                            </div>
                                                                            {
                                                                                Array.isArray(this.functions.property(item.property, null, "adgroups")) && this.functions.property(item.property, null, "adgroups").length > 0 &&
                                                                                <ReactTooltip effect='solid' id={item.id + "_" + item.property}>
                                                                                    {
                                                                                        this.functions.property(item.property, null, "adgroups").map((item) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    {item.name}
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ReactTooltip>
                                                                            }

                                                                        </div>


                                                                        {/*
                                                                        <div className={"sm:col-span-1 relative"} >
                                                                            {
                                                                                Array.isArray(this.functions.property(item.property, null, "adgroups")) && this.functions.property(item.property, null, "adgroups").length > 0 &&
                                                                                <ToolTip show={this.state.show[item.show]}>
                                                                                    {
                                                                                        this.functions.property(item.property, null, "adgroups").map((item) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    {item.name}
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ToolTip>
                                                                            }
                                                                            <div
                                                                                onMouseEnter={() => { this.state.show[item.show] = true; this.setState({ show: this.state.show }) }}
                                                                                onMouseLeave={() => { this.state.show[item.show] = false; this.setState({ show: this.state.show }) }}
                                                                            >
                                                                                <dt className="text-xs font-medium text-gray-500">{item.title}</dt>
                                                                                <dd className="mt-1 text-sm text-gray-900">{Array.isArray(this.functions.property(item.property, null, "adgroups")) ? this.functions.property(item.property, null, "adgroups").length : "-"}</dd>
                                                                            </div>
                                                                        </div>
                                                                    */}
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </dl>
                                            }

                                            {
                                                this.props.channel === "twitter" &&
                                                <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 p-6">
                                                    <div className="sm:col-span-5">
                                                        <dd className="font-semibold text-gray-900">{this.functions.property("name", null, "adgroups")}</dd>
                                                        <dd className="text-gray-900 text-xs">#{this.functions.property("id", null, "adgroups")}</dd>
                                                    </div>
                                                    <div className={"sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                        {
                                                            this.functions.property("locked", null, "adgroups") && this.functions.property("locked", null, "adgroups") !== "-" &&
                                                            <div className="absolute z-40 bg-gray-100 bg-opacity-75 rounded-lg top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                                    <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                        <LockClosedIcon className="w-5 h-5" />
                                                                    </div>
                                                                    Adgroup created, not editable
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Start date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{moment(this.functions.property("start_date", null, "adgroups")).format("YYYY-MM-DD hh:mm A")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">End date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("end_date", null, "adgroups") !== "-" ? moment(this.functions.property("end_date", null, "adgroups")).format("YYYY-MM-DD hh:mm A") : "-"}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Budget(Daily/Total)</dt>
                                                            {
                                                                this.functions.property("adgroup_budget_optimization", "id", "adgroups") == 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_budget", null, "adgroups", true)} / {this.functions.property("total_budget", null, "adgroups", true)}</dd>
                                                            }
                                                            {
                                                                this.functions.property("adgroup_budget_optimization", "id", "adgroups") == 2 &&
                                                                <dd className="mt-1 text-sm text-gray-900"> - / - </dd>
                                                            }
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Optimization goal</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("goal", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Gender</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("gender", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Ages</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {
                                                                    this.functions.property("age_min", "name", "adgroups")
                                                                }
                                                                -
                                                                {
                                                                    this.functions.property("age_max", "name", "adgroups")
                                                                }
                                                            </dd>
                                                        </div>


                                                        {/* HOVER EFFECTS */}
                                                        {
                                                            [
                                                                { show: "locations", title: "Locations", property: "locations" },
                                                                { show: "keywords", title: "Keywords", property: "keywords" },
                                                                { show: "interests", title: "Interests", property: "interests" },
                                                                { show: "events", title: "Events", property: "events" },
                                                                { show: "conversations", title: "Conversations", property: "conversations" }
                                                            ].map((item) => {
                                                                return (
                                                                    <div className={"sm:col-span-1 relative"} >
                                                                        {
                                                                            Array.isArray(this.functions.property(item.property, null, "adgroups")) && this.functions.property(item.property, null, "adgroups").length > 0 &&
                                                                            <ToolTip show={this.state.show[item.show]}>
                                                                                {
                                                                                    this.functions.property(item.property, null, "adgroups").map((item) => {
                                                                                        return (
                                                                                            <div>
                                                                                                {item.name}
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ToolTip>
                                                                        }
                                                                        <div
                                                                            onMouseEnter={() => { this.state.show[item.show] = true; this.setState({ show: this.state.show }) }}
                                                                            onMouseLeave={() => { this.state.show[item.show] = false; this.setState({ show: this.state.show }) }}
                                                                        >
                                                                            <dt className="text-xs font-medium text-gray-500">{item.title}</dt>
                                                                            <dd className="mt-1 text-sm text-gray-900">{Array.isArray(this.functions.property(item.property, null, "adgroups")) ? this.functions.property(item.property, null, "adgroups").length : "-"}</dd>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </dl>
                                            }

                                            {
                                                this.props.channel === "tiktok" &&
                                                <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 p-6">
                                                    <div className="sm:col-span-5">
                                                        <dd className="font-semibold text-gray-900">{this.functions.property("name", null, "adgroups")}</dd>
                                                        <dd className="text-gray-900 text-xs">#{this.functions.property("id", null, "adgroups")}</dd>
                                                    </div>
                                                    <div className={"sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                        {
                                                            this.functions.property("locked", null, "adgroups") && this.functions.property("locked", null, "adgroups") !== "-" &&
                                                            <div className="absolute z-40 bg-gray-100 bg-opacity-75 border-1.5 rounded-lg top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                                    <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                        <LockClosedIcon className="w-5 h-5" />
                                                                    </div>
                                                                    Adgroup created, not editable
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Start date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{moment(this.functions.property("start_date", null, "adgroups")).format("YYYY-MM-DD hh:mm A")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">End date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("end_date", null, "adgroups") !== "-" ? moment(this.functions.property("end_date", null, "adgroups")).format("YYYY-MM-DD hh:mm A") : "-"}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Budget</dt>
                                                            {
                                                                this.functions.property("budget_mode", "id", "adgroups") == 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_budget", null, "adgroups", true)} / Daily</dd>
                                                            }
                                                            {
                                                                this.functions.property("budget_mode", "id", "adgroups") == 2 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("lifetime_budget", null, "adgroups", true)} / Lifetime</dd>
                                                            }
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Targeting mode</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("targeting_mode", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Optimization goal</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("goal", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Bid strategy</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("bid_strategy", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Custom audience</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{Array.isArray(this.functions.property("audience", null, "adgroups")) ? this.functions.property("audience", null, "adgroups").length : "-"}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Gender</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("gender", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Ages</dt>
                                                            {
                                                                Array.isArray(this.functions.property("ages", null, "adgroups")) && this.functions.property("ages", null, "adgroups").length > 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">Multiple</dd>
                                                            }
                                                            {
                                                                Array.isArray(this.functions.property("ages", null, "adgroups")) && this.functions.property("ages", null, "adgroups").length === 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("ages", null, "adgroups")[0].name}</dd>
                                                            }
                                                            {
                                                                !Array.isArray(this.functions.property("ages", null, "adgroups")) &&
                                                                <dd className="mt-1 text-sm text-gray-900">-</dd>
                                                            }
                                                        </div>

                                                        {/* HOVER EFFECTS */}
                                                        {
                                                            [
                                                                { show: "locations", title: "Locations", property: "locations" },
                                                                { show: "categories", title: "Interests", property: "categories" }
                                                            ].map((item) => {
                                                                return (
                                                                    <div className={"sm:col-span-1 relative"} >
                                                                        {
                                                                            Array.isArray(this.functions.property(item.property, null, "adgroups")) && this.functions.property(item.property, null, "adgroups").length > 0 &&
                                                                            <ToolTip show={this.state.show[item.show]}>
                                                                                {
                                                                                    this.functions.property(item.property, null, "adgroups").map((item) => {
                                                                                        return (
                                                                                            <div>
                                                                                                {item.name}
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ToolTip>
                                                                        }
                                                                        <div
                                                                            onMouseEnter={() => { this.state.show[item.show] = true; this.setState({ show: this.state.show }) }}
                                                                            onMouseLeave={() => { this.state.show[item.show] = false; this.setState({ show: this.state.show }) }}
                                                                        >
                                                                            <dt className="text-xs font-medium text-gray-500">{item.title}</dt>
                                                                            <dd className="mt-1 text-sm text-gray-900">{Array.isArray(this.functions.property(item.property, null, "adgroups")) ? this.functions.property(item.property, null, "adgroups").length : "-"}</dd>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </dl>
                                            }

                                            {
                                                this.props.channel === "linkedin" &&
                                                <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 p-6">
                                                    <div className="sm:col-span-5">
                                                        <dd className="font-semibold text-gray-900">{this.functions.property("name", null, "adgroups")}</dd>
                                                        <dd className="text-gray-900 text-xs">#{this.functions.property("id", null, "adgroups")}</dd>
                                                    </div>
                                                    <div className={"sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                        {
                                                            this.functions.property("locked", null, "adgroups") && this.functions.property("locked", null, "adgroups") !== "-" &&
                                                            <div className="absolute z-40 bg-gray-100 bg-opacity-75 border-1.5 rounded-lg top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                                    <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                        <LockClosedIcon className="w-5 h-5" />
                                                                    </div>
                                                                    Adgroup created, not editable
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Adgroup type</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("adgroup_type", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Start date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{moment(this.functions.property("start_date", null, "adgroups")).format("YYYY-MM-DD")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">End date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("end_date", null, "adgroups") !== "-" ? moment(this.functions.property("end_date", null, "adgroups")).format("YYYY-MM-DD") : "-"}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">{this.functions.property("adgroup_budget", "name", "adgroups")}</dt>
                                                            {
                                                                this.functions.property("adgroup_budget", "id", "adgroups") == 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">
                                                                    {this.functions.property("daily_budget", null, "adgroups", true)}
                                                                </dd>
                                                            }
                                                            {
                                                                this.functions.property("adgroup_budget", "id", "adgroups") == 2 &&
                                                                <dd className="mt-1 text-sm text-gray-900">
                                                                    {this.functions.property("total_budget", null, "adgroups", true)}
                                                                </dd>
                                                            }
                                                            {
                                                                this.functions.property("adgroup_budget", "id", "adgroups") == 3 &&
                                                                <dd className="mt-1 text-sm text-gray-900">
                                                                    {this.functions.property("daily_budget", null, "adgroups", true)} / {this.functions.property("total_budget", null, "adgroups", true)}
                                                                </dd>
                                                            }
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Objective</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("objective", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Optimization goal</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("optimization_goal", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Bid strategy</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("bidding_strategy", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Language</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("language", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Gender</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("gender", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1 relative"} >
                                                            {
                                                                Array.isArray(this.functions.property("age", null, "adgroups")) && this.functions.property("age", null, "adgroups").length > 1 &&
                                                                <ToolTip show={this.state.show.age}>
                                                                    {
                                                                        this.functions.property("age", null, "adgroups").map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    {item.name}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </ToolTip>
                                                            }
                                                            <div
                                                                onMouseEnter={() => { this.state.show.age = true; this.setState({ show: this.state.show }) }}
                                                                onMouseLeave={() => { this.state.show.age = false; this.setState({ show: this.state.show }) }}
                                                            >
                                                                <dt className="text-xs font-medium text-gray-500">Ages</dt>
                                                                {
                                                                    Array.isArray(this.functions.property("age", null, "adgroups")) && this.functions.property("age", null, "adgroups").length > 1 &&
                                                                    <dd className="mt-1 text-sm text-gray-900">Multiple</dd>
                                                                }
                                                                {
                                                                    Array.isArray(this.functions.property("age", null, "adgroups")) && this.functions.property("age", null, "adgroups").length === 1 &&
                                                                    <dd className="mt-1 text-sm text-gray-900">{this.functions.property("age", null, "adgroups")[0].name}</dd>
                                                                }
                                                                {
                                                                    !Array.isArray(this.functions.property("age", null, "adgroups")) &&
                                                                    <dd className="mt-1 text-sm text-gray-900">-</dd>
                                                                }
                                                            </div>
                                                        </div>


                                                        {/* HOVER EFFECTS */}
                                                        {
                                                            [
                                                                { show: "audience", title: "Audience", property: "audience" },
                                                                { show: "locations", title: "Locations", property: "locations" }
                                                            ].map((item) => {
                                                                return (
                                                                    <div className={"sm:col-span-1 relative"} >
                                                                        {
                                                                            Array.isArray(this.functions.property(item.property, null, "adgroups")) && this.functions.property(item.property, null, "adgroups").length > 0 &&
                                                                            <ToolTip show={this.state.show[item.show]}>
                                                                                {
                                                                                    this.functions.property(item.property, null, "adgroups").map((item) => {
                                                                                        return (
                                                                                            <div>
                                                                                                {(item ? item.name : "-")}
                                                                                            </div >
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ToolTip >
                                                                        }
                                                                        <div
                                                                            onMouseEnter={() => { this.state.show[item.show] = true; this.setState({ show: this.state.show }) }}
                                                                            onMouseLeave={() => { this.state.show[item.show] = false; this.setState({ show: this.state.show }) }}
                                                                        >
                                                                            <dt className="text-xs font-medium text-gray-500">{item.title}</dt>
                                                                            <dd className="mt-1 text-sm text-gray-900">{Array.isArray(this.functions.property(item.property, null, "adgroups")) ? this.functions.property(item.property, null, "adgroups").length : "-"}</dd>
                                                                        </div>
                                                                    </div >
                                                                )
                                                            })
                                                        }
                                                    </div >
                                                </dl >
                                            }

                                            {
                                                this.props.channel === "snapchat" &&
                                                <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 p-6">
                                                    <div className="sm:col-span-5">
                                                        <dd className="font-semibold text-gray-900">{this.functions.property("name", null, "adgroups")}</dd>
                                                        <dd className="text-gray-900 text-xs">#{this.functions.property("id", null, "adgroups")}</dd>
                                                    </div>
                                                    <div className={"sm:col-span-5 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-5 relative"}>
                                                        {
                                                            this.functions.property("locked", null, "adgroups") && this.functions.property("locked", null, "adgroups") !== "-" &&
                                                            <div className="absolute z-40 bg-gray-100 bg-opacity-75 border-1.5 rounded-lg top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div className="text-sm font-medium text-center flex flex-col justify-center items-center">
                                                                    <div className="bg-white rounded-full h-10 w-10 flex justify-center items-center mb-2">
                                                                        <LockClosedIcon className="w-5 h-5" />
                                                                    </div>
                                                                    Adgroup created, not editable
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Start date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{moment(this.functions.property("start_date", null, "adgroups")).format("YYYY-MM-DD hh:mm A")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">End date</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("end_date", null, "adgroups") !== "-" ? moment(this.functions.property("end_date", null, "adgroups")).format("YYYY-MM-DD hh:mm A") : "-"}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Budget</dt>
                                                            {
                                                                this.functions.property("budget_mode", "id", "adgroups") == 1 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("daily_budget", null, "adgroups", true)} / Daily</dd>
                                                            }
                                                            {
                                                                this.functions.property("budget_mode", "id", "adgroups") == 2 &&
                                                                <dd className="mt-1 text-sm text-gray-900">{this.functions.property("lifetime_budget", null, "adgroups", true)} / Lifetime</dd>
                                                            }
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className={`text-xs font-medium text-gray-500`}>Optimization goal
                                                            </dt>
                                                            <dd className={`mt-1 text-sm text-gray-900`}>{this.functions.property("optimization_goal", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Bid strategy</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("bid_strategy", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Gender</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("gender", "name", "adgroups")}</dd>
                                                        </div>
                                                        <div className={"sm:col-span-1"}>
                                                            <dt className="text-xs font-medium text-gray-500">Age</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{this.functions.property("min_age", "name", "adgroups")} - {this.functions.property("max_age", "name", "adgroups")}</dd>
                                                        </div>

                                                        {/* HOVER EFFECTS */}
                                                        {
                                                            [
                                                                { show: "locations", title: "Locations", property: "locations" },
                                                                { show: "audiences", title: "Audiences", property: "audiences" }
                                                            ].map((item) => {
                                                                return (
                                                                    <div className={"sm:col-span-1 relative"} >
                                                                        {
                                                                            Array.isArray(this.functions.property(item.property, null, "adgroups")) && this.functions.property(item.property, null, "adgroups").length > 0 &&
                                                                            <ToolTip show={this.state.show[item.show]}>
                                                                                {
                                                                                    this.functions.property(item.property, null, "adgroups").map((item) => {
                                                                                        return (
                                                                                            <div>
                                                                                                {item.name}
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ToolTip>
                                                                        }
                                                                        <div
                                                                            onMouseEnter={() => { this.state.show[item.show] = true; this.setState({ show: this.state.show }) }}
                                                                            onMouseLeave={() => { this.state.show[item.show] = false; this.setState({ show: this.state.show }) }}
                                                                        >
                                                                            <dt className="text-xs font-medium text-gray-500">{item.title}</dt>
                                                                            <dd className="mt-1 text-sm text-gray-900">{Array.isArray(this.functions.property(item.property, null, "adgroups")) ? this.functions.property(item.property, null, "adgroups").length : "-"}</dd>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </dl>
                                            }
                                            {/* ADS */}
                                            {
                                                this.state.ad_options.filter((item) => {
                                                    return item.channels.filter((item) => { return item == this.props.channel }).length > 0
                                                }).filter((option) => {
                                                    if (this.props.channel === "google") {
                                                        return this.state.ads.filter((item) => {
                                                            return this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && item.channel === this.props.channel }).length > 0 && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error"))
                                                        }).filter((item) => {
                                                            if (item.ad_type && item.ad_type.type === "image" && item.ad_type.value === "merge_ad" && option.ad_type === "image_ad") {
                                                                return true;
                                                            } else if (item.ad_type && item.ad_type.type === "video" && item.ad_type.value === "merge_ad" && option.ad_type === "video_ad") {
                                                                return true;
                                                            } else if (item.type === "posts" && option.ad_type === "posts") {
                                                                return true;
                                                            } else if (item.type === "display" && option.ad_type === "display") {
                                                                return true;
                                                            } else {
                                                                return item.ad_type && item.ad_type.value === option.ad_type
                                                            }
                                                        }).length > 0
                                                    } else {
                                                        return true
                                                    }
                                                }).map((option) => {
                                                    return (
                                                        // <div className="border-t-1.5 p-4 flex flex-row items-center">
                                                        <div className="border-t-1.5 flex py-2 flex-row items-center">
                                                            <div className="flex flex-row px-2"
                                                                style={{ width: ([19, 20].includes(option.id) ? 200 : 110) }}
                                                            >
                                                                <option.icon className="h-6 w-6 text-purple-500" />
                                                                <div style={{ paddingTop: "1px" }} className="text-sm font-medium ml-2">
                                                                    {option.name}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-1 flex-row flex-wrap">
                                                                {
                                                                    this.state.ads.filter((item) => {
                                                                        return this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && item.channel === this.props.channel }).length > 0 && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error"))
                                                                    }).filter((item) => {
                                                                        if (item.ad_type && item.ad_type.type === "image" && item.ad_type.value === "merge_ad" && option.ad_type === "image_ad") {
                                                                            return true;
                                                                        } else if (item.ad_type && item.ad_type.type === "video" && item.ad_type.value === "merge_ad" && option.ad_type === "video_ad") {
                                                                            return true;
                                                                        } else if (item.type === "posts" && option.ad_type === "posts") {
                                                                            return true;
                                                                        } else if (item.type === "display" && option.ad_type === "display") {
                                                                            return true;
                                                                        } else {
                                                                            return item.ad_type && item.ad_type.value === option.ad_type
                                                                        }
                                                                    }).map((item) => {
                                                                        return (
                                                                            <div onClick={async () => {
                                                                                if (!item.locked) {
                                                                                    this.state.ads = this.state.ads.map((inner_item) => {
                                                                                        if (inner_item.id == item.id) {
                                                                                            inner_item.selected = !inner_item.selected;
                                                                                        } else {
                                                                                            inner_item.selected = false;
                                                                                        }
                                                                                        return inner_item;
                                                                                    })
                                                                                    this.state.campaigns = this.state.campaigns.map((inner_item) => {
                                                                                        if (Array.isArray(inner_item.asset)) {
                                                                                            inner_item.asset = inner_item.asset.map((asset) => {
                                                                                                asset.selected = false;
                                                                                                return asset;
                                                                                            })
                                                                                        }
                                                                                        return inner_item;
                                                                                    })
                                                                                    await this.promisedSetState({
                                                                                        ads: this.state.ads,
                                                                                        campaigns: this.state.campaigns
                                                                                    });
                                                                                }
                                                                            }} className={
                                                                                (item.selected ? "border-purple-500" : "")
                                                                                + " flex cursor-pointer flex-row border-1.5 bg-gray-50 overflow-hidden rounded-md my-1 mr-2"}>
                                                                                <div className={"w-12 h-12 overflow-hidden mr-2 relative"}>
                                                                                    {
                                                                                        item.locked &&
                                                                                        <div className="bg-gray-100 bg-opacity-75 absolute left-0 z-40 top-0 bottom-0 right-0 flex justify-center items-center">
                                                                                            <LockClosedIcon className="w-5 h-5" />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.ad_type && item.ad_type.value === "keywords" &&
                                                                                        <div className=" w-full h-full flex flex-col justify-center items-center text-xs font-medium">
                                                                                            {Array.isArray(item.keywords) ? item.keywords.length : 0}
                                                                                            <div className="text-xxs">
                                                                                                {(item.negative && item.negative.value) ? "Negative" : "Positive"}
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.ad_type && item.ad_type.value === "shopping" &&
                                                                                        <div className=" w-full h-full flex flex-col justify-center items-center text-xs font-medium">
                                                                                            {item.feed_label ? item.feed_label.amount : "-"}
                                                                                            <div className="text-xxs">
                                                                                                {"Products"}
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.ad_type && item.ad_type.value === "extension" &&
                                                                                        <div className=" w-full h-full flex flex-col justify-center items-center text-xs font-medium">
                                                                                            {
                                                                                                item.picture &&
                                                                                                <img src={item.picture} />
                                                                                            }
                                                                                            {
                                                                                                !item.picture &&
                                                                                                <div>{Array.isArray(item.items) ? item.items.length : 0} </div>
                                                                                            }
                                                                                            <div className="text-xxs">
                                                                                                {
                                                                                                    item.subtype && item.subtype.value === "call" &&
                                                                                                    <span>Numbers</span>
                                                                                                }
                                                                                                {
                                                                                                    item.subtype && item.subtype.value === "sitelink" &&
                                                                                                    <span>Links</span>
                                                                                                }
                                                                                                {
                                                                                                    item.subtype && item.subtype.value === "price" &&
                                                                                                    <span>Price</span>
                                                                                                }
                                                                                                {
                                                                                                    item.subtype && item.subtype.value === "promotion" &&
                                                                                                    <span>Promotions</span>
                                                                                                }
                                                                                                {
                                                                                                    item.subtype && item.subtype.value === "snippet" &&
                                                                                                    <span>Snippets</span>
                                                                                                }
                                                                                                {
                                                                                                    item.subtype && item.subtype.value === "callout" &&
                                                                                                    <span>Callouts</span>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.ad_type && item.ad_type.value === "search_ad" &&
                                                                                        <div className="w-full h-full flex flex-col justify-center items-center text-xs font-medium">
                                                                                            <div className="flex flex-row w-full px-1">
                                                                                                <div className="flex flex-1">
                                                                                                    <div className="border-b border-1.5 w-full "></div>
                                                                                                </div>
                                                                                                <div className="w-1"></div>
                                                                                                <div className="flex flex-1">
                                                                                                    <div className="border-b border-1.5 w-full "></div>
                                                                                                </div>
                                                                                                <div className="w-1"></div>
                                                                                                <div className="flex flex-1">
                                                                                                    <div className="border-b border-1.5 w-full "></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flex flex-row w-full mt-1 px-1">
                                                                                                <div className="flex flex-1">
                                                                                                    <div className="w-full h-2 bg-gray-300"></div>
                                                                                                </div>
                                                                                                <div className="w-1"></div>
                                                                                                <div className="flex flex-1">
                                                                                                    <div className="w-full h-2 bg-gray-300"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        ((item.ad_type && (item.ad_type.value === "image_ad" || (item.ad_type.value === "posts" && item.ad_type.type === "image" && !item.files))) ||
                                                                                            (item.ad_type === "image" && item.type === "posts" && item.picture !== "")) &&
                                                                                        <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                            <img src={item.picture} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        ((item.ad_type && (item.ad_type.value === "video_ad" || (item.ad_type.value === "posts" && item.ad_type.type === "video" && !item.files))) ||
                                                                                            (item.ad_type === "video" && item.type === "posts" && item.video !== "")) &&
                                                                                        <>
                                                                                            <video src={!item.video ? item.url : item.video} autoPlay={false} muted={true} controls={false} className="w-full h-full" />
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        (item.ad_type && (item.ad_type.value === "youtube")) &&
                                                                                        <div className="w-full h-full absolute top-0">
                                                                                            <iframe src={"https://www.youtube.com/embed/" + item.youtube_id + "?autoplay=1&mute=1&controls=0"} frameborder={"0"} styles={{ "overflow": "hidden", "height": "100%", "width": "100%" }} height={"100%"} width={"100%"}></iframe>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.ad_type && ((item.ad_type.value === "posts" || item.type === "posts") && item.files)) &&
                                                                                        <div className="w-12 h-12 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden">
                                                                                            {
                                                                                                Array.isArray(item.files) &&
                                                                                                item.files.filter((item, index) => { return index < 4 }).map((item) => {
                                                                                                    return (
                                                                                                        <div className="w-full h-full overflow-hidden">
                                                                                                            {
                                                                                                                (item.type === "image" || item.type === "IMAGE") &&
                                                                                                                <div className={"w-full h-full relative"}>
                                                                                                                    <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                (item.type === "video" || item.type === "VIDEO") &&
                                                                                                                <video src={item.url} autoPlay={false} muted={true} controls={false} className={"w-full h-full"} />

                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.ad_type && (item.ad_type.value === "carousel_ad" || item.ad_type.value === "merge_ad")) &&
                                                                                        <div className="w-12 h-12 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden">
                                                                                            {
                                                                                                Array.isArray(item.slides) &&
                                                                                                item.slides.filter((item, index) => { return index < 4 }).map((item) => {
                                                                                                    return (
                                                                                                        <div className="w-full h-full overflow-hidden">
                                                                                                            {
                                                                                                                item.type === "image" &&
                                                                                                                <div className={"w-full h-full relative"}>
                                                                                                                    <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                item.type === "video" &&
                                                                                                                <video src={item.url} autoPlay={false} muted={true} controls={false} className={"w-full h-full"} />
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.ad_type && item.ad_type.value === "catalog_ad" &&
                                                                                        !item.collection_ad &&
                                                                                        <div className="w-12 h-12 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden">
                                                                                            {
                                                                                                Array.isArray(item.preview_products) &&
                                                                                                item.preview_products.filter((item, index) => { return index < 4 }).map((item) => {
                                                                                                    return (
                                                                                                        <div className="w-full h-full overflow-hidden">
                                                                                                            <img className={"w-full h-full"} src={item.image_url} alt="" />
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.ad_type && item.ad_type.value === "catalog_ad" &&
                                                                                        item.collection_ad &&
                                                                                        <div className="w-12 h-12 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden">
                                                                                            {
                                                                                                item.instantExperience && item.instantExperience.elements && item.instantExperience.elements.carousel &&
                                                                                                item.instantExperience.elements.carousel.filter((item, index) => { return index < 4 }).map((item) => {
                                                                                                    return (
                                                                                                        <div className="w-full h-full overflow-hidden">
                                                                                                            <img className={"w-full h-full"} src={item.image_url} alt="" />
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                item.instantExperience && item.instantExperience.elements && item.instantExperience.elements.product_set &&
                                                                                                item.instantExperience.elements.product_set.filter((item, index) => { return index < 4 }).map((item) => {
                                                                                                    return (
                                                                                                        <div className="w-full h-full overflow-hidden">
                                                                                                            <img className={"w-full h-full"} src={item.image_url} alt="" />
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </div>

                                                                                    }

                                                                                </div>
                                                                                <div className="flex-1 flex pr-2 items-center justify-start">
                                                                                    <div className='flex flex-col'>
                                                                                        <div className='flex'>
                                                                                            <div className="text-sm font-semibold flex flex-1 w-24">
                                                                                                <div className=' truncate'>
                                                                                                    {item.ad_type && item.ad_type.value === "catalog_ad" && item.collection_ad ? "Collection Ad" : (item.name ? item.name : "Asset")}
                                                                                                    { }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="text-xs text-gray-900">#{item.id}</div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.functions.validation(item, "ad") &&

                                                                                    <div className='text-red-600 items-center justify-center flex'>
                                                                                        <ExclamationIcon className="h-6 w-6 text-red-600 flex" />
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className='pr-2'>
                                                                <button
                                                                    onClick={() => {

                                                                        this.functions.onEdit("ad");
                                                                    }}
                                                                    className={(this.state.ads.filter((item) => {
                                                                        if (item.selected && (item.ad_type.type === "video") && item.ad_type.value === "merge_ad" && option.ad_type === "video_ad" && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0) {
                                                                            return true
                                                                        } else if (item.selected && item.ad_type.type === "image" && item.ad_type.value === "merge_ad" && option.ad_type === "image_ad" && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0) {
                                                                            return true
                                                                        } else {
                                                                            return item.selected && (item.ad_type.value === option.ad_type || item.type === option.ad_type) && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0
                                                                        }
                                                                    }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + "  text-white shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                >
                                                                    <PencilAltIcon className="h-5 w-5" />
                                                                </button>
                                                                {
                                                                    <button
                                                                        onClick={async () => {
                                                                            this.state.ads = this.state.ads.filter((item) => {
                                                                                if (item.selected && item.ad_type.type === "video" && item.ad_type.value === "merge_ad" && option.ad_type === "video_ad" && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0) {
                                                                                    return false
                                                                                } else if (item.selected && item.ad_type.type === "image" && item.ad_type.value === "merge_ad" && option.ad_type === "image_ad" && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0) {
                                                                                    return false
                                                                                } else if (item.selected && (item.ad_type.value === option.ad_type || item.type === option.ad_type) && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0) {
                                                                                    return false;
                                                                                } else {
                                                                                    return true;
                                                                                }
                                                                            })
                                                                            // this.functions.snapchatAttachmentError();
                                                                            await this.promisedSetState({
                                                                                ads: this.state.ads
                                                                            });
                                                                            this.form.set();
                                                                        }}
                                                                        className={(this.state.ads.filter((item) => {
                                                                            if (item.selected && item.ad_type.type === "video" && item.ad_type.value === "merge_ad" && option.ad_type === "video_ad" && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0) {
                                                                                return true
                                                                            } else if (item.selected && item.ad_type.type === "image" && item.ad_type.value === "merge_ad" && option.ad_type === "image_ad" && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0) {
                                                                                return true
                                                                            } else {
                                                                                return item.selected && (item.ad_type.value === option.ad_type || item.type === option.ad_type) && this.state.adgroups.filter((adgroup) => { return adgroup.id === item.adgroup && adgroup.selected && adgroup.channel === this.props.channel }).length > 0
                                                                            }
                                                                        }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + "  text-white ml-2 shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"}
                                                                    >
                                                                        <TrashIcon className="h-5 w-5" />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div >
                                    }
                                </div >
                            </div >
                        }

                    </div >

                    <div className="pt-4 flex flex-row">
                        <div className="flex flex-1">
                            <button
                                type="button"
                                onClick={async () => {
                                    this.props.history.push("/v2/campaigns/new/" + this.renders.backButtonLabel());
                                }}
                                className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none shadow focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                Back
                            </button>
                        </div>
                        <div className="flex flex-1"></div>
                        <div className="flex justify-center items-center">
                            <div className="">
                                <button
                                    onClick={() => {
                                        if (!this.state.error) {
                                            this.props.history.push("/v2/campaigns/new/" + this.renders.nextButtonLabel());
                                        }
                                    }}
                                    className={
                                        (this.props.channel === "google" && this.state.campaigns.filter((item) => { return item.partial_parced }).length > 0 ? (((this.state.ads.filter((item) => { return item.channel === this.props.channel }).length > 0 || this.state.campaigns.filter((item) => { return item.asset && item.asset.length > 0 }).length > 0) && !this.state.error) ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") : (this.state.ads.filter((item) => { return item.channel === this.props.channel }).length > 0 && !this.state.error ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600"))
                                        + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                >
                                    <span>Next: <span className="capitalize">{this.renders.nextButtonLabel()}</span></span>
                                    <ArrowRightIcon className="ml-2 h-5 w-5" />
                                </button>
                            </div>
                        </div>
                    </div>

                </div >
            </div >
        )
    }
}

export default CreateDraftChannel;

/*

        onNewGoogle: async (existing_campaign) => {
            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);
            if (!existing_campaign) {
                this.state.campaigns.push({
                    id: campaign_id,
                    level: 'campaign',
                    channel: "google",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate,
                    end_date: this.state.order.endDate,
                    //SEARCH SETUP
                    //network: { id: 1, name: "Google search", value: "google_search" },
                    strategy_type: { id: 1, name: "Click", value: "TARGET_SPEND" },
                    allow_target_spend_ceiling: { id: 1, name: 'Disabled', value: false },
                    advertising_type: { id: 1, name: 'Search', value: "SEARCH" },
                    advertising_sub_type: { id: 0, name: "", value: null, adgroup: "SEARCH_STANDARD", ad: "RESPONSIVE_SEARCH_AD" },
                    google_search: { id: 1, name: "Enabled", value: true, locked: true },
                    search_network: { id: 1, name: "Enabled", value: true },
                    content_network: { id: 1, name: "Enabled", value: true },
                    partner_search_network: { id: 2, name: "Disabled", value: false, locked: true },
                    days: [{ id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } }]
                });
            }
            this.state.adgroups.push({
                id: adgroup_id,
                level: 'adgroup',
                channel: "google",
                campaign: existing_campaign ? existing_campaign : campaign_id,
                name: "Adset",
                start_date: this.state.order.startDate,
                end_date: this.state.order.endDate,
                advertising_type: "SEARCH_STANDARD",
                advertising_type_ad: "RESPONSIVE_SEARCH_AD",
                //SEARCH SETUP
                gender: { id: 1, name: "All genders", value: "ALL" },
                ages: [
                    { id: 1, name: "18 - 24", value: "AGE_RANGE_18_24", included: true },
                    { id: 2, name: "25 - 34", value: "AGE_RANGE_25_34", included: true },
                    { id: 3, name: "35 - 44", value: "AGE_RANGE_35_44", included: true },
                    { id: 4, name: "45 - 54", value: "AGE_RANGE_45_54", included: true },
                    { id: 5, name: "55 - 64", value: "AGE_RANGE_55_64", included: true },
                    { id: 6, name: "65+", value: "AGE_RANGE_65_UP", included: true },
                    { id: 7, name: "Undetermined", value: "AGE_RANGE_UNDETERMINED", included: true }
                ],
            });
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.form.set();
        },
        onNewLinkedin: async (existing_campaign) => {
            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);
            if (!existing_campaign) {
                this.state.campaigns.push({
                    id: campaign_id,
                    level: 'campaign',
                    channel: "linkedin",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate,
                    end_date: this.state.order.endDate,
                    campaign_budget: { id: 1, name: 'No', value: false }
                });
            }
            this.state.adgroups.push({
                id: adgroup_id,
                level: 'adgroup',
                channel: "linkedin",
                campaign: existing_campaign ? existing_campaign : campaign_id,
                name: "Adset",
                start_date: this.state.order.startDate,
                end_date: this.state.order.endDate,
                age: [
                    {
                        id: 2,
                        name: "25 to 34",
                        facetUrn: "urn:li:adTargetingFacet:ageRanges",
                        value: "urn:li:ageRange:(25,34)",
                        included: true
                    },
                    {
                        id: 3,
                        name: "35 to 54",
                        facetUrn: "urn:li:adTargetingFacet:ageRanges",
                        value: "urn:li:ageRange:(35,54)",
                        included: true
                    },
                    {
                        id: 4,
                        name: "55+",
                        facetUrn: "urn:li:adTargetingFacet:ageRanges",
                        value: "urn:li:ageRange:(55,2147483647)",
                        included: true
                    }
                ],
                //TRAFFIC SETUP
                adgroup_type: { id: 1, name: 'Image ads', value: "STANDARD_UPDATE" },
                objective: {
                    name: "Website visits",
                    value: "WEBSITE_VISIT",
                    optimization_goals: [
                        {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPM",
                                    set_cost: true
                                }
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ],
                        }
                    ]
                },
                optimization_goal: {
                    name: "Landing page click (recommended)",
                    bidding_strategies: [
                        {
                            name: "Maximum delivery (recommended)",
                            value: "MAX_CLICK",
                            cost_type: "CPM",
                            set_cost: false
                        },
                        {
                            name: "Cost cap",
                            value: "TARGET_COST_PER_CLICK",
                            cost_type: "CPM",
                            set_cost: true
                        }
                    ]
                },
                bidding_strategy: {
                    name: "Maximum delivery (recommended)",
                    value: "MAX_CLICK",
                    cost_type: "CPM",
                    set_cost: false
                },
                adgroup_budget: { id: 1, name: 'Daily budget', value: "daily" },
                daily_budget: 0,
                total_budget: 0
            });
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.form.set();
        },
        onNewFacebook: async (existing_campaign) => {
            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);
            if (!existing_campaign) {
                this.state.campaigns.push({
                    id: campaign_id,
                    level: 'campaign',
                    channel: "facebook",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate,
                    end_date: this.state.order.endDate,
                    budget_optimization: { id: 2, name: "Yes", value: true },
                    //TRAFFIC SETUP
                    objective: { id: 2, name: 'Traffic', value: "OUTCOME_TRAFFIC" },
                    special_ad_category: { id: 1, name: 'None', value: "NONE" },
                    budget_optimization_type: { id: 1, name: "Daily budget", value: 'daily' },
                    daily_budget: 0,
                    add_spend_limit: { id: 1, name: "No", value: false },
                    ios: { id: 0, name: "No", value: false },
                    app_promotion_type: { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" }
                });
            }
            this.state.adgroups.push({
                id: adgroup_id,
                level: 'adgroup',
                channel: "facebook",
                campaign: existing_campaign ? existing_campaign : campaign_id,
                name: "Adset",
                start_date: this.state.order.startDate,
                end_date: this.state.order.endDate,
                //TRAFFIC SETUP
                campaign_objective: { id: 2, name: 'Traffic', value: "OUTCOME_TRAFFIC" },
                destination_type: { id: 1, name: "Website", value: "WEBSITE" },
                optimization_goal: { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                billing_event: { id: 2, name: "Impressions", value: "IMPRESSIONS" },
                gender: { id: 1, name: "All genders", value: [] },
                age_min: { id: 18, name: "18", value: "18" },
                age_max: { id: 65, name: "65", value: "65" },
                adset_budget: { id: 2, name: "No", value: false },
                daily_budget: 0,
                lifetime_budget: 0,
                budget_type: { id: 1, name: "Daily budget", value: 'daily' },
                spend_limit: { id: 2, name: "No", value: false },
                auto_placements: { id: 1, name: "Yes (recommended)", value: true }
            });
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.form.set();
        },
        onNewTikTok: async (existing_campaign) => {
            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);
            if (!existing_campaign) {
                this.state.campaigns.push({
                    id: campaign_id,
                    level: 'campaign',
                    channel: "tiktok",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate,
                    end_date: this.state.order.endDate,
                    budget_optimization: { id: 1, name: "No", value: false },
                    //TRAFFIC SETUP
                    objective: { id: 2, name: 'Traffic', value: "TRAFFIC" },
                });
            }
            this.state.adgroups.push({
                id: adgroup_id,
                level: 'adgroup',
                channel: "tiktok",
                campaign: existing_campaign ? existing_campaign : campaign_id,
                start_date: this.state.order.startDate,
                end_date: this.state.order.endDate,
                name: "Adset",
                //TRAFFIC SETUP
                budget_mode: { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                goal: { id: 8, name: 'Click', value: "CLICK" },
                billing_event: { id: 1, name: 'CPC', value: "CPC" },
                gender: { id: 1, name: "All genders", value: "GENDER_UNLIMITED" },
                ages: [{ id: 1, name: "All ages", value: "GENDER_UNLIMITED", included: true }]
            });
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.form.set();
        },
        onNewTwitter: async (existing_campaign) => {
            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);
            if (!existing_campaign) {
                this.state.campaigns.push({
                    id: campaign_id,
                    level: 'campaign',
                    channel: "twitter",
                    name: this.state.wizard.name,
                    start_date: moment(this.state.order.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.state.order.endDate).format("YYYY-MM-DD"),
                    budget_optimization: { id: 1, name: "No", value: false },
                    objective: { id: 8, name: "Website Clicks", value: "WEBSITE_CLICKS" },
                });
            }
            this.state.adgroups.push({
                id: adgroup_id,
                level: 'adgroup',
                channel: "twitter",
                campaign: existing_campaign ? existing_campaign : campaign_id,
                start_date: moment(this.state.order.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.state.order.endDate).format("YYYY-MM-DD"),
                name: "Adset",
                //TRAFFIC SETUP
                adgroup_budget_optimization: { id: 2, name: "No", value: false },
                daily_budget: 0,
                total_budget: 0,
                gender: { id: 1, name: "All genders", value: null },
                age_min: { id: 1, name: "13", value: 13 },
                age_max: { id: 5, name: "And up", value: null },
                goal: { id: 6, name: "Link clicks", value: "LINK_CLICKS" },
                objective: { id: 8, name: "Website Clicks", value: "WEBSITE_CLICKS" },
            });
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.form.set();
        },
        */
