import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import InputTailwind from './inputTailwind';

class UpdateAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: ""
        };
    }

    componentDidMount() {
        this.setState({
            agent: this.props.agent
        });
    }

    functions = {
        updateAgent: async () => {
            let agent = {
                password: this.state.password
            };
            try {
                await this.calls.updateAgent(agent);
                this.props.onCreated();
            } catch (error) {
                this.setState({
                    error: error.body.message
                });
                this.props.onError();
            }
        }
    };

    calls = {
        updateAgent: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPassword?agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid">
                                <div className={"col-span-12"}>
                                    <InputTailwind
                                        label={"Password"}
                                        value={this.state.password}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                password: value
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.state.error &&
                                    <div className={"col-span-12"}>
                                        <div className="bg-red-100 p-4 rounded-md">
                                            <div className="text-sm text-red-500">
                                                {this.state.error}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default UpdateAgent;