import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import SwitchTailwind from './switchTailwind';
import currencies from "../assets/json/currencies.json";
import CopyButton from './copyButton';

class AccountBidtheatre extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            message: "",
            me: {},
            is_manager: false,
            loading: false,
            loading_advertisers: false,
            advertiser: {},
            advertisers: [],
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            healthcheck: {},
            owner: false,
            disabled: false,
            throttling: {},
            username: "",
            password: "",
            currency: {},
            currencies: currencies
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user,
            me: this.props.me,
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.bidtheatre) {
            this.functions.getAgents(true);
        }
    }

    componentWillReceiveProps(nextprops) {
        this.setState({
            is_manager: nextprops.isManager,
            user: nextprops.user,
            me: nextprops.me,
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    functions = {
        login: async () => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    username: this.state.username,
                    password: this.state.password,
                    channel: "bidtheatre"
                });
                await self.promisedSetState({
                    user: { ...this.state.user, ...{ bidtheatre: { authenticated: true } } },
                    loading: false,
                    show_login: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: error.body && error.body.message ? error.body.message : "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "bidtheatre"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ bidtheatre: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listAdvertisers: async () => {
            await this.promisedSetState({
                loading_advertisers: true
            });
            try {
                let response = await this.calls.listAdvertisers();
                await this.promisedSetState({
                    advertisers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_advertisers: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.advertiser.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    advertiser: this.state.advertiser
                });
                this.state.client.bidtheatre = {
                    defaultAgent: this.state.agent,
                    advertiserId: this.state.advertiser.id,
                    advertiserName: this.state.advertiser.name,
                    currency: this.state.currency.id
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    currency: {},
                    client: this.state.client
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.bidtheatre;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        updateCurrency: async () => {
            let data = {
                channel: 'bidtheatre',
                currency: this.state.currency.id
            }
            await this.promisedSetState({
                loading_update: true
            });
            await this.calls.updateClientAccount(data);
            this.state.client.bidtheatre.currency = this.state.currency.id;
            await this.promisedSetState({
                client: this.state.client,
                loading_update: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/bidtheatre/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/bidtheatre/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listAdvertisers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/bidtheatre/listAdvertisers?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=bidtheatre";
            return apiRegister.call(options, url);
        },
        updateClientAccount: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateClientAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative">

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Bidtheatre"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-bidtheatre-500 rounded-md flex justify-center items-center">
                        <div className="w-6 h-6">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Bidtheatre
                        </div>
                        {
                            !this.state.user &&
                            <div className="text-sm text-gray-500">
                                Connect Bidtheatre to this client
                            </div>
                        }
                        {
                            this.state.user &&
                            <div className="text-sm text-gray-500">
                                Connect Bidtheatre to this account
                            </div>
                        }
                    </div>
                    {
                        !this.state.user &&
                        this.state.client.bidtheatre &&
                        (this.state.is_manager || (!this.state.is_manager && this.state.client.bidtheatre.defaultAgent && this.state.client.bidtheatre.defaultAgent.id === this.state.me.id)) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={this.state.client.bidtheatre ? true : false}
                                onSwitch={async () => {
                                    if (!this.state.client.bidtheatre) {
                                        this.state.client.bidtheatre = {};
                                        await this.promisedSetState({
                                            client: this.state.client
                                        });
                                    } else {
                                        this.functions.disconnectFromClient();
                                    }
                                }}
                            />
                            {
                                this.state.loading_account &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    !this.state.user &&
                    < div className="mt-5">
                        <DropdownTailwind
                            label={"Default agent"}
                            small={false}
                            locked={this.state.client.bidtheatre}
                            skipInternalSearch={this.state.is_manager}
                            loader={this.state.loading_agents}
                            loadingPagination={this.state.loading_agents_pagination}
                            loadingSearch={this.state.loading_agents_search}
                            total={this.state.total}
                            searchInput={true}
                            placeholder={"..."}
                            defaultOptions={this.state.me.bidtheatre && this.state.me.bidtheatre.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                            pagination={this.state.total > (this.state.page * this.state.limit)}
                            selected={this.state.client.bidtheatre && this.state.client.bidtheatre.defaultAgent ? this.state.client.bidtheatre.defaultAgent : this.state.agent}
                            options={this.state.agents}
                            onChange={async (value) => {
                                await this.promisedSetState({
                                    agent: value,
                                    advertiser: {}
                                });
                                this.functions.listAdvertisers();
                            }}
                            onPagination={async () => {
                                if (!this.state.loading_agents_pagination) {
                                    await this.promisedSetState({
                                        page: this.state.page + 1
                                    });
                                    this.functions.getAgents(false, true, false);
                                }
                            }}
                            onSearch={async (value) => {
                                if (value && value === "") {
                                    await this.promisedSetState({
                                        search: "",
                                        page: 1
                                    });
                                    this.functions.getAgents(false, false, true);
                                } else {
                                    if (this.state.throttling.current) {
                                        //SKIP
                                    } else {
                                        if (value && value === "") {
                                            await this.promisedSetState({
                                                search: value,
                                                page: 1
                                            });
                                            this.functions.getAgents(false, false, true);
                                        } else {
                                            this.state.throttling.current = true;
                                            await this.promisedSetState({
                                                search: value,
                                                throttling: this.state.throttling
                                            });
                                            setTimeout(async () => {
                                                this.state.throttling.current = false;
                                                await this.promisedSetState({
                                                    throttling: this.state.throttling
                                                });
                                                await this.promisedSetState({
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            }, 300);
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                }
                {
                    this.state.client.id &&
                    (this.state.client.bidtheatre || this.state.agent.id !== 0) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                label={this.state.client.bidtheatre && this.state.client.bidtheatre.advertiserId ? "Advertiser" : "Advertisers"}
                                loader={this.state.loading_advertisers}
                                searchInput={true}
                                locked={this.state.client.bidtheatre}
                                selected={this.state.client.bidtheatre && this.state.client.bidtheatre.advertiserId ? { name: this.state.client.bidtheatre.advertiserName } : (this.state.advertiser.id ? this.state.advertiser : { name: "Select advertiser" })}
                                options={this.state.advertisers}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        advertiser: value
                                    });
                                }}
                            />
                        </div>
                        <CopyButton
                            buttonText="Copy"
                            disabled={!((this.state.client.bidtheatre && this.state.client.bidtheatre.advertiserId) || this.state.advertiser.id)}
                            copyText={(this.state.client.bidtheatre && this.state.client.bidtheatre.advertiserId) ? this.state.client.bidtheatre.advertiserId : this.state.advertiser.id}
                        />
                    </div>
                }
                {
                    this.state.client.id &&
                    (this.state.advertiser.id || (this.state.client.bidtheatre && this.state.client.bidtheatre.advertiserId)) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                searchInput={true}
                                label={"Currency"}
                                selected={this.state.currency.id
                                    ? this.state.currency
                                    : this.state.client.bidtheatre && this.state.client.bidtheatre.advertiserId
                                        ? { name: this.state.client.bidtheatre.currency }
                                        : { name: "Select currency" }}
                                options={this.state.currencies}
                                onChange={async (value) => {
                                    this.setState({
                                        currency: value
                                    });
                                }}
                            />
                        </div>
                        {
                            this.state.client.bidtheatre &&
                            this.state.currency && this.state.currency.id && this.state.client.bidtheatre.currency !== this.state.currency.name &&
                            <button onClick={async () => {
                                if (this.state.currency && this.state.currency.id && this.state.client.bidtheatre.currency !== this.state.currency.name) {
                                    await this.functions.updateCurrency();
                                }
                            }} className={"mt-5.5 ml-2 relative bg-custom-input h-12 justify-center border-1.5 rounded-md w-32 text-sm font-medium"}>
                                <span>Update</span>
                                {
                                    this.state.loading_update &&
                                    <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                        <div style={{ borderTopColor: "transparent" }}
                                            className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        }
                    </div>
                }
                {
                    this.state.client.id &&
                    !this.state.client.bidtheatre &&
                    this.state.advertiser.id &&
                    <div className="flex w-full flex-row">
                        <button
                            onClick={() => {
                                if (!this.state.loading_account) {
                                    this.functions.connectToClient();
                                }
                            }}
                            className={(this.state.advertiser.id ? "bg-bidtheatre-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Save Bidtheatre account</span>
                            {
                                this.state.loading_account &&
                                <div className={"bg-bidtheatre-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.show_login &&
                    !this.state.client.id &&
                    (!this.state.user.bidtheatre || (this.state.user.bidtheatre && !this.state.user.bidtheatre.authenticated)) &&
                    <div className="mt-5">
                        <button onClick={() => {
                            this.setState({
                                show_login: true
                            })
                        }} className={(!(this.state.user.bidtheatre && this.state.user.bidtheatre.authenticated) ?
                            "cursor-pointer bg-bidtheatre-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        }
                        >
                            <span>Login with Bidtheatre</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.bidtheatre && this.state.user.bidtheatre.authenticated) ? "bg-bidtheatre-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    this.state.show_login &&
                    <div className="grid grid-cols-12 gap-4 mt-5">
                        <div className="col-span-6">
                            <InputTailwind
                                label={"Username"}
                                disabled={false}
                                type={"text"}
                                value={this.state.username}
                                onChange={(value) => {
                                    this.setState({
                                        username: value
                                    })
                                }}
                            />
                        </div>
                        <div className="col-span-6">
                            <InputTailwind
                                label={"Password"}
                                type={"password"}
                                disabled={false}
                                value={this.state.password}
                                onChange={(value) => {
                                    this.setState({
                                        password: value
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.show_login &&
                    <div className="mt-5">
                        <button onClick={() => {
                            if (!this.state.loading) {
                                this.functions.login();
                            }
                        }} className={(true ?
                            "cursor-pointer bg-bidtheatre-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        }
                        >
                            <span>Login</span>
                            {
                                this.state.loading &&
                                <div className={(true ? "bg-bidtheatre-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.client.id &&
                    this.state.user.bidtheatre && this.state.user.bidtheatre.authenticated &&
                    <div className="mt-5">
                        <button
                            onClick={() => {
                                this.setState({
                                    remove: true
                                });
                            }}
                            className={(!(this.state.user.bidtheatre && this.state.user.bidtheatre.authenticated) ?
                                "cursor-pointer bg-bidtheatre-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Remove</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.bidtheatre && this.state.user.bidtheatre.authenticated) ? "bg-bidtheatre-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    (this.state.error) &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.message}
                    </div>
                }
                {
                    this.state.healthcheck && this.state.healthcheck.bidtheatre && this.state.healthcheck.bidtheatre.status != 200 &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        Token Check: {this.state.healthcheck.bidtheatre.message}. We recommend to sign out and sign in again.
                    </div>
                }
                {
                    !this.state.user &&
                    this.state.client.bidtheatre &&
                    !(this.state.is_manager || (!this.state.is_manager && this.state.client.bidtheatre && this.state.client.bidtheatre.defaultAgent && this.state.client.bidtheatre.defaultAgent.id === this.state.me.id)) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You are not "default agent" or "agency manager"
                    </div>
                }
                {
                    !this.state.user &&
                    !this.state.is_manager &&
                    !this.state.client.bidtheatre &&
                    !(this.state.me.bidtheatre && this.state.me.bidtheatre.authenticated) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You have not authenticated Bidtheatre, go to My Profile.
                    </div>
                }
                {
                    !this.state.user &&
                    this.state.is_manager &&
                    !this.state.client.bidtheatre &&
                    this.state.agents.length < 1 &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! No available agents who has authenticated Bidtheatre
                    </div>
                }
            </div>
        )
    }
}

export default AccountBidtheatre;
