import React, { Component } from 'react';
import { CameraIcon, CheckIcon, GiftIcon, SwitchVerticalIcon, VideoCameraIcon, AnnotationIcon, ChevronDoubleUpIcon, ChevronDoubleDownIcon, SwitchHorizontalIcon, DeviceMobileIcon, LightBulbIcon, SparklesIcon, TrashIcon, PhotographIcon, FilmIcon, ChevronUpIcon, InformationCircleIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import UploadImageTailwind from './uploadImageTailwind';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import FacebookCTA from '../assets/json/facebook_cta.json';
import tiktokCTA from '../assets/json/tiktok_cta.json';
import snapchatCTA from '../assets/json/snapchat_cta.json';
import linkedinCTA from '../assets/json/linkedin_cta.json';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { BeatLoader } from 'react-spinners';
import PreviewSectionFacebook from './previewSectionFacebook';
import PreviewSectionLinkedin from './previewSectionLinkedin';
import PreviewSectionTwitter from './previewSectionTwitter';
import PreviewSectionTikTok from './previewSectionTikTok';
import PreviewSectionSnapchat from './previewSectionSnapchat';
import SwitchTailwind from './switchTailwind';
import WarningModalTailwind from './warningModalTailwind';

class CreateOrderCreativeEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: false,
            utm: false,
            selected_channels: [],
            utm_channels: [],
            utm_values: {
                facebook: "",
                twitter: "",
                linkedin: "",
                tiktok: "",
            },
            utm_error: {
                facebook: false,
                twitter: false,
                linkedin: false,
                tiktok: false,
                snapchat: false
            },
            type: "",
            files: [],
            assetItems: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            apps: [],
            pixels: [],
            channels: {},
            identity_name: "",
            identity_image: {},
            new_identity: false,
            instagram_post: false,
            ai_suggetion: false,
            ad_type: "",
            headline: "",
            body: "",
            name: "",
            name_by_channels: {
                facebook: "",
                linkedin: "",
                tiktok: "",
                twitter: "",
                snapchat: ""
            },
            body_by_channels: {
                facebook: "",
                linkedin: "",
                tiktok: "",
                twitter: "",
                snapchat: ""
            },
            headline_by_channels: {
                facebook: "",
                linkedin: "",
                tiktok: "",
                twitter: "",
                snapchat: ""
            },
            website_by_channels: {
                facebook: "",
                linkedin: "",
                tiktok: "",
                twitter: "",
                snapchat: ""
            },
            preview: {
                facebook: { id: 1, value: "feed", name: "Feed", ratio: "1:1" },
                instagram: { id: 1, value: "feed", name: "Feed", ratio: "1:1" },
                twitter: { id: 1, value: "feed", name: "Feed" },
                linkedin: { id: 1, value: "feed", name: "Feed" },
                tiktok: { id: 1, value: "feed", name: "Feed" },
                snapchat: { id: 1, value: "feed", name: "Stories" },
                // snapchat: { id: 2, value: "feed", name: "Stories" },
            },
            previews: {
                facebook: [{ id: 1, value: "feed", name: "Feed" }, { id: 2, value: "story", name: "Story" }],
                instagram: [{ id: 1, value: "feed", name: "Feed" }, { id: 2, value: "story", name: "Story" }],
                twitter: [{ id: 1, value: "feed", name: "Feed" }],
                linkedin: [{ id: 1, value: "feed", name: "Feed" }],
                tiktok: [{ id: 1, value: "feed", name: "Feed" }],
                // snapchat: [{ id: 1, value: "spotlight", name: "Spotlight" }, { id: 2, value: "stories", name: "Stories" }],
                snapchat: [{ id: 1, value: "feed", name: "Stories" }, { id: 2, value: "feed", name: "Spotlight" }],
            },
            preview_channel: "facebook",
            description: "",
            origin: "",
            caption: "",
            website: "",
            tracking: {
                app: { id: 0, name: "Click here ..." },
                pixel: { id: 0, name: "No pixel", value: null },
            },
            cta: {
                facebook: { id: 0, name: "Click here ..." },
                tiktok: { id: 0, name: "Click here ..." },
                linkedin: { id: 0, name: "Click here ..." },
                twitter: { id: 0, name: "Click here ..." },
                instagram: { id: 0, name: "" },
                snapchat: { id: 0, name: "Click here ..." },
            },
            ctas: {
                facebook: FacebookCTA.filter(item => { return item.objective.length > 0 }),
                tiktok: tiktokCTA,
                linkedin: linkedinCTA,
                snapchat: snapchatCTA
            },
            page: {
                facebook: { id: 0, name: "Click here ..." },
                tiktok: { id: 0, name: "Click here ..." },
                snapchat: { id: 0, name: "Click here ..." },
                instagram: { id: 0, name: "Click here ..." },
                linkedin: { id: 0, name: "Click here ..." },
                twitter: { id: 0, name: "Click here ..." }
            },
            pages: {
                facebook: [],
                tiktok: [],
                snapchat: [],
                instagram: [],
                twitter: [],
                linkedin: []
            },
            loading_scraper: false,
            carousel_menu: "Main ad",
            platform: { id: 6, name: "Social Media", value: "Social Media" },
            platforms: [
                { id: 1, name: "Facebook", value: "Facebook" },
                { id: 2, name: "Instagram", value: "Instagram" },
                { id: 3, name: "TikTok", value: "TikTok" },
                { id: 4, name: "Linkedin", value: "Linkedin" },
                { id: 5, name: "Twitter", value: "Twitter" },
                { id: 6, name: "Social Media", value: "Social Media" },
                { id: 7, name: "Snapchat", value: "Snapchat" },

            ],
            tiktok_app_pages: [],
            tiktok_creative_authorized: { id: 2, name: "Disabled", value: false },
            tiktok_destination: { id: 1, name: "Website", value: "website" },
            tiktok_app_profile: { id: 0, name: "Click here ..." },
            channel_menu: "",
            language: { id: 1, name: "English" },
            searchOpenAi: "",
            OpenAiCompare: "",
            OpenAiResult: [],
            loading_suggest: false,
            openAi_error: false,
            amount: 125,
            temperature: { id: 2, name: "Mid", value: 0.5 },
            snapchat_brandname: "",
            snapchat_app_name: "",
            snapchat_url_android: "",
            snapchat_url_ios: "",
            snapchat_deep_link_uri: "",
            snapchat_iosCustom_url: "",
            snapchat_iosCustom: { id: 2, name: "Disabled", value: false },
            snapchat_standardButton: { id: 2, name: "Disabled", value: false },
            snapchat_favourit: { id: 2, name: "Disabled", value: false },
            snapchat_fallback_type: { id: 1, name: "App installation", value: "app_install" },
            snapchat_fallback_url: "",
            snapchat_app_icon: false,
            snap_brandname_error: false,
            snapchat_phone: "",
            snapchat_message: "",
            snapchat_attachment_options: [
                //{ id: 1, name: "None", value: "NONE", allowed: ["awareness"] },
                { id: 2, name: "Website", value: "WEB_VIEW", allowed: ["awareness", "traffic"] },
                { id: 3, name: "App install", value: "APP_INSTALL", allowed: ["app_promotion"] },
                { id: 4, name: "Deep link", value: "DEEP_LINK", allowed: ["app_promotion"] },
                // { id: 5, name: "AR lens", value: "AD_TO_LENS", allowed: ["awareness", "engagement"] },
                // { id: 6, name: "Call", value: "AD_TO_CALL", allowed: ["awareness", "engagement"] },
                // { id: 7, name: "Text", value: "AD_TO_MESSAGE", allowed: ["awareness", "engagement"] },
                // { id: 7, name: "Lead form", value: "AD_TO_MESSAGE", allowed: ["awareness"] },
            ],
            snapchat_attachment_error: false,
            snapchat_shareable: { id: 1, name: "Enabled", value: true },
            snapchat_status: { id: 1, name: "Active", value: "ACTIVE" },
            snapchat_attachment: { id: 2, name: "Website", value: "WEB_VIEW", allowed: ["awareness", "traffic"] },
            // AUTOFILL MAYBE REQUIRES SDK ON CLIENT WEBSITE
            snapchat_autofill: { id: 2, name: "Disabled", value: false },
            snapchat_prefetching: { id: 2, name: "Disabled", value: false },
            snapchat_impression_tags: [],
            snapchat_swipe_tags: [],
            snapchat_adType: { id: 1, name: "Single Image or Video", value: "feed" },
            snapchat_playStyle: { id: 1, name: "Loop", value: "LOOPING" },
            fb_lead_form: false,
            lead_forms: [],
            selected_lead: { id: 0, name: "Click here ..." },
            loading_new_lead: false,
            last_card: { id: 1, name: "Yes", value: true },
            auto_cards: { id: 1, name: "Yes", value: true },
            validation: {},
            open_slide: 0,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            files: this.props.files ? this.props.files : [],
            carousel: this.props.carousel,
            merge: this.props.merge,
            catalog: this.props.catalog,
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            channels: this.props.channels ? this.props.channels : {},
            client: this.props.client,
            assetItems: this.props.assetItems ? this.props.assetItems : [],
            selected_channels: this.props.selected_channels ? this.props.selected_channels : [],

        });

        if (this.state.assetItems.length > 0) {
            await this.promisedSetState({
                files: this.state.assetItems,
                step: true,
            });
        }
        if (this.state.merge && this.state.merge.error) {
            this.props.onDisable(true);
        }

        //IF ONLY ONE FILE SELETED, PRESET ALL AVALUES
        if (this.state.files.length === 1) {
            await this.promisedSetState({
                headline: this.state.files[0].headline ? this.state.files[0].headline : "",
                body: this.state.files[0].body ? this.state.files[0].body : "",
                name: this.state.files[0].name ? this.state.files[0].name : this.state.name,
                website: this.state.files[0].website ? this.state.files[0].website : "",
                description: this.state.files[0].description ? this.state.files[0].description : "",
                origin: this.state.files[0].origin ? this.state.files[0].origin : "",
                caption: this.state.files[0].caption ? this.state.files[0].caption : "",
                cta: this.state.files[0].cta ? this.state.files[0].cta : this.state.cta,
                page: this.state.files[0].page ? this.state.files[0].page : this.state.page,
                instagram_post: this.state.files[0].instagram_post ? this.state.files[0].instagram_post : false,
                type: this.state.files[0].type ? this.state.files[0].type : "",
                ad_type: this.state.files[0].ad_type ? this.state.files[0].ad_type : "",
                tracking: this.state.files[0].tracking ? this.state.files[0].tracking : this.state.tracking,
                utm_values: this.state.files[0].utm_values ? this.state.files[0].utm_values : this.state.utm_values,
                OpenAiResult: this.state.files[0].OpenAiResult ? this.state.files[0].OpenAiResult : this.state.OpenAiResult,
                name_by_channels: this.state.files[0].name_by_channels ? this.state.files[0].name_by_channels : this.state.name_by_channels,
                body_by_channels: this.state.files[0].body_by_channels ? this.state.files[0].body_by_channels : this.state.body_by_channels,
                website_by_channels: this.state.files[0].website_by_channels ? this.state.files[0].website_by_channels : this.state.website_by_channels,
                headline_by_channels: this.state.files[0].headline_by_channels ? this.state.files[0].headline_by_channels : this.state.headline_by_channels,
                snapchat_brandname: this.state.files[0].snapchat_brandname ? this.state.files[0].snapchat_brandname : this.state.snapchat_brandname,
                snapchat_app_name: this.state.files[0].snapchat_app_name ? this.state.files[0].snapchat_app_name : this.state.snapchat_app_name,
                snapchat_url_android: this.state.files[0].snapchat_url_android ? this.state.files[0].snapchat_url_android : this.state.snapchat_url_android,
                snapchat_url_ios: this.state.files[0].snapchat_url_ios ? this.state.files[0].snapchat_url_ios : this.state.snapchat_url_ios,
                snapchat_iosCustom: this.state.files[0].snapchat_iosCustom ? this.state.files[0].snapchat_iosCustom : this.state.snapchat_iosCustom,
                snapchat_iosCustom_url: this.state.files[0].snapchat_iosCustom_url ? this.state.files[0].snapchat_iosCustom_url : this.state.snapchat_iosCustom_url,
                snapchat_deep_link_uri: this.state.files[0].snapchat_deep_link_uri ? this.state.files[0].snapchat_deep_link_uri : this.state.snapchat_deep_link_uri,
                snapchat_fallback_type: this.state.files[0].snapchat_fallback_type ? this.state.files[0].snapchat_fallback_type : this.state.snapchat_fallback_type,
                snapchat_fallback_url: this.state.files[0].snapchat_fallback_url ? this.state.files[0].snapchat_fallback_url : this.state.snapchat_fallback_url,
                snapchat_app_icon: this.state.files[0].snapchat_app_icon ? this.state.files[0].snapchat_app_icon : this.state.snapchat_app_icon,
                snapchat_favourit: this.state.files[0].snapchat_favourit ? this.state.files[0].snapchat_favourit : this.state.snapchat_favourit,
                snapchat_standardButton: this.state.files[0].snapchat_standardButton ? this.state.files[0].snapchat_standardButton : this.state.snapchat_standardButton,
                snapchat_attachment: this.state.files[0].snapchat_attachment ? this.state.files[0].snapchat_attachment : this.state.snapchat_attachment,
                snapchat_status: this.state.files[0].snapchat_status ? this.state.files[0].snapchat_status : this.state.snapchat_status,
                snapchat_shareable: this.state.files[0].snapchat_shareable ? this.state.files[0].snapchat_shareable : this.state.snapchat_shareable,
                snapchat_autofill: this.state.files[0].snapchat_autofill ? this.state.files[0].snapchat_autofill : this.state.snapchat_autofill,
                snapchat_prefetching: this.state.files[0].snapchat_prefetching ? this.state.files[0].snapchat_prefetching : this.state.snapchat_prefetching,
                snapchat_phone: this.state.files[0].snapchat_phone ? this.state.files[0].snapchat_phone : this.state.snapchat_phone,
                snapchat_message: this.state.files[0].snapchat_message ? this.state.files[0].snapchat_message : this.state.snapchat_message,
                snapchat_attachment_error: this.state.files[0].snapchat_attachment_error ? this.state.files[0].snapchat_attachment_error : this.state.snapchat_attachment_error,
                snapchat_impression_tags: this.state.files[0].snapchat_impression_tags ? this.state.files[0].snapchat_impression_tags : this.state.snapchat_impression_tags,
                snapchat_swipe_tags: this.state.files[0].snapchat_swipe_tags ? this.state.files[0].snapchat_swipe_tags : this.state.snapchat_swipe_tags,
                snapchat_adType: this.state.files[0].snapchat_adType ? this.state.files[0].snapchat_adType : this.state.snapchat_adType,
                snapchat_playStyle: this.state.files[0].snapchat_playStyle ? this.state.files[0].snapchat_playStyle : this.state.snapchat_playStyle,
                selected_lead: this.state.files[0].selected_lead ? this.state.files[0].selected_lead : this.state.selected_lead,
                tiktok_app_profile: this.state.files[0].tiktok_app_profile ? this.state.files[0].tiktok_app_profile : this.state.tiktok_app_profile,
                tiktok_destination: this.state.files[0].tiktok_destination ? this.state.files[0].tiktok_destination : this.state.tiktok_destination,
                tiktok_creative_authorized: this.state.files[0].tiktok_creative_authorized ? this.state.files[0].tiktok_creative_authorized : this.state.tiktok_creative_authorized,
            });
        }

        if (this.state.type === "posts") {
            this.state.body_by_channels[this.state.files[0].channel] = this.state.body;
            await this.promisedSetState({
                utm_channels: Object.keys(this.state.channels).filter((item) => { return item === this.state.files[0].channel }),
                channel_menu: Object.keys(this.state.channels).filter((item) => { return item === this.state.files[0].channel })[0],
                preview_channel: Object.keys(this.state.channels).filter((item) => { return item === this.state.files[0].channel })[0],
                body_by_channels: this.state.body_by_channels
            });
        } else if (this.state.catalog || this.state.merge) {
            if (this.state.merge) {
                this.state.previews.facebook = [{ id: 1, value: "feed", name: "Feed (square)", ratio: "1:1" }, { id: 3, value: "feed", name: "Feed (horizontal)", ratio: "1.91:1" }, { id: 2, value: "story", name: "Story", ratio: "9:16" }];
                this.state.previews.instagram = [{ id: 1, value: "feed", name: "Feed (square)", ratio: "1:1" }, { id: 3, value: "feed", name: "Feed (horizontal)", ratio: "1.91:1" }, { id: 2, value: "story", name: "Story", ratio: "9:16" }];
                if (this.state.merge.files.filter((item) => { return item.ratio === "1:1" }).length > 0) {
                    this.state.preview.facebook = { id: 1, value: "feed", name: "Feed (square)", ratio: "1:1" };
                    this.state.preview.instagram = { id: 1, value: "feed", name: "Feed (square)", ratio: "1:1" };
                } else {
                    this.state.preview.facebook = { id: 3, value: "feed", name: "Feed (horizontal)", ratio: "1.91:1" };
                    this.state.preview.instagram = { id: 3, value: "feed", name: "Feed (horizontal)", ratio: "1.91:1" };
                }
            } else {
                this.state.previews.facebook = [{ id: 1, value: "feed", name: "Feed (square)", ratio: "1:1" }];
                this.state.previews.instagram = [{ id: 1, value: "feed", name: "Feed (square)", ratio: "1:1" }];
                this.state.preview.facebook = { id: 1, value: "feed", name: "Feed (square)", ratio: "1:1" };
                this.state.preview.instagram = { id: 1, value: "feed", name: "Feed (square)", ratio: "1:1" };
            }

            await this.promisedSetState({
                utm_channels: Object.keys(this.state.channels).filter((item) => { return item === "facebook" }),
                channel_menu: Object.keys(this.state.channels).filter((item) => { return item === "facebook" })[0],
                preview_channel: Object.keys(this.state.channels).filter((item) => { return item === "facebook" })[0],
                preview: this.state.preview,
                previews: this.state.previews,

            });

        } else if (this.state.carousel) {
            if (this.state.carousel.auto_cards || this.state.carousel.last_card) {
                await this.promisedSetState({
                    ...(this.state.carousel.auto_cards) && { auto_cards: this.state.carousel.auto_cards },
                    ...(this.state.carousel.last_card) && { last_card: this.state.carousel.last_card },
                })
            }
            if (this.state.selected_channels.length < 1) {
                await this.promisedSetState({
                    utm_channels: Object.keys(this.state.channels).filter((item) => { return this.state.carousel.allowed_channels[item] }),
                    channel_menu: Object.keys(this.state.channels).filter((item) => { return this.state.carousel.allowed_channels[item] })[0],
                    preview_channel: Object.keys(this.state.channels).filter((item) => { return this.state.carousel.allowed_channels[item] })[0],

                });
            } else {
                await this.promisedSetState({
                    utm_channels: this.state.selected_channels.filter((item) => { return this.state.carousel.allowed_channels[item] }),
                    channel_menu: this.state.selected_channels.filter((item) => { return this.state.carousel.allowed_channels[item] })[0],
                    preview_channel: this.state.selected_channels.filter((item) => { return this.state.carousel.allowed_channels[item] })[0],

                });
            }

        } else {
            if (!this.state.step) {
                await this.promisedSetState({
                    utm_channels: Object.keys(this.state.channels).filter((item) => { return this.state.files[0].allowed_channels[item] }),
                    channel_menu: Object.keys(this.state.channels).filter((item) => { return this.state.files[0].allowed_channels[item] })[0],
                    preview_channel: Object.keys(this.state.channels).filter((item) => { return this.state.files[0].allowed_channels[item] })[0],

                });
            } else {
                await this.promisedSetState({
                    utm_channels: this.state.selected_channels.filter((item) => { return item !== "google" }),
                    channel_menu: this.state.selected_channels.filter((item) => { return item !== "google" })[0],
                    preview_channel: this.state.selected_channels.filter((item) => { return item !== "google" })[0],

                });
            }
        }

        //LIST IDENTITIES
        if (this.state.channels.tiktok) {
            this.functions.listTikTokIdentities();
            this.functions.listTikTokPages();
        }
        if (this.state.channels.snapchat) {
            this.functions.listSnapchatIdentities();
            // pixels? 
            // snap track id ?
        }
        if (this.state.channels.twitter) {
            this.functions.listTwitterPages();
        }
        if (this.state.channels.linkedin) {
            this.functions.listLinkedinPages();
        }
        if (this.state.channels.facebook) {
            this.functions.pixels();
            this.functions.listApps();
            this.functions.listFacebookPages();
            if (this.state.page.facebook && this.state.page.facebook.id !== 0 && this.state.instagram_post === false) {
                this.functions.listInstagramAccounts(this.state.page.facebook.id);
            }
            if (this.state.instagram_post) {
                this.state.page.instagram = this.state.files[0].instagram;
                await this.promisedSetState({
                    page: this.state.page
                });
            }
        }

        if (this.state.files.filter((item) => { return item.ratio === "9:16" }).length > 0) {
            this.state.preview.facebook = { id: 2, value: "story", name: "Story" };
            this.state.preview.instagram = { id: 2, value: "story", name: "Story" };
            await this.promisedSetState({
                preview: this.state.preview
            });
        }

        // CHECK THERE IS MORE THAN ONE HORIZONTAL FILE
        let horizontal_media = []
        if (this.state.merge && this.state.merge.files && this.state.merge.files.length > 0) {
            this.state.merge.files.forEach(file => {
                if (file.ratio === "1.91:1") {
                    horizontal_media.push(file);
                }
                if (file.ratio === "16:9") {
                    horizontal_media.push(file);
                }
            })

            // PUSH 16:9 TO STORY AND 1.91 TO HORIZONTAL
            if (horizontal_media.length > 1) {
                horizontal_media.forEach(file => {
                    if (file.ratio === "16:9") {
                        file.ratio = "9:16";
                    }
                })
            }
        }

        if (this.state.adgroups.filter((item) => { return item.channel === "facebook" && item.destination_type && item.destination_type.id === 5 }).length > 0) {
            await this.promisedSetState({
                fb_lead_form: true
            });
            this.functions.init();
            if (this.state.page.facebook.id !== 0) {
                this.functions.listLeadForms();
                if (this.state.selected_lead.id !== 0) {
                    this.state.ctas.facebook = [
                        { name: "Subscribe", id: "SUBSCRIBE", type: "SUBSCRIBE", objective: [1, 2, 3, 4, 5, 6] },
                        { name: "Apply Now", id: "APPLY_NOW", type: "APPLY_NOW", objective: [1, 2, 3, 4, 6] },
                        { name: "Book now", id: "BOOK_TRAVEL", type: "BOOK_TRAVEL", objective: [] },
                        { name: "Download", id: "DOWNLOAD", type: "DOWNLOAD", objective: [] },
                        { name: "Get offer", id: "GET_OFFER", type: "GET_OFFER", objective: [2, 3, 4, 6] },
                        { name: "Get quote", id: "GET_QUOTE", type: "GET_QUOTE", objective: [1, 2, 3, 6] },
                        { name: "Learn more", id: "LEARN_MORE", type: "LEARN_MORE", objective: [1, 2, 3, 4, 5, 6] },
                        { name: "Sign up", id: "SIGN_UP", type: "SIGN_UP", objective: [1, 2, 3, 4, 5, 6] }
                    ];
                    await this.promisedSetState({
                        ctas: this.state.ctas
                    });
                }
            }
        }
        if (this.state.OpenAiResult && Array.isArray(this.state.OpenAiResult) && this.state.OpenAiResult.length > 0) {

            this.props.onUpdateChat(this.state.OpenAiResult);
        }
        this.functions.updatePreview();

        console.log("createOrderCreativeEdit state", this.state)

    }

    functions = {
        init: () => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '3033879003330818',
                    version: 'v16.0',
                    status: true,
                    autoLogAppEvents: true,
                    xfbml: true,
                });
                window.FB.AppEvents.logPageView();
            }.bind(this);
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },
        updateOpenAi: async (data) => {
            await this.promisedSetState({
                OpenAiResult: data,
            });
        },
        updatePreview: async () => {
            try {
                let buffer = {};
                if (this.state.selected_lead.id !== 0) {
                    if (((this.state.name_by_channels.facebook === "" && this.state.files === 1) || this.state.headline_by_channels.facebook === "" || this.state.body_by_channels.facebook === "" || this.state.page.facebook.id === 0) && !this.state.merge && this.state.type !== "posts") {
                        buffer.facebook = true;
                    }
                } else {
                    if (((this.state.name_by_channels.facebook === "" && this.state.files === 1) || this.state.headline_by_channels.facebook === "" || this.state.body_by_channels.facebook === "" || !this.state.website_by_channels.facebook.includes("https://") || this.state.page.facebook.id === 0) && !this.state.merge && this.state.type !== "posts") {
                        buffer.facebook = true;
                    }
                }
                // if (((this.state.name_by_channels.facebook === "" && this.state.files === 1) || this.state.headline_by_channels.facebook === "" || this.state.body_by_channels.facebook === "" || !this.state.website_by_channels.facebook.includes("https://") || this.state.page.facebook.id === 0) && !this.state.merge && this.state.type !== "posts") {
                //     buffer.facebook = true;
                // }
                if (((this.state.name_by_channels.linkedin === "" && this.state.files === 1) || this.state.headline_by_channels.linkedin === "" || this.state.body_by_channels.linkedin === "" || !this.state.website_by_channels.linkedin.includes("https://") || this.state.page.linkedin.id === 0) && this.state.type !== "posts") {
                    buffer.linkedin = true;
                }
                if (((this.state.name_by_channels.tiktok === "" && this.state.files === 1) || this.state.body_by_channels.tiktok === "" || ((this.state.tiktok_destination.value === "website" && !this.state.website_by_channels.tiktok.includes("https://")) || (this.state.tiktok_destination.value === "app" && this.state.tiktok_app_profile.id === 0)) || this.state.page.tiktok.id === 0) && this.state.type !== "posts") {
                    buffer.tiktok = true;
                }
                if (((this.state.name_by_channels.twitter === "" && this.state.files === 1) || this.state.headline_by_channels.twitter === "" || this.state.body_by_channels.twitter === "" || !this.state.website_by_channels.twitter.includes("https://") || this.state.page.twitter.id === 0) && this.state.type !== "posts") {
                    buffer.twitter = true;
                }
                if ((this.state.name_by_channels.snapchat === "" && this.state.files === 1) || this.state.headline_by_channels.snapchat === "" || (!this.state.website_by_channels.snapchat.includes("https://") && this.state.snapchat_attachment.value === "WEB_VIEW") || this.state.page.snapchat.id === 0) {
                    buffer.snapchat = true;
                }
                this.props.updatePreview({
                    merge: this.state.merge,
                    carousel: this.state.carousel,
                    catalog: this.state.catalog,
                    files: this.state.files,
                    headline_by_channels: this.state.headline_by_channels,
                    body_by_channels: this.state.body_by_channels,
                    cta: this.state.cta,
                    page: this.state.page,
                    website_by_channels: this.state.website_by_channels,
                    description: this.state.description,
                    caption: this.state.caption,
                    preview_channel: this.state.preview_channel,
                    previews: this.state.previews,
                    preview: this.state.preview[this.state.preview_channel],
                    snapchat_brandname: this.state.snapchat_brandname,
                    snapchat_phone: this.state.snapchat_phone,
                    snapchat_message: this.state.snapchat_message,
                    snapchat_attachment: this.state.snapchat_attachment,
                    snapchat_status: this.state.snapchat_status,
                    snapchat_shareable: this.state.snapchat_shareable,
                    snapchat_autofill: this.state.snapchat_autofill,
                    snapchat_favourit: this.state.snapchat_favourit,
                    snapchat_prefetching: this.state.snapchat_prefetching,
                    utm_channels: this.state.utm_channels,
                    channel_menu: this.state.channel_menu,
                    tiktok_app: this.state.tiktok_destination.value === "app" ? true : false,
                    ...(this.state.snapchat_app_icon && { snapchat_app_icon: this.state.snapchat_app_icon.url }),
                    validation: buffer,
                })
            } catch (error) {
            }
        },
        swithChannel: async (channel) => {
            try {
                await this.promisedSetState({
                    channel_menu: channel,
                    preview_channel: channel
                });
                this.functions.updatePreview();
            } catch (error) {
            }
        },
        previewChannel: async (channel) => {
            try {
                await this.promisedSetState({
                    preview_channel: channel
                });
                this.functions.updatePreview();
            } catch (error) {
            }
        },
        addHeadlines: async (data) => {
            try {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].force) {
                        if (this.state.merge) {
                            this.state.merge.headlines[0] = { value: data[i].value[0] };
                        }
                        this.state.headline_by_channels[data[i].channel] = data[i].value[0];
                        this.state.channel_menu = data[i].channel;
                        this.state.preview_channel = data[i].channel;
                    } else {
                        if (this.state.merge) {
                            if (this.state.merge.headlines[0] && this.state.merge.headlines[0].value === "") {
                                this.state.merge.headlines[0].value = data[i].value[0];
                            } else if (!this.state.merge.headlines[0]) {
                                this.state.merge.headlines[0] = { value: data[i].value[0] };
                            }
                        }
                        if (this.state.headline_by_channels[data[i].channel] === "") this.state.headline_by_channels[data[i].channel] = data[i].value[0];

                    }
                }
                await this.promisedSetState({
                    merge: this.state.merge,
                    headline_by_channels: this.state.headline_by_channels,
                    channel_menu: this.state.channel_menu,
                    preview_channel: this.state.preview_channel,
                });
                this.functions.updatePreview();
            } catch (error) {

            }
        },
        removeHeadline: async (data) => {
            try {
                if (this.state.merge) {
                    this.state.merge.headlines[0].value = "";
                }
                this.state.headline_by_channels[data.channel] = "";
                await this.promisedSetState({
                    merge: this.state.merge,
                    headline_by_channels: this.state.headline_by_channels
                });
                this.functions.updatePreview();
            } catch (error) {
            }

        },
        addDescriptions: async (data) => {
            try {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].force) {
                        if (this.state.merge) {
                            this.state.merge.bodies[0] = { value: data[i].value[0] };
                        }
                        this.state.body_by_channels[data[i].channel] = data[i].value[0];
                        this.state.channel_menu = data[i].channel;
                        this.state.preview_channel = data[i].channel;
                    } else {
                        if (this.state.merge) {
                            if (this.state.merge.bodies[0] && this.state.merge.bodies[0].value === "") {
                                this.state.merge.bodies[0].value = data[i].value[0];
                            } else if (!this.state.merge.bodies[0]) {
                                this.state.merge.bodies[0] = { value: data[i].value[0] };
                            }
                        }
                        if (this.state.body_by_channels[data[i].channel] === "") this.state.body_by_channels[data[i].channel] = data[i].value[0];
                    }
                }
                await this.promisedSetState({
                    merge: this.state.merge,
                    body_by_channels: this.state.body_by_channels,
                    channel_menu: this.state.channel_menu,
                    preview_channel: this.state.preview_channel,
                });
                this.functions.updatePreview();
            } catch (error) {
            }
        },
        removeDescription: async (data) => {
            try {
                if (this.state.merge) {
                    this.state.merge.bodies[0].value = "";
                }
                this.state.body_by_channels[data.channel] = "";
                await this.promisedSetState({
                    merge: this.state.merge,
                    body_by_channels: this.state.body_by_channels
                });
                this.functions.updatePreview();
            } catch (error) {
            }

        },
        save: async () => {
            if (this.state.merge) {
                this.state.merge.name_by_channels = this.state.name_by_channels;
                this.state.merge.name = this.state.name;
                this.state.merge.page = this.state.page;
                this.state.merge.OpenAiResult = this.state.OpenAiResult;

                this.state.merge.selected_lead = this.state.selected_lead;
                //this.state.merge.tracking = this.state.tracking;
                //Tracking
                if (!this.state.merge.tracking) {
                    this.state.merge.tracking = JSON.parse(JSON.stringify(this.state.tracking));
                } else {
                    this.state.merge.tracking.pixel = this.state.tracking.pixel;
                    this.state.merge.tracking.app = this.state.tracking.app.id !== 0 ? this.state.tracking.app : this.state.merge.tracking.app;

                }

                if (this.state.selected_channels.length < 1) {
                    this.props.onSave([this.state.merge]);
                } else {
                    let buff = [];
                    this.state.selected_channels.map((item) => {
                        let b = JSON.parse(JSON.stringify(this.state.merge));
                        b.id = Math.floor((Math.random() * 9999999999) + 1);
                        b.selected = false;
                        b.allowed_channels = { [item]: true }
                        buff.push(b);
                    })
                    this.props.onAddCreative(buff);
                }

            } else if (this.state.carousel) {
                this.state.carousel.name = this.state.name;
                this.state.carousel.headline = this.state.headline;
                this.state.carousel.body = this.state.body;
                this.state.carousel.description = this.state.description;
                this.state.carousel.origin = this.state.origin;
                this.state.carousel.website = this.state.website;
                this.state.carousel.cta = this.state.cta;
                this.state.carousel.page = this.state.page;
                this.state.carousel.OpenAiResult = this.state.OpenAiResult;
                this.state.carousel.name_by_channels = this.state.name_by_channels;
                this.state.carousel.body_by_channels = this.state.body_by_channels;
                this.state.carousel.headline_by_channels = this.state.headline_by_channels;
                this.state.carousel.website_by_channels = this.state.website_by_channels;
                this.state.carousel.selected_lead = this.state.selected_lead;
                this.state.carousel.auto_cards = this.state.auto_cards;
                this.state.carousel.last_card = this.state.last_card;
                if (this.state.selected_channels.length < 1) {
                    console.log("createOrderCreativeEdit save if", this.state.carousel)
                    this.props.onSave([this.state.carousel]);
                } else {
                    let buff = [];
                    this.state.selected_channels.map((item) => {
                        let b = JSON.parse(JSON.stringify(this.state.carousel));
                        b.id = Math.floor((Math.random() * 9999999999) + 1);
                        b.selected = false;
                        b.allowed_channels = { [item]: true }
                        buff.push(b);
                    })
                    this.props.onAddCreative(buff);
                    console.log("createOrderCreativeEdit save else", buff)

                }

            } else {
                this.state.files = this.state.files.map((item) => {
                    item.name = this.state.name !== "" ? this.state.name : item.name;
                    item.headline = this.state.headline !== "" ? this.state.headline : item.headline;
                    item.body = this.state.body !== "" ? this.state.body : item.body;
                    item.description = this.state.description !== "" ? this.state.description : item.description;
                    item.origin = this.state.origin !== "" ? this.state.origin : item.origin;
                    item.caption = this.state.caption !== "" ? this.state.caption : item.caption;
                    item.website = this.state.website !== "" ? this.state.website : item.website;
                    item.OpenAiResult = this.state.OpenAiResult;

                    item.utm_values = this.state.utm_values;
                    if (this.state.files.length > 1) {
                        Object.keys(this.state.name_by_channels).map((key) => {
                            if (this.state.name_by_channels[key] !== "") {
                                if (!item.name_by_channels) {
                                    item.name_by_channels = {};
                                }
                                item.name_by_channels[key] = this.state.name_by_channels[key];
                            }
                        })
                    } else {
                        item.name_by_channels = this.state.name_by_channels;
                    }


                    item.body_by_channels = this.state.body_by_channels;
                    item.headline_by_channels = this.state.headline_by_channels;
                    item.website_by_channels = this.state.website_by_channels;
                    item.snapchat_brandname = this.state.snapchat_brandname;
                    item.snapchat_attachment = this.state.snapchat_attachment;
                    item.snapchat_url_android = this.state.snapchat_url_android;
                    item.snapchat_url_ios = this.state.snapchat_url_ios;
                    item.snapchat_iosCustom_url = this.state.snapchat_iosCustom_url;
                    item.snapchat_iosCustom = this.state.snapchat_iosCustom;
                    item.snapchat_deep_link_uri = this.state.snapchat_deep_link_uri;
                    item.snapchat_app_name = this.state.snapchat_app_name;
                    item.snapchat_app_icon = this.state.snapchat_app_icon;
                    item.snapchat_favourit = this.state.snapchat_favourit;
                    item.snapchat_status = this.state.snapchat_status;
                    item.snapchat_standardButton = this.state.snapchat_standardButton;
                    item.snapchat_fallback_type = this.state.snapchat_fallback_type;
                    item.snapchat_fallback_url = this.state.snapchat_fallback_url;
                    item.snapchat_shareable = this.state.snapchat_shareable;
                    item.snapchat_autofill = this.state.snapchat_autofill;
                    item.snapchat_prefetching = this.state.snapchat_prefetching;
                    item.snapchat_phone = this.state.snapchat_phone;
                    item.snapchat_message = this.state.snapchat_message;
                    item.snapchat_attachment_error = this.state.snapchat_attachment_error;
                    item.snapchat_impression_tags = this.state.snapchat_impression_tags;
                    item.snapchat_swipe_tags = this.state.snapchat_swipe_tags;
                    item.snapchat_adType = this.state.snapchat_adType;
                    item.snapchat_playStyle = this.state.snapchat_playStyle;

                    item.selected_lead = this.state.selected_lead;
                    item.tiktok_destination = this.state.tiktok_destination;
                    item.tiktok_app_profile = this.state.tiktok_app_profile;
                    item.tiktok_creative_authorized = this.state.tiktok_creative_authorized;



                    //Tracking
                    if (!item.tracking) {
                        item.tracking = JSON.parse(JSON.stringify(this.state.tracking));
                    } else {
                        item.tracking.pixel = this.state.tracking.pixel;
                        item.tracking.app = this.state.tracking.app.id !== 0 ? this.state.tracking.app : item.tracking.app;

                    }
                    //CTA
                    if (!item.cta) {
                        item.cta = JSON.parse(JSON.stringify(this.state.cta));
                    } else {
                        item.cta.facebook = this.state.cta.facebook.id !== 0 ? this.state.cta.facebook : item.cta.facebook;
                        item.cta.linkedin = this.state.cta.linkedin.id !== 0 ? this.state.cta.linkedin : item.cta.linkedin;
                        item.cta.twitter = this.state.cta.twitter.id !== 0 ? this.state.cta.twitter : item.cta.twitter;
                        item.cta.tiktok = this.state.cta.tiktok.id !== 0 ? this.state.cta.tiktok : item.cta.tiktok;
                        item.cta.snapchat = this.state.cta.snapchat.id !== 0 ? this.state.cta.snapchat : item.cta.snapchat;
                    }

                    //PAGE
                    if (!item.page) {
                        item.page = JSON.parse(JSON.stringify(this.state.page));
                    } else {
                        item.page.facebook = this.state.page.facebook.id !== 0 ? this.state.page.facebook : item.page.facebook;
                        item.page.linkedin = this.state.page.linkedin.id !== 0 ? this.state.page.linkedin : item.page.linkedin;
                        item.page.twitter = this.state.page.twitter.id !== 0 ? this.state.page.twitter : item.page.twitter;
                        item.page.tiktok = this.state.page.tiktok.id !== 0 ? this.state.page.tiktok : item.page.tiktok;
                        item.page.snapchat = this.state.page.snapchat.id !== 0 ? this.state.page.snapchat : item.page.snapchat;
                        item.page.instagram = this.state.page.instagram.id !== 0 ? this.state.page.instagram : item.page.instagram;
                    }

                    return item;
                });

                if (this.state.selected_channels.length < 1) {
                    this.props.onSave(this.state.files);
                } else {
                    let buff = [];
                    this.state.files.map((file) => {
                        file.selected = false;
                        this.state.selected_channels.map((item) => {
                            let b = JSON.parse(JSON.stringify(file))
                            b.assetId = b.id;
                            b.id = Math.floor((Math.random() * 9999999999) + 1);
                            b.allowed_channels = { [item]: true }
                            buff.push(b);
                        })
                    })
                    this.props.onAddCreative(buff);
                }

            }

        },
        validation: (item, channel, field) => {
            let error = false;
            if (channel === "facebook") {

            } else if (channel === "tiktok") {

            } else if (channel === "linkedin") {

            } else if (channel === "twitter") {

            } else if (channel === "snapchat") {

            }
            return error;
        },
        scrape: async () => {
            await this.promisedSetState({ loading_scraper: true });
            try {
                let response = await this.calls.scrape();
                this.promisedSetState({
                    description: response.data.description ? response.data.description : this.state.description,
                    origin: response.data.hostname ? response.data.hostname : this.state.origin,
                    loading_scraper: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_scraper: false });
            }
        },
        openAi: async () => {
            let data = {
                text: this.renders.promt_text(),
                temperature: this.state.temperature
            }
            await this.promisedSetState({ loading_suggest: true });
            try {
                let response = await this.calls.openAi(data);
                this.state.OpenAiResult = this.state.OpenAiResult.map((item) => {
                    item.hidden = true
                    return item;
                })
                this.state.OpenAiResult.unshift({ text: response.data[0].text, error: false, hidden: false });
                this.promisedSetState({
                    OpenAiResult: this.state.OpenAiResult,
                    loading_suggest: false,
                    openAi_error: false
                })
            } catch (error) {
                this.state.OpenAiResult = this.state.OpenAiResult.map((item) => {
                    item.hidden = true
                    return item;
                })
                this.state.OpenAiResult.unshift({ text: error.body.message, error: true });
                await this.promisedSetState({
                    OpenAiResult: this.state.OpenAiResult,
                    loading_suggest: false,
                    openAi_error: true
                });
            }
        },
        leadForm: () => {
            let self = this;
            /*
            account_id: this.state.client.channels.filter((item) => { return item.value === "facebook"})[0].facebookAdAccountId.replace("act_", ""),
                    page_id: this.state.page.facebook.id,
                    business_id: this.state.client.channels.filter((item) => { return item.value === "facebook"})[0].facebookBusinessManagerId,
                    display: 'popup',
                    method: 'instant_experiences_builder',
                    template_id: "1932289657009030"
            */
            if (self.state.fb_lead_form && self.state.page.facebook && self.state.page.facebook.id !== 0) {
                window.FB.ui({
                    ad_account_id: self.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookAdAccountId,
                    page_id: self.state.page.facebook.id,
                    display: 'popup',
                    method: 'lead_gen',
                }, function (response) {
                    try {
                        if (response.status === "success") {
                            self.functions.leadCreated(response);
                        }

                        self.setState({ loading_new_lead: false });
                    } catch (e) {
                        self.setState({ loading_new_lead: false });
                    }
                })
            }
        },
        leadCreated: async (response) => {
            try {
                this.state.selected_lead = { id: response.formID, name: response.name.split("+").join(" "), status: "ACTIVE" };
                this.state.lead_forms.unshift({ id: response.formID, name: response.name.split("+").join(" "), status: "ACTIVE" });
                await this.promisedSetState({
                    selected_lead: this.state.selected_lead,
                    lead_forms: this.state.lead_forms
                });
                //console.log(this.state.selected_lead, "selected_lead")
            } catch (error) {
            }
        },
        listTikTokIdentities: async () => {
            await this.promisedSetState({ loading_tiktok: true });
            try {
                let response = await this.calls.tikTokIdentities();
                this.state.pages.tiktok = response.data;
                this.promisedSetState({
                    pages: this.state.pages,
                    loading_tiktok: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_tiktok: false });
            }
        },
        listTikTokPages: async () => {
            await this.promisedSetState({ loading_tiktok: true });
            try {
                let response = await this.calls.listTikTokPages();
                this.state.tiktok_app_pages = response.data;
                this.promisedSetState({
                    tiktok_app_pages: this.state.tiktok_app_pages,
                    loading_tiktok: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_tiktok: false });
            }
        },
        create_identity: async () => {
            let data = {
                url: this.state.identity_image.url,
                name: this.state.identity_name
            }
            await this.promisedSetState({ loading_identity: true });
            try {
                let response = await this.calls.createtikTokIdentities(data);
                this.state.pages.tiktok.push(response.data);
                this.state.page.tiktok = response.data;
                await this.promisedSetState({
                    loading_identity: false,
                    new_identity: false,
                    pages: this.state.pages,
                    page: this.state.page

                });
                this.functions.updatePreview();
            } catch (error) {
                await this.promisedSetState({ loading_identity: false });
            }
        },
        listSnapchatIdentities: async () => {
            await this.promisedSetState({ loading_snapchat: true });
            try {
                let response = await this.calls.snapchatIdentities();
                this.state.pages.snapchat = response.data;
                this.promisedSetState({
                    pages: this.state.pages,
                    loading_snapchat: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_snapchat: false });
            }
        },
        listFacebookPages: async () => {
            try {
                await this.promisedSetState({ loading_facebook: true });
                let response = await this.calls.facebookPages(this.state.facebook_pages_pagination);
                if (response.pagination) {
                    //this.state.facebook_pages_pagination = response.pagination;
                } else {
                    this.state.facebook_pages_pagination = false;
                }
                if (!Array.isArray(this.state.pages.facebook)) {
                    this.state.pages.facebook = [];
                }
                this.state.pages.facebook = Array.isArray(response.data) ? this.state.pages.facebook.concat(response.data.map((item) => { return { name: item.name, id: item.id, image: item.picture.data.url } })) : []
                await this.promisedSetState({
                    pages: this.state.pages,
                    facebook_pages_pagination: this.state.facebook_pages_pagination
                })
            } catch (error) { }
            await this.promisedSetState({ loading_facebook: false });
        },
        getFacebookPage: async (id) => {
            try {
                await this.promisedSetState({ loading_facebook: true });
                let response = await this.calls.facebookPage(id);
                if (response.data) {
                    this.state.page.facebook = response.data;
                    if (response.data.picture) {
                        this.state.page.facebook.image = response.data.picture.data.url;
                    }
                    if (!Array.isArray(this.state.pages.facebook)) {
                        this.state.pages.facebook = [];
                    }
                    this.state.pages.facebook.push(response.data);
                    await this.promisedSetState({
                        page: this.state.page
                    });
                    if (!this.state.instagram_post) this.functions.listInstagramAccounts(this.state.page.facebook.id);
                    this.functions.updatePreview();
                }
            } catch (error) { }
            await this.promisedSetState({ loading_facebook: false });
        },
        listTwitterPages: async () => {
            try {
                await this.promisedSetState({ loading_twitter: true });
                let response = await this.calls.twitterPages();
                this.state.pages.twitter = response.data;
                await this.promisedSetState({ pages: this.state.pages })
            } catch (error) { }
            await this.promisedSetState({ loading_twitter: false });
        },
        listLinkedinPages: async () => {
            try {
                await this.promisedSetState({ loading_linkedin: true });
                let response = await this.calls.linkedinPages();
                this.state.pages.linkedin = response.data;
                await this.promisedSetState({ pages: this.state.pages })
            } catch (error) { }
            await this.promisedSetState({ loading_linkedin: false });
        },
        listInstagramAccounts: async (page) => {
            await this.promisedSetState({ loading_instagram: true });
            try {
                let response = await this.calls.instagramAccounts(page);
                this.state.pages.instagram = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_pic } }) : [];
                this.promisedSetState({ pages: this.state.pages })
            } catch (error) { }
            await this.promisedSetState({ loading_instagram: false });
        },
        listInstagramBusinessAccounts: async (page) => {
            await this.promisedSetState({ loading_instagram: true });
            try {
                let response = await this.calls.instagramBusinessAccounts();
                this.state.pages.instagram = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_pic } }) : [];
                this.promisedSetState({ pages: this.state.pages })
            } catch (error) { }
            await this.promisedSetState({ loading_instagram: false });
        },
        listLeadForms: async () => {
            await this.promisedSetState({ loading_leadforms: true });
            try {
                let response = await this.calls.listLeadForms(this.state.page.facebook.id);
                this.state.lead_forms = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, status: item.status } }) : [];
                this.promisedSetState({ lead_forms: this.state.lead_forms })
            } catch (error) {
            }
            await this.promisedSetState({ loading_leadforms: false });
        },
        pixels: async () => {
            try {
                this.promisedSetState({ loading_pixels: true });
                let response = await this.calls.pixels();
                response.data.unshift({ id: 0, name: "No pixel", value: null });
                this.promisedSetState({ loading_pixels: false, pixels: response.data });
            } catch (error) {
                this.promisedSetState({
                    pixels: []
                })
            }
        },
        listApps: async () => {
            try {
                this.promisedSetState({ loading_apps: true });
                let response = await this.calls.apps();
                let data = [];
                data = data.concat(response.data);
                this.promisedSetState({ loading_apps: false, apps: data });
            } catch (error) {
                this.promisedSetState({
                    apps: []
                })
            }
        },
        snapBrandnameRestriction: async (value) => {
            let max_length = 32;
            if (value.length > max_length) {
                this.setState({ snap_brandname_error: true })
            } else {
                this.setState({ snap_brandname_error: false })
            }
        },
        deleteSlide: async (slide) => {
            try {
                if (this.state.carousel && this.state.carousel.files && Array.isArray(this.state.carousel.files)) {
                    this.state.carousel.files = this.state.carousel.files.filter((item) => {
                        return slide.id !== item.id;
                    });
                }
                await this.promisedSetState({
                    carousel: this.state.carousel, open_slide: 0, delete_modal: false
                });
                if (this.props.onChange) {
                    this.props.onChange(this.state.carousel);
                }
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
    };

    calls = {
        openAi: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/openAi?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        scrape: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', { url: this.state.website });
            let url = apiRegister.url.api + "/v3/adcredo/scrapeUrl";
            return apiRegister.call(options, url);
        },
        tikTokIdentities: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listIdentities?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listTikTokPages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listPages?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listLeadForms: (page) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listLeadForms?client=" + this.state.client.id + "&page=" + page;
            return apiRegister.call(options, url);
        },
        facebookPages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPages?client=" + this.state.client.id + (this.state.facebook_pages_pagination ? ("&next=" + this.state.facebook_pages_pagination) : "");
            return apiRegister.call(options, url);
        },
        facebookPage: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/getPage?client=" + this.state.client.id + "&id=" + id;
            return apiRegister.call(options, url);
        },
        twitterPages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/listPages?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        linkedinPages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listPages?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        instagramAccounts: (page) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listInstagramAccounts?client=" + this.state.client.id + "&page=" + page;
            return apiRegister.call(options, url);
        },
        instagramBusinessAccounts: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listBusinessInstagramAccounts?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createtikTokIdentities: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/createIdentity?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        apps: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listApps?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        pixels: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPixels?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        // UNKNOWN ENDPOINT
        snapchatIdentities: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listProfiles?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        promt_text: () => {
            return "Write an ad copy based on the following text:[" + this.state.searchOpenAi + ", to run on the platform:[" + this.state.platform.name + "] with up to :[" + this.state.amount + " characters including letters, numbers, emojis, spaces, and punctuation marks]. The ad copy needs to be written in the language:[" + this.state.language.name + "]."
        },
        snapchatShareable: () => {
            try {
                if ("snapchat_shareable" in this.state) {
                    return this.state.snapchat_shareable;
                } else {
                    return { id: 1, name: "Enabled", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Enabled", value: true };
            }
        },
        iosCustom: () => {
            try {
                if ("snapchat_iosCustom" in this.state) {
                    return this.state.snapchat_iosCustom;
                } else {
                    return { id: 2, name: "Disabled", value: false };
                }
            } catch (error) {
                return { id: 2, name: "Disabled", value: false };
            }
        },
        standardButton: () => {
            try {
                if ("snapchat_standardButton" in this.state) {
                    return this.state.snapchat_standardButton;
                } else {
                    return { id: 2, name: "Disabled", value: false };
                }
            } catch (error) {
                return { id: 2, name: "Disabled", value: false };
            }
        },
        favourit: () => {
            try {
                if ("snapchat_favourit" in this.state) {
                    return this.state.snapchat_favourit;
                } else {
                    return { id: 2, name: "Disabled", value: false };
                }
            } catch (error) {
                return { id: 2, name: "Disabled", value: false };
            }
        },
        snapchatAdType: () => {
            try {
                if ("snapchat_adType" in this.state) {
                    return this.state.snapchat_adType;
                } else {
                    return { id: 1, name: "Single Image or Video", value: "feed" };
                }
            } catch (error) {
                return { id: 1, name: "Single Image or Video", value: "feed" };
            }
        },
        snapchatPlayStyle: () => {
            try {
                if ("snapchat_playStyle" in this.state) {
                    return this.state.snapchat_playStyle;
                } else {
                    return { id: 1, name: "Loop", value: "LOOPING" };
                }
            } catch (error) {
                return { id: 1, name: "Loop", value: "LOOPING" };
            }
        },
        snapchatStatus: () => {
            try {
                if ("snapchat_status" in this.state) {
                    return this.state.snapchat_status;
                } else {
                    return { id: 1, name: "Active", value: "ACTIVE" };
                }
            } catch (error) {
                return { id: 1, name: "Active", value: "ACTIVE" };
            }
        },
        snapchatAutofill: () => {
            try {
                if ("snapchat_autofill" in this.state) {
                    return this.state.snapchat_autofill;
                } else {
                    return { id: 2, name: "Disabled", value: false };
                }
            } catch (error) {
                return { id: 2, name: "Disabled", value: false };
            }
        },
        snapchatPrefetching: () => {
            try {
                if ("snapchat_prefetching" in this.state) {
                    return this.state.snapchat_prefetching;
                } else {
                    return { id: 2, name: "Disabled", value: false };
                }
            } catch (error) {
                return { id: 2, name: "Disabled", value: false };
            }
        },

    };

    SortableItem = SortableElement(({ item, itemIndex }) => {
        return (
            <div
                style={{ zIndex: 99999999 }}
                className="cursor-pointer">
                {
                    <>
                        <div className={`bg-gray-100 p-4 rounded-md space-y-4 ${this.state.open_slide === itemIndex ? ' h-auto z-55 overflow-y-visible' : ' h-20 overflow-hidden'}`}>
                            <div className='flex w-full justify-between'>
                                <div className='flex'>
                                    <div className={"border-1.5 h-12 w-12 mr-2 flex justify-center cursor-pointer items-center rounded-md"}>
                                        {
                                            this.state.carousel &&
                                            <SwitchVerticalIcon className="h-5 w-5" />
                                        }
                                        {
                                            this.state.merge &&
                                            <div className="text-sm font-medium">
                                                {item.ratio ? item.ratio : "Unknown ratio"}
                                            </div>
                                        }
                                    </div>
                                    <div className='flex items-center'>
                                        <div className='flex items-center justify-center h-12 w-12 rounded-md mr-2'>
                                            {
                                                item.type === "image" &&
                                                <img src={item.url ? item.url : ""} className="w-full object-contain rounded-md"
                                                    style={{ maxHeight: "48px" }}
                                                />
                                            }
                                            {
                                                item.type === "video" &&
                                                <video src={item.url ? item.url : ""} autoPlay={false} muted={true} controls={false} className="rounded-md"
                                                    style={{ maxHeight: "48px" }}
                                                />
                                            }
                                        </div>
                                        <div className='flex flex-col'>
                                            <div className="text-sm font-medium">
                                                Slide {itemIndex + 1}
                                            </div>
                                            <div className="flex text-xs text-gray-600">

                                                {
                                                    // IF THE SLIDE IS OPEN
                                                    this.state.open_slide === itemIndex ?
                                                        item.type === "image" ?
                                                            <>
                                                                <PhotographIcon className="h-4 w-4 mr-1" />
                                                                Image
                                                            </>
                                                            :
                                                            <>
                                                                <FilmIcon className="h-4 w-4 mr-1" />
                                                                Video
                                                            </>
                                                        :
                                                        // IF THE SLIDE IS CLOSED
                                                        <>
                                                            {
                                                                this.state.channel_menu !== "twitter" ?
                                                                    <div className='grid grid-cols-2 gap-4 text-xs text-gray-600'>
                                                                        <div className='w-full'>
                                                                            Headline: {item.title && typeof item.title === 'string' ? this.truncText(item.title, this.state.channel_menu !== "linkedin" ? 15 : 30) : ''}
                                                                        </div>
                                                                        {
                                                                            this.state.channel_menu !== "linkedin" &&
                                                                            <div className='w-full'>
                                                                                Description: {item.body && typeof item.body === 'string' ? this.truncText(item.body, 15) : ''}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            item.type === "image" ?
                                                                                <>
                                                                                    <PhotographIcon className="h-4 w-4 mr-1" />
                                                                                    Image
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <FilmIcon className="h-4 w-4 mr-1" />
                                                                                    Video
                                                                                </>
                                                                        }
                                                                    </>
                                                            }

                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center space-x-4">
                                    {
                                        this.state.carousel && this.state.carousel.files && Array.isArray(this.state.carousel.files) && this.state.carousel.files.length > 2 &&
                                        <div className='bg-red-200 p-2 rounded-md cursor-pointer'
                                            onClick={async () => {
                                                await this.promisedSetState({ delete_modal: true, delete_slide: item })
                                            }}
                                        >
                                            <TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" />
                                        </div>
                                    }
                                    <div
                                        onClick={async () => await this.promisedSetState({ open_slide: this.state.open_slide === itemIndex ? null : itemIndex })}
                                        className={`bg-gray-200 p-2 cursor-pointer flex rounded font-bold justify-center align-middle items-center`}>
                                        <ChevronUpIcon className={`h-5 w-5text-grey-600 transform rotate-0 transition-all duration-200 ${this.state.open_slide === itemIndex && "rotate-180"}`} />
                                    </div >
                                </div>
                            </div>

                            <div className="w-full">
                                <InputTailwind
                                    label={"Website"}
                                    value={item.link ? item.link : ""}
                                    error={(!item.link || !item.link.includes("https://")) ? true : false}
                                    onChange={async (value) => {
                                        this.state.carousel.files[itemIndex].link = value;
                                        this.promisedSetState({
                                            carousel: this.state.carousel
                                        })
                                        this.functions.updatePreview();
                                    }}
                                />
                            </div>
                            {
                                (!item.link || !item.link.includes("https://")) &&
                                <div className="w-full mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                    OBS! Prefix https:// is required.
                                </div>
                            }
                            {
                                this.state.channel_menu !== "twitter" &&
                                <div className="flex space-x-4 w-full">
                                    <div className='w-full'>
                                        <InputTailwind
                                            label={"Headline"}
                                            value={item.title ? item.title : ""}
                                            error={this.state.utm_channels.filter((item) => { return item === "linkedin" }).length > 0 && (!item.title || item.title === "") ? true : false}
                                            onChange={async (value) => {
                                                this.state.carousel.files[itemIndex].title = value;
                                                this.promisedSetState({
                                                    carousel: this.state.carousel
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.channel_menu !== "linkedin" &&
                                        <div className="w-full">
                                            <InputTailwind
                                                label={"Description"}
                                                value={item.body ? item.body : ""}
                                                onChange={async (value) => {
                                                    this.state.carousel.files[itemIndex].body = value;
                                                    this.promisedSetState({
                                                        carousel: this.state.carousel
                                                    })
                                                    this.functions.updatePreview();
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                    </>
                }
            </div>
        )
    });

    SortableList = SortableContainer(({ items }) => (
        <div className="relative space-y-4">
            {items.map((item, index) => {
                if (item) {
                    return (
                        <this.SortableItem
                            index={index}
                            itemIndex={index}
                            item={item}
                        />
                    )
                }
            })}
        </div>
    ));

    onSortEnd = async ({ oldIndex, newIndex }) => {
        await this.promisedSetState({
            array: arrayMove((this.state.carousel.files ? this.state.carousel.files : []), oldIndex, newIndex),
        })
        if (this.state.carousel.files) {
            this.state.carousel.files = this.state.array;
            await this.promisedSetState({
                carousel: this.state.carousel
            })
            this.functions.updatePreview()
        }
    };

    renderSortableList(items) {
        return (
            <div className="relative space-y-4">
                <this.SortableList
                    items={this.state.carousel.files}
                    onSortEnd={this.onSortEnd}
                    axis="y"
                    distance={1}
                />
            </div>
        )
    }

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        // const SortableItem = SortableElement(({ item }) => {
        //     return (
        //         <div onClick={() => {
        //             if (this.state.carousel && this.state.carousel_menu === "Slides information") {
        //                 item.selected = !item.selected;
        //                 this.setState({
        //                     carousel: this.state.carousel
        //                 });
        //             }
        //         }} key={item.id} className="flex flex-row justify-center items-center cursor-pointer relative m-3">
        //             <div className="flex flex-1 flex-row items-center overflow-hidden">
        //                 {

        //                     (item.type === "image" || (item.type === "posts" && item.ad_type === "image")) &&
        //                     <div className="h-10 w-10 rounded-md relative overflow-hidden">
        //                         <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

        //                         </div>
        //                     </div>
        //                 }
        //                 {
        //                     (item.type === "video" || (item.type === "posts" && item.ad_type === "video")) &&
        //                     <video poster={item.thumbnail} autoPlay={false} muted={true} controls={false} className="h-10 w-10 rounded-md">
        //                         <source src={item.url} type="video/mp4" />
        //                     </video>
        //                 }
        //                 <div style={{ "paddingTop": "4px" }} className="ml-3 truncate">
        //                     <p className={(item.selected ? "" : "text-gray-900") + " text-sm font-medium truncate"}>{item.name}</p>
        //                     <p className={"text-sm text-gray-500 fle flex-row inline-flex items-center justify-center"}>
        //                         {item.type === "image" && <CameraIcon className="h-4 w-4 mr-1" />}
        //                         {item.type === "video" && <VideoCameraIcon className="h-4 w-4 mr-1" />}
        //                         {item.type} - {item.format}
        //                     </p>
        //                 </div>
        //                 <div className="flex flex-1"></div>
        //                 <div>
        //                     <div className={"border-1.5 h-10 w-10  ml-2 flex justify-center cursor-pointer items-center rounded-md"}>
        //                         {
        //                             this.state.carousel &&
        //                             <SwitchHorizontalIcon className="h-5 w-5" />
        //                         }
        //                         {
        //                             this.state.merge &&
        //                             <div className="text-sm font-medium">
        //                                 {item.ratio}
        //                             </div>
        //                         }
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     )
        // });

        // const SortableList = SortableContainer(({ items }) => (
        //     <div className="relative flex flex-row">
        //         {items.map((item, index) => {
        //             item.index = index;
        //             if (item) {
        //                 return (
        //                     <SortableItem
        //                         index={index}
        //                         item={item}
        //                     />
        //                 )
        //             }
        //         })}
        //     </div>
        // ));

        // const onSortEnd = ({ oldIndex, newIndex }) => {
        //     this.setState(() => ({
        //         array: arrayMove((this.state.carousel ? this.state.carousel.files : []), oldIndex, newIndex),
        //     }), () => {
        //         if (this.state.carousel) {
        //             this.state.carousel.files = this.state.array;
        //             this.setState({
        //                 carousel: this.state.carousel
        //             })
        //         }
        //     });
        // };

        return (
            <>
                <div className="grid grid-cols-12 bg-gray-50 gap-4">
                    {/* <div className="col-span-12 gap-4 ">
                        <div className="col-span-12 relative flex flex-row bg-white p-4 mx-4 rounded-lg border-blue-200 border-dashed border-4 overflow-x-scroll">

                            {
                                this.state.carousel &&
                                <div>
                                    <SortableList
                                        items={this.state.carousel.files}
                                        onSortEnd={onSortEnd}
                                        axis="x"
                                        distance={1}
                                    />
                                </div>
                            }
                            {
                                this.state.merge &&
                                <div>
                                    <SortableList
                                        items={this.state.merge.files}
                                        onSortEnd={onSortEnd}
                                        distance={1}
                                    />
                                </div>
                            }
                            {
                                !this.state.carousel && !this.state.merge &&
                                this.state.files.map((item) => (
                                    <div onClick={() => {

                                    }} key={item.id} className="flex flex-row justify-center items-center">
                                        <div className="flex flex-1 flex-row items-center overflow-hidden">
                                            {
                                                (item.type === "image" || (item.type === "posts" && item.ad_type === "image" && !item.files)) &&
                                                <img className={(item.selected ? "" : "") + " h-10 w-10 rounded-md"} src={item.url} alt="" />
                                            }
                                            {
                                                (item.type === "video" || (item.type === "posts" && item.ad_type === "video" && !item.files)) &&
                                                <video poster={item.thumbnail} autoPlay={true} muted={true} controls={false} className="h-10 w-10 rounded-md">
                                                    <source src={item.url} type="video/mp4" />
                                                </video>
                                            }
                                            {
                                                (item.type === "posts" && item.files) &&
                                                <div className="h-10 w-10 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden border">
                                                    {
                                                        item.files.filter((item, index) => { return index < 4 }).map((item) => {
                                                            return (
                                                                <div className="w-full h-full overflow-hidden">
                                                                    {
                                                                        (item.type === "image" || item.type === "IMAGE") &&
                                                                        <div className="w-full h-full relative">
                                                                            <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (item.type === "video" || item.type === "VIDEO") &&
                                                                        <video poster={item.thumbnail ? item.thumbnail : ""} autoPlay={true} muted={true} controls={false} className={"w-full h-full"}>
                                                                            <source src={item.url} type="video/mp4" />
                                                                        </video>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {
                                                item.type === "catalog" &&
                                                Array.isArray(item.preview_products) &&
                                                <div className="h-10 w-10 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden border">
                                                    {
                                                        item.preview_products.filter((item, index) => { return index < 4 }).map((item) => {
                                                            return (
                                                                <div className="w-full h-full overflow-hidden">
                                                                    <img className={"w-full h-full"} src={item.image_url} alt="" />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            <div style={{ "paddingTop": "4px" }} className="ml-3 truncate">
                                                <p className={(item.selected ? "" : "text-gray-900") + " text-sm font-medium truncate"}>{item.name}</p>
                                                <div className="flex flex-row items-center mr-1">
                                                    <p className={"text-sm text-gray-500 fle flex-row inline-flex items-center justify-center"}>
                                                        {item.type === "image" && <CameraIcon className="h-4 w-4 mr-1" />}
                                                        {item.type === "video" && <VideoCameraIcon className="h-4 w-4 mr-1" />}
                                                        {item.type === "catalog" && <GiftIcon className="h-4 w-4 mr-1" />}
                                                        {item.type === "posts" && <AnnotationIcon className="h-4 w-4 mr-1" />}
                                                        {item.type}
                                                    </p>
                                                    {
                                                        item.channel &&
                                                        <div className={"bg-" + item.channel + "-500" + " h-4 w-4 rounded-full flex justify-center items-center ml-1"}>
                                                            {
                                                                item.channel === 'google' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/google_icon.png')} />
                                                            }
                                                            {
                                                                item.channel === 'facebook' &&
                                                                <img className="w-1"
                                                                    alt=""
                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                            }
                                                            {
                                                                item.channel === 'linkedin' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                                            }
                                                            {
                                                                item.channel === 'twitter' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/twitter_icon.svg')} />
                                                            }
                                                            {
                                                                item.channel === 'tiktok' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/tiktok_icon.png')} />
                                                            }
                                                            {
                                                                item.channel === 'instagram' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/instagram_icon.svg')} />
                                                            }
                                                            {
                                                                item.channel === 'snapchat' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/snapchat.svg')} />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-1"></div>
                                            {
                                                this.state.carousel && this.state.carousel_menu === "Slides information" &&
                                                <div>
                                                    <div className={(item.selected ? "bg-purple-500" : "bg-gray-100") + " border-1.5 h-10 w-10 ml-2 flex justify-center cursor-pointer items-center rounded-md"}>
                                                        {
                                                            item.selected &&
                                                            <CheckIcon className="h-5 w-5 text-white" />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div> */}

                    <WarningModalTailwind
                        open={this.state.delete_modal ? true : false}
                        title={"Delete slide"}
                        description={'Are you sure you want to delete this slide ? This action cannot be undone.'}
                        cancelButtonText={"Cancel"}
                        submitButtonText={"Delete"}
                        onClose={async (value) => {
                            await this.promisedSetState({ delete_modal: false });
                        }}
                        onSubmit={() => {
                            this.functions.deleteSlide(this.state.delete_slide);
                        }}
                    />

                    {
                        this.state.merge && this.state.merge.error &&
                        <div className="col-span-12 py-5 text-sm px-5 text-center items-center border-red-600 border-2 bg-red-200 rounded-lg ">
                            {this.state.merge.message}
                        </div>
                    }
                    <div className="col-span-12">
                        {
                            console.log("XXXX", this.state)
                        }
                        {
                            (this.state.carousel || this.state.fb_lead_form) &&
                            <div className="block w-full mb-4 px-2 -mt-3">
                                <div className=" border-gray-200 w-full">
                                    <nav className="-mb-px flex" aria-label="Tabs">
                                        {
                                            ["Main ad", "Slides information", "Lead Form"].map((item) => {
                                                // if (item === "Lead Form" && !this.state.fb_lead_form) {
                                                if (item === "Lead Form" && this.state.channel_menu !== "facebook") {
                                                    return null;
                                                } else if (item === "Slides information" && !this.state.carousel) {
                                                    return null;
                                                } else return item
                                            }).filter((item) => { return item }).map((item) => (
                                                <div
                                                    onClick={() => {
                                                        //if ((item === "Lead Form" && this.state.page.facebook.id !== 0) || item !== "Lead Form") {
                                                        this.setState({
                                                            carousel_menu: item
                                                        })
                                                        //}
                                                    }}
                                                    key={item}
                                                    className={classNames(
                                                        item === this.state.carousel_menu
                                                            ? 'border-purple-500 text-purple-600'
                                                            : ('border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400 cursor-pointer'),
                                                        'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md mx-2'
                                                    )}
                                                    aria-current={item === this.state.carousel_menu ? 'page' : undefined}
                                                >
                                                    {item}
                                                </div>
                                            ))
                                        }
                                    </nav>

                                </div>
                            </div>
                        }
                        {
                            (!this.state.carousel || (this.state.carousel && this.state.carousel_menu === "Main ad")) &&
                            ((this.state.fb_lead_form && this.state.carousel_menu === "Main ad") || !this.state.fb_lead_form) &&

                            <div className="grid grid-cols-4 gap-4 px-4 bg-gray-50">

                                {
                                    this.state.channel_menu === "snapchat" &&
                                    this.state.channels.snapchat &&
                                    <div className="col-span-4 grid gap-4 grid-cols-4">
                                        <div className="flex flex-row">
                                            <div className="mr-1">
                                                <div className={"bg-snapchat-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                    <img className="w-2"
                                                        alt=""
                                                        src={require('../assets/images/snapchat.svg')} />
                                                </div>
                                            </div>
                                            <div className='text-xs font-medium text-gray-700'>Ad type</div>
                                        </div>
                                        <div className="col-span-4 grid gap-4 grid-cols-4 -mt-3">
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    snapchat_adType: { id: 1, name: "Single Image or Video", value: "feed" }
                                                })
                                            }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatAdType().id === 1 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                                Single Image or Video
                                            </div>
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    snapchat_adType: { id: 2, name: "Story", value: "story" }
                                                })
                                            }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatAdType().id === 2 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                                Story
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.channel_menu === "snapchat" &&
                                    this.state.channels.snapchat &&
                                    this.renders.snapchatAdType().id === 2 &&
                                    <div className="col-span-4 grid gap-4 grid-cols-4">
                                        <div className="flex flex-row">
                                            <div className="mr-1">
                                                <div className={"bg-snapchat-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                    <img className="w-2"
                                                        alt=""
                                                        src={require('../assets/images/snapchat.svg')} />
                                                </div>
                                            </div>
                                            <div className='text-xs font-medium text-gray-700'>Play Style</div>
                                        </div>
                                        <div className="col-span-4 grid gap-4 grid-cols-4 -mt-3">
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    snapchat_playStyle: { id: 1, name: "Loop", value: "LOOPING" }
                                                })
                                            }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatPlayStyle().id === 1 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                                Loop
                                            </div>
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    snapchat_playStyle: { id: 2, name: "Auto Advance", value: "AUTO_ADVANCING" }
                                                })
                                            }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatPlayStyle().id === 2 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                                Auto Advance
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => (
                                        <div className="col-span-4">
                                            <InputTailwind
                                                error={this.state.name_by_channels[item] === "" && this.state.files.length === 1 ? true : false}
                                                channel={item}
                                                label={"Name"}
                                                value={this.state.name_by_channels[item]}
                                                onChange={async (value) => {
                                                    this.state.name_by_channels[item] = value;
                                                    await this.promisedSetState({
                                                        name_by_channels: this.state.name_by_channels
                                                    })
                                                    console.log(this.state.name_by_channels, "name_by_channels =====");
                                                    this.functions.updatePreview();
                                                }}
                                            />
                                        </div>
                                    ))
                                }




                                <div className="col-span-4 text-lg font-semibold">
                                    Select identity
                                </div>
                                {

                                    ((!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "tiktok")) && this.state.channel_menu === "tiktok") &&
                                    this.state.channels.tiktok &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            error={this.state.page.tiktok.id === 0 && this.state.type !== "posts" ? true : false}
                                            channel="tiktok"
                                            label={"TikTok identity"}
                                            locked={this.state.type === "posts" ? true : false}
                                            searchInput={true}
                                            loader={this.state.loading_tiktok}
                                            selected={this.state.page.tiktok}
                                            options={this.state.pages.tiktok}
                                            onChange={async (page) => {
                                                this.state.page.tiktok = page;
                                                await this.promisedSetState({
                                                    page: this.state.page
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>

                                }
                                {
                                    !this.state.new_identity && this.state.channel_menu === "tiktok" &&
                                    this.state.type !== "posts" &&
                                    this.state.channels.tiktok &&
                                    <div className="col-span-4 mb-2 inline-flex">
                                        <div onClick={async () => {
                                            await this.promisedSetState({
                                                new_identity: true
                                            });
                                        }} className=" flex flex-row items-center text-sm font-medium bg-purple-100 rounded-full px-4 py-2 cursor-pointer text-purple-500 hover:text-purple-700">
                                            Create new custom identity
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.new_identity && this.state.channel_menu === "tiktok" &&
                                    <div className="col-span-4 grid grid-cols-6 gap-4">
                                        <div className="col-span-3">
                                            <UploadImageTailwind
                                                label={"Identity image (Ratio 1:1)"}
                                                error={this.state.identity_image.ratio && this.state.identity_image.ratio !== "1:1" ? true : false}
                                                client={this.state.client}
                                                onChange={async (value) => {
                                                    this.setState({
                                                        identity_image: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTailwind
                                                label={"Identity name"}
                                                value={this.state.identity_name}
                                                onChange={(value) => {
                                                    this.setState({
                                                        identity_name: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-4 flex flex-row items-center">
                                            <button
                                                onClick={() => {
                                                    if (this.state.identity_image.url && this.state.identity_image.ratio === "1:1" && this.state.identity_name !== "") {
                                                        this.functions.create_identity();
                                                    }
                                                }}
                                                className={"inline-flex shadow relative justify-center rounded-md border border-transparent bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Create identity
                                                {
                                                    this.state.loading_identity &&
                                                    <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    new_identity: false
                                                });
                                            }} className="flex-1 ml-5 flex justify-start text-sm font-medium text-red-500 hover:text-red-600 cursor-pointer">
                                                Close
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    ((!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) && this.state.channel_menu === "snapchat") &&
                                    this.state.channels.snapchat &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            error={this.state.page.snapchat.id === 0 ? true : false}
                                            channel="snapchat"
                                            label={"Public profile"}
                                            locked={false}
                                            searchInput={true}
                                            loader={this.state.loading_snapchat}
                                            selected={this.state.page.snapchat}
                                            options={this.state.pages.snapchat}
                                            onChange={async (page) => {
                                                this.state.page.snapchat = page;
                                                await this.promisedSetState({
                                                    page: this.state.page
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.channel_menu === "snapchat" &&
                                    this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => (
                                        <div className="col-span-4">
                                            <InputTailwind
                                                channel={item}
                                                label={"Brand name (optional, 32 characters max)"}
                                                value={this.state.snapchat_brandname}
                                                maxLength={(this.state.channel_menu === "snapchat" ? 32 : 1000000)}
                                                error={this.state.snap_brandname_error}
                                                onChange={async (value) => {
                                                    this.functions.snapBrandnameRestriction(value)
                                                    this.state.snapchat_brandname = value;
                                                    await this.promisedSetState({
                                                        snapchat_brandname: this.state.snapchat_brandname
                                                    })
                                                    this.functions.updatePreview();
                                                }}
                                            />
                                        </div>
                                    ))
                                }
                                {
                                    !this.state.catalog &&
                                    this.state.channels.twitter &&
                                    this.state.channel_menu === "twitter" &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            channel="twitter"
                                            label={"Twitter page"}
                                            searchInput={true}
                                            error={this.state.page.twitter.id === 0 ? true : false}
                                            loader={this.state.loading_twitter}
                                            selected={this.state.page.twitter}
                                            options={this.state.pages.twitter}
                                            onChange={async (page) => {
                                                this.state.page.twitter = page;
                                                await this.promisedSetState({
                                                    page: this.state.page
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.catalog &&
                                    this.state.channels.linkedin &&
                                    this.state.channel_menu === "linkedin" &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            channel="linkedin"
                                            label={"Linkedin page"}
                                            searchInput={true}
                                            error={this.state.page.linkedin.id === 0 ? true : false}
                                            loader={this.state.loading_linkedin}
                                            selected={this.state.page.linkedin}
                                            options={this.state.pages.linkedin}
                                            onChange={async (page) => {
                                                this.state.page.linkedin = page;
                                                await this.promisedSetState({
                                                    page: this.state.page
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook && this.state.channel_menu === "facebook" &&
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Facebook page"}
                                            searchInput={true}
                                            error={this.state.page.facebook.id === 0 ? true : false}
                                            pagination={this.state.facebook_pages_pagination}
                                            loader={this.state.loading_facebook}
                                            selected={this.state.page.facebook}
                                            options={this.state.pages.facebook}
                                            onPagination={() => {
                                                this.functions.listFacebookPages();
                                            }}
                                            onChange={async (page) => {
                                                this.state.page.facebook = page;
                                                if (!this.state.instagram_post) this.state.page.instagram = { id: 0, name: "Click here ..." };
                                                await this.promisedSetState({
                                                    page: this.state.page
                                                });
                                                if (this.state.fb_lead_form) this.functions.listLeadForms();
                                                if (!this.state.instagram_post) this.functions.listInstagramAccounts(this.state.page.facebook.id);
                                                this.functions.updatePreview();
                                                /*
                                                if (this.state.type === "posts" && this.state.instagram_post) {
                                                    this.functions.listInstagramBusinessAccounts();
                                                } else {
                                                    this.functions.listInstagramAccounts(this.state.page.facebook.id);
                                                }*/
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook && this.state.channel_menu === "facebook" &&
                                    <div className={(this.state.page.facebook.id === 0 ? "opacity-50 cursor-not-allowed" : "") + " col-span-2"}>
                                        <DropdownTailwind
                                            channel="instagram"
                                            label={"Instagram account"}
                                            searchInput={true}
                                            locked={this.state.files[0].instagram_post}
                                            disabled={this.state.page.facebook.id === 0 || this.state.files[0].instagram_post}
                                            loader={this.state.loading_instagram}
                                            selected={this.state.page.instagram}
                                            options={this.state.pages.instagram}
                                            onChange={async (page) => {
                                                this.state.page.instagram = page;
                                                await this.promisedSetState({
                                                    page: this.state.page
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.channels.facebook && !this.state.loading_facebook && this.state.pages.facebook.length < 1 && this.state.channel_menu === "facebook" &&
                                    <div className="col-span-6">
                                        <div className="rounded-md font-medium text-sm bg-orange-100 text-orange-500 p-4">
                                            <div className="mb-2">
                                                Facebook is having a temporary error with locating pages, please type in your page ID in input field below.
                                            </div>
                                            <div className="mb-4">
                                                <InputTailwind
                                                    label={"Page ID"}
                                                    value={this.state.facebook_page_id}
                                                    onChange={async (value) => {
                                                        this.promisedSetState({
                                                            facebook_page_id: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <div onClick={async () => {
                                                    if (!this.state.loading_facebook) {
                                                        this.functions.getFacebookPage(this.state.facebook_page_id);
                                                    }
                                                }} className="rounded-full font-medium cursor-pointer inline-flex bg-orange-500 text-white hover:bg-orange-600 py-2 px-6">
                                                    Add page
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                                <div className="col-span-4 text-lg font-semibold">
                                    Ad settings
                                </div>
                                {
                                    !this.state.merge &&
                                    this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => (
                                        ((this.state.channel_menu === "snapchat" && this.state.snapchat_attachment.value === "WEB_VIEW") || (!["snapchat", "tiktok"].includes(this.state.channel_menu)) || (this.state.channel_menu === "tiktok" && this.state.tiktok_destination.value === "website")) &&
                                        <div className={item === "tiktok" ? "col-span-3" : "col-span-4"}>
                                            <InputTailwind
                                                // error={((this.state.website_by_channels[item] === "" || !this.state.website_by_channels[item].includes("https://")) && this.state.type !== "posts") ? true : false}
                                                error={
                                                    this.state.selected_lead && this.state.selected_lead.id !== 0 ? false : ((this.state.website_by_channels[item] === "" || !this.state.website_by_channels[item].includes("https://")) && this.state.type !== "posts") ? true : false
                                                }
                                                channel={item}
                                                locked={this.state.channel_menu === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0 ? true : false}
                                                disabled={this.state.channel_menu === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0 ? true : false}
                                                label={"Website"}
                                                rightLabel={!this.state.catalog && !this.state.channel_menu === "snapchat" && this.state.client}
                                                rightLabelText={"Scrape url"}
                                                rightLabelDisabled={this.state.channel_menu === "snapchat" ? true : false}
                                                onRightLabelClick={() => {
                                                    if (!this.state.loading_scraper && !this.state.channel_menu === "snapchat") {
                                                        this.functions.scrape();
                                                    }
                                                }}
                                                value={this.state.website_by_channels[item]}
                                                onChange={async (value) => {
                                                    this.state.website_by_channels[item] = value;
                                                    await this.promisedSetState({
                                                        website_by_channels: this.state.website_by_channels
                                                    })
                                                    this.functions.updatePreview();
                                                }}
                                            />
                                            {
                                                (!this.state.selected_lead || this.state.selected_lead.id === 0) &&
                                                (this.state.website_by_channels[item] === "" || !this.state.website_by_channels[item].includes("https://")) &&
                                                <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                                    OBS! Prefix https:// is required.
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                                {
                                    !this.state.merge &&
                                    this.state.channel_menu === "tiktok" &&
                                    this.state.tiktok_destination.value === "app" &&
                                    <div className={"col-span-3"}>
                                        <DropdownTailwind
                                            channel="tiktok"
                                            label={"App profile"}
                                            loader={this.state.loading_tiktok}
                                            selected={this.state.tiktok_app_profile}
                                            options={this.state.tiktok_app_pages}
                                            onChange={async (value) => {
                                                this.state.tiktok_app_profile = value;
                                                await this.promisedSetState({
                                                    tiktok_app_profile: this.state.tiktok_app_profile
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.merge &&
                                    this.state.channel_menu === "tiktok" &&
                                    <div className={"col-span-1"}>
                                        <DropdownTailwind
                                            channel="tiktok"
                                            label={"Destination page"}
                                            loader={this.state.loading_tiktok}
                                            selected={this.state.tiktok_destination}
                                            options={[
                                                { id: 1, name: 'Website', value: "website" },
                                                { id: 2, name: 'App Profile', value: "app" },
                                            ]}
                                            onChange={async (value) => {
                                                this.state.tiktok_destination = value;
                                                await this.promisedSetState({
                                                    tiktok_destination: this.state.tiktok_destination
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.merge &&
                                    this.state.channel_menu === "tiktok" &&
                                    this.state.tiktok_destination.value === "app" &&
                                    <>
                                        <div className="col-span-4">
                                            <div className="flex flex-row">
                                                <div className="mr-1">
                                                    <div className={"bg-tiktok-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/tiktok_icon.png')} />
                                                    </div>
                                                </div>
                                                <div className='text-xs font-medium text-gray-700'>Authorize the Ad</div>
                                            </div>
                                            <div className='flex items-center justify-center mt-1 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-xs">
                                                    {this.state.tiktok_creative_authorized.name}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.tiktok_creative_authorized.value}
                                                        onSwitch={async () => {
                                                            if (this.state.tiktok_creative_authorized.value) {
                                                                this.state.tiktok_creative_authorized = { id: 2, name: "Disabled", value: false };
                                                                await this.promisedSetState({
                                                                    tiktok_creative_authorized: this.state.tiktok_creative_authorized
                                                                })

                                                            } else {
                                                                this.state.tiktok_creative_authorized = { id: 1, name: "Enabled", value: true };
                                                                await this.promisedSetState({
                                                                    tiktok_creative_authorized: this.state.tiktok_creative_authorized
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-span-4 flex transition-all duration-1000 overflow-hidden text-sm text-blue-500 rounded-sm bg-blue-100"}>
                                            <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-1" />
                                            By checking the box, you authorize the ad and the associated performance metrics of the campaign to be displayed on TikTok branded or affiliated platforms.
                                        </div>
                                    </>
                                }
                                {
                                    !this.state.merge && (!this.state.selected_lead || this.state.selected_lead.id === 0) &&
                                    ((this.state.channel_menu === "snapchat" && this.state.snapchat_attachment.value === "WEB_VIEW") || (!["snapchat", "tiktok"].includes(this.state.channel_menu)) || (this.state.channel_menu === "tiktok" && this.state.tiktok_destination.value === "website")) &&
                                    <div className="col-span-4 mb-2 grid-cols-6 gap-4 inline-flex">
                                        <div onClick={() => {
                                            if (this.state.utm === false) {
                                                this.state.utm = true;
                                            } else {
                                                this.state.utm = false;
                                            }

                                            this.setState({
                                                group: this.state.group
                                            });
                                        }} className="col-span-3 flex flex-row items-center text-sm font-medium bg-purple-100 rounded-full px-4 py-2 cursor-pointer text-purple-500 hover:text-purple-700">
                                            Url extension
                                            {
                                                this.state.utm === true &&
                                                <ChevronDoubleUpIcon className="w-3 h-3 ml-2" />
                                            }
                                            {
                                                this.state.utm === false &&
                                                <ChevronDoubleDownIcon className="w-3 h-3 ml-2" />
                                            }
                                        </div>
                                        {
                                            this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => (
                                                <div onClick={async () => {
                                                    this.state.utm_channels.filter((item) => { return item !== this.state.channel_menu }).map((inner_item) => {
                                                        if (inner_item === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0) {

                                                        } else {
                                                            this.state.website_by_channels[inner_item] = this.state.website_by_channels[item];
                                                        }
                                                    })
                                                    await this.promisedSetState({
                                                        website_by_channels: this.state.website_by_channels
                                                    })
                                                    this.functions.updatePreview();

                                                }}
                                                    className="col-span-3 items-center text-sm font-medium bg-purple-100 rounded-full px-4 py-2 cursor-pointer text-purple-500 hover:text-purple-700 float-right">
                                                    Apply to all
                                                </div>

                                            ))
                                        }
                                    </div>
                                }
                                <div className={!this.state.merge && this.state.utm ? "border-gray-500 border rounded-md p-5 grid grid-cols-4 gap-4 col-span-4" : "col-span-4"}>
                                    {
                                        !this.state.merge &&
                                        this.state.utm &&
                                        this.state.utm_channels.filter((item) => { return item !== "google" }).map((item) => {
                                            return (
                                                <div className="col-span-4">
                                                    <InputTailwind
                                                        locked={this.state.channel_menu === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0 ? true : false}
                                                        disabled={this.state.channel_menu === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0 ? true : false}
                                                        error={this.state.utm_error[item]}
                                                        errorMessage={'Invalid format "can\'t start with ? or &"'}
                                                        channel={item}
                                                        label={item + " extension"}
                                                        value={this.state.utm_values[item]}
                                                        onChange={async (value) => {
                                                            if (value.charAt(0) === "?" || value.charAt(0) === "&") {
                                                                this.state.utm_error[item] = true;
                                                                this.props.onDisable(true);
                                                            } else {
                                                                this.state.utm_error[item] = false;
                                                                if (!this.state.utm_error["facebook"] && !this.state.utm_error["linkedin"] && !this.state.utm_error["tiktok"] && !this.state.utm_error["twitter"] && !this.state.utm_error["snapchat"]) {
                                                                    this.props.onDisable(false);
                                                                }
                                                            }

                                                            this.state.utm_values[item] = value
                                                            await this.promisedSetState({
                                                                utm_values: this.state.utm_values
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                {
                                    this.state.merge &&
                                    Array.isArray(this.state.merge.links) &&
                                    this.state.merge.links.map((item) => {
                                        return (
                                            <div className={this.state.merge.links.length < 2 ? "col-span-4" : "col-span-2"}>
                                                <InputTailwind
                                                    locked={this.state.channel_menu === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0 ? true : false}
                                                    disabled={this.state.channel_menu === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0 ? true : false}
                                                    label={"Website"}
                                                    value={item.value}
                                                    error={!item.value || !item.value.includes("https://") ? true : false}
                                                    rightLabel={this.state.merge.links.length > 1}
                                                    rightLabelText={"Remove"}
                                                    rightLabelColor={"text-red-500 hover:text-red-700"}
                                                    onRightLabelClick={() => {
                                                        this.state.merge.links = this.state.merge.links.filter((inner_item) => { return item.id !== inner_item.id });
                                                        this.promisedSetState({
                                                            merge: this.state.merge
                                                        })
                                                    }}
                                                    onChange={async (value) => {
                                                        item.value = value;
                                                        await this.promisedSetState({
                                                            merge: this.state.merge
                                                        })
                                                        this.functions.updatePreview();
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.merge &&
                                    <div className={"col-span-4"}>
                                        <InputTailwind
                                            locked={this.state.channel_menu === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0 ? true : false}
                                            disabled={this.state.channel_menu === "facebook" && this.state.fb_lead_form && this.state.selected_lead.id !== 0 ? true : false}
                                            label={"Diplsay link"}
                                            value={this.state.merge.caption}
                                            error={this.state.merge.caption && this.state.merge.caption !== "" && !this.state.merge.caption.includes("https://") ? true : false}
                                            onChange={async (value) => {
                                                this.state.merge.caption = value;
                                                await this.promisedSetState({
                                                    merge: this.state.merge
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }


                                {
                                    this.state.merge &&
                                    Array.isArray(this.state.merge.headlines) &&
                                    this.state.merge.headlines.map((item) => {
                                        return (
                                            <div className={this.state.merge.headlines.length < 2 ? "col-span-4 grid grid-cols-4" : "col-span-2"}>
                                                <div className=" col-span-4">
                                                    <InputTailwind
                                                        locked={this.state.type === "posts" ? true : false}
                                                        label={"Headline"}
                                                        value={item.value}
                                                        error={!item.value || item.value.length < 1 ? true : false}
                                                        rightLabel={this.state.merge.headlines.length > 1}
                                                        rightLabelText={"Remove"}
                                                        rightLabelColor={"text-red-500 hover:text-red-700"}
                                                        onRightLabelClick={() => {
                                                            this.state.merge.headlines = this.state.merge.headlines.filter((inner_item) => { return item.id !== inner_item.id });
                                                            this.promisedSetState({
                                                                merge: this.state.merge
                                                            })
                                                        }}
                                                        onChange={async (value) => {
                                                            item.value = value;
                                                            await this.promisedSetState({
                                                                merge: this.state.merge
                                                            })
                                                            this.functions.updatePreview();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    false &&
                                    this.state.merge &&
                                    Array.isArray(this.state.merge.headlines) &&
                                    this.state.merge.headlines.length < 5 &&
                                    <div onClick={() => {
                                        if (this.state.merge.headlines.length < 5) {
                                            this.state.merge.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), value: "" });
                                            this.promisedSetState({
                                                merge: this.state.merge
                                            })
                                        }
                                    }} className="text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700 col-span-6 text-left">
                                        Add title
                                    </div>
                                }
                                {
                                    this.state.merge &&
                                    Array.isArray(this.state.merge.bodies) &&
                                    this.state.merge.bodies.map((item) => {
                                        return (
                                            <div className={this.state.merge.bodies.length < 2 ? "col-span-2" : "col-span-2"}>
                                                <TextAreaTailwind
                                                    label={"Body"}
                                                    value={item.value}
                                                    error={!item.value || item.value.length < 1 ? true : false}
                                                    rightLabel={this.state.merge.bodies.length > 1}
                                                    rightLabelText={"Remove"}
                                                    rightLabelColor={"text-red-500 hover:text-red-700"}
                                                    onRightLabelClick={() => {
                                                        this.state.merge.bodies = this.state.merge.bodies.filter((inner_item) => { return item.id !== inner_item.id });
                                                        this.promisedSetState({
                                                            merge: this.state.merge
                                                        })
                                                    }}
                                                    onChange={async (value) => {
                                                        item.value = value;
                                                        await this.promisedSetState({
                                                            merge: this.state.merge
                                                        })
                                                        this.functions.updatePreview();
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.merge &&
                                    Array.isArray(this.state.merge.descriptions) &&
                                    this.state.merge.descriptions.map((item) => {
                                        return (
                                            <div className={this.state.merge.descriptions.length < 2 ? "col-span-2" : "col-span-2"}>
                                                <TextAreaTailwind
                                                    locked={this.state.type === "posts" ? true : false}
                                                    label={"Description"}
                                                    value={item.value}
                                                    rightLabel={this.state.merge.descriptions.length > 1}
                                                    rightLabelText={"Remove"}
                                                    rightLabelColor={"text-red-500 hover:text-red-700"}
                                                    onRightLabelClick={() => {
                                                        this.state.merge.descriptions = this.state.merge.descriptions.filter((inner_item) => { return item.id !== inner_item.id });
                                                        this.promisedSetState({
                                                            merge: this.state.merge
                                                        })
                                                    }}
                                                    onChange={async (value) => {
                                                        item.value = value;
                                                        await this.promisedSetState({
                                                            merge: this.state.merge
                                                        })
                                                        this.functions.updatePreview();
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }

                                {
                                    !this.state.merge &&
                                    !this.state.catalog &&
                                    this.state.channel_menu === "facebook" &&
                                    (!this.state.selected_lead || this.state.selected_lead.id === 0) &&
                                    <div className="col-span-4">
                                        <InputTailwind
                                            channel="facebook"
                                            label={"Display link"}
                                            disabled={this.state.type === "posts" ? true : false}
                                            locked={this.state.type === "posts" ? true : false}
                                            value={this.state.caption}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    caption: value
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.instagram_post &&
                                    !this.state.merge &&
                                    !this.state.catalog &&
                                    this.state.channel_menu !== "tiktok" &&
                                    !(this.state.channel_menu === "twitter" && this.state.type === "carousel") &&
                                    this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => (
                                        <div className="col-span-4 relative">
                                            <InputTailwind
                                                // error={this.state.headline_by_channels[item] === "" ? true : false}
                                                error={this.state.type === "posts" && false || this.state.type !== "posts" && this.state.headline_by_channels[item] === "" ? true : false}
                                                channel={item}
                                                label={this.state.channel_menu === "snapchat" ? "Headline (34 characters max)" : "Headline"}
                                                maxLength={(this.state.channel_menu === "snapchat" && 34)}
                                                disabled={this.state.type === "posts" ? true : false}
                                                locked={this.state.type === "posts" ? true : false}
                                                value={this.state.headline_by_channels[item]}
                                                onChange={async (value) => {
                                                    this.state.headline_by_channels[item] = value;
                                                    await this.promisedSetState({
                                                        headline: value
                                                    })
                                                    this.functions.updatePreview();
                                                }}
                                            />
                                            {
                                                this.state.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.filter((inner_item) => { return inner_item.channel === item && inner_item.value.includes(this.state.headline_by_channels[item]) }).length > 0 }).length > 0 &&
                                                <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                    <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                </div>
                                            }

                                        </div>

                                    ))


                                }
                                {
                                    ((!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) && this.state.channel_menu === "snapchat") &&
                                    this.state.channels.snapchat &&
                                    <div className="col-span-2">
                                        <div className="flex flex-row">
                                            <div className="mr-1">
                                                <div className={"bg-snapchat-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                    <img className="w-2"
                                                        alt=""
                                                        src={require('../assets/images/snapchat.svg')} />
                                                </div>
                                            </div>
                                            <div className='text-xs font-medium text-gray-700'>Shareable</div>
                                        </div>
                                        <div className='flex items-center justify-center mt-1 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-xs">
                                                {this.renders.snapchatShareable().name}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.renders.snapchatShareable().value}
                                                    onSwitch={async () => {
                                                        if (this.renders.snapchatShareable().value) {
                                                            this.state.snapchat_shareable = { id: 2, name: "Disabled", value: false };
                                                            await this.promisedSetState({
                                                                snapchat_shareable: this.state.snapchat_shareable
                                                            })

                                                        } else {
                                                            this.state.snapchat_shareable = { id: 1, name: "Enabled", value: true };
                                                            await this.promisedSetState({
                                                                snapchat_shareable: this.state.snapchat_shareable
                                                            })
                                                        }
                                                        this.functions.updatePreview();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    /*<div className='col-span-4'>
                                        <DropdownTailwind
                                            channel={"snapchat"}
                                            label={"Shareable"}
                                            selected={this.renders.snapchatShareable()}
                                            options={[{ id: 1, name: "Enabled", value: true }, { id: 2, name: "Disabled", value: false }]}
                                            onChange={async (value) => {
                                                this.state.snapchat_shareable = value;
                                                await this.promisedSetState({
                                                    snapchat_shareable: this.state.snapchat_shareable
                                                })
                                            }}
                                        />
                                    </div>*/
                                }

                                {
                                    ((!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) && this.state.channel_menu === "snapchat") &&
                                    this.state.channels.snapchat &&
                                    <div className='col-span-2'>
                                        <DropdownTailwind
                                            channel={"snapchat"}
                                            label={"Attachment"}
                                            loader={this.state.loading_snapchat}
                                            selected={this.state.snapchat_attachment}
                                            options={this.state.snapchat_attachment_options}
                                            onChange={async (value) => {
                                                this.state.snapchat_attachment = value;
                                                await this.promisedSetState({
                                                    snapchat_attachment: this.state.snapchat_attachment,
                                                    snapchat_attachment_error:
                                                        (value.value === "NONE" && this.state.adgroups.length > 0 && this.state.adgroups.filter(item => item.goal.value === "SWIPES").length > 0) ? true : false
                                                })


                                                if (!this.state.ctas.snapchat.filter((item) => { return item.allowed.includes(this.state.snapchat_attachment.value) }).map((item) => { return item.id }).includes(this.state.cta.snapchat.id)) {
                                                    this.state.cta.snapchat = this.state.ctas.snapchat.filter((item) => { return item.allowed.includes(this.state.snapchat_attachment.value) })[0];
                                                    await this.promisedSetState({
                                                        cta: this.state.cta
                                                    })
                                                }
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {/* {
                                    this.state.snapchat_attachment.value === "NONE" &&
                                    this.state.adgroups.length > 0 && this.state.adgroups.filter(item => item.goal.value === "SWIPES").length > 0 &&
                                    <div className="col-span-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Optimization goal "Swipe up" requires website attachment.
                                    </div>
                                } */}

                                {
                                    this.state.channels.snapchat &&
                                    this.state.channel_menu === "snapchat" &&
                                    ["APP_INSTALL", "DEEP_LINK"].includes(this.state.snapchat_attachment.value) &&
                                    <>
                                        {
                                            this.state.snapchat_attachment.value === "DEEP_LINK" &&
                                            <div className='col-span-4'>
                                                <InputTailwind
                                                    channel={"snapchat"}
                                                    label={"Deep link URI"}
                                                    type={"text"}
                                                    value={this.state.snapchat_deep_link_uri}
                                                    onChange={async (value) => {
                                                        await this.promisedSetState({
                                                            snapchat_deep_link_uri: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                        <div className='col-span-2'>
                                            <div className="flex flex-row">
                                                <div className="mr-1">
                                                    <div className={"bg-snapchat-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/snapchat.svg')} />
                                                    </div>
                                                </div>
                                                <div className='text-xs font-medium text-gray-700'>Ad favouriting</div>
                                            </div>
                                            <div className='flex items-center justify-center mt-1 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-xs">
                                                    {this.renders.favourit().name}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.favourit().value}
                                                        onSwitch={async () => {
                                                            if (this.renders.favourit().value) {
                                                                this.state.snapchat_favourit = { id: 2, name: "Disabled", value: false };
                                                                await this.promisedSetState({
                                                                    snapchat_favourit: this.state.snapchat_favourit
                                                                })

                                                            } else {
                                                                this.state.snapchat_favourit = { id: 1, name: "Enabled", value: true };
                                                                await this.promisedSetState({
                                                                    snapchat_favourit: this.state.snapchat_favourit
                                                                })
                                                            }
                                                            this.functions.updatePreview();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-2'>
                                            <div className="flex flex-row">
                                                <div className="mr-1">
                                                    <div className={"bg-snapchat-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/snapchat.svg')} />
                                                    </div>
                                                </div>
                                                <div className='text-xs font-medium text-gray-700'>Switch to standard button colour</div>
                                            </div>
                                            <div className='flex items-center justify-center mt-1 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-xs">
                                                    {this.renders.standardButton().name}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.standardButton().value}
                                                        onSwitch={async () => {
                                                            if (this.renders.standardButton().value) {
                                                                this.state.snapchat_standardButton = { id: 2, name: "Disabled", value: false };
                                                                await this.promisedSetState({
                                                                    snapchat_standardButton: this.state.snapchat_standardButton
                                                                })

                                                            } else {
                                                                this.state.snapchat_standardButton = { id: 1, name: "Enabled", value: true };
                                                                await this.promisedSetState({
                                                                    snapchat_standardButton: this.state.snapchat_standardButton
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-2'>
                                            <InputTailwind
                                                channel={"snapchat"}
                                                label={"App name"}
                                                type={"text"}
                                                value={this.state.snapchat_app_name}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        snapchat_app_name: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-2 grid grid-cols-6 gap-2">
                                            <div className="col-span-5">
                                                <UploadImageTailwind
                                                    label={"App icon (Ratio 1:1 and only PNG)"}
                                                    formatAllowed={"image/png"}
                                                    error={this.state.snapchat_app_icon && this.state.snapchat_app_icon.ratio && this.state.snapchat_app_icon.ratio !== "1:1" ? true : false}
                                                    client={this.state.client}
                                                    onChange={async (value) => {
                                                        await this.promisedSetState({
                                                            snapchat_app_icon: value
                                                        })
                                                        console.log(this.state.snapchat_app_icon.url);
                                                        this.functions.updatePreview();
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                {
                                                    this.state.snapchat_app_icon &&
                                                    <img src={this.state.snapchat_app_icon.url} className='w-12 mt-5.5 rounded-md' />
                                                }
                                            </div>
                                        </div>

                                        <div className='col-span-2'>
                                            <InputTailwind
                                                channel={"snapchat"}
                                                label={"iOS app ID"}
                                                type={"text"}
                                                value={this.state.snapchat_url_ios}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        snapchat_url_ios: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='col-span-2'>
                                            <InputTailwind
                                                channel={"snapchat"}
                                                label={"Android app URL"}
                                                type={"text"}
                                                value={this.state.snapchat_url_android}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        snapchat_url_android: value
                                                    })
                                                }}
                                            />
                                        </div>


                                        <div className="col-span-2">
                                            <div className="flex flex-row">
                                                <div className="mr-1">
                                                    <div className={"bg-snapchat-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/snapchat.svg')} />
                                                    </div>
                                                </div>
                                                <div className='text-xs font-medium text-gray-700'>Use custom iOS product page</div>
                                            </div>
                                            <div className='flex items-center justify-center mt-1 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-xs">
                                                    {this.renders.iosCustom().name}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.iosCustom().value}
                                                        disabled={this.state.snapchat_attachment.value === "DEEP_LINK" && this.state.snapchat_fallback_type.value === "website" ? true : false}
                                                        locked={this.state.snapchat_attachment.value === "DEEP_LINK" && this.state.snapchat_fallback_type.value === "website" ? true : false}
                                                        onSwitch={async () => {
                                                            if (this.renders.iosCustom().value) {
                                                                this.state.snapchat_iosCustom = { id: 2, name: "Disabled", value: false };
                                                                await this.promisedSetState({
                                                                    snapchat_iosCustom: this.state.snapchat_iosCustom
                                                                })

                                                            } else {
                                                                this.state.snapchat_iosCustom = { id: 1, name: "Enabled", value: true };
                                                                await this.promisedSetState({
                                                                    snapchat_iosCustom: this.state.snapchat_iosCustom
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-2'>
                                            {
                                                this.state.snapchat_attachment.value === "DEEP_LINK" &&
                                                <DropdownTailwind
                                                    channel={"snapchat"}
                                                    label={"Fallback type"}
                                                    selected={this.state.snapchat_fallback_type}
                                                    options={[{ id: 1, name: "App installation", value: "app_intall" }, { id: 2, name: "Website", value: "website" }]}
                                                    onChange={async (value) => {
                                                        this.state.snapchat_fallback_type = value;
                                                        await this.promisedSetState({
                                                            snapchat_fallback_type: this.state.snapchat_fallback_type
                                                        })
                                                        if (value.value === "website") {
                                                            this.state.snapchat_iosCustom = { id: 2, name: "Disabled", value: false }
                                                            await this.promisedSetState({
                                                                snapchat_iosCustom: this.state.snapchat_iosCustom
                                                            })
                                                        }
                                                        //this.functions.updatePreview();
                                                    }}
                                                />
                                            }
                                        </div>
                                        {
                                            this.state.snapchat_fallback_type.value === "website" &&
                                            !this.renders.iosCustom().value &&
                                            <div className='col-span-4'>
                                                <InputTailwind
                                                    channel={"snapchat"}
                                                    label={"Fallback URL"}
                                                    type={"text"}
                                                    value={this.state.snapchat_fallback_url}
                                                    onChange={async (value) => {
                                                        await this.promisedSetState({
                                                            snapchat_fallback_url: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }


                                        {
                                            this.renders.iosCustom().value &&
                                            <>
                                                <div className='col-span-4'>
                                                    <InputTailwind
                                                        channel={"snapchat"}
                                                        label={"Custom product page ID (ppid)"}
                                                        type={"text"}
                                                        locked={this.renders.iosCustom().value ? false : true}
                                                        disabled={this.renders.iosCustom().value ? false : true}
                                                        placeholder={"45512c9b-c246-43d3-c6a0-c5a0245512c9b-c246-43d3-c6a0-c5a02f74bf7a"}
                                                        maxLength={45}
                                                        value={this.state.snapchat_iosCustom_url}
                                                        onChange={async (value) => {
                                                            await this.promisedSetState({
                                                                snapchat_iosCustom_url: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }


                                    </>

                                }
                                {
                                    ((!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) && this.state.channel_menu === "snapchat") &&
                                    this.state.channels.snapchat && (this.state.snapchat_attachment.value === "AD_TO_CALL" || this.state.snapchat_attachment.value === "AD_TO_MESSAGE") &&
                                    <div className='col-span-4'>
                                        <InputTailwind
                                            channel={"snapchat"}
                                            label={"Phone number"}
                                            input={"tel"}
                                            loader={this.state.loading_snapchat}
                                            value={this.state.snapchat_phone}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    snapchat_phone: value
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    false &&
                                    ((!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) && this.state.channel_menu === "snapchat") &&
                                    this.state.channels.snapchat && this.state.snapchat_attachment.value === "AD_TO_MESSAGE" &&
                                    <div className='col-span-4'>
                                        <TextAreaTailwind
                                            disabled={this.state.type === "posts" ? true : false}
                                            locked={this.state.type === "posts" ? true : false}
                                            channel={"snapchat"}
                                            // channel={item}
                                            label={(this.state.channel_menu === "snapchat" ? "Text (Maximum 160 characters)" : "Text")}
                                            maxLength={(this.state.channel_menu === "snapchat" ? 160 : 1000000)}
                                            // value={this.state.body_by_channels[item]}
                                            // onChange={(value) => {
                                            //     this.state.body_by_channels[item] = value;
                                            //     this.setState({
                                            //         body_by_channels: this.state.body_by_channels
                                            //     })
                                            value={this.state.snapchat_message}
                                            onChange={async (value) => {
                                                this.promisedSetState({
                                                    snapchat_message: value
                                                })
                                            }}
                                        />
                                    </div>

                                }
                                {
                                    !this.state.merge &&
                                    this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => {
                                        if (item === "facebook") {
                                            return (
                                                <div className="col-span-4">
                                                    <div className="grid grid-cols-6 gap-4 ">
                                                        <div className={!this.state.catalog ? "col-span-6 relative" : "col-span-6 relative"}>
                                                            <TextAreaTailwind
                                                                error={this.state.body_by_channels[item] === "" ? true : false}
                                                                disabled={this.state.type === "posts" ? true : false}
                                                                locked={this.state.type === "posts" ? true : false}
                                                                channel={item}
                                                                label={"Primary text"}
                                                                value={this.state.body_by_channels[item]}
                                                                onChange={async (value) => {
                                                                    this.state.body_by_channels[item] = value
                                                                    await this.promisedSetState({
                                                                        body_by_channels: this.state.body_by_channels
                                                                    })
                                                                    this.functions.updatePreview();
                                                                }}
                                                            />
                                                            {
                                                                this.state.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.filter((inner_item) => { return inner_item.channel === item && inner_item.value.includes(this.state.body_by_channels[item]) }).length > 0 }).length > 0 &&
                                                                <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                                    <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            !this.state.catalog &&
                                                            <div className="col-span-6">
                                                                <TextAreaTailwind
                                                                    disabled={this.state.type === "posts" ? true : false}
                                                                    locked={this.state.type === "posts" ? true : false}
                                                                    channel={item}
                                                                    label={"Description"}
                                                                    value={this.state.description}
                                                                    onChange={async (value) => {
                                                                        await this.promisedSetState({
                                                                            description: value
                                                                        })
                                                                        this.functions.updatePreview();
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else if (item === "snapchat") {
                                            return;
                                        } else {
                                            return (
                                                <div className="col-span-4 relative">
                                                    <TextAreaTailwind
                                                        disabled={this.state.type === "posts" ? true : false}
                                                        locked={this.state.type === "posts" ? true : false}
                                                        channel={item}
                                                        error={this.state.body_by_channels[item] === "" && this.state.type !== "posts" ? true : false}
                                                        label={(this.state.channel_menu === "tiktok" ? "Text (Maximum 100 characters)" : (this.state.channel_menu === "linkedin" ? "Text (Maximum 600 characters)" : "Text"))}
                                                        maxLength={(this.state.channel_menu === "tiktok" ? 100 : (this.state.channel_menu === "linkedin" ? 600 : 1000000))}
                                                        value={this.state.body_by_channels[item]}
                                                        onChange={async (value) => {
                                                            this.state.body_by_channels[item] = value;
                                                            await this.promisedSetState({
                                                                body_by_channels: this.state.body_by_channels
                                                            })
                                                            this.functions.updatePreview();
                                                        }}
                                                    />
                                                    {
                                                        this.state.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.filter((inner_item) => { return inner_item.channel === item && inner_item.value.includes(this.state.body_by_channels[item]) }).length > 0 }).length > 0 &&
                                                        <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                            <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }

                                    })
                                }
                                {/* {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) &&
                                    this.state.channels.snapchat &&
                                    this.state.channel_menu === "snapchat" &&
                                    this.state.snapchat_attachment.value === "NONE" &&
                                    <div className="col-span-4">
                                        <InputTailwind
                                            channel={"snapchat"}
                                            label={"Impression tag"}
                                            value={this.state.snapchat_impression_tags}
                                            type={"text"}
                                            onChange={async (value) => {
                                                console.log(value)
                                                this.promisedSetState({
                                                    snapchat_impression_tags: value
                                                })
                                            }}
                                        />
                                    </div>
                                } */}
                                {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) &&
                                    this.state.channels.snapchat &&
                                    this.state.channel_menu === "snapchat" &&
                                    this.state.snapchat_attachment.value === "WEB_VIEW" &&
                                    <>
                                        <div className="col-span-4 grid gap-4 grid-cols-4">
                                            <div className="col-span-2">
                                                <div className="flex flex-row">
                                                    <div className="mr-1">
                                                        <div className={"bg-snapchat-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                            <img className="w-2"
                                                                alt=""
                                                                src={require('../assets/images/snapchat.svg')} />
                                                        </div>
                                                    </div>
                                                    <div className='text-xs font-medium text-gray-700'>Autofill</div>
                                                </div>
                                                <div className='flex items-center justify-center mt-1 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 text-xs">
                                                        {this.renders.snapchatAutofill().name}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            value={this.renders.snapchatAutofill().value}
                                                            onSwitch={async () => {
                                                                if (this.renders.snapchatAutofill().value) {
                                                                    this.state.snapchat_autofill = { id: 2, name: "Disabled", value: false };
                                                                    await this.promisedSetState({
                                                                        snapchat_autofill: this.state.snapchat_autofill
                                                                    })

                                                                } else {
                                                                    this.state.snapchat_autofill = { id: 1, name: "Enabled", value: true };
                                                                    await this.promisedSetState({
                                                                        snapchat_autofill: this.state.snapchat_autofill
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-2">
                                                <div className="flex flex-row">
                                                    <div className="mr-1">
                                                        <div className={"bg-snapchat-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>

                                                            <img className="w-2"
                                                                alt=""
                                                                src={require('../assets/images/snapchat.svg')} />
                                                        </div>
                                                    </div>
                                                    <div className='text-xs font-medium text-gray-700'>Prefetching</div>
                                                </div>
                                                <div className='flex items-center justify-center mt-1 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 text-xs">
                                                        {this.renders.snapchatPrefetching().name}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            value={this.renders.snapchatPrefetching().value}
                                                            onSwitch={async () => {
                                                                if (this.renders.snapchatPrefetching().value) {
                                                                    this.state.snapchat_prefetching = { id: 2, name: "Disabled", value: false };
                                                                    await this.promisedSetState({
                                                                        snapchat_prefetching: this.state.snapchat_prefetching
                                                                    })

                                                                } else {
                                                                    this.state.snapchat_prefetching = { id: 1, name: "Enabled", value: true };
                                                                    await this.promisedSetState({
                                                                        snapchat_prefetching: this.state.snapchat_prefetching
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) &&
                                    this.state.channels.snapchat &&
                                    this.state.channel_menu === "snapchat" &&
                                    this.state.snapchat_attachment.value === "WEB_VIEW" &&
                                    <>
                                        <div className="col-span-4">
                                            <InputTailwind
                                                channel={"snapchat"}
                                                label={"Impression tag"}
                                                value={this.state.snapchat_impression_tags}
                                                type={"text"}
                                                onChange={async (value) => {
                                                    console.log(value)
                                                    this.promisedSetState({
                                                        snapchat_impression_tags: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-4">
                                            <InputTailwind
                                                channel={"snapchat"}
                                                label={"Swipe tag"}
                                                value={this.state.snapchat_swipe_tags}
                                                type={"text"}
                                                onChange={async (value) => {
                                                    console.log(value)
                                                    this.promisedSetState({
                                                        snapchat_swipe_tags: value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </>
                                } */}
                                {/* HEREE
                                    !this.state.merge &&
                                    this.state.carousel && this.state.channel_menu === "facebook" &&
                                    <div className="col-span-2"></div>
                            */}
                                {
                                    !this.state.merge &&
                                    this.state.carousel && this.state.channel_menu === "facebook" &&
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Automatically show the best performing cards first"}
                                            selected={this.state.auto_cards}
                                            options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    auto_cards: value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.merge &&
                                    this.state.carousel && this.state.channel_menu === "facebook" &&
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Add a card at the end with your Page profile picture"}
                                            selected={this.state.last_card}
                                            options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    last_card: value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.channel_menu !== "twitter" &&
                                    <div className="col-span-4 text-lg font-semibold">
                                        Select CTA
                                    </div>
                                }
                                {
                                    this.state.merge &&
                                    Array.isArray(this.state.merge.call_to_actions) &&
                                    this.state.merge.call_to_actions.map((item) => {
                                        return (
                                            <>
                                                <div className={"col-span-4"}>
                                                    <DropdownTailwind
                                                        channel="facebook"
                                                        label={"Facebook call-to-action"}
                                                        searchInput={true}
                                                        selected={item.value}
                                                        options={this.state.ctas.facebook.filter((item) => { return item.objective.length > 0 })}
                                                        //options={this.state.ctas.facebook}
                                                        rightLabel={this.state.merge.call_to_actions.length > 1}
                                                        rightLabelText={"Remove"}
                                                        rightLabelColor={"text-red-500 hover:text-red-700"}
                                                        onRightLabelClick={() => {
                                                            this.state.merge.call_to_actions = this.state.merge.call_to_actions.filter((inner_item) => { return item.id !== inner_item.id });
                                                            this.promisedSetState({
                                                                merge: this.state.merge
                                                            })
                                                        }}
                                                        onChange={async (cta) => {
                                                            item.value = cta;
                                                            await this.promisedSetState({
                                                                merge: this.state.merge
                                                            })
                                                            this.functions.updatePreview();
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook &&
                                    this.state.channel_menu === "facebook" &&
                                    <>
                                        <div className="col-span-4">
                                            <DropdownTailwind
                                                channel="facebook"
                                                label={"Facebook call-to-action"}
                                                searchInput={true}
                                                selected={this.state.cta.facebook}
                                                // options={this.state.ctas.facebook.filter((item) => { return item.objective.length > 0 })}
                                                options={this.state.ctas.facebook}
                                                onChange={async (cta) => {
                                                    this.state.cta.facebook = cta;
                                                    await this.promisedSetState({
                                                        cta: this.state.cta
                                                    })
                                                    this.functions.updatePreview();
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "tiktok")) &&
                                    this.state.channels.tiktok &&
                                    this.state.channel_menu === "tiktok" &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            channel="tiktok"
                                            label={"Tiktok call-to-action"}
                                            searchInput={true}
                                            selected={this.state.cta.tiktok}
                                            options={this.state.ctas.tiktok}
                                            onChange={async (cta) => {
                                                this.state.cta.tiktok = cta;
                                                await this.promisedSetState({
                                                    cta: this.state.cta
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }

                                {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "snapchat")) &&
                                    this.state.channels.snapchat &&
                                    this.state.channel_menu === "snapchat" &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            locked={this.state.snapchat_attachment.value !== "NONE" || this.state.updated && this.state.updated.snapchat_attachment.value !== "NONE" ? false : true}
                                            channel="snapchat"
                                            label={"Snapchat call-to-action"}
                                            searchInput={true}
                                            selected={this.state.snapchat_attachment.value !== "NONE" || this.state.updated && this.state.updated.snapchat_attachment.value !== "NONE" ? this.state.cta.snapchat : { id: 1, name: "None", value: "NONE", allowed: ["awareness"] }}
                                            options={this.state.ctas.snapchat.filter((item) => { return item.allowed.includes(this.state.snapchat_attachment.value) })}
                                            onChange={async (cta) => {
                                                this.state.cta.snapchat = cta;
                                                await this.promisedSetState({
                                                    cta: this.state.cta
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.merge &&
                                    !this.state.catalog &&
                                    this.state.channels.linkedin &&
                                    this.state.channel_menu === "linkedin" &&
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            channel="linkedin"
                                            label={"Linkedin call-to-action"}
                                            searchInput={true}
                                            locked={this.state.type === "posts" && this.state.ad_type === "carousel" ? true : false}
                                            selected={this.state.cta.linkedin}
                                            options={this.state.ctas.linkedin}
                                            onChange={async (cta) => {
                                                this.state.cta.linkedin = cta;
                                                await this.promisedSetState({
                                                    cta: this.state.cta
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook &&
                                    this.state.channel_menu === "facebook" &&
                                    <div className="col-span-4 text-lg font-semibold">
                                        Tracking settings
                                    </div>
                                }
                                {
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook &&
                                    this.state.channel_menu === "facebook" &&
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Facebook tracking website events"}
                                            searchInput={true}
                                            selected={this.state.tracking.pixel}
                                            options={this.state.pixels}
                                            onChange={async (pixel) => {
                                                this.state.tracking.pixel = pixel;
                                                await this.promisedSetState({
                                                    tracking: this.state.tracking
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook &&
                                    this.state.channel_menu === "facebook" &&
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Facebook tracking app events"}
                                            searchInput={true}
                                            selected={this.state.tracking.app}
                                            options={this.state.apps}
                                            onChange={async (app) => {
                                                this.state.tracking.app = app;
                                                await this.promisedSetState({
                                                    tracking: this.state.tracking
                                                })
                                            }}
                                        />
                                    </div>
                                }

                            </div>
                        }
                        {
                            (this.state.carousel && this.state.carousel_menu === "Slides information") &&
                            <>
                                <div className="flex flex-col space-y-4 px-4">
                                    {
                                        this.state.carousel && Array.isArray(this.state.carousel.files) && this.state.carousel.files.length > 0 &&
                                        this.renderSortableList(this.state.carousel && this.state.carousel.files ? this.state.carousel.files : [])
                                    }

                                </div>
                                {
                                    false &&
                                    <div className="grid grid-cols-6 gap-4 px-2">
                                        {
                                            this.state.carousel.files.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className="col-span-6 text-sm font-medium">
                                                            Slide {index + 1}
                                                        </div>
                                                        <div className="col-span-6">
                                                            <InputTailwind
                                                                label={"Website"}
                                                                value={item.link ? item.link : ""}
                                                                error={(!item.link || !item.link.includes("https://")) ? true : false}
                                                                onChange={async (value) => {
                                                                    this.state.carousel.files[index].link = value;
                                                                    this.promisedSetState({
                                                                        carousel: this.state.carousel
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            (!item.link || !item.link.includes("https://")) &&
                                                            <div className="col-span-6 mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                                                OBS! Prefix https:// is required.
                                                            </div>
                                                        }
                                                        <div className="col-span-3">
                                                            <InputTailwind
                                                                label={"Headline"}
                                                                value={item.title ? item.title : ""}
                                                                error={this.state.utm_channels.filter((item) => { return item === "linkedin" }).length > 0 && (!item.title || item.title === "") ? true : false}
                                                                onChange={async (value) => {
                                                                    this.state.carousel.files[index].title = value;
                                                                    this.promisedSetState({
                                                                        carousel: this.state.carousel
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            this.state.utm_channels.filter((item) => { return item === "facebook" }).length > 0 &&
                                                            <div className="col-span-3">
                                                                <InputTailwind
                                                                    label={"Description"}
                                                                    value={item.body ? item.body : ""}
                                                                    onChange={async (value) => {
                                                                        this.state.carousel.files[index].body = value;
                                                                        this.promisedSetState({
                                                                            carousel: this.state.carousel
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        }

                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                }

                            </>

                        }
                        {
                            (this.state.carousel_menu === "Lead Form") &&
                            <div className="grid grid-cols-6 gap-4 px-2">
                                {
                                    this.state.page.facebook.id === 0 && this.state.fb_lead_form &&
                                    <div className="col-span-6 bg-orange-100 rounded-md p-4 text-sm font-medium text-orange-500 my-2">
                                        OBS! Choose a Facebook page to create a lead form
                                    </div>
                                }
                                <div className="col-span-4">
                                    <DropdownTailwind
                                        channel="facebook"
                                        locked={this.state.page.facebook.id === 0 && this.state.fb_lead_form ? true : false}
                                        disabled={this.state.page.facebook.id === 0 && this.state.fb_lead_form ? true : false}
                                        label={"Lead forms"}
                                        loader={this.state.loading_leadforms}
                                        selected={this.state.selected_lead}
                                        options={this.state.lead_forms}
                                        onChange={async (item) => {
                                            this.state.selected_lead = item;
                                            this.state.ctas.facebook = [
                                                { name: "Subscribe", id: "SUBSCRIBE", type: "SUBSCRIBE", objective: [1, 2, 3, 4, 5, 6] },
                                                { name: "Apply Now", id: "APPLY_NOW", type: "APPLY_NOW", objective: [1, 2, 3, 4, 6] },
                                                { name: "Book now", id: "BOOK_TRAVEL", type: "BOOK_TRAVEL", objective: [] },
                                                { name: "Download", id: "DOWNLOAD", type: "DOWNLOAD", objective: [] },
                                                { name: "Get offer", id: "GET_OFFER", type: "GET_OFFER", objective: [2, 3, 4, 6] },
                                                { name: "Get quote", id: "GET_QUOTE", type: "GET_QUOTE", objective: [1, 2, 3, 6] },
                                                { name: "Learn more", id: "LEARN_MORE", type: "LEARN_MORE", objective: [1, 2, 3, 4, 5, 6] },
                                                { name: "Sign up", id: "SIGN_UP", type: "SIGN_UP", objective: [1, 2, 3, 4, 5, 6] },
                                            ];
                                            if (this.state.cta.facebook.id !== 0 && this.state.ctas.facebook.filter((item) => { return item.id === this.state.cta.facebook.id }).length < 1) {
                                                this.state.cta.facebook = { id: 0, name: "Click here ..." };
                                            }
                                            this.state.website_by_channels.facebook = "";
                                            this.state.utm_values.facebook = "";

                                            await this.promisedSetState({
                                                selected_lead: this.state.selected_lead,
                                                ctas: this.state.ctas,
                                                cta: this.state.cta,
                                                website_by_channels: this.state.website_by_channels,
                                                utm_values: this.state.utm_values
                                            });
                                            this.functions.updatePreview()
                                        }}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <button
                                        onClick={async () => {
                                            if (this.state.page.facebook.id === 0 && this.state.fb_lead_form) {

                                            } else {
                                                await this.promisedSetState({
                                                    loading_new_lead: true
                                                });
                                                this.functions.leadForm();
                                            }
                                        }}
                                        className={(this.state.page.facebook.id === 0 && this.state.fb_lead_form ? "bg-gray-500 hover:bg-gray-600 text-white cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 text-white") + " py-3 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 mt-5.5 inline-flex shadow relative justify-center rounded-md border border-transparent "}
                                    >
                                        Create new
                                        {
                                            this.state.loading_new_lead &&
                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        }

                    </div>
                    {/*
                    <div className="col-span-5 border-l-1.5 pl-6">
                        <div className="grid grid-cols-6 gap-4 rounded-md bg-gray-100">
                            <div className="col-span-6 mb-2 inline-flex pt-5 pl-3">
                                <div onClick={async () => {
                                    if (this.state.ai_suggetion) {
                                        await this.promisedSetState({
                                            ai_suggetion: false
                                        })
                                    }
                                }} className={"col-span-3 flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden text-white " + (this.state.ai_suggetion ? "cursor-pointer bg-gray-300 hover:bg-gray-400 " : " bg-purple-500")}>
                                    {<DeviceMobileIcon className="h-4 w-4 mr-1" />}
                                    Preview
                                </div>
                                <div onClick={async () => {
                                    if (!this.state.ai_suggetion) {
                                        await this.promisedSetState({
                                            ai_suggetion: true
                                        })
                                    }
                                }} className={" ml-5 col-span-3 flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden text-white " + (!this.state.ai_suggetion ? "cursor-pointer bg-gray-300 hover:bg-gray-400 " : " bg-purple-500")}>
                                    {<LightBulbIcon className="h-4 w-4 mr-1" />}
                                    AI
                                </div>
                            </div>


                            {
                                !this.state.ai_suggetion &&
                                <div className="grid grid-cols-6 col-span-6 gap-4">
                                    <div className="col-span-6 text-lg font-semibold pr-5 pl-5">
                                        PREVIEW
                                    </div>
                                    <div className="col-span-6 pr-5 pl-5 pb-5 flex flex-row">
                                        {
                                            this.state.utm_channels.map((channel) => {
                                                return (
                                                    <>
                                                        <div onClick={async () => {
                                                            await this.promisedSetState({
                                                                preview_channel: channel,
                                                                channel_menu: channel === "instagram" ? "facebook" : channel
                                                            });
                                                        }}
                                                            className={(channel === this.state.preview_channel ? ("bg-" + channel + "-500 text-white") : "text-white bg-gray-300 hover:bg-gray-400 cursor-pointer") + " mr-4 py-2 flex flex-row bg-custom-input overflow-hidden rounded-md items-center"}>

                                                            {
                                                                <span
                                                                    className={`px-4 pl-2 text-sm font-medium w-24 text-center capitalize ${channel === "snapchat" && "text-black"}`}>
                                                                    {channel}
                                                                </span>
                                                            }
                                                        </div>
                                                        {
                                                            channel === 'facebook' ? <div onClick={async () => {
                                                                await this.promisedSetState({
                                                                    preview_channel: "instagram"
                                                                });

                                                            }}
                                                                className={(this.state.preview_channel === "instagram" ? ("bg-instagram-500 text-white") : "text-white bg-gray-300 hover:bg-gray-400 cursor-pointer") + " mr-4 py-2 flex flex-row bg-custom-input overflow-hidden rounded-md items-center"}>

                                                                {
                                                                    <span
                                                                        className={"px-4 pl-2 text-sm font-medium w-24 text-center capitalize"}>
                                                                        Instagram
                                                                    </span>
                                                                }
                                                            </div> : null
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !this.state.ai_suggetion &&
                                <div className="col-span-6 grid grid-cols-6 gap-4">
                                    <div className="col-span-3 pl-5">
                                        <DropdownTailwind
                                            channel={this.state.preview_channel}
                                            label={"Preview"}
                                            selected={this.state.preview[this.state.preview_channel]}
                                            options={Array.isArray(this.state.previews[this.state.preview_channel]) ? this.state.previews[this.state.preview_channel].filter((preview) => {
                                                if (preview.ratio === "1.91:1") {
                                                    return this.state.merge && this.state.merge.files.filter((file) => {
                                                        return file.ratio === "1.91:1"
                                                    }).length > 0
                                                } else if (preview.ratio === "1:1") {
                                                    return this.state.merge && this.state.merge.files.filter((file) => {
                                                        return file.ratio === "1:1"
                                                    }).length > 0
                                                } else if (preview.ratio === "9:16") {
                                                    return this.state.merge && this.state.merge.files.filter((file) => {
                                                        return file.ratio === "9:16"
                                                    }).length > 0
                                                } else {
                                                    return true;
                                                }
                                            }) : []}
                                            onChange={(value) => {
                                                this.state.preview[this.state.preview_channel] = value;
                                                this.setState({
                                                    preview: this.state.preview
                                                })
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.preview_channel === "tiktok" && this.state.files.filter((item) => { return item.type === "image" }).length > 0 &&
                                        <div className="col-span-3 p-3 mr-5 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                            OBS! Image format is not supported.
                                        </div>
                                    }
                                    {/* {
                                        this.state.preview_channel === "snapchat" && this.state.files.filter((item) => { return item.type === "image" }).length > 0 &&
                                        <div className="col-span-3 p-3 mr-5 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                            OBS! Image format is not supported.
                                        </div>
                                    } /*}
                                    <div className="col-span-6 pr-5 pl-5 pb-5 flex flex-row overflow-x-auto">
                                        {
                                            this.state.merge &&
                                            <div className="mr-4">
                                                <PreviewSectionFacebook
                                                    ad={{
                                                        headlines: this.state.merge.headlines,
                                                        bodies: this.state.merge.bodies,
                                                        descriptions: this.state.merge.descriptions,
                                                        caption: this.state.caption,
                                                        links: this.state.merge.links,
                                                        call_to_actions: this.state.merge.call_to_actions,
                                                        page: this.state.preview_channel === "instagram" ? (this.state.page.instagram.id !== 0 ? this.state.page.instagram : { id: 0, name: "Select page" }) : (this.state.page.facebook.id !== 0 ? this.state.page.facebook : { id: 0, name: "Select page" }),
                                                        ad_type: { id: 1, name: 'Merge ad', value: "merge_ad" },
                                                        preview: this.state.merge.image ? { value: "image_" + this.state.preview[this.state.preview_channel].value, ratio: this.state.preview[this.state.preview_channel].ratio } : { value: "video_" + this.state.preview[this.state.preview_channel].value, ratio: this.state.preview[this.state.preview_channel].ratio },
                                                        slides: this.state.merge.files.map((inner_file) => {
                                                            let slide = {};
                                                            slide = inner_file;
                                                            slide.image = (inner_file.type === "image");
                                                            slide.video = (inner_file.type === "video");
                                                            if (inner_file.type === "image") {
                                                                slide.picture = inner_file.url;
                                                            }
                                                            if (inner_file.type === "video") {
                                                                //slide.video_data = inner_file;
                                                            }
                                                            return slide;
                                                        }),
                                                    }}
                                                    channel={{ name: this.state.preview_channel, value: this.state.preview_channel }}
                                                />
                                            </div>
                                        }

                                        {
                                            !this.state.merge &&
                                            this.state.files.map((item) => {
                                                let ad = {
                                                    picture: item.url,
                                                    video: item.url,
                                                    colors: item.colors ? item.colors : [],
                                                    title: (this.state.preview_channel === "instagram" ? this.state.headline_by_channels.facebook : this.state.headline_by_channels[this.state.preview_channel]),
                                                    // title: (this.state.preview_channel === "instagram" ? this.state.headline_by_channels.facebook : this.state.preview_channel === "linkedin" && item.type === "posts" ? item.title : this.state.headline_by_channels[this.state.preview_channel]),
                                                    body: item.type === "posts" ? item.body : (this.state.preview_channel === "instagram" ? this.state.body_by_channels.facebook : this.state.body_by_channels[this.state.preview_channel]),
                                                    description: this.state.description,
                                                    caption: this.state.caption,
                                                    link: this.state.website_by_channels[this.state.preview_channel],
                                                    cta: this.state.preview_channel === "instagram" && this.state.cta.facebook && this.state.cta.facebook.id !== 0 ? this.state.cta.facebook : (this.state.cta[this.state.preview_channel] && this.state.cta[this.state.preview_channel].id !== 0 ? this.state.cta[this.state.preview_channel] : { id: 0, name: "No Button", type: "NO_BUTTON" }),
                                                    page: this.state.preview_channel === "instagram" ? (this.state.page.instagram.id !== 0 ? this.state.page.instagram : { id: 0, name: "Select page" }) : (this.state.page[this.state.preview_channel] && this.state.page[this.state.preview_channel].id !== 0 ? this.state.page[this.state.preview_channel] : { id: 0, name: "Select page" }),
                                                    ad_type: (
                                                        (this.state.catalog || this.state.carousel) ?
                                                            { id: 1, name: (this.state.catalog ? "Catalog ad" : "Carousel ad"), value: (this.state.catalog ? "catalog_ad" : "carousel_ad") } :
                                                            (item.type === "image" ?
                                                                { id: 1, name: 'Image ad', value: "image_ad" } :
                                                                (item.type === "video" ?
                                                                    { id: 2, name: 'Video ad', value: "video_ad" } :
                                                                    (item.type === "posts" ?
                                                                        { id: 3, name: 'posts', value: "posts", type: item.ad_type } :
                                                                        { id: 0, name: 'error ad type', value: "error" }
                                                                    )
                                                                )
                                                            )
                                                    ),
                                                    preview: (
                                                        (this.state.catalog || this.state.carousel) ?
                                                            { value: "carousel_feed" } :
                                                            (item.type === "image" ?
                                                                { value: "image_" + this.state.preview[this.state.preview_channel].value } :
                                                                (item.type === "video" ?
                                                                    { value: "video_" + this.state.preview[this.state.preview_channel].value } :
                                                                    (item.type === "posts" && item.files ?
                                                                        { value: "carousel_feed" } :
                                                                        (item.ad_type && item.ad_type === "image" ?
                                                                            { value: "image_feed" } :
                                                                            { value: "video_feed" }
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                    ),
                                                    preview_products: item.preview_products ? item.preview_products : [],
                                                    slides: this.state.carousel ?
                                                        this.state.carousel.files.map((inner_file) => {
                                                            let slide = {};
                                                            slide = inner_file;
                                                            if (this.state.cta && this.state.cta[this.state.preview_channel] && this.state.cta[this.state.preview_channel].id !== 0) {
                                                                slide.cta = this.state.cta[this.state.preview_channel];
                                                            }
                                                            slide.headline = inner_file.title;
                                                            slide.description = inner_file.body;
                                                            slide.image = (inner_file.type === "image");
                                                            slide.video = (inner_file.type === "video");
                                                            if (inner_file.type === "image") {
                                                                slide.picture = inner_file.url;
                                                            }
                                                            if (inner_file.type === "video") {
                                                                //slide.video_data = inner_file;
                                                            }
                                                            return slide;
                                                        }) :
                                                        [],
                                                    files: item.type === "posts" && item.files ? item.files : (this.state.carousel ? this.state.carousel.files : false),
                                                    file: item

                                                };

                                                return (
                                                    <div className="mr-4">
                                                        {

                                                            (this.state.preview_channel === "facebook" || this.state.preview_channel === "instagram") &&
                                                            <PreviewSectionFacebook
                                                                ad={ad}
                                                                channel={{ name: this.state.preview_channel, value: this.state.preview_channel }}
                                                                is_post={ad && ad.ad_type && ad.ad_type.value === "posts" ? true : false}

                                                            />
                                                        }
                                                        {
                                                            this.state.preview_channel === "linkedin" &&
                                                            <PreviewSectionLinkedin
                                                                ad={ad}
                                                                channel={{ name: this.state.preview_channel, value: this.state.preview_channel }}
                                                                is_post={ad.is_post ? ad.is_post : false}
                                                            />
                                                        }
                                                        {
                                                            this.state.preview_channel === "twitter" &&
                                                            <PreviewSectionTwitter
                                                                ad={ad}
                                                                channel={{ name: this.state.preview_channel, value: this.state.preview_channel }}
                                                            />
                                                        }
                                                        {
                                                            ((this.state.preview_channel === "tiktok" && item.type === "video") || (this.state.preview_channel === "tiktok" && item.type === "posts")) &&
                                                            <PreviewSectionTikTok
                                                                ad={{
                                                                    video: item.url,
                                                                    title: this.state.headline_by_channels.tiktok,
                                                                    body: this.state.body_by_channels.tiktok,
                                                                    description: "",
                                                                    caption: "",
                                                                    link: this.state.website_by_channels.tiktok,
                                                                    cta: this.state.cta.tiktok.id !== 0 ? this.state.cta.tiktok : { id: 0, name: "No Button", type: "NO_BUTTON" },
                                                                    page: this.state.page.tiktok.id !== 0 ? this.state.page.tiktok : { id: 0, name: "Select page" },
                                                                    ad_type: { id: 2, name: 'Video ad', value: "video_ad" },
                                                                    preview: { value: "video_" + this.state.preview[this.state.preview_channel].value }
                                                                }}
                                                                channel={{ name: this.state.preview_channel, value: this.state.preview_channel }}
                                                                is_post={ad.is_post ? ad.is_post : false}

                                                            />
                                                        }
                                                        {
                                                            this.state.preview_channel === "snapchat" && item.type === "video" &&
                                                            <>
                                                                <PreviewSectionSnapchat
                                                                    ad={{
                                                                        video: item.url,
                                                                        title: this.state.headline_by_channels.snapchat,
                                                                        body: this.state.body_by_channels.snapchat,
                                                                        description: "",
                                                                        caption: "",
                                                                        link: this.state.website_by_channels.snapchat,
                                                                        cta: this.state.cta.snapchat.id !== 0 ? this.state.cta.snapchat : { id: 0, name: "No Button", type: "NO_BUTTON" },
                                                                        page: this.state.page.snapchat.id !== 0 ? this.state.page.snapchat : { id: 0, name: "Select page" },
                                                                        ad_type: { id: 2, name: 'Video ad', value: "video_ad" },
                                                                        preview: { value: "video_" + this.state.preview[this.state.preview_channel].value },
                                                                        brandname: this.state.snapchat_brandname,
                                                                        attachment: this.state.snapchat_attachment,
                                                                        status: this.state.snapchat_status.value,
                                                                        shareable: this.state.snapchat_shareable.value,
                                                                        autofill: this.state.snapchat_autofill.value,
                                                                        prefetching: this.state.snapchat_prefetching.value,
                                                                        snapchat_phone: this.state.snapchat_phone,
                                                                        snapchat_message: this.state.snapchat_message,
                                                                    }}
                                                                    channel={{ name: this.state.preview_channel, value: this.state.preview_channel }}
                                                                />
                                                            </>
                                                        }
                                                        {
                                                            this.state.preview_channel === "snapchat" && item.type === "image" &&
                                                            <>
                                                                <PreviewSectionSnapchat
                                                                    ad={{
                                                                        picture: item.url,
                                                                        title: this.state.headline_by_channels.snapchat,
                                                                        body: this.state.body_by_channels.snapchat,
                                                                        description: "",
                                                                        caption: "",
                                                                        link: this.state.website_by_channels.snapchat,
                                                                        cta: this.state.cta.snapchat.id !== 0 ? this.state.cta.snapchat : { id: 0, name: "No Button", type: "NO_BUTTON" },
                                                                        page: this.state.page.snapchat.id !== 0 ? this.state.page.snapchat : { id: 0, name: "Select page" },
                                                                        ad_type: { id: 1, name: 'Image ad', value: "image_ad" },
                                                                        preview: { value: "image_" + this.state.preview[this.state.preview_channel].value },
                                                                        brandname: this.state.snapchat_brandname,
                                                                        attachment: this.state.snapchat_attachment,
                                                                        status: this.state.snapchat_status.value,
                                                                        shareable: this.state.snapchat_shareable.value,
                                                                        autofill: this.state.snapchat_autofill.value,
                                                                        prefetching: this.state.snapchat_prefetching.value,
                                                                        snapchat_phone: this.state.snapchat_phone,
                                                                        snapchat_message: this.state.snapchat_message,

                                                                    }}
                                                                    channel={{ name: this.state.preview_channel, value: this.state.preview_channel }}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })

                                        }
                                    </div>
                                </div>
                            }


                            {/* OPEN AI *}
                            {
                                this.state.ai_suggetion &&
                                <div className="grid grid-cols-6 col-span-6 gap-4">
                                    <div className="col-span-6 text-lg font-semibold p-5">
                                        AI Suggestions
                                    </div>
                                    <div className="grid grid-cols-6 gap-4 col-span-6 pl-5 pr-5">
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Choose language"}
                                                selected={this.state.language}
                                                options={
                                                    [
                                                        { id: 1, name: "English" },
                                                        { id: 2, name: "Swedish" },
                                                        { id: 3, name: "Finnish" },
                                                        { id: 4, name: "Danish" },
                                                        { id: 5, name: "Norwegian" },
                                                        { id: 6, name: "French" },
                                                        { id: 7, name: "Dutch" },
                                                        { id: 8, name: "German" },
                                                        { id: 9, name: "Spanish" }

                                                    ]}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        language: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Choose platform"}
                                                selected={this.state.platform}
                                                options={this.state.platforms}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        platform: value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {
                                        false &&
                                        //device-mobile preview icon
                                        //light-bulb AI icon
                                        <div className="col-span-6">
                                            <div className="rounded-md text-sm bg-gray-100 p-4">
                                                <div className="mb-2">
                                                    {this.renders.promt_text()}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="grid grid-cols-6 gap-4 col-span-6 pl-5 pr-5">
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Choose randomness"}
                                                selected={this.state.temperature}
                                                options={[
                                                    { id: 1, name: "Low", value: 0 },
                                                    { id: 2, name: "Mid", value: 0.5 },
                                                    { id: 3, name: "High", value: 1 },
                                                ]}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        temperature: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTailwind
                                                label={"Set max characters"}
                                                value={this.state.amount}
                                                onChange={async (value) => {
                                                    value = value.replace(/(\D+)/g, '')
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        amount: value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-6 pl-5 pr-5">
                                        <TextAreaTailwind
                                            label={"What do you want suggestions for?"}
                                            value={this.state.searchOpenAi}
                                            onChange={(value) => {
                                                this.setState({
                                                    searchOpenAi: value
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className="col-span-6 mb-2 inline-flex pl-5 pr-5">

                                        <div onClick={async () => {
                                            if (this.state.searchOpenAi === "" || ((this.state.searchOpenAi === this.state.OpenAiCompare) && ((this.state.OpenAiResult.length > 0 && !this.state.OpenAiResult[0].error) || this.state.OpenAiResult.length === 0))) {

                                            } else {
                                                await this.promisedSetState({
                                                    OpenAiCompare: this.state.searchOpenAi
                                                })
                                                this.functions.openAi();
                                            }
                                        }} className={"col-span-3 flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden bg-white " + (this.state.searchOpenAi === "" || ((this.state.searchOpenAi === this.state.OpenAiCompare) && ((this.state.OpenAiResult.length > 0 && !this.state.OpenAiResult[0].error) || this.state.OpenAiResult.length === 0)) ? "" : " bg-purple-500 cursor-pointer text-white hover:bg-purple-600")}>
                                            Suggest
                                            {
                                                this.state.loading_suggest &&
                                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                    {
                                        this.state.OpenAiResult.length > 0 &&
                                        <div className="col-span-6 pl-5 pr-5">
                                            <div className="col-span-6 text-lg font-semibold">
                                                AI result
                                            </div>

                                            {
                                                false &&
                                                <div onClick={async () => {
                                                    this.state.OpenAiResult = this.state.OpenAiResult.map((item) => {
                                                        item.hidden = true
                                                        return item;
                                                    })

                                                    await this.promisedSetState({
                                                        OpenAiResult: this.state.OpenAiResult
                                                    })
                                                }} className=" absolute w-80">
                                                    1
                                                </div>

                                            }

                                            {
                                                this.state.OpenAiResult.map((item, index) => (
                                                    <div className={"col-span-6 m-4"}>
                                                        {index == 1 ? <div className="col-span-6 m-2 text-gray-700 text-base">History</div> : ""}
                                                        <div className={"rounded-md text-sm  p-4" + (item.error ? " bg-red-200 text-red-600" : " bg-gray-50")}>
                                                            <div className="mb-2">
                                                                {item.text}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }


                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    </div>
                    */}

                </div>
            </>
        )
    }
}

export default CreateOrderCreativeEdit;
