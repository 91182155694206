import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { ArrowsExpandIcon, BeakerIcon, CameraIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, CollectionIcon, CubeIcon, ExclamationIcon, EyeIcon, FolderIcon, GiftIcon, MinusIcon, NewspaperIcon, PlusIcon, SearchCircleIcon, TrashIcon, VideoCameraIcon, ViewGridIcon, AnnotationIcon, FilmIcon, TemplateIcon, ExternalLinkIcon, ClipboardListIcon, PuzzleIcon, BanIcon, XCircleIcon, SearchIcon } from '@heroicons/react/outline';
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import SwitchTailwind from './switchTailwind';
import ToolTip from './toolTip';
import { useRouteMatch } from 'react-router';
import { searchGoogle } from '../validators/searchGoogle';
import { performanceMax } from '../validators/performanceMax';
import { extensionGoogle } from '../validators/extensionGoogle';
import FacebookCTA from '../assets/json/facebook_cta.json';

class CreateOrderCreativePush extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            show_delete: "",
            search: "",
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            files: this.props.files ? this.props.files : [],
            campaigns: this.props.campaigns ? this.props.campaigns.map((item) => { item.selected = false; return item }) : [],
            adgroups: this.props.adgroups ? this.props.adgroups.map((item) => { item.selected = false; return item }) : [],
            ads: this.props.ads ? this.props.ads : []
        });
        // console.log("STATE FILES", this.state.files)
    }
    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ads: nextProps.ads ? nextProps.ads : [],
            files: nextProps.files ? nextProps.files : [],
        });
    }
    functions = {
        push: async (skip = false) => {
            this.props.onPush(skip, this.state.adgroups, this.state.files, this.state.campaigns);
        },
        select: (value) => {
            this.state.files = this.state.files.map((item) => {
                item.selected = value;
                return item
            });
            this.setState({
                files: this.state.files
            });
        },
        deleteAsset: async (id, campaign = false) => {
            try {
                if (campaign) {
                    this.state.campaigns = this.state.campaigns.map((item) => {
                        if (item.id === campaign.id) {
                            item.asset = item.asset.filter((asset) => asset.id !== id);
                        }
                        return item;
                    });

                    this.props.onRemove(id, campaign);
                } else {
                    this.state.ads = this.state.ads.filter((ad) => ad.id !== id)
                    await this.promisedSetState({
                        ads: this.state.ads
                    });
                    this.props.onRemove(id);
                }
            } catch (error) {
                console.log(error);
            }
        },
    };

    calls = {};

    renders = {
        allowedCreative: (item) => {
            let values = [true];
            /*
            if (this.state.files.filter((item) => { return item.selected }).length > 0) {
                this.state.files.filter((item) => { return item.selected }).map((file) => {
                    if (file.allowed_channels) {
                        for (let channel in file.allowed_channels) {
                            if (item.allowed_channels && item.allowed_channels[channel]) {
                                values.push(true);
                            }
                        }
                    }
                });
            } else if (this.state.campaigns.filter((item) => { return item.selected }).length > 0) {
                this.state.campaigns.filter((item) => { return item.selected }).map((campaign) => {
                    if (item.allowed_channels && item.allowed_channels[campaign.channel]) {
                        values.push(true);
                    }
                });
            } else {
                values.push(true);
            }
            */
            if (item.type === "keywords") {
                if ((item.name === "" || item.keywords.length < 1)) {
                    return false;
                } return true;
            } else if (item.type === "search_ad") {
                if (Object.keys(searchGoogle.validate(item)).filter((inner_item) => { return searchGoogle.validate(item)[inner_item] }).length > 0) {
                    return false;
                } else return true;
            } else if (["performance_max", "display"].includes(item.type)) {
                if (Object.keys(performanceMax.validate(item)).filter((inner_item) => { return performanceMax.validate(item)[inner_item] }).length > 0) {
                    return false;
                } else return true;
            } else if (item.type === "extension") {
                if (Object.keys(extensionGoogle.validate(item)).filter((inner_item) => { return extensionGoogle.validate(item)[inner_item] }).length > 0) {
                    return false;
                } else return true;
            } else {
                return values.filter((item) => { return !item }).length < 1 && values.filter((item) => { return item }).length > 0;
            }
        },
        allowedCampaign: (campaign) => {
            console.log(campaign, "campaign")
            let allow_all = false;
            let values = [];
            let channel = campaign.channel;
            let campaign_type = campaign.campaign_type_property;
            if (this.state.files.filter((item) => { return item.selected }).length > 0) {
                this.state.files.filter((item) => { return item.selected }).map((file) => {
                    if (!file.allowed_channels) {
                        //values.push(true);
                        allow_all = true;
                    } else {
                        if (file.allowed_channels && file.allowed_channels[channel] && file.allowed_types && file.allowed_types[campaign_type]) {
                            values.push(true);
                        } else {
                            values.push(false);
                        }
                    }
                });
            } else {
                values.push(true);
            }
            return allow_all || (values.filter((item) => { return !item }).length < 1 && values.filter((item) => { return item }).length > 0);
        },
        allowedCampaignAsset: (campaign) => {
            let values = [];
            let channel = campaign.channel;
            if (this.state.files.filter((item) => { return item.selected }).length > 0) {
                this.state.files.filter((item) => { return item.selected }).map((file) => {
                    if (file.allowed_channels && file.allowed_channels[campaign.channel] && ((file.type === "keywords" && file.negative && file.negative.id === 2) || file.type === "extension")) {
                        values.push(true);
                    } else {
                        values.push(false);
                    }
                });
            }

            if (this.state.files.filter((item) => { return item.selected && item.type === "keywords" }).length > 0 && campaign.partial_parced) {
                values.push(false);
            }

            if (this.state.adgroups.filter((item) => { return item.selected && item.campaign === campaign.id }).length > 0) {
                values.push(false);
            }

            if (campaign.partial_parced && this.state.files.filter((item) => { return item.selected && item.type === "extension" && ["callout", "sitelink", "call"].includes(item.subtype.value) }).length > 0) {
                //values.push(false);
            }

            return values.filter((item) => { return !item }).length < 1 && values.filter((item) => { return item }).length > 0;
        },
        allowedAgroup: (adgroup) => {
            let values = [];
            if (adgroup.channel === "linkedin" || adgroup.channel === "tiktok" || adgroup.channel === "snapchat") {
                if (this.state.files.filter((item) => { return item.selected }).length > 0) {
                    if (adgroup.channel === "tiktok") {
                        // if (this.state.files.filter((item) => { return item.selected && item.type !== "video" }).length > 0) {
                        if (this.state.files.filter((item) => { return item.selected && (item.type !== "video" && (item.type !== "posts" || item.ad_type !== "video")) }).length > 0) {
                            values.push(false);
                        } else {
                            if (adgroup.campaign_objective && adgroup.campaign_objective.value === "APP_PROMOTION") {
                                if (this.state.files.filter((item) => { return item.selected && (!item.tiktok_destination || (item.tiktok_destination && item.tiktok_destination.value === "website")) }).length > 0) {
                                    values.push(false);
                                } else {
                                    values.push(true);
                                }
                            } else {
                                if (this.state.files.filter((item) => { return item.selected && ((item.tiktok_destination && item.tiktok_destination.value === "app")) }).length > 0) {
                                    values.push(false);
                                } else {
                                    values.push(true);
                                }
                            }
                            //values.push(true);
                        }
                    } else if (adgroup.channel === "linkedin") {
                        if (adgroup.adgroup_type && adgroup.adgroup_type.value === "STANDARD_UPDATE" && this.state.files.filter((item) => { return item.selected && (item.type !== "image" && (item.type !== "posts" || item.ad_type !== "image")) }).length > 0) {
                            values.push(false);
                        } else if (adgroup.adgroup_type && adgroup.adgroup_type.value === "CAROUSEL" && this.state.files.filter((item) => { return item.selected && (item.type !== "carousel" && (item.type !== "posts" || item.ad_type !== "carousel")) }).length > 0) {
                            values.push(false);
                        } else if (adgroup.adgroup_type && adgroup.adgroup_type.value === "SINGLE_VIDEO" && this.state.files.filter((item) => { return item.selected && (item.type !== "video" && (item.type !== "posts" || item.ad_type !== "video")) }).length > 0) {
                            values.push(false);
                        } else {
                            values.push(true);
                        }
                    } else {
                        if (adgroup.optimization_goal && adgroup.optimization_goal.value) {
                            if (!["VIDEO_VIEWS_15_SEC", "IMPRESSIONS", "SWIPES", "VIDEO_VIEWS", "PIXEL_PAGE_VIEW", "PIXEL_ADD_TO_CART", "PIXEL_PURCHASE", "PIXEL_SIGNUP", "APP_INSTALLS", "APP_SIGNUP"].includes(adgroup.optimization_goal.value)) {
                                values.push(false);
                            } else {
                                if (adgroup.campaign_objective && adgroup.campaign_objective.value === "APP_PROMOTION") {
                                    if (this.state.files.filter((item) => { return item.selected && (item.snapchat_attachment && !["APP_INSTALL", "DEEP_LINK"].includes(item.snapchat_attachment.value))}).length) {
                                        values.push(false);
                                    } else {
                                        values.push(true);
                                    }
                                } else {
                                    if (this.state.files.filter((item) => { return item.selected && (item.snapchat_attachment && !["WEB_VIEW"].includes(item.snapchat_attachment.value))}).length) {
                                        values.push(false);
                                    } else {
                                        values.push(true);
                                    }
                                }
                            }
                        } else {
                            if (!["VIDEO_VIEWS_15_SEC", "IMPRESSIONS", "SWIPES", "VIDEO_VIEWS", "PIXEL_PAGE_VIEW", "PIXEL_ADD_TO_CART", "PIXEL_PURCHASE", "PIXEL_SIGNUP", "APP_INSTALLS", "APP_SIGNUP"].includes(adgroup.optimization_goal)) {
                                values.push(false);
                            } else values.push(true);
                        }
                    }
                } else {
                    values.push(true);
                }
            } else if (adgroup.channel === "facebook") {
                if (this.state.files.filter((item) => { return item.selected && item.type === "posts" && !item.instagram_post }).length > 0) {
                    if (adgroup.campaign_objective) {
                        if (adgroup.campaign_objective.value === "OUTCOME_ENGAGEMENT" || adgroup.campaign_objective.value === "OUTCOME_AWARENESS") {
                            values.push(true);
                        } else {
                            values.push(false);
                        }
                    } else {
                        values.push(true);
                    }
                } else {
                    if (adgroup.channel === "facebook" && adgroup.destination_type && adgroup.destination_type.id === 5) {
                        if (this.state.files.filter((item) => { return item.selected }).length > 0) {
                            if (this.state.files.filter((item) => { return item.selected && ((item.selected_lead && item.selected_lead.id === 0) || item.instantExperience) }).length > 0) {
                                values.push(false);
                            } else {
                                values.push(true);
                            }
                        } else {
                            values.push(true);
                        }
                    } else {
                        if (this.state.files.filter((item) => { return item.selected }).length > 0) {
                            if (this.state.files.filter((item) => { return item.selected && item.selected_lead && item.selected_lead.id !== 0 }).length > 0) {
                                values.push(false);
                            } else if (this.state.files.filter((item) => { return item.selected && item.instantExperience }).length > 0) {
                                if (this.state.files.filter((item) => { return item.selected && item.instantExperience && item.instantExperience.id === 0 }).length > 0) {
                                    values.push(false);
                                } else {
                                    if (adgroup.campaign_objective.value === "OUTCOME_TRAFFIC" || adgroup.campaign_objective.value === "OUTCOME_AWARENESS" || adgroup.campaign_objective.value === "OUTCOME_SALES") {
                                        values.push(true);
                                    } else {
                                        values.push(false);
                                    }
                                }
                            } else {
                                values.push(true);
                            }
                        } else {
                            values.push(true);
                        }
                        //values.push(true);
                    }
                }

            } else if (adgroup.channel === "google") {
                if (this.state.files.filter((item) => { return item.selected && item.type !== "search_ad" && item.type !== "extension" }).length > 0 && adgroup.resourceName) {
                    values.push(false);
                } else {
                    if (this.state.files.filter((item) => { return item.selected && item.adgroup_not_allowed }).length > 0) {
                        values.push(false);
                    } else {
                        values.push(true);
                    }
                }
            } else {
                values.push(true);
            }

            if (this.state.campaigns.filter((item) => { return item.selected && item.id === adgroup.campaign }).length > 0) {
                values.push(false);
            }
            return values.filter((item) => { return !item }).length < 1 && values.filter((item) => { return item }).length > 0;
        },
        preview: (item) => {
            if ((item.ad_type && (item.ad_type === "collection_ad" || (item.ad_type.value === "catalog_ad" && item.collection_ad)))) {
                if (item.instantExperience && item.instantExperience.id !== 0 && item.instantExperience.elements) {
                    return (
                        <div className="h-10 w-10 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden border-1.5">
                            {
                                item.instantExperience.elements.carousel &&
                                item.instantExperience.elements.carousel.map((item) => {
                                    return (
                                        <div className="w-full h-full overflow-hidden">
                                            <div className="w-full h-full relative">
                                                <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.image_url + ') center center / contain no-repeat' }}>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                item.instantExperience.elements.product_set &&
                                item.instantExperience.elements.product_set.map((item) => {
                                    return (
                                        <div className="w-full h-full overflow-hidden">
                                            <div className="w-full h-full relative">
                                                <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.image_url + ') center center / contain no-repeat' }}>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                } else {
                    return (
                        <div className="h-10 w-10 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden border-1.5">
                            <div className="w-full h-full overflow-hidden">
                                <div className="w-full h-full relative justify-center items-center">
                                    <BanIcon className="m-auto h-5 w-5 text-gray-400" />
                                </div>
                            </div>
                            <div className="w-full h-full overflow-hidden">
                                <div className="w-full h-full relative justify-center items-center">
                                    <BanIcon className="m-auto h-5 w-5 text-gray-400" />
                                </div>
                            </div>
                            <div className="w-full h-full overflow-hidden">
                                <div className="w-full h-full relative justify-center items-center">
                                    <BanIcon className="m-auto h-5 w-5 text-gray-400" />
                                </div>
                            </div>
                            <div className="w-full h-full overflow-hidden">
                                <div className="w-full h-full relative justify-center items-center">
                                    <BanIcon className="m-auto h-5 w-5 text-gray-400" />
                                </div>
                            </div>
                        </div>
                    )
                }
            } else if (item.type === "youtube") {
                return (
                    <div className="h-10 w-10 rounded-md relative overflow-hidden">
                        <iframe src={"https://www.youtube.com/embed/" + item.youtube_id} frameborder={"0"} styles={{ "overflow": "hidden", "height": "100%", "width": "100%" }} height={"100%"} width={"100%"}></iframe>
                    </div>
                )
            } else if (item.type === "performance_max") {
                return (
                    <div className="h-10 w-10 rounded-md bg-purple-100 flex items-center justify-center">
                        <TemplateIcon
                            className="h-5 w-5 text-purple-500"
                        />
                    </div>
                )
            } else if (item.type === "display") {
                return (
                    <div className="h-10 w-10 rounded-md bg-purple-100 flex items-center justify-center">
                        <TemplateIcon
                            className="h-5 w-5 text-purple-500"
                        />
                    </div>
                )
            } else if (item.type === "keywords") {
                return (
                    <div className="h-10 w-10 rounded-md  bg-purple-100 flex items-center justify-center">
                        <SearchCircleIcon
                            className="h-5 w-5 text-purple-500"
                        />
                    </div>
                )
            } else if (item.type === "search_ad") {
                return (
                    <div className="h-10 w-10 rounded-md  bg-purple-100 flex items-center justify-center">
                        <NewspaperIcon
                            className="h-5 w-5 text-purple-500"
                        />
                    </div>
                )
            } else if (item.type === "extension") {
                return (
                    <div className="h-10 w-10 rounded-md  bg-purple-100 flex items-center justify-center">
                        <ExternalLinkIcon
                            className="h-5 w-5 text-purple-500"
                        />
                    </div>
                )
            } else if (item.type === "shopping") {
                return (
                    <div className="h-10 w-10 rounded-md  bg-purple-100 flex items-center justify-center">
                        <GiftIcon
                            className="h-5 w-5 text-purple-500"
                        />
                    </div>
                )
            } else if (((item.type === "image" && (!item.ad_type || item.ad_type !== "collection_ad" || item.ad_type.value !== "catalog_ad")) || (item.type === "posts" && item.ad_type === "image" && !item.files))) {
                if (item.file) {
                    item = item.file;
                }
                return (
                    <div className="h-10 w-10 rounded-md relative  overflow-hidden">
                        <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}></div>
                    </div>
                )
            } else if ((item.type === "video" || (item.type === "posts" && item.ad_type === "video" && !item.files))) {
                if (item.file) {
                    item = item.file;
                }
                return (
                    <video poster={item.thumbnail} autoPlay={false} muted={true} controls={false} className="h-10 w-10 overflow-hidden rounded-md" >
                        <source src={item.url} type="video/mp4" />
                    </video>
                )
            } else if ((item.type === "carousel" || item.type === "merge" || (item.type === "posts" && item.files))) {
                if (item.type === "carousel" && item.slides) {
                    item.files = item.slides;
                }
                return (
                    <div className="h-10 w-10 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden border-1.5">
                        {
                            item.files ?
                                item.files.filter((item, index) => { return index < 4 }).map((item) => {
                                    return (
                                        <div className="w-full h-full overflow-hidden">
                                            {
                                                (item.type === "image" || item.type === "IMAGE") &&
                                                <div className="w-full h-full relative">
                                                    <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                    </div>
                                                </div>
                                            }
                                            {
                                                (item.type === "video" || item.type === "VIDEO") &&
                                                <video autoPlay={false} muted={true} controls={false} className={"w-full h-full"}>
                                                    <source src={item.url} type="video/mp4" />
                                                </video>
                                            }
                                        </div>
                                    )
                                }) : item.type === "merge" && item.slides ?
                                    item.slides.filter((item, index) => { return index < 4 }).map((item) => {
                                        return (
                                            <div className="w-full h-full overflow-hidden">
                                                {
                                                    (item.type === "image" || item.type === "IMAGE") &&
                                                    <div className="w-full h-full relative">
                                                        <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (item.type === "video" || item.type === "VIDEO") &&
                                                    <video autoPlay={false} muted={true} controls={false} className={"w-full h-full"}>
                                                        <source src={item.url} type="video/mp4" />
                                                    </video>
                                                }
                                            </div>
                                        )
                                    })
                                    : <></>

                        }
                    </div>
                )
            } else if (Array.isArray(item.preview_products) && item.type === "catalog") {
                return (
                    <div className="h-10 w-10 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden border-1.5" >
                        {
                            item.preview_products.filter((item, index) => { return index < 4 }).map((item) => {
                                return (
                                    <div className="w-full h-full overflow-hidden">
                                        <img className={"w-full h-full"} src={item.image_url} alt="" />
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            } else {
                return (<></>)
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    wait = (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-12 pb-4 h-full">

                    <div className="col-span-6 border-r-1.5 pr-6 h-full">
                        <div className="flex flex-col h-full">
                            <div className="mb-4">
                                <div className="text-sm font-medium text-gray-900">
                                    Assets
                                </div>
                                {
                                    <div id="asset_seach" className='w-120 flex transition-all duration-700 rounded-md mr-2 relative'>
                                        <input
                                            onBlur={() => {
                                                document.getElementById('asset_seach').classList.remove('w-120');
                                                document.getElementById('asset_seach').classList.add('w-24');
                                            }}
                                            onFocus={() => {
                                                document.getElementById('asset_seach').classList.remove('w-24');
                                                document.getElementById('asset_seach').classList.add('w-120');
                                            }}

                                            className={" block w-full bg-custom-input rounded-md border-1.5 px-2 pr-8 py-2 focus:outline-none sm:text-sm"}
                                            placeholder={"Search"}
                                            value={this.state.search}
                                            onChange={async (event) => {
                                                await this.promisedSetState({
                                                    search: event.target.value
                                                })

                                            }}
                                        />
                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="flex flex-1 flex-col overflow-scroll">
                                {
                                    this.state.files.filter((item) => {

                                        let allowed = false;

                                        //ONLY SHOW CREATIVES SUPPORTED BY AVAILABLE CHANNELS
                                        this.state.campaigns.map((campaign) => {
                                            if (!item.allowed_channels) {
                                                allowed = true;
                                            } else {
                                                if (item.allowed_channels && item.allowed_channels[campaign.channel]) {
                                                    allowed = true;
                                                }
                                            }
                                        });

                                        return allowed;

                                    }).filter((item) => {
                                        if (this.state.search === "") {
                                            return true;
                                        } else {
                                            if (item.name_by_channels && item.name_by_channels[Object.keys(item.allowed_channels).filter((channel) => { return item.allowed_channels[channel] })[0]] !== "") {
                                                return item.name_by_channels[Object.keys(item.allowed_channels).filter((channel) => { return item.allowed_channels[channel] })[0]].toLowerCase().includes(this.state.search.toLowerCase());
                                            } else {
                                                return item.name.toLowerCase().includes(this.state.search.toLowerCase());
                                            }
                                        }
                                    }).map((item) => (
                                        <div
                                            onClick={async () => {
                                                if (this.renders.allowedCreative(item)) {
                                                    let open_campaigns = false;
                                                    if (this.state.files.filter((item) => { return item.selected }).length < 1) {
                                                        open_campaigns = true;
                                                    }
                                                    item.selected = !item.selected;
                                                    await this.promisedSetState({
                                                        files: this.state.files
                                                    });
                                                    if (open_campaigns) {
                                                        this.state.campaigns = this.state.campaigns.map((campaign) => {
                                                            if (this.renders.allowedCampaign(campaign)) {
                                                                campaign.open = true;
                                                            }
                                                            return campaign;
                                                        });
                                                    }
                                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                                        item.selected = false;
                                                        return item
                                                    });

                                                    this.state.campaigns = this.state.campaigns.map((item) => {
                                                        item.selected = false;
                                                        return item
                                                    });
                                                    await this.promisedSetState({
                                                        campaigns: this.state.campaigns,
                                                        adgroups: this.state.adgroups
                                                    });
                                                }
                                            }}
                                            key={item.id}
                                            className={(this.renders.allowedCreative(item) ? "" : "opacity-25 cursor-not-allowed") + " flex flex-row cursor-pointer mb-2 justify-center items-center"}
                                        >
                                            <div className="flex flex-1 flex-row items-center overflow-hidden">
                                                <div>
                                                    {
                                                        this.renders.preview(item)
                                                    }
                                                </div>
                                                <div className="ml-3 truncate pr-10 flex-1">
                                                    {
                                                        item.name_by_channels &&
                                                        <p className={(item.selected ? "" : "text-gray-900") + " text-xs font-medium truncate"}>{item.name_by_channels && item.name_by_channels[Object.keys(item.allowed_channels).filter((channel) => { return item.allowed_channels[channel] })[0]] !== "" ? item.name_by_channels[Object.keys(item.allowed_channels).filter((channel) => { return item.allowed_channels[channel] })[0]] : item.name}</p>
                                                    }
                                                    {
                                                        !item.name_by_channels &&
                                                        <p className={(item.selected ? "" : "text-gray-900") + " text-xs font-medium truncate"}>{item.name}</p>
                                                    }
                                                    <div className="flex flex-row items-center mr-1">
                                                        <p className={"text-xs text-gray-500 flex-row inline-flex items-center justify-center mr-2"}>
                                                            {item.type === "image" && <CameraIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "video" && <VideoCameraIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "carousel" && <CollectionIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "catalog" && <GiftIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "keywords" && <SearchCircleIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "search_ad" && <NewspaperIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "posts" && <AnnotationIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "youtube" && <FilmIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "shopping" && <GiftIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "merge" && <TemplateIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "extension" && <ExternalLinkIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "performance_max" && <TemplateIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "display" && <TemplateIcon className="h-4 w-4 mr-1" />}
                                                            {item.type === "extension" && item.subtype.name}
                                                            {item.type !== "extension" && item.type}
                                                        </p>
                                                        {
                                                            item.selected_lead && item.selected_lead.id !== 0 &&
                                                            <p className='text-xs text-gray-500 flex-row inline-flex items-center justify-center mr-2'>
                                                                <ClipboardListIcon className="h-4 w-4 mr-1" />Lead form
                                                            </p>
                                                        }
                                                        {
                                                            item.instantExperience &&
                                                            <p className='text-xs text-gray-500 flex-row inline-flex items-center justify-center mr-2'>
                                                                <PuzzleIcon className="h-4 w-4 mr-1" />Collection Ad
                                                            </p>
                                                        }
                                                        {
                                                            item.allowed_channels &&
                                                            Object.keys(item.allowed_channels).filter((channel) => { return item.allowed_channels[channel] }).map((channel) => {
                                                                return (
                                                                    <div className={"bg-" + (channel + "-500") + " -ml-1 h-4 w-4 rounded-full flex justify-center items-center border border-white"}>
                                                                        {
                                                                            channel === 'google' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/google_icon.png')} />
                                                                        }
                                                                        {
                                                                            channel === 'facebook' &&
                                                                            <img className="w-1"
                                                                                alt=""
                                                                                src={require('../assets/images/facebook_icon.svg')} />
                                                                        }
                                                                        {
                                                                            channel === 'linkedin' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/linkedin_icon.svg')} />
                                                                        }
                                                                        {
                                                                            channel === 'twitter' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/twitter_icon.svg')} />
                                                                        }
                                                                        {
                                                                            channel === 'tiktok' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/tiktok_icon.png')} />
                                                                        }
                                                                        {
                                                                            channel === 'snapchat' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/snapchat.svg')} />
                                                                        }
                                                                        {
                                                                            channel === 'instagram' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/instagram_icon.svg')} />
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    item.type === "search_ad" &&
                                                    Object.keys(searchGoogle.validate(item)).filter((inner_item) => { return searchGoogle.validate(item)[inner_item] }).length > 0 &&
                                                    <div className='text-red-600'>
                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mx-2" />
                                                    </div>
                                                }
                                                {
                                                    item.type === "keywords" &&
                                                    (item.name === "" || item.keywords.length < 1) &&
                                                    <div className='text-red-600'>
                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mx-2" />
                                                    </div>
                                                }
                                                {
                                                    ["performance_max", "display"].includes(item.type) &&
                                                    Object.keys(performanceMax.validate(item)).filter((inner_item) => { return performanceMax.validate(item)[inner_item] }).length > 0 &&
                                                    <div className='text-red-600'>
                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mx-2" />
                                                    </div>
                                                }
                                                {
                                                    item.type === "extension" &&
                                                    Object.keys(extensionGoogle.validate(item)).filter((inner_item) => { return extensionGoogle.validate(item)[inner_item] }).length > 0 &&
                                                    <div className='text-red-600'>
                                                        <ExclamationIcon className="h-5 w-5 text-red-600 mx-2" />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                <div className={(item.selected ? "bg-purple-500" : "bg-gray-100") + " border-1.5 h-10 w-10  ml-2 flex justify-center items-center rounded-md"}>
                                                    {
                                                        item.selected &&
                                                        <CheckIcon className="h-5 w-5 text-white" />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-span-6 pl-6 h-full">
                        <div className="flex flex-col">
                            <div className="mb-4">
                                <div className="text-sm font-medium text-gray-900">
                                    Campaign structure
                                </div>
                            </div>
                            <div className={"flex flex-col flex-1"}>
                                {
                                    this.state.campaigns.map((campaign, index) => {
                                        return (
                                            <Fragment>
                                                <div className={(index > 0 ? "border-t pt-2" : "") + (this.renders.allowedCampaign(campaign) ? "" : " opacity-25 cursor-not-allowed") + " flex flex-row mb-2 items-center"}>
                                                    <div className="flex flex-1 flex-row items-center overflow-hidden">
                                                        {
                                                            this.state.files.filter((item) => { return item.selected }).length > 0 && campaign.channel === "google" &&
                                                            <div onClick={() => {
                                                                if (this.renders.allowedCampaignAsset(campaign)) {
                                                                    campaign.selected = !campaign.selected;
                                                                    this.setState({
                                                                        campaigns: this.state.campaigns
                                                                    });
                                                                }

                                                            }} className={(campaign.selected ? "bg-purple-500" : "bg-gray-100") + " border-1.5 h-10 w-10 mr-2 flex justify-center items-center rounded-md" + (this.renders.allowedCampaignAsset(campaign) ? " cursor-pointer" : " opacity-25 cursor-not-allowed")}>
                                                                {
                                                                    campaign.selected &&
                                                                    <CheckIcon className="h-5 w-5 text-white" />
                                                                }
                                                            </div>
                                                        }
                                                        <div className={"bg-" + campaign.channel + "-500 rounded-md h-10 w-10 flex justify-center items-center"}>
                                                            {
                                                                campaign.channel === "facebook" &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                            }
                                                            {
                                                                campaign.channel === "twitter" &&
                                                                <img className="w-5"
                                                                    alt=""
                                                                    src={require('../assets/images/twitter_icon.svg')} />
                                                            }
                                                            {
                                                                campaign.channel === "tiktok" &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/tiktok_icon.png')} />
                                                            }
                                                            {
                                                                campaign.channel === "snapchat" &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/snapchat.svg')} />
                                                            }
                                                            {
                                                                campaign.channel === "linkedin" &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                                            }
                                                            {
                                                                campaign.channel === "google" &&
                                                                <img className="w-4"
                                                                    alt=""
                                                                    src={require('../assets/images/google_icon.png')} />
                                                            }
                                                        </div>
                                                        <div className="h-10 w-10 hidden rounded-md ml-2 flex bg-gray-100 justify-center items-center">
                                                            <FolderIcon className="h-5 w-5 " />
                                                        </div>
                                                        <div className="ml-3 truncate">
                                                            <p className="text-xs font-medium text-gray-900">{campaign.name}</p>
                                                            <p className={"text-xs text-gray-500 flex-row inline-flex items-center justify-center"}>
                                                                <div>
                                                                    {campaign.campaign_type}
                                                                    {/*campaign.partial_parced && this.state.files.filter((item) => { return item.selected && item.type === "extension" && ["callout", "sitelink", "call"].includes(item.subtype.value) }).length > 0 ? <p className='text-orange-500 text-xxs'>Callout, Sitelink and Call extensions are not possible add</p> : ""*/}
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => {
                                                        if (this.renders.allowedCampaign(campaign)) {
                                                            campaign.open = !campaign.open;
                                                            this.promisedSetState({
                                                                campaigns: this.state.campaigns
                                                            })
                                                        }
                                                    }} className="h-10 w-10 cursor-pointer bg-gray-100 border-1.5 flex justify-center items-center rounded-md">
                                                        {
                                                            campaign.open &&
                                                            <ChevronUpIcon className="h-5 w-5" />
                                                        }
                                                        {
                                                            !campaign.open &&
                                                            <ChevronDownIcon className="h-5 w-5" />
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    campaign.asset && campaign.asset.length > 0 && campaign.open &&
                                                    <>
                                                        <div className='flex flex-row text-xs mb-2'>
                                                            <div className="h-0 w-10 mr-2 opacity-0">
                                                            </div>
                                                            <p className="text-xs font-medium text-gray-900">
                                                                Campaign assets
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-row mb-2">
                                                            <div className="h-10 w-10 mr-2 opacity-0">
                                                            </div>
                                                            {
                                                                campaign.asset.map((item) => {
                                                                    return (
                                                                        <Fragment>
                                                                            <div className="mr-2 relative cursor-pointer">
                                                                                <div
                                                                                    className="relative"
                                                                                    onMouseEnter={() => {
                                                                                        this.promisedSetState({ show_delete: item.id });
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        this.promisedSetState({ show_delete: "" });
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.functions.deleteAsset(item.id, campaign);
                                                                                    }}
                                                                                >
                                                                                    {this.renders.preview(item)}
                                                                                    {this.state.show_delete === item.id && (
                                                                                        <div className="absolute inset-0 flex justify-center items-center bg-black rounded-md bg-opacity-25">
                                                                                            <div className="relative">
                                                                                                <TrashIcon className="h-6 w-6 text-red-500" />
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>

                                                }
                                                {
                                                    //this.state.files.filter((item) => { return item.selected }).length > 0 &&
                                                    this.renders.allowedCampaign(campaign) &&
                                                    campaign.open &&
                                                    this.state.adgroups.filter((adgroup) => {
                                                        return adgroup.campaign === campaign.id;
                                                    }).map((adgroup) => {
                                                        return (
                                                            <Fragment>
                                                                <div>
                                                                    <div className={(this.renders.allowedAgroup(adgroup) ? "" : "opacity-25 cursor-not-allowed") + " flex flex-row mb-2 items-center"}>
                                                                        <div className="flex flex-1 flex-row items-center overflow-hidden">
                                                                            <div className="h-10 w-10 mr-2 opacity-0">
                                                                            </div>
                                                                            <div className="h-10 w-10 hidden mr-2 opacity-0">
                                                                            </div>
                                                                            {
                                                                                this.state.files.filter((item) => { return item.selected }).length > 0 &&
                                                                                <div onClick={() => {
                                                                                    if (this.renders.allowedAgroup(adgroup)) {
                                                                                        adgroup.selected = !adgroup.selected;
                                                                                        this.setState({
                                                                                            adgroups: this.state.adgroups
                                                                                        });
                                                                                    }
                                                                                }} className={(adgroup.selected ? "bg-purple-500" : "bg-gray-100") + " border-1.5 cursor-pointer h-10 w-10 mr-2 flex justify-center items-center rounded-md"}>
                                                                                    {
                                                                                        adgroup.selected &&
                                                                                        <CheckIcon className="h-5 w-5 text-white" />
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className="h-10 w-10 rounded-md flex bg-gray-100 justify-center items-center">
                                                                                {
                                                                                    adgroup.channel !== "linkedin" && adgroup.channel !== "tiktok" &&
                                                                                    <ViewGridIcon className="h-5 w-5 " />
                                                                                }
                                                                                {
                                                                                    adgroup.channel == "linkedin" && adgroup.adgroup_type && adgroup.adgroup_type.value === "STANDARD_UPDATE" &&
                                                                                    <CameraIcon className="h-5 w-5 " />
                                                                                }
                                                                                {
                                                                                    (adgroup.channel == "tiktok" || (adgroup.channel == "linkedin" && adgroup.adgroup_type && adgroup.adgroup_type.value === "SINGLE_VIDEO")) &&
                                                                                    <VideoCameraIcon className="h-5 w-5 " />
                                                                                }
                                                                                {
                                                                                    adgroup.channel == "linkedin" && adgroup.adgroup_type && adgroup.adgroup_type.value === "CAROUSEL" &&
                                                                                    <CollectionIcon className="h-5 w-5 " />
                                                                                }
                                                                            </div>
                                                                            <div className="ml-3 truncate">
                                                                                <p className="text-xs font-medium text-gray-900">
                                                                                    {adgroup.name}{" " + (adgroup.channel === "google" && adgroup.resourceName && this.state.files.filter((item) => { return item.selected && item.type !== "search_ad" }).length > 0 ? "(Only search ad are possible add to existing adgroup)" : "")}
                                                                                    {
                                                                                        adgroup.channel == "tiktok" &&
                                                                                        <span className="text-purple-500">
                                                                                            OBS! Video only
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        adgroup.channel == "linkedin" && adgroup.adgroup_type && adgroup.adgroup_type.value === "STANDARD_UPDATE" &&
                                                                                        <span className="text-purple-500">
                                                                                            OBS! Image only
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        adgroup.channel == "linkedin" && adgroup.adgroup_type && adgroup.adgroup_type.value === "SINGLE_VIDEO" &&
                                                                                        <span className="text-purple-500">
                                                                                            OBS! Video only
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        adgroup.channel == "linkedin" && adgroup.adgroup_type && adgroup.adgroup_type.value === "CAROUSEL" &&
                                                                                        <span className="text-purple-500">
                                                                                            OBS! Carousel only
                                                                                        </span>
                                                                                    }
                                                                                </p>
                                                                                <p className="text-xs text-gray-500 flex-row inline-flex items-center justify-center">
                                                                                    {
                                                                                        this.state.ads.filter((ad) => {
                                                                                            return ad.adgroup === adgroup.id;
                                                                                        }).length
                                                                                    } Ads
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        campaign.open &&
                                                                        this.state.ads.filter((ad) => {
                                                                            return ad.adgroup === adgroup.id;
                                                                        }).length > 0 &&
                                                                        <div className="flex flex-row mb-2">
                                                                            <div className="h-10 w-10 mr-2 opacity-0">
                                                                            </div>
                                                                            <div className="h-10 w-10 mr-2 opacity-0">
                                                                            </div>
                                                                            {
                                                                                this.state.files.filter((item) => { return item.selected }).length > 0 &&
                                                                                <div className="h-10 w-10 mr-2 opacity-0">
                                                                                </div>
                                                                            }
                                                                            {
                                                                                campaign.open &&
                                                                                this.state.ads.filter((ad) => {
                                                                                    return ad.adgroup === adgroup.id;
                                                                                }).map((ad, index) => {
                                                                                    return (
                                                                                        <Fragment>
                                                                                            <div className="mr-2 relative cursor-pointer">
                                                                                                <div
                                                                                                    className="relative"
                                                                                                    onMouseEnter={() => {
                                                                                                        this.promisedSetState({ show_delete: ad.id });
                                                                                                    }}
                                                                                                    onMouseLeave={() => {
                                                                                                        this.promisedSetState({ show_delete: "" });
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        this.functions.deleteAsset(ad.id);
                                                                                                    }}
                                                                                                >
                                                                                                    {this.renders.preview(ad)}
                                                                                                    {console.log("pushad", ad)}
                                                                                                    {this.state.show_delete === ad.id && (
                                                                                                        <div className="absolute inset-0 flex justify-center items-center bg-black rounded-md bg-opacity-25">
                                                                                                            <div className="relative">
                                                                                                                <TrashIcon className="h-6 w-6 text-red-500" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default CreateOrderCreativePush;
