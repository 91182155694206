class adTiktok {

    static validate(ad) {
        let error = {
            name: false,
            body: false,
            page: false,
            website: false,
            cta: false,
            app_profile: false,
        };

        if (ad) {
            if (!ad.name || ad.name === "") {
                error.name = true;
            }
            if (!ad.page || ad.page.id === 0) {
                if (ad.type !== "posts") error.page = true;
            }
            if (ad.destination_type && ad.destination_type.value === "website") {
                if (!ad.link || ad.link === "" || !ad.link.includes("https://")) {
                    error.website = true;
                }
            }
            if (ad.destination_type && ad.destination_type.value === "app") {
                if (!ad.app_profile || ad.app_profile.id === 0) {
                    error.app_profile = true;
                }
            }
            if (!ad.body || ad.body === "") {
                if (ad.type !== "posts") error.body = true;
            }

            if (!ad.cta_type || ad.cta_type.id === 1) {
                if (!ad.cta || ad.cta.id === 0) {
                    error.cta = true;
                }
            }
        }


        return error;
    }
}
export { adTiktok }