import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ExclamationIcon, IdentificationIcon, PlusIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import { isNumber, round } from '@turf/turf';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import phone_codes from '../assets/json/phone-countries.json';
import SingleDatepicker from '../modules/singleDatepicker';
import SlideDown from 'react-slidedown';
import SwitchTailwind from './switchTailwind';

class CreateBudgetPlanItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reference: "",
            date: null,
            budget: "",
            action: { id: 1, name: "Active", value: "active" },
            sub_action: { id: 2, name: "Spend pacing", value: "spend_pacing" },
            scheduleMonday: true,
            scheduleTuesday: true,
            scheduleWednesday: true,
            scheduleThursday: true,
            scheduleFriday: true,
            scheduleSaturday: true,
            scheduleSunday: true,
            order: {},
            channel_budget: {},
            steps: [
                { name: 'Step 1', step: 'information', parents: [] },
                { name: 'Step 2', step: 'budget', parents: ["information"] },
                { name: 'Step 3', step: 'date', parents: ["budget", "information"] }
            ],
            open: {
                information: true
            },
            open_channel: {},
            onlyUpdateMonthlyTarget: false
        };
    }

    async componentDidMount() {
        await this.promisedSetState({
            reference: this.props.crmId,
            budget: this.props.maxBudget && +this.props.maxBudget > 0 ? this.props.maxBudget : 0,
            order: this.props.order,
            onlyUpdateMonthlyTarget: this.props.onlyUpdateMonthlyTarget
        });
        if (this.state.order && this.state.order.budgetChannels) {
            try {
                let temp_channels = JSON.parse(JSON.stringify(this.state.order.budgetChannels));
                Object.keys(temp_channels).filter((channel) => {
                    return this.state.order.budgetChannels[channel];
                }).map((channel) => {
                    this.state.channel_budget[channel] = temp_channels[channel];
                });
                Object.keys(temp_channels).map((channel) => {
                    if (this.state.channel_budget[channel] && +this.state.channel_budget[channel].value > 0) {
                        this.state.channel_budget[channel].disabled = false;
                    } else {
                        this.state.channel_budget[channel].disabled = true;
                    }
                })
                await this.promisedSetState({
                    channel_budget: this.state.channel_budget
                })
            } catch (error) { }
        }
        if (this.props.updateSteps) {
            if (this.state.onlyUpdateMonthlyTarget) {
                await this.promisedSetState({
                    steps: [
                        { name: 'Step 1', step: 'information', parents: [] },
                        { name: 'Step 2', step: 'budget', parents: ["information"] }
                    ]
                });
            }
            this.props.updateSteps(this.state.steps);
        }
        if (this.props.updateStep) {
            this.props.updateStep(this.state.open);
        }
    }

    functions = {
        createItem: async () => {
            await this.promisedSetState({ loading: true });
            let item = {
                reference: this.state.reference,
                date: this.state.date && this.state.date.full_date,
                budget: this.state.budget,
                action: this.state.action,
                sub_action: this.state.sub_action,
                ...(this.state.order.budgetChannels && { channel_budget: this.state.channel_budget }),
                only_update_monthly_target: this.state.onlyUpdateMonthlyTarget
            };
            try {
                let response = await this.calls.createItem(item);
                this.props.onCreated(response.data);
            } catch (error) {
                this.setState({
                    error: error.body && error.body.message ? error.body.message : "An error occurred"
                });
                this.props.onError();
            }
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        stepBack: async (step) => {
            let open = {};
            if (step == "information") {
                open = { information: true, budget: false, date: false };
            } else if (step == "budget") {
                open = { information: false, budget: true, date: false };
            }
            this.props.onStep(step);
            await this.promisedSetState({
                open: open
            });
            if (this.props.updateStep) {
                this.props.updateStep(this.state.open);
            }
        },
        stepForward: async (step) => {
            let open = {};
            if (step == "budget") {
                open = { information: false, budget: true, date: false };
            } else if (step == "date") {
                open = { information: false, budget: false, date: true };
            }
            this.props.onStep(step);
            await this.promisedSetState({
                open: open
            });
            if (this.props.updateStep) {
                this.props.updateStep(this.state.open);
            }
        }
    };

    calls = {
        createItem: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createOrderBudgetPlanItem?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        }
    };

    renders = {
        budgetLeft: () => {
            if (this.state.budget > 0 && Object.keys(this.state.channel_budget).filter((item) => { return this.state.channel_budget[item] }).length > 0) {
                let left = this.state.budget;
                Object.keys(this.state.channel_budget).filter((item) => { return this.state.channel_budget[item] }).map((item) => {
                    if (this.state.channel_budget[item].value) {
                        left -= +this.state.channel_budget[item].value;
                    }
                });
                if (left === 0) {
                    return {
                        text: "Left ",
                        value: 0,
                        percentage: false
                    };
                } else {
                    return {
                        text: left > 0 ? "Budget left: " : "Over budget: ",
                        value: Math.abs(left),
                        percentage: round((Math.abs(left) / this.state.budget) * 100, 2)
                    }
                    //return (left > 0 ? "Budget left: " : "Over budget: ") + Math.abs(left) + " (" + round((Math.abs(left) / this.state.total_budget) * 100, 2) + "%)";
                }

                //return left > 0 ? "(Left: " + left + ", " + round((left / this.state.total_budget) * 100, 2) + "%)" : "Over budget";
            } else {
                return {
                    text: "",
                    value: 0,
                    percentage: false
                };
            }
        },
        lowerThanTotalSpend: () => {
            let lower = false;
            if ((this.state.action.value == "active" && this.state.budget && +this.state.budget < this.state.order.totalSpendThisMonth && this.state.date && this.state.date.full_date && moment(this.state.date.full_date).format("YYYYMM") === moment().format("YYYYMM"))) {
                lower = true;
            }
            return lower;
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">

                            {/* CLIENT INFORMATION */}
                            <div className={(!this.state.open.information ? "" : "") + " col-span-12"}>
                                {
                                    false &&
                                    <div className="flex flex-row">
                                        <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                            <IdentificationIcon className="w-5 h-5 " />
                                        </div>
                                        <div className="flex flex-1 flex-col justify-center ml-3">
                                            <div className="font-semibold text-sm">Budget information</div>
                                            <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                <div className="text-purple-500">{this.state.reference !== "" ? this.state.reference : "..."}</div>
                                            </div>
                                        </div>
                                        <div className='flex'></div>
                                    </div>
                                }
                                <SlideDown
                                    closed={!this.state.open.information}
                                >
                                    <div className="grid-cols-12 grid gap-4">
                                        <div className={"col-span-12"}>
                                            <InputTailwind
                                                label={"Reference ID (optional)"}
                                                value={this.state.reference}
                                                onChange={(value) => {
                                                    this.promisedSetState({
                                                        reference: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={this.state.action.value === "active" ? "col-span-6" : "col-span-12"}>
                                            <DropdownTailwind
                                                label={"Action"}
                                                small={false}
                                                searchInput={true}
                                                selected={this.state.action}
                                                locked={this.state.onlyUpdateMonthlyTarget}
                                                options={[{ id: 1, name: "Active", value: "active" }, { id: 2, name: "Paused", value: "paused" }]}
                                                onChange={async (value) => {
                                                    if (value.value == "paused") {
                                                        await this.promisedSetState({
                                                            steps: [
                                                                { name: 'Step 1', step: 'information', parents: [] },
                                                                { name: 'Step 2', step: 'date', parents: ["information"] }
                                                            ]
                                                        });
                                                        if (this.props.updateSteps) {
                                                            this.props.updateSteps(this.state.steps);
                                                        }
                                                    }
                                                    await this.promisedSetState({
                                                        action: value
                                                    });
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.action.value == "active" &&
                                            <div className={"col-span-6"}>
                                                <DropdownTailwind
                                                    label={"Sub-action"}
                                                    small={false}
                                                    searchInput={true}
                                                    locked={this.state.onlyUpdateMonthlyTarget}
                                                    selected={this.state.sub_action}
                                                    options={[{ id: 2, name: "Spend pacing", value: "spend_pacing" }, { id: 1, name: "Spend total", value: "spend_total" }, { id: 1, name: "Spend rest", value: "spend_rest" }]}
                                                    onChange={async (value) => {
                                                        await this.promisedSetState({
                                                            sub_action: value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </SlideDown>
                                {
                                    this.state.open.information &&
                                    <div className="w-full border-t py-4 mt-4 flex">
                                        <div className="flex flex-1"></div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    if (this.state.action && this.state.action.value == "paused") {
                                                        this.functions.stepForward("date");
                                                    } else {
                                                        this.functions.stepForward("budget");
                                                    }
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* BUDGET */}
                            {
                                <div className={(!this.state.open.information && !this.state.open.budget ? "" : (this.state.open.budget ? "" : "hidden")) + " col-span-12"}>
                                    {
                                        false &&
                                        <div className="flex flex-row">
                                            <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                <IdentificationIcon className="w-5 h-5 " />
                                            </div>
                                            <div className="flex flex-1 flex-col justify-center ml-3">
                                                <div className="font-semibold text-sm">Set budget</div>
                                                <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                    <div className="text-purple-500">{+this.state.budget > 0 ? this.state.budget : "..."}</div>
                                                </div>
                                            </div>
                                            <div className='flex'></div>
                                        </div>
                                    }
                                    <SlideDown
                                        closed={!this.state.open.budget}
                                    >
                                        <div className="grid-cols-12 grid gap-4">
                                            {
                                                this.renders.lowerThanTotalSpend() &&
                                                <div className={"col-span-12"}>
                                                    <div className="bg-red-100 p-4 rounded-md">
                                                        <div className="text-sm font-medium text-red-500">
                                                            <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                                            OBS! Total spend so far  <b className='mx-1'>{this.state.order.totalSpendThisMonth}</b> is higher than new budget.
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.error &&
                                                <div className={"col-span-12"}>
                                                    <div className="bg-red-100 p-4 rounded-md">
                                                        <div className="text-sm font-medium text-red-500">
                                                            {this.state.error}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className={"col-span-12"}>
                                                <InputTailwind
                                                    label={"Order budget (Monthly target)"}
                                                    value={this.state.budget}
                                                    type={"number"}
                                                    onChange={async (value) => {
                                                        if (value < 0) {
                                                            value = 0;
                                                        };
                                                        await this.promisedSetState({
                                                            budget: value
                                                        });
                                                        try {
                                                            Object.keys(this.state.channel_budget).map((channel, index) => {
                                                                if (!this.state.channel_budget[channel]) {
                                                                    this.state.channel_budget[channel] = {
                                                                        value: "",
                                                                        off_budget: ""
                                                                    };
                                                                }
                                                                let buffer = JSON.parse(JSON.stringify(this.state.channel_budget[channel]));
                                                                this.state.channel_budget[channel].value = round((this.state.channel_budget[channel].off_budget / 100) * this.state.budget, 2);
                                                                if (this.renders.budgetLeft().text.includes("Over")) {
                                                                    this.state.channel_budget[channel] = buffer;
                                                                }
                                                            });
                                                            await this.promisedSetState({
                                                                channel_budget: this.state.channel_budget
                                                            });
                                                        } catch (error) { }
                                                    }}
                                                />
                                            </div>
                                            {
                                                !this.state.onlyUpdateMonthlyTarget &&
                                                ("budgetChannels" in this.state.order) &&
                                                this.state.channel_budget &&
                                                Object.keys(this.state.channel_budget).length > 0 &&
                                                <div className="relative col-span-12">
                                                    {
                                                        !(this.state.budget !== "" && this.state.budget > 0) &&
                                                        <div className="absolute z-50 bg-white left-0 right-0 top-0 bottom-0 opacity-75">

                                                        </div>
                                                    }
                                                    <div className="rounded-lg border overflow-hidden">
                                                        {
                                                            Object.keys(this.state.channel_budget).map((channel, index) => {
                                                                return (
                                                                    <Fragment>
                                                                        <div className={(index > 0 ? "border-t" : "") + "  bg-gray-50"}>
                                                                            <div className="flex flex-row items-center justify-center w-full p-4">
                                                                                <div className={"flex items-center"}>
                                                                                    <div className={"bg-" + (channel) + "-500" + " h-10 w-10 rounded-md flex justify-center items-center mr-3"}>
                                                                                        {
                                                                                            channel === 'google' &&
                                                                                            <img className="w-4"
                                                                                                alt=""
                                                                                                src={require('../assets/images/google_icon.png')} />
                                                                                        }
                                                                                        {
                                                                                            channel === 'facebook' &&
                                                                                            <img className="w-2"
                                                                                                alt=""
                                                                                                src={require('../assets/images/facebook_icon.svg')} />
                                                                                        }
                                                                                        {
                                                                                            channel === 'linkedin' &&
                                                                                            <img className="w-4"
                                                                                                alt=""
                                                                                                src={require('../assets/images/linkedin_icon.svg')} />
                                                                                        }
                                                                                        {
                                                                                            channel === 'twitter' &&
                                                                                            <img className="w-4"
                                                                                                alt=""
                                                                                                src={require('../assets/images/twitter_icon.svg')} />
                                                                                        }
                                                                                        {
                                                                                            channel === 'tiktok' &&
                                                                                            <img className="w-4"
                                                                                                alt=""
                                                                                                src={require('../assets/images/tiktok_icon.png')} />
                                                                                        }
                                                                                        {
                                                                                            channel === 'snapchat' &&
                                                                                            <img className="w-4"
                                                                                                alt=""
                                                                                                src={require('../assets/images/snapchat.svg')} />
                                                                                        }
                                                                                        {
                                                                                            channel === 'bing' &&
                                                                                            <img className="w-3"
                                                                                                alt=""
                                                                                                src={require('../assets/images/bing-logo.png')} />
                                                                                        }
                                                                                    </div>
                                                                                    <div className="flex flex-col">
                                                                                        <div className="text-sm capitalize font-semibold flex items-center">
                                                                                            {channel}
                                                                                        </div>
                                                                                        {
                                                                                            !this.state.channel_budget[channel].disabled &&
                                                                                            <div className="text-xs font-medium">
                                                                                                {this.state.channel_budget[channel] ? this.state.channel_budget[channel].off_budget : "0"}% of monthly target
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            this.state.channel_budget[channel].disabled &&
                                                                                            <div className="text-xs font-medium">
                                                                                                Disabled
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-1"></div>
                                                                                <div className={"relative overflow-hidden"}>
                                                                                    <SwitchTailwind
                                                                                        value={!this.state.channel_budget[channel].disabled}
                                                                                        onSwitch={async () => {
                                                                                            this.state.channel_budget[channel].disabled = !this.state.channel_budget[channel].disabled;
                                                                                            if (this.state.channel_budget[channel].disabled) {
                                                                                                this.state.channel_budget[channel].value = 0;
                                                                                                this.state.channel_budget[channel].off_budget = 0;
                                                                                            }
                                                                                            this.setState({
                                                                                                open_channel: this.state.open_channel
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    false &&
                                                                                    <div onClick={() => {
                                                                                        if (!this.state.channel_budget[channel].disabled) {
                                                                                            this.state.open_channel[channel] = !this.state.open_channel[channel];
                                                                                            this.setState({
                                                                                                open_channel: this.state.open_channel
                                                                                            });
                                                                                        }
                                                                                    }} className={(this.state.channel_budget[channel].disabled ? "cursor-not-allowed bg-gray-100 text-gray-400" : "bg-purple-500 text-white cursor-pointer") + " h-10 w-10 flex justify-center items-center rounded-md "}>
                                                                                        <PlusIcon className="w-5" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <SlideDown
                                                                                closed={this.state.channel_budget[channel].disabled}
                                                                            >
                                                                                <div className="grid grid-cols-12 gap-4 border-t p-4">
                                                                                    <div className={"col-span-6"}>
                                                                                        <InputTailwind
                                                                                            label={"Channel budget"}
                                                                                            disabled={this.state.budget !== "" && this.state.budget > 0 ? false : true}
                                                                                            locked={this.state.budget !== "" && this.state.budget > 0 ? false : true}
                                                                                            type={'number'}
                                                                                            value={this.state.channel_budget[channel] ? this.state.channel_budget[channel].value : " "}
                                                                                            onChange={async (value) => {
                                                                                                if (!this.state.channel_budget[channel]) {
                                                                                                    this.state.channel_budget[channel] = {
                                                                                                        value: "",
                                                                                                        off_budget: ""
                                                                                                    };
                                                                                                }
                                                                                                let buffer = JSON.parse(JSON.stringify(this.state.channel_budget[channel]));
                                                                                                this.state.channel_budget[channel].value = value;
                                                                                                this.state.channel_budget[channel].off_budget = round((value / this.state.budget) * 100, 2);
                                                                                                await this.promisedSetState({
                                                                                                    channel_budget: this.state.channel_budget
                                                                                                })
                                                                                                if (this.renders.budgetLeft().text.includes("Over")) {
                                                                                                    this.state.channel_budget[channel] = buffer;
                                                                                                    await this.promisedSetState({
                                                                                                        channel_budget: this.state.channel_budget
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className={"col-span-6"}>
                                                                                        <InputTailwind
                                                                                            label={"% of Order Budget"}
                                                                                            disabled={this.state.budget !== "" && this.state.budget > 0 ? false : true}
                                                                                            rightSection={true}
                                                                                            rightSectionLabel={"%"}
                                                                                            type={'number'}
                                                                                            value={this.state.channel_budget[channel] ? this.state.channel_budget[channel].off_budget : " "}
                                                                                            onChange={async (value) => {
                                                                                                if (!this.state.channel_budget[channel]) {
                                                                                                    this.state.channel_budget[channel] = {
                                                                                                        value: "",
                                                                                                        off_budget: ""
                                                                                                    };
                                                                                                }
                                                                                                let buffer = JSON.parse(JSON.stringify(this.state.channel_budget[channel]));
                                                                                                this.state.channel_budget[channel].off_budget = value;
                                                                                                this.state.channel_budget[channel].value = round((value / 100) * this.state.budget, 2);
                                                                                                await this.promisedSetState({
                                                                                                    channel_budget: this.state.channel_budget
                                                                                                })
                                                                                                if (this.renders.budgetLeft().text.includes("Over")) {
                                                                                                    this.state.channel_budget[channel] = buffer;
                                                                                                    await this.promisedSetState({
                                                                                                        channel_budget: this.state.channel_budget
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </SlideDown>

                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </SlideDown>
                                    {
                                        this.state.open.budget &&
                                        <div className="w-full border-t py-4 mt-4 flex">
                                            <div onClick={() => {
                                                this.functions.stepBack("information");
                                            }} className="cursor-pointer bg-white border-1.5 text-gray-800 hover:bg-gray-200 inline-flex justify-center rounded-md w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                                                Back
                                            </div>
                                            <div className="flex flex-1"></div>
                                            {
                                                !this.state.onlyUpdateMonthlyTarget &&
                                                <div
                                                    onClick={() => {
                                                        this.functions.stepForward("date");
                                                    }}
                                                    className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    Next
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }

                            {/* DATE */}
                            {
                                <div className={(!this.state.open.information && !this.state.open.budget && !this.state.open.date ? "" : (this.state.open.date ? "" : "hidden")) + " col-span-12"}>
                                    {
                                        false &&
                                        <div className="flex flex-row">
                                            <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                <IdentificationIcon className="w-5 h-5 " />
                                            </div>
                                            <div className="flex flex-1 flex-col justify-center ml-3">
                                                <div className="font-semibold text-sm">Set date</div>
                                                <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                    <div className="text-purple-500">{this.state.date && this.state.date.full_date ? this.state.date.full_date : "..."}</div>
                                                </div>
                                            </div>
                                            <div className='flex'></div>
                                        </div>
                                    }
                                    <SlideDown
                                        closed={!this.state.open.date}
                                    >
                                        <div className="grid-cols-12 grid gap-4">
                                            {
                                                this.renders.lowerThanTotalSpend() &&
                                                <div className={"col-span-12"}>
                                                    <div className="bg-red-100 p-4 rounded-md">
                                                        <div className="text-sm font-medium text-red-500">
                                                            <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                                            OBS! Total spend so far  <b className='mx-1'>{this.state.order.totalSpendThisMonth}</b> is higher than new budget.
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.error &&
                                                <div className={"col-span-12"}>
                                                    <div className="bg-red-100 p-4 rounded-md">
                                                        <div className="text-sm font-medium text-red-500">
                                                            {this.state.error}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className={"col-span-12"}>
                                                <div className="border-1.5 bg-custom-input pb-0 p-2 pt-0 rounded-md">
                                                    <SingleDatepicker
                                                        minToday={true}
                                                        minDateToday={true}
                                                        singleDate={true}
                                                        onChange={(date) => {
                                                            console.log(date);
                                                            this.setState({
                                                                date: date
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </SlideDown>
                                    {
                                        this.state.open.date &&
                                        <div className="w-full border-t py-4 mt-4 flex">
                                            <div onClick={() => {
                                                if (this.state.action && this.state.action.value == "paused") {
                                                    this.functions.stepBack("information");
                                                } else {
                                                    this.functions.stepBack("budget");
                                                }
                                            }} className="cursor-pointer bg-white border-1.5 text-gray-800 hover:bg-gray-200 inline-flex justify-center rounded-md w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                                                Back
                                            </div>
                                            <div className="flex flex-1"></div>
                                            {
                                                false &&
                                                <div
                                                    onClick={() => {
                                                        if (this.props.submit()) {
                                                            this.props.submit();
                                                        }
                                                    }}
                                                    className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    Create
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CreateBudgetPlanItem;