import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ChevronUpIcon, IdentificationIcon, CurrencyDollarIcon, InformationCircleIcon, CubeIcon, UsersIcon, PlusIcon, XIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import SlideDown from 'react-slidedown';
import { isNumber, round } from '@turf/turf';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import SwitchTailwind from './switchTailwind';
import cn from "classnames";

class CreateAccountBudget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            note: "",
            clients: [],
            client: { id: 0, name: "Click to search ..." },
            loading_clients: false,
            endDate: null,
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            selected_client: {},
            throttling: {},
            open: {
                clients: true
            },
            channel_budgets: {},
            channels: [{
                name: "Google", value: "google", key: "google",
                new_budget: {
                    periodType: { name: "Custom dates", value: "custom" },
                    startDate: moment().format("YYYY-MM-DD"),
                    endDate: moment().endOf("month").format("YYYY-MM-DD"),
                    budgetType: { name: "Custom budget", value: "custom" },
                    budget: 0
                },
                edit: null
            },
            {
                name: "Bing", value: "bing", key: "bing",
                new_budget: {
                    periodType: { name: "Custom dates", value: "custom" },
                    startDate: moment().format("YYYY-MM-DD"),
                    endDate: moment().endOf("month").format("YYYY-MM-DD"),
                    budgetType: { name: "Custom budget", value: "custom" },
                    budget: 0
                },
                edit: null
            }],

            //GOOGLE
            loading_google_billings: false,
            google_billings: [],
            google_billing: {},
        };
    }

    async componentDidMount() {
        if (this.props.client) {
            await this.promisedSetState({
                selected_client: this.props.client,
                open: {
                    clients: false,
                }
            });
            this.functions.getAccountBudgets();
        } else {
            this.functions.getClients(true);
        }
    }

    functions = {
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        },
        getAccountBudgets: async (channel, error_message) => {
            if (channel) {
                this.state.channel_budgets[channel].loading = true;
            }
            await this.promisedSetState({
                loading_budgets: true,
                channel_budgets: this.state.channel_budgets,
            });
            try {
                let response = await this.calls.getAccountBudgets();
                if ("google" && response.data["google"]) {
                    this.state.selected_client["google"].autoRenewBudget = response.data["google"].autoRenewBudget;
                    this.state.selected_client["google"].autoRenewTotalType = response.data["google"].autoRenewTotalType;
                }
                if ("bing" && response.data["bing"]) {
                    this.state.selected_client["bing"].autoRenewBudget = response.data["bing"].autoRenewBudget;
                    this.state.selected_client["bing"].autoRenewTotalType = response.data["bing"].autoRenewTotalType;
                }
                await this.promisedSetState({
                    channel_budgets: response.data
                });
                if (channel) {
                    this.state.channel_budgets[channel].error = error_message;
                    await this.promisedSetState({
                        channel_budgets: this.state.channel_budgets
                    });
                }
            } catch (error) { }
            if (channel) {
                this.state.channel_budgets[channel].loading = false;
            }
            if (this.state.selected_client.google && this.state.selected_client.google.connected) {
                this.functions.listGoogleBillings();
            }
            await this.promisedSetState({
                loading_budgets: false,
                channel_budgets: this.state.channel_budgets,
                initial_data: this.state.channel_budgets
            });
        },
        updateClientAutoBudget: async (channel) => {
            this.state.channel_budgets[channel].loading_auto = true;
            await this.promisedSetState({
                channel_budgets: this.state.channel_budgets
            });
            let error_message = null;
            try {
                await this.calls.updateClientAutoBudget({
                    channel: channel,
                    autoRenewBudget: !this.state.selected_client[channel].autoRenewBudget,
                    autoRenewTotalType: this.state.selected_client[channel].autoRenewTotalType,
                    orderTotal: this.state.channel_budgets.order.total,
                    channelTotal: this.state.channel_budgets[channel].current_order_total,
                    accountSpend: this.state.channel_budgets[channel].total_spend_month,
                    currentDifference: this.renders.calculateCurrentMonth(channel),
                    currentTotal: this.renders.calculateCurrentTotal(channel),
                    //CHANNEL SPECIFIC
                    googleBilling: this.state.google_billing.resourceName,
                    //CHANNEL SPECIFIC
                });
            } catch (error) {
                error_message = error && error.body ? error.body.message : "Something went wrong"
                this.state.channel_budgets[channel].error = error_message;
                await this.promisedSetState({
                    channel_budgets: this.state.channel_budgets
                });
            }
            this.functions.getAccountBudgets(channel, error_message);
        },
        createAccountBudget: async (channel, data) => {
            this.state.channel_budgets[channel].create_loading = true;
            this.state.channel_budgets[channel].create_error = false;
            await this.promisedSetState({
                channel_budgets: this.state.channel_budgets
            });
            try {
                let account_data = JSON.parse(JSON.stringify(data));
                account_data.channel = channel;
                //CHANNEL SPECIFIC
                account_data.googleBilling = this.state.google_billing.resourceName;
                //CHANNEL SPECIFIC
                await this.calls.createClientBudget(account_data);
                this.functions.getAccountBudgets(channel);
            } catch (error) {
                this.state.channel_budgets[channel].create_error = error && error.body ? error.body.message : "Something went wrong";
            }
            this.state.channel_budgets[channel].create_loading = false;
            await this.promisedSetState({
                channel_budgets: this.state.channel_budgets
            });
        },
        updateClientBudget: async (channel, data, type) => {
            this.state.channel_budgets[channel].update_loading = true;
            this.state.channel_budgets[channel].update_error = false;
            await this.promisedSetState({
                channel_budgets: this.state.channel_budgets
            });
            try {
                let account_data = JSON.parse(JSON.stringify(data));
                account_data.type = type;
                account_data.channel = channel;
                await this.calls.updateClientBudget(account_data);
                this.functions.getAccountBudgets(channel);
            } catch (error) {
                this.state.channel_budgets[channel].update_error = error && error.body ? error.body.message : "Something went wrong";
            }
            this.state.channel_budgets[channel].update_loading = false;
            await this.promisedSetState({
                channel_budgets: this.state.channel_budgets
            });
        },
        removeAccountBudget: async (channel, data) => {
            this.state.channel_budgets[channel].update_loading = true;
            this.state.channel_budgets[channel].update_error = false;
            await this.promisedSetState({
                channel_budgets: this.state.channel_budgets
            });
            try {
                let account_data = JSON.parse(JSON.stringify(data));
                account_data.channel = channel;
                await this.calls.removeClientBudget(account_data);
                this.functions.getAccountBudgets(channel);
            } catch (error) {
                this.state.channel_budgets[channel].update_error = error && error.body ? error.body.message : "Something went wrong";
            }
            this.state.channel_budgets[channel].update_loading = false;
            await this.promisedSetState({
                channel_budgets: this.state.channel_budgets
            });
        },
        listGoogleBillings: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    loading_google_billings: true
                });
                try {
                    let response = await this.calls.listBillings();
                    let current = null;
                    if (this.state.channel_budgets["google"] && this.state.channel_budgets["google"].current) {
                        current = this.state.channel_budgets["google"].current.billingSetup;
                    }
                    let billing_setup = null;
                    response.data.map((item) => {
                        if (!billing_setup && item.resourceName == current) {
                            billing_setup = item;
                        }
                    });
                    if (billing_setup) {
                        await this.promisedSetState({
                            google_billing: billing_setup
                        });
                    }
                    await this.promisedSetState({
                        google_billings: response.data.filter((item) => { return item.status === "APPROVED" }).map((item) => { item.name = (item.paymentsAccountInfo ? item.paymentsAccountInfo.paymentsAccountName : item.id); return item })
                    });
                } catch (error) {
                    if (this.state.channel_budgets["google"]) {
                        let message = error && error.body ? error.body.message : "Something went wrong";
                        message += ", listing Google billing setups";
                        this.state.channel_budgets["google"].error = message
                        await this.promisedSetState({
                            channel_budgets: this.state.channel_budgets
                        });
                    }
                }
                await this.promisedSetState({
                    loading_google_billings: false
                });
                resolve();
            });
        }
    };

    renders = {
        thisMonth: (start) => {
            try {
                return moment(start).format("YYYY-MM") === moment().format("YYYY-MM");
            } catch (error) {
                return false;
            }
        },
        calculateCurrentMonth: (channel) => {
            let order_total = 0;
            let spend_total = +this.state.channel_budgets[channel].total_spend_month;
            if (this.state.selected_client[channel].autoRenewTotalType && this.state.selected_client[channel].autoRenewTotalType.value === "orders_total") {
                if (this.state.channel_budgets.order.total) {
                    order_total = this.state.channel_budgets.order.total;
                }
            } else if (this.state.selected_client[channel].autoRenewTotalType && this.state.selected_client[channel].autoRenewTotalType.value === "platform_total") {
                if (this.state.channel_budgets[channel].platform_total) {
                    order_total = this.state.channel_budgets[channel].platform_total;
                }
            } else {
                if (this.state.channel_budgets[channel].current_order_total) {
                    order_total = this.state.channel_budgets[channel].current_order_total;
                }
            }
            order_total = +order_total - +spend_total;
            try {
                order_total = order_total.toFixed(2);
            } catch (error) { }
            if (order_total < 0) {
                return 0
            } else {
                return order_total;
            }
        },
        calculateCurrentTotal: (channel) => {
            let order_total = 0;
            if (this.state.selected_client[channel].autoRenewTotalType && this.state.selected_client[channel].autoRenewTotalType.value === "orders_total") {
                if (this.state.channel_budgets.order.total) {
                    order_total = this.state.channel_budgets.order.total;
                }
            } else {
                if (this.state.channel_budgets[channel].current_order_total) {
                    order_total = this.state.channel_budgets[channel].current_order_total;
                }
            }
            if (order_total < 0) {
                return 0
            } else {
                return order_total;
            }
        },
        calculateNextMonth: (channel) => {
            let order_total = 0;
            if (this.state.selected_client[channel].autoRenewTotalType && this.state.selected_client[channel].autoRenewTotalType.value === "orders_total") {
                if (this.state.channel_budgets.order.total) {
                    order_total = this.state.channel_budgets.order.total;
                }
            } else {
                if (this.state.channel_budgets[channel].current_order_total) {
                    order_total = this.state.channel_budgets[channel].current_order_total;
                }
            }
            return order_total;
        },
        twoFixed: (value) => {
            try {
                return value.toFixed(2);
            } catch (error) {
                return value;
            }
        },
        currentBudgetSpendAndTotal: (channel) => {
            let current = { spendLimit: 0, spendingServed: 0 };
            try {
                this.state.channel_budgets[channel].items.map((item) => {
                    if (item.id == this.state.channel_budgets[channel].current.id) {
                        current.spendLimit = item.spendLimit;
                        current.spendingServed = this.renders.twoFixed(item.spendingServed);
                    }
                });
            } catch (error) { }
            return current;
        }
    }

    calls = {
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "");
            return apiRegister.call(options, url);
        },
        getAccountBudgets: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getClientChannelBudgets?client=" + this.state.selected_client.id + (this.props.channel ? "&channel=" + this.props.channel : "");
            return apiRegister.call(options, url);
        },
        updateClientAutoBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateClientAutoBudget?client=" + this.state.selected_client.id;
            return apiRegister.call(options, url);
        },
        createClientBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createClientBudget?client=" + this.state.selected_client.id;
            return apiRegister.call(options, url);
        },
        updateClientBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateClientBudget?client=" + this.state.selected_client.id;
            return apiRegister.call(options, url);
        },
        removeClientBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeClientBudget?client=" + this.state.selected_client.id;
            return apiRegister.call(options, url);
        },
        listBillings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listBillings?client=" + this.state.selected_client.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">

                    {
                        this.props.channel &&
                        this.state.loading_budgets &&
                        <div className="w-full mt-4 h-48 border rounded-md bg-white relative flex items-center justify-center">
                            <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                                <div style={{ borderTopColor: "transparent" }} class="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            </div>
                        </div>
                    }

                    {/* SELECT CLIENT */}
                    {
                        !this.props.channel &&
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <IdentificationIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Select client</div>
                                        {
                                            this.state.selected_client && this.state.selected_client.id &&
                                            <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.selected_client.name}</div></div>
                                        }
                                        {
                                            !(this.state.selected_client && this.state.selected_client.id) &&
                                            <div className="font-medium text-xs text-gray-500">No client selected</div>
                                        }
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                        onClick={() => {
                                            if (!this.props.client) {
                                                this.state.open.clients = !this.state.open.clients;
                                                this.setState({ open: this.state.open })
                                            }
                                        }}
                                    >
                                        {
                                            !this.props.client &&
                                            !this.state.open.clients &&
                                            <ChevronDownIcon className="w-5 h-5 " />
                                        }
                                        {
                                            !this.props.client &&
                                            this.state.open.clients &&
                                            <ChevronUpIcon className="w-5 h-5 " />
                                        }
                                        {
                                            this.props.client &&
                                            <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.clients}
                            >
                                <div className='mb-4'>
                                    <div className='mt-4'>
                                        <DropdownTailwind
                                            small={false}
                                            skipInternalSearch={true}
                                            loader={this.state.loading_clients}
                                            loadingPagination={this.state.loading_clients_pagination}
                                            loadingSearch={this.state.loading_clients_search}
                                            total={this.state.clients_total}
                                            searchInput={true}
                                            placeholder={"Search ..."}
                                            pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                            selected={this.state.selected_client.id !== 1 ? this.state.selected_client : { id: 1, name: "Select client" }}
                                            options={this.state.clients.length > 0 ? this.state.clients : []}
                                            onChange={async (value) => {
                                                this.state.open.clients = false;
                                                this.state.open.information = true;
                                                await this.promisedSetState({
                                                    selected_client: value,
                                                    open: this.state.open
                                                });
                                                this.functions.getAccountBudgets();
                                            }}
                                            onPagination={async () => {
                                                if (!this.state.loading_clients_pagination) {
                                                    await this.promisedSetState({
                                                        client_page: this.state.client_page + 1
                                                    });
                                                    this.functions.getClients(false, true, false);
                                                }
                                            }}
                                            onSearch={async (value) => {
                                                await this.promisedSetState({
                                                    loading_clients_search: true,
                                                    client_search: value
                                                });
                                                setTimeout(async () => {
                                                    if (value === this.state.client_search) {
                                                        await this.promisedSetState({
                                                            client_page: 1
                                                        });
                                                        this.functions.getClients(false, false, true, value);
                                                    }
                                                }, 400);
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* NEXT */}
                                {
                                    <div className='flex justify-center'>
                                        <div
                                            onClick={() => {
                                                this.state.open.clients = false;
                                                this.state.open.information = true;
                                                this.setState({ open: this.state.open })
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.selected_client.id ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {
                        //!this.state.loading_budgets &&
                        this.state.channel_budgets["order"] &&
                        <div className="bg-blue-100 rounded-md text-blue-500 p-4 text-sm font-medium mt-4">
                            This client has {this.state.channel_budgets["order"].active} active orders with connected channels.
                            <div className="mt-2">
                                {
                                    this.state.channels.filter((item) => {
                                        return this.state.channel_budgets[item.key]
                                    }).map((item) => {
                                        return (
                                            <div className="text-xs">
                                                <span className="capitalize">&#x2022; {item.key}</span> has spend {this.state.channel_budgets[item.key].total_spend_month} {this.state.channel_budgets[item.key].currency} {false && <span>of {this.state.channel_budgets[item.key].current_order_total} {this.state.channel_budgets[item.key].currency}.</span>}
                                                {
                                                    false &&
                                                    +this.state.channel_budgets[item.key].total_spend_month > this.state.channel_budgets[item.key].current_order_total &&
                                                    <span> <span className="font-bold">OBS!</span> This channel has used {this.renders.twoFixed(+this.state.channel_budgets[item.key].total_spend_month - +this.state.channel_budgets[item.key].current_order_total)} {this.state.channel_budgets[item.key].currency} more than planned.</span>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }

                    {/* FULL BORDER */}
                    {
                        !this.props.channel &&
                        <div className="border-b w-full mt-4 mb-4"></div>
                    }

                    {/* SET INFROMATION */}
                    {
                        //!this.state.loading_budgets &&
                        this.state.channels.filter((item) => {
                            return (!this.props.channel && this.state.selected_client[item.key] && this.state.selected_client[item.key].connected) || (this.props.channel && item.key == this.props.channel && this.state.selected_client[item.key] && this.state.selected_client[item.key].connected)
                        }).map((channel) => {
                            return (
                                <Fragment>
                                    <>
                                        {
                                            !this.props.channel &&
                                            <div className={"flex justify-between"}>
                                                <div className='flex flex-1'>
                                                    <div className={"h-10 w-10 cursor-pointer bg-" + channel.value + "-500 p-3 flex justify-center items-center rounded-md"}>
                                                        {
                                                            channel.value === 'facebook' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel.value === 'google' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel.value === 'tiktok' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel.value === 'linkedin' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel.value === 'bing' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel.value === 'snapchat' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                        }
                                                    </div>
                                                    <div className="flex flex-col flex-1 ml-2">
                                                        <div className="font-semibold text-sm">{channel.name}</div>
                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                            {
                                                                !this.state.channel_budgets[channel.value] &&
                                                                <span>Loading ...</span>
                                                            }
                                                            {
                                                                this.state.channel_budgets[channel.value] && this.state.channel_budgets[channel.value].current && !this.state.channel_budgets[channel.value].current.id &&
                                                                <span>No current budget</span>
                                                            }
                                                            {
                                                                (this.state.channel_budgets[channel.value] && this.state.channel_budgets[channel.value].current && this.state.channel_budgets[channel.value].current.id) &&
                                                                <div className="w-full flex flex-row">
                                                                    <div className="mr-1">{+this.renders.currentBudgetSpendAndTotal(channel.value).spendingServed + "/" + (this.state.channel_budgets[channel.value].current.spendLimit !== "unlimited" ? +this.state.channel_budgets[channel.value].current.spendLimit : "")}</div>
                                                                    {
                                                                        this.state.channel_budgets[channel.value].current.spendLimit !== "unlimited" &&
                                                                        <div className="mr-2">({((+this.renders.currentBudgetSpendAndTotal(channel.value).spendingServed / +this.state.channel_budgets[channel.value].current.spendLimit) * 100).toFixed(2)}%)</div>
                                                                    }
                                                                    {
                                                                        this.state.channel_budgets[channel.value].current.spendLimit == "unlimited" &&
                                                                        <div className="mr-2">Unlimited</div>
                                                                    }
                                                                    <div style={{ marginTop: "6.5px" }} className="h-1 w-full bg-gray-100 flex flex-row mr-4 rounded-md overflow-hidden">
                                                                        <div style={{ width: (+this.renders.currentBudgetSpendAndTotal(channel.value).spendingServed / +this.state.channel_budgets[channel.value].current.spendLimit) * 100 + "%" }} className="bg-purple-500 h-full transform ease-in-out duration-200"></div>
                                                                        <div className="flex flex-1 h-full bg-gray-100"></div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex'>
                                                    <div className={"bg-gray-100 cursor-pointer relative h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                                        onClick={() => {
                                                            if (this.state.channel_budgets[channel.value]) {
                                                                channel.open = !channel.open;
                                                                this.setState({ channels: this.state.channels });
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            this.state.channel_budgets[channel.value] &&
                                                            !channel.open &&
                                                            <ChevronDownIcon className="w-5 h-5 " />
                                                        }
                                                        {
                                                            this.state.channel_budgets[channel.value] &&
                                                            channel.open &&
                                                            <ChevronUpIcon className="w-5 h-5 " />
                                                        }
                                                        {
                                                            !this.state.channel_budgets[channel.value] &&
                                                            <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <SlideDown
                                            closed={!channel.open && !this.props.channel}
                                        >
                                            <div className='mb-4 relative'>
                                                {
                                                    this.state.channel_budgets[channel.value] &&
                                                    this.state.channel_budgets[channel.value].loading &&
                                                    <div className="absolute z-51 left-0 right-0 top-0 bottom-0  bg-white bg-opacity-75 flex items-center justify-center">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div className='mt-4'>
                                                    <div className="grid-cols-10 gap-4 grid">
                                                        <div className="col-span-12 grid-cols-12 grid gap-4">
                                                            {
                                                                //GOOGLE ONLY
                                                                this.state.channel_budgets[channel.value] &&
                                                                this.state.selected_client.google &&
                                                                channel.value == "google" &&
                                                                <div className="col-span-4">
                                                                    <DropdownTailwind
                                                                        locked={this.state.selected_client.google.autoRenewBudget}
                                                                        label={"Billing"}
                                                                        loader={this.state.loading_google_billings}
                                                                        selected={this.state.google_billing}
                                                                        options={this.state.google_billings}
                                                                        onChange={async (value) => {
                                                                            await this.promisedSetState({
                                                                                google_billing: value
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                this.state.channel_budgets[channel.value] &&
                                                                this.state.selected_client[channel.value] &&
                                                                <div className={(channel.value == "google" ? "col-span-4" : "col-span-6") + " relative"}>
                                                                    {
                                                                        this.state.channel_budgets[channel.value].loading_auto &&
                                                                        <div className="absolute left-0 right-0 top-0 bottom-0  bg-white bg-opacity-75 flex items-center justify-center">
                                                                            <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                    <DropdownTailwind
                                                                        locked={this.state.selected_client[channel.value].autoRenewBudget}
                                                                        label={"Calculate budget with"}
                                                                        selected={this.state.selected_client[channel.value].autoRenewTotalType ? this.state.selected_client[channel.value].autoRenewTotalType : { name: "Campaigns total (recommended)", value: "channels_total" }}
                                                                        options={[
                                                                            { name: "Campaigns total (recommended)", value: "channels_total" },
                                                                            { name: "Channels total (only works if orders are upgraded)", value: "platform_total" },
                                                                            { name: "Orders total", value: "orders_total" }]
                                                                        }
                                                                        onChange={async (option) => {
                                                                            this.state.selected_client[channel.value].autoRenewTotalType = option;
                                                                            this.setState({
                                                                                selected_client: this.state.selected_client
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                this.state.channel_budgets[channel.value] &&
                                                                this.state.selected_client[channel.value] &&
                                                                <div className={(channel.value == "google" ? "col-span-4" : "col-span-6") + ""}>
                                                                    <div className="text-xs font-medium mb-1">Auto renew next month budget</div>
                                                                    <div className="rounded-md bg-custom-input h-12 overflow-hidden relative border-1.5">
                                                                        {
                                                                            this.state.channel_budgets[channel.value].loading_auto &&
                                                                            <div className="absolute left-0 right-0 top-0 bottom-0  bg-white bg-opacity-75 flex items-center justify-center">
                                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                        <div className={"col-span-12 flex flex-row px-4 h-full items-center"}>
                                                                            <div className="flex-1 flex flex-row text-sm items-center font-medium truncate whitespace-no-wrap">
                                                                                <div className="truncate whitespace-no-wrap">
                                                                                    {this.state.selected_client[channel.value].autoRenewBudget ? "Enabled" : "Disabled"}
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                <SwitchTailwind
                                                                                    value={this.state.selected_client[channel.value].autoRenewBudget}
                                                                                    onSwitch={async () => {
                                                                                        this.functions.updateClientAutoBudget(channel.value);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            this.state.channel_budgets[channel.value] && this.state.channel_budgets[channel.value].error &&
                                                            <div className={"col-span-12"}>
                                                                <div className="w-full p-4 bg-red-100 text-red-500 rounded-md text-sm font-medium">
                                                                    {this.state.channel_budgets[channel.value].error}
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.channel_budgets.order &&
                                                            this.state.channel_budgets.order.active < 1 &&
                                                            <div className={"col-span-12"}>
                                                                <div className="w-full p-4 bg-red-100 text-red-500 rounded-md text-sm font-medium">
                                                                    No active orders
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.channel_budgets[channel.value] &&
                                                            this.state.selected_client[channel.value] &&
                                                            !this.state.selected_client[channel.value].autoRenewBudget &&
                                                            <div className={"col-span-12"}>
                                                                <div className="rounded-md bg-blue-100 text-blue-500 p-4 text-sm font-medium">
                                                                    <div className="mb-2">Starting auto renew will update upcoming according to client orders.</div>
                                                                    <div className="text-xs text-normal">&#x2022; Current month will be <span className="font-semibold">{this.renders.calculateCurrentMonth(channel.value)} {this.state.channel_budgets[channel.value].currency}</span></div>
                                                                    <div className="text-xs text-normal">&#x2022; Upcoming month will be <span className="font-semibold">{this.renders.calculateNextMonth(channel.value)} {this.state.channel_budgets[channel.value].currency}</span></div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.channel_budgets[channel.value] &&
                                                            Array.isArray(this.state.channel_budgets[channel.value].items) &&
                                                            this.state.channel_budgets[channel.value].items.length > 0 &&
                                                            <div className="col-span-12 relative">
                                                                {
                                                                    this.state.channel_budgets[channel.value] &&
                                                                    this.state.channel_budgets[channel.value].create &&
                                                                    <div className="absolute cursor-not-allowed z-51 left-0 right-0 top-0 bottom-0  bg-white bg-opacity-75 flex items-center justify-center">

                                                                    </div>
                                                                }
                                                                <div className="text-xs font-medium mb-1">Current/Upcoming budgets</div>
                                                                <div className="rounded-md bg-custom-input m-h-12 border-1.5">
                                                                    {
                                                                        this.state.channel_budgets[channel.value].items.sort(function (a, b) {
                                                                            if (a.end == "forever") {
                                                                                return -10000000000000000000000000000000000000000000000;
                                                                            } else {
                                                                                return new Date(b.end) - new Date(a.end);
                                                                            }
                                                                        }).map((budget, index) => {
                                                                            return (
                                                                                <div className={(index !== 0 ? "border-t" : "") + " col-span-12 flex flex-col p-4 py-2.5"}>
                                                                                    <div className="flex flex-1 flex-row items-center">
                                                                                        <div className="flex-1 flex flex-row text-sm items-center font-medium truncate whitespace-no-wrap">
                                                                                            <div className="truncate whitespace-no-wrap text-gray-500">
                                                                                                {budget.start} / {budget.end}
                                                                                            </div>
                                                                                            <div className="truncate ml-4 whitespace-no-wrap">
                                                                                                {this.renders.twoFixed(budget.spendingServed)}/{this.renders.twoFixed(budget.spendingLimit)} {this.state.channel_budgets[channel.value].currency}
                                                                                            </div>
                                                                                            <div className="ml-2 mr-2">
                                                                                                <span
                                                                                                    className={cn("px-2 py-1 inline-flex text-xs font-semibold rounded-full capitalize",
                                                                                                        {
                                                                                                            ["bg-green-100 text-green-500"]: (budget.status == "ENABLED" || budget.status == "APPROVED" || budget.status == "Active"),
                                                                                                            ["bg-gray-100 text-gray-500"]: !(budget.status == "ENABLED" || budget.status == "APPROVED" || budget.status == "Active")
                                                                                                        })
                                                                                                    }>
                                                                                                    {budget.status}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            //!this.state.selected_client[channel.value].autoRenewBudget &&
                                                                                            <div className="pr-6 text-sm font-medium">
                                                                                                <div onClick={() => {
                                                                                                    budget.edit = "budget";
                                                                                                    this.setState({
                                                                                                        channel_budgets: this.state.channel_budgets
                                                                                                    });
                                                                                                }} className="text-purple-500 hover:text-purple-700 cursor-pointer">Edit (Budget)</div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            !this.state.selected_client[channel.value].autoRenewBudget &&
                                                                                            <div className="pr-6 text-sm font-medium">
                                                                                                <div onClick={() => {
                                                                                                    budget.edit = "endDate";
                                                                                                    this.setState({
                                                                                                        channel_budgets: this.state.channel_budgets
                                                                                                    });
                                                                                                }} className="text-purple-500 hover:text-purple-700 cursor-pointer">Edit (End date)</div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            //!this.state.selected_client[channel.value].autoRenewBudget &&
                                                                                            <div className="text-sm font-medium">
                                                                                                <div onClick={() => {
                                                                                                    budget.edit = "remove";
                                                                                                    this.setState({
                                                                                                        channel_budgets: this.state.channel_budgets
                                                                                                    });
                                                                                                }} className="text-red-500 hover:text-red-700 cursor-pointer">Remove</div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        budget.edit == "endDate" &&
                                                                                        <div className="w-full mt-2 grid-cols-12 grid gap-4">
                                                                                            <div className="col-span-6">
                                                                                                <DropdownTailwind
                                                                                                    label={"Type (new)"}
                                                                                                    selected={channel.new_budget.periodType}
                                                                                                    options={[{ name: "Custom dates", value: "custom" }, { name: "Forever", value: "forever" }]}
                                                                                                    onChange={async (value) => {
                                                                                                        channel.new_budget.periodType = value;
                                                                                                        await this.promisedSetState({
                                                                                                            channels: this.state.channels
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className={"col-span-6"}>
                                                                                                <InputDatepickerTailwind
                                                                                                    label={"End date (new)"}
                                                                                                    placeholder={"Forever"}
                                                                                                    disabled={channel.new_budget.periodType && channel.new_budget.periodType.value == "forever"}
                                                                                                    value={channel.new_budget.periodType && channel.new_budget.periodType.value == "forever" ? "" : moment(channel.new_budget.endDate).format("YYYY-MM-DD")}
                                                                                                    onChange={async (value) => {
                                                                                                        channel.new_budget.endDate = value;
                                                                                                        await this.promisedSetState({
                                                                                                            channels: this.state.channels
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        budget.edit == "budget" &&
                                                                                        <div className="w-full mt-2 grid-cols-12 grid gap-4">
                                                                                            <div className="col-span-6">
                                                                                                <DropdownTailwind
                                                                                                    label={"Type (new)"}
                                                                                                    selected={channel.new_budget.budgetType}
                                                                                                    options={[{ name: "Custom budget", value: "custom" }, { name: "Unlimited", value: "unlimited" }]}
                                                                                                    onChange={async (value) => {
                                                                                                        channel.new_budget.budgetType = value;
                                                                                                        await this.promisedSetState({
                                                                                                            channels: this.state.channels
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className={"col-span-6"}>
                                                                                                <InputTailwind
                                                                                                    label={"Budget (new)"}
                                                                                                    value={channel.new_budget.budgetType && channel.new_budget.budgetType.value == "unlimited" ? "Unlimited" : channel.new_budget.budget}
                                                                                                    disabled={channel.new_budget.budgetType && channel.new_budget.budgetType.value == "unlimited"}
                                                                                                    onChange={async (value) => {
                                                                                                        channel.new_budget.budget = value;
                                                                                                        await this.promisedSetState({
                                                                                                            channels: this.state.channels
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (budget.edit == "budget" || budget.edit == "endDate" || budget.edit == "remove") &&
                                                                                        this.state.channel_budgets[channel.value] &&
                                                                                        this.state.channel_budgets[channel.value].update_error &&
                                                                                        <div className={"col-span-10 mt-4"}>
                                                                                            <div className="bg-red-100 p-4 rounded-md">
                                                                                                <div className="text-sm text-red-500">
                                                                                                    {this.state.channel_budgets[channel.value].update_error}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (budget.edit == "budget" || budget.edit == "endDate" || budget.edit == "remove") &&
                                                                                        <div className="mt-4 mb-2 flex flex-row">
                                                                                            <button className='bg-purple-500 mr-2 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                                                                                onClick={() => {
                                                                                                    if (!this.state.channel_budgets[channel.value].update_loading) {
                                                                                                        if (budget.edit == "remove") {
                                                                                                            this.functions.removeAccountBudget(channel.value, {
                                                                                                                id: budget.id,
                                                                                                                startDate: budget.start
                                                                                                            }, budget.edit);
                                                                                                        } else {
                                                                                                            this.functions.updateClientBudget(channel.value, {
                                                                                                                id: budget.id,
                                                                                                                periodType: channel.new_budget.periodType.value,
                                                                                                                budgetType: channel.new_budget.budgetType.value,
                                                                                                                budget: channel.new_budget.budget,
                                                                                                                endDate: channel.new_budget.endDate
                                                                                                            }, budget.edit);
                                                                                                        }
                                                                                                    }
                                                                                                }}>
                                                                                                {budget.edit == "remove" ? "Remove" : "Update"}
                                                                                                {
                                                                                                    this.state.channel_budgets[channel.value].update_loading &&
                                                                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </button>
                                                                                            <button className='bg-red-100  text-red-500 inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium focus:outline-none'
                                                                                                onClick={() => {
                                                                                                    budget.edit = false;
                                                                                                    this.setState({
                                                                                                        channel_budgets: this.state.channel_budgets
                                                                                                    });
                                                                                                }}>
                                                                                                Cancel
                                                                                            </button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            !(this.state.selected_client[channel.value] && this.state.selected_client[channel.value].autoRenewBudget) &&
                                                            this.state.channel_budgets[channel.value] &&
                                                            !this.state.channel_budgets[channel.value].create &&
                                                            <div className="col-span-10">
                                                                <div onClick={() => {
                                                                    this.state.channel_budgets[channel.value].create = true;
                                                                    this.setState({
                                                                        channel_budgets: this.state.channel_budgets
                                                                    });
                                                                }} className={(true ? "cursor-pointer hover:text-purple-600 text-purple-500 " : " cursor-not-allowed text-gray2-600") + " text-sm cursor-pointer font-medium  inline-flex flex-row"}>
                                                                    <PlusIcon className="w-5 mr-2" />
                                                                    Add new budget
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            !(this.state.selected_client[channel.value] && this.state.selected_client[channel.value].autoRenewBudget) &&
                                                            this.state.channel_budgets[channel.value] &&
                                                            this.state.channel_budgets[channel.value].create &&
                                                            <div className="col-span-2">
                                                                <DropdownTailwind
                                                                    label={"Type (new)"}
                                                                    selected={channel.new_budget.periodType}
                                                                    options={[{ name: "Custom dates", value: "custom" }, { name: "Forever", value: "forever" }]}
                                                                    onChange={async (value) => {
                                                                        channel.new_budget.periodType = value;
                                                                        await this.promisedSetState({
                                                                            channels: this.state.channels
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            !(this.state.selected_client[channel.value] && this.state.selected_client[channel.value].autoRenewBudget) &&
                                                            this.state.channel_budgets[channel.value] &&
                                                            this.state.channel_budgets[channel.value].create &&
                                                            <div className="col-span-2">
                                                                <InputDatepickerTailwind
                                                                    label={"Start date (new)"}
                                                                    value={moment(channel.new_budget.startDate).format("YYYY-MM-DD")}
                                                                    onChange={async (value) => {
                                                                        channel.new_budget.startDate = value;
                                                                        await this.promisedSetState({
                                                                            channels: this.state.channels
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            !(this.state.selected_client[channel.value] && this.state.selected_client[channel.value].autoRenewBudget) &&
                                                            this.state.channel_budgets[channel.value] &&
                                                            this.state.channel_budgets[channel.value].create &&
                                                            <div className="col-span-2">
                                                                <InputDatepickerTailwind
                                                                    label={"End date (new)"}
                                                                    placeholder={"Forever"}
                                                                    disabled={channel.new_budget.periodType && channel.new_budget.periodType.value == "forever"}
                                                                    value={channel.new_budget.periodType && channel.new_budget.periodType.value == "forever" ? "" : moment(channel.new_budget.endDate).format("YYYY-MM-DD")}
                                                                    onChange={async (value) => {
                                                                        channel.new_budget.endDate = value;
                                                                        await this.promisedSetState({
                                                                            channels: this.state.channels
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            !(this.state.selected_client[channel.value] && this.state.selected_client[channel.value].autoRenewBudget) &&
                                                            this.state.channel_budgets[channel.value] &&
                                                            this.state.channel_budgets[channel.value].create &&
                                                            <div className="col-span-2">
                                                                <DropdownTailwind
                                                                    label={"Type (new)"}
                                                                    selected={channel.new_budget.budgetType}
                                                                    options={[{ name: "Custom budget", value: "custom" }, { name: "Unlimited", value: "unlimited" }]}
                                                                    onChange={async (value) => {
                                                                        channel.new_budget.budgetType = value;
                                                                        await this.promisedSetState({
                                                                            channels: this.state.channels
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            !(this.state.selected_client[channel.value] && this.state.selected_client[channel.value].autoRenewBudget) &&
                                                            this.state.channel_budgets[channel.value] &&
                                                            this.state.channel_budgets[channel.value].create &&
                                                            <div className={"col-span-2"}>
                                                                <InputTailwind
                                                                    label={"Budget (new)"}
                                                                    value={channel.new_budget.budgetType && channel.new_budget.budgetType.value == "unlimited" ? "Unlimited" : channel.new_budget.budget}
                                                                    disabled={channel.new_budget.budgetType && channel.new_budget.budgetType.value == "unlimited"}
                                                                    onChange={async (value) => {
                                                                        channel.new_budget.budget = value;
                                                                        await this.promisedSetState({
                                                                            channels: this.state.channels
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            !(this.state.selected_client[channel.value] && this.state.selected_client[channel.value].autoRenewBudget) &&
                                                            this.state.channel_budgets[channel.value] &&
                                                            this.state.channel_budgets[channel.value].create &&
                                                            <div className="col-span-10 flex flex-row">
                                                                <button className='bg-purple-500 mr-2 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                                                    onClick={() => {
                                                                        if (!this.state.channel_budgets[channel.value].create_loading) {
                                                                            this.functions.createAccountBudget(channel.value, channel.new_budget);
                                                                        }
                                                                    }}>
                                                                    Create
                                                                    {
                                                                        this.state.channel_budgets[channel.value].create_loading &&
                                                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button className='bg-red-100  text-red-500 inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium focus:outline-none'
                                                                    onClick={() => {
                                                                        this.state.channel_budgets[channel.value].create = false;
                                                                        this.setState({
                                                                            channel_budgets: this.state.channel_budgets
                                                                        });
                                                                    }}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.channel_budgets[channel.value] &&
                                                            this.state.channel_budgets[channel.value].create_error &&
                                                            <div className={"col-span-10"}>
                                                                <div className="bg-red-100 p-4 rounded-md">
                                                                    <div className="text-sm text-red-500">
                                                                        {this.state.channel_budgets[channel.value].create_error}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </SlideDown>
                                    </>
                                    {
                                        !this.props.channel &&
                                        <div className="border-b w-full mt-4 mb-4"></div>
                                    }
                                </Fragment>
                            )
                        })

                    }

                </div>
            </>
        )
    }
}

export default CreateAccountBudget;