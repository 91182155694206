import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, FolderIcon, MinusIcon, PlusIcon, XIcon } from '@heroicons/react/outline'

class AddCampaignModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            channel: "",
            option: null,
            campaign: null,
            creative_options: {},
            sub_options: {
                "google": [
                    { id: 1, name: "Search", value: "search", description: "Get in front of high-intent customers at the right time on Google Search", available: true },
                    { id: 2, name: "Performance max", value: "performance_max", description: "Reach audiences across all of Google with a single campaign", available: true },
                    { id: 3, name: "Display Network", value: "display", description: "Reach customers across 3 million sites and apps with engaging creative", available: true },
                    { id: 4, name: "Shopping", value: "shopping", description: "Showcase your products to shoppers as they explore what to buy", available: true },
                    { id: 5, name: "Video", value: "video", description: "Reach viewers on YouTube and get conversions (preview only)", available: true },
                    { id: 6, name: "App", value: "app", description: "Drive downloads and grow engagement for your app", available: false },
                    { id: 7, name: "Smart", value: "smart", description: "Reach customers with a one-stop solution built for small businesses", available: false },
                    { id: 8, name: "Discovery", value: "discovery", description: "Run ads on YouTube, Gmail, Discover, and more", available: false }
                ],
                "facebook": [
                    { id: 1, name: "Awareness", value: "awareness", description: "Show your ads to people who are most likely to remember them.", available: true },
                    { id: 2, name: "Traffic", value: "traffic", description: "Send people to a destination, like your website, app or Facebook event", available: true },
                    { id: 3, name: "Engagement", value: "engagement", description: "Get more messages, video views, post engagement, Page likes or event responses.", available: true },
                    { id: 4, name: "Leads", value: "leads", description: "Collect leads for your business or brand.", available: true },
                    { id: 5, name: "App promotion", value: "app_promotion", description: "Find new people to install your app and continue using it", available: true },
                    { id: 6, name: "Sales", value: "sales", description: "Find people likely to purchase your product or service.", available: true },
                ],
                "twitter": [
                    { id: 3, name: "Reach", value: "reach", description: "Maximize your ad's reach", available: true },
                    { id: 6, name: "Video views", value: "video_views", description: "Get people to watch your video", available: true },
                    //{ id: 6, name: "Preroll views", value: "preroll_views", description: "", available: true },
                    { id: 2, name: "App installs", value: "app_installs", description: "Get people to install your app", available: true },
                    { id: 8, name: "Website traffic", value: "website_clicks", description: "Drive traffic to your website", available: true },
                    { id: 5, name: "Engagements", value: "engagements", description: "Get people to engage with your Tweet", available: true },
                    { id: 4, name: "Followers", value: "followers", description: "Build an audience for your account", available: true },
                    { id: 1, name: "App re-engagements", value: "app_engagement", description: "Get people to take action in your app", available: true },
                ],
                "tiktok": [
                    { id: 1, name: "Reach", value: "reach", description: "Show your ad to the maximum number of people", available: true },
                    { id: 2, name: "Traffic", value: "traffic", description: "Send more people to a destination on your website or app", available: true },
                    //{ id: 3, name: "Video views", value: "video_views", description: "Get more views and engagement for your video ads", available: true },
                    //{ id: 4, name: "Lead generation", value: "video_views", description: "Collect leads for your business or brand", available: true },
                    //{ id: 5, name: "Community interaction", value: "community", description: "Get more followers or profile visits", available: true },
                    { id: 7, name: "Website conversion", value: "website_conversions", description: "Drive valuable actions on your website", available: true },
                    { id: 6, name: "App promotion", value: "app_promotion", description: "Get more users to install and engage with your app", available: true },
                ],
                "snapchat": [
                    { id: 1, name: "Awareness & engagement", value: "AWARENESS_AND_ENGAGEMENT", description: "Reach Snapchatters and create interest in your brand", available: true },
                    // { id: 2, name: "Promote Places", value: "promote_places", description: "Promote your places to Snapchatters", available: true },
                    { id: 4, name: "Traffic", value: "TRAFFIC", description: "Send Snapchatters to a destination like a website or app", available: true },
                    // { id: 5, name: "Drive Traffic To App", value: "traffic_app", description: "Send Snapchatters to your app or a third-party app", available: true },
                    //{ id: 6, name: "Engagement", value: "engagement", description: "Get more Snapchatters to engage with your ad", available: true },
                    // { id: 7, name: "Video Views", value: "video_views", description: "Promote your brand or product to Snapchatters through video", available: true },
                    { id: 8, name: "Leads", value: "LEADS", description: "Get Snapchatters to share their contact details with your business", available: true },
                    // { id: 9, name: "App Conversions", value: "app_conversions", description: "Drive specific actions within your app", available: true },
                    { id: 10, name: "Sales (Comming soon)", value: "SALES", description: "Get Snapchatters to buy your product or service.", available: true },

                    { id: 3, name: "App Promotion", value: "APP_PROMOTION", description: "Get Snapchatters to install and take actions in your app", available: true },
                    // { id: 11, name: "Catalog Sales", value: "catalog_sales", description: "Choose which product catalog you want to use in this campaign", available: true }
                    // ?? MAYBE NEEDS PIXEL IDS ??
                ],
                "linkedin": [
                    { id: 1, name: "Brand awareness", value: "brand_awareness", description: "I want more people to learn about my business", available: true },
                    { id: 2, name: "Website visits", value: "website_visits", description: "I want more people to visit a website destination site on or off LinkedIn.", available: true },
                    { id: 3, name: "Engagement", value: "engagement", description: "I want more people to engage with my content posts.", available: true },
                    { id: 4, name: "Video views", value: "video_views", description: "I want more people to watch my videos", available: true },
                    { id: 5, name: "Lead generation", value: "lead_generation", description: "I want more quality leads on LinkedIn.", available: true },
                    { id: 6, name: "Website conversions", value: "website_conversions", description: "I want more purchases, registrations, or downloads.", available: true },
                    { id: 7, name: "Jobb applications", value: "jobb_applications", description: "I want to promote job opportunities at my company.", available: true },
                ]
            }
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            open: this.props.open,
            channel: this.props.channel,
            campaign: this.props.campaign
        })
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            open: nextProps.open,
            channel: nextProps.channel,
            campaign: nextProps.campaign
        });
        if (nextProps.open !== this.state.open) {
            this.setState({
                option: null,
                creative_options: {},
            })
        }
    }

    promisedSetState = (newState, dontSave) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" style={{ zIndex: "999" }} className="fixed inset-0 overflow-y-auto" onClose={() => {
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="flex flex-row items-center">
                                        <div className="">
                                            {
                                                !this.state.campaign &&
                                                <Dialog.Title as="h3" className="text-2xl flex-1 flex leading-6 font-semibold text-gray-900">
                                                    New campaign
                                                </Dialog.Title>
                                            }
                                            {
                                                this.state.campaign &&
                                                <Dialog.Title as="h3" className="text-2xl flex-1 flex leading-6 font-semibold text-gray-900">
                                                    New adgroup
                                                </Dialog.Title>
                                            }
                                        </div>
                                        <div className="flex flex-1"></div>
                                        <div onClick={() => {
                                            if (this.props.onClose) {
                                                this.props.onClose();
                                            }
                                        }} className="ml-3 cursor-pointer h-12 w-12 flex justify-center transition-colors ease-in duration-75 hover:bg-red-500 hover:text-white items-center rounded-full bg-red-100 text-red-500">
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="">
                                        {
                                            (!this.state.campaign || (this.state.campaign && this.state.channel === "linkedin")) &&
                                            <div className="text-md font-medium mt-4">
                                                Select type for <span className="capitalize">{this.state.channel}</span>
                                            </div>
                                        }
                                        {
                                            (!this.state.campaign || (this.state.campaign && this.state.channel === "linkedin")) &&
                                            <div className={"gap-4 grid pt-4 grid-cols-3"}>
                                                {
                                                    this.state.sub_options[this.state.channel] &&
                                                    this.state.sub_options[this.state.channel].filter((item) => { return item.available }).map((item) => {
                                                        return (
                                                            <div onClick={() => {

                                                                if ((item.value === "app_promotion" && item.id === 6 && false) || (item.value === "app_installs" && item.id === 3)) {//ONLY TIKTOK HAVE COMBINATION ID-6 AND APPPROMOTION

                                                                } else {
                                                                    this.setState({
                                                                        sub_option: item,
                                                                        creative_options: {}
                                                                    });
                                                                }

                                                            }} className={(this.state.sub_option && this.state.sub_option.value === item.value ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + ' col-span-1 h-20 flex-row shadow-sm border-1.5 relative rounded-md items-center flex px-2 cursor-pointer  '}>
                                                                <div className="flex flex-1 flex-col pl-4 text-left justify-start items-start">
                                                                    <div className={(item.value === "app_promotion" && item.id === 6 && false) || (item.value === "app_installs" && item.id === 3) ? "text-xs font-medium text-gray-500 justify-start items-start" : "text-xs font-medium text-gray-900 justify-start items-start"}>
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="text-xs font-medium text-gray-500 justify-start items-start">
                                                                        {item.description}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        {/* LINKEDIN CREATIVE OPTIONS */}
                                        {
                                            this.state.sub_option && this.state.channel === "linkedin" &&
                                            < div className="mt-4">
                                                <div className="text-md font-medium">
                                                    Ad format
                                                </div>
                                                <div className={"gap-4 grid pt-4 grid-cols-3"}>
                                                    {
                                                        [
                                                            { name: "Single image ad", description: "Create ads using a single image that will show up in the news feed.", value: "image" },
                                                            { name: "Carousel image ad", description: "Create ads with 2 or more images that will show up in the news feed.", value: "carousel" },
                                                            { name: "Video ad", description: "Create ads using a video that will show up in the news feed.", value: "video" }
                                                        ].map((item) => {
                                                            return (
                                                                <div onClick={() => {
                                                                    if (this.state.creative_options[item.value]) {
                                                                        delete this.state.creative_options[item.value];
                                                                    } else {
                                                                        this.state.creative_options[item.value] = item;
                                                                    }
                                                                    this.setState({
                                                                        creative_options: this.state.creative_options
                                                                    })
                                                                }} className={(this.state.creative_options[item.value] ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + ' col-span-1 flex-row h-20 shadow-sm border-1.5 relative rounded-md items-center flex px-2 cursor-pointer  '}>
                                                                    <div className="flex flex-1 flex-col pl-4 text-left justify-start items-start">
                                                                        <div className="text-xs font-medium text-gray-900 justify-start items-start">
                                                                            {item.name}
                                                                        </div>
                                                                        <div className="text-xs font-medium text-gray-500 justify-start items-start">
                                                                            {item.description}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex border-t">
                                    <div className="flex-1 hidden sm:flex"></div>
                                    <button
                                        onClick={() => {
                                            if (((this.state.sub_option && this.state.channel !== "linkedin") || (this.state.channel === "linkedin" && this.state.sub_option && Object.keys(this.state.creative_options).length > 0))) {
                                                if (this.props.onSubmit) {
                                                    this.props.onSubmit({ sub_option: this.state.sub_option, creative_options: this.state.creative_options, campaign: this.state.campaign });
                                                }
                                            }
                                        }}
                                        className={(((this.state.sub_option && this.state.channel !== "linkedin") || (this.state.channel === "linkedin" && this.state.sub_option && Object.keys(this.state.creative_options).length > 0)) ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        {
                                            this.state.sub_option &&
                                            (this.state.channel !== "linkedin") &&
                                            <span>Add 1 campaign and 1 adgroup</span>
                                        }
                                        {
                                            this.state.sub_option &&
                                            !this.state.campaign &&
                                            (this.state.channel === "linkedin" && Object.keys(this.state.creative_options).length > 0) &&
                                            <span>Add 1 campaign and {Object.keys(this.state.creative_options).length} adgroup{Object.keys(this.state.creative_options).length < 2 ? "" : "s"}</span>
                                        }
                                        {
                                            this.state.sub_option &&
                                            this.state.campaign &&
                                            (this.state.channel === "linkedin" && Object.keys(this.state.creative_options).length > 0) &&
                                            <span>Add {Object.keys(this.state.creative_options).length} adgroup{Object.keys(this.state.creative_options).length < 2 ? "" : "s"}</span>
                                        }
                                        {
                                            this.state.sub_option &&
                                            (this.state.channel === "linkedin" && Object.keys(this.state.creative_options).length < 1) &&
                                            <span>Select Ad format</span>
                                        }
                                        {
                                            !this.state.sub_option &&
                                            <span>Select Type</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default AddCampaignModal;
