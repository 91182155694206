import React, { Component, Fragment } from 'react';
import Pusher from 'pusher-js';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon, XCircleIcon, ChevronUpIcon, TrashIcon, PhotographIcon, FilmIcon, SwitchVerticalIcon, SparklesIcon, StopIcon, BanIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import UploadImageTailwind from './uploadImageTailwind';
import UploadVideoTailwind from './uploadVideoTailwind';
import tiktokCTA from '../assets/json/tiktok_cta.json';
import PreviewTikTokFeed from '../previews/tiktok/feed';
import PreviewSectionTikTok from './previewSectionTikTok';
import { adTiktok } from '../validators/adTiktok';

var pusherService = null;

class EditLiveTikTokAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            identity_name: "",
            identity_image: {},
            new_identity: false,
            open: false,
            is_post: false,
            ad: {},
            updated: {},
            client: {},
            pages: [],
            loader: false,
            wizard: false,
            channel: { id: 1, name: "Tik Tok" },
            call_to_actions: tiktokCTA,
            wizardId: 0,
            selectedAsset: 0,
            preview_data: {},
            assetItems: [],
        }
    };

    async componentDidMount() {

        pusherService = new Pusher('a07698cf59a1788c44d4', {
            cluster: 'eu'
        });

        await this.promisedSetState({
            ad: this.props.ad,
            client: this.props.client,
            //wizard: this.props.wizard,
            //assetItems: this.props.assetItems,
            //wizardId: this.props.wizardId
        })
        console.log(this.state.ad, "TIKTOK AD=====");

        this.functions.listTikTokIdentities();

        /*
                this.setState({
                    is_post: (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "posts") ? true : false
                });
                //LIST IDENTITIES
                if (this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.length > 0) {
                    this.props.onUpdatedChat(this.state.updated.OpenAiResult);
                }
                */
        this.functions.updatePreview();

    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            //ad: nextProps.ad,
            client: nextProps.client,
            //wizard: nextProps.wizard,
            //assetItems: nextProps.assetItems,
            //wizardId: nextProps.wizardId
        })
        if (nextProps.updated) {
            await this.promisedSetState({
                updated: nextProps.updated
            })
        }
        //this.functions.updatePreview();
    }

    functions = {
        onSelectFile: async (e, slide = false) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                        await this.functions.uploadVideo(files[i], slide);
                    } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                        await this.functions.uploadImage(files[i], slide);
                    }
                    const randomString = Math.random().toString(36);
                    await this.promisedSetState({
                        theInputKeyOne: randomString + 1
                    });
                }
            }
            this.functions.updatePreview();
        },
        uploadImage: async (file, slide) => {
            return new Promise(async (resolve) => {
                let files_object = {
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    loading: true,
                    name: file.name,
                    name_by_channels: {
                        facebook: file.name,
                        linkedin: file.name,
                        tiktok: file.name,
                        twitter: file.name,
                        snapchat: file.name
                    },
                    type: 'image'
                };

                files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
                files_object.allowed_types = { regular: true };
                files_object.allowed_multiple = true;

                this.state.assetItems.push(files_object);
                //this.state.updated.file = { ...this.state.updated.file,  };
                await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    let response = await this.calls.image(formData);
                    this.state.assetItems = this.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await this.promisedSetState({ assetItems: this.state.assetItems });
                    //await this.props.updateAssets(this.state.assetItems);
                    if (!slide) {
                        this.state.updated.file = { ...this.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                        this.state.updated.picture = response.data.url;
                    } else {
                        this.state.updated.slides = this.state.updated.slides.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.image = true;
                                item.video = false;
                                item.picture = response.data.url;
                                item.url = response.data.url;
                            }
                            return item;
                        });
                    }
                    await this.promisedSetState({ updated: this.state.updated, selectedAsset: response.data.id, loader: false });

                } catch (error) {
                    this.state.assetItems = this.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
                }
                resolve()
            });
        },
        uploadVideo: async (file, slide) => {

            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                name_by_channels: {
                    facebook: file.name,
                    linkedin: file.name,
                    tiktok: file.name,
                    twitter: file.name,
                    snapchat: file.name
                },
                type: 'video'
            };

            files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
            files_object.allowed_types = { regular: true };
            files_object.allowed_multiple = true;

            this.state.assetItems.push(files_object);
            //this.state.updated.file = { ...this.state.updated.file, ...files_object };
            await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });

            let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
            let self = this;
            var channel = pusherService.subscribe(channel_id);
            channel.bind('videoUpload', async function (response) {
                if (response && response.status === 200) {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems });
                    //await self.props.updateAssets(self.state.assetItems);
                    if (!slide) {
                        self.state.updated.file = { ...self.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                        self.state.updated.video = response.data.url;
                    } else {
                        self.state.updated.slides = self.state.updated.slides.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.url = response.data.url;
                                item.image = false;
                                item.video = true;
                            }
                            return item;
                        });
                        self.state.updated.files = self.state.updated.files.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.url = response.data.url;
                                item.image = false;
                                item.video = true;
                            }
                            return item;
                        });
                    }
                    await self.promisedSetState({ updated: self.state.updated, selectedAsset: response.data.id, loader: false });
                    self.functions.updatePreview();

                } else {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems, loader: false });
                }
                try {
                    pusherService.unsubscribe(channel_id);
                } catch (error) {
                    console.log(error);
                }
            });

            try {
                const formData = new FormData();
                formData.append('file', file);
                await this.calls.video_upload(formData, channel_id);
            } catch (error) {
                this.state.assetItems = this.state.assetItems.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
                //await this.props.updateAssets(this.state.assetItems);

            }
        },
        update: async () => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });

                if (Object.keys(this.state.updated).length > 0) {
                    let body = {
                        adgroup_id: this.state.ad.origin_data.adgroup_id,
                        advertiser_id: this.state.ad.origin_data.advertiser_id,
                        creatives: [this.state.ad.origin_data]
                    };
                    if (this.renders.destinationType().value === "app") {
                        body.patch_update = true;
                        body.creatives[0]= {
                            ad_id: this.state.ad.origin_data.ad_id,
                        };
                    }
                    ["campaign_name", "campaign_id", "adgroup_name", "adgroup_id", "secondary_status", "create_time", "is_aco", "is_new_structure", "modify_time", "advertiser_id", "avatar_icon_web_uri", "ad_texts"].map((item) => {
                        delete body.creatives[0][item];

                    });
                    Object.keys(body.creatives[0]).map((item) => {
                        if (body.creatives[0][item] === null || body.creatives[0][item] === "") {
                            delete body.creatives[0][item];
                        }

                    });


                    if (this.state.updated.name) {
                        body.creatives[0].ad_name = this.state.updated.name;
                    }
                    if (this.state.updated.body) {
                        body.creatives[0].ad_text = this.state.updated.body;
                    }
                    if (this.state.updated.link) {
                        body.creatives[0].landing_page_url = this.state.updated.link;
                    }
                    if (this.state.updated.cta) {
                        body.creatives[0].call_to_action = this.state.updated.cta.id;
                    }
                    if (this.state.updated.impression_tracking_url) {
                        body.creatives[0].impression_tracking_url = this.state.updated.impression_tracking_url;
                    }
                    if (this.state.updated.click_tracking_url) {
                        body.creatives[0].click_tracking_url = this.state.updated.click_tracking_url;
                    }
                    if (this.state.updated.page) {
                        body.creatives[0].identity_id = this.state.updated.page.id;
                        body.creatives[0].identity_type = this.state.updated.page.type;
                    }

                    if ("file" in this.state.updated) {
                        try {
                            let resp = await this.calls.uploadCreatives(this.state.updated.file.id);
                            body.creatives[0].video_id = resp.data.tiktok.id;
                            body.creatives[0].image_ids = [resp.data.tiktok.thumbnail]
                        } catch (error) {
                            await this.promisedSetState({
                                error: error.body.message
                            });
                            body = {};
                        }
                    }
                    console.log("PROPS CAMPAIGN", this.props.campaign)
                    body.changelog = {
                        // initial_data: { ...this.state.ad, campaign_id: this.state.origin_data.campaign_id },
                        initial_data: this.state.ad,
                        updated_data: this.state.updated,
                        level: "ad",
                        order: window.location.pathname.split("/").pop(),
                        ...this.props.campaign ? { campaign_id: this.props.campaign } : {}
                    }
                    if (Object.keys(body).length > 0) {
                        try {
                            let response = await this.calls.updateLiveAd(body);
                            if (response.data[0].code && response.data[0].code !== 0) {
                                await this.promisedSetState({
                                    error: response.data[0].message
                                });
                            }
                        } catch (error) {
                            await this.promisedSetState({
                                error: "Something went wrong"
                            });
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                }
                if (!this.state.error) {
                    this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
                this.state.updated.name = this.renders.name();
                this.state.updated.body = this.renders.body();
                this.state.updated.destination_type = this.renders.destinationType();
                this.state.updated.link = this.renders.link();
                this.state.updated.page = this.renders.page();
                this.state.updated.page = this.renders.page();
                this.props.onUpdate(this.state.updated);
            }
        },
        listTikTokIdentities: async () => {
            await this.promisedSetState({ loading_tiktok: true });
            try {
                let response = await this.calls.tikTokIdentities();
                this.promisedSetState({
                    pages: response.data,
                    loading_tiktok: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_tiktok: false });
            }
        },
        create_identity: async () => {
            let data = {
                url: this.state.identity_image.url,
                name: this.state.identity_name
            }
            await this.promisedSetState({ loading_identity: true });
            try {
                let response = await this.calls.createtikTokIdentities(data);
                this.state.pages.push(response.data);
                this.state.updated.page = response.data;
                this.promisedSetState({
                    loading_identity: false,
                    new_identity: false,
                    pages: this.state.pages,
                    updated: this.state.updated
                });

            } catch (error) {
                await this.promisedSetState({ loading_identity: false });
            }
            this.functions.updatePreview();

        },
        updatePreview: async () => {
            let preview_data = {
                merge: false,
                carousel: false,
                catalog: false,
                files: [],
                creative: this.state.ad && this.state.ad.creative ? this.state.ad.creative : false,
                // description: this.renders.description(),
                // caption: this.renders.caption(),
                preview_channel: "tiktok",
                previews: { tiktok: [{ id: 1, value: "video_feed", name: "Feed" }] },
                preview: { value: "video_feed", name: "Feed" },
                utm_channels: ["tiktok"],
                channel_menu: "tiktok",
                validation: { tiktok: false },
                tiktok_app: this.renders.destinationType().value === "app" ? true : false,
            };

            if (preview_data.creative) {
                preview_data.creative.page = this.renders.page();
                preview_data.creative.preview = { value: "video_feed", name: "Feed" };
                preview_data.creative.text = this.renders.body();
                preview_data.creative.link = this.renders.link();
                preview_data.creative.call_to_action = typeof this.renders.cta() === 'object' ? this.renders.cta().id : this.renders.cta();
                if (this.state.updated.file) {
                    preview_data.creative.video = this.state.updated.video;
                }
                /*
                if (this.state.ad.creative_type === "image") {
                    preview_data.creative.image = this.state.ad.image_url;
                } else if (this.state.ad.creative_type === "video") {
                    preview_data.creative.video = this.state.ad.video_url;
                }*/

            }
            try {

                console.log(preview_data);
                this.props.updatePreview(preview_data)
            } catch (error) {
                console.log(error)
            }
        },
        updateOpenAi: async (data) => {
            try {
                this.state.updated.OpenAiResult = data;
                await this.promisedSetState({
                    updated: this.state.updated
                })
            } catch (error) {
                console.log(error)
            }
        },
        addHeadlines: async (data) => {
            try {
                if (this.state.updated && !this.state.updated.type !== "posts") {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].force) {
                            this.state.updated.title = data[i].value[0];
                        } else {
                            if (!this.state.carousel) {
                                if (this.renders.name() === "") {
                                    this.state.updated.title = data[i].value[0];
                                }
                            }
                        }
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    });
                    this.functions.updatePreview();
                } else {
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        },
        addDescriptions: async (data) => {
            try {
                if (this.state.updated && !this.state.updated.is_post) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].force) {
                            this.state.updated.body = data[i].value[0];
                        } else {
                            if (this.renders.body() === "") {
                                this.state.updated.body = data[i].value[0];
                            }
                        }
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    });
                    this.functions.updatePreview();
                } else {
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        },
        removeDescription: async () => {
            try {
                this.state.updated.body = "";
                await this.promisedSetState({
                    updated: this.state.updated
                });
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
        removeHeadline: async () => {
            try {
                this.state.updated.title = "";
                await this.promisedSetState({
                    updated: this.state.updated
                });
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
    };

    renders = {
        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.ad.status === 'ACTIVE') {
                    return { id: 1, name: 'Active', value: "ACTIVE" }
                } else if (this.state.ad.status === 'PAUSED') {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                } else if (this.state.ad.status === 'DELETED') {
                    return { id: 3, name: 'Deleted', value: "DELETED" }
                } else if (this.state.ad.status === 'ARCHIVED') {
                    return { id: 4, name: 'Archived', value: "ARCHIVED" }
                } else {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 2, name: 'Paused', value: "PAUSED" }
            }
        },
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else {
                    return this.state.ad.name;
                }
            } catch (error) {
                return "";
            }
        },
        body: () => {
            try {
                if ('body' in this.state.updated) {
                    return this.state.updated.body;
                } else {
                    return this.state.ad.creative.text;
                }
            } catch (error) {
                return "";
            }
        },
        destinationType: () => {
            try {
                if ("destination_type" in this.state.updated) {
                    return this.state.updated.destination_type;
                } else {
                    if (this.state.ad.destination_type) {
                        return this.state.ad.destination_type;
                    } else {
                        return { id: 1, name: 'Website', value: "website" };
                    }
                }
            } catch (error) {
                return { id: 1, name: 'Website', value: "website" };
            }
        },
        app_profile: () => {
            try {
                if ("app_profile" in this.state.updated) {
                    return this.state.updated.app_profile;
                } else {
                    if (this.state.ad.app_profile) {
                        return this.state.ad.app_profile;
                    } else {
                        return { id: 0, name: 'Select ...', value: false };
                    }
                }
            } catch (error) {
                return { id: 0, name: 'Select ...', value: false };
            }
        },
        link: () => {
            try {
                if ('link' in this.state.updated) {
                    return this.state.updated.link;
                } else {
                    return this.state.ad.creative.link;
                }
            } catch (error) {
                return "";
            }
        },
        page: () => {
            try {
                if ("page" in this.state.updated) {
                    return this.state.updated.page;
                } else {
                    return this.state.ad.creative.page;
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        video: () => {
            try {
                if (this.state.updated.type === "video") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.url;
                    } else {
                        return null
                    }
                } else if (this.state.updated.type === "posts") {
                    if ("video" in this.state.updated) {
                        return this.state.updated.video;
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        thumbnail: () => {
            try {
                if (this.state.updated.type === "video") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.thumbnail;
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        ctaType: () => {
            try {
                if ("cta_type" in this.state.updated) {
                    return this.state.updated.cta_type;
                } else {
                    return { id: 1, name: 'Standard', value: "standard" };
                }
            } catch (error) {
                return { id: 1, name: 'Standard', value: "standard" };
            }
        },
        cta: () => {
            try {
                if ("cta" in this.state.updated) {
                    return this.state.updated.cta;
                } else {
                    return this.state.ad.creative.call_to_action;
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        impressionTrackingUrl: () => {
            try {
                if ('impression_tracking_url' in this.state.updated) {
                    return this.state.updated.impression_tracking_url;
                } else {
                    return this.state.ad.impression_tracking_url;;
                }
            } catch (error) {
                return "";
            }
        },
        clickTrackingUrl: () => {
            try {
                if ('click_tracking_url' in this.state.updated) {
                    return this.state.updated.click_tracking_url;
                } else {
                    return this.state.ad.click_tracking_url;
                }
            } catch (error) {
                return "";
            }
        },
        type: () => {
            try {
                if (this.state.ad && this.state.ad.creative && this.state.ad.creative.ad_type) {
                    return this.state.ad.creative.ad_type;
                } else {
                    return this.state.ad.creative_type;
                }
            } catch (error) {
                return "";
            }
        },
    };

    calls = {
        uploadCreatives: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {}, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadCreativeChannels?client=" + this.state.client.id + "&tiktok=true&creative=" + id + "&id=" + id;
            return apiRegister.call(options, url);
        },
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id + "&wizard=" + this.state.wizardId;
            return apiRegister.call(options, url);
        },
        video_upload: (data, channel_id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&directUpload=true&large=true" + "&channel=" + channel_id;
            return apiRegister.call(options, url);
        },
        update: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/updateAdCreative?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        tikTokIdentities: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listIdentities?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createtikTokIdentities: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/createIdentity?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateLiveAd: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/updateAd?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                {
                    this.state.error &&
                    <div className="flex w-full bg-red-100 rounded-md p-4 text-sm font-medium text-red-500">
                        {typeof this.state.error === "string" ? this.state.error : "Something goes wrong"}
                    </div>
                }
                <div className='flex flex-col px-4 space-y-4 bg-gray-50 pb-4'>
                    {
                        this.state.ad && this.state.ad.origin_data && !this.state.ad.origin_data.tiktok_item_id &&
                        <div className="flex flex-row items-center space-x-4">
                            <div className='w-full'>
                                <DropdownTailwind
                                    label={"Select other asset"}
                                    channel={"tiktok"}
                                    loader={this.state.loader}
                                    locked={false}
                                    selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                    options={this.state.assetItems.map((item) => {
                                        return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                    })}
                                    onChange={async (value) => {
                                        this.state.updated.file = { ...this.state.updated.file, ...value };

                                        delete this.state.updated.picture;
                                        this.state.updated.video = value.video;
                                        this.state.updated.type = "video";


                                        await this.promisedSetState({
                                            updated: this.state.updated,
                                            selectedAsset: value.id
                                        });
                                        this.functions.updatePreview();
                                    }}
                                />
                            </div>
                            {
                                !this.state.loader &&
                                <div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
                                    <div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
                                        <input
                                            onChange={(e) => this.functions.onSelectFile(e)}
                                            type="file"
                                            accept={"video/*"}
                                            className="absolute z-55 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                        />
                                        {
                                            this.state.loader &&
                                            <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                <div className="ml-2 text-xs">
                                                    Loading ...
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.state.loader &&
                                            <div className="text-xs text-gray-600">
                                                <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    <div className="flex w-full space-x-4">
                        <div className='w-full'>
                            <InputTailwind
                                label={"Name"}
                                channel={"tiktok"}
                                value={this.renders.name()}
                                onChange={async (value) => {
                                    this.state.updated.name = value;
                                    await this.promisedSetState({
                                        updated: this.state.updated
                                    });
                                    this.functions.updatePreview();
                                }}
                            />
                        </div>
                        <div className='w-1/3'>
                            <InputTailwind
                                label={"Type"}
                                channel="tiktok"
                                value={this.renders.type() && typeof this.renders.type() === 'string' ? this.renders.type().charAt(0).toUpperCase() + this.renders.type().slice(1) : ''}
                                disabled={true}
                            />
                        </div>
                        {
                            true && //this.renders.type() && typeof this.renders.type() === 'string' && ["unknown", ""].includes(this.renders.type()) &&
                            <div className='w-1/3'>
                                <div>
                                    <label class="text-xs flex flex-row font-medium text-gray-700 ">
                                        <div class="mr-1">
                                            <div class="bg-tiktok-500 h-4 w-4 rounded-full flex justify-center items-center">
                                                <img className="w-2"
                                                    alt=""
                                                    src={require('../assets/images/tiktok_icon.png')} />
                                            </div>
                                        </div>
                                        <div class="flex-1  flex flex-row">External link</div>
                                    </label>
                                    <div class="mt-1 relative flex flex-row rounded-md">
                                        <div className="items-center text-sm bg-purple-500 hover:bg-purple-600 mr-2 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-3 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                            onClick={async () => {
                                                window.open("https://ads.tiktok.com/i18n/perf/creative?aadvid=" + this.state.ad.account + "&keyword=" + encodeURIComponent(this.state.ad.name) + "&search_type=3", "_blank");
                                            }}
                                        >
                                            <ExternalLinkIcon class="w-5 h-5 mr-2" />
                                            <span>Open</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="w-full text-lg font-semibold">
                        Select identity
                    </div>

                    <div className="w-full">
                        <DropdownTailwind
                            label={"Set custom identity"}
                            channel={"tiktok"}
                            selected={this.renders.page()}
                            locked={false}
                            options={this.state.pages}
                            onChange={async (value) => {
                                this.state.updated.page = value;
                                await this.promisedSetState({
                                    updated: this.state.updated
                                });
                                this.functions.updatePreview();
                            }}
                        />

                        {
                            true && //!this.state.is_post && //NEED TO FIX FOR POSTS
                            <div onClick={async () => {
                                await this.promisedSetState({
                                    new_identity: true
                                });
                            }} className="mt-2 text-sm font-medium text-purple-500 hover:text-purple-600 cursor-pointer">
                                Create new custom identity
                            </div>
                        }

                        {
                            this.state.new_identity &&
                            <>
                                <div className="flex w-full space-x-4 mt-4">
                                    <div className="w-full">
                                        <UploadImageTailwind
                                            label={"Identity image (Ratio 1:1)"}
                                            channel={"tiktok"}
                                            client={this.state.client}
                                            error={this.state.identity_image.ratio && this.state.identity_image.ratio !== "1:1" ? true : false}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    identity_image: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <InputTailwind
                                            label={"Identity name"}
                                            channel={"tiktok"}
                                            value={this.state.identity_name}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    identity_name: value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        onClick={() => {
                                            if (this.state.identity_image.url && this.state.identity_image.ratio === "1:1" && this.state.identity_name !== "") {
                                                this.functions.create_identity();
                                            }
                                        }}
                                        className={"inline-flex shadow relative justify-center rounded-md border border-transparent bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        Create identity
                                        {
                                            this.state.loading_identity &&
                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </>
                        }
                    </div>

                    <div className="w-full text-lg font-semibold">
                        Ad settings
                    </div>

                    <div className='flex w-full space-x-4'>
                        {
                            this.renders.destinationType().value === "website" &&
                            <div className="w-full">
                                <InputTailwind
                                    label={"Website"}
                                    channel={"tiktok"}
                                    value={this.renders.link()}
                                    onChange={async (value) => {
                                        this.state.updated.link = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                        this.functions.updatePreview();
                                    }}
                                />
                            </div>
                        }
                        {
                            this.renders.destinationType().value === "app" &&
                            <div className="w-full">
                                <DropdownTailwind
                                    label={"App profile"}
                                    channel={"tiktok"}
                                    selected={this.renders.app_profile()}
                                    locked={true}
                                    options={[

                                    ]}
                                    onChange={(value) => {

                                    }}
                                />

                            </div>
                        }

                        <div className='w-1/3'>
                            <DropdownTailwind
                                label={"Destination page"}
                                channel={"tiktok"}
                                selected={this.renders.destinationType()}
                                locked={true}
                                options={[
                                    { id: 1, name: 'Website', value: "website" }
                                ]}
                                onChange={(value) => {

                                }}
                            />
                        </div>
                    </div>

                    {
                        false && adTiktok.validate(this.state.updated).website &&
                        <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                            OBS! Prefix https:// is required.
                        </div>
                    }

                    <div className="w-full relative">
                        <TextAreaTailwind
                            label={"Text (Maximum 100 characters)"}
                            channel={"tiktok"}
                            maxLength={100}
                            value={this.renders.body()}
                            onChange={async (value) => {
                                this.state.updated.body = value;
                                await this.promisedSetState({
                                    updated: this.state.updated
                                });
                                this.functions.updatePreview();
                            }}
                        />
                        {
                            false && this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.filter((inner_item) => { return inner_item.channel === "tiktok" && inner_item.value.includes(this.renders.body()) }).length > 0 }).length > 0 &&
                            <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                <SparklesIcon className="h-5 w-5 text-purple-500" />
                            </div>
                        }
                    </div>

                    <div className="w-full text-lg font-semibold">
                        Select CTA
                    </div>

                    <div className="flex w-full space-x-4">
                        {
                            this.renders.ctaType().id === 1 &&
                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Call to action"}
                                    channel={"tiktok"}
                                    selected={typeof this.renders.cta() === "string" ? this.state.call_to_actions.find(cta => cta.id === this.renders.cta()) : this.renders.cta()}
                                    options={this.state.call_to_actions}
                                    onChange={async (value) => {
                                        this.state.updated.cta = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                        this.functions.updatePreview();
                                    }}
                                />
                            </div>
                        }
                        <div className="w-1/3">
                            <DropdownTailwind
                                label={"Call to action type"}
                                channel={"tiktok"}
                                selected={this.renders.ctaType()}
                                locked={true}
                                options={[
                                    { id: 1, name: 'Standard', value: "standard" },
                                    { id: 2, name: 'Dynamic', value: "dynamic" }
                                ]}
                                onChange={(value) => {

                                }}
                            />
                        </div>
                    </div>

                    <div className="w-full text-lg font-semibold">
                        Tracking settings
                    </div>

                    <div className="flex w-full space-x-4">
                        <div className='w-full'>
                            <InputTailwind
                                label={"Impression Tracking URL"}
                                value={this.renders.impressionTrackingUrl()}
                                locked={this.renders.destinationType().value === "app" ? true : false}
                                disabled={this.renders.destinationType().value === "app" ? true : false}
                                onChange={async (value) => {
                                    this.state.updated.impression_tracking_url = value;
                                    await this.promisedSetState({
                                        updated: this.state.updated
                                    });
                                }}
                            />
                        </div>
                        <div className='w-full'>

                            <InputTailwind
                                label={"Click Tracking URL"}
                                value={this.renders.clickTrackingUrl()}
                                locked={this.renders.destinationType().value === "app" ? true : false}
                                disabled={this.renders.destinationType().value === "app" ? true : false}
                                onChange={async (value) => {
                                    this.state.updated.click_tracking_url = value;
                                    await this.promisedSetState({
                                        updated: this.state.updated
                                    });
                                }}
                            />
                        </div>
                    </div>






                </div>
                {/* {
                    <div className="grid grid-cols-12">

                        {
                            this.state.updated &&
                            Array.isArray(this.state.updated.previews) &&
                            <div className="col-span-5 grid grid-cols-3 gap-4 content-start pr-6">
                                {
                                    (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type.value === "video_ad") &&
                                    <div className="col-span-3 grid grid-cols-3 gap-4">
                                        <div className="col-span-2">
                                            <DropdownTailwind
                                                label={"Select other asset"}
                                                loader={this.state.loader}
                                                locked={!this.state.wizard}
                                                selected={this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                                options={this.state.assetItems.filter((item) => { return item.type === "video" }).map((item) => {
                                                    return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                                })}
                                                onChange={async (value) => {
                                                    this.state.updated.file = { ...this.state.updated.file, ...value };
                                                    this.state.updated.video = value.video;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated,
                                                        selectedAsset: value.id
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-1 pt-5.5">
                                            <div key={this.state.theInputKeyOne || ''} className="flex justify-center border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input h-full">
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    accept={(this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
                                                    className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                                />
                                                <div className="space-y-1 text-center justify-center flex flex-col">
                                                    {
                                                        this.state.loader &&
                                                        <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                            <div className="ml-2 text-xs">
                                                                Loading ...
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.loader &&
                                                        <div className="text-xxs text-gray-600">
                                                            <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Preview"}
                                        selected={this.state.updated.preview}
                                        options={this.state.updated.previews}
                                        onChange={(value) => {
                                            this.state.updated.preview = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <PreviewSectionTikTok
                                        ad={this.state.updated}
                                        channel={{ value: "tiktok" }}
                                    />
                                </div>
                            </div>
                        }

                        {
                            !this.state.updated.new_preview &&
                            <div className="col-span-5 grid grid-cols-3 gap-4 content-start pr-6">
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Preview channel"}
                                        selected={this.state.channel}
                                        options={[{ id: 1, name: "Tik Tok" }]}
                                        onChange={(value) => {
                                            this.setState({
                                                channel: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3 ">
                                    <div style={{ backgroundColor: "#fcfcfc" }} className="border p-4 border-gray-300 rounded-md shadow-sm">
                                        <PreviewTikTokFeed
                                            video={this.renders.video()}
                                            text={this.renders.body()}
                                            profile={this.renders.page()}
                                            thumbnail={this.renders.thumbnail()}
                                            cta={this.renders.cta()}
                                            page={this.renders.page()}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-span-7 grid grid-cols-3 gap-4 content-start pb-10 pl-6">

                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Ad name"}
                                    error={adTiktok.validate(this.state.updated).name}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Set custom identity"}
                                    error={adTiktok.validate(this.state.updated).page}
                                    selected={this.renders.page()}
                                    locked={this.state.is_post}
                                    options={this.state.pages}
                                    onChange={(value) => {
                                        this.state.updated.page = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                                {
                                    !this.state.is_post &&
                                    <div onClick={async () => {
                                        await this.promisedSetState({
                                            new_identity: true
                                        });
                                    }} className="mt-2 text-sm font-medium text-purple-500 hover:text-purple-600 cursor-pointer">
                                        Create new custom identity
                                    </div>
                                }
                            </div>

                            {
                                this.state.new_identity &&
                                <div className="col-span-3 grid grid-cols-6 gap-4">
                                    <div className="col-span-3">
                                        <UploadImageTailwind
                                            label={"Identity image (Ratio 1:1)"}
                                            client={this.state.client}
                                            error={this.state.identity_image.ratio && this.state.identity_image.ratio !== "1:1" ? true : false}
                                            onChange={async (value) => {
                                                this.setState({
                                                    identity_image: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <InputTailwind
                                            label={"Identity name"}
                                            value={this.state.identity_name}
                                            onChange={(value) => {
                                                this.setState({
                                                    identity_name: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <button
                                            onClick={() => {
                                                if (this.state.identity_image.url && this.state.identity_image.ratio === "1:1" && this.state.identity_name !== "") {
                                                    this.functions.create_identity();
                                                }
                                            }}
                                            className={"inline-flex shadow relative justify-center rounded-md border border-transparent bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                        >
                                            Create identity
                                            {
                                                this.state.loading_identity &&
                                                <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                false &&
                                <div className="col-span-3">
                                    <UploadVideoTailwind
                                        label={"Change video"}
                                        channel={"tiktok"}
                                        client={this.state.client}
                                        onChange={async (value) => {
                                            delete this.state.updated.file;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                            this.state.updated.file = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-3">
                                <TextAreaTailwind
                                    label={"Text  (Maximum 100 characters)"}
                                    error={adTiktok.validate(this.state.updated).body}
                                    maxLength={100}
                                    value={this.renders.body()}
                                    onChange={(value) => {
                                        this.state.updated.body = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Destination page"}
                                    selected={this.renders.destinationType()}
                                    locked={true}
                                    options={[
                                        { id: 1, name: 'Website', value: "website" }
                                    ]}
                                    onChange={(value) => {

                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"URL"}
                                    error={adTiktok.validate(this.state.updated).website}
                                    value={this.renders.link()}
                                    onChange={(value) => {
                                        this.state.updated.link = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                                {
                                    adTiktok.validate(this.state.updated).website &&
                                    <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Prefix https:// is required.
                                    </div>
                                }
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Call to action type"}
                                    selected={this.renders.ctaType()}
                                    locked={true}
                                    options={[
                                        { id: 1, name: 'Standard', value: "standard" },
                                        { id: 2, name: 'Dynamic', value: "dynamic" }
                                    ]}
                                    onChange={(value) => {
                                        this.state.updated.cta_type = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.ctaType().id === 1 &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Call to action"}
                                        error={adTiktok.validate(this.state.updated).cta}
                                        selected={this.renders.cta()}
                                        locked={false}
                                        options={this.state.call_to_actions}
                                        onChange={(value) => {
                                            this.state.updated.cta = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }

                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Impression Tracking URL"}
                                    channel={"tiktok"}
                                    value={this.renders.impressionTrackingUrl()}
                                    onChange={(value) => {
                                        this.state.updated.impression_tracking_url = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Click Tracking URL"}
                                    channel={"tiktok"}
                                    value={this.renders.clickTrackingUrl()}
                                    onChange={(value) => {
                                        this.state.updated.click_tracking_url = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    } */}
            </>
        )
    }
}

export default EditLiveTikTokAd;
