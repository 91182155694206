import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import MultiDropdown from '../moduleFiles/multiDropdownTailwind';
import DropdownTailwind from "./dropdownTailwind";
import InputTailwind from './inputTailwind';
import { TrashIcon } from '@heroicons/react/outline';

class UpdateAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            throttling: {},
            extra_agencies: [],
            agencies: [],
            agency_search: '',
            agency_limit: 10,
            agency_page: 1,
            agencies_total: 0,
        };
    }

    async componentDidMount() {
        let agent = this.props.agent;
        agent.agency.image = agent.agency.logo;
        await this.promisedSetState({
            agent: agent,
            extra_agencies: Array.isArray(agent.extraAgencies) ? agent.extraAgencies : []
        });
        this.functions.getAgencies(true);
    }

    functions = {
        updateAgent: async () => {
            try {
                let extraAgencies = this.state.extra_agencies.map(agency => {
                    return {
                        id: agency.id,
                        name: agency.name,
                        image: agency.image
                    }
                });
                await this.calls.updateAgent({
                    extraAgencies: extraAgencies
                });
                this.props.onCreated({ id: this.state.agent.id, extraAgencies: extraAgencies });
            } catch (error) {
                this.setState({
                    error: error.body.message
                });
                this.props.onError();
            }
        },
        getAgencies: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agencies_search: search,
                loading_agencies_pagination: paginaton,
                loading_agencies: init
            });
            try {
                let response = await this.calls.getAgencies();
                if (!paginaton) {
                    await this.promisedSetState({
                        agencies: response.data.map((item) => { item.image = item.logo; return item }),
                        agencies_total: response.meta.total
                    });
                } else {
                    this.state.agencies = this.state.agencies.concat(response.data.map((item) => { item.image = item.logo; return item }));
                    await this.promisedSetState({
                        agencies: this.state.agencies,
                        agencies_total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agencies_search: false,
                loading_agencies_pagination: false,
                loading_agencies: false
            });
        }
    };

    calls = {
        updateAgent: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentAgencies?agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAgencies: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencies?limit=" + this.state.agency_limit + "&page=" + this.state.agency_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.agency_search !== "" ? ("&search=" + this.state.agency_search) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid">
                                <div className="col-span-12">
                                    <DropdownTailwind
                                        label={"Main agency"}
                                        selected={this.state.agent ? this.state.agent.agency : {}}
                                        options={[]}
                                        locked={true}
                                    />
                                </div>
                                <div className="col-span-12">
                                    <DropdownTailwind
                                        label={"Extra agencies"}
                                        placeholder={"Search ..."}
                                        searchInput={true}
                                        defaultSelected={{}}
                                        defaultOptions={[]}
                                        selected={{}}
                                        options={this.state.agencies}
                                        onChange={async (value) => {
                                            this.state.extra_agencies.push(value);
                                            this.setState({
                                                extra_agencies: this.state.extra_agencies
                                            });
                                        }}
                                        onPagination={async () => {
                                            if (!this.state.loading_agencies_pagination) {
                                                await this.promisedSetState({
                                                    agency_page: this.state.agency_page + 1
                                                });
                                                this.functions.getAgencies(false, true, false);
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    agency_search: "",
                                                    agency_page: 1
                                                });
                                                this.functions.getAgencies(false, false, true);
                                            } else {
                                                if (this.state.throttling.current) {
                                                    //SKIP
                                                } else {
                                                    if (value && value === "") {
                                                        await this.promisedSetState({
                                                            agency_search: value,
                                                            agency_page: 1
                                                        });
                                                        this.functions.getAgencies(false, false, true);
                                                    } else {
                                                        this.state.throttling.current = true;
                                                        await this.promisedSetState({
                                                            agency_search: value,
                                                            throttling: this.state.throttling
                                                        });
                                                        setTimeout(async () => {
                                                            this.state.throttling.current = false;
                                                            await this.promisedSetState({
                                                                throttling: this.state.throttling
                                                            });
                                                            await this.promisedSetState({
                                                                agency_page: 1
                                                            });
                                                            this.functions.getAgencies(false, false, true);
                                                        }, 300);
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-span-12">
                                    {
                                        this.state.extra_agencies.map((item, index) => {
                                            return (
                                                <div className={(index > 0 ? "mt-2" : "") + " flex flex-1"}>
                                                    <div className="mr-2 flex flex-1 items-center border-1.5 rounded-md p-2">
                                                        <div className="h-7 w-7 mr-3 rounded-full flex-shrink-0 overflow-hidden justify-center flex items-center border-1.5 bg-white">
                                                            {
                                                                item.image &&
                                                                <img src={item.image} alt="" className="w-auto" />
                                                            }
                                                        </div>
                                                        <div className="text-sm font-medium">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                    <div onClick={() => {
                                                        this.state.extra_agencies = this.state.extra_agencies.filter((inner_item) => {
                                                            return inner_item.id !== item.id;
                                                        });
                                                        this.setState({
                                                            extra_agencies: this.state.extra_agencies
                                                        });
                                                    }} className="h-12 w-12 rounded-md bg-red-500 hover:bg-red-600 text-white flex items-center cursor-pointer justify-center">
                                                        <TrashIcon className="w-5"></TrashIcon>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    this.state.error &&
                                    <div className={"col-span-12"}>
                                        <div className="bg-red-100 p-4 rounded-md">
                                            <div className="text-sm font-medium text-red-500">
                                                {this.state.error}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default UpdateAgent;