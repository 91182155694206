import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, InformationCircleIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';

import { campaignTiktok } from '../validators/campaignTiktok';
import SwitchTailwind from './switchTailwind';

class EditLiveTikTikCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            wizard: false,
            open: false,
            campaign: {},
            ios_options: [{ id: 0, name: "No", value: false }, { id: 0, name: "Yes", value: true }],
            adgroups: [],
            updated: {},
            client: {},
            loader: false,
            objectives: [
                { id: 1, name: 'Traffic', value: "TRAFFIC", goal: { id: 8, name: 'Click', value: "CLICK" }, billing_event: { id: 1, name: 'CPC', value: "CPC" } },
                { id: 2, name: 'App promotion', value: "APP_PROMOTION", goal: { id: 8, name: 'Click', value: "CLICK" }, billing_event: { id: 1, name: 'CPC', value: "CPC" } },
                { id: 3, name: 'Conversions', value: "CONVERSIONS", goal: { id: 5, name: 'Conversion', value: "CONVERT" }, billing_event: { id: 4, name: 'OCPM', value: "OCPM" } },
                { id: 4, name: 'Reach', value: "REACH", goal: { id: 10, name: 'Reach', value: "REACH" }, billing_event: { id: 2, name: 'CPM', value: "CPM" } }
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" }
            ],
            status_values: [
                { id: 1, name: 'Active', value: "ENABLE" },
                { id: 2, name: 'Disabled', value: "DISABLE" },
                { id: 3, name: 'Deleted', value: "DELETE" }
            ],
            budget_modes: [
                { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
            ],
            locked: false,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            locked: this.props.locked ? this.props.locked : false
        })
        console.log(this.state.campaign);
    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {
        update: async (data) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        let body = {
                            ...(this.state.updated.name) && { campaign_name: this.state.updated.name },
                            ...(this.state.updated.budget) && { budget: this.state.updated.budget }
                        };
                        body.changelog = {
                            initial_data: this.state.campaign,
                            updated_data: this.state.updated,
                            level: "campaign",
                        }
                        try {
                            let response = await this.calls.updateLiveCampaign(body);
                            let updated_camaign = {
                                ...this.state.campaign,
                                ...{
                                    name: this.renders.name()
                                }
                            };
                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "DISABLE") {
                            this.props.onPauseCampaign(true);
                        } else {
                            this.props.onActivateCampaign(true);
                        }

                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });
                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    if (!data) this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }
        }
    };

    calls = {
        updateLiveCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveCampaign?client=" + this.state.client.id + "&campaign=" + this.state.campaign.id + "&channel=tiktok";
            return apiRegister.call(options, url);
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        optimization: () => {
            try {
                if (this.state.campaign.budget_optimize_on === false) {
                    return { id: 2, name: "No", value: false };
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return "";
            }
        },
        objective: () => {
            try {
                if (this.state.campaign.objective_type) {
                    return this.state.objectives.filter((item) => { return item.value === this.state.campaign.objective_type })[0];
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        budget: () => {
            try {
                if (this.state.campaign.budget_mode === false || this.state.campaign.budget_mode === "BUDGET_MODE_INFINITE") {
                    return { id: 0, name: "No", value: 'empty' };
                } else {
                    return { id: 1, name: "Yes", value: 'empty' };
                }
            } catch (error) {
                return "";
            }
        },
        budgetMode: () => {
            try {
                if (this.state.campaign.budget_mode !== false) {
                    return this.state.budget_modes.filter((item) => { return item.value === this.state.campaign.budget_mode })[0];
                } else if (this.state.campaign.budget_mode === false) {
                    return { id: 0, name: "...", value: 'empty' };
                } else {
                    return { id: 0, name: "...", value: 'empty' };
                }
            } catch (error) {
                return "";
            }
        },
        budgetValue: () => {
            try {
                if ('budget' in this.state.updated) {
                    return this.state.updated.budget;
                } else if (this.state.campaign.budget) {
                    return this.state.campaign.budget;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },

        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: this.state.campaign.status.toLowerCase(), value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (
                                                    (tab.id === 1 && this.renders.budgetMode.id === 2 && (parseInt(this.renders.budgetValue()) < 1 || isNaN(parseInt(this.renders.budgetValue()))))
                                                ) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    <div className="col-span-6">
                        <InputTailwind
                            label={"Campaign name"}
                            value={this.renders.name()}
                            onChange={(value) => {
                                this.state.updated.name = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>
                    <div className="col-span-6">
                        <DropdownTailwind
                            label={"Campaign objective"}
                            selected={this.renders.objective()}
                            locked={true}
                            options={this.state.objectives}
                        />
                    </div>
                    <div className="col-span-6">
                        <DropdownTailwind
                            locked={true}
                            label={"Campaign Budget Optimization"}
                            options={[
                                { id: 1, name: "Yes", value: true },
                                { id: 2, name: "No", value: false }
                            ]}
                            selected={this.renders.optimization()}

                        />
                    </div>
                    {
                        this.renders.optimization().id === 1 &&
                        <div className="col-span-6">
                            <div className="bg-blue-100 rounded-md inline-flex w-full p-4 text-sm font-medium text-blue-500">
                                <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
                                To optimize performance, add at least two adgroups to each campaign.
                            </div>
                        </div>
                    }
                    {
                        this.renders.optimization().id === 2 &&
                        <div className={this.renders.budget().id === 2 ? "col-span-2" : "col-span-6"}>
                            <DropdownTailwind
                                label={"Set campaign budget"}
                                selected={this.renders.budget()}
                                options={[
                                    { id: 1, name: "No", value: false },
                                    { id: 2, name: "Yes", value: true }
                                ]}
                                locked={true}
                            />
                        </div>
                    }

                    {
                        (this.renders.budget().id === 2 || this.renders.optimization().id === 1) &&
                        <div className="col-span-2">
                            <DropdownTailwind
                                label={"Campaign Budget"}
                                selected={this.renders.budgetMode()}
                                options={[
                                    { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                                    { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
                                ]}
                                locked={true}

                            />
                        </div>
                    }

                    {
                        (this.renders.budget().id === 2 || this.renders.optimization().id === 1) &&
                        <div className="col-span-2">
                            <InputTailwind
                                error={(parseInt(this.renders.budgetValue()) < 1 || isNaN(parseInt(this.renders.budgetValue())))}
                                leftSection={(this.state.client.currency && this.state.client.currency.tiktok ? true : false)}
                                leftSectionLabel={(this.state.client.currency && this.state.client.currency.tiktok ? this.state.client.currency.tiktok : "")}
                                disabled={false}
                                label={"Amount"}
                                value={this.renders.budgetValue()}
                                onChange={(value) => {
                                    this.state.updated.budget = value;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                            />
                        </div>
                    }






                    <div className="col-span-2">
                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                        <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                            <div className="flex flex-1 ml-5">
                                {this.renders.status().name}
                            </div>
                            <div className="relative overflow-hidden mr-5">
                                <SwitchTailwind
                                    value={this.renders.status().value === "ENABLE" ? true : false}
                                    disabled={this.state.locked}
                                    onSwitch={async () => {
                                        if (!this.state.locked) {
                                            if (this.renders.status().value === "ENABLE") {
                                                this.functions.update({ id: 2, name: 'Disabled', value: "DISABLE" });
                                            } else if (this.renders.status().value === "DISABLE") {
                                                this.functions.update({ id: 1, name: 'Active', value: "ENABLE" });
                                            }
                                        }

                                    }}
                                />
                                {
                                    this.state.loading_status &&
                                    <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>


                    {
                        this.state.error &&
                        <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                    }

                </div>
            </>
        )
    }
}

export default EditLiveTikTikCampaign;
