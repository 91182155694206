import React from 'react';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { BeatLoader } from "react-spinners";
import FeatherIcon from 'feather-icons-react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
var moment = require('moment');

export default class AdvancedReportCellChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            plugin: {
                plugins: [ChartDataLabels],
                options: {
                    plugins: {
                        datalabels: {
                            color: 'white'
                        }
                    }
                },
            },
            line: null,
            bar: null,
            doughnut: null,
            data: {},
            cell: {},
            items: [],
            show: false,
            show_component: false,
            load_status: 'Requesting data from platforms'
        };
    }

    async componentDidMount() {
        await this.promisedSetState({
            cell: this.props.source ? JSON.parse(JSON.stringify(this.props.source)) : {},
            items: this.props.items ? JSON.parse(JSON.stringify(this.props.items)) : [],
            transparent_cells: this.props.transparent_cells,
            report_background_color: this.props.report_background_color,
            show_component: this.props.show_component,
            base_font: this.props.base_font
        });
        let data = this.compareNumber();
        if (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "bar") {
            this.functions.setBarChartOptions(data);
        } else if (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "line") {
            this.functions.setLineChartOptions(data);
        } else {
            this.functions.setDonutChartOptions();
        }

        //ONLY SHOW CONTENT IN VIEW
        this.functions.showInView();

    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.promisedSetState({
            cell: nextProps.source ? JSON.parse(JSON.stringify(nextProps.source)) : {},
            items: nextProps.items ? JSON.parse(JSON.stringify(nextProps.items)) : [],
            transparent_cells: nextProps.transparent_cells,
            report_background_color: nextProps.report_background_color,
            base_font: nextProps.base_font
        });
        this.getCellLoadStatus();
        let data = this.compareNumber();
        if (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "bar") {
            this.functions.setBarChartOptions(data);
        } else if (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "line") {
            this.functions.setLineChartOptions(data);
        } else {
            this.functions.setDonutChartOptions();
        }
    }

    setLoader() { }

    functions = {
        template: () => {
            try {
                return (this.state.cell.data_sources.channels && Object.keys(this.state.cell.data_sources.channels).length < 1) && Object.keys(this.state.cell.data_sources.accounts).length < 1 && Object.keys(this.state.cell.data_sources.campaigns).length < 1 && Object.keys(this.state.cell.data_sources.adgroups).length < 1 && Object.keys(this.state.cell.data_sources.ads).length < 1
            } catch (error) {
                return false;
            }
        },
        setBarChartOptions: async (data) => {
            let self = this;
            let total = 0;
            if (data) {
                total = (data.datasets.length * data.labels.length);
            }
            let limit_characters = this.state.cell.settings && this.state.cell.settings.limit_characters ? this.state.cell.settings.limit_characters : 0;
            let metric = null;
            for (let key in this.state.cell.metrics) {
                metric = this.state.cell.metrics[key];
            }
            let bar_options = {
                animation: false,
                orientation: 'horizontal',
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    labels: {
                        fontColor: (this.state.cell.settings && this.state.cell.settings.font_color ? "#" + this.state.cell.settings.font_color : "#000000"),
                        fontFamily: this.font()
                    }
                },
                ...(this.state.cell.daterange && this.state.cell.daterange.compare_dates && this.state.cell.daterange.compare_dates.value === "enabled" && {
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        footerFontSize: 17,
                        footerMarginTop: 5,
                        callbacks: {
                            label: function (tooltipItem, data) {
                                if (Array.isArray(tooltipItem.xLabel)) {
                                    if (tooltipItem.xLabel.length > 4) {
                                        if (tooltipItem.datasetIndex == 0) {
                                            return tooltipItem.xLabel[0] + ": " + tooltipItem.value;
                                        } else {
                                            return tooltipItem.xLabel[4] + ": " + tooltipItem.value;
                                        }
                                    } else {
                                        return tooltipItem.value;
                                    }
                                } else {
                                    return tooltipItem.value;
                                }
                            },
                            title: function (tooltipItems) {
                                let title = "";
                                if (Array.isArray(tooltipItems[0].xLabel)) {
                                    title = tooltipItems[0].xLabel[0];
                                    if (tooltipItems[0].xLabel.length > 4) {
                                        title = title + " vs " + tooltipItems[0].xLabel[4];
                                    }
                                } else {
                                    title = tooltipItems[0].xLabel;
                                }
                                return title;
                            },
                            footer: function (tooltipItems) {
                                let value = 0;
                                if (tooltipItems.length > 1) {
                                    if (tooltipItems[1].value > 0) {
                                        let increase = tooltipItems[0].value - tooltipItems[1].value;
                                        value = (increase / tooltipItems[1].value) * 100;
                                        if (value % 1 != 0) {
                                            value = value.toFixed(2);
                                        }
                                    }
                                    value = value + "%";
                                } else {
                                    value = "";
                                }
                                return value;
                            }
                        }
                    }
                }),
                plugins: {
                    datalabels: {
                        color: '#ffffff',
                        font: function (context) {
                            let ctx = context
                            var width = ctx.chart.width;
                            let size = 0;
                            if (total > 15) {
                                size = 0;
                            } else if (total >= 12) {
                                size = 110;
                            } else if (total >= 9) {
                                size = 90;
                            } else if (total >= 6) {
                                size = 70;
                            } else if (total >= 3) {
                                size = 50;
                            } else {
                                size = 45;
                            }
                            var font_size = Math.round(width / size);
                            return {
                                size: font_size,
                                weight: 700,
                                family: self.font()
                            };
                        }
                    },
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            position: 'left',
                            ticks: {
                                fontSize: 10,
                                beginAtZero: true,
                                precision: 0,
                                fontFamily: this.font(),
                                fontColor: data && data.datasets.length === 2 && this.state.cell.settings.multi_y_axis && this.state.cell.settings.multi_y_axis.value === 'enabled'
                                    ? (data.datasets[0].borderColor ? data.datasets[0].borderColor : "#000000")
                                    : (this.state.cell.settings && this.state.cell.settings.font_color ? "#" + this.state.cell.settings.font_color : "#000000")
                            }
                        },
                        ...(data && data.datasets.length === 2 && this.state.cell.settings.multi_y_axis && this.state.cell.settings.multi_y_axis.value === 'enabled' ? [{
                            id: 'y-axis-2',
                            position: 'right',
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                fontSize: 10,
                                beginAtZero: true,
                                precision: 0,
                                fontFamily: this.font(),
                                fontColor: data.datasets[1].borderColor
                                    ? data.datasets[1].borderColor
                                    : this.state.cell.settings && this.state.cell.settings.font_color
                                        ? "#" + this.state.cell.settings.font_color
                                        : "#000000"
                            }
                        }] : [])
                    ],
                    xAxes: [{
                        ticks: {
                            fontSize: 10,
                            fontFamily: this.font(),
                            fontColor: (this.state.cell.settings && this.state.cell.settings.font_color ? "#" + this.state.cell.settings.font_color : "#000000"),
                            /*
                            ...(this.state.cell.daterange && this.state.cell.daterange.compare_dates && this.state.cell.daterange.compare_dates.value === "enabled" && {
                                callback: function (value, index, values) {
                                    if (limit_characters > 0) {
                                        if (Array.isArray(value)) {
                                            return value[0].substring(0, limit_characters);
                                        } else {
                                            return value.substring(0, limit_characters);
                                        }
                                    } else {
                                        if (Array.isArray(value)) {
                                            return value[0];
                                        } else {
                                            return value;
                                        }
                                    }
                                }
                            }),
                            */
                            callback: function (value, index, values) {
                                if (limit_characters > 0) {
                                    if (Array.isArray(value)) {
                                        return value[0].substring(0, limit_characters) + "...";
                                    } else {
                                        return value.substring(0, limit_characters) + "...";
                                    }
                                } else {
                                    if (Array.isArray(value)) {
                                        return value[0];
                                    } else {
                                        return value;
                                    }
                                }
                            }
                        }
                    }]
                },
            };
            if (this.state.cell.settings.show_datasources && this.state.cell.settings.show_datasources.value == "disabled") {
                bar_options.legend.display = false;
            }
            await this.promisedSetState({
                bar: bar_options
            });
        },
        setLineChartOptions: async (data) => {
            let self = this;
            let total = 0;
            if (this.state.cell.data) {
                total = (this.state.cell.data.datasets.length * this.state.cell.data.labels.length);
            }
            let limit_characters = this.state.cell.settings && this.state.cell.settings.limit_characters ? this.state.cell.settings.limit_characters : 0;
            let metric = null;
            for (let key in this.state.cell.metrics) {
                metric = this.state.cell.metrics[key];
            }
            let line_options = {
                animation: false,
                maintainAspectRatio: false,
                legend: {
                    display: !(this.state.cell && this.state.cell.settings && this.state.cell.settings.hide_legend),
                    labels: {
                        fontColor: (this.state.cell.settings && this.state.cell.settings.font_color ? "#" + this.state.cell.settings.font_color : "#000000"),
                        fontFamily: this.font()
                    }
                },
                ...(this.state.cell.daterange && this.state.cell.daterange.compare_dates && this.state.cell.daterange.compare_dates.value === "enabled" && {
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        footerFontSize: 17,
                        footerMarginTop: 5,
                        callbacks: {
                            label: function (tooltipItem, data) {
                                if (Array.isArray(tooltipItem.xLabel)) {
                                    if (tooltipItem.xLabel.length > 4) {
                                        if (tooltipItem.datasetIndex == 0) {
                                            return tooltipItem.xLabel[0] + ": " + tooltipItem.value;
                                        } else {
                                            return tooltipItem.xLabel[4] + ": " + tooltipItem.value;
                                        }
                                    } else {
                                        return tooltipItem.value;
                                    }
                                } else {
                                    return tooltipItem.value;
                                }
                            },
                            title: function (tooltipItems) {
                                let title = "";
                                if (Array.isArray(tooltipItems[0].xLabel)) {
                                    title = tooltipItems[0].xLabel[0];
                                    if (tooltipItems[0].xLabel.length > 4) {
                                        title = title + " vs " + tooltipItems[0].xLabel[4];
                                    }
                                } else {
                                    title = tooltipItems[0].xLabel;
                                }
                                return title;
                            },
                            footer: function (tooltipItems) {
                                let value = 0;
                                if (tooltipItems.length > 1) {
                                    if (tooltipItems[1].value > 0) {
                                        let increase = tooltipItems[0].value - tooltipItems[1].value;
                                        value = (increase / tooltipItems[1].value) * 100;
                                        if (value % 1 != 0) {
                                            value = value.toFixed(2);
                                        }
                                    }
                                    value = value + "%";
                                } else {
                                    value = "";
                                }
                                return value;
                            }
                        }
                    }
                }),
                ...(this.state.cell.hide_title && {
                    tooltips: {
                        tooltips: {
                            mode: 'index'
                        },
                        callbacks: {
                            title: function (tooltipItem) {
                                return "";
                            }
                        }
                    }
                }),
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                plugins: {
                    datalabels: {
                        backgroundColor: function (context) {
                            return context.dataset.borderColor;
                        },
                        borderRadius: 4,
                        color: 'white',
                        font: {
                            size: "7",
                            weight: '500',
                            family: this.font(),
                        },
                        padding: 4
                    }
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                fontSize: this.props.tickSize ? this.props.tickSize : 10,
                                beginAtZero: true,
                                precision: 0,
                                fontFamily: this.font(),
                                fontColor: data && data.datasets.length === 2 && this.state.cell.settings.multi_y_axis && this.state.cell.settings.multi_y_axis.value === 'enabled'
                                    ? (data.datasets[0].borderColor ? data.datasets[0].borderColor : "#000000")
                                    : (this.state.cell.settings && this.state.cell.settings.font_color ? "#" + this.state.cell.settings.font_color : "#000000")
                            }
                        },
                        ...(data && data.datasets.length === 2 && this.state.cell.settings.multi_y_axis && this.state.cell.settings.multi_y_axis.value === 'enabled' ? [{
                            id: 'y-axis-2',
                            type: 'linear',
                            position: 'right',
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                fontSize: this.props.tickSize ? this.props.tickSize : 10,
                                beginAtZero: true,
                                precision: 0,
                                fontFamily: this.font(),
                                fontColor: data.datasets[1].borderColor
                                    ? data.datasets[1].borderColor
                                    : this.state.cell.settings && this.state.cell.settings.font_color
                                        ? "#" + this.state.cell.settings.font_color
                                        : "#000000"
                            }
                        }] : [])
                    ],
                    xAxes: [{
                        ticks: {
                            fontSize: this.props.tickSize ? this.props.tickSize : 10,
                            fontFamily: this.font(),
                            fontColor: (this.state.cell.settings && this.state.cell.settings.font_color ? "#" + this.state.cell.settings.font_color : "#000000"),
                            /*
                            ...(this.state.cell.daterange && this.state.cell.daterange.compare_dates && this.state.cell.daterange.compare_dates.value === "enabled" && {
                                callback: function (value, index, values) {
                                    if (Array.isArray(value)) {
                                        return value[0]
                                    } else {
                                        return value;
                                    }
                                }
                            })
                            */
                            callback: function (value, index, values) {
                                if (limit_characters > 0) {
                                    if (Array.isArray(value)) {
                                        return value[0].substring(0, limit_characters) + "...";
                                    } else {
                                        return value.substring(0, limit_characters) + "...";
                                    }
                                } else {
                                    if (Array.isArray(value)) {
                                        return value[0];
                                    } else {
                                        return value;
                                    }
                                }
                            }
                        }
                    }]
                }
            };

            if (!line_options.tooltips) {
                line_options.tooltips = {
                    mode: 'index',
                    intersect: false,
                    footerFontSize: 17,
                    footerMarginTop: 5
                }
            }

            if (this.state.cell.settings.show_datasources && this.state.cell.settings.show_datasources.value == "disabled") {
                line_options.legend.display = false;
            }

            await this.promisedSetState({
                line: line_options
            });
        },
        setDonutChartOptions: async () => {
            let self = this;
            let doughnut = {
                animation: false,
                legend: {
                    position: 'left',
                    display: true,
                    labels: {
                        fontColor: (this.state.cell.settings && this.state.cell.settings.font_color ? "#" + this.state.cell.settings.font_color : "#000000"),
                        fontFamily: this.font(),
                    }
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                plugins: {
                    datalabels: {
                        backgroundColor: function (context) {
                            return context.dataset.borderColor;
                        },
                        borderRadius: 4,
                        color: 'white',
                        font: function (context) {
                            if (self.state.cell && self.state.cell.chart && self.state.cell.chart.value === "donut") {
                                let radius = context.chart.outerRadius / 100;
                                return {
                                    family: self.font(),
                                    size: 7 * radius,
                                    weight: '500'
                                };
                            } else {
                                return {
                                    family: self.font(),
                                    size: "7",
                                    weight: '500'
                                };
                            }
                        },
                        padding: 4
                    }
                },
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true
                },
                cutoutPercentage: 50,
            };

            if (this.state.cell.settings.show_datasources && this.state.cell.settings.show_datasources.value == "disabled") {
                doughnut.legend.display = false;
            }

            await this.promisedSetState({
                doughnut: doughnut
            });
        },
        showInView: async () => {
            let self = this;
            if (this.state.show_component) {
                self.setState({ show: true });
            } else {
                await self.functions.sleep(2000);
                try {
                    let box = null;
                    if (window.innerWidth <= 767) {
                        box = document.getElementById(self.state.cell.i + "_preview_mobile");
                    } else {
                        box = document.getElementById(self.state.cell.i + "_preview");
                    }
                    const rect = box.getBoundingClientRect();
                    const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                        rect.bottom > 0 &&
                        rect.right > 0;
                    if (isInView) {
                        self.setState({ show: isInView });
                    }
                } catch (error) {
                    self.setState({ show: true });
                }
                document.addEventListener('scroll', async function () {
                    try {
                        let box = null;
                        if (window.innerWidth <= 767) {
                            box = document.getElementById(self.state.cell.i + "_preview_mobile");
                        } else {
                            box = document.getElementById(self.state.cell.i + "_preview");
                        }
                        const rect = box.getBoundingClientRect();
                        const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                            rect.bottom > 0 &&
                            rect.right > 0;
                        self.setState({ show: isInView });
                    } catch (error) {
                        self.setState({ show: true });
                    }
                }, {
                    capture: true,
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    };

    getCellLoadStatus(timeout = false) {
        if (this.state.cell.loading) {
            if (this.state.cell.load_status) {
                if (!timeout && this.state.load_status === 'Requesting data from platforms') {
                    this.state.load_status = 'Requesting data from platforms';
                    setTimeout(() => {
                        this.getCellLoadStatus(true);
                    }, 10000);
                } else if (timeout) {
                    this.state.load_status = 'Waiting on data from platforms';
                }
            } else {
                this.state.load_status = 'Requesting data from platforms';
            }
        }

        this.promisedSetState({
            load_status: this.state.load_status
        });
    }

    compareNumber() {
        if (this.state.cell.daterange && this.state.cell.daterange.compare_dates && this.state.cell.daterange.compare_dates.value === "enabled" && this.state.cell.data) {

            let compare_data_to_labels = {};
            let original_data = this.state.cell.data;
            let preceding_data = null;
            this.state.items.map((item) => {
                if (item.abstract && item.parent_cell == this.state.cell.i) {
                    preceding_data = item;
                }
            });
            if (preceding_data && preceding_data.data && Array.isArray(preceding_data.data.datasets) && preceding_data.data.datasets.length > 0) {
                if (original_data && Array.isArray(original_data.datasets) && original_data.datasets.length > 0) {

                    //GET CHANNEL
                    let channel = null;
                    for (let source in this.state.cell.data_sources.channels) {
                        if (!channel) {
                            channel = this.state.cell.data_sources.channels[source].channel;
                        }
                    }
                    for (let account in this.state.cell.data_sources.accounts) {
                        if (!channel) {
                            channel = this.state.cell.data_sources.accounts[account].channel;
                        }
                    }
                    for (let campaign in this.state.cell.data_sources.campaigns) {
                        if (!channel) {
                            channel = this.state.cell.data_sources.campaigns[campaign].channel;
                        }
                    }
                    for (let adgroup in this.state.cell.data_sources.adgroups) {
                        if (!channel) {
                            channel = this.state.cell.data_sources.adgroups[adgroup].channel;
                        }
                    }
                    for (let ad in this.state.cell.data_sources.ads) {
                        if (!channel) {
                            channel = this.state.cell.data_sources.ads[ad].channel;
                        }
                    }

                    //CHECK IF SAME DATA EXIST IN BOTH DATASET, OTHERWISE MERGE

                    //ORIGINAL DATA
                    original_data.labels.map((label, index) => {
                        compare_data_to_labels[label] = { "original": original_data.datasets[0].data[index], "preciding": 0 };
                        if ("date" in this.state.cell.channel_breakdowns[channel]) {
                            compare_data_to_labels[label] = { "original": original_data.datasets[0].data[index], "preciding": preceding_data.data.datasets[0].data[index], "preciding-label": preceding_data.data.labels[index] };
                        } else if ("FILTER_DATE" in this.state.cell.channel_breakdowns[channel]) {
                            compare_data_to_labels[label] = { "original": original_data.datasets[0].data[index], "preciding": preceding_data.data.datasets[0].data[index], "preciding-label": preceding_data.data.labels[index] };
                        }
                    });

                    //PRECIDING DATA
                    if (!(this.state.cell.channel_breakdowns[channel] && ("date" in this.state.cell.channel_breakdowns[channel] || "FILTER_DATE" in this.state.cell.channel_breakdowns[channel]))) {
                        preceding_data.data.labels.map((label, index) => {
                            if (!compare_data_to_labels[label]) {
                                compare_data_to_labels[label] = { "original": 0, "preciding": preceding_data.data.datasets[0].data[index] };
                            } else {
                                compare_data_to_labels[label]["preciding"] = preceding_data.data.datasets[0].data[index];
                            }
                        });
                    }

                    let time_string = "";
                    if (preceding_data.daterange.start_date && preceding_data.daterange.end_date) {
                        var start = moment(preceding_data.daterange.start_date, "YYYY-MM-DD");
                        var end = moment(preceding_data.daterange.end_date, "YYYY-MM-DD");
                        let days = moment.duration(start.diff(end)).asDays();
                        days = days * -1;
                        let start_date = moment(start).subtract(days + 1, 'days').format("YYYY-MM-DD");
                        let end_date = moment(end).subtract(days + 1, 'days').format("YYYY-MM-DD");
                        time_string = start_date + " - " + end_date;
                        if (this.state.cell.daterange && this.state.cell.daterange.compare_last_year && this.state.cell.daterange.compare_last_year.value === "enabled") {
                            start_date = moment(this.state.cell.daterange.start_date).subtract(1, 'years').format("YYYY-MM-DD");
                            end_date = moment(this.state.cell.daterange.end_date).subtract(1, 'years').format("YYYY-MM-DD");
                            time_string = start_date + " - " + end_date;
                        } else if (this.state.cell.daterange && this.state.cell.daterange.compare_custom_date && this.state.cell.daterange.compare_custom_date.value === "enabled") {
                            start_date = moment(this.state.cell.daterange.compare_custom_date.start_date).subtract(1, 'years').format("YYYY-MM-DD");
                            end_date = moment(this.state.cell.daterange.compare_custom_date.end_date).subtract(1, 'years').format("YYYY-MM-DD");
                            time_string = start_date + " - " + end_date;
                        }
                    }

                    let merged_data = {
                        datasets: [
                            {
                                label: original_data.datasets[0].label,
                                backgroundColor: original_data.datasets[0].backgroundColor + (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "line" ? "00" : ""),
                                borderColor: original_data.datasets[0].borderColor,
                                data: [],
                                borderWidth: 2
                            },
                            {
                                label: "Preciding period: " + time_string,
                                backgroundColor: original_data.datasets[0].backgroundColor + (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "line" ? "00" : "60"),
                                borderColor: original_data.datasets[0].borderColor + "60",
                                borderDash: [5, 5],
                                data: [],
                                borderWidth: 2
                            }],
                        labels: []
                    };

                    for (let key in compare_data_to_labels) {

                        //SET DATA
                        merged_data.datasets[0].data.push(compare_data_to_labels[key].original);
                        merged_data.datasets[1].data.push(compare_data_to_labels[key].preciding);

                        //SET LABELS
                        let label = [];
                        label.push(key);
                        label.push(compare_data_to_labels[key].original);
                        label.push(compare_data_to_labels[key].preciding);
                        label.push(compare_data_to_labels[key].preciding);
                        if ("date" in this.state.cell.channel_breakdowns[channel]) {
                            label.push(compare_data_to_labels[key]["preciding-label"]);
                        } else if ("FILTER_DATE" in this.state.cell.channel_breakdowns[channel]) {
                            label.push(compare_data_to_labels[key]["preciding-label"]);
                        }
                        merged_data.labels.push(label);

                    }

                    try {
                        merged_data = merged_data.map((dataset) => {
                            dataset.data = dataset.data.map((item) => {
                                item = +item;
                                return item;
                            });
                            return dataset;
                        });
                    } catch (error) { }

                    return merged_data;

                } else {

                    return {}

                }
            } else {

                try {
                    original_data = original_data.map((dataset) => {
                        dataset.data = dataset.data.map((item) => {
                            item = +item;
                            return item;
                        });
                        return dataset;
                    });
                } catch (error) { }

                return original_data;
            }
        } else {

            try {
                if (this.state.cell.data && Array.isArray(this.state.cell.data.datasets)) {
                    this.state.cell.data.datasets = this.state.cell.data.datasets.map((dataset, index) => {
                        dataset.data = dataset.data.map((item) => +item);
                        return dataset;
                    });
                    if (this.state.cell.data.datasets && this.state.cell.data.datasets.length === 2 && this.state.cell.settings.multi_y_axis && this.state.cell.settings.multi_y_axis.value === 'enabled'
                        && ((this.state.line && this.state.line.scales && Array.isArray(this.state.line.scales.yAxes) && this.state.line.scales.yAxes.length > 1 && this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "line")
                            || (this.state.bar && this.state.bar.scales && Array.isArray(this.state.bar.scales.yAxes) && this.state.bar.scales.yAxes.length > 1 && this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "bar"))) {
                        this.state.cell.data.datasets = this.state.cell.data.datasets.map((dataset, index) => {
                            dataset.yAxisID = `y-axis-${index + 1}`;
                            return dataset
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }

            return this.state.cell.data;
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    font = () => {
        let font_string = "Poppins";
        try {
            if (this.state.base_font && this.state.base_font.value) {
                font_string = this.state.base_font.name.replace(/\x00/g, '');
                //font_string = font_string.toLowerCase();
            }
        } catch (error) { }
        return font_string;
    }

    render() {
        if (this.state.show) {
            if (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "donut" && !this.state.cell.loading && !this.functions.template()) {
                return (
                    <div
                        className={(this.props.external ? "py-4 md:py-8" : "py-8 absolute") + " w-full flex justify-center align-middle items-center flex-col h-full"}
                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color && !this.state.cell.settings.transparent ? { backgroundColor: "#" + this.state.cell.settings.background_color } : (this.state.cell && this.state.cell.settings && this.state.cell.settings.transparent ? {} : { backgroundColor: "#FFFFFF" })}
                    >
                        {
                            this.state.cell && this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                            <div className="mb-4"
                                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}
                            >{this.state.cell.settings.title}</div>
                        }
                        {
                            this.state.doughnut &&
                            this.state.cell.data &&
                            <div className={(this.props.external ? "" : "px-12") + " w-full h-full"}>
                                <Doughnut
                                    width={null}
                                    height={null}
                                    responsive={false}
                                    options={this.state.doughnut}
                                    data={this.state.cell.data}
                                    plugins={this.state.cell && this.state.cell.settings && this.state.cell.settings.chart_numbers && this.state.cell.settings.chart_numbers.value === "enabled" ? this.state.plugin.plugins : {}}
                                />
                            </div>
                        }
                    </div>
                );
            } if (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "line" && !this.state.cell.loading && !this.functions.template()) {
                return (
                    <div
                        className={(this.props.external ? "px-4 py-4 md:px-12 md:py-8" : "px-12 py-8 absolute") + " w-full flex justify-center align-middle items-center flex-col h-full"}
                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color && !this.state.cell.settings.transparent ? { backgroundColor: "#" + this.state.cell.settings.background_color } : (this.state.cell && this.state.cell.settings && this.state.cell.settings.transparent ? {} : { backgroundColor: "#FFFFFF" })}
                    >
                        {
                            !this.props.hideTitle &&
                            this.state.cell && this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                            <div className="mb-4"
                                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}
                            >{this.state.cell.settings.title}</div>
                        }
                        {
                            this.state.line &&
                            this.state.cell.data &&
                            <div className={(this.props.external ? "" : "px-12") + " w-full h-full"}>
                                <Line
                                    width={null}
                                    height={null}
                                    responsive={false}
                                    options={this.state.line}
                                    data={this.compareNumber()}
                                    plugins={this.state.cell && this.state.cell.settings && this.state.cell.settings.chart_numbers && this.state.cell.settings.chart_numbers.value === "enabled" ? this.state.plugin.plugins : {}}
                                />
                            </div>
                        }
                    </div>
                );
            } if (this.state.cell && this.state.cell.chart && this.state.cell.chart.value === "bar" && !this.state.cell.loading && !this.functions.template()) {
                return (
                    <div
                        className={(this.props.external ? "px-4 py-4 md:px-12 md:py-8" : "px-12 py-8 absolute") + " w-full flex justify-center align-middle items-center flex-col h-full"}
                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color && !this.state.cell.settings.transparent ? { backgroundColor: "#" + this.state.cell.settings.background_color } : (this.state.cell && this.state.cell.settings && this.state.cell.settings.transparent ? {} : { backgroundColor: "#FFFFFF" })}
                    //className={(this.props.external ? "px-4 py-4 md:px-12 md:py-8" : "px-12 py-8 absolute") + " w-full flex justify-center align-middle items-center flex-col h-full"}
                    //style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                    >
                        {
                            this.state.cell && this.state.cell.settings && this.state.cell.settings.title && this.state.cell.settings.title !== "" &&
                            <div className="mb-4"
                                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color && { color: "#" + this.state.cell.settings.font_color }}
                            >{this.state.cell.settings.title}</div>
                        }
                        {
                            this.state.bar &&
                            this.state.cell.data &&
                            <div className={(this.props.external ? "" : "px-12") + " w-full h-full"}>
                                <Bar
                                    width={null}
                                    height={null}
                                    responsive={false}
                                    options={this.state.bar}
                                    data={this.compareNumber()}
                                    plugins={this.state.cell && this.state.cell.settings && this.state.cell.settings.chart_numbers && this.state.cell.settings.chart_numbers.value === "enabled" ? this.state.plugin.plugins : {}}
                                />
                            </div>
                        }
                    </div>
                );
            } else if (this.state.cell && this.state.cell.loading) {
                return (
                    <div
                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                        className="w-full h-full relative flex flex-col justify-center items-center">
                        <div className="bg-transparent">
                            <div style={{ borderTopColor: "transparent" }}
                                className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                        <div className="text-xs mt-3">{this.state.load_status}</div>
                    </div>
                );
            } else if (this.functions.template()) {
                return (
                    <div
                        style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                        className="w-full h-full flex flex-col justify-center items-center relative">
                        <button onClick={() => {
                            this.props.onEditItem();
                        }} style={{ top: "15px", left: "15px" }}
                            className="transition-all duration-200 absolute px-2 font-bold text-xs leading-none flex items-center justify-center mr-1 h-8 bg-purple-100 text-purple-500 rounded">
                            <FeatherIcon className={"stroke-current"} size={15} icon={"pie-chart"} />
                            <span className="ml-2">{this.state.header !== "" ? this.state.header : "..."}</span>
                        </button>
                    </div>
                );
            } else {
                return (<></>)
            }
        } else {
            return (
                <div
                    style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                    className="w-full h-full relative flex flex-col justify-center items-center">
                    <div className="bg-transparent">
                        <div style={{ borderTopColor: "transparent" }}
                            className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                    </div>
                </div>
            )
        }
    }
}

